// components/UserFormContent.js
import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';

const GRADE_OPTIONS = ['א׳', 'ב׳', 'ג׳', 'ד׳', 'ה׳', 'ו׳', 'ז׳', 'ח׳', 'ט׳'];

export { GRADE_OPTIONS };

export const UserFormContent = ({ formData, setFormData }) => {
  return (
    <Stack spacing={4}>
      <FormControl isRequired>
        <FormLabel>שם</FormLabel>
        <Input
          value={formData.name}
          onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
          placeholder="שם מלא"
          autoComplete="off"
          autofill="off"
          name="random_name_field"
          data-form-type="other"
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>כיתה</FormLabel>
        <Select
          value={formData.grade}
          onChange={(e) => setFormData(prev => ({ ...prev, grade: e.target.value }))}
          placeholder="בחר כיתה"
        >
          {GRADE_OPTIONS.map(grade => (
            <option key={grade} value={grade}>כיתה {grade}</option>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};