export const learningContent = {
    "סדר פעולות חשבון": {
        tags: ['כיתה ז׳', 'אלגברה', 'סדר פעולות'],
        relatedLinks: [
            { title: "תרגול סדר פעולות חשבון", url: "/?tags=סדר%20פעולות", description: "תרגול בעיות סדר פעולות חשבון" }
        ],
        getStarted: [
            "**סדר פעולות חשבון:**",
            "1. פעולות בתוך סוגריים",
            "2. חזקות ושורשים",
            "3. כפל וחילוק משמאל לימין",
            "4. חיבור וחיסור משמאל לימין",
            "",
            "**דוגמאות:**",
            "$30 - 5 × 4 = 30 - 20 = 10$ (קודם כפל)",
            "$(2 + 3) × 4 = 5 × 4 = 20$ (קודם סוגריים)",
            "$2 + 3 × 4 = 2 + 12 = 14$ (קודם כפל)"
        ],

        examples: [
            {
                question: "חשבו: $30 - 5 × (8 - 4) - 10 + 4$",
                solution: "1) נפתור קודם את הסוגריים: $8 - 4 = 4$<br/>2) נכפול: $5 × 4 = 20$<br/>3) נחבר וחסר משמאל לימין: $30 - 20 - 10 + 4 = 4$"
            },
            {
                question: "חשבו: $(80 - 40) ÷ 5 × 2$",
                solution: "1) נפתור קודם את הסוגריים: $80 - 40 = 40$<br/>2) נחלק: $40 ÷ 5 = 8$<br/>3) נכפול: $8 × 2 = 16$"
            },
            {
                question: "חשבו: $10 × (6 ÷ 3) + 3 + 2$",
                solution: "1) נפתור קודם את הסוגריים: $6 ÷ 3 = 2$<br/>2) נכפול: $10 × 2 = 20$<br/>3) נחבר משמאל לימין: $20 + 3 + 2 = 25$"
            }
        ],

        quiz: [
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) × ({B}) + {C} $",
                params: {
                    A: { range: [2, 10], type: "integer" },
                    B: { range: [2, 10], type: "integer" },
                    C: { range: [1, 20], type: "integer" }
                },
                formula: "A*B+C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "קודם נכפול את המספרים בסוגריים, ואז נחבר",
                metadata: {
                    step1: "{A}*{B}",
                    step2: "{A}*{B}+{C}"
                },
                solutionGuide: "1) קודם כופלים: $ {A} × {B} = {step1} $<br/>2) אחר כך מחברים: $ {step1} + {C} = {step2} $"
            },

            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} + {B}) × {C} - {D} $",
                params: {
                    A: { range: [2, 10], type: "integer" },
                    B: { range: [2, 10], type: "integer" },
                    C: { range: [2, 5], type: "integer" },
                    D: { range: [1, 10], type: "integer" }
                },
                formula: "(A+B)*C-D",
                distractors: {
                    strategies: ["off-by-eleven", "off-by-two", "off-by-three"]
                },
                hint: "זכרו לפתור קודם מה שבתוך הסוגריים",
                metadata: {
                    step1: "{A} + {B}",
                    step2: "({A} + {B}) * {C}",
                    step3: "(({A} + {B}) * {C}) - {D}"
                },
                solutionGuide: "1) סוגריים: $ {A} + {B} = {step1} $<br/>2) כפל: $ {step1} × {C} = {step2} $<br/>3) חיסור: $ {step2} - {D} = {step3} $",

            },

            {
                type: "multiChoiceRandomMath",
                question: "$ {A} × {B} - {Calc_C} ÷ {D} $",
                params: {
                    A: { range: [2, 15], type: "integer" },
                    B: { range: [3, 15], type: "integer" },
                    C: { range: [1, 10], type: "integer" },
                    D: { range: [2, 3], type: "integer" }
                },
                formula: "(A*B-C)",
                distractors: {
                    strategies: ["half", "double", "sign-flip"]
                },
                hint: "פתרו לפי הסדר: 1) כפל וחילוק 2) חיבור וחיסור",
                metadata: {
                    Calc_C: "{C} * {D}",
                    step1: "{A} * {B}",
                    step2: "{C}",
                    step3: "(({A} * {B}) - {C})"
                },
                solutionGuide: "1) כפל בסוגריים: $ {A} × {B} = {step1} $<br/>2) חיסור בסוגריים: $ {Calc_C} ÷ {D} = {step2} $<br/> 3) חילוק: $ {step1} - {step2} = {Result} $",
            },

            {
                type: "multiChoiceRandomMath",
                question: "$ {A} × ({B} + {C}) - {D} × {E} $",
                params: {
                    A: { range: [2, 6], type: "integer" },
                    B: { range: [2, 8], type: "integer" },
                    C: { range: [2, 8], type: "integer" },
                    D: { range: [2, 5], type: "integer" },
                    E: { range: [2, 5], type: "integer" }
                },
                formula: "A*(B+C)-D*E",
                distractors: {
                    strategies: ["off-by-ten", "half", "double"]
                },
                hint: "1) סוגריים 2) כפל וחילוק 3) חיבור וחיסור",
                metadata: {
                    step1: "{B} + {C}",
                    step2: "{A} * ({B} + {C})",
                    step3: "{D} * {E}"
                },
                solutionGuide: "1) סוגריים: $ {B} + {C} = {step1} $ <br/> 2) כפל: $ {A} × {step1} = {step2}, {D} × {E} = {step3} $ <br/> 3) חיסור: $ {step2} - {step3} = {Result} $",
            },

            {
                type: "multiChoiceRandomMath",
                question: "$ {A} × {B} - {Calc_C} ÷ {D} $",
                params: {
                    A: { range: [2, 10], type: "integer" },
                    B: { range: [2, 10], type: "integer" },
                    C: { range: [1, 4], type: "integer" },
                    D: { range: [2, 7], type: "integer" }
                },
                formula: "(A*B) - C",
                metadata: {
                    Calc_C: "{C} * {D}",
                    step1: "{A} * {B}",
                    step2: "{C}",
                },
                distractors: {
                    strategies: ["half", "double", "off-by-ten"]
                },
                hint: "פתרו לפי הסדר: 1) כפל וחילוק 2) חיבור וחיסור",
                solutionGuide: "1) כפל: $ {A} × {B} = {step1} $ <br/>2) חילוק: $ {Calc_C} ÷ {D} = {step2} $ <br/> 3) חיסור: $ {step1} - {step2} = {Result} $"
            },

            {
                type: "multiChoiceRandomMath",
                question: "$ {A} × ({B} - {C}) + {D} × {E} $",
                params: {
                    A: { range: [2, 5], type: "integer" },
                    B: { range: [8, 12], type: "integer" },
                    C: { range: [2, 4], type: "integer" },
                    D: { range: [2, 4], type: "integer" },
                    E: { range: [3, 5], type: "integer" }
                },
                formula: "A*(B-C)+D*E",
                distractors: {
                    strategies: ["off-by-ten", "off-by-three", "double"]
                },
                hint: "1) סוגריים 2) כפל וחילוק 3) חיבור וחיסור",
                metadata: {
                    step1: "{B} - {C}",
                    step2: "{A} * ({B} - {C})",
                    step3: "{D} * {E}"
                },
                solutionGuide: "1) סוגריים: $ {B} - {C} = {step1} $ <br/> 2) כפל: $ {A} × {step1} = {step2}, {D} × {E} = {step3} $ <br/> 3) חיבור: $ {step2} + {step3} = {Result} $"
            },

            {
                type: "multiChoiceRandomMath",
                question: "$({Calc_A} ÷ {B}) × {C} + {D} $",
                params: {
                    A: { range: [4, 8], type: "integer" },
                    B: { range: [2, 4], type: "integer" },
                    C: { range: [2, 5], type: "integer" },
                    D: { range: [5, 15], type: "integer" }
                },
                formula: "(A*C)+D",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "1) חילוק בסוגריים 2) כפל 3) חיבור",
                metadata: {
                    Calc_A: "{A} * {B}",
                    step1: "{A}",
                    step2: "{A} * {C}"
                },
                solutionGuide: "1) חילוק: $ {Calc_A} ÷ {B} = {step1} $ <br/> 2) כפל: $ {step1} × {C} = {step2} $ <br/> 3) חיבור: $ {step2} + {D} = {Result} $"
            },

            {
                type: "multiChoiceRandomMath",
                question: "$ {A} × ({Calc_B} ÷ {C}) - {D} $",
                params: {
                    A: { range: [2, 6], type: "integer" },
                    B: { range: [2, 5], type: "integer" },
                    C: { range: [2, 3], type: "integer" },
                    D: { range: [3, 12], type: "integer" }
                },
                formula: "A*B-D",
                distractors: {
                    strategies: ["off-by-two", "half", "double"]
                },
                hint: "1) חילוק בסוגריים 2) כפל 3) חיסור",
                metadata: {
                    Calc_B: "{B} * {C}",
                    step1: "{B}",
                    step2: "{A} * {B}"
                },
                solutionGuide: "1) חילוק: $ {Calc_B} ÷ {C} = {step1} $ <br/> 2) כפל: $ {A} × {step1} = {step2} $ <br/> 3) חיסור: $ {step2} - {D} = {Result} $"
            },

            {
                type: "multiChoiceRandomMath",
                question: "$({Calc_A} ÷ {B} + {C}) × {D} $",
                params: {
                    A: { range: [4, 12], type: "integer" },
                    B: { range: [2, 4], type: "integer" },
                    C: { range: [2, 5], type: "integer" },
                    D: { range: [2, 4], type: "integer" }
                },
                formula: "(A+C)*D",
                distractors: {
                    strategies: ["off-by-five", "half", "double"]
                },
                hint: "1) חילוק בסוגריים 2) חיבור בסוגריים 3) כפל",
                metadata: {
                    Calc_A: "{A} * {B}",
                    step1: "{A}",
                    step2: "{A} + {C}"
                },
                solutionGuide: "1) חילוק: $ {Calc_A} ÷ {B} = {step1} $ <br/> 2) חיבור: $ {step1} + {C} = {step2} $ <br/> 3) כפל: $ {step2} × {D} = {Result} $"
            },

            {
                type: "multiChoiceRandomMath",
                question: "$ {Calc_A} ÷ ({B} × {C}) + {D} $",
                params: {
                    A: { range: [2, 6], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 3], type: "integer" },
                    D: { range: [3, 8], type: "integer" }
                },
                formula: "A+D",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "double"]
                },
                hint: "1) כפל בסוגריים 2) חילוק 3) חיבור",
                metadata: {
                    Calc_A: "{A} * {B} * {C}",
                    step1: "{B} * {C}",
                    step2: "{A}"
                },
                solutionGuide: "1) כפל: $ {B} × {C} = {step1} $ <br/> 2) חילוק: $ {Calc_A} ÷ {step1} = {step2} $ <br/> 3) חיבור: $ {step2} + {D} = {Result} $"
            },

            {
                type: "multiChoiceRandomMath",
                question: "$ {A} × {B} - ({Calc_C} ÷ {D} × {E})$",
                params: {
                    A: { range: [3, 6], type: "integer" },
                    B: { range: [4, 7], type: "integer" },
                    C: { range: [2, 4], type: "integer" },
                    D: { range: [2, 4], type: "integer" },
                    E: { range: [2, 3], type: "integer" }
                },
                formula: "A*B-(C*E)",
                distractors: {
                    strategies: ["off-by-ten", "half", "sign-flip"]
                },
                hint: "1) פתרו את הסוגריים: חילוק ואז כפל 2) כפל מחוץ לסוגריים 3) חיסור",
                metadata: {
                    Calc_C: "{C} * {D}",
                    step1: "{C}",
                    step2: "{C} * {E}",
                    step3: "{A} * {B}"
                },
                solutionGuide: "1) בסוגריים - חילוק: $ {Calc_C} ÷ {D} = {step1} $ <br/> 2) בסוגריים - כפל: $ {step1} × {E} = {step2} $ <br/> 3) כפל: $ {A} × {B} = {step3} $ <br/> 4) חיסור: $ {step3} - {step2} = {Result} $"
            }
        ]
    }
};