export const COIN_REWARDS = {
  CORRECT_ANSWER: {
    BASE: 3,
    STREAK_MULTIPLIER: 1,
  },
  SPEED_BONUS: {
    SUPER_FAST: { threshold: 5, coins: 3 },
    FAST: { threshold: 10, coins: 2 },
    NORMAL: { threshold: 15, coins: 1 },
  },
  COMPLETION_BONUS: {
    PERFECT_SCORE: 10,
    ABOVE_80_PERCENT: 5,
    ABOVE_60_PERCENT: 2,
  }
};

export const CHARACTER_PRICES = {
  NONE: {
    id: 'None',
    price: 0,
    name: 'No Character',
    preview: '/sprites/none/None.png'
  },
  DINO: { 
    id: 'Dino', 
    price: 1400, 
    name: 'Dino',
    preview: '/sprites/dino/Idle (1).png'
  },
  DOG: { 
    id: 'Dog', 
    price: 2000, 
    name: 'Dog',
    preview: '/sprites/dog/Idle (1).png'
  },
  CAT: { 
    id: 'Cat', 
    price: 2500, 
    name: 'Cat',
    preview: '/sprites/cat/Idle (1).png'
  },
};

export const getCharactersList = () => Object.values(CHARACTER_PRICES);