import React from 'react';

const MathVisualization = ({ type, config }) => {

  const containerStyle = "w-full max-w-full overflow-x-auto flex justify-center items-center";
  const contentStyle = "w-full sm:w-4/5 md:w-3/4 lg:w-2/3 p-2"; // Responsive width classes

  const renderGroups = ({ total, groupSize, caption, emoji }) => {
    const groups = [];
    for (let i = 0; i < total; i += groupSize) {
      groups.push(Array(Math.min(groupSize, total - i)).fill(0));
    }

    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="flex flex-wrap gap-2 justify-center">
            {groups.map((group, i) => (
              <div key={i} className="p-1 sm:p-2 border-2 border-blue-300 rounded-lg">
                <div className="flex gap-0.5 sm:gap-1">
                  {group.map((_, j) => (
                    emoji ? (
                      <span key={j} className="text-base sm:text-2xl">{emoji}</span>
                    ) : (
                      <div key={j} className="w-1 h-1 sm:w-12 sm:h-12 rounded-full bg-blue-200" />
                    )
                  ))}
                </div>
              </div>
            ))}
          </div>
          {caption && <p className="text-xs sm:text-sm text-gray-600 text-center mt-2">{caption}</p>}
        </div>
      </div>
    );
  };



  const renderRectangleStrip = ({ divisions, highlights, caption }) => {
    return (
      <div className="flex flex-col items-center gap-2">
        <div className="flex w-full max-w-md h-12 border-2 border-gray-300 rounded-lg overflow-hidden">
          {Array(divisions).fill(0).map((_, i) => {
            let bgColor = 'bg-white';
            if (highlights.first?.includes(i)) bgColor = 'bg-blue-200';
            if (highlights.second?.includes(i)) bgColor = 'bg-green-200';
            if (highlights.result?.includes(i)) bgColor = 'bg-purple-200';

            return (
              <div
                key={i}
                className={`flex-1 border-r border-black ${bgColor}`}
              />
            );
          })}
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };

  const renderRectangleGrid = ({ rows, cols, highlightCells = 0, caption }) => {
    const cells = Array(rows * cols).fill(0);
    return (
      <div className="flex flex-col items-center gap-2">
        <div
          className="grid gap-1 border-2 border-gray-300 p-2 rounded-lg"
          style={{
            gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
            width: `${cols * 2}rem`
          }}
        >
          {cells.map((_, i) => (
            <div
              key={i}
              className={`aspect-square ${i < highlightCells ? 'bg-red-200' : 'bg-gray-100'
                }`}
            />
          ))}
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };
  const renderNumberLine = ({ min, max, points = [], labels = [], caption, labelPosition = "top" }) => {

    return (
      <div className="flex flex-col items-center gap-2" dir="ltr">
        <div className="relative w-full max-w-md h-12">
          <div className="absolute w-full h-0.5 bg-gray-300 top-1/2" />
          {points.map((point, i) => (
            <div key={i}>
              <div
                className="absolute w-2 h-2 bg-blue-400 rounded-full"
                style={{
                  left: `${((point - min) / (max - min)) * 100}%`,
                  top: 'calc(50% - 4px)'
                }}
              />
              {labels[i] && (
                <div
                  className="absolute text-sm"
                  style={{
                    left: `${((point - min) / (max - min)) * 100}%`,
                    top: labelPosition === "top" ? "0" : "24px",
                    transform: 'translateX(-50%)'
                  }}
                >
                  {labels[i]}
                </div>
              )}
            </div>
          ))}
          <div className="absolute w-full flex justify-between top-8">
            <span>{min}</span>
            <span>{max}</span>
          </div>
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };

  const renderFractionCircles = ({ fractions, caption }) => {
    return (
      <div className="flex flex-col items-center gap-4">
        <div className="flex gap-8">
          {fractions.map((fraction, i) => {
            const [num, den] = fraction.split('/').map(Number);
            return (
              <div key={i} className="relative w-20 h-20">
                <svg viewBox="0 0 100 100" className="w-full h-full">
                  {Array(den).fill(0).map((_, j) => {
                    const angle = (360 / den) * j;
                    const highlighted = j < num;
                    return (
                      <path
                        key={j}
                        d={`M50,50 L${50 + 45 * Math.cos(angle * Math.PI / 180)},${50 + 45 * Math.sin(angle * Math.PI / 180)} A45,45 0 0,1 ${50 + 45 * Math.cos((angle + 360 / den) * Math.PI / 180)},${50 + 45 * Math.sin((angle + 360 / den) * Math.PI / 180)} Z`}
                        className={highlighted ? 'fill-blue-200 stroke-blue-400' : 'fill-gray-100 stroke-gray-300'}
                      />
                    );
                  })}
                </svg>
                <div className="absolute bottom-0 w-full text-center">{fraction}</div>
              </div>
            );
          })}
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };

  const renderCircle = ({ divisions, highlightSections = [], caption }) => {
    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="w-16 h-16 sm:w-32 sm:h-32 mx-auto">
            <svg viewBox="0 0 100 100" className="w-full h-full">
              {Array(divisions).fill(0).map((_, i) => {
                const angle = (360 / divisions) * i;
                const highlighted = highlightSections.includes(i + 1);
                return (
                  <path
                    key={i}
                    d={`M50,50 L${50 + 45 * Math.cos(angle * Math.PI / 180)},${50 + 45 * Math.sin(angle * Math.PI / 180)} A45,45 0 0,1 ${50 + 45 * Math.cos((angle + 360 / divisions) * Math.PI / 180)},${50 + 45 * Math.sin((angle + 360 / divisions) * Math.PI / 180)} Z`}
                    className={highlighted ? 'fill-blue-200 stroke-blue-400' : 'fill-gray-100 stroke-gray-300'}
                  />
                );
              })}
            </svg>
          </div>
          {caption && <p className="text-xs sm:text-sm text-gray-600 text-center mt-2">{caption}</p>}
        </div>
      </div>
    );
  };

  const renderCarousel = ({ people, caption }) => {
    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="flex gap-4 sm:gap-8 justify-center">
            {people.map((arrangement, i) => (
              <div key={i} className={`flex items-center justify-center w-16 h-16 sm:w-32 sm:h-32 rounded-full border-4 ${arrangement.color === 'blue' ? 'border-blue-400' : 'border-green-400'}`}>
                <div className="text-center">
                  {arrangement.names.map((name, j) => (
                    <div key={j} className="text-xs sm:text-sm">{name}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {caption && <p className="text-xs sm:text-sm text-gray-600 text-center mt-2">{caption}</p>}
        </div>
      </div>
    );
  };

  const renderBitCombinations = ({ bits, examples, highlight, caption }) => {
    return (
      <div className="flex flex-col items-center gap-2">
        <div className="font-mono text-sm">
          {examples.map((example, i) => (
            <div key={i} className="mb-1">{example}</div>
          ))}
        </div>
        {highlight && (
          <div className="mt-2">
            {highlight.choices.map((choice, i) => (
              <div key={i} className="flex items-center gap-2">
                <div className="w-3 h-3 bg-blue-400 rounded-full" />
                <span>{choice}</span>
              </div>
            ))}
          </div>
        )}
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };

  const renderFractionToDecimal = ({ fraction, decimal, caption }) => {
    return (
      <div className="flex flex-col items-center gap-4">
        <div className="flex items-center gap-8 text-2xl">
          <div>{fraction}</div>
          <div>=</div>
          <div>{decimal}</div>
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };

  const renderInequalitySteps = ({ steps, arrows, caption }) => {
    return (
      <div className="flex flex-col items-center gap-2">
        {steps.map((step, i) => (
          <div key={i} className="flex items-center gap-4">
            <div className="p-2 border border-gray-300 rounded bg-white">
              {step}
            </div>
            {i < steps.length - 1 && (
              <div className="text-gray-500">
                {arrows[i]}
              </div>
            )}
          </div>
        ))}
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };

  const renderGraph = ({ function: func, highlight = [], caption }) => {
    const gridLines = Array(9).fill(0);
    return (
      <div className="flex flex-col items-center gap-2">
        <div className="w-64 h-64 border border-gray-300 relative bg-white">
          {/* Grid lines */}
          {gridLines.map((_, i) => (
            <React.Fragment key={i}>
              <div className="absolute w-full h-px bg-gray-100" style={{ top: `${i * 12.5}%` }} />
              <div className="absolute w-px h-full bg-gray-100" style={{ left: `${i * 12.5}%` }} />
            </React.Fragment>
          ))}

          {/* Axes */}
          <div className="absolute w-full h-0.5 bg-gray-400 top-1/2" />
          <div className="absolute w-0.5 h-full bg-gray-400 left-1/2" />

          {/* Points */}
          {highlight.map(([x, y], i) => (
            <div key={i}>
              <div className="absolute w-2 h-2 bg-red-500 rounded-full"
                style={{ left: `${(x + 4) * 8}%`, bottom: `${(y + 4) * 8}%` }} />
              <div className="absolute text-xs"
                style={{ left: `${(x + 4) * 8}%`, bottom: `${(y + 4) * 8}%`, transform: 'translate(8px, 0)' }}>
                ({x}, {y})
              </div>
            </div>
          ))}
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };
  const renderTimeline = ({ points = [], caption }) => {
    if (!points || points.length === 0) return null;

    return (
      <div className="flex flex-col items-center gap-4">
        <div className="relative w-full max-w-md h-24">
          <div className="absolute w-full h-1 bg-blue-300 top-1/2" />
          <div className="absolute w-full flex justify-between top-0">
            {points.map((point, i) => (
              <div key={i} className="flex flex-col items-center">
                <div className="w-3 h-3 bg-blue-500 rounded-full" />
                <div className="mt-2 text-sm whitespace-pre-line">{point}</div>
              </div>
            ))}
          </div>
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };

  const renderPlaceValue = ({ number, values, caption }) => {
    return (
      <div className="flex flex-col items-center gap-4">
        <div className="text-4xl font-mono">{number}</div>
        <div className="flex gap-4">
          {values.map((value, i) => (
            <div key={i} className="flex flex-col items-center gap-2 p-4 border-2 border-blue-200 rounded-lg">
              <div className="text-2xl font-bold text-blue-600">{value.digit}</div>
              <div className="text-sm text-gray-600">{value.place}</div>
              <div className="text-sm font-mono">{value.value}</div>
            </div>
          ))}
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };

  const renderDivision = ({ dividend, divisor, result, steps, caption }) => {
    return (
      <div className="flex flex-col items-center gap-4">
        <div className="flex items-center gap-8">
          <div className="flex gap-2 items-center">
            <span className="text-2xl">{dividend}</span>
            <span className="text-2xl">÷</span>
            <span className="text-2xl">{divisor}</span>
            <span className="text-2xl">=</span>
            <span className="text-2xl">{result}</span>
          </div>
        </div>
        <div className="border-2 border-gray-200 rounded-lg p-4">
          <div className="font-mono">
            {steps.map((step, i) => (
              <div key={i} className="mb-2">{step}</div>
            ))}
          </div>
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };


  const renderGrid = ({ rows, cols, highlight = [], caption }) => {
    return (
      <div className="flex flex-col items-center gap-4">
        <div className="inline-grid gap-1 p-4 bg-white rounded-lg border-2 border-gray-200"
          style={{ gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))` }}>
          {Array.from({ length: rows * cols }, (_, index) => {
            const row = Math.floor(index / cols) + 1;
            const col = (index % cols) + 1;
            const isHighlighted = highlight.some(([r, c]) => r === row && c === col);

            return (
              <div
                key={index}
                className={`w-8 h-8 flex items-center justify-center border ${isHighlighted ? 'bg-blue-200 border-blue-400' : 'border-gray-300'
                  }`}
              >
                {row},{col}
              </div>
            );
          })}
        </div>
        {caption && <p className="text-sm text-gray-600 text-center">{caption}</p>}
      </div>
    );
  };


  const renderDistribution = ({ items, recipients, combinations = null, caption }) => {
    const generateCombinations = () => {
      if (combinations) return combinations;

      if (recipients.length === 2) {
        const result = [];
        const n = items.length;
        for (let i = 0; i < Math.pow(2, n); i++) {
          const binary = i.toString(2).padStart(n, '0');
          const dist = { [recipients[0]]: [], [recipients[1]]: [] };
          binary.split('').forEach((bit, idx) => {
            dist[recipients[bit === '0' ? 0 : 1]].push(items[idx]);
          });
          result.push(dist);
        }
        return result;
      }

      const result = [];
      const firstItemChoices = items[0];
      const secondItemChoices = items[1];

      firstItemChoices.forEach(item1 => {
        secondItemChoices.forEach(item2 => {
          const dist = {};
          recipients.forEach(r => dist[r] = []);
          dist[recipients[0]] = [item1];
          dist[recipients[1]] = [item2];
          result.push(dist);
        });
      });

      return result;
    };

    const allCombinations = generateCombinations();

    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="flex flex-col gap-4">
            {/* Items list */}
            <div className="flex flex-wrap gap-2 items-center mb-4">
              <div className="text-base font-bold ml-2">פריטים:</div>
              <div className="flex gap-2">
                {Array.isArray(items[0]) ?
                  items.map((group, i) => (
                    <div key={i} className="flex gap-2">
                      {group.map((item, j) => (
                        <div key={j} className="text-2xl w-12 h-12 flex items-center justify-center">{item}</div>
                      ))}
                    </div>
                  )) :
                  items.map((item, i) => (
                    <div key={i} className="text-2xl w-12 h-12 flex items-center justify-center">{item}</div>
                  ))
                }
              </div>
            </div>

            {/* Combinations */}
            <div className="flex flex-wrap gap-4 justify-center sm:justify-start">
 {allCombinations.map((dist, i) => (
   <div key={i} className="w-[calc(50%-0.5rem)] sm:w-[calc(33.33%-0.67rem)] flex flex-col items-center border-2 border-blue-200 rounded-lg p-4">
                  {recipients.map((recipient, j) => (
                    <div key={j} className="flex-1 w-full">
                      <div className="font-bold text-center mb-2">{recipient}</div>
                      <div className="flex justify-center">
                        {dist[recipient].map((item, k) => (
                          <div key={k} className="text-xl w-12 h-12 flex items-center justify-center">{item}</div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          {caption && <p className="text-xs sm:text-sm text-gray-600 text-center mt-2">{caption}</p>}
        </div>
      </div>
    );
  };

  const renderBlocks = ({ operation, firstNumber, secondNumber, result, caption }) => {
    const operationSymbol = operation === 'addition' ? '+' : '-';
    
    const renderNumberBlocks = ({ tens, ones }, label) => (
      <div className="flex flex-col gap-2">
        <div className="text-center font-bold text-gray-600">{label}</div>
        <div className="flex gap-4 justify-center">
          {/* Tens column */}
          <div className="flex flex-col items-center">
            <div className="text-sm text-gray-500 mb-1">עשרות</div>
            <div className="flex flex-wrap gap-1 justify-center w-24">
              {Array(tens).fill(0).map((_, i) => (
                <div key={i} className="w-8 h-8 bg-blue-400 rounded-lg flex items-center justify-center text-white font-bold">
                  10
                </div>
              ))}
            </div>
          </div>
          
          {/* Ones column */}
          <div className="flex flex-col items-center">
            <div className="text-sm text-gray-500 mb-1">אחדות</div>
            <div className="flex flex-wrap gap-1 justify-center w-12">
              {Array(ones).fill(0).map((_, i) => (
                <div key={i} className="w-4 h-4 bg-blue-200 rounded-full" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="flex flex-col gap-4 items-center">
            {renderNumberBlocks(firstNumber, "מספר ראשון")}
            <div className="text-2xl font-bold text-gray-600">{operationSymbol}</div>
            {renderNumberBlocks(secondNumber, "מספר שני")}
            <div className="w-16 border-t-2 border-gray-400" />
            {renderNumberBlocks(result, "תוצאה")}
            {caption && (
              <div 
                className="text-sm text-gray-600 text-center mt-2"
                dangerouslySetInnerHTML={{ __html: caption }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderVertical = ({ firstNumber, secondNumber, operation, steps }) => {
    const maxLength = Math.max(
      firstNumber.toString().length,
      secondNumber.toString().length
    );

    const padNumber = (num) => num.toString().padStart(maxLength, ' ');
    const firstPadded = padNumber(firstNumber);
    const secondPadded = padNumber(secondNumber);
    const result = operation === 'subtraction' ? 
      firstNumber - secondNumber : 
      firstNumber + secondNumber;
    const resultPadded = padNumber(result);

    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="flex flex-col items-center gap-4">
            {/* Numbers display */}
            <div className="font-mono text-2xl whitespace-pre" dir="ltr">
              <div className="text-right">{firstPadded}</div>
              <div className="flex items-center">
                <span>{operation === 'subtraction' ? '-' : '+'}</span>
                <span>{secondPadded}</span>
              </div>
              <div className="border-t-2 border-gray-400" />
              <div className="text-right">{resultPadded}</div>
            </div>

            {/* Steps */}
            <div className="flex flex-col gap-2 text-right w-full" dir="rtl">
              {steps.map((step, i) => (
                <div key={i} className="flex items-center gap-2">
                  <div className="w-2 h-2 bg-blue-400 rounded-full" />
                  <span>{step}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderNumberSum = ({ number, caption, rtl = true }) => {
    const digits = number.toString().split('');
    const sum = digits.reduce((acc, digit) => acc + parseInt(digit), 0);
    
    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="flex flex-col items-center gap-4" dir={rtl ? "rtl" : "ltr"}>
            {/* Original number */}
            <div className="text-3xl font-mono">{number}</div>
            
            {/* Arrow and sum */}
            <div className="flex items-center gap-4">
              <div className="text-2xl">{rtl ? "←" : "→"}</div>
              <div className="flex items-center gap-2">
                {digits.map((digit, i) => (
                  <React.Fragment key={i}>
                    <span className="text-2xl font-mono">{digit}</span>
                    {i < digits.length - 1 && <span className="text-2xl">+</span>}
                  </React.Fragment>
                ))}
                <span className="text-2xl">=</span>
                <span className="text-2xl font-mono">{sum}</span>
              </div>
            </div>
            
            {caption && (
              <p 
                className="text-sm text-gray-600 text-center mt-2"
                dangerouslySetInnerHTML={{ __html: caption }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderNumberHighlight = ({ number, highlightPosition, caption }) => {
    const digits = number.toString().split('');
    const getHighlightClass = (index) => {
      switch (highlightPosition) {
        case 'ones':
          return index === digits.length - 1 ? 'bg-blue-200' : '';
        case 'tens':
          return index === digits.length - 2 ? 'bg-blue-200' : '';
        case 'hundreds':
          return index === digits.length - 3 ? 'bg-blue-200' : '';
        default:
          return '';
      }
    };

    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="flex justify-center gap-1 text-4xl font-mono" dir="ltr">
            {digits.map((digit, i) => (
              <div 
                key={i} 
                className={`w-12 h-12 flex items-center justify-center rounded ${getHighlightClass(i)}`}
              >
                {digit}
              </div>
            ))}
          </div>
          {caption && (
            <p 
              className="text-sm text-gray-600 text-center mt-2"
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          )}
        </div>
      </div>
    );
  };


  const renderGeomSquare = ({ sideLength, corners = ['A', 'B', 'C', 'D'], showPerimeter = false, showArea = false, caption }) => {
    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="relative w-64 h-64">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              {/* Square */}
              <rect 
                x="40" y="40" 
                width="120" height="120" 
                className="fill-blue-50 stroke-blue-500 stroke-2"
              />
              
              {/* Corner Labels */}
              <text x="35" y="35" className="text-sm">{corners[0]}</text>
              <text x="165" y="35" className="text-sm">{corners[1]}</text>
              <text x="165" y="165" className="text-sm">{corners[2]}</text>
              <text x="35" y="165" className="text-sm">{corners[3]}</text>
              
              {/* Measurements */}
              <text x="100" y="30" className="text-sm" textAnchor="middle">{sideLength} ס״מ</text>
              <text x="30" y="100" className="text-sm" textAnchor="middle" transform="rotate(-90, 30, 100)">{sideLength} ס״מ</text>
              
              {showArea && (
                <text x="100" y="100" className="text-sm font-bold text-blue-600" textAnchor="middle">
                  שטח: {sideLength * sideLength} סמ״ר
                </text>
              )}
              
              {showPerimeter && (
                <text x="100" y="180" className="text-sm text-green-600" textAnchor="middle">
                  היקף: {sideLength * 4} ס״מ
                </text>
              )}
            </svg>
          </div>
          {caption && <p className="text-sm text-gray-600 text-center mt-2">{caption}</p>}
        </div>
      </div>
    );
  };

  const renderGeomRectangle = ({ length, width, corners = ['A', 'B', 'C', 'D'], showPerimeter = false, showArea = false, caption }) => {
    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="relative w-64 h-64">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              {/* Rectangle */}
              <rect 
                x="40" y="60" 
                width="120" height="80" 
                className="fill-blue-50 stroke-blue-500 stroke-2"
              />
              
              {/* Corner Labels */}
              <text x="35" y="55" className="text-sm">{corners[0]}</text>
              <text x="165" y="55" className="text-sm">{corners[1]}</text>
              <text x="165" y="145" className="text-sm">{corners[2]}</text>
              <text x="35" y="145" className="text-sm">{corners[3]}</text>
              
              {/* Measurements */}
              <text x="100" y="50" className="text-sm" textAnchor="middle">{length} ס״מ</text>
              <text x="30" y="100" className="text-sm" textAnchor="middle" transform="rotate(-90, 30, 100)">{width} ס״מ</text>
              
              {showArea && (
                <text x="100" y="100" className="text-sm font-bold text-blue-600" textAnchor="middle">
                  שטח: {length * width} סמ״ר
                </text>
              )}
              
              {showPerimeter && (
                <text x="100" y="160" className="text-sm text-green-600" textAnchor="middle">
                  היקף: {2 * (length + width)} ס״מ
                </text>
              )}
            </svg>
          </div>
          {caption && <p className="text-sm text-gray-600 text-center mt-2">{caption}</p>}
        </div>
      </div>
    );
  };

  const renderGeomTriangle = ({ base, height, corners = ['A', 'B', 'C'], showArea = false, caption }) => {
    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="relative w-64 h-64">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              {/* Triangle */}
              <path 
                d={`M40,140 L160,140 L100,60 Z`} 
                className="fill-blue-50 stroke-blue-500 stroke-2"
              />
              
              {/* Corner Labels */}
              <text x="35" y="155" className="text-sm">{corners[0]}</text>
              <text x="165" y="155" className="text-sm">{corners[1]}</text>
              <text x="100" y="55" className="text-sm">{corners[2]}</text>
              
              {/* Height Line */}
              <line 
                x1="100" y1="60" 
                x2="100" y2="140" 
                className="stroke-gray-400 stroke-dasharray-2"
              />
              
              {/* Measurements */}
              <text x="100" y="160" className="text-sm" textAnchor="middle">{base} ס״מ</text>
              <text x="115" y="100" className="text-sm">{height} ס״מ</text>
              
              {showArea && (
                <text x="100" y="110" className="text-sm font-bold text-blue-600" textAnchor="middle">
                  שטח: {(base * height) / 2} סמ״ר
                </text>
              )}
            </svg>
          </div>
          {caption && <p className="text-sm text-gray-600 text-center mt-2">{caption}</p>}
        </div>
      </div>
    );
  };

  const renderDivisionGroups = ({ total, groups, emoji, caption }) => {
    const itemsPerGroup = Math.floor(total / groups);
    const groupedItems = Array(groups).fill().map(() => Array(itemsPerGroup).fill(0));

    return (
      <div className={containerStyle}>
        <div className={contentStyle}>
          <div className="flex flex-wrap gap-4 justify-center">
            {groupedItems.map((group, i) => (
              <div key={i} className="p-2 border-2 border-blue-300 rounded-lg">
                <div className="flex gap-1">
                  {group.map((_, j) => (
                    <span key={j} className="text-2xl">{emoji}</span>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {caption && <p className="text-sm text-gray-600 text-center mt-2">{caption}</p>}
        </div>
      </div>
    );
  };

  const visualizations = {
    'geom-square': renderGeomSquare,
    'geom-rectangle': renderGeomRectangle,
    'geom-triangle': renderGeomTriangle,
    vertical: renderVertical,
    blocks: (config) => {
      if (Array.isArray(config)) {
        return (
          <div className="flex flex-col gap-6">
            {config.map((blockConfig, i) => renderBlocks(blockConfig))}
          </div>
        );
      }
      return renderBlocks(config);
    },
    grid: renderGrid,
    groups: renderGroups,
    rectangleStrip: renderRectangleStrip,
    rectangleGrid: renderRectangleGrid,
    numberLine: renderNumberLine,
    fractionCircles: renderFractionCircles,
    circle: renderCircle,
    carousel: renderCarousel,
    bitCombinations: renderBitCombinations,
    fractionToDecimal: renderFractionToDecimal,
    inequalitySteps: renderInequalitySteps,
    graph: renderGraph,
    timeline: renderTimeline,
    placeValue: renderPlaceValue,
    division: renderDivision,
    distribution: renderDistribution,
    'number-sum': renderNumberSum,
    'number-highlight': renderNumberHighlight,
    'division-groups': renderDivisionGroups,
  };

  const render = visualizations[type];
  return render ? render(config) : null;
};

export default MathVisualization;