export const learningContent = {
    "יחס ישר, הפוך ובריבוע": {
        tags: ['כיתה ז׳', 'יחסים',],
        relatedLinks: [
            { title: "תרגול יחסים", url: "/?tags=יחסים", description: "תרגול בעיות יחסים" }
        ],
        getStarted: [
            "**סוגי יחסים בין משתנים:**",
            "<b>יחס ישר:</b> כשמשתנה אחד גדל פי n, השני גדל פי n",
            "דוגמה: y = 2x, אם x גדל פי 3, גם y יגדל פי 3",
            "",
            "<b>יחס הפוך:</b> כשמשתנה אחד גדל פי n, השני קטן פי n",
            "דוגמה: y = $\\frac{6}{x} $, אם x גדל פי 2, y יקטן פי 2",
            "",
            "<b>יחס בריבוע:</b> כשמשתנה אחד גדל פי x, השני גדל פי x²",
            "דוגמה: y = x², אם x גדל פי 2, y יגדל פי 4",
            "",
            // Start of Selection
            "**תכונות של יחסים:**",
            "- ביחס ישר, אם אחד משתנה, השני משתנה פי אותו יחס",
            "- ביחס הפוך, אם אחד גדל, השני קטן פי אותו יחס",
            "- ביחס ריבועי, שינוי במשתנה אחד גורם לשינוי בריבוע במשתנה השני"
        ],
        examples: [
            // Start of Selection
            // Start of Selection
            {
                question: "נתון $y = x²$. אם $x$ גדל פי 3, מה קורה ל-$y$?",
                solution: "1) נתחיל עם $y = x²$<br />2) אם $x$ גדל פי 3, אז $y = (3x)² = 9x²$<br />3) לכן $y$ גדל פי 9"
            },
            {
                question: "אם $y = 16x$, כאשר $x$ גדל פי 4, מה קורה ל-$y$?",
                solution: "מאחר ו-$y$ משתנה ביחס ישר ל-$x$, אז $y$ גם יגדל פי 4."
            },
            {
                question: "אם יש לנו את ההתאמה $m = \\frac{10}{n} $, וכאשר $n$ גדל פי 5, מה קורה ל-$m$?",
                solution: "מכיוון ש-$m$ נמצא ביחס הפוך ל-$n$, כאשר $n$ גדל פי 5, $m$ יקטן פי 5."
            },
            {
                question: "נתון $a = \\frac{1}{b^2} $. אם $b$ גדל פי 2, מה קורה ל-$a$?",
                solution: "1) נתחיל עם $a = \\frac{1}{b^2} $<br />2) אם $b$ גדל פי 2, נוכל לכתוב: $\\frac{1}{(2b)^2} $<br />3) נפשט את המכנה: $\\frac{1}{4b^2} $<br />4) כלומר $a$ החדש = $\\frac{1}{4b^2} = \\frac{1}{4} × \\frac{1}{b^2} $<br />5) לכן $a$ קטן פי 4"
            },
        ],
        quiz: [
            {
                question: "אם $y = {A}x^2$ ו-x גדל פי {B}, פי כמה יגדל y?",
                type: "multiChoiceRandomMath",
                params: {
                  A: {
                    range: [2, 5],    // Coefficient of x^2
                    type: "integer"
                  },
                  B: {
                    range: [2, 4],    // Growth factor
                    type: "integer"
                  }
                },
                formula: "B*B",  // When x grows by B, y grows by B^2 (squared relationship)
                metadata: {
                  originalTerm: "{A}",
                  afterGrowth: "{A}*{B}*{B}",
                  ratio: "{B}*{B}"
                },
                distractors: {
                  strategies: ["off-by-two", "double", "half"]  // Common mistakes in square relationships
                },
                hint: "כשמשתנה בחזקת 2 גדל פי מספר מסוים, התוצאה גדלה פי ריבוע אותו מספר",
                solutionGuide: "יחס בריבוע אומר ש-y גדל לפי ריבוע הגידול ב-x. התשובה היא {Result}. והנה החישוב המלא: נציב את השינוי ב-$x$:<br />אם $x$ גדל פי {B}, נכפול אותו ב-{B}<br />$y = {A}({B}x)^2 = {A}({B}^2x^2) = {A}({ratio}x^2)$<br />הביטוי המקורי היה $ {A}x^2$<br />לכן הביטוי גדל פי {Result}"
              },
              {
                question: "אם $y$ ו-$x$ נמצאים ביחס ישר, וכאשר $x={firstX} $ אז $y={firstY} $, מה יהיה $y$ כאשר $x$ קטן פי {B}?",
                type: "multiChoiceRandomMath",
                params: {
                  A: {
                    range: [6, 12],
                    type: "integer"
                  },
                  B: {
                    range: [2, 5],
                    type: "integer"
                  },
                  C: {
                    range: [2, 6],
                    type: "integer"
                  }
        
                },
                formula: "A",
                metadata: {
                  firstY: "{A}*{B}",
                  ratio: "{B}",
                  firstX: "{C}*{B}"
                },
                distractors: {
                  strategies: ["off-by-one", "half", "double"]
                },
                hint: "  $y$ ו- $x$  ביחס ישר, היחס בין המספרים נשאר קבוע.",
                solutionGuide: "1) ביחס ישר היחס נשמר קבוע, לכן $y$ יקטן פי {ratio} <br/>2) $y= {firstY}\\div{ratio} = {Result} $"
              },
              {
                // First question - inverse relationship
                question: "אם $y = \\frac{{A}}{x} $ ו-$x$ גדל פי {B}, פי כמה יקטן $y$?",
                type: "multiChoiceRandomMath",
                params: {
                  A: {
                    range: [12, 24],    // Makes division work nicely
                    type: "integer"
                  },
                  B: {
                    range: [2, 4],      // Growth factor
                    type: "integer"
                  }
                },
                formula: "B",  // In inverse relationship, if x grows by B, y shrinks by B
                metadata: {
                  beforeChange: "{A}",
                  afterChange: "{A}/{B}"
                },
                distractors: {
                  strategies: ["off-by-two", "double", "off-by-one"]
                },
                hint: "ביחס הפוך, כאשר גורם אחד גדל פי מספר מסוים, הגורם השני קטן פי אותו מספר",
                solutionGuide: "יחס הפוך הוא יחס שבו כשאחד הגדל, השני קטן פי אותו יחס.<br/>לכן, אם x גדל פי {B}, y קטן פי {Result}",
              },
        
              
        
              {
                // Third question - squared relationship with division
                question: "אם $y = {A}x^2$ ו-$x$ קטן פי {B}, פי כמה יקטן $y$?",
                type: "multiChoiceRandomMath",
                params: {
                  A: {
                    range: [2, 5],     // Coefficient of x^2
                    type: "integer"
                  },
                  B: {
                    range: [2, 3],     // Reduction factor
                    type: "integer"
                  }
                },
                formula: "B*B",  // When x shrinks by B, y shrinks by B^2
                metadata: {
                  originalTerm: "{A}",
                  ratio: "{B}*{B}"
                },
                distractors: {
                  strategies: ["off-by-two", "off-by-one", "double"]
                },
                hint: "כשמשתנה בחזקת 2 קטן פי מספר מסוים, התוצאה קטנה פי ריבוע אותו מספר",
                    // Start of Selection
                    solutionGuide: "**יחס בריבוע** מתאר מצב שבו שינוי במשתנה אחד גורם לשינוי בריבוע במשתנה השני.<br />הנה חישוב מלא:<br />נציב את השינוי ב-$x$:<br />אם $x$ קטן פי {B}, נחלק אותו ב-{B}<br />$y = {A}(\\frac{x}{{{B}}})^2 = {A}(\\frac{x^2}{{{ratio}}})= \\frac{{{A}x^2}}{{{ratio}}} $<br />הביטוי המקורי היה $ {A}x^2$<br />לכן הביטוי קטן פי {Result}"
              },
              {
                question: "אם $y = {A}x^2$ ו-$x$ גדל פי {B}, פי כמה יגדל $y$?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 5],    
                        type: "integer"
                    },
                    B: {
                        range: [2, 4],    
                        type: "integer"
                    }
                },
                formula: "B*B",  
                metadata: {
                    originalTerm: "{A}",
                    ratio: "{B}*{B}"
                },
                distractors: {
                    strategies: ["off-by-two", "double", "off-by-ten"]
                },
                hint: "כשמשתנה בחזקת 2 גדל פי מספר מסוים, התוצאה גדלה פי ריבוע אותו מספר",
                solutionGuide: "כאשר יש יחס בריבוע, ו-$x$ גדל פי {B}, $y$ גדל פי ריבוע הגידול, כלומר פי {Result}"
            },
            
            {
                question: "אם $y = \\frac{{A}}{x} $ ו-$x$ קטן פי {B}, פי כמה יגדל $y$?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [12, 24],    
                        type: "integer"
                    },
                    B: {
                        range: [2, 4],      
                        type: "integer"
                    }
                },
                formula: "B",  
                metadata: {
                    beforeChange: "{A}",
                    afterChange: "{A}*{B}"
                },
                distractors: {
                    strategies: ["off-by-two", "off-by-ten", "off-by-one"]
                },
                hint: "ביחס הפוך, כאשר גורם אחד קטן פי מספר מסוים, הגורם השני גדל פי אותו מספר",
                solutionGuide: "כאשר יש יחס הפוך, ו-$x$ קטן פי {B}, $y$ גדל פי {Result}"
            },
            
            {
                question: "אם $y$ ו-$x$ נמצאים ביחס ישר, וכאשר $x={A} $ אז $y={firstY} $, מה יהיה $y$ כאשר $x$ גדל פי {B}?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [6, 12],      
                        type: "integer"
                    },
                    B: {
                        range: [2, 3],      
                        type: "integer"
                    },
                    C: {
                        range: [2, 4],      
                        type: "integer"
                    }
                },
                formula: "A*B*C",  
                metadata: {
                    firstY: "{A}*{C}",
                    ratio: "{C}",
                    finalY: "{A}*{B}*{C}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-three", "double"]
                },
                hint: "ביחס ישר, אם $x$ גדל פי מספר מסוים, גם $y$ יגדל פי אותו מספר",
                solutionGuide: "כאשר היחס ישר ו-$x$ גדל פי {B}, גם $y$ גדל פי {B} ולכן $y$ יהיה {Result}"
            },
            {
                question: "אם $x$ ו-$y$ נמצאים ביחס ישר וכאשר $x = 2$ אז $y = 8$, מה יהיה $y$ כאשר $x = 6$?",
                type: "multiplechoice",
                options: ["24", "16", "20", "12"],
                hint: "מצאו פי כמה $y$ גדול מ-$x$ במקרה הראשון, ואז השתמשו באותו יחס",
                solutionGuide: "כש-$x=2$ אז $y=8$, כלומר $y$ גדול פי 4 מ-$x$.<br />לכן כש $x=6$, גם כאן $y$ יהיה גדול פי 4:<br />$y = 6 × 4 = 24$"
            },
            {
                question: "אם $y = 2x^2$ ו-$x$ גדל פי 3, פי כמה יגדל $y$?",
                type: "multiplechoice",
                options: ["פי 9", "פי 6", "פי 18", "פי 12"],
                hint: "כשמשתנה בחזקת 2 גדל פי מספר מסוים, התוצאה גדלה פי ריבוע אותו מספר",
                solutionGuide: "נציב את השינוי ב-$x$:<br />אם $x$ גדל פי 3, נכפול אותו ב-3<br />$y = 2(3x)^2 = 2(9x^2) = 18x^2$<br />הביטוי המקורי היה $2x^2$<br />לכן $y$ גדל פי $\\frac{18x^2}{2x^2} = 9$"
            },
            {
                question: "אם $x = \\frac{1}{y} $, ו-$y$ קטן פי 4, מה קורה ל-$x$?",
                type: "multiplechoice",
                options: ["גדל פי 4", "גדל פי 2", "קטן פי 4", "קטן פי 2"],
                hint: "כשמחלקים 1 במספר קטן יותר, התוצאה גדלה",
                solutionGuide: "אם $y$ קטן פי 4, אז $\\frac{1}{y} $ גדל פי 4<br />לכן $x$ גדל פי 4"
            },
            {
                question: "אם $x$ ו-$y$ נמצאים ביחס ישר וכאשר $x=3$ אז $y=6$, מה יהיה $y$ כאשר $x=9$?",
                type: "multiplechoice",
                options: ["18", "12", "15", "24"],
                hint: "ביחס ישר, היחס בין המספרים נשאר קבוע. מצאו את היחס בין $y$ ל-$x$ במקרה הראשון",
                solutionGuide: "1) ביחס ישר, היחס בין המספרים נשמר קבוע<br/>2) כש-$x=3$, $y=6$ - כלומר $y$ פי 2 מ-$x$<br/>3) נוכל לכתוב: $y = 2x$<br/>4) לכן כש-$x=9$:<br/>$y = 2 × 9 = 18$"
            },
            {
                question: "אם $y = 3x$ ו-$x$ גדל פי 5, פי כמה יגדל $y$?",
                type: "multiplechoice",
                options: ["פי 5", "פי 3", "פי 8", "פי 15"],
                hint: "כשיש יחס ישר בין שני משתנים, כל שינוי באחד גורם לאותו שינוי בשני",
                solutionGuide: "מכיוון ש-$y$ ו-$x$ נמצאים ביחס ישר (כי $y = 3x$), אם $x$ גדל פי 5, גם $y$ יגדל פי 5, כי כל שינוי ב-$x$ מתבטא ישירות ב-$y$."
            },
            {
                question: "אם $z = 2w$ ו-$w$ קטן פי 3, מה קורה ל-$z$?",
                type: "multiplechoice",
                options: ["קטן פי 3", "גדל פי 3", "קטן פי 6", "גדל פי 6"],
                hint: "כשיש יחס ישר בין שני משתנים, כל שינוי באחד גורם לאותו שינוי בשני",
                solutionGuide: "מכיוון ש-$z$ ו-$w$ נמצאים ביחס ישר ($z = 2w$), כאשר $w$ קטן פי 3, גם $z$ יקטן פי 3. לדוגמה: אם $w = 6$ אז $z = 12$, וכש-$w$ קטן פי 3 ל-2, גם $z$ יקטן פי 3 ל-4."
            },


        ]
    }
}; 