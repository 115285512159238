import React from 'react';
import { Box, VStack, Button, Text } from '@chakra-ui/react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DraggableItem } from './DraggableItem';

export const DraggableList = ({
  items,
  droppableId,
  onDragEnd,
  onSubmit,
  showFeedback,
  renderRightSide,
  loading
}) => {
  if (loading || !items?.length) {
    return (
      <Box textAlign="center" p={4}>
        <Text>Loading...</Text>
      </Box>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <VStack spacing={2} w="full" px={0}>
        <Box 
          bg="white" 
          p={0}
          borderRadius="lg" 
          w="full" 
          maxW={["100%", "100%", "90%", "800px"]}
          mx="auto"
          my={0}
        >
          {renderRightSide ? (
            renderRightSide(
              <Box flex="1">
                <Droppable droppableId={droppableId}>
                  {(provided) => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      minH={`${items.length * 90}px`}
                    >
                      {items.map((item, index) => (
                        <Box key={index} position="relative">
                          <DraggableItem
                            item={item}
                            index={index}
                            itemKey={typeof item === 'string' ? item : item.id}
                          />
                        </Box>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>
            )
          ) : (
            <Box flex="1">
              <Droppable droppableId={droppableId}>
                {(provided) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    minH={`${items.length * 90}px`}
                  >
                    {items.map((item, index) => (
                      <Box key={index} position="relative">
                        <DraggableItem
                          item={item}
                          index={index}
                          itemKey={typeof item === 'string' ? item : item.id}
                        />
                      </Box>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
          )}
        </Box>

        <Button
          mt={2}
          bg="blue.500"
          color="white"
          onClick={() => !showFeedback && onSubmit(items)}
          maxW="400px"
          w="full"
          size="lg"
          fontWeight="bold"
          height="60px"
          fontSize="xl"
          _hover={{ bg: 'blue.600' }}
        >
          בדוק תשובה
        </Button>
      </VStack>
    </DragDropContext>
  );
}; 