import { motion, AnimatePresence } from 'framer-motion';
import { Text } from '@chakra-ui/react';

export const CoinAnimation = ({ coins }) => {
  if (!coins) return null;

  return (
    <AnimatePresence>
      <motion.div
       // key={Date.now()} // Force re-render on new coins
        initial={{ opacity: 0, y: 0, scale: 0.5 }}
        animate={{ 
          opacity: [0, 1, 1, 0],
          y: [-50, -150],
          scale: [0.5, 1.5, 1.5, 0.5],
        }}
        transition={{ 
          duration: 1.5,
          ease: "easeOut"
        }}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          pointerEvents: 'none',
        }}
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          color="yellow.400"
          textShadow="2px 2px 8px rgba(255,215,0,0.6)"
        >
          +{coins} 💰
        </Text>
      </motion.div>
    </AnimatePresence>
  );
}; 