import React, { useEffect } from 'react';
import { Box, Text, VStack, Heading, SlideFade } from '@chakra-ui/react';
import MathVisualization from './MathVisualization';

export const ExampleCard = ({ example, index }) => {
  useEffect(() => {
    window.MathJax?.typeset();
  }, [example]);

  return (
    <SlideFade in={true} delay={index * 0.1}>
      <Box bg="white" p={6} borderRadius="xl" boxShadow="lg" border="2px solid" borderColor="blue.100">
        <VStack align="stretch" spacing={4}>
          <Heading size="md" color="blue.600" dangerouslySetInnerHTML={{ __html: `שאלה: ${example.question}` }} />
          <Box bg="blue.50" p={4} borderRadius="md">
            <Text dangerouslySetInnerHTML={{ __html: `<strong>פתרון:</strong> <br/>${example.solution}` }} />
          </Box>
          {example.drawing && (
            <Box mt={4}>
              <MathVisualization type={example.drawing.type} config={example.drawing} />
            </Box>
          )}
        </VStack>
      </Box>
    </SlideFade>
  );
}; 