export const learningContent = {
    "שטח והיקף": {
        tags: ['כיתה ד׳', 'כיתה ה׳', 'גיאומטריה'],
        relatedLinks: [
            { title: "תרגול שטח", url: "/?tags=גיאומטריה", description: "תרגול נוסחאות שטח" },
            { title: "תרגול היקף", url: "/?tags=גיאומטריה", description: "תרגול נוסחאות היקף וצורות" }
        ],

        getStarted: [
            "**מה זה שטח והיקף?**",
            "",
            "• <b>היקף:</b>",
            "סכום אורכי הצלעות של הצורה",
            "כמו המרחק שנעבור אם נלך על גבול הצורה",
            "",
            "• <b>שטח:</b>",
            "כמה מקום הצורה תופסת",
            "כמה משבצות של 1×1 יכולות להיכנס בתוך הצורה",
            "",
            "**נוסחאות חשובות:**",
            "",
            "<b>ריבוע (כל הצלעות שוות):</b>",
            "היקף = צלע × 4",
            "שטח = צלע × צלע",
            "",
            "<b>מלבן (צלעות נגדיות שוות):</b>",
            "היקף = (אורך + רוחב) × 2",
            "שטח = אורך × רוחב",
            "",
            "<b>משולש:</b>",
            "היקף = צלע א + צלע ב + צלע ג",
            "שטח = (בסיס × גובה) ÷ 2",
            "",
            "**טיפים חשובים:**",
            "שטח תמיד במטרים רבועים (מ״ר)",
            "היקף תמיד במטרים (מ׳)",
            "בריבוע - כל הצלעות שוות",
            "במלבן - צלעות נגדיות שוות"
        ],
        examples: [
            {
                question: "מה ההיקף של ריבוע שצלעו 5 ס״מ?",
                solution: "היקף ריבוע = צלע × 4<br/>היקף = $5 × 4 = 20$ ס״מ",
                drawing: {
                    type: "geom-square",
                    sideLength: 5,
                    showPerimeter: true,
                    caption: "ריבוע 5×5 ס״מ"
                }
            },
            {
                question: "מה השטח של מלבן שאורכו 6 ס״מ ורוחבו 4 ס״מ?",
                solution: "שטח מלבן = אורך × רוחב<br/>שטח = 6$ × 4 = $24 סמ״ר",
                drawing: {
                    type: "geom-rectangle",
                    length: 6,
                    width: 4,
                    showArea: true,
                    caption: "מלבן 6×4 ס״מ"
                }
            },
            {
                question: "מה השטח של משולש שבסיסו 8 ס״מ וגובהו 6 ס״מ?",
                solution: "שטח משולש = (בסיס × גובה) ÷ 2<br/>שטח = $(8 × 6) ÷ 2 = 48 ÷ 2 = 24$ סמ״ר",
                drawing: {
                    type: "geom-triangle",
                    base: 8,
                    height: 6,
                    showArea: true,
                    caption: "משולש עם בסיס 8 ס״מ וגובה 6 ס״מ"
                }
            }
        ],
        quiz: [
            {
                question: "מה ההיקף של ריבוע שצלעו {A} ס״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 12],
                        type: "integer"
                    }
                },
                formula: "A*4",
                distractors: {
                    strategies: ["off-by-ten", "double", "off-by-one"]
                },
                hint: "היקף ריבוע הוא צלע כפול 4",
                solutionGuide: "היקף = $ {A} × 4 = {Result}$ ס״מ",
                drawing: {
                    type: "geom-square",
                    sideLength: "{A}",
                    showArea: false,
                    caption: "ריבוע עם צלע {A} ס״מ"
                }
            },
            {
                question: "מה השטח של ריבוע שצלעו {A} ס״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                formula: "A*A",
                distractors: {
                    strategies: ["double", "off-by-ten", "off-by-one"]
                },
                hint: "שטח ריבוע הוא צלע בריבוע (צלע × צלע)",
                solutionGuide: "שטח = $ {A} × {A} = {Result}$ סמ״ר",
                drawing: {
                    type: "geom-square",
                    sideLength: "{A}",
                    showArea: false,
                    caption: "ריבוע עם צלע {A} ס״מ"
                }
            },
            {
                question: "מה ההיקף של מלבן שאורכו {A} ס״מ ורוחבו {B} ס״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],
                        type: "integer"
                    },
                    B: {
                        range: ["A+1", 11],
                        type: "integer"
                    }
                },
                formula: "(A+B)*2",
                distractors: {
                    strategies: ["double", "half", "off-by-two"]
                },
                hint: "היקף מלבן הוא פעמיים האורך ועוד פעמיים הרוחב",
                solutionGuide: "היקף = $({A} + {B}) × 2 = {Result}$ ס״מ",
                drawing: {
                    type: "geom-rectangle",
                    length: "{A}",
                    width: "{B}",
                    showPerimeter: false,
                    caption: "מלבן {A}×{B} ס״מ"
                }
            },
            {
                question: "מה השטח של מלבן שאורכו {A} ס״מ ורוחבו {B} ס״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],
                        type: "integer"
                    },
                    B: {
                        range: ["A+1", 11],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["plus-three", "off-by-two", "double"]
                },
                drawing: {
                    type: "geom-rectangle",
                    length: "{A}",
                    width: "{B}",
                    showArea: false,
                },
                hint: "שטח מלבן הוא אורך כפול רוחב",
                solutionGuide: "שטח =$ {A} × {B} = {Result}$ סמ״ר"
            },
            {
                question: "מה השטח של משולש שבסיסו {A} ס״מ וגובהו {B} ס״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [4, 12],
                        type: "integer"
                    },
                    B: {
                        range: [3, 8],
                        type: "integer"
                    }
                },
                formula: "(A*B)/2",
                distractors: {
                    strategies: ["double", "off-by-two", "off-by-ten"]
                },
                hint: "שטח משולש הוא (בסיס × גובה) ÷ 2",
                solutionGuide: "שטח = $({A} × {B}) ÷ 2 = {Result}$ סמ״ר",
                drawing: {
                    type: "geom-triangle",
                    base: "{A}",
                    height: "{B}",
                    showArea: false,
                    caption: "משולש עם בסיס {A} ס״מ וגובה {B} ס״מ"
                }
            },
            {
                question: "מה ההיקף של ריבוע שצלעו {A} ס״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 12],
                        type: "integer"
                    }
                },
                formula: "A*4",
                distractors: {
                    strategies: ["off-by-ten", "double", "off-by-one"]
                },
                hint: "היקף ריבוע הוא צלע כפול 4",
                solutionGuide: "היקף = $ {A} × 4 = {Result}$ ס״מ",
                drawing: {
                    type: "geom-square", 
                    sideLength: "{A}",
                    showPerimeter: false,
                    caption: "ריבוע עם צלע {A} ס״מ"
                }
            },
            {
                type: "order",
                question: "סדרו את הריבועים מהקטן לגדול לפי השטח",
                items: ["ריבוע עם צלע 3 ס״מ",  "ריבוע עם צלע 4 ס״מ", "ריבוע עם צלע 5 ס״מ", "ריבוע עם צלע 6 ס״מ"],
                hint: "חשבו על שטח כל ריבוע (צלע × צלע)",
                solutionGuide: "$3×3=9$, $4×4=16$, $5×5=25$, $6×6=36$"
            },
            {
                type: "order",
                question: "סדרו את הצורות מהקטן לגדול לפי ההיקף",
                items: ["משולש עם צלעות 5,5,5 ס״מ", "ריבוע עם צלע 4 ס״מ", "מלבן עם צלעות 3 ו-6 ס״מ", "ריבוע עם צלע 5 ס״מ"],
                hint: "חשבו על סכום כל הצלעות בכל צורה",
                solutionGuide: "משולש: $5+5+5=15$, ריבוע: $4×4=16$, מלבן: $2×(3+6)=18$, ריבוע: $4×5=20$"
            },
            {
                type: "matching",
                question: "התאימו כל צורה לשטח שלה",
                pairs: [
                    { left: "ריבוע עם צלע 5 ס״מ", right: "25 סמ״ר" },
                    { left: "מלבן 7×4 ס״מ", right: "28 סמ״ר" },
                    { left: "משולש עם בסיס 8 וגובה 6 ס״מ", right: "24 סמ״ר" },
                    { left: "ריבוע עם צלע 4 ס״מ", right: "16 סמ״ר" }
                ],
                hint: "השתמשו בנוסחאות השטח המתאימות לכל צורה",
                solutionGuide: "ריבוע: צלע × צלע, מלבן: אורך × רוחב, משולש: (בסיס × גובה) ÷ 2"
            },
            {
                type: "matching",
                question: "התאימו כל צורה להיקף שלה",
                pairs: [
                    { left: "ריבוע עם צלע 6 ס״מ", right: "24 ס״מ" },
                    { left: "מלבן 8×3 ס״מ", right: "22 ס״מ" },
                    { left: "משולש שווה צלעות 5 ס״מ", right: "15 ס״מ" },
                    { left: "ריבוע עם צלע 7 ס״מ", right: "28 ס״מ" }
                ],
                hint: "חברו את כל הצלעות בכל צורה",
                solutionGuide: "ריבוע: 4 × צלע, מלבן: 2 × (אורך + רוחב), משולש: סכום כל הצלעות"
            },
            {
                question: "חדר בצורת ריבוע שצלעו {A} מטר. נרצה להקיף את החדר בפנלים לאורך הקירות. כמה מטר פנל נצטרך ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    }
                },
                formula: "A*4",
                distractors: {
                    strategies: ["half", "off-by-ten", "off-by-two"]
                },
                hint: "חשבו על היקף החדר - כמה מטרים של שטיח צריך לאורך כל הקירות",
                solutionGuide: "היקף = {A} × 4 = {Result} מטר",
                drawing: {
                    type: "geom-square",
                    sideLength: "{A}",
                    showPerimeter: false,
                }
            },
            {
                question: "בחצר מלבנית שאורכה {A} מטר ורוחבה {B} מטר רוצים לשים דשא. כמה מטר רבוע של דשא צריך?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [5, 12],
                        type: "integer"
                    },
                    B: {
                        range: [3, 8],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["double", "off-by-ten", "off-by-two"]
                },
                
                hint: "חשבו על שטח החצר - כמה מטר רבוע יש לכסות בדשא",
                solutionGuide: "שטח = {A} × {B} = {Result} מ״ר",
                drawing: {
                    type: "geom-rectangle",
                    length: "{A}",
                    width: "{B}",
                    showArea: false,
                },
            }
        ]
    }
};
