export const learningContent = {
    "חוקי החילוף, הקיבוץ והפילוג": {
        tags: ['כיתה ג׳', 'אלגברה', 'חיבור וחיסור', 'כפל', 'חילוק'],
        relatedLinks: [
            { title: "תרגול חוקי פעולות", url: "/?tags=סדר פעולות", description: "תרגול חוקי חילוף, קיבוץ ופילוג" },
            { title: "תרגול אלגברה בסיסית", url: "/?tags=אלגברה", description: "תרגול בעיות באלגברה" }
        ],

        getStarted: [
            "**מהם חוקי הפעולות?**",
            "חוקי הפעולות עוזרים לנו לפתור תרגילים בדרכים שונות",
            "",
            "**חוק החילוף:**",
            "בחיבור וכפל, סדר המספרים לא משנה את התוצאה",
            "לדוגמה: $4 + 3 = 3 + 4$ וגם $4 × 3 = 3 × 4$",
            "",
            "**חוק הקיבוץ:**",
            "בחיבור וכפל, אפשר לקבץ מספרים בדרכים שונות",
            "לדוגמה: $(2 + 3) + 4 = 2 + (3 + 4)$",
            "וגם: $(2 × 3) × 4 = 2 × (3 × 4)$",
            "",
            "**חוק הפילוג:**",
            "אפשר לפרק כפל של סכום למכפלות נפרדות",
            "לדוגמה: $3 × (4 + 2) = (3 × 4) + (3 × 2)$",
            "",
            "**טיפים חשובים:**",
            "✨ חוק החילוף והקיבוץ עובדים רק בחיבור וכפל",
            "✨ בחיסור וחילוק סדר המספרים כן משנה",
            "✨ חוק הפילוג עוזר לפתור תרגילי כפל מורכבים"
        ],
        examples: [
            {
                question: "פתרו בעזרת חוק הקיבוץ: $(8 + 7) + 3$",
                solution: "נשתמש בחוק הקיבוץ:<br/>במקום $(8 + 7) + 3$ נעשה $8 + (7 + 3)$<br/>$ 8 + 10 = 18$<br/>כך קל יותר כי 7 + 3 = 10 "
            },
            {
                question: "פתרו בעזרת חוק הפילוג: $5 × (10 + 2)$",
                solution: "נשתמש בחוק הפילוג:<br/>$5 × (10 + 2) = (5 × 10) + (5 × 2)$<br/>$50 + 10 = 60$"
            },
            {
                question: "פתרו בעזרת חוק החילוף: $8 × 4 + 8 × 6$",
                solution: "נשתמש בחוק החילוף:<br/>$8 × 4 + 8 × 6 = 8 × (4 + 6)$<br/>$8 × 10 = 80$"
            },
            {
                question: "פתרו בעזרת חוק הפילוג: $3 × (8 + 4)$",
                solution: "נשתמש בחוק הפילוג:<br/>$3 × (8 + 4) = (3 × 8) + (3 × 4)$<br/>$ 24 + 12 = 36$"
            }
        ],
        quiz: [
           {
                type: "multiChoiceRandomMath",
                question: "פתרו בעזרת חוק הקיבוץ: $ ({A} + {C}) + {Calc_B} $",
                params: {
                    A: { range: [3, 8], type: "integer" },
                    B: { range: [2, 9], type: "integer" },
                    C: { range: [2, 9], type: "integer" }
                },
                metadata: {
                    Calc_B: "10*{B}-{A}", // Makes A+Calc_B sum to multiple of 10
                    CalcB10: "10*{B}" // Makes B a multiple of 10
                },
                formula: "C+10*B",
                distractors: {
                    strategies: ["off-by-ten", "off-by-one"],
                    custom: [
                        "{A}+{C}*{B}",
                        "{A}+{C}+{B}"
                    ]
                },
                hint: "האם כדאי לחבר קודם את {A} ו-{Calc_B} כדי להגיע למספר עגול?",
                solutionGuide: "$ ({A} + {C}) + {Calc_B} = {C} + ({A} + {Calc_B}) = {C} + {CalcB10} = {Result}$"
            },
            {
                type: "multiChoiceRandomMath",
                question: "פתרו בעזרת חוק הפילוג: $ {A} × ({Calc_B} + {C}) $",
                params: {
                    A: { range: [2, 5], type: "integer" },
                    B: { range: [2, 9], type: "integer" },
                    C: { range: [2, 5], type: "integer" }
                },
                metadata: {
                    Calc_B: "10*{B}", // Makes B a multiple of 10 for easier calculation
                    AC: "{A}*{C}",
                    AB: "{A}*10*{B}"
                },
                formula: "A*(10*B+C)",
                distractors: {
                    strategies: ["off-by-ten", "off-by-one"],
                    custom: [
                        "{A}+10*{B}+{C}"
                    ]
                },
                hint: "פלגו את הסכום שבסוגריים. {Calc_B} הוא מספר עגול",
                solutionGuide: "נשתמש בחוק הפילוג:<br/>$ {A} × ({Calc_B} + {C}) = ({A} × {Calc_B}) + ({A} × {C}) = {AB} + {AC} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו באמצעות חוק החילוף: $ {A} + {B} × {Calc_C} $",
                params: {
                    A: { range: [10, 20], type: "integer" },
                    B: { range: [2, 5], type: "integer" },
                    C: { range: [2, 5], type: "integer" }
                },
                metadata: {
                    Calc_C: "10*{C}" // Makes C a multiple of 10
                },
                formula: "A+B*10*C",
                distractors: {
                    strategies: ["off-by-ten", "off-by-one"],
                    custom: [
                        "{A}*{B}+10*{C}",
                        "{B}*({A}+10*{C})"
                    ]
                },
                hint: "זכרו: קודם כפל ואז חיבור",
                solutionGuide: "$ {A} + {B} × {Calc_C} = {A} + {B}×{Calc_C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "פתרו בעזרת חוק הפילוג: $ {A} × ({Calc_B} + {C} + {D}) $",
                params: {
                    A: { range: [2, 4], type: "integer" },
                    B: { range: [2, 9], type: "integer" },
                    C: { range: [2, 4], type: "integer" },
                    D: { range: [2, 4], type: "integer" }
                },
                metadata: {
                    Calc_B: "10*{B}" // Makes B a multiple of 10
                },
                formula: "A*(10*B+C+D)",
                distractors: {
                    strategies: ["off-by-ten", "off-by-one"],
                    custom: [
                        "{A}+(10*{B})*{C}+{D}",
                        "({A}+(10*{B}))*({C}+{D})",
                        "{A}*(10*{B})+{C}+{D})"
                    ]
                },
                hint: "פלגו את הסכום לשלוש מכפלות נפרדות",
                solutionGuide: "$ {A} × ({Calc_B} + {C} + {D}) = ({A} × {Calc_B}) + ({A} × {C}) + ({A} × {D}) = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "פתרו באמצעות חוק הקיבוץ: $ ({A} + {B}) × {Calc_C} $",
                params: {
                    A: { range: [3, 8], type: "integer" },
                    B: { range: [2, 5], type: "integer" },
                    C: { range: [2, 9], type: "integer" }
                },
                metadata: {
                    Calc_C: "10*{C}" // Makes C a multiple of 10
                },
                formula: "(A+B)*10*C",
                distractors: {
                    strategies: ["off-by-ten", "off-by-one"],
                    custom: [
                        "{A}*10*{C}+{B}*10*{C}",    // Forgot to add first
                        "({A}*10*{C})+{B}"            // Only multiplied first number
                    ]
                },
                hint: "נסו לקבץ את המספרים כך שתגיעו למספר עגול",
                solutionGuide: "$ ({A} + {B}) × {Calc_C} = {A}+{B} × {Calc_C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו בעזרת חוק הפילוג: $ {A} × ({Calc_B} - {C}) $",
                params: {
                    A: { range: [2, 5], type: "integer" },
                    B: { range: [2, 9], type: "integer" },
                    C: { range: [2, 5], type: "integer" }
                },
                metadata: {
                    Calc_B: "10*{B}" // Makes B a multiple of 10
                },
                formula: "A*(10*B-C)",
                distractors: {
                    strategies: ["off-by-ten", "off-by-one"],
                    custom: [
                        "{A}-(10*{B})*{C}",
                        "{A}*(10*{B}-{C})"
                    ]
                },
                hint: "פלגו את החיסור שבסוגריים. {Calc_B} הוא מספר עגול",
                solutionGuide: "$ {A} × ({Calc_B} - {C}) = ({A} × {Calc_B}) - ({A} × {C}) = {Result} $"
            },
            {
                type: "matching",
                question: "התאימו כל תרגיל לביטוי השווה לו",
                pairs: [
                    { left: "$(4 + 3) × 5$", right: "$4 × 5 + 3 × 5$" },
                    { left: "$6 × (80 + 2)$", right: "$6 × 80 + 6 × 2$" },
                    { left: "$(47 + 3) + 8$", right: "$47 + (3 + 8)$" },
                    { left: "$4 × (30 - 3)$", right: "$4 × 30 - 4 × 3$" }
                ],
                hint: "השתמשו בחוקי הפילוג והקיבוץ",
                solutionGuide: "השתמשו בחוק הפילוג בכפל או בחוק הקיבוץ בחיבור"
            },
            
            {
                type: "order",
                question: "סדרו את התרגילים מהקטן לגדול",
                items: ["$2 × (8 + 5)$", "$2 × (9 + 5)$", "$2 × (10 + 5)$", "$2 × (11 + 5)$"],
                hint: "שימו לב שכל התרגילים מוכפלים ב-2. השתמשו בחוק החילוף כדי להשוות רק את הסכומים שבסוגריים",
                solutionGuide: "כיוון שכל התרגילים מוכפלים ב-2, מספיק להשוות את הסכומים: 13, 14, 15, 16"
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "האם התרגיל $ {A} × {B} = {B} × {A} $ נכון?",
                params: { 
                    A: { range: [2, 10] },
                    B: { range: [2, 10] }
                },
                formula: "0",
                hint: "חשבו על חוק החילוף בכפל",
                solutiontemplate: {
                    true: "נכון! $ {A} × {B} = {B} × {A} $ בגלל חוק החילוף בכפל",
                    false: "לא נכון! $ {A} × {B} ≠ {B} × {A} $ בכפל"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "האם התרגיל $ {A} - {B} = {B} - {A} $ נכון?",
                params: { 
                    A: { range: [5, 10] },
                    B: { range: [1, 4] }
                },
                formula: "1",
                hint: "האם סדר המספרים משנה בחיסור?",
                solutiontemplate: {
                    true: "נכון! $ {A} - {B} = {B} - {A} $ בחיסור",
                    false: "לא נכון! $ {A} - {B} ≠ {B} - {A} $ כי בחיסור סדר המספרים כן משנה את התוצאה"
                }
            }
        ]
    }
};