// useTopicProgress.js
import { useAuth } from '../AuthContext';
import { calculateMastery, calculateSuccessRate } from '../utils/progressCalculations';

export const useTopicProgress = (topicId) => {
  const { progress, updateProgress } = useAuth();

  const addQuizScore = async (data) => {
    try {
      if (!data || typeof data.score === 'undefined') {
        throw new Error('Invalid quiz score data');
      }
  
      const topicProgress = progress.topics?.[topicId] || {
        totalAttempts: 0,
        totalTimeSpent: 0,
        totalCorrect: 0,
        recentAttempts: []
      };
      
      const newAttempt = {
        timestamp: Date.now(),
        correctAnswers: data.score,
        totalQuestions: data.totalQuestions
      };

      const recentAttempts = [
        newAttempt,
        ...(topicProgress.recentAttempts || []).slice(0, 4)
      ];
      
      const updateData = {
        totalAttempts: (topicProgress.totalAttempts || 0) + 1,
        totalTimeSpent: (topicProgress.totalTimeSpent || 0) + (data.timeSpent || 0),
        totalCorrect: (topicProgress.totalCorrect || 0) + data.score,
        recentAttempts,
        mastery: calculateMastery(recentAttempts),
        successRate: calculateSuccessRate(recentAttempts),
        lastAccessed: new Date()
      };

      await updateProgress(topicId, updateData);
    } catch (error) {
      console.error('Failed to add quiz score:', error);
      throw error;
    }
  };

  return {
    topicProgress: progress.topics?.[topicId],
    addQuizScore
  };
};