import React, { useState, useEffect } from 'react';
import { Box, VStack, HStack } from '@chakra-ui/react';
import { DraggableList } from './DraggableList';
import { MatchingBox } from './MatchingBox';

export const MatchingQuestion = ({ question, onAnswer, showFeedback }) => {
  const [matchedItems, setMatchedItems] = useState([]);
  const [isDndReady, setIsDndReady] = useState(false);

  useEffect(() => {
    if (question.pairs) {
      const shuffledItems = [...question.pairs]
        .map(p => ({
          ...p,
          id: `item-${Math.random()}`,
        }))
        .sort(() => Math.random() - 0.5);
      setMatchedItems(shuffledItems);
      window.MathJax?.typeset();
    }
  }, [question]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDndReady(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(matchedItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setMatchedItems(items);
  };

  const renderRightSide = (leftSide) => (
    <HStack 
      spacing={1}
      m={0}
      p={0}
      align="flex-start" 
      position="relative" 
      w="100%" 
      maxW="100%"
    >
      <Box 
        w="50%"
        position="relative"
        minW={0}
      >
        {leftSide}
      </Box>
      <Box 
        w="50%"
        position="relative"
        minW={0}
      >
        <VStack spacing={2}>
          {question.pairs.map((pair, index) => (
            <Box key={index} position="relative" w="100%">
              <MatchingBox 
                content={pair.right}
                isDraggable={false}
              />
            </Box>
          ))}
        </VStack>
      </Box>
    </HStack>
  );

  return (
    <DraggableList
      items={matchedItems}
      droppableId="matching-list"
      onDragEnd={handleDragEnd}
      onSubmit={onAnswer}
      showFeedback={showFeedback}
      renderRightSide={renderRightSide}
      loading={!isDndReady}
    />
  );
}; 