export const learningContent = {
    "חיסור במאונך": {
        tags: ['כיתה ג׳', 'חיבור וחיסור'],
        relatedLinks: [
            { title: "תרגול חיסור במאונך", url: "/?tags=חיבור וחיסור", description: "תרגול בעיות חיסור במאונך" }
        ],
        getStarted: [
            "**איך מחסרים במאונך?**",
            "",
            "רושמים את המספר הגדול למעלה",
            "מיישרים לפי האחדות (ימין)",
            "מתחילים לחסר מימין לשמאל",
            "אם צריך, פורטים עשרת מהספרה השמאלית",
            "",
            "**שלבי החיסור:**",
            "1. מסדרים את המספרים זה תחת זה",
            "2. מתחילים מאחדות (הטור הימני)",
            "3. אם אי אפשר לחסר, פורטים עשרת מהספרה השמאלית",
            "4. ממשיכים לטור הבא משמאל",
            "",
            "**דוגמה:**",
            "",
            "",
            "**טיפים:**",
            "לסמן את הפריטות בצורה ברורה",
            "לזכור שמחסרים מלמעלה למטה",
            "לבדוק שהתוצאה הגיונית",
            {
                drawing: {
                    type: "vertical", 
                    firstNumber: 453,
                    secondNumber: 267,
                    operation: "subtraction",
                    steps: [
                        "פורטים עשרת: 13 → 3",
                        "מחסרים אחדות: $13 - 7 = 6$",
                        "פורטים מאה: 14 → 4",
                        "מחסרים עשרות: $14 - 6 = 8$",
                        "מחסרים מאות: $3 - 2 = 1$"
                    ]
                }
            }
        ],
        examples: [
            {
                question: "חסרו במאונך: $234 - 156$",
                solution: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0ex^{1}\\kern-0.8ex\\not{2}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}156 \\\\ \\hline \\phantom{0}\\phantom{0}78 \\end{array}$<br/><br/>אחדות: $14-6=8$<br/>עשרות: $12-5=7$<br/>מאות: $1-1=0$<br/>התוצאה: $78$",
                drawing: {
                    type: "vertical",
                    firstNumber: 234,
                    secondNumber: 156,
                    operation: "subtraction",
                    steps: [
                        "פורטים עשרת: 14 → 4",
                        "מחסרים אחדות: $14 - 6 = 8$",
                        "פורטים מאה: 13 → 3",
                        "מחסרים עשרות: $13 - 5 = 8$",
                        "מחסרים מאות: $2 - 1 = 1$"
                    ]
                }
            },
            {
                question: "חסרו במאונך: $567 - 285$",
                solution: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{16} \\\\ \\kern-0ex^{4}\\kern-0.8ex\\not{5}\\kern-0.5em\\not{6}\\kern-0em{7} \\\\ - \\phantom{0}285 \\\\ \\hline \\phantom{0}282 \\end{array}$<br/><br/>אחדות: $7 - 5 = 2$<br/>עשרות: $16 - 8 = 8$ <br/>מאות: $4 - 2 = 2$<br/>התוצאה: $282$",
                drawing: {
                    type: "vertical",
                    firstNumber: 567,
                    secondNumber: 285,
                    operation: "subtraction",
                    steps: [
                        "מחסרים אחדות: $7 - 5 = 2$",
                        "פורטים מאה: 16 → 6",
                        "מחסרים עשרות: $16 - 8 = 8$",
                        "מחסרים מאות: $4 - 2 = 2$"
                    ]
                }
            }
        ],
        quiz: [
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 456 \\\\ - 327 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["129", "139", "119", "149"],
                hint: "כשמחסרים את האחדות 6-7, האם אפשר לחסר ישירות? אם לא, צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-0.3em^{16} \\\\ 4\\kern-0.5em\\not{5}\\kern-0.2ex^{4}\\kern-0.5em\\not{6} \\\\ - \\phantom{0}327 \\\\ \\hline \\phantom{0}129 \\end{array}$<br/><br/>אחדות: $16 - 7 = 9$<br/>עשרות: $4 - 2 = 2$<br/>מאות: $4 - 3 = 1$<br/>התוצאה: $129$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 628 \\\\ - 154 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["474", "464", "484", "454"],
                hint: "התחילו מימין - חברו את האחדות 8-4. אם המספר למעלה קטן מהמספר למטה, צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{18} \\\\ \\kern-0.1ex^{5}\\kern-0.5em\\not{6}\\kern-0.5em\\not{2}\\kern-0.5em\\not{8} \\\\ - \\phantom{0}154 \\\\ \\hline \\phantom{0}474 \\end{array}$<br/><br/>אחדות: $18 - 4 = 14$<br/>עשרות: $12 - 5 = 7$<br/>מאות: $5 - 1 = 4$<br/>התוצאה: $474$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 734 \\\\ - 528 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["206", "216", "196", "226"],
                hint: "שימו לב שבאחדות 4-8 לא ניתן לחסר ישירות. צריך לפרוט עשרת מהעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{6}\\kern-0.5em\\not{7}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}528 \\\\ \\hline \\phantom{0}206 \\end{array}$<br/><br/>אחדות: $14 - 8 = 6$<br/>עשרות: $12 - 2 = 10$<br/>מאות: $6 - 5 = 1$<br/>התוצאה: $206$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 845 \\\\ - 367 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["478", "488", "468", "498"],
                hint: "סדרו את המספרים זה מתחת לזה, יישרו לימין. בדקו אם צריך לפרוט עשרת באחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13}\\kern-0.5ex^{15} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{4}\\kern-0.5em\\not{5} \\\\ - \\phantom{0}367 \\\\ \\hline \\phantom{0}478 \\end{array}$<br/><br/>אחדות: $15 - 7 = 8$<br/>עשרות: $13 - 6 = 7$<br/>מאות: $7 - 3 = 4$<br/>התוצאה: $478$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 923 \\\\ - 645 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["278", "288", "268", "298"],
                hint: "התחילו מימין. כשמחסרים 3-5, האם אפשר לחסר ישירות? אם לא, מה עושים?",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{11}\\kern-0.5ex^{13} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{2}\\kern-0.5em\\not{3} \\\\ - \\phantom{0}645 \\\\ \\hline \\phantom{0}278 \\end{array}$<br/><br/>אחדות: $13 - 5 = 8$<br/>עשרות: $11 - 4 = 7$<br/>מאות: $8 - 6 = 2$<br/>התוצאה: $278$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 756 \\\\ - 489 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "שימו לב שבאחדות 6-9 לא ניתן לחסר ישירות. צריך לפרוט עשרת. זכרו שאחרי שפורטים, המספר בעשרות קטן ב-1",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{14}\\kern-0.5ex^{16} \\\\ \\kern-0.1ex^{6}\\kern-0.5em\\not{7}\\kern-0.5em\\not{5}\\kern-0.5em\\not{6} \\\\ - \\phantom{0}489 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $16 - 9 = 7$<br/>עשרות: $14 - 8 = 6$<br/>מאות: $6 - 4 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 834 \\\\ - 567 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "התחילו מימין. כשמחסרים 4-7, צריך לפרוט עשרת. זכרו שכל פריטה מקטינה את הספרה משמאל ב-1",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}567 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $14 - 7 = 7$<br/>עשרות: $12 - 6 = 6$<br/>מאות: $7 - 5 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 912 \\\\ - 645 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "סדרו את המספרים זה מתחת לזה. בדקו בכל ספרה אם אפשר לחסר ישירות, ואם לא - פרטו עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{10}\\kern-0.5ex^{12} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{1}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}645 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $12 - 5 = 7$<br/>עשרות: $10 - 4 = 6$<br/>מאות: $8 - 6 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 845 \\\\ - 378 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["467", "477", "457", "487"],
                hint: "התחילו מימין. כשמחסרים 5-8, צריך לפרוט עשרת. זכרו לסמן את השינויים בספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13}\\kern-0.5ex^{15} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{4}\\kern-0.5em\\not{5} \\\\ - \\phantom{0}378 \\\\ \\hline \\phantom{0}467 \\end{array}$<br/><br/>אחדות: $15 - 8 = 7$<br/>עשרות: $13 - 7 = 6$<br/>מאות: $7 - 3 = 4$<br/>התוצאה: $467$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 934 \\\\ - 567 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["367", "377", "357", "387"],
                hint: "בדקו בכל ספרה אם אפשר לחסר ישירות. אם לא, פרטו עשרת מהספרה שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}567 \\\\ \\hline \\phantom{0}367 \\end{array}$<br/><br/>אחדות: $14 - 7 = 7$<br/>עשרות: $12 - 6 = 6$<br/>מאות: $8 - 5 = 3$<br/>התוצאה: $367$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 823 \\\\ - 456 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["367", "377", "357", "387"],
                hint: "התחילו מימין. כשמחסרים 3-6, צריך לפרוט עשרת. זכרו שכל פריטה משפיעה על הספרות שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{11}\\kern-0.5ex^{13} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{2}\\kern-0.5em\\not{3} \\\\ - \\phantom{0}456 \\\\ \\hline \\phantom{0}367 \\end{array}$<br/><br/>אחדות: $13 - 6 = 7$<br/>עשרות: $11 - 5 = 6$<br/>מאות: $7 - 4 = 3$<br/>התוצאה: $367$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 956 \\\\ - 589 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["367", "377", "357", "387"],
                hint: "סדרו את המספרים זה מתחת לזה. בדקו בכל ספרה אם צריך לפרוט עשרת. זכרו לעדכן את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{14}\\kern-0.5ex^{16} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{5}\\kern-0.5em\\not{6} \\\\ - \\phantom{0}589 \\\\ \\hline \\phantom{0}367 \\end{array}$<br/><br/>אחדות: $16 - 9 = 7$<br/>עשרות: $14 - 8 = 6$<br/>מאות: $8 - 5 = 3$<br/>התוצאה: $367$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 734 \\\\ - 467 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "התחילו מימין. בדקו בכל ספרה אם אפשר לחסר ישירות, ואם לא - פרטו עשרת מהספרה שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{6}\\kern-0.5em\\not{7}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}467 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $14 - 7 = 7$<br/>עשרות: $12 - 6 = 6$<br/>מאות: $6 - 4 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 845 \\\\ - 578 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "סדרו את המספרים זה מתחת לזה. כשמחסרים 5-8, צריך לפרוט עשרת. זכרו לעדכן את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13}\\kern-0.5ex^{15} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{4}\\kern-0.5em\\not{5} \\\\ - \\phantom{0}578 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $15 - 8 = 7$<br/>עשרות: $13 - 7 = 6$<br/>מאות: $7 - 5 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 923 \\\\ - 656 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "התחילו מימין. כשמחסרים 3-6, צריך לפרוט עשרת. זכרו שכל פריטה משפיעה על הספרות שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{11}\\kern-0.5ex^{13} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{2}\\kern-0.5em\\not{3} \\\\ - \\phantom{0}656 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $13 - 6 = 7$<br/>עשרות: $11 - 5 = 6$<br/>מאות: $8 - 6 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 812 \\\\ - 545 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "סדרו את המספרים זה מתחת לזה. בדקו בכל ספרה אם צריך לפרוט עשרת. זכרו לעדכן את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{10}\\kern-0.5ex^{12} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{1}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}545 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $12 - 5 = 7$<br/>עשרות: $10 - 4 = 6$<br/>מאות: $7 - 5 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 934 \\\\ - 667 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "התחילו מימין. כשמחסרים 4-7, צריך לפרוט עשרת. זכרו לסמן את השינויים בספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}667 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $14 - 7 = 7$<br/>עשרות: $12 - 6 = 6$<br/>מאות: $8 - 6 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 673 \\\\ - 85 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["588", "578", "598", "568"],
                hint: "שימו לב שהמספר השני הוא דו-ספרתי. יש להוסיף 0 משמאל כדי ליישר אותו. כשמחסרים 3-5, צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{16}\\kern-0.5ex^{13} \\\\ \\kern-0.1ex\\not{6}\\kern-2ex^{5}\\kern-0.5em\\not{7}\\kern-0.5em\\not{3} \\\\ - \\phantom{0}\\phantom{0}85 \\\\ \\hline \\phantom{0}588 \\end{array}$<br/><br/>אחדות: $13 - 5 = 8$<br/>עשרות: $16 - 8 = 8$<br/>מאות: $5 - 0 = 5$<br/>התוצאה: $588$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 492 \\\\ - 175 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["317", "327", "307", "337"],
                hint: "התחילו מימין. כשמחסרים 2-5, צריך לפרוט עשרת. זכרו שכל פריטה משפיעה על הספרות שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-0.5ex^{12}\\kern-2.8ex^{8} \\\\ \\kern-0.5em{4}\\kern-0.5em\\not{9}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}175 \\\\ \\hline \\phantom{0}317 \\end{array}$<br/><br/>אחדות: $12 - 5 = 7$<br/>עשרות: $8 - 7 = 1$<br/>מאות: $4 - 1 = 3$<br/>התוצאה: $317$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 861 \\\\ - 94 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["767", "777", "757", "787"],
                hint: "שימו לב שהמספר השני הוא דו-ספרתי. הוסיפו 0 משמאל כדי ליישר אותו. בדקו בכל ספרה אם צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{15}\\kern-0.5ex^{11} \\\\ \\kern-0.1ex\\not{8}\\kern-2ex^{7}\\kern-0.5em\\not{6}\\kern-0.5em\\not{1} \\\\ - \\phantom{0}\\phantom{0}94 \\\\ \\hline \\phantom{0}767 \\end{array}$<br/><br/>אחדות: $11 - 4 = 7$<br/>עשרות: $15 - 9 = 6$<br/>מאות: $7 - 0 = 7$<br/>התוצאה: $767$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 543 \\\\ - 276 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["267", "277", "257", "287"],
                hint: "התחילו מימין. כשמחסרים 3-6, צריך לפרוט עשרת. זכרו לעדכן את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13}\\kern-0.5ex^{13} \\\\ \\kern-0.1ex^{4}\\kern-0.5em\\not{5}\\kern-0.5em\\not{4}\\kern-0.5em\\not{3} \\\\ - \\phantom{0}276 \\\\ \\hline \\phantom{0}267 \\end{array}$<br/><br/>אחדות: $13 - 6 = 7$<br/>עשרות: $13 - 7 = 6$<br/>מאות: $4 - 2 = 2$<br/>התוצאה: $267$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 752 \\\\ - 63 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["689", "679", "699", "669"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{14}\\kern-0.5ex^{12} \\\\ \\kern-0.1ex\\not{7}\\kern-2ex^{6}\\kern-0.5em\\not{5}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}\\phantom{0}63 \\\\ \\hline \\phantom{0}689 \\end{array}$<br/><br/>אחדות: $12 - 3 = 9$<br/>עשרות: $14 - 6 = 8$<br/>מאות: $6 - 0 = 6$<br/>התוצאה: $689$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 981 \\\\ - 324 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["657", "667", "647", "677"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-1.8ex^{7}\\kern-0.5ex^{11} \\\\ \\kern-0.5em{9}\\kern-0.5em\\not{8}\\kern-0.5em\\not{1} \\\\ - \\phantom{0}324 \\\\ \\hline \\phantom{0}657 \\end{array}$<br/><br/>אחדות: $11 - 4 = 7$<br/>עשרות: $7 - 2 = 5$<br/>מאות: $9 - 3 = 6$<br/>התוצאה: $657$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 635 \\\\ - 82 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["553", "563", "543", "573"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13} \\\\ \\kern-0ex^{5}\\kern-0ex\\not{6}\\kern-1ex\\not{3}\\kern-0ex{5} \\\\ - \\phantom{0}\\phantom{0}82 \\\\ \\hline \\phantom{0}553 \\end{array}$<br/><br/>אחדות: $5 - 2 = 3$<br/>עשרות: $13 - 8 = 5$<br/>מאות: $5 - 0 = 5$<br/>התוצאה: $553$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 847 \\\\ - 389 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["458", "468", "448", "478"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13}\\kern-0.5ex^{17} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{4}\\kern-0.5em\\not{7} \\\\ - \\phantom{0}389 \\\\ \\hline \\phantom{0}458 \\end{array}$<br/><br/>אחדות: $17 - 9 = 8$<br/>עשרות: $13 - 8 = 5$<br/>מאות: $7 - 3 = 4$<br/>התוצאה: $458$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 763 \\\\ - 295 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["468", "478", "458", "488"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{15}\\kern-0.5ex^{13} \\\\ \\kern-0.1ex^{6}\\kern-0.5em\\not{7}\\kern-0.5em\\not{6}\\kern-0.5em\\not{3} \\\\ - \\phantom{0}295 \\\\ \\hline \\phantom{0}468 \\end{array}$<br/><br/>אחדות: $13 - 5 = 8$<br/>עשרות: $15 - 9 = 6$<br/>מאות: $6 - 2 = 4$<br/>התוצאה: $468$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 892 \\\\ - 435 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["457", "467", "447", "477"],
                hint: "התחילו מימין. כשמחסרים 2-5, צריך לפרוט עשרת. זכרו שכל פריטה משפיעה על הספרות שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{18}\\kern-0.5ex^{12} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}435 \\\\ \\hline \\phantom{0}457 \\end{array}$<br/><br/>אחדות: $12 - 5 = 7$<br/>עשרות: $18 - 3 = 5$<br/>מאות: $7 - 4 = 4$<br/>התוצאה: $457$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 594 \\\\ - 73 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["521", "531", "511", "541"],
                hint: "שימו לב שהמספר השני הוא דו-ספרתי. הוסיפו 0 משמאל כדי ליישר אותו. בדקו אם צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ \\kern-0em{5}\\kern-0em{9}\\kern-0em{4} \\\\ - \\phantom{0}\\phantom{0}73 \\\\ \\hline \\phantom{0}521 \\end{array}$<br/><br/>אחדות: $4 - 3 = 1$<br/>עשרות: $9 - 7 = 2$<br/>מאות: $5 - 0 = 5$<br/>התוצאה: $521$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 834 \\\\ - 276 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["558", "568", "548", "578"],
                hint: "התחילו מימין. כשמחסרים 4-6, צריך לפרוט עשרת. שימו לב שצריך לעדכן את הספרות שמשמאל אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}276 \\\\ \\hline \\phantom{0}558 \\end{array}$<br/><br/>אחדות: $14 - 6 = 8$<br/>עשרות: $12 - 7 = 5$<br/>מאות: $7 - 2 = 5$<br/>התוצאה: $558$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 956 \\\\ - 589 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["367", "377", "357", "387"],
                hint: "התחילו מימין. כשמחסרים 6-7, צריך לפרוט עשרת. המשיכו לפרוט עשרות כשצריך ועדכנו את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{14}\\kern-0.5ex^{16} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{5}\\kern-0.5em\\not{6} \\\\ - \\phantom{0}589 \\\\ \\hline \\phantom{0}367 \\end{array}$<br/><br/>אחדות: $16 - 7 = 9$<br/>עשרות: $14 - 8 = 6$<br/>מאות: $8 - 5 = 3$<br/>התוצאה: $367$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 582 \\\\ - 265 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["317", "327", "307", "337"],
                hint: "התחילו מימין. כשמחסרים 2-5, צריך לפרוט עשרת. זכרו לעדכן את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-0ex^{12}\\kern-2.5ex^{7} \\\\ \\kern-0.5em{5}\\kern-0.5em\\not{8}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}265 \\\\ \\hline \\phantom{0}317 \\end{array}$<br/><br/>אחדות: $12 - 5 = 7$<br/>עשרות: $7 - 6 = 1$<br/>מאות: $5 - 2 = 3$<br/>התוצאה: $317$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 967 \\\\ - 122 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["845", "855", "835", "844"],
                hint: "בדקו בכל ספרה אם אפשר לחסר ישירות. אם לא, פרטו עשרת מהספרה שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 967 \\\\ - 122 \\\\ \\hline 845 \\end{array}$<br/><br/>אחדות: $7 - 2 = 5$<br/>עשרות: $6 - 2 = 4$<br/>מאות: $9 - 1 = 8$<br/>התוצאה: $845$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 834 \\\\ - 205 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["629", "639", "619", "628"],
                hint: "התחילו מימין. כשמחסרים 4-5, צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}205 \\\\ \\hline \\phantom{0}629 \\end{array}$<br/><br/>אחדות: $14 - 5 = 9$<br/>עשרות: $12 - 0 = 2$<br/>מאות: $7 - 2 = 5$<br/>התוצאה: $629$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 452 \\\\ - 279 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["173", "183", "163", "174"],
                hint: "שימו לב שבאחדות 2-9 לא ניתן לחסר ישירות. צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{14}\\kern-0.5ex^{12} \\\\ \\kern-0.1ex^{3}\\kern-0.5em\\not{4}\\kern-0.5em\\not{5}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}279 \\\\ \\hline \\phantom{0}173 \\end{array}$<br/><br/>אחדות: $12 - 9 = 3$<br/>עשרות: $14 - 7 = 7$<br/>מאות: $3 - 2 = 1$<br/>התוצאה: $173$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 756 \\\\ - 162 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["594", "584", "604", "593"],
                hint: "בדקו בכל ספרה אם אפשר לחסר ישירות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{15}\\\\ \\kern-0.1ex^{6}\\kern-0.5em\\not{7}\\kern-0.5em\\not{5}{6} \\\\ - \\phantom{0}162 \\\\ \\hline \\phantom{0}594 \\end{array}$<br/><br/>אחדות: $6 - 2 = 4$<br/>עשרות: $15 - 6 = 9$<br/>מאות: $6 - 1 = 5$<br/>התוצאה: $594$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 981 \\\\ - 324 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["657", "667", "647", "677"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-1.8ex^{7}\\kern-0.5ex^{11} \\\\ \\kern-0.5em{9}\\kern-0.5em\\not{8}\\kern-0.5em\\not{1} \\\\ - \\phantom{0}324 \\\\ \\hline \\phantom{0}657 \\end{array}$<br/><br/>אחדות: $11 - 4 = 7$<br/>עשרות: $7 - 2 = 5$<br/>מאות: $9 - 3 = 6$<br/>התוצאה: $657$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 635 \\\\ - 82 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["553", "563", "543", "573"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13} \\\\ \\kern-0ex^{5}\\kern-0ex\\not{6}\\kern-1ex\\not{3}\\kern-0ex{5} \\\\ - \\phantom{0}\\phantom{0}82 \\\\ \\hline \\phantom{0}553 \\end{array}$<br/><br/>אחדות: $5 - 2 = 3$<br/>עשרות: $13 - 8 = 5$<br/>מאות: $5 - 0 = 5$<br/>התוצאה: $553$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 847 \\\\ - 389 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["458", "468", "448", "478"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13}\\kern-0.5ex^{17} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{4}\\kern-0.5em\\not{7} \\\\ - \\phantom{0}389 \\\\ \\hline \\phantom{0}458 \\end{array}$<br/><br/>אחדות: $17 - 9 = 8$<br/>עשרות: $13 - 8 = 5$<br/>מאות: $7 - 3 = 4$<br/>התוצאה: $458$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 763 \\\\ - 295 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["468", "478", "458", "488"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{15}\\kern-0.5ex^{13} \\\\ \\kern-0.1ex^{6}\\kern-0.5em\\not{7}\\kern-0.5em\\not{6}\\kern-0.5em\\not{3} \\\\ - \\phantom{0}295 \\\\ \\hline \\phantom{0}468 \\end{array}$<br/><br/>אחדות: $13 - 5 = 8$<br/>עשרות: $15 - 9 = 6$<br/>מאות: $6 - 2 = 4$<br/>התוצאה: $468$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 892 \\\\ - 435 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["457", "467", "447", "477"],
                hint: "התחילו מימין. כשמחסרים 2-5, צריך לפרוט עשרת. זכרו שכל פריטה משפיעה על הספרות שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{8}\\kern-1.5ex^{12} \\\\ \\kern-1.5em{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}435 \\\\ \\hline \\phantom{0}457 \\end{array}$<br/><br/>אחדות: $12 - 5 = 7$<br/>עשרות: $8 - 3 = 5$<br/>מאות: $8 - 4 = 4$<br/>התוצאה: $457$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 594 \\\\ - 73 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["521", "531", "511", "541"],
                hint: "שימו לב שהמספר השני הוא דו-ספרתי. הוסיפו 0 משמאל כדי ליישר אותו",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ \\kern-0em{5}\\kern-0em{9}\\kern-0em{4} \\\\ - \\phantom{0}\\phantom{0}73 \\\\ \\hline \\phantom{0}521 \\end{array}$<br/><br/>אחדות: $4 - 3 = 1$<br/>עשרות: $9 - 7 = 2$<br/>מאות: $5 - 0 = 5$<br/>התוצאה: $521$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 834 \\\\ - 276 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["558", "568", "548", "578"],
                hint: "התחילו מימין. כשמחסרים 4-6, צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}276 \\\\ \\hline \\phantom{0}558 \\end{array}$<br/><br/>אחדות: $14 - 6 = 8$<br/>עשרות: $12 - 7 = 5$<br/>מאות: $7 - 2 = 5$<br/>התוצאה: $558$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 956 \\\\ - 387 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["569", "579", "559", "589"],
                hint: "התחילו מימין. כשמחסרים 6-7, צריך לפרוט עשרת. המשיכו לפרוט עשרות כשצריך ועדכנו את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{14}\\kern-0.5ex^{16} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{5}\\kern-0.5em\\not{6} \\\\ - \\phantom{0}387 \\\\ \\hline \\phantom{0}569 \\end{array}$<br/><br/>אחדות: $16 - 7 = 9$<br/>עשרות: $14 - 8 = 6$<br/>מאות: $8 - 3 = 5$<br/>התוצאה: $569$"
            },

     
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 956 \\\\ - 589 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["367", "377", "357", "387"],
                hint: "התחילו מימין. כשמחסרים 6-9, צריך לפרוט עשרת. המשיכו לפרוט עשרות כשצריך ועדכנו את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{14}\\kern-0.5ex^{16} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{5}\\kern-0.5em\\not{6} \\\\ - \\phantom{0}589 \\\\ \\hline \\phantom{0}367 \\end{array}$<br/><br/>אחדות: $16 - 9 = 7$<br/>עשרות: $14 - 8 = 6$<br/>מאות: $8 - 5 = 3$<br/>התוצאה: $367$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 912 \\\\ - 267 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["645", "655", "635", "665"],
                hint: "התחילו מימין. כשמחסרים 2-7, צריך לפרוט עשרת. זכרו לעדכן את הספרות אחרי כל פריטה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{10}\\kern-0.5ex^{12} \\\\ \\kern-0.1ex^{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{1}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}267 \\\\ \\hline \\phantom{0}645 \\end{array}$<br/><br/>אחדות: $12 - 7 = 5$<br/>עשרות: $10 - 6 = 4$<br/>מאות: $8 - 2 = 6$<br/>התוצאה: $645$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 967 \\\\ - 122 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["845", "855", "835", "844"],
                hint: "בדקו בכל ספרה אם אפשר לחסר ישירות. אם לא, פרטו עשרת מהספרה שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 967 \\\\ - 122 \\\\ \\hline 845 \\end{array}$<br/><br/>אחדות: $7 - 2 = 5$<br/>עשרות: $6 - 2 = 4$<br/>מאות: $9 - 1 = 8$<br/>התוצאה: $845$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 834 \\\\ - 205 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["629", "639", "619", "628"],
                hint: "התחילו מימין. כשמחסרים 4-5, צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-1.5ex^{2}\\kern-0.5ex^{14} \\\\ \\kern-0.5em{8}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}205 \\\\ \\hline \\phantom{0}629 \\end{array}$<br/><br/>אחדות: $14 - 5 = 9$<br/>עשרות: $2 - 0 = 2$<br/>מאות: $8 - 2 = 6$<br/>התוצאה: $629$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 452 \\\\ - 279 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["173", "183", "163", "174"],
                hint: "שימו לב שבאחדות 2-9 לא ניתן לחסר ישירות. צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{14}\\kern-0.5ex^{12} \\\\ \\kern-0.1ex^{3}\\kern-0.5em\\not{4}\\kern-0.5em\\not{5}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}279 \\\\ \\hline \\phantom{0}173 \\end{array}$<br/><br/>אחדות: $12 - 9 = 3$<br/>עשרות: $14 - 7 = 7$<br/>מאות: $3 - 2 = 1$<br/>התוצאה: $173$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 756 \\\\ - 162 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["594", "584", "604", "593"],
                hint: "בדקו בכל ספרה אם אפשר לחסר ישירות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{15} \\\\ \\kern-0.1ex^{6}\\kern-0.5em\\not{7}\\kern-0.5em\\not{5}\\kern0em{6} \\\\ - \\phantom{0}162 \\\\ \\hline \\phantom{0}594 \\end{array}$<br/><br/>אחדות: $6 - 2 = 4$<br/>עשרות: $15 - 6 = 9$<br/>מאות: $6 - 1 = 5$<br/>התוצאה: $594$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 981 \\\\ - 324 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["657", "667", "647", "677"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-1.5ex^{7}\\kern-0.5ex^{11} \\\\ \\kern-0.5em{9}\\kern-0.5em\\not{8}\\kern-0.5em\\not{1} \\\\ - \\phantom{0}324 \\\\ \\hline \\phantom{0}657 \\end{array}$<br/><br/>אחדות: $11 - 4 = 7$<br/>עשרות: $7 - 2 = 5$<br/>מאות: $9 - 3 = 6$<br/>התוצאה: $657$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 635 \\\\ - 82 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["553", "563", "543", "573"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13} \\\\ \\kern-0ex^{5}\\kern-0ex\\not{6}\\kern-1ex\\not{3}\\kern-0ex{5} \\\\ - \\phantom{0}\\phantom{0}82 \\\\ \\hline \\phantom{0}553 \\end{array}$<br/><br/>אחדות: $5 - 2 = 3$<br/>עשרות: $13 - 8 = 5$<br/>מאות: $5 - 0 = 5$<br/>התוצאה: $553$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 847 \\\\ - 389 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["458", "468", "448", "478"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{13}\\kern-0.5ex^{17} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{4}\\kern-0.5em\\not{7} \\\\ - \\phantom{0}389 \\\\ \\hline \\phantom{0}458 \\end{array}$<br/><br/>אחדות: $17 - 9 = 8$<br/>עשרות: $13 - 8 = 5$<br/>מאות: $7 - 3 = 4$<br/>התוצאה: $458$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 763 \\\\ - 295 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["468", "478", "458", "488"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{15}\\kern-0.5ex^{13} \\\\ \\kern-0.1ex^{6}\\kern-0.5em\\not{7}\\kern-0.5em\\not{6}\\kern-0.5em\\not{3} \\\\ - \\phantom{0}295 \\\\ \\hline \\phantom{0}468 \\end{array}$<br/><br/>אחדות: $13 - 5 = 8$<br/>עשרות: $15 - 9 = 6$<br/>מאות: $6 - 2 = 4$<br/>התוצאה: $468$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 892 \\\\ - 435 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["457", "467", "447", "477"],
                hint: "התחילו מימין. כשמחסרים 2-5, צריך לפרוט עשרת. זכרו שכל פריטה משפיעה על הספרות שמשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{8}\\kern-1.5ex^{12} \\\\ \\kern-1.5em{8}\\kern-0.5em\\not{9}\\kern-0.5em\\not{2} \\\\ - \\phantom{0}435 \\\\ \\hline \\phantom{0}457 \\end{array}$<br/><br/>אחדות: $12 - 5 = 7$<br/>עשרות: $8 - 3 = 5$<br/>מאות: $8 - 4 = 4$<br/>התוצאה: $457$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 594 \\\\ - 73 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["521", "531", "511", "541"],
                hint: "שימו לב שהמספר השני הוא דו-ספרתי. הוסיפו 0 משמאל כדי ליישר אותו",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ \\kern-0em{5}\\kern-0em{9}\\kern-0em{4} \\\\ - \\phantom{0}\\phantom{0}73 \\\\ \\hline \\phantom{0}521 \\end{array}$<br/><br/>אחדות: $4 - 3 = 1$<br/>עשרות: $9 - 7 = 2$<br/>מאות: $5 - 0 = 5$<br/>התוצאה: $521$"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 834 \\\\ - 276 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["558", "568", "548", "578"],
                hint: "התחילו מימין. כשמחסרים 4-6, צריך לפרוט עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחסר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\kern-2.5ex^{12}\\kern-0.5ex^{14} \\\\ \\kern-0.1ex^{7}\\kern-0.5em\\not{8}\\kern-0.5em\\not{3}\\kern-0.5em\\not{4} \\\\ - \\phantom{0}276 \\\\ \\hline \\phantom{0}558 \\end{array}$<br/><br/>אחדות: $14 - 6 = 8$<br/>עשרות: $12 - 7 = 5$<br/>מאות: $7 - 2 = 5$<br/>התוצאה: $558$"
            }

            
        ]
    }
}; 