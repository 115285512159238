import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export const MatchingBox = ({ content, isDraggable, isDragging }) => (
  <Box
    p={[1, 2, 3]}
    bg={isDraggable ? "blue.50" : "white"}
    borderRadius="lg"
    boxShadow={isDragging ? "lg" : isDraggable ? "md" : "sm"}
    border="2px solid"
    borderColor={isDragging ? "blue.400" : isDraggable ? "blue.200" : "gray.200"}
    h={["90px", "90px", "90px"]}
    w="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    cursor={isDraggable ? "grab" : "default"}
    position="relative"
    transition="all 0.2s"
    _hover={isDraggable ? {
      transform: "scale(1.02)",
      borderColor: "blue.400",
      boxShadow: "lg"
    } : {}}
    {...(isDraggable && {
      _before: {
        content: '"⋮⋮"',
        position: "absolute",
        right: "4px",
        top: "50%",
        transform: "translateY(-50%)",
        color: "blue.500",
        fontSize: "20px",
        fontWeight: "bold"
      },
      pl: 2,
      pr: 6
    })}
  >
    <Text 
      fontSize={['2xs', 'xs', 'sm']}
      fontWeight="medium" 
      textAlign="center"
      width="100%"
      p={1}
      dangerouslySetInnerHTML={{ __html: typeof content === 'string' ? content : content.left }}
      sx={{
        fontSize: 'clamp(0.8rem, 2.5vw, 1.1rem)',
        lineHeight: '1.2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '& .MathJax': {
          maxWidth: '95%',
          overflow: 'visible !important',
          display: 'inline-block !important',
          verticalAlign: 'middle',
          margin: '0 1px',
          fontSize: '100%'
        },
        '& .MJX-TEX': {
          margin: 'auto',
          fontSize: 'inherit'
        }
      }}
    />
  </Box>
); 