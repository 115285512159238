export const learningContent = {
    "קומבינטוריקה": {
        tags: ['כיתה ח׳', 'הסתברות'],
        relatedLinks: [
            { title: "תרגול קומבינטוריקה", url: "/?tags=הסתברות", description: "תרגול בעיות קומבינטוריקה והסתברות" },
            { title: "תרגול הסתברות", url: "/?tags=הסתברות", description: "תרגול בעיות הסתברות" }
        ],


        getStarted: [
            "**עקרונות בסיסיים בקומבינטוריקה:**",
            "כלל המכפלה: אם יש m אפשרויות לפעולה ראשונה ו-n לשנייה, יש m×n אפשרויות בסך הכל",
            "סדר חשוב: סידורים שונים נחשבים אפשרויות שונות",
            "סדר לא חשוב: סידורים שונים נחשבים אותה אפשרות",
            "",
            "**מושגי יסוד בהסתברות:**",
            "הסתברות = מספר המקרים הרצויים חלקי מספר כל המקרים האפשריים",
            "$0 \\leq P(A) \\leq 1$ לכל מאורע A",
            "מאורעות בלתי תלויים: $P(A \\text{ ו-}B) = P(A) × P(B)$",
            "",
            "**חישובי אפשרויות:**",
            "סידור n איברים = n!",
            "בחירת k מתוך n = $\\binom{n}{k}$",
            "**דוגמאות מחיי היומיום:**",
            "בחירת בגדים: כמה דרכים יש לבחור חולצה ומכנסיים?",
            "סידור תלמידים: כמה אפשרויות יש לסדר קבוצה בשורה?",
            "משחקים: הסתברות לניצחון במשחק קלפים או קוביות",
            "",
            "**טיפים לפתרון:**",
            "לצייר עץ אפשרויות כשיש מעט מקרים",
            "לזהות אם הסדר חשוב או לא",
            "לחשוב על דוגמה פשוטה קודם",
            "",
            "**שגיאות נפוצות:**",
            "לשכוח להבדיל בין מקרים בהם הסדר חשוב או לא",
            "לשכוח לכפול כשיש כמה שלבים",
            "לחשב הסתברות בלי לספור את כל המקרים האפשריים"
        ],
        examples: [
            {
                question: "בכמה דרכים אפשר לסדר 3 אנשים על קרוסלה מסתובבת?",
                solution: "כשהסדר יחסי (רק מי ליד מי), מספר האפשרויות הוא (n-1)!<br />עבור 3 אנשים: (3-1)! = 2! = 2 אפשרויות",
                drawing: {
                    type: "carousel",
                    people: [
                        { names: ["דן", "רון", "גיל"], color: "blue" },
                        { names: ["דן", "גיל", "רון"], color: "green" }
                    ],
                    caption: "שתי האפשרויות השונות: סידור אחד בכחול והשני בירוק"
                }
            },
            {
                question: "בית במחשב הוא רצף של 8 תווים, כל אחד 0 או 1. כמה אפשרויות יש?",
                solution: "לכל תו יש 2 אפשרויות, ויש 8 תווים<br />לפי כלל המכפלה: $2^8 = 256$ אפשרויות",
                drawing: {
                    type: "bitCombinations",
                    bits: 3,
                    examples: [
                        "000",
                        "001",
                        "010",
                        "011",
                        "...",
                        "111"
                    ],
                    highlight: {
                        positions: [1, 2, 3],
                        choices: ["0", "1"]
                    },
                    caption: "הדגמה של האפשרויות עבור 3 ביטים (מתוך 8). כל עמודה יכולה להיות 0 או 1"
                }
            },
            {
                question: "בכמה דרכים אפשר לבחור חולצה ומכנסיים אם יש 3 חולצות ו-2 מכנסיים?",
                solution: "נפתור בשלבים:<br />1. לכל חולצה אפשר לבחור כל מכנסיים<br />2. מספר האפשרויות לפי כלל המכפלה: 3 × 2 = 6",
                drawing: {
                    type: "distribution",
                    items: [["👕", "🧥", "👔"], ["👖", "🩳"]],
                    recipients: ["חולצה", "מכנסיים"],
                    combinations: [
                        { "חולצה": ["👕"], "מכנסיים": ["👖"] },  // T-Shirt with Jeans
                        { "חולצה": ["🧥"], "מכנסיים": ["👖"] },  // Coat with Jeans
                        { "חולצה": ["👔"], "מכנסיים": ["👖"] },  // Necktie with Jeans
                        { "חולצה": ["👕"], "מכנסיים": ["🩳"] },  // T-Shirt with Shorts
                        { "חולצה": ["🧥"], "מכנסיים": ["🩳"] },  // Coat with Shorts
                        { "חולצה": ["👔"], "מכנסיים": ["🩳"] }  // Necktie with Shorts

                    ],
                    caption: "כל האפשרויות לבחירת חולצה ומכנסיים"
                }
            },
            {
                question: "מה ההסתברות להטיל קובייה פעמיים ולקבל בשתיהן מספר גדול מ-4?",
                solution: "נפתור שלב אחר שלב:<br />1. בכל הטלה המספרים הטובים הם 5,6<br />2. בכל הטלה ההסתברות היא $\\frac{2}{6}$<br />3. צריך לקבל מספר טוב בשתי ההטלות<br />4. ההסתברות היא $\\frac{2}{6} × \\frac{2}{6} = \\frac{1}{9}$",
                drawing: {
                    type: "grid",
                    rows: 6,
                    cols: 6,
                    highlight: [[5, 5], [5, 6], [6, 5], [6, 6]],
                    caption: "המקרים הרצויים מסומנים בטבלה"
                }
            },
            {
                question: "בכמה דרכים אפשר לחלק 4 ממתקים שונים בין 2 ילדים?",
                solution: "נפתור בשלבים:<br />1. לכל ממתק יש 2 אפשרויות (ילד ראשון או שני)<br />2. יש 4 ממתקים<br />3. לפי כלל המכפלה: $2 × 2 × 2 × 2 = 16$ דרכים",
                drawing: {
                    type: "distribution",
                    items: ["🍫", "🍭", "🍪", "🍬"],
                    recipients: ["ילד 1", "ילד 2"],
                    caption: "דוגמה לכמה אפשרויות חלוקה"
                }
            }
        ],
        quiz: [
            {
                question: "בכמה דרכים אפשר לבחור 2 מספרים שונים בין 1 ל-5?",
                type: "multiplechoice",
                options: ["10", "20", "15", "5"],
                hint: "חשבו על כמה אפשרויות יש לבחירה הראשונה, וכמה נשארו לבחירה השנייה. זכרו שהסדר לא חשוב",
                solutionGuide: "נפתור בשלבים:<br />1. מתוך 5 מספרים בוחרים 2, כשהסדר לא חשוב<br />2. נשתמש בנוסחת הבחירה: $\\binom{5}{2} = \\frac{5!}{2!(5-2)!}$<br />3. נציב: $\\frac{5 × 4}{2 × 1} = 10$ אפשרויות<br />4. למשל: {1,2}, {1,3}, {1,4}, {1,5}, {2,3}, {2,4}, {2,5}, {3,4}, {3,5}, {4,5}"
            },
            {
                question: "מה ההסתברות להטיל קובייה ולקבל מספר זוגי?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{1}{3}$", "$\\frac{2}{3}$", "$\\frac{1}{6}$"],
                hint: "רשמו את כל המספרים הזוגיים שיכולים לצאת בקובייה, וחשבו כמה הם מתוך כל האפשרויות",
                solutionGuide: "נבחן את המאורע:<br />מספרים זוגיים בקובייה: $\\{2,4,6\\}$<br />מספר המקרים הרצויים: 3<br />מספר כל המקרים האפשריים: 6<br />לכן ההסתברות היא: P(זוגי) = $\\frac{3}{6} = \\frac{1}{2}$"
            },
            {
                question: "בכמה דרכים אפשר לסדר 4 אנשים בשורה?",
                type: "multiplechoice",
                options: ["24", "12", "16", "8"],
                hint: "חשבו על כמה אפשרויות יש למקום הראשון, כמה למקום השני אחרי שמישהו כבר נבחר, וכן הלאה",
                solutionGuide: "סידור $n$ איברים שונים מכונה תמורה.<br />נוסחת התמורה היא: $n!$<br />במקרה זה: $4! = 4 \\times 3 \\times 2 \\times 1 = 24$ דרכים<br />לדוגמה: אם האנשים הם א,ב,ג,ד אז אפשר: אבגד, אבדג, אגבד, וכו׳"
            },
            {
                question: "בכמה דרכים אפשר לבחור 3 צבעים שונים מתוך 6 צבעים?",
                type: "multiplechoice",
                options: ["20", "216", "120", "15"],
                hint: "זכרו שהסדר לא חשוב - לא משנה באיזה סדר בוחרים את הצבעים. נסו לחשוב כמה אפשרויות יש לבחירה הראשונה, השנייה והשלישית",
                solutionGuide: "זוהי בחירת $k$ פריטים מתוך $n$ כשהסדר לא חשוב.<br />נשתמש בנוסחת הצירופים: $\\binom{6}{3} = \\frac{6!}{3!(6-3)!}$<br />נחשב: $\\frac{6 \\times 5 \\times 4}{3 \\times 2 \\times 1} = \\frac{120}{6} = 20$ אפשרויות"
            },
            {
                question: "בכמה דרכים אפשר לחלק 3 סוכריות שונות בין 2 ילדים?",
                type: "multiplechoice",
                options: ["8", "6", "9", "12"],
                hint: "לכל סוכריה יש שתי אפשרויות - ילד ראשון או שני. נסו לחשוב כמה החלטות צריך לקבל בסך הכל",
                solutionGuide: "לכל סוכריה יש 2 אפשרויות (ילד 1 או 2).<br />מכיוון שהסוכריות שונות, לפי כלל המכפלה:<br />$2 \\times 2 \\times 2 = 2^3 = 8$ דרכים<br />כל האפשרויות עם סוכריות 🍬,🍭,🍪:<br />ילד 1: (🍬🍭🍪), (🍬🍭), (🍬🍪), (🍭🍪), (🍬), (🍭), (🍪), ()<br />ילד 2: (), (🍪), (🍭), (🍬), (🍭🍪), (🍬🍪), (🍬🍭), (🍬🍭🍪)"
            },
            {
                question: "בכמה דרכים אפשר לבחור נציג ונציג סגן מכיתה של 20 תלמידים?",
                type: "multiplechoice",
                options: ["380", "190", "400", "40"],
                hint: "שימו לב שהסדר חשוב - נציג ראשי שונה מסגן. חשבו כמה אפשרויות יש לבחירה הראשונה, וכמה נשארו לבחירה השנייה",
                solutionGuide: "זוהי בחירת 2 תלמידים כשהסדר חשוב (נציג ראשי וסגן).<br />בחירה ראשונה: 20 אפשרויות<br />בחירה שנייה: 19 אפשרויות (נשאר פחות 1)<br />לפי כלל המכפלה: $20 \\times 19 = 380$ דרכים"
            },
            {
                question: "מה ההסתברות להוציא מלך מחפיסת קלפים רגילה?",
                type: "multiplechoice",
                options: ["$\\frac{1}{13}$", "$\\frac{1}{4}$", "$\\frac{2}{52}$", "$\\frac{13}{26}$"],
                hint: "חשבו כמה מלכים יש בחפיסה רגילה, וכמה קלפים יש בסך הכל",
                solutionGuide: "נחשב לפי הגדרת ההסתברות:<br />מספר המקרים הרצויים: 4 מלכים<br />מספר כל המקרים האפשריים: 52 קלפים<br />P({מלך}) = $\\frac{4}{52} = \\frac{1}{13}$"
            },
            {
                question: "בכמה דרכים אפשר לסדר את האותיות של המילה 'תפוח'?",
                type: "multiplechoice",
                options: ["24", "12", "60", "30"],
                hint: "ספרו כמה אותיות שונות יש במילה. חשבו על כמה אפשרויות יש למקום הראשון, השני, וכן הלאה",
                solutionGuide: "המילה 'תפוח' מכילה 4 אותיות שונות: ת,פ,ו,ח<br />סידור של $n$ איברים שונים נותן $n!$ אפשרויות<br />במקרה זה: $4! = 4 \\times 3 \\times 2 \\times 1 = 24$ דרכים<br />לדוגמה: תפוח, תפחו, תופח, וכו׳"
            },
            {
                question: "בכמה דרכים אפשר לבחור 3 מספרים שונים בין 1 ל-10?",
                type: "multiplechoice",
                options: ["120", "720", "360", "210"],
                hint: "זכרו שהמספרים צריכים להיות שונים זה מזה, והסדר לא חשוב. חשבו על כמה אפשרויות יש לכל בחירה",
                solutionGuide: "זוהי בחירת 3 מספרים שונים מתוך 10. נשתמש בנוסחה: $\\binom{10}{3} = \\frac{10!}{3!(10-3)!} = \\frac{10 × 9 × 8}{3 × 2 × 1} = 120$",
            },
            {
                question: "מה ההסתברות להטיל שתי קוביות ולקבל סכום קטן מ-5?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{1}{9}$", "$\\frac{1}{4}$", "$\\frac{1}{3}$"],
                hint: "רשמו את כל הזוגות של מספרים שסכומם קטן מ-5. זכרו שיש 36 אפשרויות שונות בסך הכל",
                solutionGuide: "נמצא את המקרים המתאימים:<br />(1,1)=2<br />(1,2),(2,1)=3<br />(1,3),(2,2),(3,1)=4<br /><br />סה״כ 6 מקרים אפשריים מתוך 36 מקרים אפשריים בשתי קוביות.<br /><br />לכן ההסתברות היא $\\frac{6}{36} = \\frac{1}{6}$",
            },
            {
                question: "בכמה דרכים אפשר לסדר 5 ילדים בשורה אם שני ילדים חייבים לשבת זה ליד זה?",
                type: "multiplechoice",
                options: ["48", "24", "72", "96"],
                hint: "נסו לחשוב על שני הילדים שחייבים לשבת יחד כיחידה אחת. כמה יחידות יש לסדר עכשיו?",
                solutionGuide: "נחשיב את שני הילדים כיחידה אחת, ואז יש 4! דרכים לסדר 4 יחידות, וזוג הילדים שיושבים תמיד יחד יכול להסתדר ב-2! דרכים. סה״כ $4! × 2! = 48$"
            },
            {
                question: "בכמה דרכים אפשר לבחור 2 תלמידים מתוך 5 לייצג את הכיתה?",
                type: "multiplechoice",
                options: ["10", "20", "25", "30"],
                hint: "זכרו שהסדר לא חשוב - לא משנה מי נבחר ראשון ומי שני. חשבו על כמה אפשרויות יש לבחירה הראשונה והשנייה",
                solutionGuide: "נחשוב על זה ככה:<br />1. יש 5 אפשרויות לבחור את התלמיד הראשון.<br />2. אחרי שבחרנו את הראשון, יש 4 אפשרויות לבחור את השני.<br />3. זה נראה כאילו יש $ 5\\times 4 = 20$ אפשרויות, אבל...<br />4. הסדר לא משנה (דני ואז שירה זה כמו שירה ואז דני), אז ספרנו כל זוג פעמיים.<br />5. נחלק ב-2 כדי לתקן: 2 / 20 = 10<br />לכן, יש 10 דרכים לבחור 2 תלמידים."
            },
            {
                question: "מה ההסתברות לקבל לפחות 6 בהטלת קובייה?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{1}{3}$", "$\\frac{1}{2}$", "$\\frac{2}{3}$"],
                hint: "חשבו על המקרה הרצוי - מתי מקבלים לפחות 6? כמה מספרים מקיימים את התנאי הזה?",
                solutionGuide: "יש רק דרך אחת לקבל 6 מתוך 6 אפשרויות, לכן $\\frac{1}{6}$"
            },
            {
                question: "בכמה דרכים אפשר לחלק 8 סוכריות זהות בין 3 ילדים?",
                type: "multiplechoice",
                options: ["45", "36", "28", "21"],
                hint: "נסו לחשוב על זה כמו הצבת מחיצות בין הסוכריות. כמה מחיצות צריך? כמה מקומות אפשריים יש למחיצות?",
                solutionGuide: "בואו נדמיין שיש לנו 8 סוכריות בשורה, ואנחנו צריכים להוסיף 2 'מחיצות' כדי לחלק את הסוכריות ל-3 ילדים. <br />למשל: ססס|סס|ססס - זה אומר שהילד הראשון קיבל 3, השני 2 והשלישי 3 סוכריות.<br />אז בעצם, אנחנו צריכים לסדר 8 סוכריות ו-2 מחיצות, סה\"כ 10 דברים. <br />זה כמו לבחור איפה לשים את 2 המחיצות מתוך 10 המקומות, ולכן יש $\\binom{10}{2} = \\frac{10 \\times 9}{2 \\times 1} = 45$ אפשרויות."
            },
            {
                question: "בכמה דרכים אפשר לבחור 3 מספרים (לא בהכרח שונים) בין 1 ל-5?",
                type: "multiplechoice",
                options: ["125", "60", "5", "100"],
                hint: "שימו לב שמותר לבחור אותו מספר יותר מפעם אחת. כמה אפשרויות יש לכל בחירה?",
                solutionGuide: "יש 5 אפשרויות לכל בחירה, ו-3 בחירות. לפי כלל המכפלה: $5^3 = 125$"
            },
            {
                question: "מה ההסתברות להוציא שני מלכים ברצף מחפיסת קלפים?",
                type: "multiplechoice",
                options: ["$\\frac{1}{221}$", "$\\frac{1}{17}$", "$\\frac{1}{91}$", "$\\frac{1}{15}$"],
                hint: "חשבו על ההסתברות להוציא מלך ראשון, ואז על ההסתברות להוציא מלך שני כשיש כבר מלך אחד בחוץ",
                solutionGuide: "ההסתברות למלך ראשון היא $\\frac{4}{52}$ והשני $\\frac{3}{51}$. לכן $\\frac{4}{52} × \\frac{3}{51} = \\frac{1}{13} × \\frac{3}{51} = \\frac{3}{663} = \\frac{1}{221}$"
            },
            {
                question: "בכמה דרכים אפשר לבחור 2 בנים ו-2 בנות מכיתה של 5 בנים ו-5 בנות?",
                type: "multiplechoice",
                options: ["100", "50", "30", "10"],
                hint: "פרקו את הבעיה לשני חלקים - בחירת הבנים ובחירת הבנות. כמה דרכים יש לבחור 2 מתוך 5 בכל קבוצה?",
                solutionGuide: "בואו נחשוב על זה בשני חלקים:<br />1. בחירת הבנים: יש לנו 5 בנים, ואנחנו רוצים לבחור 2. כמו שלמדנו קודם, יש 10 דרכים לעשות זאת ($\\frac{5 \\times 4}{2} = 10$).<br />2. בחירת הבנות: יש לנו 5 בנות, ואנחנו רוצים לבחור 2. שוב, יש 10 דרכים לעשות זאת ($\\frac{5 \\times 4}{2} = 10$).<br />3. השילוב: כדי לקבל את כל האפשרויות, אנחנו מכפילים את האפשרויות לבחירת בנים באפשרויות לבחירת בנות:  $ 10 \\times 10 = 100$.<br />לכן, יש 100 דרכים לבחור 2 בנים ו-2 בנות."
            },
            {
                question: "מה ההסתברות להטיל מטבע 3 פעמים ולקבל בדיוק 2 'עץ'?",
                type: "multiplechoice",
                options: ["$\\frac{3}{8}$", "$\\frac{1}{2}$", "$\\frac{1}{4}$", "$\\frac{5}{8}$"],
                hint: "חשבו על כל הדרכים האפשריות לקבל בדיוק 2 'עץ'. באילו מקומות יכולים להופיע שני ה'עץ'?",
                solutionGuide: "כאשר מטילים מטבע 3 פעמים, יש 8 תוצאות אפשריות. מתוכן, 3 תוצאות מכילות בדיוק 2 'עץ' (עץ-עץ-פלי, עץ-פלי-עץ, פלי-עץ-עץ). לכן, ההסתברות היא $\\frac{3}{8}$."            },
            {
                question: "בכמה דרכים אפשר לחלק 12 ספרים שונים בין 4 מדפים?",
                type: "multiplechoice",
                options: ["4^12", "12^4", "48", "16"],
                hint: "לכל ספר יש 4 אפשרויות (4 מדפים). כמה החלטות צריך לקבל בסך הכל?",
                solutionGuide: "כל אחד מ-12 הספרים יכול להיבחר עבור כל אחד מ-4 המדפים. לכן, יש $4 \\times 4 \\times ...$ (12 פעמים), שזה שווה ל-$4^{12}$."            },
            {
                question: "מה ההסתברות לקבל תוצאה זוגית בהטלת קובייה ותוצאה אי-זוגית בהטלת קובייה שנייה?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{1}{3}$", "$\\frac{1}{2}$", "$\\frac{1}{6}$"],
                hint: "חשבו על ההסתברות לקבל מספר זוגי בקובייה אחת, ואת ההסתברות לקבל מספר אי-זוגי בקובייה השנייה. איך משלבים את שתי ההסתברויות?",
                solutionGuide: "ההסתברות לקבל תוצאה זוגית בקובייה אחת היא $\\frac{1}{2}$, וההסתברות לקבל תוצאה אי-זוגית בקובייה אחרת היא גם $\\frac{1}{2}$. כדי למצוא את ההסתברות לשניהם יחד, נכפיל: $\\frac{1}{2} \\times \\frac{1}{2} = \\frac{1}{4}$."            },
            {
                question: "בכמה דרכים אפשר לבחור 3 צבעים מתוך 8 צבעים כאשר מותר לבחור אותו צבע יותר מפעם אחת?",
                type: "multiplechoice",
                options: ["512", "336", "120", "56"],
                hint: "שימו לב שמותר לבחור אותו צבע כמה פעמים שרוצים. כמה אפשרויות יש לכל בחירה?",
                solutionGuide: "לכל אחד משלושת הצבעים שאנחנו בוחרים, יש 8 אפשרויות (מכיוון שמותר לבחור אותו צבע יותר מפעם אחת). לכן, מספר האפשרויות הוא $8 \\times 8 \\times 8 = 8^3 = 512$."            },
            {
                question: "בכמה דרכים אפשר לסדר 6 אנשים סביב שולחן עגול?",
                type: "multiplechoice",
                options: ["120", "720", "60", "360"],
                hint: "חשבו על ההבדל בין סידור בשורה לבין סידור במעגל. האם המיקום המוחלט חשוב, או רק המיקום היחסי?",
                solutionGuide: "בסידור אנשים סביב שולחן עגול, הסדר *היחסיחשוב, ולא המיקום המוחלט. לכן, מספר הדרכים לסדר 6 אנשים הוא $(6-1)! = 5! = 120$."            },
            {
                question: "מה ההסתברות להוציא קלף אס אחד מחפיסה?",
                type: "multiplechoice",
                options: ["$\\frac{1}{13}$", "$\\frac{1}{26}$", "$\\frac{1}{52}$", "$\\frac{1}{4}$"],
                hint: "חשבו כמה אסים יש בחפיסה רגילה, וכמה קלפים יש בסך הכל",
                solutionGuide: "בחפיסה סטנדרטית יש 52 קלפים, כאשר 4 מהם הם אסים. לכן ההסתברות להוציא אס היא $\\frac{4}{52}$, שזה מצטמצם ל-$\\frac{1}{13}$."            },
            {
                question: "בכמה דרכים אפשר לחלק 3 כדורים שונים בין 2 קבוצות?",
                type: "multiplechoice",
                options: ["8", "6", "4", "2"],
                hint: "לכל כדור יש שתי אפשרויות - קבוצה 1 או קבוצה 2. כמה החלטות צריך לקבל בסך הכל?",
                solutionGuide: "כל אחד מ-3 הכדורים יכול להיכנס לאחת משתי הקבוצות. מכאן שיש $2 \\times 2 \\times 2 = 2^3 = 8$ אפשרויות חלוקה שונות."            },
            {
                question: "בכמה דרכים אפשר לבחור 3 מספרים בין 1 ל-10?",
                type: "multiplechoice",
                options: ["120", "90", "60", "30"],
                hint: "זכרו שהסדר לא חשוב ושהמספרים צריכים להיות שונים זה מזה. חשבו על כמה אפשרויות יש לכל בחירה",
                solutionGuide: "זוהי בעיה של בחירת 3 מספרים שונים מתוך 10, כאשר הסדר אינו חשוב. נשתמש בנוסחת הצירופים: $\\binom{10}{3} = \\frac{10!}{3! \\times 7!} = \\frac{10 \\times 9 \\times 8}{3 \\times 2 \\times 1} = 120$."            
            },
            {
                question: "מה ההסתברות לקבל מספר זוגי בהטלת קובייה?",
                type: "multiplechoice",
                options: ["1/2", "1/3", "1/4", "1/6"],
                hint: "רשמו את כל המספרים הזוגיים שיכולים לצאת בקובייה, וחשבו כמה הם מתוך כל האפשרויות",
                solutionGuide: "בקובייה יש 6 פאות, מתוכן 3 הן זוגיות (2, 4, ו-6). לכן, ההסתברות לקבל תוצאה זוגית היא $\\frac{3}{6} = \\frac{1}{2}$."            
            },
            {
                question: "בכמה דרכים אפשר לחלק 15 עוגיות זהות בין 4 ילדים, כך שכל ילד מקבל לפחות עוגייה אחת?",
                type: "multiplechoice",
                options: ["364", "455", "280", "196"],
                hint: "נסו קודם לתת לכל ילד עוגייה אחת. כמה עוגיות נשארו? עכשיו חשבו על חלוקת העוגיות הנותרות",
                solutionGuide: "נשתמש בשיטת המחיצות, אבל קודם ניתן לכל ילד עוגייה אחת.<br />נשארו 11 עוגיות לחלק בין 4 ילדים.<br />זה שקול לבחירת 3 מחיצות מתוך 14 מקומות אפשריים (11+3).<br />לכן: $\\binom{14}{3} = \\frac{14!}{3!(14-3)!} = \\frac{14 × 13 × 12}{6} = 364$ דרכים"
            },
            {
                question: "בטורניר שחמט משתתפים 8 שחקנים. בכמה דרכים אפשר לבחור מקום ראשון, שני ושלישי?",
                type: "multiplechoice",
                options: ["336", "256", "512", "448"],
                hint: "חשבו על תהליך הבחירה בשלבים - כמה שחקנים יש לבחור מתוכם למקום ראשון? כמה נשארו לבחור מתוכם למקום שני? וכן הלאה",
                solutionGuide: "זוהי בעיית סידור עם סדר חשוב.<br />למקום ראשון: 8 אפשרויות<br />למקום שני: 7 אפשרויות<br />למקום שלישי: 6 אפשרויות<br />לפי כלל המכפלה: $8 × 7 × 6 = 336$ דרכים"
            },
            {
                question: "מה ההסתברות להטיל שלוש קוביות ולקבל סכום של בדיוק 7?",
                type: "multiplechoice",
                options: ["$\\frac{15}{216}$", "$\\frac{1}{6}$", "$\\frac{1}{12}$", "$\\frac{1}{36}$"],
                hint: "נסו לרשום כמה דוגמאות של שלשות מספרים שסכומם 7. שימו לב שצריך לספור את כל האפשרויות ולחלק במספר כל התוצאות האפשריות",
                solutionGuide: "נספור את כל הדרכים לקבל סכום 7 בשלוש קוביות:<br />1+1+5, 1+2+4, 1+3+3, 1+4+2, 1+5+1<br />2+1+4, 2+2+3, 2+3+2, 2+4+1<br />3+1+3, 3+2+2, 3+3+1<br />4+1+2, 4+2+1<br />5+1+1<br />סה״כ 15 דרכים מתוך 216 אפשרויות ($6^3$)<br />לכן ההסתברות היא $\\frac{15}{216}$"
            },
            {
                question: "בכמה דרכים אפשר לחלק 5 ספרים שונים בין 3 תלמידים?",
                type: "multiplechoice",
                options: ["243", "125", "32", "81"],
                hint: "עבור כל ספר, חשבו כמה אפשרויות יש להקצאה. האם סדר החלוקה של הספרים משנה? האם מותר שתלמיד לא יקבל ספרים?",
                solutionGuide: "לכל ספר יש 3 אפשרויות (תלמיד 1, 2 או 3).<br />מכיוון שהספרים שונים, לפי כלל המכפלה:<br />$3 × 3 × 3 × 3 × 3 = 3^5 = 243$ דרכים<br />למשל: אם הספרים הם א,ב,ג,ד,ה אז אפשר:<br />תלמיד 1: (א,ב), תלמיד 2: (ג,ד), תלמיד 3: (ה)<br />או: תלמיד 1: (א), תלמיד 2: (ב,ג,ד), תלמיד 3: (ה)"
            }
        ]
    }
};