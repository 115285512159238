export const learningContent = {
    "מספרים עשרוניים": {
        tags: ['כיתה ו׳', 'כיתה ז׳', 'מספרים עשרוניים'],

        relatedLinks: [
            {
                title: "תרגול מספרים עשרוניים",
                url: "/?tags=מספרים%20עשרוניים",
                description: "תרגול נוסף במספרים עשרוניים"
            },
            {
                title: "שברים ומספרים עשרוניים",
                url: "/?tags=שברים,מספרים%20עשרוניים",
                description: "תרגול משולב של שברים ומספרים עשרוניים"
            },
            {
                title: "בעיות מילוליות במספרים עשרוניים",
                url: "/?tags=מספרים%20עשרוניים,בעיות%20מילוליות",
                description: "תרגול בעיות מילוליות עם מספרים עשרוניים"
            }
        ],

        getStarted: [
            "**מהם מספרים עשרוניים?**",
            "מספרים עשרוניים מייצגים חלקים של שלם באמצעות נקודה עשרונית",
            "",
            "**מבנה המספר העשרוני:**",
            "ספרות שמאל לנקודה = יחידות שלמות",
            "ספרה ראשונה אחרי הנקודה = עשיריות ($\\frac{1}{10} $)",
            "ספרה שנייה = מאיות ($\\frac{1}{100} $)",
            "ספרה שלישית = אלפיות ($\\frac{1}{1000} $)",
            "",
            "**המרות:**",
            "משבר פשוט לעשרוני: לחלק מונה במכנה",
            "מעשרוני לשבר: לכתוב כמונה עם מכנה של חזקת 10",
            "דוגמה: 0.25 = $\\frac{25}{100} = \\frac{1}{4} $",
            "",
            "**צפיפות המספרים העשרוניים:**",
            "בין כל שני מספרים עשרוניים יש אינסוף מספרים",
            "אפשר תמיד להוסיף ספרות נוספות אחרי הנקודה",
            "דוגמה: בין 0.1 ל-0.2 יש 0.11, 0.12, 0.13, ..."
        ],
        examples: [
            {
                question: "כמה מספרים עשרוניים יש בין 0.22 ל-0.223?",
                solution: "יש אינסוף מספרים, למשל:<br/>0.221, 0.222, 0.2221, 0.2222, ...",
                drawing: {
                    type: "numberLine",
                    min: 0.22,
                    max: 0.223,
                    points: [0.221, 0.222],
                    labels: ["0.22", "0.221", "0.222", "0.223"],
                    caption: "הדגמה של מספרים בין 0.22 ל-0.223"
                }
            },
            {
                question: "איך נכתוב את $\\frac{3}{8} $ כמספר עשרוני?",
                solution: "$\\frac{3}{8} $ הוא 3 פעמים $\\frac{1}{8} $, כאשר $\\frac{1}{8} = 0.125$:<br/>$3 × 0.125 = 0.375$",
            },
            {
                question: "מהו הערך של כל ספרה במספר 2.354?",
                solution: "2 = יחידות<br/>3 = עשיריות = $\\frac{3}{10} $<br/>5 = מאיות = $\\frac{5}{100} $<br/>4 = אלפיות = $\\frac{4}{1000} $",
                drawing: {
                    type: "placeValue",
                    number: "2.354",
                    values: [
                        { digit: "4", place: "אלפיות", value: "0.004" },
                        { digit: "5", place: "מאיות", value: "0.05" },
                        { digit: "3", place: "עשיריות", value: "0.3" },
                        { digit: "2", place: "יחידות", value: "2" },

                    ],
                    caption: "ערך המקום של כל ספרה במספר עשרוני"
                }
            },
            {
                question: "איזה מספר גדול יותר: 0.8 או 0.75?",
                solution: "0.8 = 0.80 גדול יותר מ-0.75",
                drawing: {
                    type: "numberLine",
                    min: 0.7,
                    max: 0.9,
                    points: [0.75, 0.8],
                    labels: ["0.75", "0.8"],
                    caption: "השוואת מספרים עשרוניים על ציר המספרים"
                }
            }
        ],
        quiz: [
            {
                question: "מה השבר העשרוני המייצג את $\\frac{{A}}{B} $?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 3],    // Numerator for quarters (1/4, 2/4, 3/4)
                        type: "integer"
                    },
                    B: {
                        range: [4, 5],    // Numerator for quarters (1/4, 2/4, 3/4)
                        type: "integer"
                    }
                },
                formula: "A/B",          // Will match the correct fraction
                metadata: {
                    decimal: "{A}/{B}",     // Will give 0.25, 0.5, or 0.75
                },
                distractors: {
                    strategies: [
                        "double",  // e.g., 0.75 → 7/5
                        "half",        // e.g., 0.75 → 75/100
                        "off-by-10th"                 // e.g., 0.75 → 7/10
                    ],
                },
        
                hint: "המרו את השבר לעשרוני וצמצמו אם אפשר",
                solutionGuide: "$\\frac{{A}}{B} = {decimal} $ אחרי צמצום"
            },
            {
                question: "מה השבר העשרוני המייצג את $\\frac{{A}}{B} $?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 7],
                        type: "integer"
                    },
                    B: {
                        range: [8, 8],
                        type: "integer"
                    }
                },
                formula: "A/B",     
                metadata: {
                    decimal: "{A}/{B}",
                },
                distractors: {
                    strategies: [
                        "double", 
                        "half",    
                        "off-by-10th"   
                    ],
                },
        
                hint: "המרו את השבר לעשרוני וצמצמו אם אפשר",
                solutionGuide: "$\\frac{{A}}{B} = {decimal} $ אחרי צמצום"
            },

            {
                question: "כמה אלפיות יש במספר {decimal}?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 8],    // First digit
                        type: "integer"
                    },
                    B: {
                        range: [2, 8],    // Second digit
                        type: "integer"
                    },
                    C: {
                        range: [3, 9],    // Third digit
                        type: "integer"
                    }
                },
                formula: "A*100 + B*10 + C",  // Total thousandths
                metadata: {
                    decimal: "0.{A}{B}{C}"
                },
                distractors: {
                    strategies: [
                        "get-hundreds",        // e.g., just C
                        "get-hundreds-tens",        // e.g., B*10 + C
                        "get-tens-ones",      // e.g., A.BC
                    ]
                },
                hint: "חשבו כמה אלפיות יש בכל ספרה אחרי הנקודה העשרונית",
                solutionGuide: " $ {decimal} = \\frac{{Result}}{1000} = {Result} $ אלפיות"
            },

            {
                question: "כמה מאיות יש במספר {decimal}?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 8],    // First digit
                        type: "integer"
                    },
                    B: {
                        range: [2, 8],    // Second digit
                        type: "integer"
                    },
                    C: {
                        range: [3, 9],    // Third digit
                        type: "integer"
                    }
                },
                formula: "A*10 + B",  // Total hundredths (ignoring C since we only want hundredths)
                metadata: {
                    decimal: "0.{A}{B}{C}"
                },
                distractors: {
                    strategies: [
                        "get-hundreds",
                        "get-hundreds-tens",
                        "get-ones",
                    ]
                },
                hint: "חשבו כמה מאיות יש בשתי הספרות הראשונות אחרי הנקודה העשרונית",
                solutionGuide: " $ {decimal} מכיל {Result} מאיות (הספרות {A}{B})"
            },

            {
                question: "כמה עשיריות יש במספר {decimal}?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 8],    // First digit
                        type: "integer"
                    },
                    B: {
                        range: [2, 8],    // Second digit
                        type: "integer"
                    },
                    C: {
                        range: [3, 9],    // Third digit
                        type: "integer"
                    }
                },
                formula: "A",  // Total tenths (only first digit matters for tenths)
                metadata: {
                    decimal: "0.{A}{B}{C}"
                },
                distractors: {
                    strategies: [
                        "off-by-one",
                        "plus-two",
                        "double",
                    ]
                },
                hint: "חשבו כמה עשיריות יש בספרה הראשונה אחרי הנקודה העשרונית",
                solutionGuide: " $ {decimal} מכיל {Result} עשיריות (הספרה {A})"
            },

            {
                question: "איזה מספר נמצא בין 0.4 ל-0.5?",
                type: "multiplechoice",
                options: ["0.45", "0.54", "0.35", "0.25"],
                hint: "נסו לסדר את המספרים מהקטן לגדול על ציר המספרים",
                solutionGuide: "נסדר את המספרים מהקטן לגדול:<br/>0.25 < 0.35 < 0.4 < 0.45 < 0.5 < 0.54<br/>לכן 0.45 נמצא בין 0.4 ל-0.5"
            },
            {
                question: "מה השבר הפשוט המייצג את 0.75?",
                type: "multiplechoice",
                options: ["$\\frac{3}{4} $", "$\\frac{7}{5} $", "$\\frac{7.5}{100} $", "$\\frac{7}{10} $"],
                hint: "המירו את המספר העשרוני לשבר עם מכנה 100 וצמצמו",
                solutionGuide: "$0.75 = \\frac{75}{100} = \\frac{3}{4} $"
            },
            {
                question: "כמה אלפיות יש במספר 0.234?",
                type: "multiplechoice",
                options: ["234", "34", "2.34", "4"],
                hint: "חשבו כמה אלפיות יש בכל ספרה אחרי הנקודה העשרונית",
                solutionGuide: "0.234 = $\\frac{234}{1000} $ = 234 אלפיות"
            },
            {
                question: "בחרו במספר הכי גדול",
                type: "multiplechoice",
                options: ["0.8", "0.75", "0.699", "0.7"],
                hint: "השוו את המספרים על ידי הוספת אפסים בסוף כך שלכולם יהיה אותו מספר ספרות אחרי הנקודה",
                solutionGuide: "נשווה: 0.699 < 0.7 < 0.75 < 0.8"
            },
            {
                question: "מהו הערך של הספרה 5 במספר 3.157?",
                type: "multiplechoice",
                options: ["מאיות", "אלפיות", "יחידות", "עשיריות"],
                hint: "ספרו את המיקום של הספרות אחרי הנקודה העשרונית משמאל לימין",
                solutionGuide: "במספר 3.157: 5 נמצא במקום המאיות ($3 . 1|5|7$)"
            },
            {
                question: "מהו השבר הפשוט המתאים ל-0.125?",
                type: "multiplechoice",
                options: ["$\\frac{1}{8} $", "$\\frac{1}{4} $", "$\\frac{1}{5} $", "$\\frac{1}{6} $"],
                hint: "נסו לחשוב על חלוקת 1 לחלקים שווים. איזה מהשברים המוצעים נותן 0.125 בחישוב?",
                solutionGuide: "0.125 = $\\frac{125}{1000} $ = $\\frac{1}{8} $"
            },
            {
                question: "איזה מספר קרוב יותר ל-1?",
                type: "multiplechoice",
                options: ["0.901", "0.89", "0.8999", "0.899"],
                hint: "סדרו את המספרים מהקטן לגדול. המספר הגדול ביותר יהיה הקרוב ביותר ל-1",
                solutionGuide: "0.89 < 0.899 < 0.8999 < 0.901<br/>לכן 0.901 הכי קרוב ל-1"
            },
            {
                question: "כמה עשיריות יש במספר 2.456?",
                type: "multiplechoice",
                options: ["4", "5", "2", "6"],
                hint: "הספרה הראשונה אחרי הנקודה העשרונית מייצגת את העשיריות",
                solutionGuide: "במספר 2.456, הספרה 4 במקום העשיריות = 4 עשיריות"
            },
            {
                question: "איזה מהמספרים הבאים שווה ל-$\\frac{3}{2} $?",
                type: "multiplechoice",
                options: ["1.5", "1.25", "1.35", "1.75"],
                hint: "חלקו 3 ב-2 או חשבו כמה חצאים יש ב-3",
                solutionGuide: "$\\frac{3}{2} = 1.5$ כי $3 ÷ 2 = 1.5$"
            },
            {
                question: "איזה מספר נמצא בין 0.8 ל-0.9?",
                type: "multiplechoice",
                options: ["0.88", "0.95", "0.75", "0.65"],
                hint: "סדרו את המספרים על ציר המספרים וחפשו מספר שנמצא בין 0.8 ל-0.9",
                solutionGuide: "0.65 < 0.75 < 0.8 < 0.88 < 0.9 < 0.95"
            },
            {
                question: "כמה מאיות יש במספר 3.842?",
                type: "multiplechoice",
                options: ["84", "4", "8", "42"],
                hint: "הסתכלו על שתי הספרות הראשונות אחרי הנקודה העשרונית",
                solutionGuide: "3.842 = 3 + $\\frac{84}{100} $ + $\\frac{2}{1000} $ = 84 מאיות"
            },
            {
                question: "איזה מספר קטן מ-0.3?",
                type: "multiplechoice",
                options: ["0.299", "0.303", "0.33", "0.3001"],
                hint: "השוו כל מספר ל-0.3. אם צריך, הוסיפו אפסים בסוף המספרים להשוואה קלה יותר",
                solutionGuide: "0.299 < 0.3 < 0.303 < 0.31 < 0.33"
            },
            {
                question: "מה המספר העשרוני המתאים לשבר $\\frac{3}{4} $?",
                type: "multiplechoice",
                options: ["0.75", "0.85", "0.74", "0.7"],
                hint: "חשבו כמה רבעים יש ב-1 שלם והשתמשו בזה כדי למצוא את הערך של רבע אחד",
                solutionGuide: "$\\frac{3}{4} = \\frac{75}{100} = 0.75$"
            },
            {
                question: "איזה מספר גדול מ-1.99?",
                type: "multiplechoice",
                options: ["1.991", "1.989", "1.909", "1.901"],
                hint: "סדרו את המספרים מהקטן לגדול וחפשו את אלה שגדולים מ-1.99",
                solutionGuide: "1.909 < 1.989 < 1.99 < 1.991"
            },
            {
                question: "איזה מספר קרוב יותר ל-0.5?",
                type: "multiplechoice",
                options: ["0.501", "0.489", "0.472", "0.481"],
                hint: "חשבו על המרחק של כל מספר מ-0.5 על ידי חיסור",
                solutionGuide: "0.501 קרוב יותר ל-0.5 כי המרחק שלו מ-0.5 הוא $0.501 - 0.5 = 0.001$ בעוד שהמרחק של 0.489 מ-0.5 הוא $0.5 - 0.489 = 0.011$. $0.011 > 0.001$, לכן 0.501 קרוב יותר."
            },
            {
                question: "מהו השבר הפשוט המתאים ל-0.8?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5} $", "$\\frac{3}{4} $", "$\\frac{2}{3} $", "$\\frac{5}{6} $"],
                hint: "המירו את המספר העשרוני לשבר עם מכנה 10 וצמצמו",
                solutionGuide: "0.8 = $\\frac{8}{10} $ = $\\frac{4}{5} $"
            },
            {
                question: "איזה מספר גדול יותר מ-1.001?",
                type: "multiplechoice",
                options: ["1.01", "1.0001", "1.0009", "1.0008"],
                hint: "הוסיפו אפסים בסוף המספרים כך שלכולם יהיה אותו מספר ספרות ואז השוו",
                solutionGuide: "1.0001 < 1.0008 < 1.0009 < 1.001 < 1.01"
            },
            {
                question: "מה המספר העשרוני המתאים לשבר $\\frac{7}{8} $?",
                type: "multiplechoice",
                options: ["0.875", "0.87", "0.88", "0.89"],
                hint: "חשבו קודם כמה שווה $\\frac{1}{8} $ כמספר עשרוני ואז הכפילו ב-7",
                solutionGuide: "$\\frac{7}{8} = 7 \\times \\frac{1}{8} = 7 \\times 0.125 = 0.875$"
            },
            {
                question: "כמה מאיות יש במספר 2.035?",
                type: "multiplechoice",
                options: ["3", "35", "53", "305"],
                hint: "במספר עשרוני, המיקום של כל ספרה קובע את ערכה. חשבו איזו ספרה נמצאת במקום המאיות (המקום השני אחרי הנקודה)",
                solutionGuide: "במספר 2.035, הספרה 3 נמצאת במקום המאיות = 3 מאיות"
            },
            {
                question: "בחרו במספר הקטן ביותר",
                type: "multiplechoice",
                options: ["0.099", "0.1", "0.101", "0.11"],
                hint: "כדי להשוות מספרים עשרוניים, אפשר להוסיף אפסים בסוף המספרים כך שיהיה להם אותו מספר ספרות אחרי הנקודה",
                solutionGuide: "0.099 < 0.1 < 0.101 < 0.11"
            },
            {
                question: "מהו המספר העשרוני המתקבל מחיבור $\\frac{1}{5} $ ו-$\\frac{3}{10} $?",
                type: "multiplechoice",
                options: ["0.5", "0.4", "0.45", "0.35"],
                hint: "המירו כל שבר למספר עשרוני. זכרו ש-$\\frac{1}{10} $ = 0.1",
                solutionGuide: "$\\frac{1}{5} = 0.2$ ו-$\\frac{3}{10} = 0.3$, לכן $\\frac{1}{5} + \\frac{3}{10} = 0.2 + 0.3 = 0.5$"
            },
            {
                question: "איזה מהמספרים הבאים מייצג שלושה רבעים של עשירית?",
                type: "multiplechoice",
                options: ["0.075", "0.75", "0.175", "0.0075"],
                hint: "חשבו קודם כמה שווה עשירית (0.1), ואז מצאו שלושה רבעים ממנה",
                solutionGuide: "שלושה רבעים של עשירית = $\\frac{3}{4} \\times \\frac{1}{10} = \\frac{3}{40} = 0.075$<br/>או: $\\frac{3}{4} = 0.75$, ולכן $0.75 \\times \\frac{1}{10} = 0.075$"
            },
            {
                question: "כמה אפסים צריך להוסיף אחרי הנקודה כדי לכתוב $\\frac{1}{1000000} $ כמספר עשרוני?",
                type: "multiplechoice",
                options: ["5", "4", "6", "7"],
                hint: "ספרו כמה אפסים יש במכנה וחשבו כמה אפסים צריך אחרי הנקודה כדי להגיע ל-1",
                solutionGuide: "$\\frac{1}{1000000} = 0.000001$ - צריך 5 אפסים"
            },
            {
                question: "איזה מספר נמצא בדיוק באמצע בין 3.14 ל-3.16?",
                type: "multiplechoice",
                options: ["3.15", "3.145", "3.155", "3.165"],
                hint: "מצאו את המרחק בין שני המספרים וחלקו אותו לשניים",
                solutionGuide: "המרחק בין 3.14 ל-3.16 הוא 0.02. חצי מזה הוא 0.01. לכן המספר האמצעי הוא 3.14 + 0.01 = 3.15"
            },
            {
                question: "אם נכפיל 0.125 ב-8, איזה מספר נקבל?",
                type: "multiplechoice",
                options: ["1", "0.8", "1.25", "2"],
                hint: "0.125 הוא שבר פשוט. נסו לזהות איזה שבר (רמז: חשבו על שמינית)",
                solutionGuide: "0.125 × 8 = $\\frac{1}{8} \\times 8 = 1$"
            },
            {
                question: "איזה מהמספרים הבאים שווה ל-2.5% כמספר עשרוני?",
                type: "multiplechoice",
                options: ["0.025", "0.25", "0.0025", "0.205"],
                hint: "כדי להמיר אחוזים למספר עשרוני, חלקו ב-100",
                solutionGuide: "2.5% = $\\frac{2.5}{100} = 0.025$"
            },
            {
                question: "מהו הסכום של 0.1, 0.01 ו-0.001?",
                type: "multiplechoice",
                options: ["0.111", "1.11", "0.0111", "0.1011"],
                hint: "סדרו את המספרים זה מתחת לזה כך שהנקודות העשרוניות מיושרות, וחברו",
                solutionGuide: "0.1 + 0.01 + 0.001 = 0.111 (עשירית + מאית + אלפית)"
            },
            {
                question: "איזה מספר מהבאים הוא הקרוב ביותר ל-$\\pi$ (3.14159...)?",
                type: "multiplechoice",
                options: ["3.142", "3.140", "3.145", "3.150"],
                hint: "השוו כל מספר ל-3.14159 וחשבו את ההפרש. המספר עם ההפרש הקטן ביותר הוא הקרוב ביותר",
                solutionGuide: "3.142 הוא הקרוב ביותר ל-3.14159..."
            },
            {
                question: "כמה פעמים צריך לחבר 0.2 לעצמו כדי לקבל 1?",
                type: "multiplechoice",
                options: ["5", "4", "6", "3"],
                hint: "נסו לחבר 0.2 לעצמו עד שתגיעו ל-1, או חשבו: כמה פעמים 0.2 נכנס ב-1?",
                solutionGuide: "0.2 × 5 = 1, או: 0.2 + 0.2 + 0.2 + 0.2 + 0.2 = 1"
            },
            {
                question: "איזה מספר עשרוני מייצג שתי חמישיות ועוד שלוש עשיריות?",
                type: "multiplechoice",
                options: ["0.7", "0.5", "0.6", "0.8"],
                hint: "המירו כל שבר למספר עשרוני בנפרד ($\\frac{2}{5} $ ו-$\\frac{3}{10} $) ואז חברו",
                solutionGuide: "$\\frac{2}{5} + \\frac{3}{10} = 0.4 + 0.3 = 0.7$"
            },
            {
                question: "סדרו את המספרים העשרוניים מהקטן לגדול",
                type: "order",
                items: ["0.099", "0.1", "0.101", "0.11"],
                hint: "המירו את כל המספרים לאותו מספר ספרות אחרי הנקודה להשוואה קלה יותר",
                solutionGuide: "$0.099 = 0.099$<br/>$0.1 = 0.100$<br/>$0.101 = 0.101$<br/>$0.11 = 0.110$"
            },
            {
                question: "סדרו את השברים הבאים מהקטן לגדול",
                type: "order",
                items: ["0.2", "0.25", "0.3", "0.5"],
                hint: "הוסיפו אפסים בסוף המספרים להשוואה קלה יותר",
                solutionGuide: "0.20 < 0.25 < 0.30 < 0.50"
            },
            {
                question: "סדרו את המספרים לפי המרחק שלהם מ-1 (מהקרוב לרחוק)",
                type: "order",
                items: ["1.08", "0.91", "1.1", "0.89"],
                hint: "חשבו את ההפרש המוחלט של כל מספר מ-1",
                solutionGuide: "$|1 - 1.08| = 0.08$<br/>$|1 - 0.91| = 0.09$<br/>$|1 - 1.1| = 0.1$<br/>$|1 - 0.89| = 0.11$"
            },
            {
                question: "סדרו את המספרים העשרוניים מהגדול לקטן",
                type: "order",
                items: ["2.001", "2", "1.999", "1.99"],
                hint: "הוסיפו אפסים בסוף המספרים להשוואה קלה יותר",
                solutionGuide: "$2.001 > 2.000 > 1.999 > 1.990$"
            },
            {
                question: "התאימו כל מספר עשרוני לייצוג המתאים שלו",
                type: "matching",
                pairs: [
                    { left: "$0.75$", right: "$\\frac{3}{4} $" },
                    { left: "$0.5$", right: "חצי" },
                    { left: "$0.25$", right: "רבע" },
                    { left: "$0.125$", right: "שמינית" },
                    { left: "$0.2$", right: "$\\frac{1}{5} $" }
                ],
                hint: "חשבו על השברים הפשוטים המוכרים והמרתם למספרים עשרוניים",
                solutionGuide: "$0.75 = \\frac{3}{4} $<br/>$0.5 = \\frac{1}{2} $<br/>$0.25 = \\frac{1}{4} $<br/>$0.125 = \\frac{1}{8} $<br/>$0.2 = \\frac{1}{5} $"
            },
            {
                question: "התאימו כל מספר עשרוני למיקום הספרות שלו",
                type: "matching",
                pairs: [
                    { left: "$0.4$", right: "4 עשיריות" },
                    { left: "$0.04$", right: "4 מאיות" },
                    { left: "$0.004$", right: "4 אלפיות" },
                    { left: "$4.0$", right: "4 יחידות" },
                ],
                hint: "ספרו את מספר האפסים אחרי הנקודה עד לספרה 4",
                solutionGuide: "0.4 = 4 עשיריות<br/>0.04 = 4 מאיות<br/>0.004 = 4 אלפיות<br/>4.0 = 4 יחידות<br/>0.0004 = 4 רבבות"
            },
            {
                question: "התאימו כל ביטוי לתוצאה העשרונית שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2} + \\frac{1}{4} $", right: "$0.75$" },
                    { left: "$\\frac{1}{5} + \\frac{1}{10} $", right: "$0.3$" },
                    { left: "$\\frac{3}{10} + \\frac{1}{5} $", right: "$0.5$" },
                    { left: "$1 - \\frac{1}{8} $", right: "$0.875$" }
                ],
                hint: "המירו כל שבר למספר עשרוני ובצעו את החישוב",
                solutionGuide: "$\\frac{1}{2} + \\frac{1}{4} = 0.5 + 0.25 = 0.75$<br/>$\\frac{1}{5} + \\frac{1}{10} = 0.2 + 0.1 = 0.3$<br/>$1 - \\frac{1}{4} = 1 - 0.25 = 0.75$<br/>$\\frac{3}{10} + \\frac{1}{5} = 0.3 + 0.2 = 0.5$<br/>$1 - \\frac{1}{8} = 1 - 0.125 = 0.875$"
            },
            {
                question: "התאימו כל מספר עשרוני לתיאור שלו",
                type: "matching",
                pairs: [
                    { left: "$0.25$", right: "רבע" },
                    { left: "$0.333...$", right: "שליש" },
                    { left: "$0.8$", right: "ארבע חמישיות" },
                    { left: "$1.4$", right: "שבע חמישיות" },
                    { left: "$1.25$", right: "אחד ורבע" }
                ],
                hint: "חשבו על השברים הפשוטים המוכרים",
                solutionGuide: "$0.25 = \\frac{1}{4} $<br/>$0.333... = \\frac{1}{3} $<br/>$0.8 = \\frac{4}{5} $<br/>$1.4 = \\frac{7}{5} $<br/>$1.25 = 1\\frac{1}{4} $"
            }
        ]
    }
};
