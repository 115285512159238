import React from 'react';
import { 
  Box, 
  SimpleGrid, 
  Button, 
  Image, 
  Text, 
  useToast, 
  VStack,
  HStack,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCoins } from 'react-icons/fa';
import { useCurrency } from '../hooks/useCurrency';
import { useCharacter } from '../hooks/useCharacter';
import {getCharactersList } from '../config/coinRewards';

export const CharacterStore = () => {
  const { coins, deductCoins } = useCurrency();
  const { selectedCharacter, unlockedCharacters, selectCharacter, unlockCharacter } = useCharacter();
  const toast = useToast();
  const characters = getCharactersList();
  const bg = useColorModeValue(
    'linear(to-r, blue.50, pink.50)',
    'linear(to-r, blue.900, pink.900)'
  );

  const handlePurchase = (character) => {
    if (coins >= character.price) {
      deductCoins(character.price, `${character.id}`);
      unlockCharacter(character.id);
      toast({
        title: 'Character Unlocked!',
        description: `You've unlocked ${character.name}!`,
        status: 'success',
        duration: 3000,
      });
    }
  };

  return (
    <Box p={4} bgGradient={bg} borderRadius="xl">
      <HStack justify="space-between" mb={6}>
        <Text fontSize="2xl" fontWeight="bold">Character Store</Text>
        <HStack 
          bg="transparent"
          color="black"
          border="2.5px dashed"
          borderColor="yellow.400"
          background= "yellow.50"
          boxShadow="0 4px 12px rgba(255, 198, 0, 0.3)"
          borderRadius="2xl"
          px={4} 
          py={1}
        >
          <Icon as={FaCoins} color="yellow.400" />
          <Text fontWeight="bold">{coins}</Text>
        </HStack>
      </HStack>

      <SimpleGrid 
        columns={{ base: 2, sm: 2, md: 3 }} 
        spacing={{ base: 2, sm: 6, md: 6 }}
      >
        {characters.map(character => {
          const isUnlocked = unlockedCharacters.includes(character.id);
          const isSelected = selectedCharacter === character.id;
          const canAfford = coins >= character.price;

          return (
            <Box 
              key={character.id} 
              bg="white"
              p={{ base: 2, sm: 3, md: 3 }}
              borderRadius="xl" 
              boxShadow="md"
              border="2px solid"
              borderColor={isSelected ? 'brand.500' : 'transparent'}
              transition="all 0.2s"
              _hover={{
                transform: isUnlocked ? 'translateY(-4px) scale(1.05)' : 'none',
                boxShadow: isUnlocked ? 'xl' : 'md',
                cursor: isUnlocked ? 'pointer' : 'default',
              }}
              onClick={() => isUnlocked && selectCharacter(character.id)}
              position="relative"
              height={{ base: "140px", sm: "280px" }}
              width="100%"
            >
              {!isUnlocked && (
                <Box 
                  position="absolute" 
                  width="100%" 
                  height="180px"
                  borderRadius="lg"
                  overflow="hidden"
                  zIndex="1"
                >
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    bg="blackAlpha.30"
                    backdropFilter="blur(1px) grayscale(0.6)"
                  />
                </Box>
              )}
              <VStack spacing={{ base: 1, sm: 3 }} height="100%" justify="space-between">
                <Box 
                  position="relative" 
                  width="100%" 
                  height={{ base: "90px", sm: "180px" }}
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <Image
                    src={character.preview}
                    alt={character.name}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="90%"
                    height="90%"
                    objectFit="contain"
                  />
                  {!isUnlocked && (
                    <Box
                      position="absolute"
                      inset="0"
                      bg="blackAlpha.30"
                      backdropFilter="blur(1px) grayscale(0.6)"
                      zIndex={1}
                    />
                  )}
                </Box>

                <HStack spacing={1} position="relative" zIndex={2}>
                  {character.price > 0 ? (
                    <>
                      <Icon as={FaCoins} color="yellow.400" boxSize={{ base: "3", sm: "4" }} />
                      <Text fontWeight="semibold" fontSize={{ base: "sm", sm: "md" }}>
                        {character.price}
                      </Text>
                    </>
                  ) : (
                    <Text fontSize={{ base: "xs", sm: "sm" }} color="gray.500" fontWeight="medium">None</Text>
                  )}
                </HStack>

                {!isUnlocked ? (
                  <Button 
                    colorScheme={canAfford ? "brand" : "gray"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePurchase(character);
                    }}
                    isDisabled={!canAfford}
                    width="full"
                    size={{ base: "xs", sm: "sm" }}
                    zIndex={2}
                  >
                    רכישה
                  </Button>
                ) : (
                  <Button
                    colorScheme="brand"
                    width="full"
                    size={{ base: "xs", sm: "sm" }}
                    variant={isSelected ? "solid" : "outline"}
                    onClick={(e) => {
                      e.stopPropagation();
                      selectCharacter(character.id);
                    }}
                  >
                    {isSelected ? 'נבחר' : 'בחירה'}
                  </Button>
                )}
              </VStack>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
}; 