import React from 'react';
import { createRoot } from 'react-dom/client';
import AppRouter from './AppRouter';
import './firebase'; 
import './AuthContext';

console.warn('NODE_ENV:', process.env.NODE_ENV);

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.debug = () => {};
}

createRoot(document.getElementById('root')).render(<AppRouter />);