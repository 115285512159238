import React, { useMemo } from 'react';
import {Star} from 'lucide-react';

const QuizScore = ({ correct, total}) => {
  const percentage = (correct / total) * 100 || 0;

  const getStars = () => {
    if (percentage === 100) return 3;
    if (percentage >= 70) return 2;
    if (percentage >= 40) return 1;
    return 0;
  };

  const message = useMemo(() => {
    const messages = {
      100: ["מושלם! כל הכבוד!", "מעולה! עבודה נהדרת!", "100%! גאון!", "אתם אלופים!"],
      70: ["עבודה מצוינת!", "כל הכבוד! כמעט מושלם!", "יופי! עבודה טובה!", "אתם מתקדמים!"],
      40: ["התקדמות טובה!", "אתם בדרך הנכונה!", "התקדמת יפה!", "אתם עושים עבודה טובה!"],
      10: ["אתם יכולים לעשות יותר!", "המשיכו להתאמן!", "אל תתייאשו! להמשיך לנסות!", "אולי כדאי לחזור על החומר!", "אתם בדרך הנכונה!"],
      0: [""],
      default: [""]
    };

    if (percentage === 100) return messages[100][Math.floor(Math.random() * messages[100].length)];
    if (percentage >= 70) return messages[70][Math.floor(Math.random() * messages[70].length)];
    if (percentage >= 40) return messages[40][Math.floor(Math.random() * messages[40].length)];
    if (percentage > 0) return messages[10][Math.floor(Math.random() * messages[10].length)];
    return messages[0][0];
  }, [percentage]); // Recalculate on score change

  return (
    <div className="flex flex-col gap-1 p-4 bg-white rounded-lg shadow-lg" style={{ width: '300px', height: '120px' }}>
      <div className="flex items-center justify-between w-full">
          <div className="text-[clamp(0.75rem,2vw,1rem)]">
            {correct}/{total} תשובות נכונות
        </div>
        <div className="flex gap-0.5">
          {[...Array(3)].map((_, i) => (
            <Star
              key={i}
              className="w-[clamp(1.25rem,4vw,1.5rem)] h-[clamp(1.25rem,4vw,1.5rem)]"
              style={{
                color: i < getStars() ? '#FFD700' : '#E5E7EB'
              }}
              fill={i < getStars() ? '#FFD700' : '#E5E7EB'}
            />
          ))}
        </div>
      </div>
      <div className="text-[clamp(0.8rem,1.7vw,1.5rem)] font-bold text-gray-600 w-full">
        {message}
      </div>
    </div>
  );
};

export default QuizScore;