import { useReward } from 'react-rewards';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

// Firework explosion effect
const Firework = () => (
  <AnimatePresence>
    {[...Array(20)].map((_, i) => {
      const angle = (i / 20) * Math.PI * 2;
      return (
        <motion.div
          key={i}
          initial={{ scale: 0, x: 0, y: 0 }}
          animate={{
            scale: [1, 0],
            x: Math.cos(angle) * 200,
            y: Math.sin(angle) * 200,
          }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            width: '10px',
            height: '10px',
            background: `hsl(${Math.random() * 360}, 100%, 50%)`,
            borderRadius: '50%',
          }}
        />
      );
    })}
  </AnimatePresence>
);

// Modified to accept any emoji
const FloatingEmoji = ({ emoji }) => (
  <motion.div
    initial={{ scale: 0, y: 100 }}
    animate={{
      scale: [0, 1.5, 1],
      y: [100, -20],
      rotate: [0, -10, 10, -10, 0],
    }}
    transition={{ duration: 1.5 }}
    style={{
      position: 'fixed',
      bottom: '20%',
      left: '50%',
      fontSize: '80px',
      transform: 'translateX(-50%)',
    }}
  >
    {emoji}
  </motion.div>
);

export const useRandomReward = () => {
  const [showFirework, setShowFirework] = useState(false);
  const [showFloatingEmoji, setShowFloatingEmoji] = useState(false);
  const [currentEmoji, setCurrentEmoji] = useState('🏆');

  // Keep original emoji confetti
  const { reward: rewardConfetti } = useReward('rewardId', 'confetti', {
    elementCount: Math.floor(Math.random() * (204 - 56 + 1)) + 56,
    spread: Math.floor(Math.random() * (217 - 63 + 1)) + 63,
    lifetime: 500,
  });

  const { reward: rewardBalloons } = useReward('rewardId', 'balloons', {
    elementCount: Math.floor(Math.random() * (79 - 21 + 1)) + 21,
    spread: Math.floor(Math.random() * (217 - 63 + 1)) + 63,
    lifetime: 150,
  });

  const emojis = ['⭐️', '✨', '🌟', '💫', '🎉', '🎊', '🎈', '🎁', '🥳', '🎇', '🌈', '🍀', '🏅', '🥇', '🥈', '🥉', '🏆', '🎖️'];
  
  const { reward: rewardEmoji } = useReward('rewardId', 'emoji', {
    elementCount: Math.floor(Math.random() * (50 - 10 + 1)) + 10,
    emoji: [emojis[Math.floor(Math.random() * emojis.length)]],
  });

  // Special animation triggers
  const triggerFirework = () => {
    setShowFirework(true);
    setTimeout(() => setShowFirework(false), 1000);
  };

  const triggerFloatingEmoji = () => {
    setCurrentEmoji(emojis[Math.floor(Math.random() * emojis.length)]);
    setShowFloatingEmoji(true);
    setTimeout(() => setShowFloatingEmoji(false), 2000);
  };

  // Floating score effect
  const showFloatingScore = () => {
    const scores = ['מושלם!', 'מדהים!', 'נהדר!', 'מצוין!', 'גאוני!'];
    const element = document.createElement('div');
    element.style.cssText = `
      position: fixed;
      font-size: 48px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #FFD700;
      text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
      font-weight: bold;
      z-index: 1000;
    `;
    element.textContent = scores[Math.floor(Math.random() * scores.length)];
    document.body.appendChild(element);

    element.animate([
      { transform: 'translate(-50%, -50%) scale(0.5)', opacity: 0 },
      { transform: 'translate(-50%, -50%) scale(1.2)', opacity: 1 },
      { transform: 'translate(-50%, -200%) scale(1)', opacity: 0 }
    ], {
      duration: 1500,
      easing: 'ease-out'
    }).onfinish = () => element.remove();
  };

  const playRandomReward = () => {
    const animations = [
      rewardConfetti,
      rewardBalloons,
      rewardEmoji,
      triggerFirework,
      triggerFloatingEmoji,
      showFloatingScore,
    ];
    
    // Pick just one random animation
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
    randomAnimation();
  };

  return {
    playRandomReward,
    FireworkComponent: showFirework && <Firework />,
    FloatingEmojiComponent: showFloatingEmoji && <FloatingEmoji emoji={currentEmoji} />
  };
};