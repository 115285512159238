export const learningContent = {
    "בעיות מילוליות עם שברים פשוטים": {
        tags: ['כיתה ג׳', 'כיתה ד׳', 'שברים', 'בעיות מילוליות'],
        relatedLinks: [
            { title: "תרגול שברים בסיסיים", url: "/?tags=שברים", description: "תרגול בעיות בסיסיות בשברים" },
            { title: "תרגול בעיות מילוליות בשברים", url: "/?tags=בעיות מילוליות,שברים", description: "תרגול בעיות שברים מילוליות" }
        ],

        getStarted: [
            "**איך פותרים בעיות מילוליות עם שברים?**",
            "",
            "<b>צעד 1: קוראים ומבינים</b>",
            "קוראים את השאלה לאט ובקול",
            "מסמנים בעיגול את כל השברים שמופיעים",
            "שואלים: על מה מדברת השאלה? (עוגה? פיצה? ילדים?)",
            "שואלים: מה רוצים למצוא?",
            "",
            "<b>צעד 2: מציירים</b>", 
            "מציירים ציור פשוט של מה שיש בשאלה",
            "מחלקים את הציור לחלקים שווים",
            "צובעים את החלקים שמדברים עליהם בשאלה",
            "",
            "<b>צעד 3: פותרים</b>",
            "שואלים: האם צריך לחבר או לחסר?",
            "אם מדברים על ״יחד״ או ״סך הכל״ - מחברים",
            "אם מדברים על ״נשאר״ או ״כמה עוד״ - מחסרים",
            "פותרים לאט ובזהירות",
            "",
            "**טיפים שיעזרו לכם:**",
            "תמיד כדאי לצייר ציור - זה עוזר להבין!",
            "השתמשו בצבעים שונים בציור",
            "בדקו אם התשובה הגיונית",
            "אם לא בטוחים - תשאלו את עצמכם: ״האם זה הגיוני?״"
        ],
        examples: [
            {
                question: "דן אכל $\\frac{2}{8}$ מהפיצה ודנה אכלה $\\frac{3}{8}$ מהפיצה. איזה חלק מהפיצה נאכל?",
                solution: "המכנים זהים (8), נחבר את המונים: $2 + 3 = 5$<br/>סך הכל נאכל $\\frac{5}{8}$ מהפיצה",
                drawing: {
                    type: "circle",
                    divisions: 8,
                    highlightSections: [1, 2, 3, 4, 5],
                }
            },
            {
                question: "במגרש משחקים $\\frac{3}{6}$ מהילדים הם בנים. כמה שישיות הן בנות?",
                solution: "אם $\\frac{3}{6}$ הם בנים, אז $\\frac{3}{6}$ הן בנות<br/>סך הכל $\\frac{6}{6} = 1$ שלם",
                drawing: {
                    type: "rectangleGrid",
                    rows: 1,
                    cols: 6,
                    highlightCells: 3,
                }
            },
            {
                question: "מעוגה נאכלו $\\frac{5}{10}$. כמה עשיריות נשארו?",
                solution: "העוגה השלמה היא $\\frac{10}{10}$<br/>אם נאכלו $\\frac{5}{10}$, נשארו: $10 - 5 = 5$ עשיריות<br/>התשובה: $\\frac{5}{10}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 10,
                    highlights: {
                        first: [0, 1, 2, 3, 4],
                        result: [5, 6, 7, 8, 9]
                    },
                }
            }
        ],
        quiz: [
            {
                question: "בכיתה יש 20 תלמידים. $\\frac{2}{4}$ מהם לובשים חולצה כחולה. כמה תלמידים לובשים חולצה כחולה?",
                type: "multiplechoice",
                options: ["10 תלמידים", "5 תלמידים", "15 תלמידים", "8 תלמידים"],
                hint: "פשטו את השבר $\\frac{2}{4}$ וחשבו כמה זה מתוך 20",
                solutionGuide: "2/4 זה חצי<br/>חצי מ-20 הוא 10<br/>לכן 10 תלמידים לובשים חולצה כחולה"
            },
            {
                question: "אמא חילקה עוגה ל-6 חלקים שווים. טל אכל $\\frac{2}{6}$ ויהלי אכלה $\\frac{3}{6}$. כמה שישיות נשארו?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{2}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{6}$"],
                hint: "קודם חברו כמה חלקים נאכלו יחד, ואז חסרו מהשלם ($\\frac{6}{6}$)",
                solutionGuide: "נאכלו: $\\frac{2}{6} + \\frac{3}{6} = \\frac{5}{6}$<br/>נשאר: $\\frac{6}{6} - \\frac{5}{6} = \\frac{1}{6}$"
            },
            {
                question: "נועם צבע $\\frac{3}{8}$ מהציור ורונית צבעה $\\frac{4}{8}$. איזה חלק מהציור צבוע?",
                type: "multiplechoice",
                options: ["$\\frac{7}{8}$", "$\\frac{6}{8}$", "$\\frac{5}{8}$", "$\\frac{4}{8}$"],
                hint: "המכנים זהים (8). חברו את החלקים שצבעו",
                solutionGuide: "מחברים את החלקים הצבועים: $\\frac{3}{8} + \\frac{4}{8} = \\frac{7}{8}$"
            },
            {
                question: "דינוזאור רעב אכל $\\frac{4}{10}$ מהפיצה הענקית, וחברו הטירנוזאור אכל $\\frac{3}{10}$. כמה נשאר לשאר הדינוזאורים?",
                type: "multiplechoice",
                options: ["$\\frac{3}{10}$", "$\\frac{4}{10}$", "$\\frac{5}{10}$", "$\\frac{2}{10}$"],
                hint: "קודם חברו כמה אכלו שני הדינוזאורים, ואז חסרו מהשלם",
                solutionGuide: "אכלו: $\\frac{4}{10} + \\frac{3}{10} = \\frac{7}{10}$<br/>נשאר: $\\frac{10}{10} - \\frac{7}{10} = \\frac{3}{10}$"
            },
            {
                question: "סופרמן הציל $\\frac{2}{6}$ מהחתולים בעיר, ובטמן הציל $\\frac{3}{6}$. איזה חלק מהחתולים ניצל?",
                type: "multiplechoice",
                options: ["$\\frac{5}{6}$", "$\\frac{4}{6}$", "$\\frac{3}{6}$", "$\\frac{6}{6}$"],
                hint: "חברו את החלקים שכל גיבור הציל",
                solutionGuide: "מחברים: $\\frac{2}{6} + \\frac{3}{6} = \\frac{5}{6}$"
            },
            {
                question: "נינג׳ה אכל $\\frac{3}{8}$ מקערת האטריות, וסמוראי אכל $\\frac{2}{8}$. כמה נשאר בקערה?",
                type: "multiplechoice",
                options: ["$\\frac{3}{8}$", "$\\frac{4}{8}$", "$\\frac{5}{8}$", "$\\frac{2}{8}$"],
                hint: "חברו כמה אכלו יחד וחסרו מהשלם",
                solutionGuide: "אכלו: $\\frac{3}{8} + \\frac{2}{8} = \\frac{5}{8}$<br/>נשאר: $\\frac{8}{8} - \\frac{5}{8} = \\frac{3}{8}$"
            },
            {
                question: "בלון מרחף $\\frac{4}{12}$ מהזמן באוויר ו-$\\frac{5}{12}$ מהזמן על הרצפה. כמה זמן הוא מבלה בדרך בין השמיים לרצפה?",
                type: "multiplechoice",
                options: ["$\\frac{3}{12}$", "$\\frac{4}{12}$", "$\\frac{2}{12}$", "$\\frac{1}{12}$"],
                hint: "חברו את הזמן באוויר ועל הרצפה, וחסרו מהשלם",
                solutionGuide: "סה״כ: $\\frac{4}{12} + \\frac{5}{12} = \\frac{9}{12}$<br/>נשאר: $\\frac{12}{12} - \\frac{9}{12} = \\frac{3}{12}$"
            },
            {
                question: "רובוט שמח $\\frac{2}{5}$ מהיום ועצוב $\\frac{2}{5}$ מהיום. איזה חלק מהיום הוא מבולבל?",
                type: "multiplechoice",
                options: ["$\\frac{1}{5}$", "$\\frac{2}{5}$", "$\\frac{3}{5}$", "$\\frac{4}{5}$"],
                hint: "חברו את הזמן שהוא שמח ועצוב, וחסרו מהשלם",
                solutionGuide: "זמן עם רגשות: $\\frac{2}{5} + \\frac{2}{5} = \\frac{4}{5}$<br/>זמן מבולבל: $\\frac{5}{5} - \\frac{4}{5} = \\frac{1}{5}$"
            },
            {
                question: "חייזר אכל $\\frac{3}{10}$ מהגלידה הירוקה ו-$\\frac{4}{10}$ מהגלידה הסגולה. איזה חלק מהגלידה נשאר?",
                type: "multiplechoice",
                options: ["$\\frac{3}{10}$", "$\\frac{4}{10}$", "$\\frac{5}{10}$", "$\\frac{2}{10}$"],
                hint: "חברו כמה גלידה אכל החייזר וחסרו מהשלם",
                solutionGuide: "אכל: $\\frac{3}{10} + \\frac{4}{10} = \\frac{7}{10}$<br/>נשאר: $\\frac{10}{10} - \\frac{7}{10} = \\frac{3}{10}$"
            },
            {
                question: "פיראט מצא $\\frac{2}{8}$ מהאוצר בבוקר ו-$\\frac{5}{8}$ בצהריים. איזה חלק מהאוצר עוד מחכה לו?",
                type: "multiplechoice",
                options: ["$\\frac{1}{8}$", "$\\frac{2}{8}$", "$\\frac{3}{8}$", "$\\frac{4}{8}$"],
                hint: "חברו כמה אוצר כבר מצא וחסרו מהשלם",
                solutionGuide: "מצא: $\\frac{2}{8} + \\frac{5}{8} = \\frac{7}{8}$<br/>נשאר: $\\frac{8}{8} - \\frac{7}{8} = \\frac{1}{8}$"
            },
            {
                question: "קוסם הפך $\\frac{3}{6}$ מהצפרדעים לנסיכים ו-$\\frac{2}{6}$ לארנבים. כמה צפרדעים נשארו צפרדעים?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{2}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{6}$"],
                hint: "חברו כמה צפרדעים הפכו למשהו אחר וחסרו מהשלם",
                solutionGuide: "הפכו: $\\frac{3}{6} + \\frac{2}{6} = \\frac{5}{6}$<br/>נשארו צפרדעים: $\\frac{6}{6} - \\frac{5}{6} = \\frac{1}{6}$"
            },
            {
                question: "דרקון אכל $\\frac{4}{12}$ מעוגת יום ההולדת שלו בנגיסה ראשונה ו-$\\frac{5}{12}$ בנגיסה שנייה. כמה עוגה נשארה?",
                type: "multiplechoice",
                options: ["$\\frac{3}{12}$", "$\\frac{4}{12}$", "$\\frac{5}{12}$", "$\\frac{6}{12}$"],
                hint: "חברו כמה אכל בשתי הנגיסות וחסרו מהשלם",
                solutionGuide: "אכל: $\\frac{4}{12} + \\frac{5}{12} = \\frac{9}{12}$<br/>נשאר: $\\frac{12}{12} - \\frac{9}{12} = \\frac{3}{12}$"
            },
            {
                question: "יוניקורן קישט $\\frac{2}{4}$ מהשמיים בקשתות ו-$\\frac{1}{4}$ בכוכבים. איזה חלק מהשמיים נשאר ריק?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{4}$", "$\\frac{4}{4}$"],
                hint: "חברו כמה מהשמיים קושט וחסרו מהשלם",
                solutionGuide: "קושט: $\\frac{2}{4} + \\frac{1}{4} = \\frac{3}{4}$<br/>ריק: $\\frac{4}{4} - \\frac{3}{4} = \\frac{1}{4}$"
            },
            {
                question: "זומבי אכל $\\frac{3}{10}$ מהמוח הירוק ו-$\\frac{4}{10}$ מהמוח הכחול. איזה חלק מהמוח עוד לא נאכל?",
                type: "multiplechoice",
                options: ["$\\frac{3}{10}$", "$\\frac{4}{10}$", "$\\frac{5}{10}$", "$\\frac{6}{10}$"],
                hint: "חברו כמה מוח אכל וחסרו מהשלם",
                solutionGuide: "אכל: $\\frac{3}{10} + \\frac{4}{10} = \\frac{7}{10}$<br/>נשאר: $\\frac{10}{10} - \\frac{7}{10} = \\frac{3}{10}$"
            },
            {
                question: "חתול חלל צבע $\\frac{2}{8}$ מהחללית באדום ו-$\\frac{5}{8}$ בכחול. איזה חלק נשאר לצבוע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{8}$", "$\\frac{2}{8}$", "$\\frac{3}{8}$", "$\\frac{4}{8}$"],
                hint: "חברו כמה כבר צבע וחסרו מהשלם",
                solutionGuide: "צבע: $\\frac{2}{8} + \\frac{5}{8} = \\frac{7}{8}$<br/>נשאר: $\\frac{8}{8} - \\frac{7}{8} = \\frac{1}{8}$"
            },
            {
                question: "פיה הפכה $\\frac{4}{6}$ מהצעצועים לממתקים ו-$\\frac{1}{6}$ לפרחים. איזה חלק נשאר צעצועים?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{2}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{6}$"],
                hint: "חברו כמה צעצועים הפכו למשהו אחר וחסרו מהשלם",
                solutionGuide: "הפכו: $\\frac{4}{6} + \\frac{1}{6} = \\frac{5}{6}$<br/>נשארו צעצועים: $\\frac{6}{6} - \\frac{5}{6} = \\frac{1}{6}$"
            },
            {
                question: "רובין הוד נתן $\\frac{3}{12}$ מהזהב לילדים ו-$\\frac{7}{12}$ למבוגרים. איזה חלק נשאר לו?",
                type: "multiplechoice",
                options: ["$\\frac{2}{12}$", "$\\frac{3}{12}$", "$\\frac{4}{12}$", "$\\frac{5}{12}$"],
                hint: "חברו כמה זהב חילק וחסרו מהשלם",
                solutionGuide: "חילק: $\\frac{3}{12} + \\frac{7}{12} = \\frac{10}{12}$<br/>נשאר: $\\frac{12}{12} - \\frac{10}{12} = \\frac{2}{12}$"
            },
            {
                question: "תרנגול מלומד קרא $\\frac{2}{6}$ מהספר בבוקר ו-$\\frac{3}{6}$ אחר הצהריים. כמה נשאר לו לקרוא?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{2}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{6}$"],
                hint: "חברו כמה קרא וחסרו מהשלם",
                solutionGuide: "קרא: $\\frac{2}{6} + \\frac{3}{6} = \\frac{5}{6}$<br/>נשאר: $\\frac{6}{6} - \\frac{5}{6} = \\frac{1}{6}$"
            },
            {
                question: "פינגווין אכל $\\frac{3}{8}$ מהגלידה בקוטב הצפוני ו-$\\frac{4}{8}$ בקוטב הדרומי. כמה גלידה נשארה?",
                type: "multiplechoice",
                options: ["$\\frac{1}{8}$", "$\\frac{2}{8}$", "$\\frac{3}{8}$", "$\\frac{4}{8}$"],
                hint: "חברו כמה גלידה אכל וחסרו מהשלם",
                solutionGuide: "אכל: $\\frac{3}{8} + \\frac{4}{8} = \\frac{7}{8}$<br/>נשאר: $\\frac{8}{8} - \\frac{7}{8} = \\frac{1}{8}$"
            },
            {
                question: "לוויתן שתה $\\frac{4}{10}$ מהים הכחול ו-$\\frac{3}{10}$ מהים הירוק. כמה מים נשארו?",
                type: "multiplechoice",
                options: ["$\\frac{3}{10}$", "$\\frac{4}{10}$", "$\\frac{5}{10}$", "$\\frac{6}{10}$"],
                hint: "חברו כמה מים שתה וחסרו מהשלם",
                solutionGuide: "שתה: $\\frac{4}{10} + \\frac{3}{10} = \\frac{7}{10}$<br/>נשאר: $\\frac{10}{10} - \\frac{7}{10} = \\frac{3}{10}$"
            },
            {
                question: "דוב פנדה אכל $\\frac{2}{4}$ מהבמבוק בבוקר ו-$\\frac{1}{4}$ בערב. כמה במבוק נשאר?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{4}$", "$\\frac{4}{4}$"],
                hint: "חברו כמה במבוק אכל וחסרו מהשלם",
                solutionGuide: "אכל: $\\frac{2}{4} + \\frac{1}{4} = \\frac{3}{4}$<br/>נשאר: $\\frac{4}{4} - \\frac{3}{4} = \\frac{1}{4}$"
            },
            {
                question: "ג׳ירפה אכלה $\\frac{3}{6}$ מהעלים למעלה ו-$\\frac{2}{6}$ מהעלים באמצע. כמה עלים נשארו?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{2}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{6}$"],
                hint: "חברו כמה עלים אכלה וחסרו מהשלם",
                solutionGuide: "אכלה: $\\frac{3}{6} + \\frac{2}{6} = \\frac{5}{6}$<br/>נשאר: $\\frac{6}{6} - \\frac{5}{6} = \\frac{1}{6}$"
            },
            {
                question: "קנגורו קפץ $\\frac{2}{8}$ מהדרך בקפיצה ראשונה ו-$\\frac{5}{8}$ בקפיצה שנייה. כמה דרך נשארה?",
                type: "multiplechoice",
                options: ["$\\frac{1}{8}$", "$\\frac{2}{8}$", "$\\frac{3}{8}$", "$\\frac{4}{8}$"],
                hint: "חברו כמה קפץ וחסרו מהשלם",
                solutionGuide: "קפץ: $\\frac{2}{8} + \\frac{5}{8} = \\frac{7}{8}$<br/>נשאר: $\\frac{8}{8} - \\frac{7}{8} = \\frac{1}{8}$"
            },
            {
                question: "דג זהב שחה $\\frac{4}{12}$ מהאקווריום בבוקר ו-$\\frac{5}{12}$ בצהריים. כמה מהאקווריום עוד לא חקר?",
                type: "multiplechoice",
                options: ["$\\frac{3}{12}$", "$\\frac{4}{12}$", "$\\frac{5}{12}$", "$\\frac{6}{12}$"],
                hint: "חברו כמה שחה וחסרו מהשלם",
                solutionGuide: "שחה: $\\frac{4}{12} + \\frac{5}{12} = \\frac{9}{12}$<br/>נשאר: $\\frac{12}{12} - \\frac{9}{12} = \\frac{3}{12}$"
            },
            {
                question: "תוכי צבעוני צבע $\\frac{2}{10}$ מהציור במקורו ו-$\\frac{5}{10}$ בכנפיו. כמה נשאר לצבוע?",
                type: "multiplechoice",
                options: ["$\\frac{3}{10}$", "$\\frac{4}{10}$", "$\\frac{5}{10}$", "$\\frac{6}{10}$"],
                hint: "חברו כמה צבע וחסרו מהשלם",
                solutionGuide: "צבע: $\\frac{2}{10} + \\frac{5}{10} = \\frac{7}{10}$<br/>נשאר: $\\frac{10}{10} - \\frac{7}{10} = \\frac{3}{10}$"
            },
            {
                question: "נמלה חרוצה אספה $\\frac{3}{8}$ מהפירורים בבוקר ו-$\\frac{4}{8}$ בערב. כמה פירורים נשארו?",
                type: "multiplechoice",
                options: ["$\\frac{1}{8}$", "$\\frac{2}{8}$", "$\\frac{3}{8}$", "$\\frac{4}{8}$"],
                hint: "חברו כמה אספה וחסרו מהשלם",
                solutionGuide: "אספה: $\\frac{3}{8} + \\frac{4}{8} = \\frac{7}{8}$<br/>נשאר: $\\frac{8}{8} - \\frac{7}{8} = \\frac{1}{8}$"
            },
            {
                question: "חתול מצחיק רקד $\\frac{2}{6}$ מהזמן על הראש ו-$\\frac{3}{6}$ מהזמן על הזנב. כמה זמן הוא רקד על הרגליים?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{2}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{6}$"],
                hint: "חברו כמה זמן רקד בצורות מוזרות וחסרו מהשלם",
                solutionGuide: "רקד מוזר: $\\frac{2}{6} + \\frac{3}{6} = \\frac{5}{6}$<br/>נשאר לרגליים: $\\frac{6}{6} - \\frac{5}{6} = \\frac{1}{6}$"
            },
            {
                question: "דינוזאור מצחיק צחק $\\frac{3}{10}$ מהיום וישן $\\frac{5}{10}$ מהיום. כמה זמן נשאר לו לאכול?",
                type: "multiplechoice",
                options: ["$\\frac{2}{10}$", "$\\frac{3}{10}$", "$\\frac{4}{10}$", "$\\frac{5}{10}$"],
                hint: "חברו כמה זמן בילה בצחוק ושינה וחסרו מהשלם",
                solutionGuide: "בילה: $\\frac{3}{10} + \\frac{5}{10} = \\frac{8}{10}$<br/>נשאר לאכילה: $\\frac{10}{10} - \\frac{8}{10} = \\frac{2}{10}$"
            },
            {
                question: "ליצן קסום הפך $\\frac{2}{8}$ מהכובעים לארנבים ו-$\\frac{5}{8}$ ליונים. כמה כובעים נשארו כובעים?",
                type: "multiplechoice",
                options: ["$\\frac{1}{8}$", "$\\frac{2}{8}$", "$\\frac{3}{8}$", "$\\frac{4}{8}$"],
                hint: "חברו כמה כובעים הפכו לחיות וחסרו מהשלם",
                solutionGuide: "הפך לחיות: $\\frac{2}{8} + \\frac{5}{8} = \\frac{7}{8}$<br/>נשארו כובעים: $\\frac{8}{8} - \\frac{7}{8} = \\frac{1}{8}$"
            },
            {
                question: "דרקון קטן נשף אש על $\\frac{3}{6}$ מהעוגיות ושרף $\\frac{2}{6}$ מהן לגמרי. כמה עוגיות נשארו שלמות?",
                type: "multiplechoice",
                options: ["$\\frac{1}{6}$", "$\\frac{2}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{6}$"],
                hint: "חברו כמה עוגיות נפגעו מהאש וחסרו מהשלם",
                solutionGuide: "נפגעו מהאש: $\\frac{3}{6} + \\frac{2}{6} = \\frac{5}{6}$<br/>נשארו שלמות: $\\frac{6}{6} - \\frac{5}{6} = \\frac{1}{6}$"
            },
            {
                question: "התאימו כל משפט לשבר המתאים",
                type: "matching",
                pairs: [
                    { left: "הפיל אכל חצי מהפיצה והג׳ירפה אכלה רבע ממנה", right: "$\\frac{3}{4}$" },
                    { left: "האריה אכל שליש מהעוגה והקוף אכל עוד שליש", right: "$\\frac{2}{3}$" },
                    { left: "הדרקון שרף שלוש חמישיות מהעוגיות ועוד חמישית מהן", right: "$\\frac{4}{5}$" },
                    { left: "הליצן הפך שליש מהכובעים לארנבים ושישית ליונים", right: "$\\frac{1}{2}$" }
                ],
                hint: "פתרו כל ביטוי בנפרד וחברו את השברים",
                solutionGuide: "$\\frac{1}{2} + \\frac{1}{4} = \\frac{2}{4} + \\frac{1}{4} = \\frac{3}{4}$<br/>$\\frac{1}{3} + \\frac{1}{3} = \\frac{2}{3}$<br/>$\\frac{3}{5} + \\frac{1}{5} = \\frac{4}{5}$<br/>$\\frac{1}{3} + \\frac{1}{6} = \\frac{2}{6} + \\frac{1}{6} = \\frac{1}{2}$"
            },
            {
                question: "התאימו כל סיפור לשבר שנשאר",
                type: "matching",
                pairs: [
                    { left: "סופרמן הציל חצי מהחתולים ובטמן הציל שליש מהנותרים", right: "$\\frac{1}{3}$" },
                    { left: "הנמלה אספה רבע מהפירורים וחצי מהנותרים", right: "$\\frac{3}{8}$" },
                    { left: "התוכי צבע שליש מהציור ושליש מהנותר", right: "$\\frac{3}{5}$" },
                    { left: "הדינוזאור אכל חצי מהפיצה ורבע מהנותר", right: "$\\frac{7}{8}$" }
                ],
                hint: "חשבו בשלבים - קודם כמה נשאר אחרי החלק הראשון, ואז כמה נשאר בסוף",
                solutionGuide: "חתולים: נשאר חצי, שליש ממנו הוצל = $1 - \\frac{1}{2} - \\frac{1}{6} = \\frac{1}{3}$ נשאר<br/>פירורים: נשאר $\\frac{3}{4}$, חצי ממנו נאסף = $\\frac{3}{4} \\times \\frac{1}{2} = \\frac{3}{8}$ נשאר<br/>ציור: נשארו $\\frac{2}{3}$, שליש מהם נצבע = $\\frac{2}{3} \\times \\frac{2}{3} = \\frac{4}{9}$ נשאר<br/>פיצה: נשאר חצי, רבע ממנו נאכל = $\\frac{1}{2} \\times \\frac{3}{4} = \\frac{3}{8}$ נשאר"
            },
            {
                question: "התאימו כל חישוב לתוצאה הנכונה",
                type: "matching",
                pairs: [
                    { left: "חתול רקד שליש מהזמן על הראש ורבע על הזנב", right: "$\\frac{7}{12}$" },
                    { left: "דרקון צחק חצי מהיום וישן שישית ממנו", right: "$\\frac{2}{3}$" },
                    { left: "ליצן הפך רבע מהכובעים לארנבים ושמינית ליונים", right: "$\\frac{3}{8}$" },
                    { left: "דג זהב שחה שלוש שמיניות בבוקר ורבע בערב", right: "$\\frac{5}{8}$" }
                ],
                hint: "הביאו למכנה משותף לפני החיבור",
                solutionGuide: "$\\frac{1}{3} + \\frac{1}{4} = \\frac{4}{12} + \\frac{3}{12} = \\frac{7}{12}$<br/>$\\frac{1}{2} + \\frac{1}{6} = \\frac{3}{6} + \\frac{1}{6} = \\frac{2}{3}$<br/>$\\frac{1}{4} + \\frac{1}{8} = \\frac{2}{8} + \\frac{1}{8} = \\frac{3}{8}$<br/>$\\frac{3}{8} + \\frac{1}{4} = \\frac{3}{8} + \\frac{2}{8} = \\frac{5}{8}$"
            },
            {
                question: "התאימו כל בעיה לכמות שנותרה",
                type: "matching",
                pairs: [
                    { left: "דינוזאור אכל שני שלישים מ-18 עוגיות", right: "6" },
                    { left: "חתול שתה רבע מ-32 כוסות חלב", right: "24" },
                    { left: "תוכי צבע שלוש חמישיות מ-20 ציורים", right: "8" },
                    { left: "ליצן חילק חצי מ-14 בלונים", right: "7" }
                ],
                hint: "כפלו את השבר במספר השלם וחסרו מהכמות ההתחלתית",
                solutionGuide: "$18 - (\\frac{2}{3} × 18) = 6$<br/>$32 - (\\frac{1}{4} × 32) = 24$<br/>$20 - (\\frac{3}{5} × 20) = 8$<br/>$14 - (\\frac{1}{2} × 14) = 7$"
            },
            {
                question: "התאימו כל משפט לשבר המתאים",
                type: "matching",
                pairs: [
                    { left: "חצי מהעוגה ועוד רבע ממנה", right: "$\\frac{3}{4}$" },
                    { left: "שליש מהפיצה ועוד שליש ממנה", right: "$\\frac{2}{3}$" },
                    { left: "רבע מהשוקולד פחות שמינית ממנו", right: "$\\frac{1}{8}$" },
                    { left: "חצי מהמיץ פחות שישית ממנו", right: "$\\frac{1}{3}$" }
                ],
                hint: "פתרו כל ביטוי בנפרד. למשל: חצי ועוד רבע זה $\\frac{1}{2} + \\frac{1}{4} = \\frac{3}{4}$",
                solutionGuide: "$\\frac{1}{2} + \\frac{1}{4} = \\frac{3}{4}$<br/>$\\frac{1}{3} + \\frac{1}{3} = \\frac{2}{3}$<br/>$\\frac{1}{4} - \\frac{1}{8} = \\frac{1}{8}$<br/>$\\frac{1}{2} - \\frac{1}{6} = \\frac{1}{3}$"
            },
            {
                question: "התאימו כל סיפור לתשובה הנכונה",
                type: "matching",
                pairs: [
                    { left: "טל אכל חצי פיצה ואיתי אכל חצי מהנותר", right: "$\\frac{1}{4}$ נשאר" },
                    { left: "רבע מהילדים הלכו הביתה, ושליש מהנותרים לספריה", right: "$\\frac{1}{2}$ נשאר" },
                    { left: "שליש מהעוגה נאכל, וחצי מהנותר התקלקל", right: "$\\frac{1}{3}$ נשאר" },
                    { left: "חמישית מהמים נשפכו, ורבע מהנותרים התאדו", right: "$\\frac{3}{5}$ נשאר" }
                ],
                hint: "פתרו בשלבים - קודם מצאו כמה נשאר אחרי החלק הראשון, ואז חשבו את החלק השני",
                solutionGuide: "פיצה: נשאר חצי, ואז נאכל חצי ממנו = $\\frac{1}{4}$<br/>ילדים: נשארו $\\frac{3}{4}$, ושליש הלכו = $\\frac{1}{2}$ נשארו<br/>עוגה: נשאר $\\frac{2}{3}$, חצי התקלקל = $\\frac{1}{3}$ טרי<br/>מים: נשאר חצי, שליש התאדה = $\\frac{1}{3}$ נשאר"
            },
            {
                question: "התאימו כל חישוב לתוצאה הנכונה",
                type: "matching",
                pairs: [
                    { left: "שליש ועוד רבע מעוגה", right: "$\\frac{7}{12}$" },
                    { left: "חצי פחות שישית מפיצה", right: "$\\frac{1}{3}$" },
                    { left: "רבע ועוד שמינית משוקולד", right: "$\\frac{3}{8}$" },
                    { left: "שלוש שמיניות פחות רבע מגלידה", right: "$\\frac{1}{8}$" }
                ],
                hint: "הביאו למכנה משותף לפני החיבור או החיסור",
                solutionGuide: "$\\frac{1}{3} + \\frac{1}{4} = \\frac{4}{12} + \\frac{3}{12} = \\frac{7}{12}$<br/>$\\frac{1}{2} - \\frac{1}{6} = \\frac{3}{6} - \\frac{1}{6} = \\frac{1}{3}$<br/>$\\frac{1}{4} + \\frac{1}{8} = \\frac{2}{8} + \\frac{1}{8} = \\frac{3}{8}$<br/>$\\frac{3}{8} - \\frac{1}{4} = \\frac{3}{8} - \\frac{2}{8} = \\frac{1}{8}$"
            },
            {
                question: "התאימו כל בעיה לפתרון שלה",
                type: "matching",
                pairs: [
                    { left: "שני שלישים מ-18 דינוזאורים", right: "12" },
                    { left: "רבע מ-32 שרביטי קסם", right: "8" },
                    { left: "שלוש חמישיות מ-20 כוסות שיקוי", right: "12" },
                    { left: "חצי מ-14 מטאטאי קסם", right: "7" }
                ],
                hint: "כפלו את השבר במספר השלם",
                solutionGuide: "$\\frac{2}{3} × 18 = 12$<br/>$\\frac{1}{4} × 32 = 8$<br/>$\\frac{3}{5} × 20 = 12$<br/>$\\frac{1}{2} × 14 = 7$"
            },
            {
                question: "התאימו כל שאלה לתשובה הנכונה",
                type: "matching",
                pairs: [
                    { left: "כמה זה $\\frac{2}{3}$ של 15?", right: "10" },
                    { left: "כמה זה $\\frac{3}{4}$ של 16?", right: "12" },
                    { left: "כמה זה $\\frac{4}{5}$ של 20?", right: "16" },
                    { left: "כמה זה $\\frac{5}{6}$ של 24?", right: "20" }
                ],
                hint: "כפלו את השבר במספר השלם",
                solutionGuide: "$\\frac{2}{3} × 15 = 10$<br/>$\\frac{3}{4} × 16 = 12$<br/>$\\frac{4}{5} × 20 = 16$<br/>$\\frac{5}{6} × 24 = 20$"
            }
        ]
    }
};