import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, Box, Container, Text, VStack, Button, useToast, Link, Heading } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { useAuth } from './AuthContext';
import { UserOnboarding } from './components/userOnboarding';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

export const AuthWrapper = ({ isOpen, onClose }) => {
  const { user, loading, signInWithGoogle } = useAuth();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const checkUser = async () => {
      if (user?.uid && isOpen) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists() && userDoc.data().grade) {
          setShowOnboarding(false);
          onClose();
        } else {
          setShowOnboarding(true);
        }
      }
    };
    checkUser();
  }, [user, onClose, isOpen]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithGoogle();
      if (result) {
        const userDoc = await getDoc(doc(db, 'users', result.user.uid));
        if (!userDoc.exists() || !userDoc.data().grade) {
          setShowOnboarding(true);
        } else {
          onClose();
        }
        toast({ title: 'התחברת בהצלחה!', status: 'success' });
      }
    } catch (error) {
      if (error.code === 'auth/cancelled-popup-request' ||
          error.code === 'auth/popup-closed-by-user') {
        return;
      }
      console.error('Sign in error:', error);
      toast({ title: 'שגיאה', description: error.message, status: 'error' });
    }
  };

  if (loading) {
    return (
      <Container centerContent py={20}>
        <Text>טוען...</Text>
      </Container>
    );
  }

  if (user?.uid && showOnboarding && isOpen) {
    return (
      <UserOnboarding
        isOpen={true}
        onClose={() => {
          setShowOnboarding(false);
          onClose();
        }}
        user={user}
        onComplete={(grade) => {
          setShowOnboarding(false);
          onClose();
        }}
      />
    );
  }

  if (!user && isOpen) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="xl">
          <VStack spacing={12} bg="white" p={12} borderRadius="xl" boxShadow="xl" w="full">
            <VStack spacing={6} align="center">
              <Heading
                size="2xl"
                bgGradient="linear(to-r, blue.400, blue.600)"
                bgClip="text"
                textAlign="center"
              >
                להבין מתמטיקה באמת
              </Heading>
              <Text fontSize="xl" color="gray.600" textAlign="center">
                צעד אחר צעד, בדרך המתאימה לך
              </Text>
            </VStack>

            <Box p={8} borderRadius="lg" bg="gray.50" w="full">
              <VStack spacing={6}>
                <Button
                  onClick={handleGoogleSignIn}
                  leftIcon={<FcGoogle />}
                  width="100%"
                >
                  התחבר עם Google
                </Button>
                <Box textAlign="center">
                  <Link
                    href="/privacy.html"
                    color="blue.500"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    הצהרת פרטיות
                  </Link>
                </Box>
              </VStack>
            </Box>
          </VStack>
        </ModalContent>
      </Modal>
    );
  }

  return null;
};