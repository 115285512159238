import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Text,
  Box
} from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';

export const WrongAnswerModal = ({ isOpen, onClose, solutionGuide }) => {
  const contentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen && window.MathJax) {
      setIsLoading(true);
      // Small delay to ensure modal content is mounted
      const timer = setTimeout(() => {
        window.MathJax.typeset([contentRef.current]);
        setIsLoading(false);
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [isOpen, solutionGuide]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size={{ base: "sm", md: "xl" }}
      isCentered
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" bg="blackAlpha.300"/>
      <ModalContent 
        bg="whiteAlpha.900"
        mx={2}
      >
        <ModalHeader display="flex" alignItems="center" color="red.600">
          <Icon as={FiX} w={6} h={6} mr={2} />
          <Text>תשובה לא נכונה</Text>
        </ModalHeader>
        <ModalBody>
          <Box 
            ref={contentRef}
            bg="whiteAlpha.900" 
            p={4} 
            borderRadius="md"
            fontSize="lg"
            opacity={isLoading ? 0 : 1}
            transition="opacity 0.2s"
            whiteSpace="pre-wrap"
            overflowWrap="break-word"
            wordBreak="break-word"
            dangerouslySetInnerHTML={{ __html: solutionGuide }}
          />
        </ModalBody>
        <ModalFooter>
          <Button 
            colorScheme="blue"
            onClick={onClose}
          >
            הבנתי
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};