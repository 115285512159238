export const learningContent = {
    "חוקי חזקות": {
        tags: ['כיתה ז׳', 'אלגברה', 'חזקות'],

        relatedLinks: [
            {
                title: "תרגול חזקות",
                url: "/?tags=חזקות",
                description: "תרגול נוסף בחזקות"
            },
            {
                title: "תרגול אלגברה",
                url: "/?tags=אלגברה",
                description: "תרגול נוסף באלגברה"
            },
            {
                title: "סדר פעולות חשבון",
                url: "/?tags=סדר%20פעולות",
                description: "תרגול סדר פעולות חשבון"
            }
        ],

        getStarted: [
            "**מהי חזקה?**",
            "חזקה היא דרך מקוצרת לכתוב כפל של אותו מספר בעצמו",
            "למשל: $2^3 = 2 × 2 × 2 = 8$",
            "או עם משתנה: $x^3 = x × x × x$",
            "",
            "**חוקי כפל חזקות:**",
            "כשכופלים חזקות עם אותו בסיס מחברים את המעריכים: $x^m × x^n = x^{m+n}$",
            "  דוגמה: $x^3 × x^2 = x^5$ (לא $x^6$!)",
            "כשמעלים חזקה בחזקה כופלים את המעריכים: $(x^m)^n = x^{m×n}$",
            "  דוגמה: $(x^2)^3 = x^{2×3} = x^6$",
            "כשכופלים חזקות עם אותו מעריך: $x^n × y^n = (x×y)^n$",
            "  דוגמה: $2^3 × 3^3 = (2×3)^3 = 6^3$",
            "",
            "**כללים חשובים:**",
            "כל מספר בחזקת 0 שווה ל-1: $x^0 = 1$",
            "כל מספר בחזקת 1 שווה לעצמו: $x^1 = x$",
            "חזקות שליליות: $x^{-n} = \\frac{1}{x^n}$",
            "  דוגמה: $2^{-2} = \\frac{1}{2^2} = \\frac{1}{4}$",
            "",
            "**טעויות נפוצות להיזהר מהן:**",
            "$x^3 × x^3 ≠ x^9$ (התשובה הנכונה היא $x^6$)",
            "$(x^2)^3 ≠ x^5$ (התשובה הנכונה היא $x^6$)",
            "$(-2)^3 ≠ 8$ (התשובה הנכונה היא $-8$)"
        ],
        examples: [
            {
                question: "חשבו את $2x^3 × 4x^4$",
                solution: "<br>1. נכפול את המקדמים:<br>" +
                    "$2 × 4 = 8$<br><br>" +
                    "2. נחבר את המעריכים של $x$:<br>" +
                    "$x^3 × x^4 = x^{3+4} = x^7$<br><br>" +
                    "התשובה הסופית: $8x^7$"
            },
            {
                question: "פשטו את הביטוי $(3x^2)^3$",
                solution: "<br>1. נעלה את המספר בחזקה:<br>" +
                    "$3^3 = 3 × 3 × 3 = 27$<br><br>" +
                    "2. נכפיל את המעריכים:<br>" +
                    "$(x^2)^3 = x^{2×3} = x^6$<br><br>" +
                    "התשובה הסופית: $27x^6$"
            },
            {
                question: "פשטו את הביטוי $2x^3 × (-3x^2)$",
                solution: "<br>1. נכפול את המקדמים:<br>" +
                    "$2 × (-3) = -6$<br><br>" +
                    "2. נחבר את המעריכים:<br>" +
                    "$x^3 × x^2 = x^{3+2} = x^5$<br><br>" +
                    "התשובה הסופית: $-6x^5$"
            }
        ],
        quiz: [
            {
                question: "מהי התוצאה של $3x^4 \\cdot 2x^3$?",
                type: "multiplechoice",
                options: ["$6x^7$", "$5x^7$", "$6x^{12}$", "$5x^{12}$"],
                hint: "כשכופלים חזקות עם אותו בסיס, מחברים את המעריכים. וגם צריך לכפול את המקדמים",
                solutionGuide: "נפרק לשלבים:<br/>1. נכפול מקדמים: $3 \\cdot 2 = 6$<br/>2. נחבר מעריכים: $x^4 \\cdot x^3 = x^{4+3} = x^7$<br/>לכן: $3x^4 \\cdot 2x^3 = 6x^7$"
            },
            {
                question: "פשטו את $(2x^3)^2$",
                type: "multiplechoice",
                options: ["$4x^6$", "$2x^6$", "$4x^5$", "$2x^9$"],
                hint: "כשמעלים חזקה בחזקה, כופלים את המעריכים. זכרו גם להעלות את המקדם בחזקה",
                solutionGuide: "נפרק לשלבים:<br/>1. נעלה את המקדם בריבוע: $2^2 = 4$<br/>2. נכפיל את המעריך ב-$2$: $(x^3)^2 = x^{3 \\cdot 2} = x^6$<br/>לכן: $(2x^3)^2 = 4x^6$"
            },
            {
                question: "מהי התוצאה של $x^3 \\cdot x^2 \\cdot x^4$?",
                type: "multiplechoice",
                options: ["$x^9$", "$x^5$", "$x^{24}$", "$x^6$"],
                hint: "כשכופלים חזקות עם אותו בסיס, פשוט מחברים את כל המעריכים יחד",
                solutionGuide: "נחבר את המעריכים:<br/>$x^3 \\cdot x^2 \\cdot x^4 = x^{3+2+4} = x^9$"
            },
            {
                question: "פשטו את $2x^3 \\cdot (-3x^2)$",
                type: "multiplechoice",
                options: ["$-6x^5$", "$24x^5$", "$6x^5$", "$-5x^5$"],
                hint: "שימו לב לסימן השלילי בכפל המקדמים. וזכרו לחבר את המעריכים",
                solutionGuide: "נפרק לשלבים:<br/>1. נכפול מקדמים: $2 \\cdot (-3) = -6$<br/>2. נחבר מעריכים: $x^3 \\cdot x^2 = x^{3+2} = x^5$<br/>לכן: $2x^3 \\cdot (-3x^2) = -6x^5$"
            },
            {
                question: "מהי התוצאה של $(4x^2)^3$?",
                type: "multiplechoice",
                options: ["$64x^6$", "$12x^6$", "$12x^8$", "$64x^8$"],
                hint: "כשמעלים ביטוי בחזקה 3, המקדם מועלה בחזקת 3 והמעריך מוכפל ב-3",
                solutionGuide: "נפרק לשלבים:<br/>1. נעלה את המקדם בשלוש: $4^3 = 64$<br/>2. נכפיל את המעריך ב-$3$: $(x^2)^3 = x^{2 \\cdot 3} = x^6$<br/>לכן: $(4x^2)^3 = 64x^6$"
            },
            {
                question: "פשטו את $2x^4 \\cdot 3x^2 \\cdot (-x)$",
                type: "multiplechoice",
                options: ["$-6x^7$", "$-6x^6$", "$5x^7$", "$6x^7$"],
                hint: "כפלו את כל המקדמים (כולל הסימן השלילי) וחברו את כל המעריכים",
                solutionGuide: "נפרק לשלבים:<br/>1. נכפול מקדמים: $2 \\cdot 3 \\cdot (-1) = -6$<br/>2. נחבר מעריכים: $x^4 \\cdot x^2 \\cdot x^1 = x^{4+2+1} = x^7$<br/>לכן: $2x^4 \\cdot 3x^2 \\cdot (-x) = -6x^7$"
            },
            {
                question: "מהי התוצאה של $(3x)^2$?",
                type: "multiplechoice",
                options: ["$9x^2$", "$9x$", "$6x^2$", "$3x^2$"],
                hint: "בריבוע של ביטוי, המקדם מועלה בריבוע והמשתנה מועלה בריבוע",
                solutionGuide: "נפרק לשלבים:<br/>1. נעלה את המקדם בריבוע: $3^2 = 9$<br/>2. נעלה את $x$ בריבוע: $x^2$<br/>לכן: $(3x)^2 = 9x^2$"
            },
            {
                question: "פשטו את $x^3 \\cdot x^{-2}$",
                type: "multiplechoice",
                options: ["$x$", "$x^6$", "$x^5$", "$x^{-6}$"],
                hint: "גם עם חזקות שליליות, פשוט מחברים את המעריכים",
                solutionGuide: "נחבר את המעריכים:<br/>$x^3 \\cdot x^{-2} = x^{3+(-2)} = x^1 = x$"
            },
            {
                question: "מהי התוצאה של $(-2x^3)^2$?",
                type: "multiplechoice",
                options: ["$4x^6$", "$-4x^6$", "$4x^9$", "$-4x^9$"],
                hint: "זכרו: כל מספר בריבוע הוא חיובי! גם אם מתחילים עם מספר שלילי",
                solutionGuide: "שימו לב: מספר בריבוע תמיד חיובי!<br/>נפרק לשלבים:<br/>1. נעלה את המקדם בריבוע: $(-2)^2 = 4$<br/>2. נכפיל את המעריך ב-$2$: $(x^3)^2 = x^{3 \\cdot 2} = x^6$<br/>לכן: $(-2x^3)^2 = 4x^6$"
            },
            {
                question: "פשטו את $3x^2 \\cdot 2y^2$",
                type: "multiplechoice",
                options: ["$6x^2y^2$", "$6(x^2y^2)^2$", "$5(xy)^2$", "$5x^2y^2$"],
                hint: "כשיש משתנים שונים, כל משתנה נשאר עם החזקה שלו. רק המקדמים מוכפלים",
                solutionGuide: "נפרק את הפתרון לשלבים:<br/>1. נכפול את המקדמים: $3 \\cdot 2 = 6$<br/>2. כל משתנה נשאר בחזקתו המקורית: $x^2$ ו-$y^2$<br/>3. לכן התשובה היא $6x^2y^2$"
            },
            {
                question: "מהי התוצאה של $(5x^2)^2$?",
                type: "multiplechoice",
                options: ["$25x^4$", "$10x^2$", "$10x^4$", "$25x^2$"],
                hint: "בריבוע של ביטוי, המקדם מועלה בריבוע והמעריך מוכפל ב-2",
                solutionGuide: "נפרק לשלבים:<br/>1. נעלה את המקדם בריבוע: $5^2 = 25$<br/>2. נכפיל את המעריך ב-$2$: $(x^2)^2 = x^{2 \\cdot 2} = x^4$<br/>לכן: $(5x^2)^2 = 25x^4$"
            },
            {
                question: "פשטו את $x^4 \\cdot x^3 \\cdot x^2$",
                type: "multiplechoice",
                options: ["$x^9$", "$x^7$", "$x^5$", "$x^{24}$"],
                hint: "חברו את כל המעריכים. אין צורך להכפיל אותם",
                solutionGuide: "נחבר את המעריכים:<br/>$x^4 \\cdot x^3 \\cdot x^2 = x^{4+3+2} = x^9$"
            },
            {
                question: "מהי התוצאה של $(2y)^3$?",
                type: "multiplechoice",
                options: ["$8y^3$", "$6y^3$", "$8y^2$", "$6y^2$"],
                hint: "כשמעלים ביטוי בחזקת 3, המקדם מועלה בחזקת 3 והמשתנה מועלה בחזקת 3",
                solutionGuide: "נפרק לשלבים:<br/>1. נעלה את המקדם בשלוש: $2^3 = 8$<br/>2. נעלה את $y$ בשלוש: $y^3$<br/>לכן: $(2y)^3 = 8y^3$"
            },
            {
                question: "פשטו את $2x^3 \\cdot 4y^3$",
                type: "multiplechoice",
                options: ["$8x^3y^3$", "$8x^6y^3$", "$6x^3y^3$", "$6x^6y^3$"],
                hint: "כשיש משתנים שונים, כפלו את המקדמים והשאירו כל משתנה עם החזקה שלו",
                solutionGuide: "נפרק לשלבים:<br/>1. נכפול מקדמים: $2 \\cdot 4 = 8$<br/>2. המשתנים נשארים באותן חזקות: $x^3$ ו-$y^3$<br/>לכן: $2x^3 \\cdot 4y^3 = 8x^3y^3$"
            },
            {
                question: "מהי התוצאה של $x^5 \\cdot x^{-3}$?",
                type: "multiplechoice",
                options: ["$x^2$", "$x^{-8}$", "$x^8$", "$x^{-2}$"],
                hint: "חברו את המעריכים החיובי והשלילי",
                solutionGuide: "נחבר את המעריכים:<br/>$x^5 \\cdot x^{-3} = x^{5+(-3)} = x^2$"
            },
            {
                question: "פשטו את $(3xy)^2$",
                type: "multiplechoice",
                options: ["$9x^2y^2$", "$6x^2y^2$", "$9xy^2$", "$6xy^2$"],
                hint: "בריבוע של ביטוי, המקדם מועלה בריבוע וכל משתנה מועלה בריבוע",
                solutionGuide: "נפרק לשלבים:<br/>1. נעלה את המקדם בריבוע: $3^2 = 9$<br/>2. כל משתנה מועלה בריבוע: $x^2$ ו-$y^2$<br/>לכן: $(3xy)^2 = 9x^2y^2$"
            },
            {
                question: "מהי התוצאה של $(x^2)^3 \\cdot (x^3)^2$?",
                type: "multiplechoice",
                options: ["$x^{12}$", "$x^8$", "$x^6$", "$x^{10}$"],
                hint: "פרקו כל ביטוי בנפרד על ידי כפל המעריכים, ואז חברו את התוצאות",
                solutionGuide: "נפרק לשלבים:<br/>1. נכפיל בסוגריים הראשונים: $(x^2)^3 = x^{2 \\cdot 3} = x^6$<br/>2. נכפיל בסוגריים השניים: $(x^3)^2 = x^{3 \\cdot 2} = x^6$<br/>3. נחבר את המעריכים: $x^6 \\cdot x^6 = x^{12}$"
            },
            {
                question: "פשטו את $2x^2 \\cdot (-3x^3) \\cdot 4x$",
                type: "multiplechoice",
                options: ["$-24x^6$", "$24x^6$", "$24x^5$", "$-24x^5$"],
                hint: "כפלו את כל המקדמים (שימו לב לסימן השלילי) וחברו את כל המעריכים",
                solutionGuide: "נפרק לשלבים:<br/>1. נכפול מקדמים: $2 \\cdot (-3) \\cdot 4 = -24$<br/>2. נחבר מעריכים: $x^2 \\cdot x^3 \\cdot x^1 = x^{2+3+1} = x^6$<br/>לכן: $2x^2 \\cdot (-3x^3) \\cdot 4x = -24x^6$"
            },
            {
                question: "מהי התוצאה של $(4x^2y)^2$?",
                type: "multiplechoice",
                options: ["$16x^4y^2$", "$8x^2y^2$", "$16x^2y^2$", "$8x^4y^2$"],
                hint: "בריבוע של ביטוי, המקדם מועלה בריבוע וכל משתנה מוכפל המעריך שלו ב-2",
                solutionGuide: "נפרק לשלבים:<br/>1. נעלה את המקדם בריבוע: $4^2 = 16$<br/>2. נכפיל את המעריך של $x$ ב-$2$: $(x^2)^2 = x^4$<br/>3. נכפיל את המעריך של $y$ ב-$2$: $y^1 \\cdot 2 = y^2$<br/>לכן: $(4x^2y)^2 = 16x^4y^2$"
            },
            {
                question: "פשטו את $x^3 \\cdot y^3 \\cdot z^3$",
                type: "multiplechoice",
                options: ["$(xyz)^3$", "$xyz^3$", "$(xyz)^9$", "$(x^6)(y^6)(z^6)$"],
                hint: "חפשו דפוס - כל המשתנים מועלים באותה חזקה",
                solutionGuide: "נפרק לשלבים:<br/>1. בביטוי $x^3 \\cdot y^3 \\cdot z^3$ כל משתנה בחזקת $3$<br/>2. לכן אפשר לכתוב זאת כ-$(xyz)^3$"
            },
            {
                question: "מהי התוצאה של $(-2x)^3$?",
                type: "multiplechoice",
                options: ["$-8x^3$", "$8x^2$", "$8x^3$", "$-8x^2$"],
                hint: "זכרו: בחזקה אי-זוגית, הסימן השלילי נשמר!",
                solutionGuide: "שימו לב: בחזקה אי-זוגית הסימן נשמר!<br/>נפרק לשלבים:<br/>1. נעלה את המקדם בשלוש: $(-2)^3 = -8$<br/>2. נעלה את $x$ בשלוש: $x^3$<br/>לכן: $(-2x)^3 = -8x^3$"
            },
            {
                question: "פשטו את $(x^2y)^3 \\cdot (xy^2)^2$",
                type: "multiplechoice",
                options: ["$x^8y^7$", "$x^7y^8$", "$x^5y^5$", "$x^6y^6$"],
                hint: "פרקו כל ביטוי בנפרד, ואז כפלו את התוצאות. עקבו אחרי המעריכים של כל משתנה",
                solutionGuide: "נפרק לשלבים:<br/>1. נפרק את הביטוי הראשון: $(x^2y)^3 = x^6y^3$<br/>2. נפרק את הביטוי השני: $(xy^2)^2 = x^2y^4$<br/>3. נכפול את הביטויים: $x^6y^3 \\cdot x^2y^4 = x^8y^7$"
            },
            {
                question: "מהי התוצאה של $x^4 \\cdot x^{-4}$?",
                type: "multiplechoice",
                options: ["$1$", "$x$", "$x^8$", "$x^{-8}$"],
                hint: "זכרו: כל מספר בחזקת 0 שווה ל-1. מה קורה כשמחברים 4 עם -4?",
                solutionGuide: "נחבר את המעריכים:<br/>$x^4 \\cdot x^{-4} = x^{4+(-4)} = x^0 = 1$<br/>זכרו: כל מספר בחזקת 0 שווה ל-1"
            },
            {
                question: "פשטו את $3x^2 \\cdot 2x^3 \\cdot (-x^4)$",
                type: "multiplechoice",
                options: ["$-6x^9$", "$6x^9$", "$6x^8$", "$-6x^8$"],
                hint: "כפלו את כל המקדמים (שימו לב לסימן השלילי) וחברו את כל המעריכים של x",
                solutionGuide: "נפרק לשלבים:<br/>1. נכפול מקדמים: $3 \\cdot 2 \\cdot (-1) = -6$<br/>2. נחבר מעריכים: $x^2 \\cdot x^3 \\cdot x^4 = x^{2+3+4} = x^9$<br/>לכן: $3x^2 \\cdot 2x^3 \\cdot (-x^4) = -6x^9$"
            },
            {
                question: "מהי התוצאה של $(2x^3y^2)^2$?",
                type: "multiplechoice",
                options: ["$4x^6y^4$", "$4x^6y^2$", "$4x^5y^2$", "$4x^5y^4$"],
                hint: "בריבוע של ביטוי, המקדם מועלה בריבוע וכל משתנה מוכפל המעריך שלו ב-2",
                solutionGuide: "נפרק לשלבים:<br/>1. נעלה את המקדם בריבוע: $2^2 = 4$<br/>2. נכפיל את המעריך של $x$ ב-$2$: $(x^3)^2 = x^6$<br/>3. נכפיל את המעריך של $y$ ב-$2$: $(y^2)^2 = y^4$<br/>לכן: $(2x^3y^2)^2 = 4x^6y^4$"
            },
            {
                question: "פשטו את $(3x)^2 \\cdot (2x)^3$",
                type: "multiplechoice",
                options: ["$72x^5$", "$36x^4$", "$72x^4$", "$108x^5$"],
                hint: "פרקו כל ביטוי בנפרד (זכרו לחשב את המקדמים והמעריכים), ואז כפלו את התוצאות",
                solutionGuide: "נפרק לשלבים:<br/>1. נפרק את הביטוי הראשון: $(3x)^2 = 9x^2$<br/>2. נפרק את הביטוי השני: $(2x)^3 = 8x^3$<br/>3. נכפול: $9x^2 \\cdot 8x^3 = 72x^5$"
            },
            {
                question: "מהי התוצאה של $x^{-2} \\cdot y^{-3}$?",
                type: "multiplechoice",
                options: ["$\\frac{1}{x^2y^3}$", "$\\frac{1}{x^3y^2}$", "$\\frac{1}{x^5y^5}$", "$x^{-5}y^{-5}$"],
                hint: "חזקות שליליות הופכות לשברים. חשבו איך לכתוב את המכפלה כשבר אחד",
                solutionGuide: "חזקות שליליות הן שברים:<br/>$x^{-2} \\cdot y^{-3} = \\frac{1}{x^2} \\cdot \\frac{1}{y^3} = \\frac{1}{x^2y^3}$"
            },
            {
                question: "פשטו את $(x^2)^3 \\cdot (x^3)^{-1}$",
                type: "multiplechoice",
                options: ["$x^3$", "$x^6$", "$x^{-3}$", "$x^{-6}$"],
                hint: "פרקו כל ביטוי בנפרד (זכרו שחזקה שלילית הופכת את הכיוון), ואז חברו את המעריכים",
                solutionGuide: "נפרק לשלבים:<br/>1. נפרק את הביטוי הראשון: $(x^2)^3 = x^6$<br/>2. נפרק את הביטוי השני: $(x^3)^{-1} = x^{-3}$<br/>3. נחבר את המעריכים: $x^6 \\cdot x^{-3} = x^{6+(-3)} = x^3$"
            },
            {
                question: "פשטו את $(2x^2)^2 \\cdot (3x)^2$",
                type: "multiplechoice",
                options: ["$36x^6$", "$12x^4$", "$36x^4$", "$12x^6$"],
                hint: "פרקו כל ביטוי בנפרד ואז כפלו את התוצאות. שימו לב למעריכים!",
                solutionGuide: "נפרק לשלבים:<br/>1. נפרק את הביטוי הראשון: $(2x^2)^2 = 4x^4$<br/>2. נפרק את הביטוי השני: $(3x)^2 = 9x^2$<br/>3. נכפול: $4x^4 \\cdot 9x^2 = 36x^6$"
            },
            {
                question: "מהי התוצאה של $(x^2y)^3 \\cdot (xy^2)^{-1}$?",
                type: "multiplechoice",
                options: ["$x^5y$", "$x^5y^3$", "$x^3y^5$", "$x^3y$"],
                hint: "פרקו כל ביטוי, זכרו שחזקה שלילית הופכת את הכיוון, ואז חברו את המעריכים של כל משתנה",
                solutionGuide: "נפרק לשלבים:<br/>1. נפרק את הביטוי הראשון: $(x^2y)^3 = x^6y^3$<br/>2. נפרק את הביטוי השני: $(xy^2)^{-1} = x^{-1}y^{-2}$<br/>3. נכפול וחברו מעריכים: $x^6y^3 \\cdot x^{-1}y^{-2} = x^{6+(-1)}y^{3+(-2)} = x^5y$"
            },
            {
                question: "התאימו כל חזקה לערך שלה",
                type: "matching",
                pairs: [
                    { left: "2³", right: "8" },
                    { left: "2⁴", right: "16" },
                    { left: "2⁵", right: "32" },
                    { left: "2⁶", right: "64" }
                ],
                hint: "כפלו את הבסיס במספר הפעמים שמציין המעריך",
                solutionGuide: "2³ = 2×2×2 = 8, 2⁴ = 2×2×2×2 = 16, וכן הלאה"
            },
            {
                question: "התאימו כל חזקה לכתיבה המפורטת שלה",
                type: "matching",
                pairs: [
                    { left: "3³", right: "3 × 3 × 3" },
                    { left: "4²", right: "4 × 4" },
                    { left: "5³", right: "5 × 5 × 5" },
                    { left: "2⁴", right: "2 × 2 × 2 × 2" }
                ],
                hint: "כתבו את הבסיס מספר פעמים לפי המעריך",
                solutionGuide: "המעריך מציין כמה פעמים לכפול את הבסיס בעצמו"
            },
            {
                question: "התאימו בין חזקות שוות",
                type: "matching",
                pairs: [
                    { left: "$2^6$", right: "$(2^3)^2$" },
                    { left: "$3^4$", right: "$(3^2)\\cdot(3^2)$" },
                    { left: "$2^8$", right: "$(2^4)^2$" },
                    { left: "$5^2$", right: "$5^1 \\times 5^1$" }
                ],
                hint: "פתחו את החזקות ובדקו כמה פעמים מופיע הבסיס",
                solutionGuide: "כשמעלים חזקה בחזקה, מכפילים את המעריכים"
            },
            {
                question: "התאימו כל חזקה למשמעות שלה",
                type: "matching",
                pairs: [
                    { left: "2³", right: "שטח קובייה שאורך צלעה 2" },
                    { left: "5²", right: "שטח ריבוע שאורך צלעו 5" },
                    { left: "4³", right: "נפח קובייה שאורך צלעה 4" },
                    { left: "3²", right: "שטח ריבוע שאורך צלעו 3" }
                ],
                hint: "חשבו על המשמעות הגיאומטרית של חזקה 2 וחזקה 3",
                solutionGuide: "חזקה 2 מייצגת שטח ריבוע, חזקה 3 מייצגת נפח קובייה"
            },
            {
                question: "התאימו בין ביטויים שווים",
                type: "matching",
                pairs: [
                    { left: "2⁴", right: "4²" },
                    { left: "3³", right: "9 × 3" },
                    { left: "2⁵", right: "8 × 4" },
                    { left: "4²", right: "8 × 2" }
                ],
                hint: "חשבו את הערך של כל ביטוי",
                solutionGuide: "2⁴=16, 4²=16, 3³=27, 9×3=27, וכן הלאה"
            },
            {
                question: "התאימו כל מספר לייצוג שלו בחזקות",
                type: "matching",
                pairs: [
                    { left: "16", right: "2⁴" },
                    { left: "25", right: "5²" },
                    { left: "27", right: "3³" },
                    { left: "81", right: "9²" }
                ],
                hint: "נסו לפרק את המספר לגורמים",
                solutionGuide: "16=2×2×2×2, 25=5×5, 27=3×3×3, 81=9×9"
            },
            {
                question: "סדרו את החזקות מהקטנה לגדולה",
                type: "order",
                items: ["2³", "3³", "4³", "5³"],
                hint: "חשבו את הערך של כל חזקה",
                solutionGuide: "2³=8 < 3³=27 < 4³=64 < 5³=125"
            },
            {
                question: "התאימו כל חזקה לתיאור המילולי שלה",
                type: "matching",
                pairs: [
                    { left: "2³", right: "מכפלה של שלוש פעמים 2" },
                    { left: "5²", right: "מכפלה של שתי פעמים 5" },
                    { left: "3⁴", right: "מכפלה של ארבע פעמים 3" },
                    { left: "4²", right: "מכפלה של שתי פעמים 4" }
                ],
                hint: "המעריך מציין כמה פעמים מכפילים את הבסיס",
                solutionGuide: "התיאור המילולי מתאר את מספר הפעמים שהבסיס מוכפל בעצמו"
            },
            {
                question: "התאימו בין חזקות לפירוק שלהן",
                type: "matching",
                pairs: [
                    { left: "2⁵", right: "2² × 2³" },
                    { left: "3⁴", right: "3² × 3²" },
                    { left: "2⁶", right: "2³ × 2³" },
                    { left: "4³", right: "4¹ × 4²" }
                ],
                hint: "סכום המעריכים בצד ימין צריך להיות שווה למעריך בצד שמאל",
                solutionGuide: "מפרקים את המעריך לסכום של מספרים"
            }
        ]
    }
};
