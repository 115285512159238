export const learningContent = {
    "מספרים מכוונים": {
        tags: ['כיתה ז׳', 'אלגברה', 'חיבור וחיסור'],
        relatedLinks: [
            { title: "תרגול מספרים מכוונים", url: "/?tags=אלגברה", description: "תרגול בעיות מספרים מכוונים" },
            { title: "תרגול חיבור וחיסור מספרים מכוונים", url: "/?tags=חיבור וחיסור", description: "תרגול בעיות חיבור וחיסור מספרים מכוונים" }
        ],
        getStarted: [
            "**מהם מספרים מכוונים?**",
            "מספרים מכוונים כוללים מספרים חיוביים, שליליים ואפס",
            "מספרים חיוביים נמצאים מימין לאפס על ציר המספרים",
            "מספרים שליליים נמצאים משמאל לאפס על ציר המספרים",
            "",
            "**חיבור מספרים מכוונים:**",
            "חיבור שני מספרים חיוביים: חיבור רגיל",
            "חיבור שני מספרים שליליים: חיבור והוספת סימן מינוס",
            "חיבור מספר חיובי ושלילי: חיסור הקטן מהגדול וסימן לפי הגדול",
            "",
            "**חיסור מספרים מכוונים:**",
            "חיסור = חיבור ההופכי",
            "$a - b = a + (-b)$",
            "",
            "**כפל מספרים מכוונים:**",
            "חיובי × חיובי = חיובי",
            "חיובי × שלילי = שלילי",
            "שלילי × חיובי = שלילי",
            "שלילי × שלילי = חיובי",
            "",
            "**חוקי סדר פעולות:**",
            "1. סוגריים",
            "2. חזקות",
            "3. כפל וחילוק משמאל לימין",
            "4. חיבור וחיסור משמאל לימין"
        ],
        examples: [
            {
                question: "חשבו: $(-5) + 8$",
                solution: "נחסר את המספר הקטן מהגדול: $8 - 5 = 3$\nהסימן יהיה של המספר הגדול (חיובי)\nלכן התוצאה היא 3",
                drawing: {
                    type: "numberLine",
                    min: -10,
                    max: 10,
                    points: [-5, 0, 3],
                    labels:[-5, "$0$", 3],
                    caption: "תנועה על ציר המספרים מ-(-5) ימינה 8 יחידות"
                }
            },
            {
                question: "חשבו: $(-3) × (-4)$",
                solution: "לפי חוקי הכפל: שלילי × שלילי = חיובי\nלכן $(-3) × (-4) = 12$",
            },
            {
                question: "חשבו: $6 - (-2)$",
                solution: "חיסור שלילי = חיבור חיובי\n$6 - (-2) = 6 + 2 = 8$",
                
            }
        ],
        quiz: [
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) × ({B})$",
                params: {
                  A: {
                    range: [-15, -1],
                    type: "integer"
                  },
                  B: {
                    range: [-15, -1],
                    type: "integer"
                  }
                },
                formula: "A*B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-ten"],
                  custom: [
                    "-({A}*{B})",         // Common mistake: keeping result negative
                    "{A}*(-{B})",         // Common mistake: flipping only one sign
                    "-{A}*{B}"           // Another sign flip variation
                  ]
                },
                hint: "זכרו את חוקי הכפל של מספרים מכוונים - מה קורה כשכופלים שני מספרים שליליים?",
                metadata: {
                  step1: "{A} * {B}"
                },
                solutionGuide: "שלילי × שלילי = חיובי, לכן $({A}) × ({B}) = {Result}$"
              },
        
              // Three numbers multiplication
              {
                type: "multiChoiceRandomMath",
                question: "מה התוצאה של $({A}) × ({B}) × ({C})$?",
                params: {
                  A: {
                    range: [-10, -1],
                    type: "integer"
                  },
                  B: {
                    range: [-10, -1],
                    type: "integer"
                  },
                  C: {
                    range: [-10, -1],
                    type: "integer"
                  }
                },
                formula: "A*B*C",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-ten"],
                  custom: [
                    "-({A}*{B}*{C})",     // Common mistake: final sign flip
                    "{A}*(-{B}*{C})",     // Partial sign flip
                    "-({A}*{B})*{C}"      // Wrong intermediate sign handling
                  ]
                },
                hint: "פתרו בשלבים: קודם כפלו שני מספרים, ואז את התוצאה במספר השלישי",
                metadata: {
                  step1: "{A} * {B}",
                  step2: "({A} * {B}) * {C}"
                },
                solutionGuide: "$ ({A}) × ({B}) = {step1} $, ואז $ {step1} × ({C}) = {Result} $"
              },
        
              // Mix of positive and negative multiplication
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) × {B}$",
                params: {
                  A: {
                    range: [-15, -1],
                    type: "integer"
                  },
                  B: {
                    range: [1, 15],
                    type: "integer"
                  }
                },
                formula: "A*B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-ten"],
                  custom: [
                    "-({A}*{B})",         // Flipping the result unnecessarily
                    "{A}*(-{B})",         // Flipping the positive number
                    "-{A}*(-{B})"         // Flipping both numbers
                  ]
                },
                hint: "זכרו את חוקי הכפל של מספרים מכוונים - מה קורה כשכופלים מספר שלילי במספר חיובי?",
                metadata: {
                  absA: "abs({A})"
                },
                solutionGuide: "שלילי × חיובי = שלילי, לכן $({A}) × {B} = {Result}$"
              },
        
              // Three mixed numbers multiplication
              {
                type: "multiChoiceRandomMath",
                question: "מה התוצאה של $({A}) × {B} × ({C})$?",
                params: {
                  A: {
                    range: [-10, -1],
                    type: "integer"
                  },
                  B: {
                    range: [1, 10],
                    type: "integer"
                  },
                  C: {
                    range: [-10, -1],
                    type: "integer"
                  }
                },
                formula: "A*B*C",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-ten"],
                  custom: [
                    "-({A}*{B}*{C})",     // Flipping final result
                    "(-{A}*{B})*(-{C})",  // Wrong intermediate signs
                    "{A}*(-{B})*{C}"      // Flipping middle number
                  ]
                },
                hint: "פתרו בשלבים משמאל לימין, וזכרו את חוקי הכפל של מספרים מכוונים",
                metadata: {
                  step1: "{A} * {B}",
                  step2: "({A} * {B}) * {C}"
                },
                solutionGuide: " $ ({A}) × {B} = {step1}$, ואז $ {step1} × ({C}) = {Result} $ "
              },
        
              // Category 1: Negative + Positive
              {
                type: "multiChoiceRandomMath",
                question: "מה התוצאה של $ ({A}) + {B} $?",
                params: {
                  A: {
                    range: [-9, -1],
                    type: "integer"
                  },
                  B: {
                    range: [2, 10],
                    type: "integer"
                  }
                },
                formula: "A+B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "-({A}+{B})",         // Flipping the entire result
                    "{A}-{B}",            // Converting addition to subtraction
                    "-{B}+{A}"           // Flipping the positive number
                  ]
                },
                hint: "כשמחברים מספר חיובי ושלילי, מחסירים את הקטן מהגדול והסימן יהיה של המספר הגדול",
                metadata: {
                  absA: "abs({A})",
                  res: "{B}+{A}"
                },
                solutionGuide: "$ {B} - {absA} = {res} $, והסימן יהיה של המספר הגדול ({B})"
              },
        
              // Category 2: Positive + Negative
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} + ({B}) $",
                params: {
                  A: {
                    range: [2, 10],
                    type: "integer"
                  },
                  B: {
                    range: [-9, -1],
                    type: "integer"
                  }
                },
                formula: "A+B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "-({A}+{B})",         // Flipping the entire sum
                    "{A}-(-{B})",         // Double negative confusion
                    "-{A}+{B}"           // Flipping the wrong number
                  ]
                },
                hint: "כשמחברים מספר חיובי ושלילי, מחסירים את הקטן מהגדול והסימן יהיה של המספר הגדול",
                metadata: {
                  absB: "abs({B})",
                  res: "{A}+{B}"
                },
                solutionGuide: "$ {A} - {absB} = {res} $, והסימן יהיה של המספר הגדול ({A})"
              },
        
              // Category 3: Negative + Negative
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) + ({B})$",
                params: {
                  A: {
                    range: [-9, -1],
                    type: "integer"
                  },
                  B: {
                    range: [-9, -1],
                    type: "integer"
                  }
                },
                formula: "A+B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"]
                },
                hint: "כשמחברים שני מספרים שליליים, מחברים את הערכים המוחלטים והתוצאה שלילית",
                metadata: {
                  absA: "abs({A})",
                  absB: "abs({B})",
                  res: "{A}+{B}"
                },
                solutionGuide: "חיבור שני מספרים שליליים: $({A}) + ({B}) = -({absA} + {absB}) = {res} $"
              },
        
              // Category 4: Positive + Positive
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} + {B} $",
                params: {
                  A: {
                    range: [1, 9],
                    type: "integer"
                  },
                  B: {
                    range: [1, 9],
                    type: "integer"
                  }
                },
                formula: "A+B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "-({A}+{B})",        // Flipping the entire sum unnecessarily
                    "-{A}+{B}",          // Flipping first number
                    "{A}+(-{B})"         // Flipping second number
                  ]
                },
                hint: "חיבור שני מספרים חיוביים הוא חיובי",
                metadata: {
                  res: "{A}+{B}"
                },
                solutionGuide: "חיבור שני מספרים חיוביים: $ {A} + {B} = {res} $"
              },
        
              // Category 5: Negative - Positive
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) - {B} $",
                params: {
                  A: {
                    range: [-9, -1],
                    type: "integer"
                  },
                  B: {
                    range: [1, 9],
                    type: "integer"
                  }
                },
                formula: "A-B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "{A}+{B}",           // Forgetting to handle subtraction
                    "-({A}-{B})",        // Flipping the entire result
                    "{A}+(-{B})"        // Confusion with negative addition
                  ]
                },
                hint: "חיסור זה כמו חיבור ההופכי",
                metadata: {
                  absA: "abs({A})",
                  res: "{A}-{B}"
                },
                solutionGuide: "$({A}) - {B} = ({A}) + (-{B}) = {res} $"
              },
        
              // Category 6: Positive - Negative
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - ({B})$",
                params: {
                  A: {
                    range: [1, 9],
                    type: "integer"
                  },
                  B: {
                    range: [-9, -1],
                    type: "integer"
                  }
                },
                formula: "A-B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "{A}+{B}",           // Forgetting double negative rule
                    "-({A}-{B})",        // Flipping final result
                    "{A}-(-{B})"        // Double negative confusion
                  ]
                },
                hint: "חיסור מספר שלילי שקול לחיבור המספר החיובי",
                metadata: {
                  absB: "abs({B})",
                  res: "{A}-{B}"
                },
                solutionGuide: "$ {A} - ({B}) = {A} + {absB} = {res} $"
              },
        
              // Category 7: Positive - Positive
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - {B} $",
                params: {
                  A: {
                    range: [1, 9],
                    type: "integer"
                  },
                  B: {
                    range: [1, 9],
                    type: "integer"
                  }
                },
                formula: "A-B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "-({A}-{B})",        // Flipping the result unnecessarily
                    "{B}-{A}",           // Reversing the order
                    "-{A}+{B}"          // Sign confusion
                  ]
                },
                hint: "חיסור מספר חיובי שקול לחיבור המספר השלילי שלו",
                metadata: {
                  res: "{A}-{B}"
                },
                solutionGuide: "$ {A} - {B} = {res} $"
              },
        
              // Category 8: Negative - Negative
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) - ({B})$",
                params: {
                  A: {
                    range: [-9, -1],
                    type: "integer"
                  },
                  B: {
                    range: [-9, -1],
                    type: "integer"
                  }
                },
                formula: "A-B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "{A}+{B}",           // Forgetting subtraction rule
                    "-({A}-{B})",        // Unnecessary sign flip
                    "(-{A})+(-{B})"     // Converting to addition incorrectly
                  ]
                },
                hint: "חיסור מספר שלילי שקול לחיבור המספר החיובי",
                metadata: {
                  absA: "abs({A})",
                  absB: "abs({B})",
                  res: "{A}-{B}"
                },
                solutionGuide: "$({A}) - ({B}) = ({A}) + {absB} = {res} $"
              },
              // two digit numbers
             
        
              // Category 1: Negative + Positive
              {
                type: "multiChoiceRandomMath",
                question: "מה התוצאה של $ ({A}) + {B} $?",
                params: {
                  A: {
                    range: [-99, -10],
                    type: "integer"
                  },
                  B: {
                    range: [10, 99],
                    type: "integer"
                  }
                },
                formula: "A+B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "-({A}+{B})",        // Flipping the entire sum
                    "{A}-{B}",           // Converting to subtraction
                    "-{B}+(-{A})"       // Double negative confusion
                  ]
                },
                hint: "כשמחברים מספר חיובי ושלילי, מחסירים את הקטן מהגדול והסימן יהיה של המספר הגדול",
                metadata: {
                  absA: "abs({A})",
                  res: "{B}+{A}"
                },
                solutionGuide: "$ {B} - {absA} = {res} $, והסימן יהיה של המספר הגדול ({B})"
              },
        
              // Category 2: Positive + Negative
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} + ({B}) $",
                params: {
                  A: {
                    range: [10, 99],
                    type: "integer"
                  },
                  B: {
                    range: [-99, -10],
                    type: "integer"
                  }
                },
                formula: "A+B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"]
                },
                hint: "כשמחברים מספר חיובי ושלילי, מחסירים את הקטן מהגדול והסימן יהיה של המספר הגדול",
                metadata: {
                  absB: "abs({B})",
                  res: "{A}+{B}"
                },
                solutionGuide: "$ {A} - {absB} = {res} $, והסימן יהיה של המספר הגדול ({A})"
              },
        
              // Category 3: Negative + Negative
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) + ({B})$",
                params: {
                  A: {
                    range: [-99, -10],
                    type: "integer"
                  },
                  B: {
                    range: [-99, -10],
                    type: "integer"
                  }
                },
                formula: "A+B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"]
                },
                hint: "כשמחברים שני מספרים שליליים, מחברים את הערכים המוחלטים והתוצאה שלילית",
                metadata: {
                  absA: "abs({A})",
                  absB: "abs({B})",
                  res: "{A}+{B}"
                },
                solutionGuide: "חיבור שני מספרים שליליים: $({A}) + ({B}) = -({absA} + {absB}) = {res} $"
              },
        
              // Category 4: Positive + Positive
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} + {B} $",
                params: {
                  A: {
                    range: [10, 99],
                    type: "integer"
                  },
                  B: {
                    range: [10, 99],
                    type: "integer"
                  }
                },
                formula: "A+B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"]
                },
                hint: "חיבור שני מספרים חיוביים הוא חיובי",
                metadata: {
                  res: "{A}+{B}"
                },
                solutionGuide: "חיבור שני מספרים חיוביים: $ {A} + {B} = {res} $"
              },
        
              // Category 5: Negative - Positive
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) - {B} $",
                params: {
                  A: {
                    range: [-99, -10],
                    type: "integer"
                  },
                  B: {
                    range: [10, 99],
                    type: "integer"
                  }
                },
                formula: "A-B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "{A}+{B}",           // Forgetting to handle subtraction
                    "-({A}-{B})",        // Flipping the entire result
                    "{A}+(-{B})"        // Confusion with negative addition
                  ]
                },
                hint: "חיסור זה כמו חיבור ההופכי",
                metadata: {
                  absA: "abs({A})",
                  res: "{A}-{B}"
                },
                solutionGuide: "$({A}) - {B} = ({A}) + (-{B}) = {res} $"
              },
        
              // Category 6: Positive - Negative
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - ({B})$",
                params: {
                  A: {
                    range: [10, 99],
                    type: "integer"
                  },
                  B: {
                    range: [-99, -10],
                    type: "integer"
                  }
                },
                formula: "A-B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "{A}+{B}",           // Forgetting double negative rule
                    "-({A}-{B})",        // Flipping final result
                    "{A}-(-{B})"        // Double negative confusion
                  ]
                },
                hint: "חיסור מספר שלילי שקול לחיבור המספר החיובי",
                metadata: {
                  absB: "abs({B})",
                  res: "{A}-{B}"
                },
                solutionGuide: "$ {A} - ({B}) = {A} + {absB} = {res} $"
              },
        
              // Category 7: Positive - Positive
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - {B} $",
                params: {
                  A: {
                    range: [10, 99],
                    type: "integer"
                  },
                  B: {
                    range: [10, 99],
                    type: "integer"
                  }
                },
                formula: "A-B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "-({A}-{B})",        // Flipping the result unnecessarily
                    "{B}-{A}",           // Reversing the order
                    "-{A}+{B}"          // Sign confusion
                  ]
                },
                hint: "חיסור מספר חיובי שקול לחיבור המספר השלילי שלו",
                metadata: {
                  res: "{A}-{B}"
                },
                solutionGuide: "$ {A} - {B} = {res} $"
              },
        
              // Category 8: Negative - Negative
              {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A}) - ({B})$",
                params: {
                  A: {
                    range: [-99, -10],
                    type: "integer"
                  },
                  B: {
                    range: [-99, -10],
                    type: "integer"
                  }
                },
                formula: "A-B",
                distractors: {
                  strategies: ["sign-flip", "off-by-one", "off-by-two"],
                  custom: [
                    "{A}+{B}",           // Forgetting subtraction rule
                    "-({A}-{B})",        // Unnecessary sign flip
                    "(-{A})+(-{B})"     // Converting to addition incorrectly
                  ]
                },
                hint: "חיסור מספר שלילי שקול לחיבור המספר החיובי",
                metadata: {
                  absA: "abs({A})",
                  absB: "abs({B})",
                  res: "{A}-{B}"
                },
                solutionGuide: "$({A}) - ({B}) = ({A}) + {absB} = {res} $"
              },


            {
                question: "סדרו את המספרים מהקטן לגדול",
                type: "order",
                items: ["$-8$", "$-5$", "$-3$", "$0$", "$2$"],
                hint: "השתמשו בציר המספרים - ככל שזזים שמאלה המספר קטן יותר",
                solutionGuide: "$-8 < -5 < -3 < 0 < 2$"
            },
            {
                question: "סדרו את התרגילים מהגדול לקטן לפי התוצאה שלהם",
                type: "order",
                items: ["$(-2)×(-3)$", "$2+2$", "$8÷4$", "$(-1)+1$", "$5-6$"],
                hint: "פתרו קודם כל תרגיל: $4$, $-1$, $6$, $0$, $2$",
                solutionGuide: "$(-2)×(-3) = 6 > 2+2 = 4 > 8÷4 = 2 > (-1)+1 = 0 > 5-6 = -1$"
            },
            {
                question: "סדרו את תוצאות התרגילים מהקטן לגדול",
                type: "order",
                items: ["$(-2)×(3)$", "$(-2)+(3)$", "$(-2)÷(-1)$", "$(-2)×(-3)$"],
                hint: "פתרו קודם כל תרגיל: $6$, $-6$, $1$, $2$",
                solutionGuide: "$(-2)×(3) = -6 < (-2)+(3) = 1 < (-2)÷(-1) = 2 < (-2)×(-3) = 6$"
            },
            {
                question: "סדרו את המרחקים מאפס מהקטן לגדול",
                type: "order",
                items: ["$-2$", "$4$", "$-5$", "$-7$", "$8$"],
                hint: "המרחק מאפס הוא הערך המוחלט של המספר",
                solutionGuide: "$|-2| < |4| < |-5| < |-7| < |8|$"
            },
            {
                question: "התאימו כל ביטוי לתוצאה שלו",
                type: "matching",
                pairs: [
                    { left: "$(-3)×(-4)$", right: "$12$" },
                    { left: "$(-3)×(4)$", right: "$-12$" },
                    { left: "$(-3)÷(-3)$", right: "$1$" },
                    { left: "$(-3)+(-4)$", right: "$-7$" }
                ],
                hint: "זכרו את חוקי הכפל והחיבור של מספרים מכוונים",
                solutionGuide: "שלילי × שלילי = חיובי, שלילי × חיובי = שלילי, שלילי ÷ שלילי = חיובי"
            },
            {
                question: "התאימו כל תרגיל למרחק שלו מאפס",
                type: "matching",
                pairs: [
                    { left: "$(-2)×(2)-1$", right: "$5$" },
                    { left: "$12÷4$", right: "$3$" },
                    { left: "$(-3)×(3)+1$", right: "$8$" },
                    { left: "$(-2)+2$", right: "$0$" }
                ],
                hint: "המרחק מאפס הוא הערך המוחלט של המספר",
                solutionGuide: "המרחק מאפס של מספר שלילי שווה לערך המוחלט שלו"
            },
            {
                question: "התאימו כל ביטוי למספר הנגדי שלו",
                type: "matching",
                pairs: [
                    { left: "$(-7)$", right: "$7$" },
                    { left: "$(-2)×(4)$", right: "$8$" },
                    { left: "$(-3)+(-6)$", right: "$9$" },
                    { left: "$(-4)×(-2)$", right: "$-8$" }
                ],
                hint: "פתרו קודם את הביטויים ומצאו את המספרים הנגדיים",
                solutionGuide: "המספר הנגדי הוא המספר שסכומו עם המספר המקורי הוא 0"
            },
            {
                question: "התאימו כל ביטוי לביטוי השווה לו",
                type: "matching",
                pairs: [
                    { left: "$8-(-2)$", right: "$8+2$" },
                    { left: "$(-3)×(-4)$", right: "$3×4$" },
                    { left: "$-6÷(-2)$", right: "$6÷2$" },
                    { left: "$(-5)-(-5)$", right: "$0$" }
                ],
                hint: "פתרו את שני הצדדים או השתמשו בחוקי הפעולות",
                solutionGuide: "חיסור שלילי = חיבור חיובי, כפל/חילוק שני מספרים שליליים = כפל/חילוק שני מספרים חיוביים"
            },
            {
                question: "התאימו כל מספר לתיאור שלו",
                type: "matching",
                pairs: [
                    { left: "$-3$", right: "שלוש יחידות שמאלה מאפס" },
                    { left: "$5$", right: "חמש יחידות ימינה מאפס" },
                    { left: "$0$", right: "נקודת ההתחלה" },
                    { left: "$-1$", right: "יחידה אחת שמאלה מאפס" }
                ],
                hint: "חשבו על תנועה על ציר המספרים",
                solutionGuide: "מספרים שליליים נמצאים משמאל לאפס, חיוביים מימין לאפס"
            }
        ]
    }
};