export const learningContent = {
    "פעולות הפוכות - חיבור וחיסור": {
        tags: ['כיתה ב׳', 'חיבור וחיסור', 'פעולות הפוכות'],
        relatedLinks: [
            { title: "תרגול פעולות הפוכות", url: "/?tags=פעולות%20הפוכות", description: "תרגול בעיות פעולות הפוכות" }
        ],
        getStarted: [
            "**מה הן פעולות הפוכות?**",
            "פעולות הפוכות הן פעולות שמבטלות זו את זו",
            "חיבור וחיסור הם פעולות הפוכות",
            "",
            "**דוגמאות לפעולות הפוכות:**",
            "אם מחברים 5 ואז מחסרים 5, חוזרים למספר המקורי",
            "אם מחסרים 3 ואז מחברים 3, חוזרים למספר המקורי",
            "",
            "**עקרונות חשובים:**",
            "1. חיבור מספר וחיסורו מביא למספר המקורי",
            "2. חיסור מספר וחיבורו מביא למספר המקורי",
            "3. סדר הפעולות חשוב - צריך להשתמש באותו מספר",
            "",
            "**דוגמאות לשימוש:**",
            "$15 + 7 = 22$ $\\quad$→ $\\quad$ $22 - 7 = 15$",
            "$20 - 4 = 16$ $\\quad$→ $\\quad$ $16 + 4 = 20$"
        ],
        examples: [
            {
                question: "לדני היו 12 קלפים. הוא קיבל 5 קלפים ואז נתן 5 קלפים. כמה קלפים יש לו?",
                solution: "נחשב שלב אחר שלב:<br/>$12 + 5 = 17$ קלפים<br/>$17 - 5 = 12$ קלפים<br/>יש לו 12 קלפים, כמו בהתחלה, כי חיבור 5 וחיסור 5 מבטלים זה את זה"
            },
            {
                question: "אם $8 + 6 = 14$, מה התרגיל ההפוך שמראה שחיבור וחיסור הם פעולות הפוכות?",
                solution: "התרגיל ההפוך הוא: $14 - 6 = 8$<br/>אם מחסרים 6 מהתוצאה 14, מקבלים בחזרה את המספר הראשון 8"
            }
        ],
        quiz: [
            {
                type: "multiChoiceRandomMath",
                question: "אם $ {A} + {B} = {Calc_C} $, אז $ {Calc_C} - {B} = ?$",
                params: {
                    A: {
                        range: [10, 50],
                        type: "integer"
                    },
                    B: {
                        range: [5, 20],
                        type: "integer"
                    },
                },
                formula: "A",
                metadata: {
                    Calc_C: "{A}+{B}"
                },
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}",            
                        "{A}+{B}"           
                    ]
                },
                hint: "חיסור הוא פעולה הפוכה לחיבור. אם מחסרים את אותו מספר שחיברנו, מקבלים את המספר ההתחלתי",
                solutionGuide: "$ {A} + {B} = {Calc_C} $ → $ {Calc_C} - {B} = {A} $ - אם מחסרים {B}, חוזרים למספר המקורי"
            },
            {
                type: "multiChoiceRandomMath",
                question: "אם $ {A} - {B} = {Calc_C} $, אז $ {Calc_C} + {B} = ?$",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    Calc_C: "{A}-{B}"
                },
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}",            // Common mistake: using wrong number
                        "{A}-{B}",        // Common mistake: using difference
                        "{A}-2*{B}"       // Common mistake: subtracting instead of adding
                    ]
                },
                hint: "חיבור הוא פעולה הפוכה לחיסור. אם מחברים את אותו מספר שחיסרנו, מקבלים את המספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Calc_C} $ → $ {Calc_C} + {B} = {A} $ - אם מחברים {B}, חוזרים למספר המקורי"
            },
            {
                type: "multiChoiceRandomMath",
                question: "לרומי היו {A} סוכריות. היא קיבלה {B} סוכריות ואז נתנה {B} סוכריות. כמה סוכריות יש לה עכשיו?",
                params: {
                    A: {
                        range: [10, 30],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}+{B}",          // Common mistake: only adding
                        "{A}-{B}",          // Common mistake: only subtracting
                    ]
                },
                hint: "חיבור וחיסור אותו מספר מבטלים זה את זה ומחזירים למספר ההתחלתי",
                solutionGuide: "$ {A} + {B} - {B} = {A} $ - חיבור וחיסור {B} מבטלים זה את זה"
            },
            {
                type: "multiChoiceRandomMath",
                question: "אם $ {A} + {B} = {Calc_C} $, מה צריך לחסר מ-{Calc_C} כדי לקבל  {A} ?",
                params: {
                    A: {
                        range: [10, 50],
                        type: "integer"
                    },
                    B: {
                        range: [5, 20],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_C: "{A}+{B}"
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}",            // Common mistake: using wrong number
                        "{A}-{B}",        // Common mistake: using the difference instead of the correct subtrahend
                        "{B}+3"           // Off by 3
                    ]
                },
                hint: "צריך לחסר את המספר שחיברנו כדי לחזור למספר ההתחלתי",
                solutionGuide: "$ {A} + {B} = {Calc_C} $ → $ {Calc_C} - {B} = {A} $ - צריך לחסר {B} כדי לחזור ל-{A}"
            },
            {
                type: "multiChoiceRandomMath",
                question: "אם $ {A} - {B} = {Calc_C} $, מה צריך לחבר ל-$ {Calc_C} $ כדי לקבל $ {A} $?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_C: "{A}-{B}"
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}",            
                        "{B}",            
                    ]
                },
                hint: "צריך לחבר את המספר שחיסרנו כדי לחזור למספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Calc_C} $ → $ {Calc_C} + {B} = {A} $ - צריך לחבר {B} כדי לחזור ל-{A}"
            },
            {
                type: "multiChoiceRandomMath",
                question: "ללביא היו {A} כדורים. הוא נתן {B} כדורים ואז קיבל {B} כדורים. כמה כדורים יש לו עכשיו?",
                params: {
                    A: {
                        range: [15, 40],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}+{B}",          
                        "{A}-{B}",          
                    ]
                },
                hint: "חיסור וחיבור אותו מספר מבטלים זה את זה ומחזירים למספר ההתחלתי",
                solutionGuide: "$ {A} - {B} + {B} = {A} $ - חיסור וחיבור {B} מבטלים זה את זה"
            },
            {
                type: "multiChoiceRandomMath",
                question: "אם $ {A} + {B} = {Calc_C} $, והחסרנו $ {B} $, כמה קיבלנו?",
                params: {
                    A: {
                        range: [10, 40],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_C: "{A}+{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}",            
                        "{A}+{B}",            
                    ]
                },
                hint: "כשמחסרים את אותו מספר שחיברנו, חוזרים למספר ההתחלתי",
                solutionGuide: "$ {A} + {B} = {Calc_C} $ → $ {Calc_C} - {B} = {A} $ - חיסור {B} מחזיר למספר המקורי"
            },
            {
                type: "multiChoiceRandomMath",
                question: "לאור היו {A} בולים. הוא נתן {B} בולים ואז קיבל במתנה {B} בולים. כמה בולים יש לו?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}+{B}",          
                        "{A}-{B}",          
                    ]
                },
                hint: "חיסור וחיבור אותו מספר מבטלים זה את זה ומחזירים למספר ההתחלתי",
                solutionGuide: "$ {A} - {B} + {B} = {A} $ - חיסור וחיבור {B} מבטלים זה את זה"
            },
            {
                type: "matching",
                question: "התאימו כל תרגיל לתרגיל ההפוך שלו",
                pairs: [
                    { left: "$12 + 5 = 17$", right: "$17 - 5 = 12$" },
                    { left: "$20 - 8 = 12$", right: "$12 + 8 = 20$" },
                    { left: "$15 + 3 = 18$", right: "$18 - 3 = 15$" },
                    { left: "$25 - 6 = 19$", right: "$19 + 6 = 25$" }
                ],
                hint: "חפשו את התרגיל שמבטל את הפעולה הראשונה",
                solutionGuide: "כל תרגיל מימין מבטל את הפעולה שבתרגיל משמאל ומחזיר למספר ההתחלתי"
            },
            
            {
                type: "matching",
                question: "התאימו כל מצב למספר הסופי",
                pairs: [
                    { left: "היו 20, הוסיפו 5 והורידו 5", right: "20" },
                    { left: "היו 15, הורידו 3 והוסיפו 3", right: "15" },
                    { left: "היו 30, הוסיפו 8 והורידו 8", right: "30" },
                    { left: "היו 25, הורידו 4 והוסיפו 4", right: "25" }
                ],
                hint: "כשמוסיפים ומורידים אותו מספר, חוזרים למספר ההתחלתי",
                solutionGuide: "בכל מקרה, הפעולה השנייה מבטלת את הראשונה ולכן מקבלים בחזרה את המספר ההתחלתי"
            },
            {
                type: "matching",
                question: "התאימו כל פעולה לפעולה ההפוכה שלה",
                pairs: [
                    { left: "הוספת 7", right: "הורדת 7" },
                    { left: "הורדת 4", right: "הוספת 4" },
                    { left: "הוספת 10", right: "הורדת 10" },
                    { left: "הורדת 6", right: "הוספת 6" }
                ],
                hint: "חפשו את הפעולה שמבטלת את הפעולה הראשונה",
                solutionGuide: "כל פעולה מימין היא ההפך של הפעולה משמאל - הוספה מול הורדה של אותו מספר"
            }
        ]
    }
};