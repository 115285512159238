export const learningContent = {
    "סימני התחלקות ב-2,5,10": {
      tags: ['כיתה ג׳', 'כיתה ב׳', 'חילוק'],

        relatedLinks: [
            {
                title: "תרגול חילוק",
                url: "/?tags=חילוק",
                description: "תרגול נוסף בחילוק"
            },
            {
                title: "בעיות מילוליות בחילוק",
                url: "/?tags=חילוק,בעיות%20מילוליות",
                description: "תרגול בעיות מילוליות עם חילוק"
            },
            {
                title: "כפל וחילוק",
                url: "/?tags=כפל,חילוק",
                description: "תרגול משולב של כפל וחילוק"
            }
        ],

        getStarted: [
            "**איך לדעת אם מספר מתחלק ב-2, 5 או 10?**",
            "",
            "• <b>התחלקות ב-2</b>:",
            "מספר מתחלק ב-2 אם הספרה האחרונה שלו היא 0,2,4,6,8",
            "מספרים כאלה נקראים מספרים זוגיים",
            "לדוגמה: 12, 24, 36, 48, 50",
            "",
            "• <b>התחלקות ב-5</b>:",
            "מספר מתחלק ב-5 אם הספרה האחרונה שלו היא 0 או 5",
            "לדוגמה: 15, 25, 30, 45, 50",
            "",
            "• <b>התחלקות ב-10</b>:",
            "מספר מתחלק ב-10 אם הספרה האחרונה שלו היא 0",
            "לדוגמה: 10, 20, 30, 40, 50",
            "",
            "**טריקים מועילים:**",
            "אם מספר מתחלק ב-10, הוא מתחלק גם ב-2 וגם ב-5",
            "מספר שמתחלק ב-2 הוא תמיד זוגי",
            "אפשר לבדוק רק את הספרה האחרונה"
        ],
        examples: [
            {
                question: "האם המספר 346 מתחלק ב-2?",
                solution: "נסתכל על הספרה האחרונה: 6<br/>6 היא ספרה זוגית (0,2,4,6,8)<br/>לכן 346 מתחלק ב-2",
                drawing: {
                    type: "number-highlight",
                    number: "346",
                    highlightPosition: "ones",
                    caption: "הספרה 6 היא זוגית, לכן המספר מתחלק ב-2"
                }
            },
            {
                question: "האם המספר 785 מתחלק ב-5?",
                solution: "נסתכל על הספרה האחרונה: 5<br/>5 היא ספרה שמתחלקת ב-5 (0 או 5)<br/>לכן 785 מתחלק ב-5",
                drawing: {
                    type: "number-highlight",
                    number: "785",
                    highlightPosition: "ones",
                    caption: "הספרה 5 מראה שהמספר מתחלק ב-5"
                }
            },
            {
                question: "האם המספר 670 מתחלק ב-10?",
                solution: "נסתכל על הספרה האחרונה: 0<br/>0 בסוף אומר שהמספר מתחלק ב-10<br/>לכן 670 מתחלק ב-10",
                drawing: {
                    type: "number-highlight",
                    number: "670",
                    highlightPosition: "ones",
                    caption: "הספרה 0 מראה שהמספר מתחלק ב-10"
                }
            }
        ],
        quiz: [
            {
                type: "multiChoiceRandomMath",  
                question: "איזה מהמספרים הבאים מתחלק ב-2?",
                params: {
                  A: { range: [2, 2] },
                  B: { range: [50, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                  strategies: ["off-by-one", "off-by-three", "off-by-eleven"]
                },
                hint: "בדקו את הספרה האחרונה. אם היא זוגית (0,2,4,6,8), המספר מתחלק ב-2",
                metadata: {
                  Result: "{B} * {A}",
                  onesResult: "mod({B} * {A}, 10)",
                },
                solutionGuide: "רק {Result} מתחלק ב-2 כיוון שהוא מסתיים בספרה {onesResult} שהיא זוגית"
              },
              {
                type: "multiChoiceRandomMath",  
                question: "איזה מהמספרים הבאים מתחלק ב-5?",
                params: {
                  A: { range: [5, 5] },
                  B: { range: [20, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                  strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "בדקו את הספרה האחרונה. אם היא 0 או 5, המספר מתחלק ב-5",
                metadata: {
                  Result: "{B} * {A}",
                  onesResult: "mod({B} * {A}, 10)",
                },
                solutionGuide: "רק {Result} מתחלק ב-5 כיוון שהוא מסתיים בספרה {onesResult}"
              },
              
              {
                type: "multiChoiceRandomMath",  
                question: "בחרו את המספר שניתן לחלוקה ב-10",
                params: {
                  A: { range: [10, 10] },
                  B: { range: [10, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                  strategies: ["off-by-one", "off-by-two", "off-by-eleven"]
                },
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-10 רק אם הוא מסתיים ב-0",
                metadata: {
                  Result: "{B} * {A}",
                  onesResult: "mod({B} * {A}, 10)",
                },
                solutionGuide: "רק {Result} מתחלק ב-10 כיוון שהוא מסתיים ב-0"
              },
              {
                type: "multiChoiceRandomMath",  
                question: "בחרו את המספר שניתן לחלוקה ב-2",
                params: {
                  A: { range: [2, 2] },
                  B: { range: [50, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                  strategies: ["off-by-one", "off-by-three", "off-by-eleven"]
                },
                hint: "בדקו את הספרה האחרונה. אם היא זוגית (0,2,4,6,8), המספר מתחלק ב-2",
                metadata: {
                  Result: "{B} * {A}",
                  onesResult: "mod({B} * {A}, 10)",
                },
                solutionGuide: "רק {Result} מתחלק ב-2 כיוון שהוא מסתיים בספרה {onesResult} שהיא זוגית"
              },
              {
                type: "multiChoiceRandomMath",  
                question: "בחרו את המספר שניתן לחלוקה ב-5",
                params: {
                  A: { range: [5, 5] },
                  B: { range: [20, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                  strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "בדקו את הספרה האחרונה. אם היא 0 או 5, המספר מתחלק ב-5",
                metadata: {
                  Result: "{B} * {A}",
                  onesResult: "mod({B} * {A}, 10)",
                },
                solutionGuide: "רק {Result} מתחלק ב-5 כיוון שהוא מסתיים בספרה {onesResult}"
              },
              
              {
                type: "multiChoiceRandomMath",  
                question: "איזה מהמספרים הבאים מתחלק ב-10?",
                params: {
                  A: { range: [10, 10] },
                  B: { range: [10, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                  strategies: ["off-by-one", "off-by-two", "off-by-eleven"]
                },
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-10 רק אם הוא מסתיים ב-0",
                metadata: {
                  Result: "{B} * {A}",
                  onesResult: "mod({B} * {A}, 10)",
                },
                solutionGuide: "רק {Result} מתחלק ב-10 כיוון שהוא מסתיים ב-0"
              },
            // Divisible by 2 questions
            {
                type: "multiChoiceRandomBoolean",
                question: "המלך רוצה לחלק {A} סוכריות לזוגות. האם הוא יכול?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,2)",
                distractors: ["רק אם הכתר שלו מספיק גדול", "תלוי אם המלכה מרשה"],
                hint: "בדקו את הספרה האחרונה. מספר זוגי חייב להסתיים בספרה זוגית (0,2,4,6,8)",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, שהיא ספרה זוגית",
                    false: "{A} מסתיים ב-{onesA}, שהיא ספרה אי-זוגית"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "לחתול המתמטיקאי יש {A} עכברים. האם הוא יכול לסדר אותם בזוגות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,2)",
                distractors: ["תלוי באיזה צד קם החתול", "רק אם החתול למד מתמטיקה"],
                hint: "בדקו את הספרה האחרונה. מספר זוגי חייב להסתיים בספרה זוגית (0,2,4,6,8)",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, שהיא ספרה זוגית",
                    false: "{A} מסתיים ב-{onesA}, שהיא ספרה אי-זוגית"
                }
            },

            // Divisible by 5 questions
            {
                type: "multiChoiceRandomBoolean",
                question: "הקוסם רוצה לסדר {A} כדורים בשורות של 5. האם יצליח בלי שישאר לו כדורים?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,5)",
                distractors: ["רק אם הכדורים עגולים", "תלוי במצב הרוח של הקוסם"],
                hint: "כדי שמספר יתחלק ב-5 בדיוק, הוא חייב להסתיים ב-0 או ב-5",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, לכן מתחלק ב-5",
                    false: "{A} לא מסתיים ב-0 או 5, לכן לא מתחלק ב-5"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "הפיה הקטנה אספה {A} פרפרים. האם היא יכולה לחלק אותם ל-5 חברות שווה בשווה?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,5)",
                distractors: ["רק עם שרביט קסמים", "תלוי אם יש אבקת פיות"],
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-5 אם הוא מסתיים ב-0 או ב-5",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, לכן מתחלק ב-5",
                    false: "{A} לא מסתיים ב-0 או 5, לכן לא מתחלק ב-5"
                }
            },

            // Divisible by 10 questions
            {
                type: "multiChoiceRandomBoolean",
                question: "לדינוזאור יש {A} עוגיות. האם הוא יכול לחלק אותן ל-10 חברים שווה בשווה?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,10)",
                distractors: ["רק אם העוגיות טעימות", "תלוי אם הדינוזאור רעב"],
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-10 רק אם הוא מסתיים ב-0",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-0, לכן מתחלק ב-10",
                    false: "{A} לא מסתיים ב-0, לכן לא מתחלק ב-10"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "הנסיכה רוצה לסדר {A} פרחים בעשר אגרטלים שווים. האם תצליח?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,10)",
                distractors: ["רק אם היא עם כתר", "תלוי אם הצפרדע עוזר"],
                hint: "כדי להתחלק ב-10, המספר חייב להסתיים ב-0. בדקו את הספרה האחרונה",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-0, לכן מתחלק ב-10",
                    false: "{A} לא מסתיים ב-0, לכן לא מתחלק ב-10"
                }
            },
            // Division by 2 (Pairs)
            {
                type: "multiChoiceRandomBoolean",
                question: "הדרקון אסף {A} אבני חן. האם הוא יכול לסדר אותן בזוגות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,2)",
                distractors: ["רק אם האבנים נוצצות", "תלוי במצב רוח של הדרקון"],
                hint: "בדקו את הספרה האחרונה. מספר זוגי חייב להסתיים בספרה זוגית (0,2,4,6,8)",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, שהיא ספרה זוגית",
                    false: "{A} מסתיים ב-{onesA}, שהיא ספרה אי-זוגית"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "הג'ירפה אספה {A} עלים. האם היא יכולה לחלק אותם לזוגות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,2)",
                distractors: ["רק אם הצוואר ארוך מספיק", "תלוי אם העלים ירוקים"],
                hint: "בדקו את הספרה האחרונה. מספר זוגי חייב להסתיים בספרה זוגית (0,2,4,6,8)",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, שהיא ספרה זוגית",
                    false: "{A} מסתיים ב-{onesA}, שהיא ספרה אי-זוגית"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "לקוסם יש {A} שרביטים קסומים. האם הוא יכול לחלק אותם לזוגות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,2)",
                distractors: ["רק בליל ירח מלא", "תלוי אם יש קסם באוויר"],
                hint: "בדקו את הספרה האחרונה. מספר זוגי חייב להסתיים בספרה זוגית (0,2,4,6,8)",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, שהיא ספרה זוגית",
                    false: "{A} מסתיים ב-{onesA}, שהיא ספרה אי-זוגית"
                }
            },

            // Division by 5
            {
                type: "multiChoiceRandomBoolean",
                question: "הפנדה אספה {A} במבוק. האם היא יכולה לחלק אותם ל-5 ערימות שוות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,5)",
                distractors: ["רק אם הבמבוק טרי", "תלוי אם היא שבעה"],
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-5 רק אם הוא מסתיים ב-0 או ב-5",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, לכן מתחלק ב-5",
                    false: "{A} לא מסתיים ב-0 או ב-5, לכן לא מתחלק ב-5"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "לארנב יש {A} גזרים. האם הוא יכול לחלק אותם ל-5 ערימות שוות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,5)",
                distractors: ["רק אם הגזרים כתומים", "תלוי אם הארנב קופץ"],
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-5 רק אם הוא מסתיים ב-0 או ב-5",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, לכן מתחלק ב-5",
                    false: "{A} לא מסתיים ב-0 או ב-5, לכן לא מתחלק ב-5"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "לאסטרונאוט יש {A} כוכבי ים. האם הוא יכול לחלק אותם ל-5 אקווריומים שווים?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,5)",
                distractors: ["רק בחללית", "תלוי אם יש מים"],
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-5 אם הוא מסתיים ב-0 או ב-5",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, לכן מתחלק ב-5",
                    false: "{A} לא מסתיים ב-0 או ב-5, לכן לא מתחלק ב-5"
                }
            },

            // Division by 10
            {
                type: "multiChoiceRandomBoolean",
                question: "לקנגורו יש {A} מטבעות בכיס. האם הוא יכול לחלק אותם ל-10 ערימות שוות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,10)",
                distractors: ["רק אם הכיס גדול מספיק", "תלוי אם הוא קופץ"],
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-10 רק אם הוא מסתיים ב-0",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-0, לכן מתחלק ב-10",
                    false: "{A} לא מסתיים ב-0, לכן לא מתחלק ב-10"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "הגמד אסף {A} פטריות. האם הוא יכול לחלק אותן ל-10 סלים שווים?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,10)",
                distractors: ["רק אם הפטריות קסומות", "תלוי בגובה של הגמד"],
                hint: "מספר מתחלק ב-10 רק אם הוא מסתיים ב-0. בדקו את הספרה האחרונה",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-0, לכן מתחלק ב-10",
                    false: "{A} לא מסתיים ב-0, לכן לא מתחלק ב-10"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "לפינגווין יש {A} קוביות קרח. האם הוא יכול לחלק אותן ל-10 חברים שווה בשווה?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,10)",
                distractors: ["רק בקוטב הצפוני", "תלוי אם הקרח נמס"],
                hint: "בדקו את הספרה האחרונה. מספר מתחלק ב-10 רק אם הוא מסתיים ב-0",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-0, לכן מתחלק ב-10",
                    false: "{A} לא מסתיים ב-0, לכן לא מתחלק ב-10"
                }
            },

            {
                type: "multiChoiceRandomBoolean",
                question: "האם המספר {A} מתחלק ב-2?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,2)",
                hint: "בדקו את הספרה האחרונה",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, שהיא ספרה זוגית, לכן מתחלק ב-2",
                    false: "{A} מסתיים ב-{onesA}, שהיא ספרה אי-זוגית, לכן לא מתחלק ב-2"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "האם המספר {A} מתחלק ב-5?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,5)",
                hint: "בדקו את הספרה האחרונה",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-{onesA}, לכן מתחלק ב-5",
                    false: "{A} לא מסתיים ב-0 או ב-5, לכן לא מתחלק ב-5"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "האם המספר {A} מתחלק ב-10?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,10)",
                hint: "בדקו את הספרה האחרונה",
                metadata: { onesA: "mod({A}, 10)" },
                solutiontemplate: {
                    true: "{A} מסתיים ב-0, לכן מתחלק ב-10",
                    false: "{A} לא מסתיים ב-0, לכן לא מתחלק ב-10"
                }
            },

        ]
    }
}; 