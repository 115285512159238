export const checkAnswer = (userAnswer, question) => {
  switch (question.type) {
    case 'multiplechoice':
      case 'multiChoiceRandomMath': 
      return question.shuffledAnswerMap[userAnswer.charCodeAt(0) - 97] === 'a';

    case 'fillIn':
      return question.acceptableAnswers.includes(userAnswer.trim());

    case 'order':
      return JSON.stringify(userAnswer) === JSON.stringify(question.originalOrder);

    case 'matching':
      return userAnswer.every((item, index) => 
        item.left === question.pairs[index].left && 
        item.right === question.pairs[index].right
      );

    default:
      return false;
  }
}; 