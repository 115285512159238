import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { learningContent } from './topics/topicsList';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { topicUrls } from './topics/topicsList';
import { Link } from '@chakra-ui/react';
import { AboutModal } from './components/AboutModal';
import TopicCard from './components/TopicCard';
import AppHeader from './components/AppHeader';
import { Helmet } from 'react-helmet';
import { AuthWrapper } from './AuthWrapper';

import {
  Box,
  Container,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
  HStack,
  Icon,
  Button,
  Tag as ChakraTag,
  TagLabel,
  TagLeftIcon,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react';
import { FiTag, FiArrowRight } from 'react-icons/fi';
import { QuizCard } from './components/QuizCard';
import { ExplanationCard } from './components/ExplanationCard';
import { ExampleCard } from './components/ExampleCard';

// Move tabToSection outside component
const tabToSection = {
  getStarted: 'explanation',
  examples: 'examples',
  quiz: 'quiz'
};


const MathTutor = () => {
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const { topicId, tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // Find Hebrew name from URL path
  const hebrewName = Object.entries(topicUrls).find(
    ([_, urlPath]) => urlPath === topicId
  )?.[0] || '';
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [activeSection, setActiveSection] = useState('getStarted');
  const [selectedGrade, setSelectedGrade] = useState(searchParams.get('grade') || 'all');
  const [selectedTags, setSelectedTags] = useState(() => {
    const tags = searchParams.get('tags');
    return tags ? new Set(tags.split(',').map(tag => tag.trim())) : new Set();
  });
  const content = learningContent[selectedTopic];
  const bg = useColorModeValue(
    'linear(to-r, blue.50, pink.50)',
    'linear(to-r, blue.900, pink.900)'
  );
  // 'linear-gradient(135deg, var(--chakra-colors-blue-50) 0%, var(--chakra-colors-pink-50) 50%)',
  // 'linear-gradient(135deg, var(--chakra-colors-blue-900) 0%, var(--chakra-colors-pink-900) 50%)'
  //  );
  // const bg = useColorModeValue('gray.50', 'gray.900');
  const { progress } = useAuth();
  const { isOpen: isAboutOpen, onOpen: onAboutOpen, onClose: onAboutClose } = useDisclosure();

  useEffect(() => {
    // Get URL parameters
    const gradeParam = searchParams.get('grade');
    const tagsParam = searchParams.get('tags');
    
    // Update grade if present in URL
    if (gradeParam) {
      setSelectedGrade(gradeParam);
    } else {
      const savedGrade = localStorage.getItem('selectedGrade');
      if (savedGrade) {
        setSelectedGrade(savedGrade);
      }
    }

    // Update tags if present in URL
    if (tagsParam) {
      setSelectedTags(new Set(tagsParam.split(',').map(tag => tag.trim())));
    } else {
      const savedTags = localStorage.getItem('selectedTags');
      if (savedTags) {
        setSelectedTags(new Set(JSON.parse(savedTags)));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (topicId && hebrewName) {
      setSelectedTopic(hebrewName);
    } else {
      setSelectedTopic('');
    }
  }, [location, topicId, hebrewName]);

  useEffect(() => {
    if (tab) {
      setActiveSection(tabToSection[tab] || 'getStarted');
    } else if (location.pathname.includes('/math/')) {
      const currentTab = location.pathname.split('/').pop();
      setActiveSection(tabToSection[currentTab] || 'getStarted');
    }
  }, [tab, location.pathname]);

  useEffect(() => {
    if (selectedTopic && activeSection) {
      const lastTabs = JSON.parse(localStorage.getItem('lastTopicTabs') || '{}');
      lastTabs[selectedTopic] = activeSection;
      localStorage.setItem('lastTopicTabs', JSON.stringify(lastTabs));
    }
  }, [selectedTopic, activeSection]);

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedTags.size > 0) {
      params.set('tags', Array.from(selectedTags).join(','));
    }
    if (selectedGrade !== 'all') {
      params.set('grade', selectedGrade);
    }
    if (selectedTags.size > 0 || selectedGrade !== 'all') {
      navigate({ search: params.toString() }, { replace: true });
    } else {
      navigate({ search: '' }, { replace: true });
    }
  }, [selectedTags, selectedGrade, navigate]);

  const grades = [...new Set(Object.values(learningContent)
    .flatMap(content => content.tags
      .filter(tag => tag.startsWith('כיתה'))))]
    .sort();

  const topicTags = [...new Set(Object.values(learningContent)
    .filter(content => selectedGrade === 'all' || content.tags.includes(selectedGrade))
    .flatMap(content => content.tags
      .filter(tag => !tag.startsWith('כיתה'))))]
    .sort();

  const filteredTopics = Object.keys(learningContent).filter(topic => {
    const topicTags = learningContent[topic].tags;
    const matchesGrade = selectedGrade === 'all' ||
      topicTags.some(tag => tag === selectedGrade);
    const matchesTags = selectedTags.size === 0 ||
      topicTags.some(tag => selectedTags.has(tag));
    return matchesGrade && matchesTags;
  });

  const calculateTopicProgress = (topic) => {
    if (!progress.topics?.[topic]) return { mastery: 0, successRate: 0 };
    return {
      mastery: progress.topics[topic].mastery,
      successRate: progress.topics[topic].successRate
    };
  };

  const toggleTag = (tag) => {
    const newTags = new Set(selectedTags);
    if (newTags.has(tag)) {
      newTags.delete(tag);
    } else {
      newTags.add(tag);
    }
    setSelectedTags(newTags);
    localStorage.setItem('selectedTags', JSON.stringify([...newTags]));
  };

  const handleGradeSelect = (grade) => {
    setSelectedGrade(grade);
    // Don't clear tags when selecting grade
    localStorage.setItem('selectedGrade', grade);
  };

  // Update URL when section changes
  const handleSectionChange = (section) => {
    setActiveSection(section);
    navigate(`/math/${topicId}/${tabToSection[section]}`);
  };

  // Update handleBack to restore previous search params if they exist
  const handleBack = () => {
    setSelectedTopic('');
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate('/');
    }
  };

  const handleTopicSelect = (topic) => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }
    setSelectedTopic(topic);
    const lastTabs = JSON.parse(localStorage.getItem('lastTopicTabs') || '{}');
    const lastTab = lastTabs[topic];
  
    // Navigate without search params
    navigate(`/math/${topicUrls[topic]}/${lastTab ? tabToSection[lastTab] : 'explanation'}`, {
      replace: true,
      state: { from: location.pathname + location.search }
    });
  };

  const gradeTagStyle = {
    size: { base: "md", md: "lg" },
    colorScheme: "blue",
    cursor: "pointer",
    borderWidth: "2px",
    fontWeight: "bold",
    px: { base: 2, md: 4 },
    _hover: {
      transform: 'scale(1.05)',
    },
    transition: "transform 0.2s"
  };

  // Add this function inside MathTutor component
  const renderSectionButtons = () => (
    <HStack spacing={4} overflowX="auto" p={2}>
      {[
        { id: 'getStarted', label: 'הסברים' },
        { id: 'examples', label: 'דוגמאות' },
        { id: 'quiz', label: 'תרגול' }
      ].map(({ id, label }) => (
        <Button
          key={id}
          onClick={() => handleSectionChange(id)}
          colorScheme={activeSection === id ? 'blue' : 'gray'}
          size="lg"
          flexShrink={0}
        >
          {label}
        </Button>
      ))}
    </HStack>
  );
  const canonicalUrl = topicId
    ? `https://thinkmathnow.com/math/${topicId}`
    : 'https://thinkmathnow.com';

  const getMetaTags = () => {
    // Handle filtered views
    if (!topicId && (searchParams.get('grade') || searchParams.get('tags'))) {
      const grade = searchParams.get('grade');
      const tags = searchParams.get('tags')?.split(',') || [];
      
      // Create descriptive title based on filters
      let title = '';
      let description = '';
      
      if (grade && tags.length > 0) {
        title = `${tags.join(', ')} ל${grade} | לחשוב מתמטיקה`;
        description = `תרגול ${tags.join(', ')} ל${grade} - תרגול אינטראקטיבי, הסברים ודוגמאות. מותאם לתוכנית הלימודים של משרד החינוך.`;
      } else if (grade) {
        title = `תרגול מתמטיקה ל${grade} | לחשוב מתמטיקה`;
        description = `תרגול מתמטיקה ל${grade} - תרגול אינטראקטיבי, הסברים ודוגמאות. מותאם לתוכנית הלימודים של משרד החינוך.`;
      } else if (tags.length > 0) {
        title = `${tags.join(', ')} | לחשוב מתמטיקה`;
        description = `תרגול ${tags.join(', ')} - תרגול אינטראקטיבי, הסברים ודוגמאות. מותאם לתוכנית הלימודים של משרד החינוך.`;
      }

      const keywords = [
        ...tags,
        grade,
        'לימוד מתמטיקה',
        'תרגול חשבון',
        'מתמטיקה לבית ספר',
        'תרגילי חשבון'
      ].filter(Boolean).join(', ');

      return {
        title,
        description,
        keywords,
        ogTitle: title,
        ogDescription: description
      };
    }

    // Handle homepage (no filters, no topic)
    if (!topicId) {
      return {
        title: 'Think Math Now - לחשוב מתמטיקה | לימוד מתמטיקה אונליין',
        description: 'פלטפורמה ללימוד מתמטיקה עם תרגול אינטראקטיבי, הסברים מפורטים ודוגמאות. מותאם לתוכנית הלימודים של משרד החינוך. תרגול חשבון, לימוד מתמטיקה, מתמטיקה לבית ספר.',
        keywords: 'מתמטיקה, לימוד מתמטיקה, תרגול מתמטיקה, בגרות במתמטיקה, תרגול חשבון, מתמטיקה לבית ספר, תרגילי חשבון',
        ogTitle: 'Think Math Now - לחשוב מתמטיקה | לימוד מתמטיקה אונליין',
        ogDescription: 'פלטפורמה ללימוד מתמטיקה עם תרגול אינטראקטיבי והסברים מפורטים. מותאם לתוכנית הלימודים.'
      };
    }

    // Handle specific topic pages (existing code)
    const content = learningContent[hebrewName];
    const tags = content?.tags || [];
    const grade = tags.find(tag => tag.includes('כיתה')) || '';
    
    const topicKeywords = {
      'כפל עד 10': ['לוח הכפל', 'תרגילי כפל', 'כפל לילדים', 'תרגול כפל'],
      'שברים פשוטים': ['שברים פשוטים', 'תרגילי שברים', 'לימוד שברים'],
      // Add more topic-specific keywords as needed
    }[hebrewName] || [];

    const allKeywords = [
      hebrewName,
      ...tags,
      ...topicKeywords,
      'לימוד מתמטיקה',
      'תרגול חשבון',
      'מתמטיקה לבית ספר'
    ].join(', ');

    return {
      title: `${hebrewName} | לימוד ותרגול ${grade ? `ל${grade}` : ''} - Think Math Now`,
      description: `תרגול אינטראקטיבי בנושא ${hebrewName} - הסברים, דוגמאות ותרגול. ${grade ? `מותאם לתוכנית הלימודים של משרד החינוך ל${grade}` : 'מותאם לתוכנית הלימודים של משרד החינוך'}. כולל תרגול אינטראקטיבי והסברים מפורטים.`,
      keywords: allKeywords,
      ogTitle: `${hebrewName} | לימוד ותרגול ${grade ? `ל${grade}` : ''} - Think Math Now`,
      ogDescription: `תרגול אינטראקטיבי בנושא ${hebrewName}. ${grade ? `מותאם ל${grade}` : ''} - הסברים, דוגמאות ותרגול.`
    };
  };

  const meta = getMetaTags();
  return (
    <Box bgGradient={bg} minH="100vh" py={8}>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:description" content={meta.ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
        {tab && <link rel="alternate" href={canonicalUrl} />}
        <html lang="he" dir="rtl" />
      </Helmet>
      <AppHeader />
      <Container maxW="6xl" position="relative">
        <VStack spacing={2} pt={0}>

          {selectedTopic ? (
            <>
              <VStack w="full" spacing={2}>
                <Box w="full">
                  <Text
                    fontSize="3xl"
                    fontWeight="semibold"
                    bgGradient="linear(to-r, blue.300, blue.500)"
                    bgClip="text"
                    textAlign="center"
                    mb={2}
                  >
                    {selectedTopic}
                  </Text>
                  <Box w="full" display="flex" justifyContent="flex-start" dir="rtl">
                    <Button
                      size="md"
                      variant="outline"
                      colorScheme="blue"
                      borderWidth="2px"
                      leftIcon={<Icon as={FiArrowRight} />}
                      onClick={handleBack}
                      _hover={{
                        bg: 'blue.50',
                        transform: 'translateX(-4px)',
                      }}
                      transition="all 0.2s"
                    >
                      חזרה
                    </Button>
                  </Box>
                </Box>
              </VStack>
              <VStack spacing={4} align="stretch" w="full">
                {renderSectionButtons()}

                {activeSection === 'getStarted' && (
                  <ExplanationCard 
                    content={content.getStarted} 
                    relatedLinks={content.relatedLinks}
                  />
                )}
                {activeSection === 'examples' && (
                  <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6}>
                    {content.examples.map((example, index) => (
                      <ExampleCard key={index} example={example} index={index} />
                    ))}
                  </SimpleGrid>
                )}
                {activeSection === 'quiz' && (
                  <QuizCard quiz={content.quiz} selectedTopic={selectedTopic} setSelectedTopic={setSelectedTopic} />
                )}
              </VStack>
            </>
          ) : (
            <>
              <Box className="flex flex-wrap gap-2 p-4 w-full">
                <Box w="full" mb={3}>

                  <Wrap spacing={2} justify="center">
                    <WrapItem>
                      <ChakraTag
                        {...gradeTagStyle}
                        variant={selectedGrade === 'all' ? "solid" : "outline"}
                        bg={selectedGrade === 'all' ? "blue.400" : "blue.50"}
                        color={selectedGrade === 'all' ? "white" : "black"}
                        onClick={() => handleGradeSelect('all')}
                      >
                        <TagLabel>כל הכיתות</TagLabel>
                      </ChakraTag>
                    </WrapItem>
                    {grades.map(grade => (
                      <WrapItem key={grade}>
                        <ChakraTag
                          {...gradeTagStyle}
                          variant={selectedGrade === grade ? "solid" : "outline"}
                          bg={selectedGrade === grade ? "blue.400" : "blue.50"}
                          color={selectedGrade === grade ? "white" : "black"}
                          onClick={() => handleGradeSelect(grade)}
                        >
                          <TagLabel>{grade}</TagLabel>
                        </ChakraTag>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>

                {topicTags.map(tag => (
                  <ChakraTag
                    key={tag}
                    size={{ base: "md", md: "lg" }}
                    borderRadius="full"
                    variant={selectedTags.has(tag) ? "solid" : "outline"}
                    colorScheme="blue"
                    bg={selectedTags.has(tag) ? "blue.500" : "white"}
                    cursor="pointer"
                    onClick={() => toggleTag(tag)}
                    _hover={{
                      transform: 'scale(1.05)',
                    }}
                    transition="transform 0.2s"
                  >
                    <TagLeftIcon as={FiTag} />
                    <TagLabel>{tag}</TagLabel>
                  </ChakraTag>
                ))}
              </Box>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8} w="full">
                {filteredTopics.map(topic => (
                  <TopicCard
                    key={topic}
                    title={topic}
                    tags={learningContent[topic].tags}
                    onSelect={handleTopicSelect}
                    progress={calculateTopicProgress(topic)}
                  />
                ))}
              </SimpleGrid>
            </>
          )}
        </VStack>
        {!selectedTopic && (
          <Box as="footer" mt={8} textAlign="center">
            <HStack spacing={4} justify="center">
              <Text
                color="blue.500"
                cursor="pointer"
                _hover={{ textDecoration: 'underline' }}
                onClick={onAboutOpen}
              >
                אודות
              </Text>
              <Text>|</Text>
              <Link
                href="https://thinkmathnow.com/privacy.html"
                color="blue.500"
                _hover={{ textDecoration: 'underline' }}
                isExternal
              >
                הצהרת פרטיות
              </Link>
            </HStack>
          </Box>
        )}

        <AboutModal isOpen={isAboutOpen} onClose={onAboutClose} />
      </Container>
      <AuthWrapper
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </Box>
  );
};

export default MathTutor;