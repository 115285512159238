export const learningContent = {
    "שאלות השוואה ואיסוף": {
        tags: ['כיתה ב׳', 'חיבור וחיסור', 'בעיות מילוליות'],
        relatedLinks: [
            { title: "תרגול השוואות ואיסופים", url: "/?tags=יחסים ,בעיות מילוליות", description: "תרגול בעיות השוואה ואיסוף" },
            { title: "בעיות מילוליות בהשוואה", url: "/?tags=בעיות מילוליות", description: "תרגול בעיות השוואה מילוליות" }
        ],
        getStarted: [
            "**מהן שאלות השוואה?**",
            "שאלות השוואה עוסקות במציאת ההפרש בין שני דברים",
            "למשל: כמה יותר תלמידים יש בכיתה ב׳ מאשר בכיתה א׳?",
            "",
            "**מהן שאלות איסוף?**", 
            "שאלות איסוף עוסקות בחיבור של כמה דברים יחד",
            "למשל: כמה תלמידים יש בשתי הכיתות ביחד?",
            "",
            "**דרך הפתרון:**",
            "בשאלות השוואה - מחסרים את המספר הקטן מהגדול",
            "בשאלות איסוף - מחברים את כל המספרים יחד",
            "",
            "**טיפים:**",
            "שימו לב למילים המנחות בשאלה",
            "השוואה: יותר, פחות, ההפרש",
            "איסוף: ביחד, סך הכל, בסך הכל"
        ],
        examples: [
            {
                question: "לליה 8 בובות ולאלה 5 בובות. כמה בובות יותר יש לליה?",
                solution: "זוהי שאלת השוואה. נחסר: $8 - 5 = 3$, לליה יש 3 בובות יותר"
            },
            {
                question: "דור קנה 3 מחברות, 2 עפרונות ו-5 צבעים. כמה פריטים קנה דור בסך הכל?",
                solution: "זוהי שאלת איסוף. נחבר: $3 + 2 + 5 = 10$, דור קנה 10 פריטים"
            }
        ],
        quiz: [
            {
                type: "multiChoiceRandomMath",
                question: "לאיתי יש {A} קלפים. לנעמה יש {B} קלפים. כמה קלפים יותר יש לאיתי מנעמה?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-three"],
                    custom: [
                        "{B}-{A}",           // Common mistake: subtracting in wrong order
                        "{A}+{B}",           // Common mistake: adding instead of subtracting
                    ]
                },
                hint: "כדי למצוא כמה יותר יש לאיתי, צריך לחסר את מספר הקלפים של נעמה ממספר הקלפים של איתי",
                solutionGuide: "$ {A} - {B} = {Result} $ קלפים יותר"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בכיתה א׳ {A} תלמידים ובכיתה ב׳ {B} תלמידים. כמה תלמידים יש בשתי הכיתות ביחד?",
                params: {
                    A: {
                        range: [15, 30],
                        type: "integer"
                    },
                    B: {
                        range: [15, 30],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "plus-three"],
                    custom: [
                        "{A}-{B}    ",           // Common mistake: subtracting instead of adding
                        "{B}-{A}",           // Common mistake: subtracting in wrong order
                        "({A}+{B})-5"        // Off by 5
                    ]
                },
                hint: "כדי למצוא כמה תלמידים יש ביחד, צריך לחבר את מספר התלמידים בשתי הכיתות",
                solutionGuide: "$ {A} + {B} = {Result} $ תלמידים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בחנות היו {A} סוכריות. נמכרו {B} סוכריות. כמה סוכריות נשארו?",
                params: {
                    A: {
                        range: [30, 80],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}-{A}",           // Common mistake: subtracting in wrong order
                        "{A}+{B}",           // Common mistake: adding instead of subtracting
                    ]
                },
                hint: "כדי למצוא כמה סוכריות נשארו, צריך לחסר את מספר הסוכריות שנמכרו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ סוכריות"
            },
            {
                type: "multiChoiceRandomMath",
                question: "באגרטל הראשון {A} פרחים ובאגרטל השני {B} פרחים. כמה פרחים יש בשני האגרטלים ביחד?",
                params: {
                    A: {
                        range: [10, 40],
                        type: "integer"
                    },
                    B: {
                        range: [10, 40],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}-{B}",           // Common mistake: subtracting instead of adding
                        "{B}-{A}",           // Common mistake: subtracting in wrong order
                    ]
                },
                hint: "כדי למצוא כמה פרחים יש ביחד, צריך לחבר את מספר הפרחים בשני האגרטלים",
                solutionGuide: "$ {A} + {B} = {Result} $ פרחים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "איתי קנה {A} גולות וליה קנתה {B} גולות. כמה גולות יותר קנתה ליה מאיתי?",
                params: {
                    A: {
                        range: [10, 40],
                        type: "integer"
                    },
                    B: {
                        range: ["A+1", 50],
                        type: "integer"
                    }
                },
                formula: "B-A",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}-{B}",           // Common mistake: subtracting in wrong order
                        "{A}+{B}",           // Common mistake: adding instead of subtracting
                    ]
                },
                hint: "כדי למצוא כמה יותר גולות קנתה ליה, צריך לחסר את מספר הגולות של איתי ממספר הגולות של ליה",
                solutionGuide: "$ {B} - {A} = {Result} $ גולות יותר"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בארנק הראשון {A} שקלים ובארנק השני {B} שקלים. כמה כסף יש בשני הארנקים ביחד?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [20, 50],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}-{B}",           // Common mistake: subtracting instead of adding
                        "{B}-{A}",           // Common mistake: subtracting in wrong order
                    ]
                },
                hint: "כדי למצוא כמה כסף יש ביחד, צריך לחבר את הסכומים בשני הארנקים",
                solutionGuide: "$ {A} + {B} = {Result} $ שקלים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בכיתה א׳ {A} בנים ו-{B} בנות. כמה תלמידים יש בכיתה?",
                params: {
                    A: {
                        range: [10, 20],
                        type: "integer"
                    },
                    B: {
                        range: [10, 20],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "plus-three"],
                    custom: [
                        "{A}-{B}",           // Common mistake: subtracting instead of adding
                        "{B}-{A}",           // Common mistake: subtracting in wrong order
                    ]
                },
                hint: "כדי למצוא את מספר התלמידים בכיתה, צריך לחבר את מספר הבנים ומספר הבנות",
                solutionGuide: "$ {A} + {B} = {Result} $ תלמידים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "במגרש החנייה היו {A} מכוניות. עזבו {B} מכוניות. כמה מכוניות נשארו?",
                params: {
                    A: {
                        range: [30, 80],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-three"],
                    custom: [
                        "{B}-{A}",           // Common mistake: subtracting in wrong order
                        "{A}+{B}",           // Common mistake: adding instead of subtracting
                    ]
                },
                hint: "כדי למצוא כמה מכוניות נשארו, צריך לחסר את מספר המכוניות שעזבו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ מכוניות"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הדרקון של רון אכל {A} עוגיות והדרקון של דנה אכל {B} עוגיות. כמה עוגיות פחות אכל הדרקון של דנה?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}-{A}",
                        "{A}+{B}",
                    ]
                },
                hint: "כדי למצוא כמה פחות עוגיות אכל הדרקון של דנה, צריך לחסר את מספר העוגיות שלו ממספר העוגיות של הדרקון של רון",
                solutionGuide: "$ {A} - {B} = {Result} $ עוגיות"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בלון קסמים אדום עף {A} מטרים באוויר ובלון קסמים כחול עף {B} מטרים. כמה יותר גבוה עף הבלון האדום?",
                params: {
                    A: {
                        range: [30, 100],
                        type: "integer"
                    },
                    B: {
                        range: [20, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-three"],
                    custom: [
                        "{B}-{A}",
                        "{A}+{B}",
                    ]
                },
                hint: "כדי למצוא כמה יותר גבוה עף הבלון האדום, צריך לחסר את גובה הבלון הכחול מגובה הבלון האדום",
                solutionGuide: "$ {A} - {B} = {Result} $ מטרים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הרובוט של יובל אסף {A} כוכבי ים והרובוט של מאיה אסף {B} כוכבי ים. כמה יותר כוכבי ים אסף הרובוט של יובל?",
                params: {
                    A: {
                        range: [15, 40],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}-{A}",
                        "{A}+{B}",
                    ]
                },
                hint: "כדי למצוא כמה יותר כוכבי ים אסף הרובוט של יובל, צריך לחסר את מספר כוכבי הים של הרובוט של מאיה ממספר כוכבי הים של הרובוט של יובל",
                solutionGuide: "$ {A} - {B} = {Result} $ כוכבי ים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הדינוזאור של שחר קפץ {A} פעמים והדינוזאור של נועה קפץ {B} פעמים. כמה פעמים יותר קפץ הדינוזאור של שחר?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-three"],
                    custom: [
                        "{B}-{A}",
                        "{A}+{B}",
                    ]
                },
                hint: "כדי למצוא כמה יותר פעמים קפץ הדינוזאור של שחר, צריך לחסר את מספר הקפיצות של הדינוזאור של נועה ממספר הקפיצות של הדינוזאור של שחר",
                solutionGuide: "$ {A} - {B} = {Result} $ פעמים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "החללית של גיל אספה {A} אבני חלל והחללית של טל אספה {B} אבני חלל. כמה אבני חלל יותר אספה החללית של גיל?",
                params: {
                    A: {
                        range: [25, 60],
                        type: "integer"
                    },
                    B: {
                        range: [15, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}-{A}",
                        "{A}+{B}",
                    ]
                },
                hint: "כדי למצוא כמה יותר אבני חלל אספה החללית של גיל, צריך לחסר את מספר האבנים של החללית של טל ממספר האבנים של החללית של גיל",
                solutionGuide: "$ {A} - {B} = {Result} $ אבני חלל"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הטירנוזאורוס אכל {A} עלים והברונטוזאורוס אכל {B} עלים. כמה עלים יותר אכל הטירנוזאורוס?",
                params: {
                    A: {
                        range: [30, 70],
                        type: "integer"
                    },
                    B: {
                        range: [20, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: ["{B}-{A}", "{A}+{B}"]
                },
                hint: "כדי למצוא כמה יותר עלים אכל הטירנוזאורוס, צריך לחסר את מספר העלים של הברונטוזאורוס ממספר העלים של הטירנוזאורוס",
                solutionGuide: "$ {A} - {B} = {Result} $ עלים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הוולוצירפטור רץ {A} קילומטרים והסטגוזאורוס רץ {B} קילומטרים. כמה קילומטרים פחות רץ הסטגוזאורוס?",
                params: {
                    A: {
                        range: [25, 55],
                        type: "integer"
                    },
                    B: {
                        range: [15, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-three"],
                    custom: ["{B}-{A}", "{A}+{B}"]
                },
                hint: "כדי למצוא כמה פחות קילומטרים רץ הסטגוזאורוס, צריך לחסר את מספר הקילומטרים שלו ממספר הקילומטרים של הוולוצירפטור",
                solutionGuide: "$ {A} - {B} = {Result} $ קילומטרים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "אמא קנתה {A} תפוחים ואבא קנה {B} תפוחים. כמה תפוחים יותר קנתה אמא?",
                params: {
                    A: {
                        range: [20, 45],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: ["{B}-{A}", "{A}+{B}"]
                },
                hint: "כדי למצוא כמה יותר תפוחים קנתה אמא, צריך לחסר את מספר התפוחים של אבא ממספר התפוחים של אמא",
                solutionGuide: "$ {A} - {B} = {Result} $ תפוחים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "סבא אסף {A} תותים וסבתא אספה {B} תותים. כמה תותים יותר אסף סבא?",
                params: {
                    A: {
                        range: [25, 50],
                        type: "integer"
                    },
                    B: {
                        range: [15, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: ["{B}-{A}", "{A}+{B}"]
                },
                hint: "כדי למצוא כמה יותר תותים אסף סבא, צריך לחסר את מספר התותים של סבתא ממספר התותים של סבא",
                solutionGuide: "$ {A} - {B} = {Result} $ תותים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בגינה של דני גדלו {A} גזרים ובגינה של אחותו גדלו {B} גזרים. כמה גזרים יותר גדלו אצל דני?",
                params: {
                    A: {
                        range: [30, 60],
                        type: "integer"
                    },
                    B: {
                        range: [20, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-three"],
                    custom: ["{B}-{A}", "{A}+{B}"]
                },
                hint: "כדי למצוא כמה יותר גזרים גדלו אצל דני, צריך לחסר את מספר הגזרים של אחותו ממספר הגזרים שלו",
                solutionGuide: "$ {A} - {B} = {Result} $ גזרים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "אח שלי קטף {A} ענבים ואני קטפתי {B} ענבים. כמה ענבים יותר קטף אח שלי?",
                params: {
                    A: {
                        range: [35, 65],
                        type: "integer"
                    },
                    B: {
                        range: [25, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: ["{B}-{A}", "{A}+{B}"]
                },
                hint: "כדי למצוא כמה יותר ענבים קטף אח שלי, צריך לחסר את מספר הענבים שלי ממספר הענבים שלו",
                solutionGuide: "$ {A} - {B} = {Result} $ ענבים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בן הדוד שלי אכל {A} מלפפונים ובת הדודה שלי אכלה {B} מלפפונים. כמה מלפפונים יותר אכל בן הדוד שלי?",
                params: {
                    A: {
                        range: [15, 35],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A-1"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: ["{B}-{A}", "{A}+{B}"]
                },
                hint: "כדי למצוא כמה יותר מלפפונים אכל בן הדוד שלי, צריך לחסר את מספר המלפפונים של בת הדודה ממספר המלפפונים של בן הדוד",
                solutionGuide: "$ {A} - {B} = {Result} $ מלפפונים"
            }
        ]
    }
};