import { learningContent as fractions } from './fractions-and-decimals';
import { learningContent as wordProblems } from './wordProblemsSpeedTime';
import { learningContent as decimals } from './decimal-numbers';
import { learningContent as inequalities } from './variable-inequalities';
import { learningContent as combinatorics } from './combinatorics';
import { learningContent as multiplication } from './multiplication-to-10';
import { learningContent as elementaryWordProblems } from './addition-subtraction-multiplication-word-problems';
import { learningContent as powers } from './exponent-rules';
import { learningContent as directednumbers} from './directed-numbers';
import { learningContent as wordProblemsFractions} from './fraction-word-problems-advanced';
import { learningContent as conversions} from './fraction-decimal-percent-conversions';
import { learningContent as additionSubtraction100 } from './addition-subtraction-to-100';
import { learningContent as verticalAddition } from './vertical-addition';
import { learningContent as verticalSubtraction } from './vertical-subtraction';
import { learningContent as divisibilityRules } from './divisibility-rules-2-5-10';
import { learningContent as simpleVerticalAddition } from './vertical-addition-no-carrying';
import { learningContent as verticalAddition100 } from './vertical-addition-with-carrying';
import { learningContent as basicFractions } from './adding-fractions-same-denominator';
import { learningContent as fractionWordProblems } from './basic-fraction-word-problems';
import { learningContent as divisibilityRules_3_6_9 } from './divisibility-rules-3-6-9';
import { learningContent as fractionsIntroduction } from './fractions-introduction';  
import { learningContent as algebraAndExpressions } from './algebra-and-expressions';
import { learningContent as relations } from './relations';
import { learningContent as areaAndPerimeter } from './area-and-perimeter';
import { learningContent as setTheory } from './set-theory';
import { learningContent as orderOfOperations } from './order-of-operations';
import { learningContent as divisionTo10 } from './division-to-10';
import { learningContent as parenthesesOrderOperations } from './parentheses-order-operations';
import { learningContent as comparisonCollectionProblems } from './comparison-collection-problems';
import { learningContent as simpleEquations } from './simple-equations';
import { learningContent as inverseOperations } from './inverse-operations';
import { learningContent as commutativeAssociativeDistributive } from './commutative-associative-distributive';
import { learningContent as wordProblemsComparison } from './word-problems-comparison';
//import { learningContent as questionTypes } from './questionTypes';


const topicMappings = {
  'multiplication-to-10': multiplication,
  'fractions-and-decimals': fractions,
  'word-problems-speed-time': wordProblems,
  'decimal-numbers': decimals,
  'variable-inequalities': inequalities,
  'combinatorics': combinatorics,
  'addition-subtraction-multiplication-word-problems': elementaryWordProblems,
  'exponent-rules': powers,
  'directed-numbers': directednumbers,
  'fraction-word-problems-advanced': wordProblemsFractions,
  'fraction-decimal-percent-conversions': conversions,
  'addition-subtraction-to-100': additionSubtraction100,
  'vertical-addition': verticalAddition,
  'vertical-subtraction': verticalSubtraction,
  'divisibility-rules-2-5-10': divisibilityRules,
  'vertical-addition-no-carrying': simpleVerticalAddition,
  'vertical-addition-with-carrying': verticalAddition100,
  'adding-fractions-same-denominator': basicFractions,
  'basic-fraction-word-problems': fractionWordProblems,
  'divisibility-rules-3-6-9': divisibilityRules_3_6_9,
  'fractions-introduction': fractionsIntroduction,
  'algebra-and-expressions': algebraAndExpressions,
  'relations': relations,
  'area-and-perimeter': areaAndPerimeter,
  'set-theory': setTheory,
  'order-of-operations': orderOfOperations,
  'division-to-10': divisionTo10,
  'parentheses-order-operations': parenthesesOrderOperations,
  'comparison-collection-problems': comparisonCollectionProblems,
  'simple-equations': simpleEquations,
  'inverse-operations': inverseOperations,
  'commutative-associative-distributive': commutativeAssociativeDistributive,
  'word-problems-comparison': wordProblemsComparison,
};


export const learningContent = {
  ...fractions,
  ...wordProblems,
  ...decimals,
  ...inequalities,
  ...combinatorics,
  ...multiplication,
  ...elementaryWordProblems,
  ...powers,
  ...directednumbers, 
  ...fractionsIntroduction,
  ...wordProblemsFractions,
  ...conversions,
  ...additionSubtraction100,
  ...verticalSubtraction,
  ...verticalAddition,
  ...simpleVerticalAddition,
  ...divisibilityRules,
  ...verticalAddition100, 
  ...basicFractions,
  ...fractionWordProblems,
  ...divisibilityRules_3_6_9,
  ...algebraAndExpressions,
  ...relations,
  ...areaAndPerimeter,
  ...setTheory,
  ...orderOfOperations,
  ...divisionTo10,
  ...parenthesesOrderOperations,
  ...comparisonCollectionProblems,
  ...simpleEquations,
  ...inverseOperations,
  ...commutativeAssociativeDistributive,
  ...wordProblemsComparison,
 // ...questionTypes
};



// Generate URL mappings from content
export const topicUrls = {};
Object.entries(topicMappings).forEach(([urlPath, content]) => {
  const [hebrewName] = Object.keys(content);
  topicUrls[hebrewName] = urlPath;
});

export const generateSitemap = () => `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url><loc>https://thinkmathnow.com/</loc></url>
  ${Object.values(topicUrls).map(urlPath => 
    `<url><loc>https://thinkmathnow.com/topic/${urlPath}</loc></url>`
  ).join('\n  ')}
</urlset>`;