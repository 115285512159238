export const learningContent = {
    "חיבור במאונך ללא המרה": {
        tags: ['כיתה ב׳', 'חיבור וחיסור'],
        relatedLinks: [
            { title: "תרגול חיבור במאונך ללא המרה", url: "/?tags=חיבור וחיסור", description: "תרגול בעיות חיבור במאונך ללא המרה" }
        ],
        getStarted: [
            "**איך מחברים מספרים?**",
            "",
            "רושמים את המספרים זה מתחת לזה",
            "מיישרים לפי האחדות (ימין)",
            "מתחילים לחבר מימין לשמאל",
            "",
            "**שלבי החיבור:**",
            "1. מסדרים את המספרים זה תחת זה",
            "2. מתחילים מאחדות (הטור הימני)",
            "3. ממשיכים לטור הבא משמאל",
            "",
            "**טיפים:**",
            "תמיד לרשום מספרים ברורים",
            "לבדוק את התוצאה בסוף",
            {
                drawing: {
                    type: "vertical",
                    firstNumber: 23,
                    secondNumber: 45,
                    operation: "addition",
                    steps: [
                        "מחברים אחדות: $3 + 5 = 8$",
                        "מחברים עשרות: $2 + 4 = 6$"
                    ]
                }
            }
        ],
        examples: [
            {
                question: "חשבו במאונך:$\\qquad$ 34 + 25",
                solution: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 34 \\\\ + 25 \\\\ \\hline 59 \\end{array}$<br/><br/>אחדות: 4 + 5 = 9<br/>עשרות: 3 + 2 = 5",
                drawing: {
                    type: "vertical",
                    firstNumber: 34,
                    secondNumber: 25,
                    operation: "addition",
                    steps: [
                        "מחברים אחדות: $4 + 5 = 9$",
                        "מחברים עשרות: $3 + 2 = 5$"
                    ]
                }
            }
        ],
        quiz: [
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 23 \\\\ + 45 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["68", "58", "78", "48"],
                hint: "סדרו את המספרים זה תחת זה, והתחילו לחבר מימין (אחדות)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 23 \\\\ + 45 \\\\ \\hline 68 \\end{array}$<br/><br/>אחדות: $3 + 5 = 8$<br/>עשרות: $2 + 4 = 6$<br/><br/>התוצאה הסופית היא 68"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 31 \\\\ + 47 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["78", "68", "88", "58"],
                hint: "חברו את האחדות (1 + 7) ואז את העשרות (3 + 4)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 31 \\\\ + 47 \\\\ \\hline 78 \\end{array}$<br/><br/>אחדות: $1 + 7 = 8$<br/>עשרות: $3 + 4 = 7$<br/><br/>התוצאה הסופית היא 78"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 24 \\\\ + 25 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["49", "63", "43", "73"],
                hint: "יישרו את המספרים לפי האחדות וחברו כל טור בנפרד",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 24 \\\\ + 25 \\\\ \\hline 49 \\end{array}$<br/><br/>אחדות: $4 + 5 = 9$<br/>עשרות: $2 + 2 = 4$<br/><br/>התוצאה הסופית היא 49"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 25 \\\\ + 20 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["45", "35", "55", "40"],
                hint: "שימו לב שהמספר השני מסתיים באפס - זה אומר שבאחדות מחברים רק את הספרה של המספר הראשון",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 25 \\\\ + 20 \\\\ \\hline 45 \\end{array}$<br/><br/>אחדות: $5 + 0 = 5$<br/>עשרות: $2 + 2 = 4$<br/><br/>התוצאה הסופית היא 45"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 41 \\\\ + 38 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["79", "69", "89", "59"],
                hint: "סדרו את המספרים כך שהאחדות מיושרות זו מתחת לזו. חברו קודם את האחדות (1 + 8) ואז את העשרות (4 + 3)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 41 \\\\ + 38 \\\\ \\hline 79 \\end{array}$<br/><br/>אחדות: $1 + 8 = 9$<br/>עשרות: $4 + 3 = 7$<br/><br/>התוצאה הסופית היא 79"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 13 \\\\ + 6 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["19", "20", "18", "17"],
                hint: "כשמחברים מספר דו-ספרתי עם חד-ספרתי, המספר החד-ספרתי נכנס לטור האחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 13 \\\\ + 6 \\\\ \\hline 19 \\end{array}$<br/><br/>אחדות: $3 + 6 = 9$<br/>עשרות: $1 = 1$<br/><br/>התוצאה הסופית היא 19"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 43 \\\\ + 35 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["78", "88", "68", "98"],
                hint: "חשבו על כל טור בנפרד - טור האחדות (3 + 5) וטור העשרות (4 + 3)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 43 \\\\ + 35 \\\\ \\hline 78 \\end{array}$<br/><br/>אחדות: $3 + 5 = 8$<br/>עשרות: $4 + 3 = 7$<br/><br/>התוצאה הסופית היא 78"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 21 \\\\ + 57 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["78", "68", "88", "58"],
                hint: "רשמו את המספרים זה תחת זה כך שספרת האחדות של 57 תהיה מתחת לספרת האחדות של 21",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 21 \\\\ + 57 \\\\ \\hline 78 \\end{array}$<br/><br/>אחדות: $1 + 7 = 8$<br/>עשרות: $2 + 5 = 7$<br/><br/>התוצאה הסופית היא 78"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 14 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["29", "31", "33", "30"],
                hint: "שימו לב שבשני המספרים יש 1 בספרת העשרות - זה יעזור לכם לחבר את טור העשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 14 \\\\ + 15 \\\\ \\hline 29 \\end{array}$<br/><br/>אחדות: $4 + 5 = 9$<br/>עשרות: $1 + 1 = 2$<br/><br/>התוצאה הסופית היא 29"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 32 \\\\ + 46 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["78", "88", "68", "98"],
                hint: "התחילו מטור האחדות (2 + 6) ואז עברו לטור העשרות (3 + 4)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 32 \\\\ + 46 \\\\ \\hline 78 \\end{array}$<br/><br/>אחדות: $2 + 6 = 8$<br/>עשרות: $3 + 4 = 7$<br/><br/>התוצאה הסופית היא 78"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 12 \\\\ + 24 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["36", "46", "26", "56"],
                hint: "שימו לב שבשני המספרים יש 2 בספרת האחדות של אחד המספרים ו-4 בשני",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 12 \\\\ + 24 \\\\ \\hline 36 \\end{array}$<br/><br/>אחדות: $2 + 4 = 6$<br/>עשרות: $1 + 2 = 3$<br/><br/>התוצאה הסופית היא 36"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 11 \\\\ + 21 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["32", "42", "22", "52"],
                hint: "שימו לב שבשני המספרים יש 1 בספרת האחדות - זה מקל על החיבור",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 11 \\\\ + 21 \\\\ \\hline 32 \\end{array}$<br/><br/>אחדות: $1 + 1 = 2$<br/>עשרות: $1 + 2 = 3$<br/><br/>התוצאה הסופית היא 32"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 21 \\\\ + 4 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["25", "35", "15", "45"],
                hint: "כשמחברים מספר חד-ספרתי, רושמים אותו בטור האחדות (מימין)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 21 \\\\ + 4 \\\\ \\hline 25 \\end{array}$<br/><br/>21 + 4 = 25<br/><br/>התוצאה הסופית היא 25"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 33 \\\\ + 7 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["40", "50", "30", "60"],
                hint: "חברו את 7 לספרת האחדות של 33. שימו לב שהתוצאה מסתיימת באפס",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 33 \\\\ + 7 \\\\ \\hline 40 \\end{array}$<br/><br/>33 + 7 = 40<br/><br/>התוצאה הסופית היא 40"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 42 \\\\ + 5 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["47", "57", "37", "67"],
                hint: "המספר 5 נכנס לטור האחדות. ספרת העשרות של 42 נשארת ללא שינוי",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 42 \\\\ + 5 \\\\ \\hline 47 \\end{array}$<br/><br/>42 + 5 = 47<br/><br/>התוצאה הסופית היא 47"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 51 \\\\ + 8 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["59", "69", "49", "79"],
                hint: "חברו את 8 לספרת האחדות של 51. ספרת העשרות נשארת אותו דבר",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 51 \\\\ + 8 \\\\ \\hline 59 \\end{array}$<br/><br/>51 + 8 = 59<br/><br/>התוצאה הסופית היא 59"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 64 \\\\ + 3 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["67", "77", "57", "87"],
                hint: "חברו את 3 לספרת האחדות של 64. העשרות לא משתנות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 64 \\\\ + 3 \\\\ \\hline 67 \\end{array}$<br/><br/>64 + 3 = 67<br/><br/>התוצאה הסופית היא 67"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 72 \\\\ + 6 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["78", "88", "68", "98"],
                hint: "המספר 6 מתחבר לספרת האחדות (2). ספרת העשרות (7) נשארת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 72 \\\\ + 6 \\\\ \\hline 78 \\end{array}$<br/><br/>72 + 6 = 78<br/><br/>התוצאה הסופית היא 78"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 83 \\\\ + 6 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["89", "84", "74", "64"],
                hint: "חברו 6 לספרת האחדות של 83. העשרות נשארות 8",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 83 \\\\ + 6 \\\\ \\hline 89 \\end{array}$<br/><br/>83 + 6 = 89<br/><br/>התוצאה הסופית היא 89"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 91 \\\\ + 2 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["93", "83", "73", "63"],
                hint: "חברו 2 לספרת האחדות של 91. העשרות נשארות 9",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 91 \\\\ + 2 \\\\ \\hline 93 \\end{array}$<br/><br/>91 + 2 = 93<br/><br/>התוצאה הסופית היא 93"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 32 \\\\ + 22 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["54", "64", "44", "74"],
                hint: "שימו לב שבשני המספרים יש 2 בספרת האחדות. זה מקל על החיבור",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 32 \\\\ + 22 \\\\ \\hline 54 \\end{array}$<br/><br/>אחדות: $2 + 2 = 4$<br/>עשרות: $3 + 2 = 5$<br/><br/>התוצאה הסופית היא 54"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 21 \\\\ + 12 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["33", "43", "23", "53"],
                hint: "שימו לב שהמספרים מורכבים מאותן ספרות, רק בסדר הפוך",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 21 \\\\ + 12 \\\\ \\hline 33 \\end{array}$<br/><br/>אחדות: $1 + 2 = 3$<br/>עשרות: $2 + 1 = 3$<br/><br/>התוצאה הסופית היא 33"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 31 \\\\ + 11 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["42", "52", "32", "62"],
                hint: "המספר השני הוא 11 - זה אומר שמוסיפים 1 גם לאחדות וגם לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 31 \\\\ + 11 \\\\ \\hline 42 \\end{array}$<br/><br/>אחדות: $1 + 1 = 2$<br/>עשרות: $3 + 1 = 4$<br/><br/>התוצאה הסופית היא 42"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 11 \\\\ + 21 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["32", "42", "22", "52"],
                hint: "שימו לב שבשני המספרים יש 1 בספרת האחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 11 \\\\ + 21 \\\\ \\hline 32 \\end{array}$<br/><br/>אחדות: $1 + 1 = 2$<br/>עשרות: $1 + 2 = 3$<br/><br/>התוצאה הסופית היא 32"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 12 \\\\ + 24 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["36", "46", "26", "56"],
                hint: "סדרו את המספרים כך שהאחדות (2 ו-4) מיושרות זו מתחת לזו",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 12 \\\\ + 24 \\\\ \\hline 36 \\end{array}$<br/><br/>אחדות: $2 + 4 = 6$<br/>עשרות: $1 + 2 = 3$<br/><br/>התוצאה הסופית היא 36"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 51 \\\\ + 14 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["65", "75", "55", "85"],
                hint: "חברו את האחדות (1 + 4) ואז את העשרות (5 + 1)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 51 \\\\ + 14 \\\\ \\hline 65 \\end{array}$<br/><br/>אחדות: $1 + 4 = 5$<br/>עשרות: $5 + 1 = 6$<br/><br/>התוצאה הסופית היא 65"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 23 \\\\ + 34 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["57", "67", "47", "77"],
                hint: "יישרו את המספרים לפי האחדות וחברו כל טור בנפרד",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 23 \\\\ + 34 \\\\ \\hline 57 \\end{array}$<br/><br/>אחדות: $3 + 4 = 7$<br/>עשרות: $2 + 3 = 5$<br/><br/>התוצאה הסופית היא 57"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 42 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["57", "67", "47", "77"],
                hint: "התחילו מטור האחדות (2 + 5) ואז עברו לטור העשרות (4 + 1)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 42 \\\\ + 15 \\\\ \\hline 57 \\end{array}$<br/><br/>אחדות: $2 + 5 = 7$<br/>עשרות: $4 + 1 = 5$<br/><br/>התוצאה הסופית היא 57"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 2 \\\\ + 4 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["6", "7", "5", "8"],
                hint: "אלו מספרים חד-ספרתיים - אפשר לחבר אותם ישירות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 2 \\\\ + 4 \\\\ \\hline 6 \\end{array}$<br/><br/>2 + 4 = 6<br/><br/>התוצאה הסופית היא 6"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 61 \\\\ + 14 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["75", "85", "65", "95"],
                hint: "סדרו את המספרים כך שהאחדות (1 ו-4) מיושרות זו מתחת לזו",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 61 \\\\ + 14 \\\\ \\hline 75 \\end{array}$<br/><br/>אחדות: $1 + 4 = 5$<br/>עשרות: $6 + 1 = 7$<br/><br/>התוצאה הסופית היא 75"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 33 \\\\ + 44 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["77", "87", "67", "97"],
                hint: "שימו לב שבשני המספרים יש אותה ספרה בעשרות ובאחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 33 \\\\ + 44 \\\\ \\hline 77 \\end{array}$<br/><br/>אחדות: $3 + 4 = 7$<br/>עשרות: $3 + 4 = 7$<br/><br/>התוצאה הסופית היא 77"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 22 \\\\ + 13 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["35", "45", "25", "55"],
                hint: "יישרו את המספרים לפי האחדות (2 ו-3) וחברו כל טור בנפרד",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 22 \\\\ + 13 \\\\ \\hline 35 \\end{array}$<br/><br/>אחדות: $2 + 3 = 5$<br/>עשרות: $2 + 1 = 3$<br/><br/>התוצאה הסופית היא 35"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 1 \\\\ + 3 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["4", "5", "3", "6"],
                hint: "אלו מספרים קטנים וחד-ספרתיים - אפשר לחבר אותם ישירות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 1 \\\\ + 3 \\\\ \\hline 4 \\end{array}$<br/><br/>1 + 3 = 4<br/><br/>התוצאה הסופית היא 4"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 41 \\\\ + 24 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["65", "75", "55", "85"],
                hint: "חברו קודם את האחדות (1 + 4) ואז את העשרות (4 + 2)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 41 \\\\ + 24 \\\\ \\hline 65 \\end{array}$<br/><br/>אחדות: $1 + 4 = 5$<br/>עשרות: $4 + 2 = 6$<br/><br/>התוצאה הסופית היא 65"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 31 \\\\ + 23 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["54", "64", "44", "74"],
                hint: "סדרו את המספרים זה תחת זה ויישרו לפי האחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 31 \\\\ + 23 \\\\ \\hline 54 \\end{array}$<br/><br/>אחדות: $1 + 3 = 4$<br/>עשרות: $3 + 2 = 5$<br/><br/>התוצאה הסופית היא 54"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 21 \\\\ + 33 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["54", "64", "44", "74"],
                hint: "שימו לב שהמספר השני גדול יותר - זה בסדר, העיקר ליישר לפי האחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 21 \\\\ + 33 \\\\ \\hline 54 \\end{array}$<br/><br/>אחדות: $1 + 3 = 4$<br/>עשרות: $2 + 3 = 5$<br/><br/>התוצאה הסופית היא 54"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 11 \\\\ + 43 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["54", "64", "44", "74"],
                hint: "כשיש 1 בעשרות, זה כמו להוסיף 10 למספר השני",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 11 \\\\ + 43 \\\\ \\hline 54 \\end{array}$<br/><br/>אחדות: $1 + 3 = 4$<br/>עשרות: $1 + 4 = 5$<br/><br/>התוצאה הסופית היא 54"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 42 \\\\ + 12 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["54", "64", "44", "74"],
                hint: "שימו לב שיש אותה ספרה באחדות בשני המספרים - זה יכול להקל על החישוב",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 42 \\\\ + 12 \\\\ \\hline 54 \\end{array}$<br/><br/>אחדות: $2 + 2 = 4$<br/>עשרות: $4 + 1 = 5$<br/><br/>התוצאה הסופית היא 54"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 2 \\\\ + 1 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["3", "4", "2", "5"],
                hint: "במספרים קטנים כל כך, אפשר לדמיין את הכמות בראש - כמה זה שתי יחידות ועוד אחת?",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר:<br/><br/>$\\begin{array}{r} \\\\ 2 \\\\ + 1 \\\\ \\hline 3 \\end{array}$<br/><br/>2 + 1 = 3<br/><br/>התוצאה הסופית היא 3"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 41 \\\\ + 13 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["54", "64", "44", "74"],
                hint: "יישרו את המספרים לפי האחדות (1 ו-3) וחברו כל טור בנפרד",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 41 \\\\ + 13 \\\\ \\hline 54 \\end{array}$<br/><br/>אחדות: $1 + 3 = 4$<br/>עשרות: $4 + 1 = 5$<br/><br/>התוצאה הסופית היא 54"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 21 \\\\ + 34 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["55", "45", "65", "35"],
                hint: "סדרו את המספרים זה תחת זה, והתחילו לחבר מימין (אחדות)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 21 \\\\ + 34 \\\\ \\hline 55 \\end{array}$<br/><br/>אחדות: $1 + 4 = 5$<br/>עשרות: $2 + 3 = 5$<br/><br/>התוצאה הסופית היא 55"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 45 \\\\ + 23 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["68", "78", "58", "88"],
                hint: "חברו את האחדות (5 + 3) ואז את העשרות (4 + 2)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 45 \\\\ + 23 \\\\ \\hline 68 \\end{array}$<br/><br/>אחדות: $5 + 3 = 8$<br/>עשרות: $4 + 2 = 6$<br/><br/>התוצאה הסופית היא 68"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 34 \\\\ + 23 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["57", "67", "47", "77"],
                hint: "יישרו את המספרים לפי האחדות וחברו כל טור בנפרד",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 34 \\\\ + 23 \\\\ \\hline 57 \\end{array}$<br/><br/>אחדות: $4 + 3 = 7$<br/>עשרות: $3 + 2 = 5$<br/><br/>התוצאה הסופית היא 57"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 25 \\\\ + 21 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["46", "56", "36", "66"],
                hint: "שימו לב שבשני המספרים יש 2 בספרת העשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 25 \\\\ + 21 \\\\ \\hline 46 \\end{array}$<br/><br/>אחדות: $5 + 1 = 6$<br/>עשרות: $2 + 2 = 4$<br/><br/>התוצאה הסופית היא 46"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 12 \\\\ + 23 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["35", "45", "25", "55"],
                hint: "סדרו את המספרים כך שהאחדות מיושרות זו מתחת לזו",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 12 \\\\ + 23 \\\\ \\hline 35 \\end{array}$<br/><br/>אחדות: $2 + 3 = 5$<br/>עשרות: $1 + 2 = 3$<br/><br/>התוצאה הסופית היא 35"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 53 \\\\ + 34 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["87", "77", "97", "67"],
                hint: "חברו את האחדות (3 + 4) ואז את העשרות (5 + 3)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 53 \\\\ + 34 \\\\ \\hline 87 \\end{array}$<br/><br/>אחדות: $3 + 4 = 7$<br/>עשרות: $5 + 3 = 8$<br/><br/>התוצאה הסופית היא 87"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 61 \\\\ + 32 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["93", "83", "103", "73"],
                hint: "יישרו את המספרים לפי האחדות וחברו כל טור בנפרד",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 61 \\\\ + 32 \\\\ \\hline 93 \\end{array}$<br/><br/>אחדות: $1 + 2 = 3$<br/>עשרות: $6 + 3 = 9$<br/><br/>התוצאה הסופית היא 93"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 42 \\\\ + 11 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["53", "63", "43", "73"],
                hint: "חברו את האחדות (2 + 1) ואז את העשרות (4 + 1)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 42 \\\\ + 11 \\\\ \\hline 53 \\end{array}$<br/><br/>אחדות: $2 + 1 = 3$<br/>עשרות: $4 + 1 = 5$<br/><br/>התוצאה הסופית היא 53"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 31 \\\\ + 10 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["41", "51", "31", "61"],
                hint: "שימו לב שבמספר השני יש 0 באחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 31 \\\\ + 10 \\\\ \\hline 41 \\end{array}$<br/><br/>אחדות: $1 + 0 = 1$<br/>עשרות: $3 + 1 = 4$<br/><br/>התוצאה הסופית היא 41"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 20 \\\\ + 10 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["30", "40", "20", "50"],
                hint: "שימו לב שבשני המספרים יש 0 באחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 20 \\\\ + 10 \\\\ \\hline 30 \\end{array}$<br/><br/>אחדות: $0 + 0 = 0$<br/>עשרות: $2 + 1 = 3$<br/><br/>התוצאה הסופית היא 30"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 21 \\\\ + 13 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["34", "44", "24", "54"],
                hint: "יישרו את המספרים לפי האחדות וחברו כל טור בנפרד",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 21 \\\\ + 13 \\\\ \\hline 34 \\end{array}$<br/><br/>אחדות: $1 + 3 = 4$<br/>עשרות: $2 + 1 = 3$<br/><br/>התוצאה הסופית היא 34"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 12 \\\\ + 21 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["33", "43", "23", "53"],
                hint: "שימו לב שהמספרים מורכבים מאותן ספרות בסדר הפוך",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 12 \\\\ + 21 \\\\ \\hline 33 \\end{array}$<br/><br/>אחדות: $2 + 1 = 3$<br/>עשרות: $1 + 2 = 3$<br/><br/>התוצאה הסופית היא 33"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 11 \\\\ + 22 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["33", "43", "23", "53"],
                hint: "חברו את האחדות (1 + 2) ואז את העשרות (1 + 2)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 11 \\\\ + 22 \\\\ \\hline 33 \\end{array}$<br/><br/>אחדות: $1 + 2 = 3$<br/>עשרות: $1 + 2 = 3$<br/><br/>התוצאה הסופית היא 33"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 31 \\\\ + 12 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["43", "53", "33", "63"],
                hint: "סדרו את המספרים כך שהאחדות מיושרות זו מתחת לזו",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 31 \\\\ + 12 \\\\ \\hline 43 \\end{array}$<br/><br/>אחדות: $1 + 2 = 3$<br/>עשרות: $3 + 1 = 4$<br/><br/>התוצאה הסופית היא 43"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 21 \\\\ + 22 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["43", "53", "33", "63"],
                hint: "שימו לב שבשני המספרים יש 2 בספרת העשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 21 \\\\ + 22 \\\\ \\hline 43 \\end{array}$<br/><br/>אחדות: $1 + 2 = 3$<br/>עשרות: $2 + 2 = 4$<br/><br/>התוצאה הסופית היא 43"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 13 \\\\ + 21 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["34", "44", "24", "54"],
                hint: "חברו את האחדות (3 + 1) ואז את העשרות (1 + 2)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 13 \\\\ + 21 \\\\ \\hline 34 \\end{array}$<br/><br/>אחדות: $3 + 1 = 4$<br/>עשרות: $1 + 2 = 3$<br/><br/>התוצאה הסופית היא 34"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 25 \\\\ + 22 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["47", "57", "37", "67"],
                hint: "יישרו את המספרים לפי האחדות וחברו כל טור בנפרד",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 25 \\\\ + 22 \\\\ \\hline 47 \\end{array}$<br/><br/>אחדות: $5 + 2 = 7$<br/>עשרות: $2 + 2 = 4$<br/><br/>התוצאה הסופית היא 47"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 12 \\\\ + 12 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["24", "34", "14", "44"],
                hint: "שימו לב שאתם מחברים את אותו מספר פעמיים",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 12 \\\\ + 12 \\\\ \\hline 24 \\end{array}$<br/><br/>אחדות: $2 + 2 = 4$<br/>עשרות: $1 + 1 = 2$<br/><br/>התוצאה הסופית היא 24"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 11 \\\\ + 11 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["22", "32", "12", "42"],
                hint: "שימו לב שאתם מחברים את אותו מספר פעמיים",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 11 \\\\ + 11 \\\\ \\hline 22 \\end{array}$<br/><br/>אחדות: $1 + 1 = 2$<br/>עשרות: $1 + 1 = 2$<br/><br/>התוצאה הסופית היא 22"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 21 \\\\ + 11 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["32", "42", "22", "52"],
                hint: "חברו את האחדות (1 + 1) ואז את העשרות (2 + 1)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 21 \\\\ + 11 \\\\ \\hline 32 \\end{array}$<br/><br/>אחדות: $1 + 1 = 2$<br/>עשרות: $2 + 1 = 3$<br/><br/>התוצאה הסופית היא 32"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 46 \\\\ + 42 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["88", "98", "78", "68"],
                hint: "חברו את האחדות (6 + 2) ואז את העשרות (4 + 4)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 46 \\\\ + 42 \\\\ \\hline 88 \\end{array}$<br/><br/>אחדות: $6 + 2 = 8$<br/>עשרות: $4 + 4 = 8$<br/><br/>התוצאה הסופית היא 88"
            }


        ]
    }
}; 