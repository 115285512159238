import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    VStack,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';
import packageJson from '../../package.json';

export const AboutModal = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>אודות</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack align="start" spacing={4}>
                        <Text>
                            לחשוב מתמטיקה היא פלטפורמה חינוכית שנועדה לעזור לתלמידים ללמוד ולתרגל מתמטיקה וחשבון בדרך חווייתית ואינטראקטיבית.
                        </Text>
                        <Text>
                            האתר מותאם לתוכנית הלימודים של משרד החינוך ומיועד לתלמידי בית ספר יסודי. התכנים מאורגנים לפי נושאים וכיתות, כך שכל תלמיד יכול למצוא את החומר המתאים לו.
                        </Text>
                        <Text>
                            הפלטפורמה מציעה:
                        </Text>
                        <UnorderedList spacing={2} paddingStart={4}>
                            <ListItem>תרגול אינטראקטיבי</ListItem>
                            <ListItem>הסברים מפורטים</ListItem>
                            <ListItem>דוגמאות מעשיות</ListItem>
                            <ListItem>מעקב אחר התקדמות</ListItem>
                            <ListItem>התאמה אישית לקצב הלמידה</ListItem>
                            <ListItem>משוב מיידי על התשובות</ListItem>
                        </UnorderedList>
                        <Text mt={4} fontSize="sm" color="gray.600">
                            האתר נמצא בגירסת הרצה. נשמח לקבל משוב והצעות לשיפור:
                        </Text>
                        <Text
                            as="a"
                            href="mailto:appxlnes@gmail.com"
                            fontSize="sm"
                            color="blue.500"
                            cursor="pointer"
                            textDecoration="underline"
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = "mailto:appxlnes@gmail.com";
                            }}
                            _hover={{
                                color: 'blue.600',
                            }}
                        >
                            appxlnes@gmail.com
                        </Text>
                        <Text fontSize="sm" color="gray.600">
                            גרסה: {packageJson.version}
                        </Text>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}; 