import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { 
  getAuth, 
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut
} from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, getDocs, collection, writeBatch } from 'firebase/firestore';
import { updateLastLogin } from './firebase';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState({});
  const auth = getAuth();
  const db = getFirestore();

  const loadUserData = useCallback(async (uid) => {
    console.log('loadUserData called for uid:', uid);
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      const topicsSnapshot = await getDocs(collection(db, `users/${uid}/progress`));
      console.log('User doc exists:', userDoc.exists());
  
      let userData;
      if (!userDoc.exists()) {
        userData = {
          uid: uid,
          displayName: auth.currentUser.displayName,
          email: auth.currentUser.email,
        };
      } else {
        userData = userDoc.data();
      }
  
      setUser(currentUser => ({
        ...currentUser,
        ...userData
      }));
  
      // Convert topics snapshot to object
      const topics = {};
      topicsSnapshot.forEach(doc => {
        topics[doc.id] = doc.data();
      });
      setProgress({ topics });
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  }, [auth, db]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user?.uid);
      if (user) {
        setUser(user);
        // Use optimized lastLogin update
        await updateLastLogin(user.uid);
        await loadUserData(user.uid);
      } else {
        setUser(null);
        loadDefaultProgress();
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth, loadUserData, db]);

  const loadDefaultProgress = () => {
    const defaultProgress = {
      topics: {}
    };
    setProgress(defaultProgress);
  };

  const updateProgress = async (topicId, data) => {
    const newData = {
      ...data,
      lastUpdated: new Date()
    };

    // Update local state with only the new data
    setProgress(prev => ({
      topics: {
        ...prev.topics,
        [topicId]: {
          ...(prev.topics?.[topicId] || {}),
          ...newData
        }
      }
    }));

    if (user) {
      try {
        await setDoc(doc(db, `users/${user.uid}/progress/${topicId}`), newData, { merge: true });
      } catch (error) {
        console.error('Error updating progress:', error);
      }
    } else {
      localStorage.setItem('progress', JSON.stringify({ 
        topics: { 
          [topicId]: newData 
        } 
      }));
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      const result = await signInWithPopup(auth, provider);
      
      const localProgress = JSON.parse(localStorage.getItem('progress') || '{}');
      if (Object.keys(localProgress.topics || {}).length > 0) {
        // Write each topic separately
        const batch = writeBatch(db);
        Object.entries(localProgress.topics).forEach(([topicId, data]) => {
          const ref = doc(db, `users/${result.user.uid}/progress/${topicId}`);
          batch.set(ref, data);
        });
        await batch.commit();
      }
      
      return result;
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        return null;
      }
      console.error('Error signing in with Google:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('progress');
      setProgress({});
      window.location.href = '/';
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  const updateUserData = async (updates) => {
    try {
      console.log('Updating user data:', updates);
      if (!user?.uid) {
        console.error('No user found');
        return;
      }

      // Update Firestore
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, updates, { merge: true });

      // Update local state
      setUser(currentUser => ({
        ...currentUser,
        ...updates
      }));
    } catch (error) {
      console.error('Error updating user data:', error);
      throw error; // Propagate error to handle it in useCurrency
    }
  };

  const value = {
    user,
    loading,
    progress,
    updateProgress,
    signInWithGoogle,
    logout,
    updateUserData
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};