import React, { useState } from 'react';
import { Box, Input, Button } from '@chakra-ui/react';

export const FillInQuestion = ({ onAnswer, showFeedback }) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Box w="full">
      <Input
        placeholder="הקלד את תשובתך כאן"
        size="lg"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !showFeedback) {
            onAnswer(inputValue);
          }
        }}
        mb={4}
      />
      <Button
        colorScheme="blue"
        onClick={() => !showFeedback && onAnswer(inputValue)}
        isFullWidth
      >
        בדוק תשובה
      </Button>
    </Box>
  );
}; 