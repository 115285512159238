import { COIN_REWARDS } from '../config/coinRewards';

const calculateSpeedBonus = (timeElapsed) => {
  const { SPEED_BONUS } = COIN_REWARDS;

  if (timeElapsed <= SPEED_BONUS.SUPER_FAST.threshold) return SPEED_BONUS.SUPER_FAST.coins;
  if (timeElapsed <= SPEED_BONUS.FAST.threshold) return SPEED_BONUS.FAST.coins;
  if (timeElapsed <= SPEED_BONUS.NORMAL.threshold) return SPEED_BONUS.NORMAL.coins;
  return 0;
};

export const calculateCoins = ({
  isCorrect,
  streak = 0,
  questionStartTime,
  isQuizComplete = false,
  correctAnswers = 0,
  totalQuestions = 0
}) => {
  if (!isCorrect) return 0;

  let totalCoins = COIN_REWARDS.CORRECT_ANSWER.BASE;

  // Add streak bonus
  const streakBonus = Math.floor(streak * COIN_REWARDS.CORRECT_ANSWER.STREAK_MULTIPLIER);
  totalCoins += streakBonus;

  // Add speed bonus
  const timeElapsed = (Date.now() - questionStartTime) / 1000;
  totalCoins += calculateSpeedBonus(timeElapsed);

  // Add completion bonus
  if (isQuizComplete) {
    const percentage = (correctAnswers / totalQuestions) * 100;
    if (percentage === 100) totalCoins += COIN_REWARDS.COMPLETION_BONUS.PERFECT_SCORE;
    else if (percentage >= 80) totalCoins += COIN_REWARDS.COMPLETION_BONUS.ABOVE_80_PERCENT;
    else if (percentage >= 60) totalCoins += COIN_REWARDS.COMPLETION_BONUS.ABOVE_60_PERCENT;
  }

  return totalCoins;
}; 