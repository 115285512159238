import React from 'react';
import { 
  Box, 
  Container, 
  Heading, 
  HStack, 
  Image,
  Button,
  Text,
  Icon,
  Flex,
  Link as ChakraLink
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { UserProfile } from './UserProfile';
import { useDisclosure } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { CharacterStore } from './CharacterStore';
import { FaStore, FaCoins } from 'react-icons/fa';
import { useCurrency } from '../hooks/useCurrency';
import { Link as RouterLink } from 'react-router-dom';
const MotionBox = motion(Box);

const AppHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { coins } = useCurrency();

  return (
    <Box as="header" w="full" minH="100px">
      <Container maxW="6xl">
        <Flex direction="column" py={2}>
          {/* Coins */}
          <Flex justify="flex-start" mb={2}>
            <Button
              onClick={onOpen}
              bg="transparent"
              color="black"
              border="2.5px dashed"
              borderColor="yellow.400"
              boxShadow="0 4px 12px rgba(255, 198, 0, 0.3)"
              borderRadius="2xl"
              _hover={{ 
                transform: 'scale(1.05) translateY(-2px)',
                borderColor: 'yellow.500',
                boxShadow: '0 6px 20px rgba(255, 198, 0, 0.4)',
              }}
              rightIcon={<FaStore size="1.2em" />}
              leftIcon={<Icon as={FaCoins} color="yellow.400" fontSize="1.2em" />}
              px={3}
              py={2}
              size="sm"
              transition="all 0.2s ease-in-out"
            >
              <Text fontWeight="bold" fontSize="md">{coins}</Text>
            </Button>
          </Flex>

          {/* Main row */}
          <HStack spacing={4} justify="space-between" align="center">
            <Box minW="48px">
              <UserProfile />
            </Box>
            
            <Heading
              size={{ base: "lg", md: "2xl", lg: "3xl" }}
              bgGradient="linear(to-r, blue.400, purple.400)"
              bgClip="text"
              fontWeight="bold"
              letterSpacing="normal"
              textAlign="center"
              whiteSpace="nowrap"
            >
              לחשוב מתמטיקה
            </Heading>

            <Box minW="48px">
              <ChakraLink as={RouterLink} to="/">
                <MotionBox
                  whileHover={{ scale: 1.10 }}
                  whileTap={{ scale: 0.95 }}
                  style={{ transformOrigin: 'center' }}
                >
                  <Image
                    src="/logo512.png"
                    alt="Think Math Now Logo"
                    w={{ base: 12, md: 15, lg: 20 }}
                    h={{ base: 12, md: 15, lg: 20 }}
                    borderRadius="full"
                    bg="white"
                    p={0}
                    boxShadow="lg"
                  />
                </MotionBox>
              </ChakraLink>
            </Box>
          </HStack>
        </Flex>

        {/* Modal */}
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent bgGradient="linear(to-r, blue.50, pink.50)">
            <ModalCloseButton />
            <ModalBody p={6} mt={2}>
              <CharacterStore />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </Box>
  );
};

export default AppHeader;