export const learningContent = {
    "סימני התחלקות ב-3,6,9": {
        tags: ['כיתה ב׳', 'כיתה ג׳', 'חילוק'],
        relatedLinks: [
            {
                title: "תרגול חילוק",
                url: "/?tags=חילוק",
                description: "תרגול נוסף בחילוק"
            },
            {
                title: "בעיות מילוליות בחילוק",
                url: "/?tags=חילוק,בעיות%20מילוליות",
                description: "תרגול בעיות מילוליות עם חילוק"
            },
            {
                title: "כפל וחילוק",
                url: "/?tags=כפל,חילוק",
                description: "תרגול משולב של כפל וחילוק"
            }
        ],
        getStarted: [
            "**איך לדעת אם מספר מתחלק ב-3, 6 או 9?**",
            "",
            "• <b>התחלקות ב-3</b>:",
            "מספר מתחלק ב-3 אם סכום כל הספרות שלו מתחלק ב-3",
            "לדוגמה: 123 → 1+2+3=6, ולכן 123 מתחלק ב-3",
            "",
            "• <b>התחלקות ב-6</b>:",
            "מספר מתחלק ב-6 אם הוא מתחלק גם ב-2 וגם ב-3",
            "לדוגמה: 126 הוא זוגי וסכום ספרותיו (1+2+6=9) מתחלק ב-3",
            "",
            "• <b>התחלקות ב-9</b>:",
            "מספר מתחלק ב-9 אם סכום כל הספרות שלו מתחלק ב-9",
            "לדוגמה: 729 → 7+2+9=18 → 1+8=9, ולכן 729 מתחלק ב-9",
            "",
            "**טריקים מועילים:**",
            "אם סכום הספרות גדול מ-9, אפשר לחבר שוב את הספרות של הסכום",
            "אם מספר מתחלק ב-9, הוא מתחלק גם ב-3",
            "כדי להתחלק ב-6, המספר חייב להיות זוגי"
        ],
        examples: [
            {
                question: "האם המספר 234 מתחלק ב-3?",
                solution: "נחבר את כל הספרות: 2+3+4=9<br/>9 מתחלק ב-3<br/>לכן 234 מתחלק ב-3",
                drawing: {
                    type: "number-sum",
                    number: "234",
                    caption: "2+3+4=9, והמספר 9 מתחלק ב-3"
                }
            },
            {
                question: "האם המספר 426 מתחלק ב-6?",
                solution: "נבדוק שני דברים:<br/>1. המספר זוגי? כן, כי מסתיים ב-6<br/>2. סכום הספרות מתחלק ב-3? 4+2+6=12, 1+2=3<br/>המספר מתחלק גם ב-2 וגם ב-3, לכן מתחלק ב-6",
                drawing: {
                    type: "number-sum",
                    number: "426",
                    caption: "זוגי וסכום ספרותיו מתחלק ב-3"
                }
            },
            {
                question: "האם המספר 549 מתחלק ב-9?",
                solution: "נחבר את הספרות: 5+4+9=18<br/>נחבר שוב: 1+8=9<br/>התוצאה 9 מתחלקת ב-9, לכן 549 מתחלק ב-9",
                drawing: {
                    type: "number-sum",
                    number: "549",
                    caption: "5+4+9=18, 1+8=9, המספר מתחלק ב-9"
                }
            }
        ],
        quiz: [
            // Divisible by 3
            {
                type: "multiChoiceRandomBoolean",
                question: "הרובוט המצחיק אסף {A} ברגים. האם הוא יכול לסדר אותם בשלשות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,3)",
                distractors: [
                    "רק אם הברגים מתגלגלים מצחוק",
                    "תלוי אם הרובוט טען את הסוללות"
                ],
                hint: "חברו את כל הספרות וראו אם הסכום מתחלק ב-3",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))"
                },
                solutiontemplate: {
                    true: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, מתחלק ב-3",
                    false: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, לא מתחלק ב-3"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "האם הקוסם יכול להפוך את המספר {A} לשלוש קבוצות שוות של קלפים?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,3)",
                distractors: [
                    "רק אם יש לו את שרביט הקסם",
                    "תלוי אם הארנב במגבעת"
                ],
                hint: "חברו את כל הספרות וראו אם הסכום מתחלק ב-3",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))"
                },
                solutiontemplate: {
                    true: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, מתחלק ב-3",
                    false: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, לא מתחלק ב-3"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "יש {A} בלונים בחנות. האם אפשר לחלק אותם ל-3 ילדים באופן שווה?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,3)",
                distractors: [
                    "רק אם הבלונים בצבע כחול",
                    "תלוי אם יש מסיבה"
                ],
                hint: "חברו את כל הספרות וראו אם הסכום מתחלק ב-3",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))"
                },
                solutiontemplate: {
                    true: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, מתחלק ב-3",
                    false: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, לא מתחלק ב-3"
                }
            },

            // Divisible by 6
            {
                type: "multiChoiceRandomBoolean",
                question: "הדינוזאור הקטן אסף {A} עלים. האם הוא יכול לסדר אותם בשישיות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,6)",
                distractors: [
                    "רק אם הוא מנגב את האף",
                    "תלוי אם הוא רעב"
                ],
                hint: "בדקו אם המספר זוגי ואם סכום ספרותיו מתחלק ב-3",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))"
                },
                solutiontemplate: {
                    true: "{A}: זוגי וגם {hundredsA}+{tensA}+{onesA}={sumA} מתחלק ב-3",
                    false: "{A}: או לא זוגי או {hundredsA}+{tensA}+{onesA}={sumA} לא מתחלק ב-3"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: " אפשר לסדר {A} כוכבים בקבוצות של שישה?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,6)",
                distractors: [
                    "רק בלילה כשחשוך",
                    "תלוי אם יש ירח מלא"
                ],
                hint: "בדקו אם המספר זוגי ואם סכום ספרותיו מתחלק ב-3",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))"
                },
                solutiontemplate: {
                    true: "{A}: זוגי וגם {hundredsA}+{tensA}+{onesA}={sumA} מתחלק ב-3",
                    false: "{A}: או לא זוגי או {hundredsA}+{tensA}+{onesA}={sumA} לא מתחלק ב-3"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "יש {A} סוכריות בצנצנת.  אפשר לחלק אותן לשקיות של 6?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,6)",
                distractors: [
                    "רק אם הסוכריות מתוקות",
                    "תלוי אם יש לי שיניים"
                ],
                hint: "בדקו אם המספר זוגי ואם סכום ספרותיו מתחלק ב-3",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))"
                },
                solutiontemplate: {
                    true: "{A}: זוגי וגם {hundredsA}+{tensA}+{onesA}={sumA} מתחלק ב-3",
                    false: "{A}: או לא זוגי או {hundredsA}+{tensA}+{onesA}={sumA} לא מתחלק ב-3"
                }
            },

            // Divisible by 9
            {
                type: "multiChoiceRandomBoolean",
                question: "החייזר המצחיק אסף {A} כפתורים צבעוניים.  הוא יכול לסדר אותם בקבוצות של 9?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,9)",
                distractors: [
                    "רק אם הוא מנופף בזרועות",
                    "תלוי אם החללית חונה"
                ],
                hint: "חברו את הספרות עד שתקבלו מספר חד-ספרתי",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))",
                    finalSum: "mod((floor({A}/100) + floor(mod({A},100)/10) + mod({A},10)), 9) or 9"
                },
                solutiontemplate: {
                    true: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, נראה שסכום הספרות {finalSum} מתחלק ב-9",
                    false: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, נראה שסכום הספרות {finalSum} לא מתחלק ב-9"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "הפיל הקטן אסף {A} בננות. האם הוא יוכל לחלק אותן ל-9 ערימות שוות?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,9)",
                distractors: [
                    "רק אם הבננות בשלות",
                    "תלוי אם הוא מרים את החדק"
                ],
                hint: "חברו את הספרות עד שתקבלו מספר חד-ספרתי",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))",
                    finalSum: "mod((floor({A}/100) + floor(mod({A},100)/10) + mod({A},10)), 9) or 9"
                },
                solutiontemplate: {
                    true: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, נראה שסכום הספרות {finalSum} מתחלק ב-9",
                    false: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, נראה שסכום הספרות {finalSum} לא מתחלק ב-9"
                }
            },
            {
                type: "multiChoiceRandomBoolean",
                question: "יש {A} כדורים צבעוניים.  אפשר לסדר אותם ב-9 סלים שווים?",
                params: { A: { range: [100, 999] } },
                formula: "mod(A,9)",
                distractors: [
                    "רק אם הכדורים קופצים",
                    "תלוי אם יש קשת בענן"
                ],
                hint: "חברו את הספרות עד שתקבלו מספר חד-ספרתי",
                metadata: {
                    hundredsA: "floor({A}/100)",
                    tensA: "floor(mod({A},100)/10)",
                    onesA: "mod({A},10)",
                    sumA: "(floor({A}/100) + floor(mod({A},100)/10) + mod({A},10))",
                    finalSum: "mod((floor({A}/100) + floor(mod({A},100)/10) + mod({A},10)), 9) or 9"
                },
                solutiontemplate: {
                    true: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, נראה שסכום הספרות {finalSum} מתחלק ב-9",
                    false: "{A}: {hundredsA}+{tensA}+{onesA}={sumA}, נראה שסכום הספרות {finalSum} לא מתחלק ב-9"
                }
            },
            {
                type: "multiChoiceRandomMath",
                question: "איזה מהמספרים הבאים מתחלק ב-{A}?",
                params: {
                    A: { range: [3, 3] },
                    B: { range: [100, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "חברו את כל הספרות בכל מספר. אם הסכום מתחלק ב-{A}, המספר מתחלק ב-{A}",
                metadata: {
                    Result: "{B} * {A}",
                    hundredsResult: "floor(({B} * {A})/100)",
                    tensResult: "floor(mod({B} * {A}, 100)/10)",
                    onesResult: "mod({B} * {A}, 10)",
                    sumResult: "floor(({B} * {A})/100) + floor(mod({B} * {A}, 100)/10) + mod({B} * {A}, 10)"
                },
                solutionGuide: "רק {Result} מתחלק ב {A}. כיוון שסכום ספרותיו {hundredsResult}+{tensResult}+{onesResult}={sumResult} מתחלק ב {A}"
            },
            {
                type: "multiChoiceRandomMath",
                question: "איזה מהמספרים הבאים מתחלק ב-{A}?",
                params: {
                    A: { range: [6, 6] },
                    B: { range: [17, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                    strategies: ["off-by-eleven", "off-by-two", "off-by-three"]
                },
                hint: "המספר צריך להיות זוגי וסכום ספרותיו צריך להתחלק ב-3",
                metadata: {
                    Result: "{B} * {A}",
                    hundredsResult: "floor(({B} * {A})/100)",
                    tensResult: "floor(mod({B} * {A}, 100)/10)",
                    onesResult: "mod({B} * {A}, 10)",
                    sumResult: "floor(({B} * {A})/100) + floor(mod({B} * {A}, 100)/10) + mod({B} * {A}, 10)"
                },
                solutionGuide: "רק {Result} מתחלק ב-6. הוא זוגי וסכום ספרותיו {hundredsResult}+{tensResult}+{onesResult}={sumResult} מתחלק ב-3"
            },
            {
                type: "multiChoiceRandomMath",
                question: "איזה מהמספרים הבאים מתחלק ב-{A}?",
                params: {
                    A: { range: [9, 9] },
                    B: { range: [12, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                    strategies: ["off-by-three", "off-by-two", "off-by-eleven"]
                },
                hint: "חברו את כל הספרות וראו אם הסכום מתחלק ב-9",
                metadata: {
                    Result: "{B} * {A}",
                    hundredsResult: "floor(({B} * {A})/100)",
                    tensResult: "floor(mod({B} * {A}, 100)/10)",
                    onesResult: "mod({B} * {A}, 10)",
                    sumResult: "floor(({B} * {A})/100) + floor(mod({B} * {A}, 100)/10) + mod({B} * {A}, 10)"
                },
                solutionGuide: "רק {Result} מתחלק ב-9. כיוון שסכום ספרותיו {hundredsResult}+{tensResult}+{onesResult}={sumResult} מתחלק ב-9"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בחרו את המספר שניתן לחלוקה ב-{A}",
                params: {
                    A: { range: [3, 3] },
                    B: { range: [100, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "חברו את כל הספרות בכל מספר. אם הסכום מתחלק ב-{A}, המספר מתחלק ב-{A}",
                metadata: {
                    Result: "{B} * {A}",
                    hundredsResult: "floor(({B} * {A})/100)",
                    tensResult: "floor(mod({B} * {A}, 100)/10)",
                    onesResult: "mod({B} * {A}, 10)",
                    sumResult: "floor(({B} * {A})/100) + floor(mod({B} * {A}, 100)/10) + mod({B} * {A}, 10)"
                },
                solutionGuide: "רק {Result} מתחלק ב {A}. כיוון שסכום ספרותיו {hundredsResult}+{tensResult}+{onesResult}={sumResult} מתחלק ב {A}"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בחרו את המספר שניתן לחלוקה ב-{A}",
                params: {
                    A: { range: [6, 6] },
                    B: { range: [17, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                    strategies: ["off-by-eleven", "off-by-two", "off-by-three"]
                },
                hint: "המספר צריך להיות זוגי וסכום ספרותיו צריך להתחלק ב-3",
                metadata: {
                    Result: "{B} * {A}",
                    hundredsResult: "floor(({B} * {A})/100)",
                    tensResult: "floor(mod({B} * {A}, 100)/10)",
                    onesResult: "mod({B} * {A}, 10)",
                    sumResult: "floor(({B} * {A})/100) + floor(mod({B} * {A}, 100)/10) + mod({B} * {A}, 10)"
                },
                solutionGuide: "רק {Result} מתחלק ב-6. הוא זוגי וסכום ספרותיו {hundredsResult}+{tensResult}+{onesResult}={sumResult} מתחלק ב-3"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בחרו את המספר שניתן לחלוקה ב-{A}",
                params: {
                    A: { range: [9, 9] },
                    B: { range: [12, "999/A"] }
                },
                formula: "A * B",
                distractors: {
                    strategies: ["off-by-three", "off-by-two", "off-by-eleven"]
                },
                hint: "חברו את כל הספרות וראו אם הסכום מתחלק ב-9",
                metadata: {
                    Result: "{B} * {A}",
                    hundredsResult: "floor(({B} * {A})/100)",
                    tensResult: "floor(mod({B} * {A}, 100)/10)",
                    onesResult: "mod({B} * {A}, 10)",
                    sumResult: "floor(({B} * {A})/100) + floor(mod({B} * {A}, 100)/10) + mod({B} * {A}, 10)"
                },
                solutionGuide: "רק {Result} מתחלק ב-9. כיוון שסכום ספרותיו {hundredsResult}+{tensResult}+{onesResult}={sumResult} מתחלק ב-9"
            },
        ]
    }
}; 