// useQuizMascot.js
import { useState, useEffect } from 'react';

// Animation timing constants and behavior:
// - Default: Alternates between Walk(WALK_DURATION) and Idle(IDLE_DURATION)
// - Correct answer: Jump animation for JUMP_DURATION
// - Wrong answer: Dead animation for DEAD_DURATION
// - Taking too long(> TIME_WARNING_THRESHOLD): Run animation for RUN_DURATION
const TIMINGS = {
  WALK_DURATION: 5000,
  IDLE_DURATION: 3000,
  JUMP_DURATION: 5000,
  DEAD_DURATION: 7500,
  RUN_DURATION: 5000,
  TIME_WARNING_THRESHOLD: 50000
};

export const useQuizMascot = (questionStartTime, isCorrect) => {
  const [mascotState, setMascotState] = useState('Walk');

  // Idle/Walk alternation during normal state
  useEffect(() => {
    const switchToIdle = () => {
      if (mascotState === 'Walk') {
        setMascotState('Idle');
        setTimeout(switchToWalk, TIMINGS.IDLE_DURATION);
      }
    };

    const switchToWalk = () => {
      if (mascotState === 'Idle') {
        setMascotState('Walk');
        setTimeout(switchToIdle, TIMINGS.WALK_DURATION);
      }
    };

    // Start the cycle if in normal state
    if (['Walk', 'Idle'].includes(mascotState)) {
      const timer = setTimeout(
        mascotState === 'Walk' ? switchToIdle : switchToWalk,
        mascotState === 'Walk' ? TIMINGS.WALK_DURATION : TIMINGS.IDLE_DURATION
      );
      return () => clearTimeout(timer);
    }
  }, [mascotState]);

  // Handle answer results
  useEffect(() => {
    if (isCorrect === true) {
      setMascotState('Jump');
      setTimeout(() => setMascotState('Walk'), TIMINGS.JUMP_DURATION);
    } else if (isCorrect === false) {
      setMascotState('Dead');
      setTimeout(() => setMascotState('Walk'), TIMINGS.DEAD_DURATION);
    }
  }, [isCorrect]);

  // Handle time-based states
  useEffect(() => {
    const timer = setInterval(() => {
      const timeElapsed = Date.now() - questionStartTime;
      if (timeElapsed > TIMINGS.TIME_WARNING_THRESHOLD && ['Walk', 'Idle'].includes(mascotState)) {
        setMascotState('Run');
        setTimeout(() => setMascotState('Walk'), TIMINGS.RUN_DURATION);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [questionStartTime, mascotState]);

  return { mascotState };
};