export const learningContent = {
    "פתרון משוואות פשוטות": {
        tags: ['כיתה ב׳', 'אלגברה', 'חיבור וחיסור', 'כפל', 'חילוק'],

        relatedLinks: [
            {
                title: "תרגול אלגברה",
                url: "/?tags=אלגברה",
                description: "תרגול נוסף באלגברה"
            },
            {
                title: "בעיות מילוליות באלגברה",
                url: "/?tags=אלגברה,בעיות%20מילוליות",
                description: "תרגול בעיות מילוליות עם משוואות"
            },
            {
                title: "סדר פעולות חשבון",
                url: "/?tags=סדר%20פעולות",
                description: "תרגול סדר פעולות חשבון"
            }
        ],

        getStarted: [
            "**מהי משוואה?**",
            "משוואה היא שוויון בין שני ביטויים מתמטיים",
            "למשל: $\\text{__} + 5 = 12$",
            "",
            "**איך פותרים משוואה?**",
            "בכיתה ב׳ פותרים משוואות בדרך של ניסוי ובדיקה:",
            "1. מנחשים מספר שנראה מתאים",
            "2. מציבים את המספר במשוואה",
            "3. בודקים אם התוצאה נכונה",
            "4. אם לא - מנסים מספר אחר",
            "",
            "**דוגמאות למשוואות:**",
            "<b>משוואות חיבור:</b> $\\text{__} + 3 = 8$",
            "<b>משוואות חיסור:</b> $\\text{__} - 4 = 7$",
            "<b>משוואות כפל: </b> $\\text{__} \\times 2 = 10$", 
            "<b>משוואות חילוק:</b> $\\text{__} \\div 3 = 4$"
        ],
        examples: [
            {
                question: "פתרו את המשוואה: $\\text{__} + 5 = 12$",
                solution: "ננסה מספרים:<br/><b>אם נציב 5:</b> $5 + 5 = 10$ (קטן מדי)<br/><b>אם נציב 6:</b> $6 + 5 = 11$ (קטן מדי)<br/><b>אם נציב 7:</b> $7 + 5 = 12$ (מתאים!)<br/>הפתרון הוא 7"
            },
            {
                question: "פתרו את המשוואה: $\\text{__} \\times 3 = 15$",
                solution: "ננסה מספרים:<br/><b>אם נציב 4:</b> $4 × 3 = 12$ (קטן מדי)<br/><b>אם נציב 5:</b> $5 × 3 = 15$ (מתאים!)<br/><b>אם נציב 6:</b> $6 × 3 = 18$ (גדול מדי)<br/>הפתרון הוא 5"
            },
            {
                question: "פתרו את המשוואה: $\\text{__} - 4 = 3$",
                solution: "ננסה מספרים:<br/><b>אם נציב 5:</b> $5 - 4 = 1$ (קטן מדי)<br/><b>אם נציב 6:</b> $6 - 4 = 2$ (קטן מדי)<br/><b>אם נציב 7:</b> $7 - 4 = 3$ (מתאים!)<br/>הפתרון הוא 7"
            },
            {
                question: "פתרו את המשוואה: $\\text{__} \\div 2 = 6$",
                solution: "ננסה מספרים:<br/><b>אם נציב 10:</b> $10 ÷ 2 = 5$ (קטן מדי)<br/><b>אם נציב 12:</b> $12 ÷ 2 = 6$ (מתאים!)<br/><b>אם נציב 14:</b> $14 ÷ 2 = 7$ (גדול מדי)<br/>הפתרון הוא 12"
            }
        ],
        quiz: [
            {
                type: "multiChoiceRandomMath",
                question: "מה המספר החסר במשוואה: $\\text{__} + {A} = {B} $",
                params: {
                    A: {
                        range: [2, 20],
                        type: "integer"
                    },
                    B: {
                        range: ["A+1", "A+20"],
                        type: "integer"
                    }
                },
                formula: "B-A",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}+{B}",
                        "{B}-{A}",
                        "{A}-{B}"
                    ]
                },
                hint: "נסו להציב מספרים שונים ובדקו אם התוצאה היא {B}",
                solutionGuide: "המספר החסר הוא {Result} כי $ {Result} + {A} = {B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "מה המספר החסר במשוואה: $\\text{__} - {A} = {B} $",
                params: {
                    A: {
                        range: [2, 20],
                        type: "integer"
                    },
                    B: {
                        range: [1, 50],
                        type: "integer"
                    }
                },
                
                formula: "B+A",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}-{A}",
                        "{A}-{B}",
                        "{A}+{B}"
                    ]
                },
                hint: "נסו להציב מספרים שונים ובדקו אם לאחר חיסור {A} מתקבל {B}",
                solutionGuide: "המספר החסר הוא {Result} כי $ {Result} - {A} = {B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "מה המספר החסר במשוואה: $\\text{__} × {A} = {Calc_B} $",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_B: "{A}*{B}",
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}*{B}",
                        "{B}-{A}",
                        "{A}+{B}"
                    ]
                },
                hint: "נסו להציב מספרים שונים ובדקו אם המכפלה שלהם ב-{A} היא {Calc_B}",
                solutionGuide: "המספר החסר הוא {Result} כי $ {Result} × {A} = {Calc_B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "מה המספר החסר במשוואה: $\\text{__} ÷ {A} = {B} $",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}",
                        "{B}",
                        "{A}+{B}"
                    ]
                },
                hint: "נסו להציב מספרים שונים ובדקו אם כשמחלקים אותם ב-{A} מקבלים {B}",
                solutionGuide: "המספר החסר הוא {Result} כי $ {Result} ÷ {A} = {B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הקוסם חשב על מספר. הוא הוסיף {A} וקיבל {Calc_B}. על איזה מספר חשב הקוסם?",
                params: {
                    A: {
                        range: [5, 20],
                        type: "integer"
                    },
                    B: {
                        range: [10, 50],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_B: "{A}+{B}",
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}-{A}",
                        "{B}"
                    ]
                },
                hint: "נסו להציב מספרים שונים, להוסיף להם {A} ולבדוק אם מקבלים {Calc_B}",
                solutionGuide: "הקוסם חשב על {Result} כי $ {Result} + {A} = {Calc_B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הפיה חשבה על מספר. היא חיסרה {A} וקיבלה {B}. על איזה מספר חשבה הפיה?",
                params: {
                    A: {
                        range: [5, 15],
                        type: "integer"
                    },
                    B: {
                        range: [10, 40],
                        type: "integer"
                    }
                },
                
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}-{A}",
                        "{A}-{B}",
                    ]
                },
                hint: "נסו להציב מספרים שונים, לחסר מהם {A} ולבדוק אם מקבלים {B}",
                solutionGuide: "הפיה חשבה על {Result} כי $ {Result} - {A} = {B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הדרקון חשב על מספר. הוא כפל אותו ב-{A} וקיבל {Calc_B}. על איזה מספר חשב הדרקון?",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_B: "{A}*{B}",
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}*{B}",
                        "{B}-{A}",
                        "{A}+{B}"
                    ]
                },
                hint: "נסו להציב מספרים שונים, לכפול אותם ב-{A} ולבדוק אם מקבלים {Calc_B}",
                solutionGuide: "הדרקון חשב על {Result} כי $ {Result} × {A} = {Calc_B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הנינג׳ה חשב על מספר. הוא חילק אותו ב-{A} וקיבל {B}. על איזה מספר חשב הנינג׳ה?",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}/{B}",
                        "{B}/{A}",
                        "{A}+{B}"
                    ]
                },
                hint: "נסו להציב מספרים שונים, לחלק אותם ב-{A} ולבדוק אם מקבלים {B}",
                solutionGuide: "הנינג׳ה חשב על {Result} כי $ {Result} ÷ {A} = {B} $"
            },
            
            {
                type: "matching",
                question: "התאימו כל משוואה לפתרון שלה",
                pairs: [
                    { left: "$\\text{__} + 3 = 8$", right: "5" },
                    { left: "$\\text{__} - 2 = 6$", right: "8" },
                    { left: "$\\text{__} × 2 = 10$", right: "5" },
                    { left: "$\\text{__} ÷ 3 = 3$", right: "9" }
                ],
                hint: "פתרו כל משוואה בדרך של ניסוי ובדיקה",
                solutionGuide: "בדקו את הפתרונות: $5 + 3 = 8$, $8 - 2 = 6$, $5 × 2 = 10$, $9 ÷ 3 = 3$"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הליצן קנה בלונים. הוא נתן {A} בלונים לחבר וכעת נשארו לו {B} בלונים. כמה בלונים היו לליצן בהתחלה?",
                params: {
                    A: {
                        range: [3, 15],
                        type: "integer"
                    },
                    B: {
                        range: [5, 20],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}-{A}",
                        "{A}-{B}",
                        "{B}"
                    ]
                },
                hint: "חשבו: אם היו לליצן ___ בלונים והוא נתן {A}, האם יישארו לו {B}?",
                solutionGuide: "לליצן היו {Result} בלונים כי אם נחסר {A} נקבל {B}"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הטבח הכפיל את כמות העוגיות שאפה פי {A} וקיבל {Calc_B} עוגיות. כמה עוגיות היו בהתחלה?",
                params: {
                    A: {
                        range: [2, 5],
                        type: "integer"
                    },
                    B: {
                        range: [4, 12],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_B: "{A}*{B}",
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{Calc_B}",
                        "{A}+{B}",
                        "{A}"
                    ]
                },
                hint: "נסו להציב מספר ולכפול אותו ב-{A}. האם מקבלים {Calc_B}?",
                solutionGuide: "היו {Result} עוגיות כי $ {Result} × {A} = {Calc_B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הגנן חילק {Calc_B} פרחים באופן שווה ל-{A} גינות. כמה פרחים שתל בכל גינה?",
                params: {
                    A: {
                        range: [2, 6],
                        type: "integer"
                    },
                    B: {
                        range: [3, 10],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_B: "{A}*{B}",
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}",
                        "{A}+{B}"
                    ]
                },
                hint: "אם בכל גינה יש ___ פרחים, כמה פרחים יש ב-{A} גינות?",
                solutionGuide: "בכל גינה יש {Result} פרחים כי $ {Result} × {A} = {Calc_B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "לרובוט היו סוללות. הוא השתמש ב-{A} סוללות ונשארו לו {B}. כמה סוללות היו לו בהתחלה?",
                params: {
                    A: {
                        range: [2, 8],
                        type: "integer"
                    },
                    B: {
                        range: [3, 12],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{B}",
                        "{A}",
                        "{B}-{A}"
                    ]
                },
                hint: "אם היו ___ סוללות ונוצלו {A}, האם יישארו {B}?",
                solutionGuide: "היו {Result} סוללות כי $ {Result} - {A} = {B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "החייזר אסף {A} כוכבים בכל יום במשך {B} ימים. כמה כוכבים אסף בסך הכל?",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 6],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}+{B}",
                        "{A}",
                        "{B}"
                    ]
                },
                hint: "חשבו: {A} כוכבים כפול {B} ימים",
                solutionGuide: "החייזר אסף {Result} כוכבים כי $ {A} × {B} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הקוסמת חילקה {Calc_B} ממתקים באופן שווה ל-{A} ילדים. כמה ממתקים קיבל כל ילד?",
                params: {
                    A: {
                        range: [2, 5],
                        type: "integer"
                    },
                    B: {
                        range: [2, 8],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_B: "{A}*{B}",
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}",
                        "{A}+{B}"
                    ]
                },
                hint: "אם כל ילד קיבל ___ ממתקים, כמה ממתקים חולקו ל-{A} ילדים?",
                solutionGuide: "כל ילד קיבל {Result} ממתקים כי $ {Result} × {A} = {Calc_B} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "הדייג תפס {Calc_B} דגים. הוא שחרר {A} דגים בחזרה לים. כמה דגים נשארו לו?",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                metadata: {
                    Calc_B: "{A}+{B}",
                },
                formula: "B",
                distractors: {
                    strategies: ["off-by-one", "plus-two"],
                    custom: [
                        "{A}",
                        "{A}+{B}"
                    ]
                },
                hint: "אם היו {Calc_B} דגים ושוחררו {A}, כמה נשארו?",
                solutionGuide: "נשארו {Result} דגים כי $ {Calc_B} - {A} = {Result} $"
            },
            {
                type: "order",
                question: "סדרו את המשוואות לפי המספר החסר מהקטן לגדול ",
                items: [ "$\\text{__} + 5 = 10$", "$\\text{__} + 5 = 12$", "$\\text{__} + 5 = 13$", "$\\text{__} + 5 = 20$" ],
                hint: "שימו לב שהמספרים החסרים הם תוצאה של חיבור 5 למספרים שונים. כלל: שהתוצאה גדולה יותר, כך הנעלם גדול יותר.",
                solutionGuide: "כיוון שלכל הנעלמים מחברים 5, הסדר הנכון הוא לפי גודל התוצאה"
            },
          
            {
                type: "order",
                question: "סדרו את המשוואות לפי המספר החסר מהגדול לקטן ",
                items: [ "$\\text{__} - 7 = 18$", "$\\text{__} - 7 = 16$", "$\\text{__} - 7 = 14$", "$\\text{__} - 7 = 12$" ],
                hint: "שימו לב שהמספרים החסרים הם תוצאה של חיסור 7 ממספרים שונים. כלל: שהתוצאה גדולה יותר, כך הנעלם גדול יותר.",
                solutionGuide: "כיוון שלכל הנעלמים מחסרים 7, הסדר הנכון הוא לפי גודל התוצאה"
            },
              
            {
                type: "order",
                question: "סדרו את המשוואות לפי המספר החסר מהגדול לקטן ",
                items: [ "$\\text{__} \\times 3 = 24$", "$\\text{__} \\times 3 = 18$",  "$\\text{__} \\times 3 = 15$", "$\\text{__} \\times 3 = 9$" ],
                hint: "שימו לב שהמספרים החסרים הם תוצאה של כפל 3 במספרים שונים. כלל: שהתוצאה גדולה יותר, כך הנעלם גדול יותר.",
                solutionGuide: "כיוון שלכל הנעלמים מכפילים ב-3, הסדר הנכון הוא לפי גודל התוצאה"
            }
        ]
    }
};