import React, { useState, useEffect } from 'react';
import { DraggableList } from './DraggableList';
import { Box } from '@chakra-ui/react';

export const OrderQuestion = ({ question, onAnswer, showFeedback }) => {
  const [orderedItems, setOrderedItems] = useState(() => {
    if (question.items) {
      return [...question.items].map(item => item.toString());
    }
    return [];
  });
  const [isDndReady, setIsDndReady] = useState(false);

  useEffect(() => {
    if (question.items) {
      setOrderedItems([...question.items].map(item => item.toString()));
      window.MathJax?.typeset();
    }
  }, [question]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDndReady(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(orderedItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setOrderedItems(items);
  };

  const renderSingleColumn = (content) => (
    <Box w="100%" display="flex" justifyContent="center">
      <Box w="90%">
        {content}
      </Box>
    </Box>
  );

  return (
    <DraggableList
      items={orderedItems}
      droppableId="order-list"
      onDragEnd={handleDragEnd}
      onSubmit={onAnswer}
      showFeedback={showFeedback}
      loading={!isDndReady}
      renderRightSide={renderSingleColumn}
    />
  );
}; 