export const learningContent = {
    "כפל עד 10": {
        tags: ['כיתה ב׳', 'כפל'],

        // Add SEO-friendly related links
        relatedLinks: [
            {
                title: "תרגול כפל לכיתה ב׳",
                url: "/?grade=כיתה%20ב׳&tags=כפל",
                description: "תרגול כפל מותאם לכיתה ב׳"
            },
            {
                title: "תרגילי חשבון לבית ספר יסודי",
                url: "/?grade=כיתה%20ב׳&tags=חיבור וחיסור,כפל",
                description: "תרגילי חשבון מגוונים לכיתה ב׳"
            }
        ],

        getStarted: [
            "**מה זה כפל?**",
            "כפל הוא דרך קצרה לחבר מספר כמה פעמים.",
            "למשל: $3 × 4$ זה כמו $4 + 4 + 4$",
            "",
            "**דברים חשובים לזכור:**",
            "כשכופלים באפס ($0$) התוצאה תמיד אפס",
            "כשכופלים באחד ($1$) המספר לא משתנה",
            "כשכופלים בעשר ($10$) מוסיפים אפס בסוף המספר",
            "לא משנה באיזה סדר כופלים - התוצאה אותו דבר!",

            "**טריקים לכפל מהיר:**",
            "<b>כפל ב-$5$:</b> לחלק ב-$2$ ולהכפיל ב-$10$",
            "  למשל: $5 × 16 = (16 ÷ 2) × 10$",
            "  $8 × 10 = 80$",
            "",
            "<b>כפל ב-$9$:</b> להכפיל ב-$10$ ולהחסיר את המספר המקורי",
            "  למשל: $9 × 7 = (10 × 7) - 7$",
            "  $70 - 7 = 63$",
            "",
            "<b>כפל מספרים קרובים ל-$10$:</b>",
            "  להשלים ל-$10$, להכפיל, ולתקן",
            "  למשל: $8 × 7 = (10 × 7) - (2 × 7)$",
            "  $70 - 14 = 56$",
            "",
            "<b>פירוק לגורמים קלים:</b>",
            "  למשל: $6 × 7 = (2 × 3) × 7$",
            "  $2 × (3 × 7) = 2 × 21 = 42$"
        ],
        examples: [
            {
                question: "כמה זה 3 × 2?",
                solution: "\u202A3 × 2 = 2 + 2 + 2 = 6\u202C",
                drawing: {
                    type: "groups",
                    total: 6,
                    groupSize: 2,
                    emoji: "🍎",

                }
            },
            {
                question: "כמה זה 4 × 3?",
                solution: "\u202A4 × 3 = 3 + 3 + 3 + 3 = 12\u202C",
                drawing: {
                    type: "groups",
                    total: 12,
                    groupSize: 3,
                    emoji: "🌟",

                }
            },
            {
                question: "כמה זה 5 × 2?",
                solution: "\u202A5 × 2 = 2 + 2 + 2 + 2 + 2 = 10\u202C",
                drawing: {
                    type: "groups",
                    total: 10,
                    groupSize: 2,
                    emoji: "🎈",

                }
            },
            {
                question: "כמה זה 2 × 5?",
                solution: "\u202A2 × 5 = 5 + 5 = 10\u202C",
                drawing: {
                    type: "groups",
                    total: 10,
                    groupSize: 5,
                    emoji: "🎈",

                }
            },
            {
                question: "חשבו במהירות: \u202A9 × 8\u202C",
                solution: "נשתמש בטריק של כפל ב-\u202A9\u202C:<br/><br/>1. נכפיל ב-10\u202C: \u202A8 × 10 = 80\u202C <br/>2. נחסיר את המספר המקורי (8): \u202A80 - 8 = 72\u202C<br/><br/>התשובה: \u202A72\u202C",
                drawing: {
                    type: "groups",
                    total: 72,
                    groupSize: 9,
                    emoji: "🌟",

                }
            }
        ],
        quiz: [
            {
                question: "חשבו: {A} × {B} = ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 2],
                        type: "integer"
                    },
                    B: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "כשכופלים ב-2, פשוט מחברים את המספר לעצמו",
                solutionGuide: "{A} × {B} = {Result}",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "🌟",

                }
            },
            {
                question: "חשבו: {A} × {B} = ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: ["A", 8],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "חשבו על זה כמו {A} קבוצות של {B}, וחברו אותם",
                solutionGuide: "{A} × {B} = {Result}",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "🍎",

                }
            },

            {
                question: "חשבו: {A} × {B} = ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [9, 9],
                        type: "integer"
                    },
                    B: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "כשכופלים ב-9, אפשר לכפול ב-10 ולהחסיר את המספר המקורי",
                solutionGuide: "{A} × {B} = {Result}",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: 9,
                    emoji: "🎈",
                }
            },
            {
                question: "חשבו: {A} × {B} = ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [5, 5],
                        type: "integer"
                    },
                    B: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "כשכופלים ב-5, אפשר להכפיל ב-10 ולחלק ב-2",
                solutionGuide: "{A} × {B} = {Result}"
            },
            {
                question: "חשבו: {A} × {B} = ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [10, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "כשכופלים ב-10, פשוט מוסיפים אפס בסוף המספר",
                solutionGuide: "{A} × {B} = {Result}",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: 10,
                    emoji: "🌸",
                }
            },

            {
                question: "חשבו: {A} × {B} = ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 10],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "השתמשו בכלים שלמדתם כדי לפתור במהירות",
                solutionGuide: "{A} × {B} = {Result}",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "🦋",

                }
            },
            {
                question: "התאימו כל תרגיל כפל לתוצאה שלו",
                type: "matching",
                pairs: [
                    { left: "$7 × 8$", right: "$56$" },
                    { left: "$6 × 9$", right: "$54$" },
                    { left: "$5 × 7$", right: "$35$" },
                    { left: "$4 × 8$", right: "$32$" }
                ],
                hint: "נסו לפתור כל תרגיל בנפרד, או השתמשו בטריקים שלמדנו",
                solutionGuide: "$7 × 8 = 56$<br/>$6 × 9 = 54$<br/>$5 × 7 = 35$<br/>$4 × 8 = 32$"
            },
            {
                question: "התאימו כל תרגיל לביטוי השקול לו",
                type: "matching",
                pairs: [
                    { left: "$4 × 6$", right: "$6 + 6 + 6 + 6$" },
                    { left: "$3 × 8$", right: "$8 + 8 + 8$" },
                    { left: "$5 × 3$", right: "$3 + 3 + 3 + 3 + 3$" },
                    { left: "$2 × 9$", right: "$9 + 9$" }
                ],
                hint: "זכרו: כפל הוא חיבור חוזר של אותו מספר",
                solutionGuide: "כפל הוא חיבור חוזר של אותו מספר מספר פעמים"
            },
            {
                question: "התאימו כל תרגיל לציור המתאים לו",
                type: "matching",
                pairs: [
                    { left: "$3 × 4$", right: "🍎🍎🍎🍎$\\quad$🍎🍎🍎🍎$\\quad$🍎🍎🍎🍎" },
                    { left: "$3 × 8$", right: "🌟🌟🌟🌟🌟🌟🌟🌟$\\quad$🌟🌟🌟🌟🌟🌟🌟🌟$\\quad$🌟🌟🌟🌟🌟🌟🌟🌟" },
                    { left: "$2 × 6$", right: "🎈🎈🎈🎈🎈🎈$\\quad$🎈🎈🎈🎈🎈🎈" },
                    { left: "$2 × 5$", right: "🌸🌸🌸🌸🌸$\\quad$🌸🌸🌸🌸🌸" }
                ],
                hint: "ספרו את מספר הקבוצות ואת מספר הפריטים בכל קבוצה",
                solutionGuide: "סדר הגורמים לא משנה את המכפלה, אבל משנה את סידור הקבוצות"
            },
            {
                question: "התאימו כל תרגיל לטריק המתאים לפתרון שלו",
                type: "matching",
                pairs: [
                    { left: "$9 × 6$", right: "$10 × 6 - 6$" },
                    { left: "$6 × 7$", right: "$3 × 7 + 3 × 7$" },
                    { left: "$8 × 5$", right: "$10 × 5 - 2 × 5$" },
                    { left: "$4 × 8$", right: "$2 × 8 + 2 × 8$" }
                ],
                hint: "זכרו את הטריקים שלמדנו לכפל מהיר",
                solutionGuide: "כפל ב-9: להכפיל ב-10 ולהחסיר<br/>כפל במספר זוגי: לחלק את המספר לשניים ולהכפיל פעמיים<br/>כפל במספר קרוב ל-10: להשלים ל-10 ולהחסיר"
            },
            {
                question: "התאימו כל זוג תרגילים שווים",
                type: "matching",
                pairs: [
                    { left: "$4 × 7$", right: "$7 × 4$" },
                    { left: "$8 × 3$", right: "$3 × 8$" },
                    { left: "$5 × 6$", right: "$6 × 5$" },
                    { left: "$9 × 2$", right: "$2 × 9$" }
                ],
                hint: "זכרו: סדר הגורמים לא משנה את המכפלה",
                solutionGuide: "חוק החילוף בכפל: $a × b = b × a$"
            },
            {
                question: "התאימו כל סיפור לתרגיל הכפל המתאים",
                type: "matching",
                pairs: [
                    {
                        left: "🐲 לכל דרקון יש 3 ראשים. כמה ראשים יש ל-4 דרקונים?",
                        right: "$4 × 3$"
                    },
                    {
                        left: "🤖 לכל רובוט יש 8 ברגים. כמה ברגים יש ב-5 רובוטים?",
                        right: "$5 × 8$"
                    },
                    {
                        left: "🍦 לכל גלידה יש 2 כדורים. כמה כדורי גלידה יש ב-6 גביעים?",
                        right: "$6 × 2$"
                    },
                    {
                        left: "🦄 לכל חד-קרן יש 4 פרסות. כמה פרסות יש ל-7 חדי-קרן?",
                        right: "$7 × 4$"
                    }
                ],
                hint: "חשבו על המספר הכולל של דברים שצריך לספור",
                solutionGuide: "מספר הפריטים × מספר הקבוצות = סך הכל"
            },
            {
                question: "התאימו כל סיפור לתרגיל הכפל המתאים",
                type: "matching",
                pairs: [
                    {
                        left: "✨ 🦄 לכל רייבוקורן יש 6 כנפיים זוהרות. כמה כנפיים יש ל-3 רייבוקורנים?",
                        right: "$3 × 6$"
                    },
                    {
                        left: "🎎 לכל בובה יש 5 אצבעות בכל יד. כמה אצבעות יש בשתי הידיים של הבובה?",
                        right: "$2 × 5$"
                    },
                    {
                        left: "🐲 לכל דרקון יש 9 קשקשים על הזנב. כמה קשקשים יש על הזנבות של 4 דרקונים?",
                        right: "$4 × 9$"
                    },
                    {
                        left: "🤖 לכל רובוט יש 7 נורות מהבהבות. כמה נורות יש ב-6 רובוטים?",
                        right: "$6 × 7$"
                    }
                ],
                hint: "חשבו כמה פריטים יש בכל קבוצה וכמה קבוצות יש",
                solutionGuide: "מספר הפריטים × מספר הקבוצות = סך הכל"
            },
            {
                question: "התאימו כל סיפור לתרגיל הכפל המתאים",
                type: "matching",
                pairs: [
                    {
                        left: "🦄 🌈 לכל חד-קרן יש 3 צבעים בזנב הקשת. כמה צבעים יש בזנבות של 8 חדי-קרן?",
                        right: "$8 × 3$"
                    },
                    {
                        left: "✨ 🦄 לכל רייבוקורן יש 4 עיניים נוצצות. כמה עיניים יש ל-5 רייבוקורנים?",
                        right: "$5 × 4$"
                    },
                    {
                        left: "🍦 לכל גלידה יש 6 סוכריות צבעוניות. כמה סוכריות יש על 3 גלידות?",
                        right: "$3 × 6$"
                    },
                    {
                        left: "🎎 לכל בובה יש 2 צמות. כמה צמות יש ל-7 בובות?",
                        right: "$7 × 2$"
                    }
                ],
                hint: "חשבו על כמה פריטים יש בכל קבוצה",
                solutionGuide: "מספר הפריטים × מספר הקבוצות = סך הכל"
            },
            {
                question: "התאימו כל סיפור לתרגיל הכפל המתאים",
                type: "matching",
                pairs: [
                    {
                        left: "🐲 לכל דרקון יש 8 שיניים חדות. כמה שיניים יש ל-4 דרקונים?",
                        right: "$4 × 8$"
                    },
                    {
                        left: "🤖 לכל רובוט יש 5 כפתורים מהבהבים. כמה כפתורים יש ב-6 רובוטים?",
                        right: "$6 × 5$"
                    },
                    {
                        left: "🦄 🌸 לכל חד-קרן יש 7 פרחים בשיער. כמה פרחים יש בשיער של 3 חדי-קרן?",
                        right: "$3 × 7$"
                    },
                    {
                        left: "✨ 🦄 לכל רייבוקורן יש 3 קרני קסם. כמה קרני קסם יש ל-9 רייבוקורנים?",
                        right: "$9 × 3$"
                    }
                ],
                hint: "חשבו על כמה פריטים יש בכל קבוצה",
                solutionGuide: "מספר הפריטים × מספר הקבוצות = סך הכל"
            },
            {
                question: "התאימו כל סיפור לתרגיל הכפל המתאים",
                type: "matching",
                pairs: [
                    {
                        left: "🎎 לכל בובה יש 4 כפתורים על החולצה. כמה כפתורים יש על החולצות של 8 בובות?",
                        right: "$8 × 4$"
                    },
                    {
                        left: "🍦 ✨ לכל גלידה יש 3 קישוטים צבעוניים. כמה קישוטים יש על 7 גלידות?",
                        right: "$7 × 3$"
                    },
                    {
                        left: "🤖 לכל רובוט יש 6 גלגלים. כמה גלגלים יש ל-5 רובוטים?",
                        right: "$5 × 6$"
                    },
                    {
                        left: "🐲 לכל דרקון יש 5 טפרים בכל כף רגל. כמה טפרים יש בשתי כפות הרגליים הקדמיות?",
                        right: "$2 × 5$"
                    }
                ],
                hint: "חשבו על כמה פריטים יש בכל קבוצה",
                solutionGuide: "מספר הפריטים × מספר הקבוצות = סך הכל"
            },
            {
                question: "יש {A} דרקונים ולכל דרקון ניתן {B} עוגיות.<br/>כמה עוגיות נצטרך?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [3, 8], type: "integer" },
                    B: { range: [2, 6], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} עוגיות",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "🍪"
                }
            },
            {
                question: "יש {A} חתולי קסם ולכל חתול יש {B} כנפיים.<br/>כמה כנפיים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [4, 9], type: "integer" },
                    B: { range: [2, 6], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} כנפיים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "🐱"
                }
            },
            {
                question: "יש {A} רובוטים ולכל רובוט {B} גלגלים.<br/>כמה גלגלים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [3, 7], type: "integer" },
                    B: { range: [4, 8], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} גלגלים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "⚙️"
                }
            },
            {
                question: "יש {A} פיות ולכל פיה {B} שרביטים.<br/>כמה שרביטים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [4, 9], type: "integer" },
                    B: { range: [2, 5], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} שרביטים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "✨"
                }
            },
            {
                question: "יש {A} חד-קרנים ולכל חד-קרן {B} פרחים בשיער.<br/>כמה פרחים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [3, 7], type: "integer" },
                    B: { range: [4, 8], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} פרחים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "🌸"
                }
            },
            {
                question: "יש {A} מכשפות ולכל מכשפה {B} חתולים שחורים.<br/>כמה חתולים שחורים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [4, 9], type: "integer" },
                    B: { range: [2, 10], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} חתולים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "🐈‍⬛"
                }
            },
            {
              question: "יש {A} גמדים ולכל גמד {B} יהלומים.<br/>כמה יהלומים יש בסך הכל?",
              type: "multiChoiceRandomMath",
                params: {
                    A: { range: [3, 8], type: "integer" },
                    B: { range: [2, 10], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} יהלומים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "💎"
                }
            },
            {
                question: "יש {A} ענקים ולכל ענק {B} כפתורים על המעיל.<br/>כמה כפתורים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [2, 6], type: "integer" },
                    B: { range: [2, 10], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} כפתורים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "⭕"
                }
            },
            {
                question: "יש {A} נינג׳ות ולכל נינג׳ה {B} כוכבי נינג׳ה.<br/>כמה כוכבים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [4, 8], type: "integer" },
                    B: { range: [2, 10], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} כוכבים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "⭐"
                }
            },
            {
                question: "יש {A} דינוזאורים ולכל דינוזאור {B} קשקשים.<br/>כמה קשקשים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [3, 7], type: "integer" },
                    B: { range: [2, 10], type: "integer" }
                },
                formula: "A*B",
                distractors: { strategies: ["off-by-one", "digit-swap", "off-by-ten"] },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} - נכון! {A} קבוצות של {B} קשקשים",
                drawing: {
                    type: "groups",
                    total: "{Result}",
                    groupSize: "{B}",
                    emoji: "🦎"
                }
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["2 × 3", "2 × 4", "3 × 3", "2 × 5"],
                hint: "",
                solutionGuide: "2 × 3 = 6<br/>2 × 4 = 8<br/>3 × 3 = 9<br/>2 × 5 = 10"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["3 × 4", "3 × 5", "4 × 4", "3 × 6"],
                hint: "",
                solutionGuide: "3 × 4 = 12<br/>3 × 5 = 15<br/>4 × 4 = 16<br/>3 × 6 = 18"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["4 × 6", "5 × 5", "3 × 9", "7 × 4"],
                hint: "",
                solutionGuide: "4 × 6 = 24<br/>5 × 5 = 25<br/>3 × 9 = 27<br/>7 × 4 = 28"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["4 × 7", "5 × 6", "7 × 5", "6 × 6"],
                hint: "",
                solutionGuide: "4 × 7 = 28<br/>5 × 6 = 30<br/>7 × 5 = 35<br/>6 × 6 = 36"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["7 × 5", "6 × 6", "5 × 8", "7 × 6"],
                hint: "",
                solutionGuide: "7 × 5 = 35<br/>6 × 6 = 36<br/>5 × 8 = 40<br/>7 × 6 = 42"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["5 × 8", "6 × 7", "8 × 6", "7 × 7"],
                hint: "",
                solutionGuide: "5 × 8 = 40<br/>6 × 7 = 42<br/>8 × 6 = 48<br/>7 × 7 = 49"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["6 × 8", "7 × 7", "6 × 9", "8 × 8"],
                hint: "",
                solutionGuide: "6 × 8 = 48<br/>7 × 7 = 49<br/>6 × 9 = 54<br/>8 × 8 = 64"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["9 × 6", "8 × 7", "7 × 9", "8 × 8"],
                hint: "",
                solutionGuide: "9 × 6 = 54<br/>8 × 7 = 56<br/>7 × 9 = 63<br/>8 × 8 = 64"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["7 × 8", "8 × 8", "6 × 11", "9 × 8"],
                hint: "",
                solutionGuide: "7 × 8 = 56<br/>8 × 8 = 64<br/>6 × 11 = 66<br/>9 × 8 = 72"
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["8 × 9", "7 × 11", "9 × 9", "10 × 9"],
                hint: "",
                solutionGuide: "8 × 9 = 72<br/>7 × 11 = 77<br/>9 × 9 = 81<br/>10 × 9 = 90"
            },


            {
                question: "יש {A} דרקונים ולכל דרקון {B} ראשים. כמה ראשים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 9],
                        type: "integer"
                    },
                    B: {
                        range: [2, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} (כפל מספר הדרקונים במספר הראשים)"
            },
            {
                question: "יש {A} פיות ולכל פיה יש {B} שיקויים. כמה שיקויים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 9],
                        type: "integer"
                    },
                    B: {
                        range: [2, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} (כפל מספר הפיות במספר השיקויים)"
            },
            {
                question: "{A} רובוטים הגיעו למסיבה, כל רובוט הביא {B} בלונים. כמה בלונים יש במסיבה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 9],
                        type: "integer"
                    },
                    B: {
                        range: [2, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} (כפל מספר הרובוטים במספר הבלונים)"
            },
            {
                question: "{A} נינג'ות מתאמנות בחצר, כל נינג'ה זורקת {B} כוכבי נינג'ה. כמה כוכבים עפים באוויר?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 9],
                        type: "integer"
                    },
                    B: {
                        range: [2, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} (כפל מספר הנינג'ות במספר הכוכבים)"
            },
            {
                question: "בגן החיות המכושף יש {A} פינגווינים קסומים, ולכל פינגווין {B} כובעי קוסמים. כמה כובעים יש?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 9],
                        type: "integer"
                    },
                    B: {
                        range: [2, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "digit-swap", "off-by-ten"]
                },
                hint: "חשבו: {A} × {B} = ?",
                solutionGuide: "{A} × {B} = {Result} (כפל מספר הפינגווינים במספר הכובעים)"
            }
        ]
    }
};