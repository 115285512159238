export const learningContent = {
    "בעיות מילוליות קצב מהירות וזמן": {
        tags: ['כיתה ז׳', 'בעיות מילוליות', 'מהירות וזמן', 'יחסים'],

        relatedLinks: [
            {
                title: "בעיות מילוליות",
                url: "/?tags=בעיות%20מילוליות",
                description: "תרגול בעיות מילוליות נוספות"
            },
            {
                title: "תרגול מהירות וזמן",
                url: "/?tags=מהירות%20וזמן",
                description: "תרגול נוסף בנושא מהירות וזמן"
            },
            {
                title: "בעיות מילוליות בחשבון",
                url: "/?tags=בעיות%20מילוליות,חשבון",
                description: "תרגול בעיות מילוליות בחשבון"
            }
        ],

        getStarted: [
            "**פתרון בעיות מילוליות בשלבים:**",
            "1. **קריאה והבנה:**",
            "   קראו את השאלה בעיון",
            "   סמנו נתונים חשובים",
            "   זהו מה נדרש למצוא",
            "",
            "2. **תרגום למתמטיקה:**",
            "   רשמו את הנתונים בצורה מסודרת",
            "   זהו את היחסים בין הכמויות",
            "   בנו משוואה או תרגיל מתאים",
            "",
            "3. **נוסחאות בסיסיות:**",
            "   מהירות =   זמן ÷  מרחק",
            "   זמן = מהירות ÷ מרחק",
            "   מרחק = מהירות × זמן",
            "   קצב עבודה = זמן ÷ כמות עבודה",
            "",
            "4. **בדיקת הגיונית:**",
            "   האם התשובה הגיונית?",
            "   האם היחידות נכונות?",
            "   האם ענינו על מה שנשאלנו?"
        ],
        examples: [
            {
                question: "שני פועלים צובעים קיר. פועל א׳ צובע קיר ב-6 שעות, ופועל ב׳ צובע את אותו הקיר ב-4 שעות. כמה זמן יקח להם לצבוע את הקיר יחד?",
                solution: "בואו נפתור את הבעיה בשלבים:<br /><br />1. ראשית, נחשב כמה חלק מהקיר כל פועל צובע בשעה אחת:<br />• פועל א׳ צובע קיר שלם ב-6 שעות, אז בשעה אחת הוא צובע: $\\frac{1}{6}$ מהקיר<br />• פועל ב׳ צובע קיר שלם ב-4 שעות, אז בשעה אחת הוא צובע: $\\frac{1}{4}$ מהקיר<br /><br />2. כעת נחשב כמה חלק מהקיר הם צובעים יחד בשעה אחת:<br />• $\\frac{1}{6} + \\frac{1}{4} = \\frac{4}{24} + \\frac{6}{24} = \\frac{10}{24}$ מהקיר בשעה<br /><br />3. אם בשעה אחת הם צובעים $\\frac{10}{24}$ מהקיר, נחשב כמה שעות יקח להם לצבוע קיר שלם:<br />• כדי למצוא את הזמן, נחלק 1 (קיר שלם) בקצב העבודה המשותף:<br />• $\\frac{1}{\\frac{10}{24}} = \\frac{24}{10} = 2.4$ שעות<br /><br />התשובה הסופית: 2.4 שעות, או 2 שעות ו-24 דקות",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 24,
                    highlights: {
                        first: [1, 2, 3, 4],
                        second: [5, 6, 7, 8, 9, 10],
                        result: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
                    },
                    caption: "רצועה המייצגת קיר שלם, מחולקת ל-24 חלקים. כחול: קצב פועל א׳, ירוק: קצב פועל ב׳, סגול: קצב משותף"
                }
            },
            {
                question: "חנות מוכרת חולצות ב-100 ש״ח. בסוף העונה יש הנחה של 20%, ואחר כך הנחה נוספת של 10% על המחיר המוזל. מה המחיר הסופי?",
                type: "multiplechoice",
                solution: "בואו נפתור את הבעיה בשלבים:<br /><br />1. נחשב את המחיר אחרי ההנחה הראשונה של 20%:<br />• הנחה של 20% משמעותה שנשלם 80% מהמחיר המקורי<br />• 80% = 0.8<br />• מחיר אחרי הנחה ראשונה: 100 $\\times$ 0.8 = 80 ש״ח<br /><br />2. נחשב את המחיר אחרי ההנחה השנייה של 10%:<br />• הנחה של 10% על 80 ש״ח משמעותה שנשלם 90% מ-80 ש״ח<br />• 90% = 0.9<br />• מחיר סופי: 80 $\\times$ 0.9 = 72 ש״ח<br /><br />3. דרך חלופית לחישוב (לבדיקה):<br />• אחוז התשלום הכולל: 0.8 $\\times$ 0.9 = 0.72<br />• מחיר סופי: 100 $\\times$ 0.72 = 72 ש״ח<br /><br />התשובה הסופית: 72 ש״ח",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 100,
                    highlights: {
                        first: Array.from({ length: 20 }, (_, i) => i + 1),
                        second: Array.from({ length: 8 }, (_, i) => i + 21),
                        result: Array.from({ length: 72 }, (_, i) => i + 29)
                    },
                    caption: "רצועת 100 חלקים המייצגת את המחיר המקורי. כחול: הנחה ראשונה (20%), ירוק: הנחה שנייה (8%), סגול: מחיר סופי (72%)"
                }
            },
            {
                question: "אופנוע נוסע במהירות של 60 קמ״ש. כמה זמן יקח לו להגיע ליעד המרוחק 150 ק״מ?",
                type: "multiplechoice",
                solution: "בואו נפתור את הבעיה בשלבים:<br /><br />1. נזהה את הנתונים:<br />• מהירות = 60 קילומטר בשעה<br />• מרחק = 150 קילומטר<br /><br />2. נשתמש בנוסחה: זמן = מרחק ÷ מהירות<br />• זמן = 150 ÷ 60 = 2.5 שעות<br /><br />3. נבין את התשובה:<br />• 2.5 שעות = שעתיים וחצי<br />• חצי שעה = 30 דקות<br /><br />התשובה הסופית: 2.5 שעות, או שעתיים ו-30 דקות",
                drawing: {
                    type: "timeline",
                    points: [
                        "0 ק״מ\n0 שעות",
                        "60 ק״מ\n1 שעה",
                        "120 ק״מ\n2 שעות",
                        "150 ק״מ\n2.5 שעות"
                    ],
                    caption: "התקדמות האופנוע לאורך זמן"
                }
            },
            {
                question: "רוכב אופניים נוסע במהירות 10 קמ״ש. כמה ק״מ יעבור בשעתיים?",
                type: "multiplechoice",
                solution: "בואו נפתור את הבעיה בשלבים:<br /><br />1. נזהה את הנתונים:<br />• מהירות = 10 קילומטר בשעה<br />• זמן = שעתיים<br /><br />2. נשתמש בנוסחה: מרחק = מהירות $\\times$ זמן<br />• מרחק = 10 $\\times$ 2 = 20 קילומטר<br /><br />3. נוכל לבדוק את ההיגיון:<br />• בשעה אחת עובר 10 ק״מ<br />• בשעתיים יעבור פי 2 = 20 ק״מ<br /><br />התשובה הסופית: 20 קילומטר",
                drawing: {
                    type: "numberLine",
                    min: 0,
                    max: 20,
                    points: [0, 10, 20],
                    labels: ["0", "10", "20"],
                    caption: "ציר זמן-מרחק המראה את התקדמות רוכב האופניים"
                }
            }
        ],
        quiz: [
            {
                question: "רוכב אופניים רוכב במהירות של {speed} קמ״ש. כמה שעות יקח לו לרכוב {distance} ק״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [4, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 4],
                        type: "integer"
                    }
                },
                formula: "A/2",
                metadata: {
                    speed: "{B} * 10",
                    time: "{A}/2",
                    distance: "{B} * 10 * {A}/2"
                },
                distractors: {
                    strategies: ["off-by-one", "half", "double"]
                },
                hint: "השתמשו בנוסחת זמן = מרחק ÷ מהירות. שימו לב שהתוצאה תהיה בשעות",
                solutionGuide: "$ {distance} ÷ {speed} = {Result} $ שעות"
            },
            {
                question: "פועל ראשון צובע דירה ב-{workerA} שעות, ופועל שני צובע את אותה דירה ב-{workerB} שעות. כמה זמן יקח לשניהם לצבוע את הדירה יחד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 4],
                        type: "integer"
                    },
                    B: {
                        range: [3, 3],
                        type: "integer"
                    }

                },
                formula: "(2*A*B)/(1+B)",
                metadata: {
                    workerA: "2*{A}",
                    workerB: "2*{A}*{B}",
                    totalRate: "1/(2*{A}) + 1/(2*{A}*{B})",
                    totalRateHour: "1/(1/(2*{A}) + 1/(2*{A}*{B}))"

                },
                distractors: {
                    strategies: ["off-by-one", "double", "off-by-two"]
                },
                hint: "חשבו על קצב העבודה של כל פועל בנפרד (כמה דירה לשעה), ואז חברו את הקצבים",
                solutionGuide: "קצב פועל א׳: $\\frac{1}{{workerA}}$ דירה לשעה. קצב פועל ב׳: $\\frac{1}{{workerB}}$ דירה לשעה.<br/>יחד: $\\frac{1}{{workerA}} + \\frac{1}{{workerB}} = \\frac{1}{{totalRateHour}}$ דירה לשעה. זמן = $1 \\div \\frac{1}{{totalRateHour}} = {Result}$ שעות."
            },
            {
                question: "מכנסיים בזארה עולים {price} ש״ח. אם יש הנחה של {percent}%, מה המחיר החדש?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 60],
                        type: "integer"
                    },
                    B: {
                        range: [1, 3],
                        type: "integer"
                    }
                },
                formula: "A*10-B*A",
                metadata: {
                    percent: "{B} * 10",
                    price: "{A}*10",
                    discount: "{A}*{B}"
                },
                distractors: {
                    strategies: [
                        "half",
                        "double",
                        "off-by-ten"
                    ]
                },
                hint: "חשבו כמה אחוזים מהמחיר המקורי צריך לשלם (100% פחות אחוז ההנחה)",
                solutionGuide: "נחשב מחיר אחרי הנחה של {percent}%:<br/>" +
                    "• המחיר המקורי: {price} ש״ח<br/>" +
                    "• סכום ההנחה: {discount} ש״ח (חישוב: {price} × {percent} ÷ 100)<br/>" +
                    "• מחיר סופי: {Result} ש״ח (חישוב: {price} - {discount})"
            },
            // Converting: "בעל חנות קנה מוצר ב-400 ש״ח, הוסיף 25% רווח ונתן הנחה של 10%. מה מחיר המכירה הסופי?"
            {
                question: "בעל חנות קנה מוצר ב-{originalPrice} ש״ח, הוסיף {profit}% רווח ונתן הנחה של {discount}%. מה מחיר המכירה הסופי?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 5],  // Base price will be A*100 = 200-500 שח
                        type: "integer"
                    },
                    B: {
                        range: [1, 2],    // Profit will be B*50 = 25-100%
                        type: "integer"
                    },
                    C: {
                        range: [2, 2],    // Discount will be C*5 = 10-10%
                        type: "integer"
                    }
                },
                formula: "A*100*(1+B*50/100)*(1-C*5/100)",  // Price*(1+profit%)*(1-discount%)
                metadata: {
                    originalPrice: "{A}*100",
                    profit: "{B}*50",
                    discount: "{C}*5",
                    priceAfterProfit: "{A}*100*(1+{B}*50/100)"
                },
                distractors: {
                    strategies: ["off-by-ten", "half", "off-by-two"]
                },
                hint: "חשבו בשלבים - קודם הוסיפו את הרווח למחיר הקנייה, ואז הפחיתו את ההנחה מהתוצאה",
                solutionGuide: "מחיר אחרי רווח: $ {originalPrice} × (1 + {profit}/100) = {priceAfterProfit} $ ש״ח<br/>מחיר סופי אחרי הנחה: $ {priceAfterProfit} × (1 - {discount}/100) = {Result} $ ש״ח"
            },
            {
                question: "שני ילדים רצים באותו כיוון. טל במהירות {speedA} קמ״ש וברק במהירות {speedB} קמ״ש. טל {distance} ק״מ מאחורי ברק, תוך כמה שעות ישיג טל את ברק?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 5],    // Will be multiplied by 2 for first speed
                        type: "integer"
                    },
                    B: {
                        range: [2, 3],    // Time to catch up (will construct other values to make this work)
                        type: "integer"
                    }
                },
                formula: "B",  // Time is B because we construct speeds and distance to make this work
                metadata: {
                    speedA: "{A}*4",      // First speed: 12-20 km/h
                    speedB: "{A}*4-4",    // Second speed: 4 km/h slower
                    distance: "{B}*4",    // Distance: makes the division work out
                    speedDiff: "4"        // Difference in speed is always 4 for simpler calculations
                },
                distractors: {
                    strategies: ["plus-two", "plus-one", "double"]
                },
                hint: "כשרצים באותו כיוון, מהירות ההתקרבות היא ההפרש בין המהירויות. השתמשו בנוסחת זמן = מרחק ÷ מהירות",
                solutionGuide: "מהירות התקרבות = $ {speedA} - {speedB} = {speedDiff} $ קמ״ש<br/>זמן = $ {distance} ÷ {speedDiff} = {Result} $  שעות"
            },

            // Converting: "אם נדרשות 6 שעות לצביעת 3 דירות, כמה דירות אפשר לצבוע ב-24 שעות?"
            {
                question: "אם נדרשות {hours} שעות לצביעת {apartments} דירות, כמה דירות אפשר לצבוע ב-{totalHours} שעות?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],    // Base number of apartments
                        type: "integer"
                    },
                    B: {
                        range: [2, 3],    // Multiplier for total hours
                        type: "integer"
                    }
                },
                formula: "A*B*4",         // Result will be 4 times the original amount
                metadata: {
                    hours: "6",
                    apartments: "{A}",
                    totalHours: "{B}*24",
                    ratePerHour: "{A}/6"
                },
                distractors: {
                    strategies: ["off-by-two", "off-by-one", "double"]
                },
                hint: "מצאו קודם כמה דירות צובעים בשעה אחת, ואז הכפילו במספר השעות הכולל",
                solutionGuide: "קצב צביעה = $ {apartments} ÷ {hours}  $ דירות לשעה<br/>מספר דירות = $ ({apartments} ÷ {hours}) × {totalHours} = {Result} $   דירות"
            },
            // Converting: "חנות מוכרת מעילים ב-400 ש״ח. במבצע סוף עונה יש הנחה של 50% ואחר כך הנחה נוספת של 25%. מה המחיר הסופי?"
            {
                question: "חנות מוכרת מעילים ב-{originalPrice} ש״ח. במבצע סוף עונה יש הנחה של 50% ואחר כך הנחה נוספת של {extraDiscount}%. מה המחיר הסופי?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 6],    // Price will be A*100 = 200-600 שח
                        type: "integer"
                    },
                    B: {
                        range: [2, 2],    // Extra discount will be B*10 = 20%
                        type: "integer"
                    }
                },
                formula: "A*100*0.5*(1-B*10/100)",  // Price * 0.5 * (1-extraDiscount%)
                metadata: {
                    originalPrice: "{A}*100",
                    extraDiscount: "{B}*10",
                    priceAfterFirst: "{A}*100*0.5"
                },
                distractors: {
                    strategies: ["off-by-ten", "double", "half"]
                },
                hint: "חשבו בשלבים - קודם חשבו את המחיר אחרי ההנחה הראשונה של 50%, ואז הפחיתו את ההנחה השנייה מהתוצאה",
                solutionGuide: "מחיר אחרי הנחה ראשונה: $ {originalPrice} × 0.5 = {priceAfterFirst} $ ש״ח<br/>מחיר סופי: $ {priceAfterFirst} × (1 - {extraDiscount}/100) = {Result} $ ש״ח"
            },

            // Converting: "שתי מכוניות יוצאות זו לקראת זו. האחת במהירות 70 קמ״ש והשנייה במהירות 50 קמ״ש. המרחק ביניהן 360 ק״מ. אחרי כמה זמן ייפגשו?"
            {
                question: "שתי מכוניות יוצאות זו לקראת זו. האחת במהירות {speedA} קמ״ש והשנייה במהירות {speedB} קמ״ש. המרחק ביניהן {distance} ק״מ. אחרי כמה שעות ייפגשו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],    // Time to meet (will construct speeds to make this work)
                        type: "integer"
                    },
                    B: {
                        range: [3, 5],    // Base for speeds
                        type: "integer"
                    }
                },
                formula: "A",  // Time is A because we construct speeds and distance to make this work
                metadata: {
                    speedA: "{B}*20",     // First speed: 60-100 km/h
                    speedB: "{B}*10",     // Second speed: half of first speed
                    distance: "{A}*{B}*30",  // Distance that makes division work
                    totalSpeed: "{B}*30"  // Combined speed
                },
                distractors: {
                    strategies: ["off-by-one", "half", "double"]
                },
                hint: "כשנוסעים זה לקראת זה, מהירות ההתקרבות היא סכום המהירויות. השתמשו בנוסחת זמן = מרחק ÷ מהירות",
                solutionGuide: "מהירות התקרבות = $ {speedA} + {speedB} = {totalSpeed} $ קמ״ש<br/>זמן = $ {distance} ÷ {totalSpeed} = {Result} $ שעות"
            },

            // Converting: "ברז א׳ ממלא בריכה ב-2 שעות וברז ב׳ ממלא את אותה בריכה ב-4 שעות. אם פותחים את שניהם יחד, כמה זמן יקח למלא את הבריכה?"
            {
                question: "ברז א׳ ממלא בריכה ב-{timeA} שעות וברז ב׳ ממלא את אותה בריכה ב-{timeB} שעות. אם פותחים את שניהם יחד, כמה שעות יקח למלא את הבריכה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 4],
                        type: "integer"
                    },
                    B: {
                        range: [3, 3],
                        type: "integer"
                    }

                },
                formula: "(2*A*B)/(1+B)",
                metadata: {
                    timeA: "2*{A}",
                    timeB: "2*{A}*{B}",
                    rateA: "1/{A}",
                    rateB: "1/({A}*{B})",
                    totalRate: "(1/{A}) + 1/({A}*{B})"
                },
                distractors: {
                    strategies: ["off-by-one", "double", "off-by-two"]
                },
                hint: "חשבו על קצב המילוי של כל ברז (כמה בריכה לשעה) וחברו את הקצבים",
                solutionGuide: "קצב ברז א׳: $ \\frac{1}{{timeA}} $ בריכה לשעה<br/>קצב ברז ב׳: $ \\frac{1}{{timeB}} $ בריכה לשעה<br/>קצב כולל: $ \\frac{1}{{timeA}} + \\frac{1}{{timeB}} $ בריכה לשעה<br/>זמן מילוי: $ {Result} $ שעות"
            },
            // Converting: "בכיתה יש 30 תלמידים. 40% מהם בנים. כמה בנות יש בכיתה?"
            {
                question: "בכיתה יש {total} תלמידים. {boysPercent}% מהם בנים. כמה בנות יש בכיתה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 5],    // Total will be A*10 = 20-50 students
                        type: "integer"
                    },
                    B: {
                        range: [2, 3],    // Boys percent will be B*20 = 40-60%
                        type: "integer"
                    }
                },
                formula: "A*10-A*10*B*20/100",  // Total - (Total * boysPercent)
                metadata: {
                    total: "{A}*10",
                    boysPercent: "{B}*20",
                    boys: "{A}*10*{B}*20/100"
                },
                distractors: {
                    strategies: ["off-by-three", "off-by-two", "off-by-ten"]
                },
                hint: "קודם מצאו כמה בנים יש בכיתה ({boysPercent}% מ-{total}), ואז חסרו מהמספר הכולל",
                solutionGuide: "מספר הבנים: $ {total} × \\frac{{{boysPercent}}}{100} = {boys} $<br/>מספר הבנות: $ {total} - {boys} = {Result} $ בנות"
            },

            // Converting: "מיכל מתמלא על ידי צינור במשך 10 דקות, ומתרוקן דרך חור במשך 15 דקות. אם המיכל מלא ופותחים את שניהם, כמה זמן יקח לו להתרוקן כשהצינור פתוח?"
            {
                question: "מיכל מתמלא על ידי צינור במשך {fillTime} דקות, ומתרוקן דרך חור במשך {emptyTime} דקות. אם המיכל מלא ופותחים את שניהם, כמה דקות יקח לו להתרוקן?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],    // Base time unit
                        type: "integer"
                    },
                    B: {
                        range: [2, 2],    // Multiplier for empty time
                        type: "integer"
                    }
                },
                formula: "A*A*B",  // Constructing times to get nice division
                metadata: {
                    fillTime: "{A}*5",    // Fill time: 10-20 minutes
                    emptyTime: "{A}*5*{B}", // Empty time: 2x fill time
                    fillRate: "1/({A}*5)",
                    emptyRate: "1/({A}*5*{B})",
                    netRate: "1/({A}*{A}*{B})"
                },
                distractors: {
                    strategies: ["off-by-ten", "half", "double"]
                },
                hint: "חשבו על קצב השינוי הכולל - ההפרש בין קצב המילוי (חיובי) לקצב הריקון (שלילי)",
                solutionGuide: "קצב מילוי: $ \\frac{1}{{{fillTime}}} $ מיכל לדקה<br/>קצב ריקון: $ \\frac{1}{{{emptyTime}}} $ מיכל לדקה<br/>קצב נטו: $ \\frac{1}{{{fillTime}}} - \\frac{1}{{{emptyTime}}} = \\frac{1}{{Result}} $ מיכל לדקה<br/>זמן: $ {Result} $ דקות"
            },

            // Converting: "עובד מרוויח 10,000 ש״ח בחודש. אם משכורתו עלתה ב-5% ואז ירדה ב-5%, מה משכורתו הסופית?"
            {
                question: "עובד מרוויח {salary} ש״ח בחודש. אם משכורתו עלתה ב-{increase}% ואז ירדה ב-{decrease}%, מה משכורתו הסופית?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [5, 12],   // Salary will be A*1000 = 5000-12000 שח
                        type: "integer"
                    },
                    B: {
                        range: [1, 2],    // Percent changes will be B*5 = 5-10%
                        type: "integer"
                    }
                },
                formula: "A*1000*(1+(B*5)/100)*(1-(B*5)/100)",  // Salary*(1+increase%)*(1-decrease%)
                metadata: {
                    salary: "{A}*1000",
                    increase: "{B}*5",
                    decrease: "{B}*5",
                    afterIncrease: "{A}*1000*(1+{B}*5/100)"
                },
                distractors: {
                    strategies: ["double", "off-by-ten", "half"]
                },
                hint: "חשבו בשלבים - קודם חשבו את המשכורת אחרי העלייה, ואז הפחיתו את הירידה מהתוצאה",
                solutionGuide: "משכורת אחרי עלייה: $ {salary} × (1 + \\frac{{{increase}}}{100}) = {afterIncrease} $ ש״ח<br/>משכורת סופית: $ {afterIncrease} × (1 - \\frac{{{decrease}}}{100}) = {Result} $ ש״ח"
            },
            {
                question: "מכונית נוסעת במהירות של {speed} קמ״ש. כמה ק״מ היא תעבור ב-{time} דקות?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [4, 12],  // Speed will be A*10 = 40-120 km/h
                        type: "integer"
                    },
                    B: {
                        range: [2, 6],  // Time will be B*15 = 30-90 minutes
                        type: "integer"
                    }
                },
                formula: "A*B*2.5", // (A*10)*(B*15)/60 simplified
                metadata: {
                    speed: "{A}*10",
                    time: "{B}*15"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-two"]
                },
                hint: "המרו את הזמן לשעות ({time} דקות = {time}/60 שעות) ואז השתמשו בנוסחת מרחק = מהירות × זמן",
                solutionGuide: "מרחק = מהירות × זמן = $ {speed} × ({time}/60) = {Result} $ ק״מ"
            },
            // Converting original: "רוכב אופניים נוסע במהירות 15 קמ״ש. אחרי כמה זמן יעבור מרחק של 45 ק״מ?"
            {
                question: "רוכב אופניים נוסע במהירות {speed} קמ״ש. אחרי כמה שעות יעבור מרחק של {distance} ק״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 6],  // Will give speeds of 10-25 km/h
                        type: "integer"
                    },
                    B: {
                        range: [2, 7],  // Will be multiplied to get distance
                        type: "integer"
                    }
                },
                formula: "B",  // Time is B because we construct distance as speed*B
                metadata: {
                    speed: "{A}*5",
                    distance: "{A}*5*{B}"  // Makes sure division results in whole number (B)
                },
                distractors: {
                    strategies: ["off-by-one", "plus-two", "double"]
                },
                hint: "השתמשו בנוסחת זמן = מרחק ÷ מהירות",
                solutionGuide: "זמן = $ {distance} ÷ {speed} = {Result} $ שעות"
            },
            // Converting original: "אופה מכין 30 עוגיות בשעה. כמה שעות יקח לו להכין 210 עוגיות?"
            {
                question: "חלי אופה  {rate} עוגיות בשעה. כמה שעות יקח לה להכין {total} עוגיות?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 6],  // Will give production rates of 30-60 cookies/hour
                        type: "integer"
                    },
                    B: {
                        range: [4, 9],  // Will be multiplied to get total cookies
                        type: "integer"
                    }
                },
                formula: "B",  // Time is B because we construct total as rate*B
                metadata: {
                    rate: "{A}*10",
                    total: "{A}*10*{B}"  // Makes sure division results in whole number (B)
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "double"]
                },
                hint: "חשבו כמה קבוצות של {rate} עוגיות יש ב-{total} עוגיות",
                solutionGuide: "זמן = $ {total} ÷ {rate} = {Result} $ שעות"
            },
            // Converting: "חנות מציעה מבצע 'הנחה של 30% על המוצר השני'. כמה ישלם לקוח הקונה שתי חולצות במחיר 200 ש״ח כל אחת?"
            {
                question: "חנות מציעה מבצע 'הנחה של {discount}% על המוצר השני'. כמה ישלם לקוח הקונה שתי חולצות במחיר {price} ש״ח כל אחת?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],    // Price will be A*100 = 200-400 שח
                        type: "integer"
                    },
                    B: {
                        range: [3, 3],    // Discount will be B*10 = 30%
                        type: "integer"
                    }
                },
                formula: "A*100*2 - A*100*B*10/100",  // 2*price - (price * discount%)
                metadata: {
                    price: "{A}*100",
                    discount: "{B}*10",
                    fullPrice: "{A}*100*2",
                    discountAmount: "{A}*100*{B}*10/100"
                },
                distractors: {
                    strategies: ["off-by-eleven", "half", "off-by-ten"]
                },
                hint: "שלמו מחיר מלא על החולצה הראשונה, ומחיר מופחת ({discount}%) על החולצה השנייה",
                solutionGuide: "חולצה ראשונה: $ {price} $ ש״ח<br/>חולצה שנייה: $ {price} × (1 - \\frac{{{discount}}}{100}) = {price} - {discountAmount} $ ש״ח<br/>סה״כ: $ {price} + ({price} - {discountAmount}) = {Result} $ ש״ח"
            },

            // Converting: "שלושה פועלים צובעים קיר ב-6 שעות. כמה זמן יקח לשני פועלים לצבוע את אותו הקיר?"
            {
                question: "שלושה פועלים צובעים קיר ב-{originalTime} שעות. כמה שעות יקח ל-{workers} פועלים לצבוע את אותו הקיר?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 5],    // Base time unit
                        type: "integer"
                    },
                    B: {
                        range: [2, 2],    // Number of workers will be B (fixed at 2)
                        type: "integer"
                    }
                },
                formula: "A*4.5",  // Time for 2 workers
                metadata: {
                    originalTime: "{A}*3",
                    workers: "{B}",
                    ratePerWorker: "1/({A}*9)",
                    timeperWorker: "{A}*9",
                    totalRate: "{B}/(A*9)"
                },
                distractors: {
                    strategies: ["off-by-three", "half", "double"]
                },
                hint: "חשבו קודם כמה זמן לוקח לפועל אחד לצבוע את הקיר, ואז חשבו כמה זמן יקח לשני פועלים",
                solutionGuide: "קצב צביעה לפועל: $ \\frac{1}{3} × \\frac{1}{{originalTime}} = \\frac{1}{{timeperWorker}} $ קיר לשעה<br/>{workers} פועלים: $ \\frac{{{workers}}}{{{timeperWorker}}} $ קיר לשעה<br/>זמן כולל: $ {Result} $ שעות"
            },

            // Converting: "בקבוק מים מתרוקן דרך חור תוך 6 דקות. ברז ממלא אותו תוך 3 דקות. אם הבקבוק מלא ופותחים את שניהם, כמה זמן יקח עד שיתרוקן?"
            {
                question: "בקבוק מים מתרוקן דרך חור תוך {emptyTime} דקות. ברז ממלא אותו תוך {fillTime} דקות. אם הבקבוק מלא ופותחים את שניהם, כמה דקות יקח עד שיתרוקן?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 6],    // Base time unit
                        type: "integer"
                    },
                    B: {
                        range: [2, 2],    // Time ratio multiplier (fixed at 2)
                        type: "integer"
                    }
                },
                formula: "(A*B*2)/(B-1)",  // Constructed to get nice numbers
                metadata: {
                    fillTime: "{A}*{B}*2",
                    emptyTime: "{A}*2",
                    emptyRate: "1/({A}*{B}*2)",
                    fillRate: "1/({A}*2)",
                    netRate: "({B}-1)/({A}*{B}*2)"
                },
                distractors: {
                    strategies: ["off-by-two", "half", "double"]
                },
                hint: "חשבו על קצב השינוי הכולל - ההפרש בין קצב המילוי (שלילי) לקצב הריקון (חיובי)",
                solutionGuide: "קצב ריקון: $ \\frac{1}{{{emptyTime}}} $ בקבוק לדקה<br/>קצב מילוי: $ \\frac{1}{{{fillTime}}} $ בקבוק לדקה<br/>קצב נטו: $ \\frac{1}{{{emptyTime}}} - \\frac{1}{{{fillTime}}} = \\frac{1}{{Result}} $ בקבוק לדקה<br/>זמן: $ {Result} $ דקות"
            },
            // Converting: "חנות מוכרת חולצות ב-150 ש״ח. אחרי הנחה של 20% ועוד 10% למשלמים במזומן. מה המחיר הסופי במזומן?"
            {
                question: "חנות מוכרת חולצות ב-{originalPrice} ש״ח. אחרי הנחה של {firstDiscount}% ועוד {secondDiscount}% למשלמים במזומן. מה המחיר הסופי במזומן?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [1, 4],    // Price will be A*100 = 100-400 שח
                        type: "integer"
                    },
                    B: {
                        range: [2, 2],    // First discount will be B*10 = 20%
                        type: "integer"
                    }
                },
                formula: "A*100*(1-B*10/100)*(1-10/100)",  // Price*(1-firstDiscount%)*(1-secondDiscount%)
                metadata: {
                    originalPrice: "{A}*100",
                    firstDiscount: "{B}*10",
                    secondDiscount: "10",
                    afterFirst: "{A}*100*(1-{B}*10/100)",
                    firstDiscountAmount: "{A}*100*{B}*10/100"
                },
                distractors: {
                    strategies: ["off-by-ten", "half", "off-by-eleven"]
                },
                hint: "חשבו בשלבים - קודם הפחיתו את ההנחה הראשונה, ואז את ההנחה השנייה מהתוצאה",
                solutionGuide: "מחיר התחלתי: $ {originalPrice} $ ש״ח<br/>אחרי הנחה ראשונה: $ {originalPrice} - {firstDiscountAmount} = {afterFirst} $ ש״ח<br/>מחיר סופי: $ {afterFirst} × (1 - \\frac{10}{100}) = {Result} $ ש״ח"
            },

            // Converting: "שני ברזים מזרימים מים לבריכה. ברז א׳ ממלא אותה ב-{timeA} שעות וברז ב׳ ב-{timeB} שעות. כמה זמן יקח למלא את הבריכה כששניהם פתוחים?"
            {
                question: "שני ברזים מזרימים מים לבריכה. ברז א׳ ממלא אותה ב-{timeA} שעות וברז ב׳ ב-{timeB} שעות. כמה שעות יקח למלא את הבריכה כששניהם פתוחים?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 6],    // First tap time
                        type: "integer"
                    },
                    B: {
                        range: [2, 4],    // Multiplier for second tap
                        type: "integer"
                    }
                },
                formula: "B*A-B",  // The total time formula we derived
                metadata: {
                    timeA: "{A}*{B}",      // First tap time
                    timeB: "{A}*{B}*({A}-1)",  // Second tap time
                    rateA: "1/({A}*{B})",    // Rate of first tap
                    rateB: "1/({A}*{B}*({A}-1))", // Rate of second tap
                    totalRate: "{A}/({A}*{B}*({A}-1))"// Combined rate
                },
                distractors: {
                    strategies: ["off-by-one", "half", "double"]
                },
                hint: "חשבו על קצב המילוי של כל ברז (כמה בריכה לשעה) וחברו את הקצבים",
                solutionGuide: "קצב ברז א׳: $ \\frac{1}{{{timeA}}} $ בריכה לשעה<br/>קצב ברז ב׳: $ \\frac{1}{{{timeB}}} $ בריכה לשעה<br/>קצב כולל: $ \\frac{1}{{{timeA}}} + \\frac{1}{{{timeB}}} $ בריכה לשעה<br/>זמן מילוי: $ {Result} $ שעות"
            },

            // Converting: "רוכב אופניים נוסע במהירות {speed} קמ״ש. אחרי כמה זמן יגיע ליעד המרוחק {distance} ק״מ?"
            {
                question: "רוכב אופניים נוסע במהירות {speed} קמ״ש. אחרי כמה שעות יגיע ליעד המרוחק {distance} ק״מ?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],    // Base speed unit
                        type: "integer"
                    },
                    B: {
                        range: [2, 6],    // Time (will construct distance to match)
                        type: "integer"
                    }
                },
                formula: "B",  // Time is B because we construct distance to make this work
                metadata: {
                    speed: "{A}*5",      // Speeds: 10-20 km/h
                    distance: "{A}*5*{B}" // Distance that ensures whole number division
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "double"]
                },
                hint: "השתמשו בנוסחת זמן = מרחק ÷ מהירות",
                solutionGuide: "זמן = $ \\frac{{{distance}}}{{{speed}}} = {Result} $ שעות"
            },
            // Converting: "שתי מכוניות יוצאות זו לקראת זו. האחת במהירות 70 קמ״ש והשנייה במהירות 50 קמ״ש. המרחק ביניהן 360 ק״מ. אחרי כמה זמן ייפגשו?"
            {
                question: "שתי מכוניות יוצאות זו לקראת זו. האחת במהירות {speedA} קמ״ש והשנייה במהירות {speedB} קמ״ש. המרחק ביניהן {distance} ק״מ. אחרי כמה שעות ייפגשו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],    // Will determine meeting time
                        type: "integer"
                    },
                    B: {
                        range: [4, 6],    // Base speed multiple
                        type: "integer"
                    }
                },
                formula: "A",  // Time is A because we construct speeds and distance to make this work
                metadata: {
                    speedA: "{B}*20",     // First speed: 80-120 km/h
                    speedB: "{B}*10",     // Second speed: half of first speed
                    distance: "{A}*{B}*30",  // Distance that makes division work
                    totalSpeed: "{B}*30"  // Combined speed
                },
                distractors: {
                    strategies: ["off-by-one", "half", "double"]
                },
                hint: "כשנוסעים זה לקראת זה, מהירות ההתקרבות היא סכום המהירויות. השתמשו בנוסחת זמן = מרחק ÷ מהירות",
                solutionGuide: "מהירות התקרבות: $ {speedA} + {speedB} = {totalSpeed} $ קמ״ש<br/>זמן = $ \\frac{{{distance}}}{{{totalSpeed}}} = {Result} $ שעות"
            },


            // Converting: "בעל חנות קנה סחורה ב-500 ש״ח והוסיף 40% רווח. אחר כך נתן הנחה של 25%. מה המחיר הסופי?"
            {
                question: "בעל חנות קנה סחורה ב-{cost} ש״ח והוסיף {profit}% רווח. אחר כך נתן הנחה של {discount}%. מה המחיר הסופי?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 6],    // Cost will be A*100 = 300-600 שח
                        type: "integer"
                    },
                    B: {
                        range: [2, 2],    // Profit will be B*20 = 40%
                        type: "integer"
                    }
                },
                formula: "A*100*(1+B*20/100)*(1-25/100)",  // Cost*(1+profit%)*(1-discount%)
                metadata: {
                    cost: "{A}*100",
                    profit: "{B}*20",
                    discount: "25",
                    priceAfterProfit: "{A}*100*(1+{B}*20/100)",
                    profitAmount: "{A}*100*{B}*20/100"
                },
                distractors: {
                    strategies: ["off-by-ten", "half", "off-by-eleven"]
                },
                hint: "חשבו בשלבים - קודם הוסיפו את הרווח למחיר הקנייה, ואז הפחיתו את ההנחה מהתוצאה",
                solutionGuide: "מחיר קנייה: $ {cost} $ ש״ח<br/>תוספת רווח: $ {profitAmount} $ ש״ח<br/>מחיר אחרי רווח: $ {priceAfterProfit} $ ש״ח<br/>מחיר סופי אחרי הנחה: $ {priceAfterProfit} × (1 - \\frac{25}{100}) = {Result} $ ש״ח"
            },
            // Converting: "חנות מציעה מבצע 'קנה 2 קבל 1 חינם' על ספרים שמחירם 50 ש״ח כל אחד. כמה ישלם לקוח שקונה 7 ספרים?"
            {
                question: "חנות מציעה מבצע 'קנה 2 קבל 1 חינם' על ספרים שמחירם {price} ש״ח כל אחד. כמה ישלם לקוח שקונה {amount} ספרים?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 5],    // Price will be A*25 = 50-125 שח
                        type: "integer"
                    },
                    B: {
                        range: [4, 8],    // Total books: always give numbers that work with the deal
                        type: "integer"
                    }
                },
                formula: "A*25*(floor(B/3)*2 + B%3)",  // Pay for 2 out of every 3 books, plus remainder
                metadata: {
                    price: "{A}*25",
                    amount: "{B}",
                    fullSets: "floor({B}/3)",
                    remainder: "{B}%3",
                    booksInFullSets: "floor({B}/3)*3",
                    paidBooksInSets: "floor({B}/3)*2"
                },
                distractors: {
                    strategies: ["off-by-ten", "off-by-eleven", "double"]
                },
                hint: "חלקו את הספרים לקבוצות של שלושה (שמשלמים רק על שניים מהם), ובדקו כמה ספרים נשארים בודדים",
                solutionGuide: "קבוצות שלמות: $ {fullSets} $ קבוצות = $ {booksInFullSets} $ ספרים<br/>משלמים על: $ {paidBooksInSets} $ ספרים מהקבוצות<br/>נשארו: $ {remainder} $ ספרים בודדים<br/>סה״כ משלמים על: $ {paidBooksInSets} + {remainder} $ ספרים<br/>מחיר סופי: $ ({paidBooksInSets} + {remainder}) × {price} = {Result} $ ש״ח"
            },

            // Converting: "שני אחים קיבלו יחד 100 ש״ח. האח הגדול קיבל פי 3 מהאח הקטן. כמה קיבל כל אחד?"
            {
                question: "שני אחים קיבלו יחד {total} ש״ח. האח הגדול קיבל פי {ratio} מהאח הקטן. כמה שקלים קיבל האח הקטן?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 6],    // Base amount that we'll multiply by (ratio+1)
                        type: "integer"
                    },
                    B: {
                        range: [2, 3],    // Ratio bigger/smaller (2 or 3)
                        type: "integer"
                    }
                },
                formula: "A*50",  // Smaller amount = A*100 (total is A*100*(B+1))
                metadata: {
                    total: "{A}*50*({B}+1)",    // Total is always divisible by (B+1)
                    ratio: "{B}",
                    biggerAmount: "{A}*50*{B}",
                    parts: "{B}+1"
                },
                distractors: {
                    strategies: ["off-by-ten", "double", "half"]
                },
                hint: "חשבו על הסכום כמורכב מחלקים שווים, כאשר האח הגדול מקבל {ratio} חלקים והקטן חלק אחד",
                solutionGuide: "סך הכל חלקים: $ {parts} $ ({ratio} לגדול + 1 לקטן)<br/>סכום כולל: $ {total} $ ש״ח<br/>כל חלק: $ \\frac{{{total}}}{{{parts}}} = {Result} $ ש״ח<br/>האח הקטן מקבל חלק אחד = $ {Result} $ ש״ח<br/>האח הגדול מקבל $ {ratio} $ חלקים = $ {biggerAmount} $ ש״ח"
            },

            // Converting: "בקופסה היו 30 סוכריות. אבא חילק אותן שווה בשווה בין 5 ילדים. כמה סוכריות קיבל כל ילד?"
            {
                question: "בקופסה היו {total} סוכריות. אבא חילק אותן שווה בשווה בין {kids} ילדים. כמה סוכריות קיבל כל ילד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 5],    // Each kid gets A*5 candies
                        type: "integer"
                    },
                    B: {
                        range: [2, 5],    // Number of kids
                        type: "integer"
                    }
                },
                formula: "A*5",  // Candies per kid is A*5
                metadata: {
                    total: "{A}*5*{B}",
                    kids: "{B}"
                },
                distractors: {
                    strategies: ["off-by-two", "double", "half"]
                },
                hint: "חלקו את מספר הסוכריות הכולל במספר הילדים",
                solutionGuide: "$ {total} ÷ {kids} = {Result} $ סוכריות לכל ילד"
            },
            // Converting: "מכונית נוסעת במהירות 90 קמ״ש. כמה ק״מ היא תעבור ב-40 דקות?"
            {
                question: "מכונית נוסעת במהירות {speed} קמ״ש. כמה ק״מ היא תעבור ב-{minutes} דקות?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 6],    // Speed will be A*20 = 60-120 km/h
                        type: "integer"
                    },
                    B: {
                        range: [2, 4],    // Minutes will be B*15 = 30-60 minutes
                        type: "integer"
                    }
                },
                formula: "A*20*B*15/60",  // Speed * minutes / 60
                metadata: {
                    speed: "{A}*20",
                    minutes: "{B}*15",
                    hours: "{B}*15/60",
                    ratePerMinute: "{A}*20/60"
                },
                distractors: {
                    strategies: ["off-by-ten", "double", "off-by-eleven"]
                },
                hint: "המרו את הדקות לשעות ({minutes} דקות = שעה אחת) ואז כפלו במהירות בקמ״ש",
                solutionGuide: "זמן בשעות: $ {minutes} \\div 60 = {hours} $ שעות<br/>מרחק = $ {speed} × {hours} = {Result} $ ק״מ"
            },

            // Converting: "במגרש יש {total} כדורים. צריך לחלק אותם ל-{groups} שולחנות שווה בשווה. כמה כדורים יהיו בכל שולחן?"
            {
                question: "במגרש יש {total} כדורים. צריך לחלק אותם ל-{groups} קבוצות שווה בשווה. כמה כדורים יהיו בכל קבוצה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],    // Items per group
                        type: "integer"
                    },
                    B: {
                        range: [2, 4],    // Number of groups
                        type: "integer"
                    }
                },
                formula: "A*5",  // Items per group 
                metadata: {
                    total: "{A}*5*{B}",
                    groups: "{B}"
                },
                distractors: {
                    strategies: ["off-by-two", "double", "half"]
                },
                hint: "חלקו את מספר הכדורים הכולל במספר הקבוצות",
                solutionGuide: "$ {total} ÷ {groups} = {Result} $ כדורים בכל קבוצה"
            },

            // Converting sequence of changes: students leaving then new ones arriving
            {
                // Start of Selection
                question: "בבית הספר יש {initial} תלמידים. {left} תלמידים הלכו הביתה ואז {joined} תלמידים חדשים הגיעו. כמה תלמידים יש עכשיו בבית הספר?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 30],    // Initial number: A*10 = 30-60 students
                        type: "integer"
                    },
                    B: {
                        range: [1, 5],    // Left: B*5 = 5-10 students
                        type: "integer"
                    },
                    C: {
                        range: [2, 6],    // Joined: C*5 = 10-15 students
                        type: "integer"
                    }
                },
                formula: "A*10-B*5+C*5",  // Initial - left + joined
                metadata: {
                    initial: "{A}*10",
                    left: "{B}*5",
                    joined: "{C}*5",
                    afterLeft: "{A}*10-{B}*5"
                },
                distractors: {
                    strategies: ["off-by-three", "off-by-two", "off-by-ten"]
                },
                hint: "חשבו בשלבים - קודם חסרו את התלמידים שהלכו, ואז הוסיפו את התלמידים החדשים",
                solutionGuide: "התחלה: $ {initial} $ תלמידים<br/>אחרי שהלכו: $ {initial} - {left} = {afterLeft} $ תלמידים<br/>אחרי שהגיעו חדשים: $ {afterLeft} + {joined} = {Result} $ תלמידים"
            },

            // Converting age comparison pattern
            {
                question: "אמא בת {momAge}, בת פי {ratio} מבתה. בת כמה הבת?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],    // Daughter's age will be A*5
                        type: "integer"
                    },
                    B: {
                        range: [3, 3],    // Ratio will be fixed at 3 for realistic ages
                        type: "integer"
                    }
                },
                formula: "A*5",  // Daughter's age
                metadata: {
                    momAge: "{A}*5*{B}",
                    ratio: "{B}"
                },
                distractors: {
                    strategies: ["plus-two", "off-by-three", "double"]
                },
                hint: "אם אמא בת פי {ratio} מבתה, צריך לחלק את גיל האם ב-{ratio}",
                solutionGuide: "גיל הבת = $ {momAge} ÷ {ratio} = {Result} $ שנים"
            },

            // Converting mixed rate purchase pattern
            {
                question: "בחנות יש מבצע: {bundle} חטיפים ב-{bundlePrice} ש״ח, חטיף בודד עולה {singlePrice} ש״ח. כמה ישלם מי שקונה {amount} חטיפים?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 4],    // Base price unit
                        type: "integer"
                    },
                    B: {
                        range: [3, 3],    // Bundle size fixed at 3
                        type: "integer"
                    }
                },
                formula: "floor(7/3)*A*4 + (7%3)*A*2",  // Complete bundles cost + remainder at single price
                metadata: {
                    bundle: "3",
                    bundlePrice: "{A}*4",
                    singlePrice: "{A}*2",
                    amount: "7",
                    fullBundles: "floor(7/3)",
                    remainder: "7%3",
                    bundleCost: "floor(7/3)*{A}*4",
                    remainderCost: "(7%3)*{A}*2"
                },
                distractors: {
                    strategies: ["double", "off-by-ten", "off-by-two"]
                },
                hint: "חשבו כמה חבילות שלמות אפשר לקנות, ואז הוסיפו את העלות של החטיפים הבודדים שנשארו",
                solutionGuide: "חבילות שלמות: $ {fullBundles} × {bundlePrice} = {bundleCost} $ ש״ח<br/>חטיפים בודדים: $ {remainder} × {singlePrice} = {remainderCost} $ ש״ח<br/>סה״כ: $ {bundleCost} + {remainderCost} = {Result} $ ש״ח"
            },
            // Time overlap problem with split hours
            {
                question: "האח הגדול צופה בטלויזיה משעה {startHourA}:00 עד {endHourA}:00, והאח הקטן צופה משעה {startHourB}:30 עד {endHourB}:30. באיזה פרק זמן (בדקות) הם צפו שניהם ביחד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 5],    // First kid start hour
                        type: "integer"
                    },
                    B: {
                        range: [1, 4],    // Duration for first kid
                        type: "integer"
                    },
                    C: {
                        range: [1, 2],    // Duration overlap multiplier
                        type: "integer"
                    }
                },
                formula: "C*60 - 30",  // Overlap time in minutes
                metadata: {
                    startHourA: "{A}",
                    endHourA: "{A} + {B}",
                    startHourB: "{A} + ({B} - {C})",
                    endHourB: "{A} + ({B} + 1)"
                },
                distractors: {
                    strategies: ["off-by-ten", "double", "half"]
                },
                hint: "מצאו את תקופת הזמן שבה שניהם צפו - מהשעה המאוחרת מבין שעות ההתחלה עד השעה המוקדמת מבין שעות הסיום",
                solutionGuide: "זמן צפייה משותף: משעה {startHourB}:30 עד שעה {endHourA}:00<br/>סה״כ: {Result} דקות"
            },
            {
                question: "מיכל מתמלא על ידי צינור במשך 10 דקות, ומתרוקן דרך חור במשך 15 דקות. אם המיכל מלא ופותחים את שניהם, כמה זמן יקח לו להתרוקן כשהצינור פתוח?",
                type: "multiplechoice",
                options: ["30 דקות", "25 דקות", "20 דקות", "35 דקות"],
                hint: "חשבו על קצב השינוי הכולל - הפרש בין קצב המילוי (חיובי) לקצב הריקון (שלילי)",
                solutionGuide: "קצב מילוי: $\\frac{1}{10}$ מיכל לדקה, קצב התרוקנות: $\\frac{1}{15}$ מיכל לדקה. קצב נטו = $\\frac{1}{10} - \\frac{1}{15} = \\frac{1}{30}$ מיכל לדקה. זמן = $1 \\div \\frac{1}{30} = 30$ דקות."
            },
            {
                question: "סוחר קנה סחורה ב-1000 ש״ח, הוסיף 40% רווח, ואז נתן הנחה של 20%. מה המחיר הסופי?",
                type: "multiplechoice",
                options: ["1120 ש״ח", "1200 ש״ח", "1080 ש״ח", "1160 ש״ח"],
                hint: "פתרו בשלבים - קודם הוסיפו את הרווח למחיר הקנייה, ואז הפחיתו את ההנחה מהתוצאה",
                solutionGuide: "מחיר אחרי רווח: 1000 $\\times$ $(1 + \\frac{40}{100})$ = 1400 ש״ח. מחיר אחרי הנחה: 1400 $\\times$ $\\frac{80}{100}$ = 1120 ש״ח."
            },

            {
                question: "בריכה מתמלאת על ידי ברז א׳ ב-2 שעות וברז ב׳ ב-4 שעות. אם פותחים את שניהם יחד, כמה זמן יקח למלא את הבריכה?",
                type: "multiplechoice",
                options: ["1.33 שעות", "1.25 שעות", "1.5 שעות", "2 שעות"],
                hint: "חשבו על קצב המילוי של כל ברז (כמה בריכה לשעה) וחברו את הקצבים",
                solutionGuide: "קצב ברז א׳: $\\frac{1}{2}$ בריכה לשעה, ברז שני: $\\frac{1}{4}$ בריכה לשעה. יחד: $\\frac{3}{4}$ בריכה לשעה. זמן: $\\frac{4}{3} = 1\\frac{1}{3}$ שעות"
            },

            {
                question: "בכיתה יש 30 תלמידים. 40% מהם בנים. כמה בנות יש בכיתה?",
                type: "multiplechoice",
                options: ["18 בנות", "20 בנות", "12 בנות", "15 בנות"],
                hint: "קודם מצאו כמה בנים יש בכיתה (40% מ-30), ואז חסרו מהמספר הכולל",
                solutionGuide: "מספר הבנים: $30 \\times \\frac{4}{10} = 12$. מספר הבנות: $30 - 12 = 18$"
            },

            {
                question: "פועל צובע קיר ב-6 שעות. פועל מנוסה יותר צובע את אותו הקיר ב-2 שעות. כמה זמן יקח להם יחד?",
                type: "multiplechoice",
                options: ["שעה וחצי", "שעתיים", "שעה ורבע", "שעה"],
                hint: "חשבו על קצב העבודה של כל פועל (כמה קיר לשעה) וחברו את הקצבים",
                solutionGuide: "קצב פועל ראשון = $\\frac{1}{6}$ קיר לשעה<br/> קצב פועל שני = $\\frac{1}{2}$ קיר לשעה<br/> קצב משותף = $\\frac{1}{6} + \\frac{1}{2} = \\frac{4}{6} = \\frac{2}{3}$ קיר לשעה<br/> זמן = $\\frac{3}{2} = 1\\frac{1}{2}$ שעות<br/> כלומר לשני הפועלים יחד יקח שעה וחצי לצבוע את הקיר"
            },
            {
                question: "סוחר קנה סחורה ב-200 ש״ח והוסיף 50% רווח. מה מחיר המכירה?",
                type: "multiplechoice",
                options: ["300 ש״ח", "250 ש״ח", "400 ש״ח", "350 ש״ח"],
                hint: "50% רווח משמעותו תוספת של מחצית מהמחיר המקורי",
                solutionGuide: "מחיר מכירה = $200 × (1 + \\frac{1}{2}) = 200 × \\frac{3}{2} = 300$ ש״ח"
            },
            {
                question: "שתי מכוניות יוצאות זו לקראת זו. האחת במהירות 70 קמ״ש והשנייה במהירות 50 קמ״ש. המרחק ביניהן 360 ק״מ. אחרי כמה זמן ייפגשו?",
                type: "multiplechoice",
                options: ["3 שעות", "2.5 שעות", "4 שעות", "3.5 שעות"],
                hint: "כשנוסעים זה לקראת זה, מהירות ההתקרבות היא סכום המהירויות. השתמשו בנוסחת זמן = מרחק ÷ מהירות",
                solutionGuide: "מהירות התקרבות = $70 + 50 = 120$ קמ״ש. זמן = $360 \\div 120 = \\frac{360}{120} = 3$ שעות"
            },
            {
                question: "ברז א׳ ממלא בריכה ב-2 שעות וברז ב׳ מרוקן אותה ב-3 שעות. אם הבריכה מלאה ושני הברזים פתוחים, כמה זמן יקח לרוקן אותה?",
                type: "multiplechoice",
                options: ["6 שעות", "5 שעות", "7 שעות", "4 שעות"],
                hint: "חשבו על קצב השינוי הכולל - ההפרש בין קצב המילוי (חיובי) לקצב הריקון (שלילי)",
                solutionGuide: "קצב מילוי = $\\frac{1}{2}$ בריכה לשעה, קצב ריקון = $-\\frac{1}{3}$ בריכה לשעה. קצב נטו = $\\frac{1}{2} - \\frac{1}{3} = \\frac{1}{6}$ בריכה לשעה. זמן = $1 \\div \\frac{1}{6} = 6$ שעות"
            },
            {
                question: "חולצה עולה 150 ש״ח. אחרי הנחה של 20% ועוד 10% למשלמים במזומן. מה המחיר הסופי במזומן?",
                type: "multiplechoice",
                options: ["108 ש״ח", "120 ש״ח", "112 ש״ח", "105 ש״ח"],
                hint: "חשבו בשלבים - קודם הפחיתו את ההנחה הראשונה, ואז את ההנחה השנייה מהתוצאה",
                solutionGuide: "מחיר אחרי הנחה ראשונה: $150 × (1 - \\frac{2}{10}) = 150 × \\frac{8}{10} = 120$ ש״ח. מחיר סופי: $120 × \\frac{9}{10} = 108$ ש״ח"
            },
            {
                question: "בקבוק מים מתרוקן דרך חור תוך 6 דקות. ברז ממלא אותו תוך 3 דקות. אם הבקבוק מלא ופותחים את שניהם, כמה זמן יקח עד שיתמלא?",
                type: "multiplechoice",
                options: ["6 דקות", "3 דקות", "4 דקות", "2 דקות"],
                hint: "חשבו על קצב השינוי הכולל - ההפרש בין קצב המילוי (חיובי) לקצב הריקון (שלילי)",
                solutionGuide: "קצב ריקון = $\\frac{1}{6}$ בקבוק לדקה. קצב מילוי = $\\frac{1}{3}$ בקבוק לדקה. קצב נטו = $\\frac{1}{3} - \\frac{1}{6} = \\frac{1}{6}$ בקבוק לדקה. זמן = $1 \\div \\frac{1}{6} = 6$ דקות"
            },
            {
                question: "עובד מרוויח 10,000 ש״ח בחודש. אם משכורתו עלתה ב-5% ואז ירדה ב-5%, מה משכורתו הסופית?",
                type: "multiplechoice",
                options: ["9,975 ש״ח", "10,000 ש״ח", "9,950 ש״ח", "10,025 ש״ח"],
                hint: "חשבו בשלבים - קודם הוסיפו 5% למשכורת המקורית, ואז הפחיתו 5% מהתוצאה. שימו לב שזה לא אותו דבר כמו לא לשנות כלום",
                solutionGuide: "אחרי העלאה: $10,000 \\times 1.05 = 10,500$. אחרי ירידה: $10,500 \\times 0.95 = 9,975$ ש״ח"
            },
            {
                question: "שני ילדים רצים באותו כיוון. טל במהירות 12 קמ״ש ושי רצה במהירות 8 קמ״ש. טל 10 ק״מ מאחורי שי, תוך כמה זמן ישיג טל את שי?",
                type: "multiplechoice",
                options: ["2.5 שעות", "3 שעות", "2 שעות", "3.5 שעות"],
                hint: "כשרצים באותו כיוון, מהירות ההתקרבות היא ההפרש בין המהירויות. השתמשו בנוסחת זמן = מרחק ÷ מהירות",
                solutionGuide: "מהירות התקרבות = $12 - 8 = 4$ קמ״ש. זמן = $10 ÷ 4 = 2.5$ שעות"
            },

            {
                question: "בעל חנות קנה מוצר ב-400 ש״ח, הוסיף 25% רווח ונתן הנחה של 10%. מה מחיר המכירה הסופי?",
                type: "multiplechoice",
                options: ["450 ש״ח", "425 ש״ח", "475 ש״ח", "500 ש״ח"],
                hint: "חשבו בשלבים - קודם הוסיפו את הרווח למחיר הקנייה, ואז הפחיתו את ההנחה מהתוצאה",
                solutionGuide: "מחיר אחרי רווח: 400 $\\times$ 1.25 = 500 ש״ח. מחיר סופי: 500 $\\times$ 0.9 = 450 ש״ח"
            },
            {
                question: "אם נדרשות 6 שעות לצביעת 3 דירות, כמה דירות אפשר לצבוע ב-24 שעות?",
                type: "multiplechoice",
                options: ["12 דירות", "10 דירות", "8 דירות", "9 דירות"],
                hint: "מצאו קודם כמה דירות צובעים בשעה אחת, ואז הכפילו ב-24",
                solutionGuide: "קצב צביעה = $\\frac{3}{6} = \\frac{1}{2}$ דירות לשעה. מספר דירות = $\\frac{1}{2} × 24 = 12$ דירות"
            },
            {
                question: "חנות מציעה מבצע 'הנחה של 30% על המוצר השני'. כמה ישלם לקוח הקונה שתי חולצות במחיר 200 ש״ח כל אחת?",
                type: "multiplechoice",
                options: ["340 ש״ח", "320 ש״ח", "360 ש״ח", "380 ש״ח"],
                hint: "שלמו מחיר מלא על החולצה הראשונה, ומחיר מופחת (70%) על החולצה השנייה",
                solutionGuide: "חולצה ראשונה: $200$ ש״ח. חולצה שנייה: $200 × 0.7 = 140$ ש״ח. סה״כ: $200 + 140 = 340$ ש״ח"
            },
            {
                question: "שלושה פועלים צובעים קיר ב-6 שעות. כמה זמן יקח לשני פועלים לצבוע את אותו הקיר?",
                type: "multiplechoice",
                options: ["9 שעות", "8 שעות", "10 שעות", "7 שעות"],
                hint: "חשבו קודם כמה זמן לוקח לפועל אחד לצבוע את הקיר, ואז חשבו כמה זמן יקח לשני פועלים",
                solutionGuide: "נחשב את קצב העבודה של פועל אחד: אם 3 פועלים צובעים קיר ב-6 שעות, אז פועל אחד צובע $\\frac{1}{3}$ קיר ב-6 שעות, כלומר $\\frac{1}{18}$ קיר בשעה. שני פועלים יצבעו פי 2 מהר, כלומר $\\frac{2}{18} = \\frac{1}{9}$ קיר בשעה. כדי לצבוע קיר שלם בקצב של $\\frac{1}{9}$ קיר לשעה, יידרשו $1 \\div \\frac{1}{9} = 9$ שעות"
            }
        ]
    }
};
