export const learningContent = {
    "חיבור במאונך עם המרה": {
        tags: ['כיתה ב׳', 'חיבור וחיסור'],
        relatedLinks: [
            { title: "תרגול חיבור במאונך עם המרה", url: "/?tags=חיבור וחיסור", description: "תרגול בעיות חיבור במאונך עם המרה" }
        ],
        getStarted: [
            "**איך מחברים מספרים עם המרה?**",
            "",
            "רושמים את המספרים זה מתחת לזה",
            "מיישרים לפי האחדות (ימין)",
            "מתחילים לחבר מימין לשמאל",
            "אם התוצאה גדולה מ-9, ממירים לעשרת",
            "",
            "**שלבי החיבור:**",
            "1. מסדרים את המספרים זה תחת זה",
            "2. מתחילים מאחדות (הטור הימני)",
            "3. אם התוצאה גדולה מ-9, רושמים את האחדות ומעבירים 1 לעשרות",
            "4. ממשיכים לטור העשרות",
            "",
            "**טיפים:**",
            "תמיד לרשום מספרים ברורים",
            "לסמן את העשרות שמעבירים",
            "לבדוק את התוצאה בסוף",
            {
                drawing: {
                    type: "vertical",
                    firstNumber: 26,
                    secondNumber: 37,
                    operation: "addition",
                    steps: [
                        "מחברים אחדות: $6 + 7 = 13$",
                        "רושמים 3 ומעבירים 1 לעשרות",
                        "מחברים עשרות: $1 + 2 + 3 = 6$"
                    ]
                }
            }
        ],
        examples: [
            {
                question: "חברו במאונך: 38 + 45",
                solution: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 38 \\\\ + 45 \\\\ \\hline 83 \\end{array}$<br/><br/>אחדות: 8 + 5 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 3 + 4 = 8",
                drawing: {
                    type: "vertical",
                    firstNumber: 38,
                    secondNumber: 45,
                    operation: "addition",
                    steps: [
                        "מחברים אחדות: $8 + 5 = 13$",
                        "רושמים 3 ומעבירים 1 לעשרות",
                        "מחברים עשרות: $1 + 3 + 4 = 8$"
                    ]
                }
            }
        ],
        quiz: [
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 28 \\\\ + 19 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["47", "37", "57", "67"],
                hint: "כשמחברים את האחדות 8+9, האם התוצאה גדולה מ-10? אם כן, זכרו להעביר עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 28 \\\\ + 19 \\\\ \\hline 47 \\end{array}$<br/><br/>אחדות: $8 + 9 = 17$, נרשום 7 ונעביר 1<br/>עשרות: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 47"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 35 \\\\ + 28 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["63", "53", "73", "43"],
                hint: "התחילו מימין - חברו את האחדות 5+8. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 35 \\\\ + 28 \\\\ \\hline 63 \\end{array}$<br/><br/>אחדות: $5 + 8 = 13$, נרשום 3 ונעביר 1<br/>עשרות: $1 + 3 + 2 = 6$<br/><br/>התוצאה הסופית היא 63"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 17 \\\\ + 44 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["61", "51", "71", "41"],
                hint: "חברו קודם את האחדות 7+4. אם יש העברה, אל תשכחו להוסיף אותה לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 17 \\\\ + 44 \\\\ \\hline 61 \\end{array}$<br/><br/>אחדות: $7 + 4 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 1 + 4 = 6$<br/><br/>התוצאה הסופית היא 61"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 45 \\\\ + 16 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["61", "71", "51", "81"],
                hint: "סדרו את המספרים זה מתחת לזה, יישרו לימין. התחילו מהאחדות וזכרו להעביר עשרת אם צריך",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 45 \\\\ + 16 \\\\ \\hline 61 \\end{array}$<br/><br/>אחדות: $5 + 6 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 4 + 1 = 6$<br/><br/>התוצאה הסופית היא 61"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 28 \\\\ + 34 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["62", "52", "72", "42"],
                hint: "חברו את האחדות 8+4. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 28 \\\\ + 34 \\\\ \\hline 62 \\end{array}$<br/><br/>אחדות: $8 + 4 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 2 + 3 = 6$<br/><br/>התוצאה הסופית היא 62"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 38 \\\\ + 28 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["66", "76", "56", "86"],
                hint: "שימו לב שיש לחבר שני מספרים שמסתיימים ב-8. האם סכומם גדול מ-10?",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 38 \\\\ + 28 \\\\ \\hline 66 \\end{array}$<br/><br/>אחדות: $8 + 8 = 16$, נרשום 6 ונעביר 1<br/>עשרות: $1 + 3 + 2 = 6$<br/><br/>התוצאה הסופית היא 66"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 47 \\\\ + 25 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["72", "82", "62", "92"],
                hint: "התחילו מימין - חברו את האחדות 7+5. אם התוצאה גדולה מ-10, זכרו להעביר עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 47 \\\\ + 25 \\\\ \\hline 72 \\end{array}$<br/><br/>אחדות: $7 + 5 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 4 + 2 = 7$<br/><br/>התוצאה הסופית היא 72"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 18 \\\\ + 35 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["53", "63", "43", "73"],
                hint: "סדרו את המספרים כך שהאחדות מיושרות. חברו מימין לשמאל והעבירו עשרת אם צריך",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 18 \\\\ + 35 \\\\ \\hline 53 \\end{array}$<br/><br/>אחדות: $8 + 5 = 13$, נרשום 3 ונעביר 1<br/>עשרות: $1 + 1 + 3 = 5$<br/><br/>התוצאה הסופית היא 53"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 29 \\\\ + 44 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["73", "83", "63", "93"],
                hint: "חברו את האחדות 9+4. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 29 \\\\ + 44 \\\\ \\hline 73 \\end{array}$<br/><br/>אחדות: $9 + 4 = 13$, נרשום 3 ונעביר 1<br/>עשרות: $1 + 2 + 4 = 7$<br/><br/>התוצאה הסופית היא 73"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 36 \\\\ + 45 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["81", "71", "91", "61"],
                hint: "התחילו מימין - חברו את האחדות 6+5. אם התוצאה גדולה מ-10, זכרו להעביר עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 36 \\\\ + 45 \\\\ \\hline 81 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 3 + 4 = 8$<br/><br/>התוצאה הסופית היא 81"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 48 \\\\ + 34 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["82", "92", "72", "62"],
                hint: "סדרו את המספרים זה מתחת לזה, יישרו לימין. התחילו מהאחדות וזכרו להעביר עשרת אם צריך",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 48 \\\\ + 34 \\\\ \\hline 82 \\end{array}$<br/><br/>אחדות: $8 + 4 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 4 + 3 = 8$<br/><br/>התוצאה הסופית היא 82"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 19 \\\\ + 43 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["62", "72", "52", "82"],
                hint: "חברו את האחדות 9+3. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 19 \\\\ + 43 \\\\ \\hline 62 \\end{array}$<br/><br/>אחדות: $9 + 3 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 1 + 4 = 6$<br/><br/>התוצאה הסופית היא 62"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 17 \\\\ + 17 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["34", "44", "24", "54"],
                hint: "שימו לב שאתם מחברים את אותו מספר פעמיים. חברו את האחדות 7+7 וזכרו להעביר עשרת אם צריך",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 17 \\\\ + 17 \\\\ \\hline 34 \\end{array}$<br/><br/>אחדות: $7 + 7 = 14$, נרשום 4 ונעביר 1<br/>עשרות: $1 + 1 + 1 = 3$<br/><br/>התוצאה הסופית היא 34"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 46 \\\\ + 35 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["81", "71", "91", "61"],
                hint: "התחילו מימין - חברו את האחדות 6+5. אם התוצאה גדולה מ-10, זכרו להעביר עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 46 \\\\ + 35 \\\\ \\hline 81 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 4 + 3 = 8$<br/><br/>התוצאה הסופית היא 81"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 27 \\\\ + 45 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["72", "82", "62", "92"],
                hint: "סדרו את המספרים כך שהאחדות מיושרות. חברו מימין לשמאל והעבירו עשרת אם צריך",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 27 \\\\ + 45 \\\\ \\hline 72 \\end{array}$<br/><br/>אחדות: $7 + 5 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 2 + 4 = 7$<br/><br/>התוצאה הסופית היא 72"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 38 \\\\ + 44 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["82", "92", "72", "62"],
                hint: "חברו את האחדות 8+4. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 38 \\\\ + 44 \\\\ \\hline 82 \\end{array}$<br/><br/>אחדות: $8 + 4 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 3 + 4 = 8$<br/><br/>התוצאה הסופית היא 82"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 57 \\\\ + 37 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["94", "84", "74", "64"],
                hint: "שימו לב שיש לחבר שני מספרים שמסתיימים ב-7. האם סכומם גדול מ-10?",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 57 \\\\ + 37 \\\\ \\hline 94 \\end{array}$<br/><br/>אחדות: $7 + 7 = 14$, נרשום 4 ונעביר 1<br/>עשרות: $1 + 5 + 3 = 9$<br/><br/>התוצאה הסופית היא 94"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 18 \\\\ + 7 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["25", "35", "15", "45"],
                hint: "סדרו את המספרים זה מתחת לזה, יישרו לימין. שימו לב שהמספר השני הוא חד-ספרתי",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 18 \\\\ + 7 \\\\ \\hline 25 \\end{array}$<br/><br/>אחדות: $8 + 7 = 15$, נרשום 5 ונעביר 1<br/>עשרות: $1 + 1 = 2$<br/><br/>התוצאה הסופית היא 25"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 18 \\\\ + 19 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["37", "47", "27", "57"],
                hint: "חברו את האחדות 8+9. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 18 \\\\ + 19 \\\\ \\hline 37 \\end{array}$<br/><br/>אחדות: $8 + 9 = 17$, נרשום 7 ונעביר 1<br/>עשרות: $1 + 1 + 1 = 3$<br/><br/>התוצאה הסופית היא 37"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 17 \\\\ + 14 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["31", "41", "21", "51"],
                hint: "התחילו מימין - חברו את האחדות 7+4. אם התוצאה גדולה מ-10, זכרו להעביר עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 17 \\\\ + 14 \\\\ \\hline 31 \\end{array}$<br/><br/>אחדות: $7 + 4 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 1 + 1 = 3$<br/><br/>התוצאה הסופית היא 31"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 26 \\\\ + 19 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["45", "55", "35", "65"],
                hint: "חברו את האחדות 6+9. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 26 \\\\ + 19 \\\\ \\hline 45 \\end{array}$<br/><br/>אחדות: $6 + 9 = 15$, נרשום 5 ונעביר 1<br/>עשרות: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 45"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 29 \\\\ + 29 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["58", "68", "48", "78"],
                hint: "שימו לב שאתם מחברים את אותו מספר פעמיים. חברו את האחדות 9+9 וזכרו להעביר עשרת אם צריך",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 29 \\\\ + 29 \\\\ \\hline 58 \\end{array}$<br/><br/>אחדות: $9 + 9 = 18$, נרשום 8 ונעביר 1<br/>עשרות: $1 + 2 + 2 = 5$<br/><br/>התוצאה הסופית היא 58"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 25 \\\\ + 34 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["59", "69", "49", "79"],
                hint: "סדרו את המספרים כך שהאחדות מיושרות. חברו מימין לשמאל",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 25 \\\\ + 34 \\\\ \\hline 59 \\end{array}$<br/><br/>אחדות: $5 + 4 = 9$<br/>עשרות: $2 + 3 = 5$<br/><br/>התוצאה הסופית היא 59"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 16 \\\\ + 25 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["41", "51", "31", "61"],
                hint: "התחילו מימין - חברו את האחדות 6+5. אם התוצאה גדולה מ-10, זכרו להעביר עשרת",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 16 \\\\ + 25 \\\\ \\hline 41 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 1 + 2 = 4$<br/><br/>התוצאה הסופית היא 41"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 47 \\\\ + 43 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["90", "80", "70", "60"],
                hint: "חברו את האחדות 7+3. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 47 \\\\ + 43 \\\\ \\hline 90 \\end{array}$<br/><br/>אחדות: $7 + 3 = 10$, נרשום 0 ונעביר 1<br/>עשרות: $1 + 4 + 4 = 9$<br/><br/>התוצאה הסופית היא 90"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 37 \\\\ + 17 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["54", "64", "44", "74"],
                hint: "שימו לב שיש לחבר שני מספרים שמסתיימים ב-7. האם סכומם גדול מ-10?",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 37 \\\\ + 17 \\\\ \\hline 54 \\end{array}$<br/><br/>אחדות: $7 + 7 = 14$, נרשום 4 ונעביר 1<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 54"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 18 \\\\ + 19 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["37", "47", "27", "57"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 18 \\\\ + 19 \\\\ \\hline 37 \\end{array}$<br/><br/>אחדות: $8 + 9 = 17$, נרשום 7 ונעביר 1<br/>עשרות: $1 + 1 + 1 = 3$<br/><br/>התוצאה הסופית היא 37"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 19 \\\\ + 19 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["38", "48", "28", "58"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 19 \\\\ + 19 \\\\ \\hline 38 \\end{array}$<br/><br/>אחדות: $9 + 9 = 18$, נרשום 8 ונעביר 1<br/>עשרות: $1 + 1 + 1 = 3$<br/><br/>התוצאה הסופית היא 38"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 26 \\\\ + 23 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["49", "59", "39", "69"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 26 \\\\ + 23 \\\\ \\hline 49 \\end{array}$<br/><br/>אחדות: $6 + 3 = 9$<br/>עשרות: $2 + 2 = 4$<br/><br/>התוצאה הסופית היא 49"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 27 \\\\ + 34 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["61", "71", "51", "81"],
                hint:"",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 27 \\\\ + 34 \\\\ \\hline 61 \\end{array}$<br/><br/>אחדות: $7 + 4 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 2 + 3 = 6$<br/><br/>התוצאה הסופית היא 61"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 38 \\\\ + 44 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["82", "92", "72", "62"],
                hint: "חברו את האחדות 8+4. אם התוצאה גדולה מ-10, רשמו את האחדות והעבירו 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 38 \\\\ + 44 \\\\ \\hline 82 \\end{array}$<br/><br/>אחדות: $8 + 4 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 3 + 4 = 8$<br/><br/>התוצאה הסופית היא 82"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 57 \\\\ + 37 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["94", "84", "74", "64"],
                hint: "שימו לב שיש לחבר שני מספרים שמסתיימים ב-7. האם סכומם גדול מ-10?",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 57 \\\\ + 37 \\\\ \\hline 94 \\end{array}$<br/><br/>אחדות: $7 + 7 = 14$, נרשום 4 ונעביר 1<br/>עשרות: $1 + 5 + 3 = 9$<br/><br/>התוצאה הסופית היא 94"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 36 \\\\ + 46 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["82", "92", "72", "62"],
                hint: "שימו לב שבאחדות יש המרה: 6 + 6 = 12",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 36 \\\\ + 46 \\\\ \\hline 82 \\end{array}$<br/><br/>אחדות: $6 + 6 = 12$, נרשום 2 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 4 = 8$<br/><br/>התוצאה הסופית היא 82"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 45 \\\\ + 28 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["73", "83", "63", "93"],
                hint: "חברו את האחדות (5 + 8 = 13) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 45 \\\\ + 28 \\\\ \\hline 73 \\end{array}$<br/><br/>אחדות: $5 + 8 = 13$, נרשום 3 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 2 = 7$<br/><br/>התוצאה הסופית היא 73"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 56 \\\\ + 35 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["91", "81", "71", "61"],
                hint: "שימו לב שבאחדות יש המרה: 6 + 5 = 11",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 56 \\\\ + 35 \\\\ \\hline 91 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1 לעשרות<br/>עשרות: $1 + 5 + 3 = 9$<br/><br/>התוצאה הסופית היא 91"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 37 \\\\ + 27 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["64", "74", "54", "84"],
                hint: "חברו את האחדות (7 + 7 = 14) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 37 \\\\ + 27 \\\\ \\hline 64 \\end{array}$<br/><br/>אחדות: $7 + 7 = 14$, נרשום 4 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 2 = 6$<br/><br/>התוצאה הסופית היא 64"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 38 \\\\ + 27 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["65", "75", "55", "85"],
                hint: "שימו לב שבאחדות יש המרה: 8 + 7 = 15",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 38 \\\\ + 27 \\\\ \\hline 65 \\end{array}$<br/><br/>אחדות: $8 + 7 = 15$, נרשום 5 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 2 = 6$<br/><br/>התוצאה הסופית היא 65"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 24 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["39", "49", "29", "59"],
                hint: "חברו את האחדות (4 + 5 = 9) - אין צורך בהמרה כאן",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 24 \\\\ + 15 \\\\ \\hline 39 \\end{array}$<br/><br/>אחדות: $4 + 5 = 9$<br/>עשרות: $2 + 1 = 3$<br/><br/>התוצאה הסופית היא 39"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 54 \\\\ + 45 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["99", "89", "79", "69"],
                hint: "חברו את האחדות (4 + 5 = 9) ואז את העשרות (5 + 4 = 9)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 54 \\\\ + 45 \\\\ \\hline 99 \\end{array}$<br/><br/>אחדות: $4 + 5 = 9$<br/>עשרות: $5 + 4 = 9$<br/><br/>התוצאה הסופית היא 99"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 47 \\\\ + 42 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["89", "99", "79", "69"],
                hint: "חברו את האחדות (7 + 2 = 9) ואז את העשרות (4 + 4 = 8)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 47 \\\\ + 42 \\\\ \\hline 89 \\end{array}$<br/><br/>אחדות: $7 + 2 = 9$<br/>עשרות: $4 + 4 = 8$<br/><br/>התוצאה הסופית היא 89"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 49 \\\\ + 39 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["88", "98", "78", "68"],
                hint: "שימו לב שבאחדות יש המרה: 9 + 9 = 18",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 49 \\\\ + 39 \\\\ \\hline 88 \\end{array}$<br/><br/>אחדות: $9 + 9 = 18$, נרשום 8 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 3 = 8$<br/><br/>התוצאה הסופית היא 88"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 45 \\\\ + 33 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["78", "88", "68", "98"],
                hint: "חברו את האחדות (5 + 3 = 8) ואז את העשרות (4 + 3 = 7)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 45 \\\\ + 33 \\\\ \\hline 78 \\end{array}$<br/><br/>אחדות: $5 + 3 = 8$<br/>עשרות: $4 + 3 = 7$<br/><br/>התוצאה הסופית היא 78"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 46 \\\\ + 33 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["79", "89", "69", "99"],
                hint: "חברו את האחדות (6 + 3 = 9) ואז את העשרות (4 + 3 = 7)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 46 \\\\ + 33 \\\\ \\hline 79 \\end{array}$<br/><br/>אחדות: $6 + 3 = 9$<br/>עשרות: $4 + 3 = 7$<br/><br/>התוצאה הסופית היא 79"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 37 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["52", "62", "42", "72"],
                hint: "שימו לב שבאחדות צריך לבצע המרה: 7 + 5 = 12",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 37 \\\\ + 15 \\\\ \\hline 52 \\end{array}$<br/><br/>אחדות: $7 + 5 = 12$, נרשום 2 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 52"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 26 \\\\ + 27 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["53", "63", "43", "73"],
                hint: "חברו את האחדות (6 + 7 = 13) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 26 \\\\ + 27 \\\\ \\hline 53 \\end{array}$<br/><br/>אחדות: $6 + 7 = 13$, נרשום 3 ונעביר 1 לעשרות<br/>עשרות: $1 + 2 + 2 = 5$<br/><br/>התוצאה הסופית היא 53"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 38 \\\\ + 18 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["56", "66", "46", "76"],
                hint: "שימו לב שבאחדות יש המרה: 8 + 8 = 16",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 38 \\\\ + 18 \\\\ \\hline 56 \\end{array}$<br/><br/>אחדות: $8 + 8 = 16$, נרשום 6 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 56"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 47 \\\\ + 14 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["61", "71", "51", "81"],
                hint: "חברו את האחדות (7 + 4 = 11) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 47 \\\\ + 14 \\\\ \\hline 61 \\end{array}$<br/><br/>אחדות: $7 + 4 = 11$, נרשום 1 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 1 = 6$<br/><br/>התוצאה הסופית היא 61"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 35 \\\\ + 35 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["70", "80", "60", "90"],
                hint: "שימו לב שמחברים את אותו מספר פעמיים ויש המרה באחדות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 35 \\\\ + 35 \\\\ \\hline 70 \\end{array}$<br/><br/>אחדות: $5 + 5 = 10$, נרשום 0 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 3 = 7$<br/><br/>התוצאה הסופית היא 70"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 46 \\\\ + 25 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["71", "81", "61", "91"],
                hint: "חברו את האחדות (6 + 5 = 11) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 46 \\\\ + 25 \\\\ \\hline 71 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 2 = 7$<br/><br/>התוצאה הסופית היא 71"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 48 \\\\ + 26 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["74", "84", "64", "94"],
                hint: "שימו לב שבאחדות יש המרה: 8 + 6 = 14",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 48 \\\\ + 26 \\\\ \\hline 74 \\end{array}$<br/><br/>אחדות: $8 + 6 = 14$, נרשום 4 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 2 = 7$<br/><br/>התוצאה הסופית היא 74"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 57 \\\\ + 24 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["81", "91", "71", "61"],
                hint: "חברו את האחדות (7 + 4 = 11) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 57 \\\\ + 24 \\\\ \\hline 81 \\end{array}$<br/><br/>אחדות: $7 + 4 = 11$, נרשום 1 ונעביר 1 לעשרות<br/>עשרות: $1 + 5 + 2 = 8$<br/><br/>התוצאה הסופית היא 81"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 28 \\\\ + 39 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["67", "77", "57", "87"],
                hint: "שימו לב שבאחדות יש המרה: 8 + 9 = 17",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 28 \\\\ + 39 \\\\ \\hline 67 \\end{array}$<br/><br/>אחדות: $8 + 9 = 17$, נרשום 7 ונעביר 1 לעשרות<br/>עשרות: $1 + 2 + 3 = 6$<br/><br/>התוצאה הסופית היא 67"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 45 \\\\ + 33 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["78", "88", "68", "98"],
                hint: "חברו את האחדות (5 + 3 = 8) ואז את העשרות (4 + 3 = 7)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 45 \\\\ + 33 \\\\ \\hline 78 \\end{array}$<br/><br/>אחדות: $5 + 3 = 8$<br/>עשרות: $4 + 3 = 7$<br/><br/>התוצאה הסופית היא 78"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 46 \\\\ + 33 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["79", "89", "69", "99"],
                hint: "חברו את האחדות (6 + 3 = 9) ואז את העשרות (4 + 3 = 7)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 46 \\\\ + 33 \\\\ \\hline 79 \\end{array}$<br/><br/>אחדות: $6 + 3 = 9$<br/>עשרות: $4 + 3 = 7$<br/><br/>התוצאה הסופית היא 79"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 28 \\\\ + 17 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["45", "55", "35", "65"],
                hint: "חברו את האחדות (8 + 7 = 15) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 28 \\\\ + 17 \\\\ \\hline 45 \\end{array}$<br/><br/>אחדות: $8 + 7 = 15$, נרשום 5 ונעביר 1 לעשרות<br/>עשרות: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 45"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 29 \\\\ + 17 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["46", "56", "36", "66"],
                hint: "שימו לב שבאחדות יש המרה: 9 + 7 = 16",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 29 \\\\ + 17 \\\\ \\hline 46 \\end{array}$<br/><br/>אחדות: $9 + 7 = 16$, נרשום 6 ונעביר 1 לעשרות<br/>עשרות: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 46"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 29 \\\\ + 18 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["47", "57", "37", "67"],
                hint: "חברו את האחדות (9 + 8 = 17) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 29 \\\\ + 18 \\\\ \\hline 47 \\end{array}$<br/><br/>אחדות: $9 + 8 = 17$, נרשום 7 ונעביר 1 לעשרות<br/>עשרות: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 47"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 29 \\\\ + 19 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["48", "58", "38", "68"],
                hint: "שימו לב שבאחדות יש המרה: 9 + 9 = 18",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 29 \\\\ + 19 \\\\ \\hline 48 \\end{array}$<br/><br/>אחדות: $9 + 9 = 18$, נרשום 8 ונעביר 1 לעשרות<br/>עשרות: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 48"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 36 \\\\ + 13 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["49", "59", "39", "69"],
                hint: "חברו את האחדות (6 + 3 = 9) ואז את העשרות (3 + 1 = 4)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 36 \\\\ + 13 \\\\ \\hline 49 \\end{array}$<br/><br/>אחדות: $6 + 3 = 9$<br/>עשרות: $3 + 1 = 4$<br/><br/>התוצאה הסופית היא 49"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 35 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["50", "60", "40", "70"],
                hint: "חברו את האחדות (5 + 5 = 10) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 35 \\\\ + 15 \\\\ \\hline 50 \\end{array}$<br/><br/>אחדות: $5 + 5 = 10$, נרשום 0 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 50"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 36 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["51", "61", "41", "71"],
                hint: "חברו את האחדות (6 + 5 = 11) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 36 \\\\ + 15 \\\\ \\hline 51 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 51"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 37 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["52", "62", "42", "72"],
                hint: "שימו לב שבאחדות יש המרה: 7 + 5 = 12",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 37 \\\\ + 15 \\\\ \\hline 52 \\end{array}$<br/><br/>אחדות: $7 + 5 = 12$, נרשום 2 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 52"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 38 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["53", "63", "43", "73"],
                hint: "חברו את האחדות (8 + 5 = 13) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 38 \\\\ + 15 \\\\ \\hline 53 \\end{array}$<br/><br/>אחדות: $8 + 5 = 13$, נרשום 3 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 53"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 39 \\\\ + 15 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["54", "64", "44", "74"],
                hint: "שימו לב שבאחדות יש המרה: 9 + 5 = 14",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 39 \\\\ + 15 \\\\ \\hline 54 \\end{array}$<br/><br/>אחדות: $9 + 5 = 14$, נרשום 4 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 54"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 37 \\\\ + 18 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["55", "65", "45", "75"],
                hint: "חברו את האחדות (7 + 8 = 15) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 37 \\\\ + 18 \\\\ \\hline 55 \\end{array}$<br/><br/>אחדות: $7 + 8 = 15$, נרשום 5 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 55"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 38 \\\\ + 18 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["56", "66", "46", "76"],
                hint: "שימו לב שבאחדות יש המרה: 8 + 8 = 16",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 38 \\\\ + 18 \\\\ \\hline 56 \\end{array}$<br/><br/>אחדות: $8 + 8 = 16$, נרשום 6 ונעביר 1 לעשרות<br/>עשרות: $1 + 3 + 1 = 5$<br/><br/>התוצאה הסופית היא 56"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 48 \\\\ + 17 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["65", "75", "55", "85"],
                hint: "חברו את האחדות (8 + 7 = 15) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 48 \\\\ + 17 \\\\ \\hline 65 \\end{array}$<br/><br/>אחדות: $8 + 7 = 15$, נרשום 5 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 1 = 6$<br/><br/>התוצאה הסופית היא 65"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 49 \\\\ + 17 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["66", "76", "56", "86"],
                hint: "שימו לב שבאחדות יש המרה: 9 + 7 = 16",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 49 \\\\ + 17 \\\\ \\hline 66 \\end{array}$<br/><br/>אחדות: $9 + 7 = 16$, נרשום 6 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 1 = 6$<br/><br/>התוצאה הסופית היא 66"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 49 \\\\ + 18 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["67", "77", "57", "87"],
                hint: "חברו את האחדות (9 + 8 = 17) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 49 \\\\ + 18 \\\\ \\hline 67 \\end{array}$<br/><br/>אחדות: $9 + 8 = 17$, נרשום 7 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 1 = 6$<br/><br/>התוצאה הסופית היא 67"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 49 \\\\ + 19 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["68", "78", "58", "88"],
                hint: "שימו לב שבאחדות יש המרה: 9 + 9 = 18",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 49 \\\\ + 19 \\\\ \\hline 68 \\end{array}$<br/><br/>אחדות: $9 + 9 = 18$, נרשום 8 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 1 = 6$<br/><br/>התוצאה הסופית היא 68"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 45 \\\\ + 24 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["69", "79", "59", "89"],
                hint: "חברו את האחדות (5 + 4 = 9) ואז את העשרות (4 + 2 = 6)",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 45 \\\\ + 24 \\\\ \\hline 69 \\end{array}$<br/><br/>אחדות: $5 + 4 = 9$<br/>עשרות: $4 + 2 = 6$<br/><br/>התוצאה הסופית היא 69"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 45 \\\\ + 25 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["70", "80", "60", "90"],
                hint: "חברו את האחדות (5 + 5 = 10) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 45 \\\\ + 25 \\\\ \\hline 70 \\end{array}$<br/><br/>אחדות: $5 + 5 = 10$, נרשום 0 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 2 = 7$<br/><br/>התוצאה הסופית היא 70"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 46 \\\\ + 25 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["71", "81", "61", "91"],
                hint: "חברו את האחדות (6 + 5 = 11) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 46 \\\\ + 25 \\\\ \\hline 71 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 2 = 7$<br/><br/>התוצאה הסופית היא 71"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 47 \\\\ + 25 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["72", "82", "62", "92"],
                hint: "שימו לב שבאחדות יש המרה: 7 + 5 = 12",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 47 \\\\ + 25 \\\\ \\hline 72 \\end{array}$<br/><br/>אחדות: $7 + 5 = 12$, נרשום 2 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 2 = 7$<br/><br/>התוצאה הסופית היא 72"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 48 \\\\ + 25 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["73", "83", "63", "93"],
                hint: "חברו את האחדות (8 + 5 = 13) - צריך לבצע המרה",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 48 \\\\ + 25 \\\\ \\hline 73 \\end{array}$<br/><br/>אחדות: $8 + 5 = 13$, נרשום 3 ונעביר 1 לעשרות<br/>עשרות: $1 + 4 + 2 = 7$<br/><br/>התוצאה הסופית היא 73"
            }
        ]
    }
}; 