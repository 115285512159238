export const learningContent = {
    "חילוק עד 10": {
        tags: ['כיתה ב׳', 'כיתה ג׳', 'חילוק'],
        relatedLinks: [
            { title: "תרגול חילוק", url: "/?tags=חילוק", description: "תרגול נוסף בחילוק" },
            { title: "בעיות מילוליות בחילוק", url: "/?tags=חילוק,בעיות%20מילוליות", description: "תרגול בעיות מילוליות עם חילוק" },
            { title: "כפל וחילוק", url: "/?tags=כפל,חילוק", description: "תרגול משולב של כפל וחילוק" }
        ],
        getStarted: [
            "**מה זה חילוק?**",
            "חילוק הוא כמו לחלק דברים לקבוצות שוות",
            "למשל: $12 ÷ 3$ זה כמו לחלק 12 דברים ל-3 קבוצות שוות",
            "",
            "**דברים חשובים לזכור:**",
            "אי אפשר לחלק ב-0",
            "כשמחלקים מספר באותו מספר מקבלים 1",
            "כשמחלקים ב-1 המספר לא משתנה",
            "חילוק הוא ההפך של כפל!",
            "",
            "**טריקים לחילוק מהיר:**",
            "חילוק ב-2 זה כמו לחלק לזוגות",
            "חילוק ב-10 זה כמו להוריד את האפס האחרון",
            "אם המספרים שווים התשובה היא 1",
            "",
            "**איך לבדוק את התשובה?**",
            "כופלים את התשובה במספר שחילקנו בו",
            "אם קיבלנו את המספר המקורי - הצלחנו!"
        ],
        examples: [
            {
                question: "כמה זה $6 ÷ 2$?",
                solution: "נחלק 6 דברים ל-2 קבוצות שוות:<br/>בכל קבוצה יהיו 3 דברים",
                drawing: {
                    type: "division-groups",
                    total: 6,
                    groups: 2,
                    emoji: "🍎"
                }
            },
            {
                question: "כמה זה $15 ÷ 3$?",
                solution: "נחלק 15 דברים ל-3 קבוצות שוות:<br/>בכל קבוצה יהיו 5 דברים",
                drawing: {
                    type: "division-groups",
                    total: 15,
                    groups: 3,
                    emoji: "🌟"
                }
            },
            {
                question: "כמה זה $20 ÷ 4$?",
                solution: "נחלק 20 דברים ל-4 קבוצות שוות:<br/>בכל קבוצה יהיו 5 דברים",
                drawing: {
                    type: "division-groups",
                    total: 20,
                    groups: 4,
                    emoji: "🎈"
                }
            }
        ],
        quiz: [
            {
                question: "לדרקון יש {Dividend} ביצים והוא רוצה לחלק אותן ל-{B} קינים שווים. כמה ביצים יהיו בכל קן?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [2, 2], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-three", "half"]
                },
                hint: "חילוק ב-2 זה כמו לחלק לזוגות",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ - {B} קבוצות של {A} ביצים בכל קן",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "🥚"
                }
            },
            {
                question: "לפיה יש {Dividend} פרחים והיא רוצה לחלק אותם ל-{B} אגרטלים שווים. כמה פרחים יהיו בכל אגרטל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [3, 9], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-three", "half"]
                },
                hint: "חלקו את הפרחים ל-{B} קבוצות שוות",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ -  {B} קבוצות של {A} פרחים בכל אגרטל",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "🌸"
                }
            },
            {
                question: "לרובוט יש {Dividend} ברגים והוא רוצה לחלק אותם ל-{B} קופסאות שוות. כמה ברגים יהיו בכל קופסה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [3, 9], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "half"]
                },
                hint: "חלקו את הברגים ל-{B} קבוצות שוות",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ -  {B} קבוצות של {A} ברגים בכל קופסה",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "🔩"
                }
            },
            {
                question: "לחד-קרן יש {Dividend} קשתות והוא רוצה לחלק אותן ל-{B} חברים שווה בשווה. כמה קשתות יקבל כל חבר?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [3, 9], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "חלקו את הקשתות ל-{B} קבוצות שוות",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ -  {B} קבוצות של {A} קשתות לכל חבר",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "🌈"
                }
            },
            {
                question: "לנינג'ה יש {Dividend} כוכבי נינג'ה והיא רוצה לחלק אותם ל-{B} כיסים שווים. כמה כוכבים יהיו בכל כיס?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [3, 9], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "חלקו את הכוכבים ל-{B} קבוצות שוות",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ -  {B} קבוצות של {A} כוכבים בכל כיס",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "⭐"
                }
            },
            {
                question: "לקוסם יש {Dividend} שרביטים והוא רוצה לחלק אותם ל-{B} קופסאות קסם שוות. כמה שרביטים יהיו בכל קופסה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [3, 9], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "חלקו את השרביטים ל-{B} קבוצות שוות",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ -  {B} קבוצות של {A} שרביטים בכל קופסה",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "✨"
                }
            },
            {
                question: "לפינגווין יש {Dividend} דגים והוא רוצה לחלק אותם ל-{B} ימים שווים. כמה דגים יאכל כל יום?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [3, 9], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "חלקו את הדגים ל-{B} קבוצות שוות",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ -  {B} קבוצות של {A} דגים לכל יום",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "🐟"
                }
            },
            {
                question: "לדינוזאור יש {Dividend} עוגיות והוא רוצה לחלק אותן ל-{B} חברים שווה בשווה. כמה עוגיות יקבל כל חבר?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [3, 9], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "half"]
                },
                hint: "חלקו את העוגיות ל-{B} קבוצות שוות",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ - {B} קבוצות של {A} עוגיות לכל חבר",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "🍪"
                }
            },
            {
                question: "לגמד יש {Dividend} יהלומים והוא רוצה לחלק אותם ל-{B} תיבות אוצר שוות. כמה יהלומים יהיו בכל תיבה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [10, 10], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{B}"
                },
                formula: "A",
                distractors: {
                    strategies: ["off-by-one", "off-by-three", "half"]
                },
                hint: "חילוק ב-10 זה כמו להוריד את האפס האחרון",
                solutionGuide: "$ {Dividend} ÷ {B} = {A} $ - {B} קבוצות של {A} יהלומים בכל תיבה",
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{B}",
                    emoji: "💎"
                }
            },
            {
                question: "האם {Dividend} סוכריות אפשר לחלק ל-{C} ילדים שווה בשווה?",
                type: "multiChoiceRandomBoolean",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [0, "A"], type: "integer" },
                    C: { range: [2, 10], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{C}+{B}"
                },
                formula: "mod(A*C+B,C)",
                distractors: ["רק אם הסוכריות צבעוניות", "תלוי אם יש מספיק שמש"],
                hint: "נסו לחלק את הסוכריות לקבוצות שוות",
                solutiontemplate: {
                    true: "$ {Dividend} ÷ {C} = {A} $ - כן! כל ילד יקבל {A} סוכריות בדיוק",
                    false: "$ {Dividend} ÷ {C} = {A} $ עם שארית {B} - לא! נשארות {B} סוכריות שאי אפשר לחלק"
                },
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{C}",
                    emoji: "🍬"
                }
            },
            {
                question: "האם $ {Dividend} $ כדורים אפשר לסדר ב-{C} שורות שוות?",
                type: "multiChoiceRandomBoolean",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [0, "A"], type: "integer" },
                    C: { range: [2, 10], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{C}+{B}"
                },
                formula: "mod(A*C+B,C)",
                distractors: ["רק אם הכדורים עגולים", "תלוי אם הם קופצים"],
                hint: "נסו לחלק את הכדורים לקבוצות שוות",
                solutiontemplate: {
                    true: "$ {Dividend} ÷ {C} = {A} $ - כן! {A} כדורים בכל שורה בדיוק",
                    false: "$ {Dividend} ÷ {C} = {A} $ עם שארית {B} - לא! נשארת שורה עם {B} כדורים"
                },
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{C}",
                    emoji: "⚽"
                }
            },
            {
                question: "האם {Dividend} בלונים אפשר לחלק ל-{C} ילדים בצורה שווה?",
                type: "multiChoiceRandomBoolean",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [0, "A"], type: "integer" },
                    C: { range: [2, 10], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{C}+{B}"
                },
                formula: "mod(A*C+B,C)",
                distractors: ["רק אם הבלונים מתנפחים", "תלוי אם יש מסיבה"],
                hint: "נסו לחלק את הבלונים לקבוצות שוות",
                solutiontemplate: {
                    true: "$ {Dividend} ÷ {C} = {A} $ - כן! כל ילד יקבל {A} בלונים בדיוק",
                    false: "$ {Dividend} ÷ {C} = {A} $ עם שארית {B} - לא! נשארים {B} בלונים שאי אפשר לחלק"
                },
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{C}",
                    emoji: "🎈"
                }
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["$30 ÷ 5$", "$16 ÷ 2$", "$27 ÷ 3$", "$20 ÷ 2$"],
                hint: "פתרו כל תרגיל בנפרד וסדרו את התוצאות",
                solutionGuide: "$30 ÷ 5 = 6$ <br/> $16 ÷ 2 = 8$ <br/> $27 ÷ 3 = 9$ <br/> $20 ÷ 2 = 10$ "
            },
            {
                question: "סדרו את התרגילים מהקטן לגדול לפי התוצאה",
                type: "order",
                items: ["$35 ÷ 5$", "$32 ÷ 4$", "$27 ÷ 3$", "$50 ÷ 5$"],
                hint: "פתרו כל תרגיל בנפרד וסדרו את התוצאות",
                solutionGuide: "$35 ÷ 5 = 7$ <br/> $32 ÷ 4 = 8$ <br/> $27 ÷ 3 = 9$ <br/> $50 ÷ 5 = 10$ "
            },
            {
                question: "לדינוזאור יש {Dividend} ביצים והוא רוצה לסדר אותן ב-{C} קערות. האם אפשר לחלק את הביצים בצורה שווה?",
                type: "multiChoiceRandomBoolean",
                params: {
                    A: { range: [1, 10], type: "integer" },
                    B: { range: [0, "A"], type: "integer" },
                    C: { range: [2, 10], type: "integer" }
                },
                metadata: {
                    Dividend: "{A}*{C}+{B}"
                },
                formula: "mod(A*C+B,C)",
                distractors: [
                    "לא, כי כל הביצים נשברו לו על הראש 🍳",
                    "אולי, אם הדינוזאור לא יאכל אותן קודם 🦖"
                ],
                hint: "נסו לחלק את הביצים לקערות שוות, בלי לשבור אותן...",
                solutiontemplate: {
                    true: "$ {Dividend} ÷ {C} = {A} $ - כן! בכל קערה יהיו {A} ביצים",
                    false: "$ {Dividend} ÷ {C} = {A} $ עם שארית {B} - לא! נשארות {B} ביצים שהדינוזאור יצטרך לשמור בצד"
                },
                drawing: {
                    type: "division-groups",
                    total: "{Dividend}",
                    groups: "{C}",
                    emoji: "🥚"
                }
            }
        ]
    }
};