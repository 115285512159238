export const learningContent = {
    "מבוא לשברים": {
        tags: ['כיתה ב׳', 'כיתה ג׳', 'שברים'],
        relatedLinks: [
            {
                title: "תרגול שברים",
                url: "/?tags=שברים",
                description: "תרגול שברים בסיסי"
            },
            {
                title: "בעיות מילוליות בשברים",
                url: "/?tags=שברים,בעיות%20מילוליות",
                description: "תרגול בעיות מילוליות עם שברים"
            },
            {
                title: "שברים ומספרים עשרוניים",
                url: "/?tags=שברים,מספרים%20עשרוניים",
                description: "תרגול משולב של שברים ומספרים עשרוניים"
            }
        ],
        getStarted: [
            "**מה זה שבר?**",
            "שבר הוא חלק מדבר שלם",
            "למשל: אם מחלקים עוגה ל-2 חלקים שווים, כל חלק הוא חצי ($\\frac{1}{2}$) מהעוגה",
            "",
            "**איך כותבים שבר?**",
            "<b>מונה:</b> המספר שלמעלה - כמה חלקים לקחנו",
            "<b>מכנה:</b> המספר שלמטה - לכמה חלקים שווים חילקנו את השלם",
            "<b>קו השבר:</b> הקו שמפריד בין המונה למכנה",
            "",
            "**שברים פשוטים שחשוב להכיר:**",
            "חצי = $\\frac{1}{2}$ - חלק אחד מתוך שניים",
            "רבע = $\\frac{1}{4}$ - חלק אחד מתוך ארבעה",
            "שליש = $\\frac{1}{3}$ - חלק אחד מתוך שלושה",
            "",
            "**כללים חשובים:**",
            "1. החלקים חייבים להיות שווים",
            "2. המכנה לא יכול להיות 0",
            "3. המכנה תמיד למטה והמונה למעלה",
            "",
            "**דוגמאות מהחיים:**",
            "- חצי פיצה",
            "- רבע שעה",
            "- שליש כוס חלב",
            "- חצי לחמנייה"
        ],
        examples: [
            {
                question: "מהו חצי מהעיגול?",
                solution: "חצי הוא חלק אחד מתוך שניים שווים<br/>כלומר $\\frac{1}{2}$ מהעיגול",
                drawing: {
                    type: "circle",
                    divisions: 2,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "מהו רבע מהמלבן?",
                solution: "רבע הוא חלק אחד מתוך ארבעה חלקים שווים<br/>כלומר $\\frac{1}{4}$ מהמלבן",
                drawing: {
                    type: "rectangleGrid",
                    rows: 2,
                    cols: 2,
                    highlightCells: 1,
                    
                }
            },
            {
                question: "מהו שליש מהפס?",
                solution: "שליש הוא חלק אחד מתוך שלושה חלקים שווים<br/>כלומר $\\frac{1}{3}$ מהפס",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 3,
                    highlights: {
                        first: [0]
                    },
                    
                }
            },
            {
                question: "מהו חמישית מהעיגול?",
                solution: "חמישית היא חלק אחד מתוך חמישה חלקים שווים<br/>כלומר $\\frac{1}{5}$ מהעיגול",
                drawing: {
                    type: "circle",
                    divisions: 5,
                    highlightSections: [1],
                    
                }
            }
        ],
        quiz: [
            {
                question: "איזה חלק מהעיגול צבוע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{1}{3}$", "$\\frac{1}{4}$", "$\\frac{2}{2}$"],
                hint: "ספרו לכמה חלקים שווים העיגול מחולק וכמה חלקים צבועים",
                solutionGuide: "העיגול מחולק ל-2 חלקים שווים וחלק אחד צבוע, לכן זה $\\frac{1}{2}$",
                drawing: {
                    type: "circle",
                    divisions: 2,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "איזה חלק מהמלבן צבוע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{1}{2}$", "$\\frac{1}{3}$", "$\\frac{3}{4}$"],
                hint: "ספרו לכמה חלקים שווים המלבן מחולק וכמה חלקים צבועים",
                solutionGuide: "המלבן מחולק ל-4 חלקים שווים וחלק אחד צבוע, לכן זה $\\frac{1}{4}$",
                drawing: {
                    type: "rectangleGrid",
                    rows: 2,
                    cols: 2,
                    highlightCells: 1,
                    
                }
            },
            {
                question: "איזה שבר מתאר חצי?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{2}{1}$", "$\\frac{1}{3}$", "$\\frac{2}{2}$"],
                hint: "חצי הוא חלק אחד מתוך שניים",
                solutionGuide: "חצי הוא חלק אחד מתוך שניים, לכן כותבים $\\frac{1}{2}$",
                drawing: {
                    type: "circle",
                    divisions: 2,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "איזה שבר מתאר רבע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{4}{1}$", "$\\frac{1}{2}$", "$\\frac{4}{4}$"],
                hint: "רבע הוא חלק אחד מתוך ארבעה",
                solutionGuide: "רבע הוא חלק אחד מתוך ארבעה, לכן כותבים $\\frac{1}{4}$",
                drawing: {
                    type: "circle",
                    divisions: 4,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "איזה שבר מתאר שליש?",
                type: "multiplechoice",
                options: ["$\\frac{1}{3}$", "$\\frac{3}{1}$", "$\\frac{1}{2}$", "$\\frac{2}{3}$"],
                hint: "שליש הוא חלק אחד מתוך שלושה",
                solutionGuide: "שליש הוא חלק אחד מתוך שלושה, לכן כותבים $\\frac{1}{3}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 3,
                    highlights: {
                        first: [0],
                        
                    },
                    
                }
            },
            {
                question: "איזה חלק מהפיצה נשאר?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{2}{2}$", "$\\frac{1}{4}$", "$\\frac{3}{4}$"],
                hint: "הפיצה מחולקת לשני חלקים שווים וחלק אחד נאכל",
                solutionGuide: "נשאר חלק אחד מתוך שניים, לכן נשאר $\\frac{1}{2}$",
                drawing: {
                    type: "circle",
                    divisions: 2,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "איזה חלק מהעוגה צבוע בכחול?",
                type: "multiplechoice",
                options: ["$\\frac{1}{3}$", "$\\frac{2}{3}$", "$\\frac{1}{2}$", "$\\frac{1}{4}$"],
                hint: "העוגה מחולקת לשלושה חלקים שווים וחלק אחד צבוע בכחול",
                solutionGuide: "חלק אחד מתוך שלושה צבוע בכחול, לכן זה $\\frac{1}{3}$",
                drawing: {
                    type: "circle",
                    divisions: 3,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "איזה חלק מהשעה זה 15 דקות?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{1}{2}$", "$\\frac{1}{3}$", "$\\frac{3}{4}$"],
                hint: "שעה היא 60 דקות, 15 דקות הן רבע שעה",
                solutionGuide: "15 דקות הן חלק אחד מתוך ארבעה חלקים של שעה, לכן זה $\\frac{1}{4}$",
                drawing: {
                    type: "circle",
                    divisions: 4,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "איזה חלק צבוע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{1}{3}$", "$\\frac{2}{3}$", "$\\frac{1}{4}$"],
                hint: "המלבן מחולק לשני חלקים שווים וחלק אחד צבוע",
                solutionGuide: "חלק אחד מתוך שניים מלא, לכן זה $\\frac{1}{2}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 2,
                    highlights: {
                        first: [0],
                        
                    },
                    
                }
            },
            {
                question: "איזה חלק צבוע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{1}{2}$", "$\\frac{5}{4}$", "$\\frac{3}{4}$"],
                hint: "המלבן מחולק לארבעה חלקים שווים וחלק אחד צבוע",
                solutionGuide: "חלק אחד מתוך ארבעה צבוע, לכן זה $\\frac{1}{4}$",
                drawing: {
                    type: "rectangleGrid",
                    rows: 2,
                    cols: 2,
                    highlightCells: 1,
                    
                }
            },
            {
                question: "איזה שבר מתאר את החלק הסגול מתוך השלם?",
                type: "multiplechoice",
                options: ["$\\frac{2}{3}$", "$\\frac{1}{3}$", "$\\frac{1}{2}$", "$\\frac{3}{3}$"],
                hint: "השוקולד מחולק לשלושה חלקים שווים ואכלת חלק אחד",
                solutionGuide: "נשארו שני חלקים מתוך שלושה, לכן זה $\\frac{2}{3}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 3,
                    highlights: {
                        result: [1,2]
                    },
                    
                }
            },
            {
                question: "איזה חלק מהדף צבוע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{2}{2}$", "$\\frac{1}{4}$", "$\\frac{1}{3}$"],
                hint: "הדף מחולק לשני חלקים שווים וחלק אחד צבוע",
                solutionGuide: "חלק אחד מתוך שניים צבוע, לכן זה $\\frac{1}{2}$",
                drawing: {
                    type: "rectangleGrid",
                    rows: 1,
                    cols: 2,
                    highlightCells: 1,
                    
                }
            },
            {
                question: "איזה חלק צבוע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{3}$", "$\\frac{2}{3}$", "$\\frac{1}{2}$", "$\\frac{3}{3}$"],
                hint: "המלבן מחולק לשלושה חלקים שווים וחלק אחד צבוע",
                solutionGuide: "חלק אחד מתוך שלושה צבוע, לכן זה $\\frac{1}{3}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 3,
                    highlights: {
                        first: [0],
                        
                    },
                    
                }
            },
            {
                question: "איזה חלק צבוע?",
                type: "multiplechoice",
                options: ["$\\frac{1}{5}$", "$\\frac{2}{5}$", "$\\frac{1}{3}$", "$\\frac{1}{4}$"],
                hint: "המלבן מחולק לחמישה חלקים שווים וחלק אחד צבוע",
                solutionGuide: "חלק אחד מתוך חמישה צבוע, לכן זה $\\frac{1}{5}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 5,
                    highlights: {
                        first: [0],
                        
                    },
                    
                }
            },
            {
                question: "איזה חלק מהפרי נשאר?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{1}{4}$", "$\\frac{2}{2}$", "$\\frac{1}{3}$"],
                hint: "הפרי חולק לשני חלקים שווים ואכלת חלק אחד",
                solutionGuide: "נשאר חלק אחד מתוך שניים, לכן זה $\\frac{1}{2}$",
                drawing: {
                    type: "circle",
                    divisions: 2,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "איזה חלק מהצלחת מלא?",
                type: "multiplechoice",
                options: ["$\\frac{1}{3}$", "$\\frac{2}{3}$", "$\\frac{1}{2}$", "$\\frac{3}{3}$"],
                hint: "הצלחת מחולקת לשלושה חלקים שווים וחלק אחד מלא",
                solutionGuide: "חלק אחד מתוך שלושה מלא, לכן זה $\\frac{1}{3}$",
                drawing: {
                    type: "circle",
                    divisions: 3,
                    highlightSections: [1],
                    
                }
            },
            {
                question: "איזה חלק מהדרך עברת?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{4}$", "$\\frac{4}{4}$"],
                hint: "הדרך מחולקת לארבעה חלקים שווים ועברת חלק אחד",
                solutionGuide: "עברת חלק אחד מתוך ארבעה, לכן זה $\\frac{1}{4}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 4,
                    highlights: {
                        first: [0]
                    },
                    
                }
            },
            {
                question: "איזה חלק מהמשחק סיימת?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{2}{2}$", "$\\frac{1}{4}$", "$\\frac{1}{3}$"],
                hint: "המשחק מחולק לשני חלקים שווים וסיימת חלק אחד",
                solutionGuide: "סיימת חלק אחד מתוך שניים, לכן זה $\\frac{1}{2}$",
                drawing: {
                    type: "rectangleGrid",
                    rows: 1,
                    cols: 2,
                    highlightCells: 1,
                    
                }
            },
            {
                question: "איזה חלק מהחדר ניקית?",
                type: "multiplechoice",
                options: ["$\\frac{1}{3}$", "$\\frac{2}{3}$", "$\\frac{1}{2}$", "$\\frac{3}{3}$"],
                hint: "החדר מחולק לשלושה חלקים שווים וניקית חלק אחד",
                solutionGuide: "ניקית חלק אחד מתוך שלושה, לכן זה $\\frac{1}{3}$",
                drawing: {
                    type: "rectangleGrid",
                    rows: 1,
                    cols: 3,
                    highlightCells: 1,
                    
                }
            },
            {
                question: "איזה חלק מהמים שתית?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{4}$", "$\\frac{4}{4}$"],
                hint: "הבקבוק מחולק לארבעה חלקים שווים ושתית חלק אחד",
                solutionGuide: "שתית חלק אחד מתוך ארבעה, לכן זה $\\frac{1}{4}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 4,
                    highlights: {
                        first: [0]
                    },
                    
                }
            },
            {
                question: "איזה חלק מהעיגול צבעת?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{2}{2}$", "$\\frac{1}{4}$", "$\\frac{1}{3}$"],
                hint: "הציור מחולק לשני חלקים שווים וצבעת חלק אחד",
                solutionGuide: "צבעת חלק אחד מתוך שניים, לכן זה $\\frac{1}{2}$",
                drawing: {
                    type: "circle",
                    divisions: 2,
                    highlightSections: [1],
                }
            },
            {
                question: "התאימו כל שבר לשם שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "חצי" },
                    { left: "$\\frac{1}{4}$", right: "רבע" },
                    { left: "$\\frac{1}{3}$", right: "שליש" },
                    { left: "$1$", right: "שלם" }
                ],
                hint: "חשבו על השמות המוכרים של השברים הפשוטים",
                solutionGuide: "חצי = $\\frac{1}{2}$, רבע = $\\frac{1}{4}$, שליש = $\\frac{1}{3}$, שלם = $\\frac{2}{2}$"
            },
            {
                question: "התאימו כל שבר לציור המתאים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "💙🤍" },
                    { left: "$\\frac{1}{3}$", right: "💙🤍🤍" },
                    { left: "$\\frac{1}{4}$", right: "💙🤍🤍🤍" },
                    { left: "$\\frac{4}{4}$", right: "💙💙💙💙" }
                ],
                hint: "ספרו את החלקים הצבועים והלא צבועים",
                solutionGuide: "💙 מסמל חלק צבוע, 🤍 מסמל חלק לא צבוע"
            },
            {
                question: "התאימו כל שבר למצב היומיומי",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "חצי כוס מים" },
                    { left: "$\\frac{1}{4}$", right: "רבע שעה" },
                    { left: "$\\frac{1}{3}$", right: "שליש עוגה" },
                    { left: "$1$", right: "כל הפיצה" }
                ],
                hint: "חשבו על דוגמאות מהחיים היומיום",
                solutionGuide: "חיבור בין שברים למצבים מוכרים מהיומיום"
            },
            {
                question: "התאימו כל שבר לכמות הצבועה",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "🟦⬜" },
                    { left: "$\\frac{1}{3}$", right: "🟦⬜⬜" },
                    { left: "$\\frac{1}{4}$", right: "🟦⬜⬜⬜" },
                    { left: "$\\frac{2}{2}$", right: "🟦🟦" }
                ],
                hint: "ספרו כמה חלקים צבועים מתוך הכל",
                solutionGuide: "🟦 מסמל חלק צבוע, ⬜ מסמל חלק לא צבוע"
            },
            {
                question: "התאימו כל שבר לתיאור המתאים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "חלק אחד מתוך שניים" },
                    { left: "$\\frac{1}{3}$", right: "חלק אחד מתוך שלושה" },
                    { left: "$\\frac{1}{4}$", right: "חלק אחד מתוך ארבעה" },
                    { left: "$\\frac{2}{2}$", right: "שני חלקים מתוך שניים" }
                ],
                hint: "קראו את התיאור המילולי של כל שבר",
                solutionGuide: "חיבור בין השבר לתיאור המילולי שלו"
            },
            {
                question: "התאימו כל שבר לזמן המתאים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$ שעה", right: "30 דקות" },
                    { left: "$\\frac{1}{4}$ שעה", right: "15 דקות" },
                    { left: "$\\frac{1}{3}$ שעה", right: "20 דקות" },
                    { left: "$1$ שעה", right: "60 דקות" }
                ],
                hint: "חשבו כמה דקות יש בכל חלק של השעה",
                solutionGuide: "המרת שברי שעה לדקות"
            },
            {
                question: "התאימו כל שבר לכמות העיגולים הצבועים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "⚫⚪" },
                    { left: "$\\frac{1}{3}$", right: "⚫⚪⚪" },
                    { left: "$\\frac{1}{4}$", right: "⚫⚪⚪⚪" },
                    { left: "$\\frac{2}{2}$", right: "⚫⚫" }
                ],
                hint: "ספרו את העיגולים הצבועים והלא צבועים",
                solutionGuide: "⚫ מסמל עיגול צבוע, ⚪ מסמל עיגול לא צבוע"
            },
            {
                question: "התאימו כל שבר לחלק המתאים בעוגה",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$ עוגה", right: "חתיכה אחת מעוגה חתוכה לשניים" },
                    { left: "$\\frac{1}{4}$ עוגה", right: "חתיכה אחת מעוגה חתוכה לארבע" },
                    { left: "$\\frac{1}{3}$ עוגה", right: "חתיכה אחת מעוגה חתוכה לשלוש" },
                    { left: "$1$ עוגה", right: "כל העוגה" }
                ],
                hint: "חשבו כמה חתיכות יש בכל חלוקה של העוגה",
                solutionGuide: "חיבור בין השבר לחלק המתאים בעוגה"
            },
            {
                question: "התאימו כל שבר למספר הכוכבים הצבועים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "💚🤍" },
                    { left: "$\\frac{1}{3}$", right: "💚🤍🤍" },
                    { left: "$\\frac{1}{4}$", right: "💚🤍🤍🤍" },
                    { left: "$\\frac{2}{2}$", right: "💚💚" }
                ],
                hint: "ספרו את הכוכבים הצבועים והלא צבועים",
                solutionGuide: "💚 מסמל כוכב צבוע, 🤍 מסמל כוכב לא צבוע"
            },
            {
                question: "התאימו כל שבר לתיאור בכוסות",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$ כוס", right: "חצי כוס" },
                    { left: "$\\frac{1}{4}$ כוס", right: "רבע כוס" },
                    { left: "$\\frac{1}{3}$ כוס", right: "שליש כוס" },
                    { left: "$1$ כוס", right: "כוס מלאה" }
                ],
                hint: "חשבו על המונחים המוכרים למדידת נוזלים",
                solutionGuide: "חיבור בין השבר למונח המתאים במדידות"
            },
            {
                question: "התאימו כל שבר לריבועים הצבועים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "■□" },
                    { left: "$\\frac{1}{3}$", right: "■□□" },
                    { left: "$\\frac{1}{4}$", right: "■□□□" },
                    { left: "$\\frac{2}{2}$", right: "■■" }
                ],
                hint: "ספרו את הריבועים הצבועים והלא צבועים",
                solutionGuide: "■ מסמל ריבוע צבוע, □ מסמל ריבוע לא צבוע"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{1}{4}$", "$\\frac{1}{3}$", "$\\frac{1}{2}$", "$\\frac{2}{2}$"],
                hint: "חשבו איזה חלק גדול יותר",
                solutionGuide: "רבע קטן משליש, שליש קטן מחצי, חצי קטן משלם"
            },
            {
                question: "סדרו את החלקים מהקטן לגדול",
                type: "order",
                items: ["רבע", "שליש", "חצי", "שלם"],
                hint: "חשבו איזה חלק גדול יותר",
                solutionGuide: "רבע הוא החלק הקטן ביותר, אחריו שליש, חצי, ובסוף שלם"
            },
            {
                question: "סדרו את הזמנים מהקצר לארוך",
                type: "order",
                items: ["רבע שעה", "20 דקות", "חצי שעה", "שעה שלמה"],
                hint: "המירו את הזמנים לדקות",
                solutionGuide: "15 דקות, 20 דקות, 30 דקות, 60 דקות"
            },
            {
                question: "סדרו את כמויות המים מהקטנה לגדולה",
                type: "order",
                items: ["רבע כוס", "שליש כוס", "חצי כוס", "כוס מלאה"],
                hint: "חשבו איזו כמות גדולה יותר",
                solutionGuide: "רבע כוס היא הכמות הקטנה ביותר, אחריה שליש, חצי, ובסוף כוס מלאה"
            },
            {
                question: "סדרו את החלקים מהקטן לגדול",
                type: "order",
                items: ["חלק אחד מארבעה", "חלק אחד משלושה", "חלק אחד משניים", "שני חלקים משניים"],
                hint: "חשבו איזה חלק גדול יותר",
                solutionGuide: "ככל שמחלקים ליותר חלקים, כל חלק קטן יותר"
            }
        ]
    }
}; 