import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from './AuthContext';
import MathTutor from './App';
import theme from './theme';

function RedirectToExplanation() {
    const { topicId } = useParams();
    return <Navigate replace to={`/math/${topicId}/explanation`} />;
}

export default function AppRouter() {
    return (
        <ChakraProvider theme={theme}>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        {/* Keep homepage at root URL */}
                        <Route path="/" element={<MathTutor />} />
                        
                        {/* Math topic routes */}
                        <Route path="/math/:topicId" element={<RedirectToExplanation />} />
                        <Route path="/math/:topicId/*" element={<MathTutor />} />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </ChakraProvider>
    );
}