export const learningContent = {
    "אי-שוויונים עם משתנים": {
        tags: ['כיתה ז׳', 'אלגברה'],
        relatedLinks: [
            { title: "תרגול אי-שוויונים עם משתנים", url: "/?tags=אלגברה", description: "תרגול בעיות אי-שוויונים עם משתנים" }
        ],
        getStarted: [
            "**מהם אי-שוויונים?**",
            "אי-שוויון הוא יחס מתמטי המראה שערך אחד גדול או קטן מערך אחר",
            "",
            "**סימנים בסיסיים:**",
            "$>$ - גדול מ-",
            "$<$ - קטן מ-",
            "$\\geq$ - גדול או שווה ל-",
            "$\\leq$ - קטן או שווה ל-",
            "",
            "**חוקי אי-שוויונים:**",
            "אם מוסיפים או מחסירים אותו מספר משני האגפים, אי-השוויון נשמר",
            "אם כופלים או מחלקים במספר חיובי, אי-השוויון נשמר",
            "אם כופלים או מחלקים במספר שלילי, כיוון אי-השוויון מתהפך",
            "",
            "**אי-שוויונים בשברים:**",
            "כדי להשוות שברים, צריך להביא למכנה משותף",
            "אפשר להשתמש במכפלות צולבות כשהמכנים חיוביים",
            "",
            "**אי-שוויונים דו-צדדיים:**",
            "אי-שוויון דו-צדדי מתאר מספר שנמצא בין שני ערכים",
            "לדוגמה: $2 \\leq x + 1 \\leq 7$ אומר ש-$x + 1$ גדול או שווה ל-2 וגם קטן או שווה ל-7",
            "",
            "**פתרון אי-שוויון דו-צדדי:**",
            "פותרים את שני הצדדים באותה דרך",
            "למשל: $2 \\leq x + 1 \\leq 7$",
            "נחסיר 1 מכל האגפים: $1 \\leq x \\leq 6$",
            "",
            "**הצגה על ציר המספרים:**",
            "אי-שוויון דו-צדדי מתאר קטע או קרן על ציר המספרים",
            "הסימנים $\\leq$ או $\\geq$ כוללים את נקודות הקצה",
            "הסימנים $<$ או $>$ לא כוללים את נקודות הקצה"
        ],
        examples: [
            {
                question: "האם נכון ש- $\\frac{3}{7} \\leq \\frac{5}{14} \\leq \\frac{8}{21}$?",
                solution: "נביא למכנה משותף של 42:\n$\\frac{3}{7} = \\frac{18}{42}$\n$\\frac{5}{14} = \\frac{15}{42}$\n$\\frac{8}{21} = \\frac{16}{42}$\nלכן: $\\frac{18}{42} > \\frac{15}{42} < \\frac{16}{42}$\nהאי-שוויון המקורי אינו נכון",
                drawing: {
                    type: "fractionCircles",
                    fractions: ["3/7", "5/14", "8/21"],
                    caption: "השוואה ויזואלית של השברים באמצעות עיגולים"
                }
            },
            {
                question: "פתרו את אי-השוויון: $2x + 3 < 7$",
                solution: "נחסיר 3 משני האגפים:\n$2x < 4$\nנחלק ב-2 (חיובי, לכן הכיוון נשמר):\n$x < 2$",
                drawing: {
                    type: "inequalitySteps",
                    steps: [
                        "$2x + 3 < 7$",
                        "$2x < 4$",
                        "$x < 2$"
                    ],
                    arrows: [
                        "$-3$",
                        "$÷2$"
                    ],
                    caption: "צעדי הפתרון של האי-שוויון"
                }
            },
            {
                question: "הציגו את אי-השוויון $x < 2$ על קו מספרים.",
                solution: "נציג את הפתרון על קו מספרים, כאשר המספר 2 לא כלול, כלומר נציין את כל הערכים משמאל ל-2.",
                drawing: {
                    type: "numberLine",
                    min: -5,
                    max: 5,
                    points: [2],
                    labels: ["2"],
                    caption: "קו מספרים המראה את הפתרון של $x < 2$"
                }
            }
        ],
        quiz: [
            {
                question: "איזה מהאי-שוויונים הבאים נכון?",
                type: "multiplechoice",
                options: ["$\\frac{5}{6} = \\frac{10}{12}$", "$\\frac{3}{8} > \\frac{4}{7}$", "$\\frac{2}{5} < \\frac{3}{8}$", "$\\frac{1}{3} > \\frac{2}{5}$"],
                hint: "כדי להשוות שברים, כדאי להביא אותם למכנה משותף",
                solutionGuide: "נבדוק כל אפשרות בנפרד על ידי מציאת מכנה משותף:<br/><br/>$\\frac{5}{6} = \\frac{10}{12}$<br/>$\\frac{10}{12} = \\frac{10}{12}$ נכון<br/><br/>$\\frac{3}{8} > \\frac{4}{7}$<br/>$\\frac{21}{56} > \\frac{32}{56}$ לא נכון<br/><br/>$\\frac{2}{5} < \\frac{3}{8}$<br/>$\\frac{16}{40} < \\frac{15}{40}$ לא נכון<br/><br/>$\\frac{1}{3} > \\frac{2}{5}$<br/>$\\frac{15}{45} > \\frac{18}{45}$ לא נכון<br/><br/>התשובה הנכונה היא $\\frac{5}{6} = \\frac{10}{12}$"
            },
            {
                question: "אם $a < b$ ו- $c > 0$, איזה משפט הוא הנכון ביותר?",
                type: "multiplechoice",
                options: ["$ac < bc$", "$a + c < b - c$", "$ac > bc$", "כל התשובות נכונות"],
                hint: "חשבו על הכללים של כפל, חילוק וחיבור באי-שוויונות כשהמספרים חיוביים",
                solutionGuide: "כאשר כופלים אי-שוויון במספר חיובי, כיוון האי-שוויון נשמר:<br/>אם $a < b$ ו-$c > 0$ אז $ac < bc$<br/><br/>שאר התשובות שגויות:<br/>• $a + c < b - c$ לא נכון תמיד (למשל: אם $a=1$, $b=2$, $c=2$)<br/>• $ac > bc$ סותר את הנתונים<br/> לכן התשובה הנכונה היא $ac < bc$"
            },
            {
                question: "איזה מספר מקיים את אי-השוויון $-2 \\leq x < 3$?",
                type: "multiplechoice",
                options: ["$-1$", "$4$", "$3$", "$-3$"],
                hint: "בדקו אילו מספרים נמצאים בין -2 ל-3, כולל -2 אבל לא כולל 3",
                solutionGuide: "המספרים המתקבלים הם בין $-2$ ל $3$, ולכן $-1$ הוא המספר היחיד שמקיים את האי-שוויון."
            },
            {
                question: "מה הפתרון של $2(x + 1) < 8$?",
                type: "multiplechoice",
                options: ["$x < 3$", "$x > 3$", "$x < 4$", "$x < 2$"],
                hint: "פתחו את הסוגריים תחילה או חלקו ב-2 את שני האגפים",
                solutionGuide: "נחלק ב-2: $x + 1 < 4$. לאחר מכן, נחסיר 1: $x < 3$."
            },
            {
                question: "אם $x < 0$, מה נכון?",
                type: "multiplechoice",
                options: ["$-x > 0$", "$x^2 < 0$", "$\\frac{1}{x} > 0$", "$3x > x$"],
                hint: "חשבו מה קורה כשהופכים את הסימן של מספר שלילי",
                solutionGuide: "אם $x < 0$, אז $-x$ הוא חיובי, ולכן $-x > 0$.<br/><br/>שאר התשובות שגויות:<br/>• $x^2 < 0$ - לא נכון כי ריבוע תמיד חיובי<br/>• $\\frac{1}{x} > 0$ - לא נכון כי 1 חלקי מספר שלילי הוא שלילי<br/>• $3x > x$ - לא נכון כי כפל מספר שלילי במספר חיובי מרחיק אותו מהאפס"
            },
            {
                question: "פתרו: $\\frac{x-1}{3} \\geq 2$",
                type: "multiplechoice",
                options: ["$x \\geq 7$", "$x \\leq 7$", "$x \\geq 5$", "$x \\leq 5$"],
                hint: "כפלו את שני האגפים ב-3 (זהו מספר חיובי, אז כיוון האי-שוויון נשמר)",
                solutionGuide: "נכפיל ב-3: $x - 1 \\geq 6$. לאחר מכן, נוסיף 1: $x \\geq 7$."
            },
            {
                question: "איזה מהבאים שווה ערך ל- $x > -3$?",
                type: "multiplechoice",
                options: ["$-x < 3$", "$-x > 3$", "$3x > -3$", "$x - 3 > 0$"],
                hint: "נסו להעביר את כל האיברים לצד אחד של האי-שוויון",
                solutionGuide: "נבדוק את האפשרות הראשונה:<br/>$-x < 3$<br/>נכפיל ב-(-1) את שני האגפים (כיוון האי-שוויון מתהפך):<br/>$x > -3$ ✓<br/><br/>זוהי התשובה הנכונה כי היא שקולה לאי-השוויון המקורי $x > -3$"
            },
            {
                question: "מה התחום המתאים לפתרון $|x| < 2$?",
                type: "multiplechoice",
                options: ["$-2 < x < 2$", "$x > 2$", "$x < -2$", "$x > -2$"],
                hint: "ערך מוחלט קטן מ-2 משמעותו שהמרחק מ-0 קטן מ-2",
                solutionGuide: "נפתור בשלבים:<br/>$|x| < 2$ אומר שהמרחק של $x$ מ-$0$ קטן מ-$2$<br/>לכן:<br/>$-2 < x < 2$<br/>כלומר המספר $x$ נמצא בטווח שבין $-2$ ל-$2$"
            },
            {
                question: "פתרו את אי-השוויון: $5x - 2 < 8$",
                type: "multiplechoice",
                options: ["$x < 2$", "$x > 2$", "$x < 5$", "$x > 5$"],
                hint: "העבירו את כל האיברים שאינם כוללים x לצד ימין, ואז חלקו ב-5",
                solutionGuide: "נוסיף 2 לשני האגפים: $5x < 10$. נחלק ב-5: $x < 2$"
            },
            {
                question: "איזה מספר מקיים $3 \\leq x \\leq 6$?",
                type: "multiplechoice",
                options: ["3", "2", "7", "8"],
                hint: "חפשו מספר שגדול או שווה ל-3 וגם קטן או שווה ל-6",
                solutionGuide: "המספר צריך להיות גדול או שווה ל-3 וקטן או שווה ל-6. המספר 3 מקיים תנאים אלו"
            },
            {
                question: "אם $\\frac{x}{4} > 2$, מה ערכו של $x$?",
                type: "multiplechoice",
                options: ["$x > 8$", "$x < 8$", "$x > 2$", "$x < 2$"],
                hint: "כפלו את שני האגפים ב-4 כדי לבודד את x",
                solutionGuide: "נכפיל את שני האגפים ב-4: $x > 8$"
            },
            {
                question: "מה הפתרון של $-2x + 5 < -3$?",
                type: "multiplechoice",
                options: ["$x > 4$", "$x < 4$", "$x > -4$", "$x < -4$"],
                hint: "העבירו קודם את המספרים, ואז זכרו שבחלוקה במספר שלילי כיוון האי-שוויון מתהפך",
                solutionGuide: "נחסיר $5$ משני האגפים: $-2x < -8$<br/>נחלק ב-$(-2)$ (מספר שלילי ולכן הכיוון מתהפך): $x > 4$"
            },
            {
                question: "איזה מהבאים נכון תמיד כאשר $x < 0$?",
                type: "multiplechoice",
                options: ["$x^2 > 0$", "$\\frac{1}{x} > 0$", "$-x < 0$", "$2x > -x$"],
                hint: "חשבו על התכונות של מספרים שליליים בריבוע",
                solutionGuide: "כאשר $x < 0$:<br/>• $x^2 > 0$ נכון תמיד (כי מספר שלילי בריבוע הוא חיובי)<br/><br/>שאר התשובות שגויות:<br/>• $\\frac{1}{x} > 0$ - לא נכון כי אחד חלקי מספר שלילי הוא שלילי<br/>• $-x < 0$ - לא נכון כי הופכים את הסימן של מספר שלילי מקבלים חיובי<br/>• $2x > -x$ - לא נכון כי כפל מספר שלילי ב-2 נותן מספר יותר שלילי מהפיכת הסימן"
            },
            {
                question: "פתרו את אי-השוויון: $\\frac{x+2}{3} \\leq 4$",
                type: "multiplechoice",
                options: ["$x \\leq 10$", "$x \\leq 12$", "$x \\leq 14$", "$x \\leq 16$"],
                hint: "כפלו את שני האגפים ב-3 ואז בודדו את x",
                solutionGuide: "נכפיל ב-3: $x + 2 \\leq 12$. נחסיר 2: $x \\leq 10$"
            },
            {
                question: "אם $a > b$ ו-$c < 0$, מה נכון בהכרח?",
                type: "multiplechoice",
                options: ["$ac < bc$", "$ac > bc$", "$\\frac{a}{c} > \\frac{b}{c}$", "$a + c < b + c$"],
                hint: "זכרו: כשכופלים אי-שוויון במספר שלילי, הכיוון מתהפך",
                solutionGuide: "כאשר כופלים אי-שוויון במספר שלילי, כיוון האי-שוויון מתהפך:<br/><br/>• $ac < bc$ נכון כי: $a > b$ ו-$c < 0$ אז הכיוון מתהפך<br/><br/>שאר התשובות שגויות:<br/>• $ac > bc$ - לא נכון כי הכיוון מתהפך בכפל בשלילי<br/>• $\\frac{a}{c} > \\frac{b}{c}$ - לא נכון כי בחילוק בשלילי הכיוון מתהפך<br/>• $a + c < b + c$ - לא נכון כי כשמוסיפים אותו מספר לשני האגפים, כיוון האי-שוויון נשמר"
            },
            {
                question: "איזה מהבאים שווה ערך ל-$x \\geq -5$?",
                type: "multiplechoice",
                options: ["$x + 5 \\geq 0$", "$x \\leq 5$", "$5 + x \\leq 0$", "$-x \\geq -5$"],
                hint: "נסו להעביר את -5 לצד השני של האי-שוויון",
                solutionGuide: "אם נוסיף 5 לשני האגפים נקבל $x + 5 \\geq 0$"
            },
            {
                question: "מה הפתרון של $3(x - 2) > 6$?",
                type: "multiplechoice",
                options: ["$x > 4$", "$x < 4$", "$x > 2$", "$x < 2$"],
                hint: "פתחו את הסוגריים או חלקו ב-3 את שני האגפים תחילה",
                solutionGuide: "נחלק ב-$3$ את שני האגפים: $x - 2 > 2$<br/>נוסיף $2$ לשני האגפים: $x > 4$"
            },
            {
                question: "איזה מהבאים נכון תמיד כאשר $|x| > 3$?",
                type: "multiplechoice",
                options: ["$x < -3$ או $x > 3$", "$x > 3$", "$x < -3$", "$x > -3$"],
                hint: "ערך מוחלט גדול מ-3 משמעותו שהמרחק מ-0 גדול מ-3",
                solutionGuide: "$|x| > 3$ משמעותו שהמספר נמצא מחוץ לתחום $[-3,3]$"
            },
            {
                question: "פתרו: $-4 < 2x - 3 < 5$",
                type: "multiplechoice",
                options: ["$-\\frac{1}{2} < x < 4$", "$3 < x < 1$", "$4 < x < -\\frac{1}{2}$", "$1 < x < 3$"],
                hint: "באי-שוויון דו-צדדי, פותרים את כל הצדדים באותה דרך",
                solutionGuide: "נוסיף 3 לכל האגפים: $-1 < 2x < 8$. נחלק ב-2: $-\\frac{1}{2} < x < 4$"
            },
            {
                question: "אם $x > y$ ו-$y > z$, מה נכון בהכרח?",
                type: "multiplechoice",
                options: ["$x > z$", "$x = z$", "$x \\leq z$", "$x < z$"],
                hint: "חשבו על חוק המעבר באי-שוויונות",
                solutionGuide: "לפי חוק המעבר, אם $x > y$ ו-$y > z$ אז $x > z$"
            },
            {
                question: "פתרו את אי-השוויון: $\\frac{2x-1}{5} < 3$",
                type: "multiplechoice",
                options: ["$x < 8$", "$x > 8$", "$x > 4$", "$x < 4$"],
                hint: "כפלו את שני האגפים ב-5 ואז בודדו את x",
                solutionGuide: "נכפיל ב-5: $2x-1 < 15$. נוסיף 1: $2x < 16$. נחלק ב-2: $x < 8$"
            },
            {
                question: "מה נכון תמיד כאשר $x > 1$?",
                type: "multiplechoice",
                options: ["$\\frac{1}{x} < 1$", "$x^2 = x$", "$x^2 < x$", "$\\frac{1}{x} > 1$"],
                hint: "חשבו מה קורה כשמחלקים 1 במספר גדול מ-1",
                solutionGuide: "כאשר $x > 1$, נחלק את האי-שוויון ב-$x$:<br/>$\\frac{x}{x} > \\frac{1}{x}$<br/>$1 > \\frac{1}{x}$<br/>לכן $\\frac{1}{x} < 1$"
            },
            {
                question: "פתרו: $4x + 2 \\geq 2x - 4$",
                type: "multiplechoice",
                options: ["$x \\geq -3$", "$x \\geq 3$", "$x \\leq 3$", "$x \\leq -3$"],
                hint: "העבירו את כל האיברים עם x לצד אחד ואת כל השאר לצד השני",
                solutionGuide: "נחסיר $2x$ משני האגפים: $2x + 2 \\geq -4$. נחסיר 2: $2x \\geq -6$. נחלק ב-2: $x \\geq -3$"
            },
            {
                question: "איזה מספר מקיים $-1 < x < 2$?",
                type: "multiplechoice",
                options: ["0", "-2", "2.5", "3"],
                hint: "חפשו מספר שנמצא בין -1 ל-2",
                solutionGuide: "המספר 0 נמצא בין $-1$ ל 2"
            },
            {
                question: "פתרו את אי-השוויון: $\\frac{x-3}{2} > -1$",
                type: "multiplechoice",
                options: ["$x > 1$", "$x < 1$", "$x > -1$", "$x < -1$"],
                hint: "כפלו את שני האגפים ב-2 ואז בודדו את x",
                solutionGuide: "נכפיל ב-2: $x-3 > -2$. נוסיף 3: $x > 1$"
            },
            {
                question: "אם $x < 5$ ו-$x > 2$, מה נכון?",
                type: "multiplechoice",
                options: ["$2 < x < 5$", "$x < 2$", "$x > 5$", "$x = 2$"],
                hint: "חשבו על המספרים שמקיימים את שני התנאים בו-זמנית",
                solutionGuide: "המספר $x$ חייב להיות גדול מ-2 וקטן מ-5<br/>לכן $2 < x < 5$"
            },
            {
                question: "מה הפתרון של $|2x + 1| \\leq 3$?",
                type: "multiplechoice",
                options: ["$-2 \\leq x \\leq 1$", "$0 \\leq x \\leq 3$", "$1 \\leq x \\leq 4$", "$-1 \\leq x \\leq 2$"],
                hint: "פתחו את הערך המוחלט לאי-שוויון דו-צדדי: -3 ≤ 2x + 1 ≤ 3",
                solutionGuide: "נפתור: $-3 \\leq 2x + 1 \\leq 3$. נחסיר 1: $-4 \\leq 2x \\leq 2$. נחלק ב-2: $-2 \\leq x \\leq 1$"
            },
            {
                question: "איזה מספר מקיים $x^2 < 4$?",
                type: "multiplechoice",
                options: ["1", "-3", "3", "2"],
                hint: "חשבו על המספרים שבריבוע נותנים פחות מ-4",
                solutionGuide: "$x^2 < 4$ משמעותו $-2 < x < 2$, ולכן 1 מקיים את האי-שוויון"
            },
            {
                question: "התאימו כל אי-שוויון לפתרון שלו",
                type: "matching",
                pairs: [
                    { left: "$2x + 3 < 7$", right: "$x < 2$" },
                    { left: "$-3x > 6$", right: "$x < -2$" },
                    { left: "$\\frac{x}{2} + 1 > 4$", right: "$x > 6$" },
                    { left: "$4x - 8 \\leq 0$", right: "$x \\leq 2$" }
                ],
                hint: "פתרו כל אי-שוויון בנפרד",
                solutionGuide: "$2x + 3 < 7 \\rightarrow x < 2$<br/>$-3x > 6 \\rightarrow x < -2$<br/>$\\frac{x}{2} + 1 > 4 \\rightarrow x > 6$<br/>$4x - 8 \\leq 0 \\rightarrow x \\leq 2$"
            },
            {
                question: "התאימו כל ביטוי לתכונה הנכונה",
                type: "matching",
                pairs: [
                    { left: "$x < 0$", right: "$-x > 0$" },
                    { left: "$x > y$", right: "$x - y > 0$" },
                    { left: "$x > y > z$", right: "$x > z$" },
                    { left: "$x < y$", right: "$-x > -y$" }
                ],
                hint: "בדקו את התכונות של אי-שוויונים",
                solutionGuide: "אם $x < 0$ אז $-x > 0$<br/>אם $x > y$ אז הפרשם חיובי<br/>אי-שוויונים מקיימים חוק מעבר<br/>הפיכת סימן הופכת את כיוון האי-שוויון"
            },
            {
                question: "התאימו כל פעולה לתוצאה שלה באי-שוויונים",
                type: "matching",
                pairs: [
                    { left: "כפל במספר חיובי", right: "שמירת כיוון" },
                    { left: "חילוק במספר שלילי", right: "היפוך כיוון" }
                ],
                hint: "זכרו את הכללים של פעולות באי-שוויונים",
                solutionGuide: "לדוגמה: אם $x > 2$ אז $3x > 6$ (כפל בחיובי)<br/>אם $x > 2$ אז $-x < -2$ (כפל בשלילי)"
            },
            {
                question: "התאימו כל אי-שוויון מורכב לצורתו הפשוטה",
                type: "matching",
                pairs: [
                    { left: "$2(x + 3) < 10$", right: "$x < 2$" },
                    { left: "$\\frac{x-1}{3} \\geq -2$", right: "$x \\geq -5$" },
                    { left: "$-2(x - 4) > 6$", right: "$x < 1$" },
                    { left: "$\\frac{2x+4}{2} \\leq 6$", right: "$x \\leq 4$" }
                ],
                hint: "פתרו כל אי-שוויון בשלבים",
                solutionGuide: "פתחו סוגריים, אחדו איברים דומים, ובודדו את x"
            },
            {
                question: "התאימו כל אי-שוויון לתחום ההגדרה שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{x-2} > 0$", right: "$x > 2$" },
                    { left: "$\\sqrt{x+1} < 3$", right: "$x < 8$ ו-$x \\geq -1$" },
                    { left: "$\\frac{x}{x+4} \\geq 0$", right: "$x > 0$ או $x < -4$" },
                    { left: "$\\frac{1}{x^2} > 0$", right: "$x \\neq 0$" }
                ],
                hint: "חשבו על המגבלות של כל פעולה (שורש, מכנה וכו')",
                solutionGuide: "בדקו היכן הביטויים מוגדרים ומתי מתקיים האי-שוויון"
            },

            {
                question: "התאימו כל אי-שוויון לתכונה המתמטית שלו",
                type: "matching",
                pairs: [
                    { left: "$|x| > |y|$", right: "המרחק מ-0 של x גדול יותר" },
                    { left: "$\\frac{1}{x} < \\frac{1}{y}$", right: "$x > y > 0$" },
                    { left: "$x + a < x + b$", right: "$a < b$" },
                    { left: "$ax < bx$", right: "$a < b$ כאשר $x > 0$" }
                ],
                hint: "חשבו על המשמעות הגיאומטרית של כל אי-שוויון",
                solutionGuide: "נתחו את התכונות האלגבריות והגיאומטריות של כל ביטוי"
            }
        ]
    }
};
