import React from 'react';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { FillInQuestion } from './FillInQuestion';
import { OrderQuestion } from './OrderQuestion';
import { MatchingQuestion } from './MatchingQuestion';

export const QuestionContent = ({ question, onAnswer, showFeedback }) => {
  switch (question.type) {
    case 'multiplechoice':
      return <MultipleChoiceQuestion question={question} onAnswer={onAnswer} showFeedback={showFeedback} />;
    
    case 'fillIn':
      return <FillInQuestion onAnswer={onAnswer} showFeedback={showFeedback} />;
    
    case 'order':
      return <OrderQuestion question={question} onAnswer={onAnswer} showFeedback={showFeedback} />;
    
    case 'matching':
      return <MatchingQuestion question={question} onAnswer={onAnswer} showFeedback={showFeedback} />;
    
    default:
      return null;
  }
}; 