export const learningContent = {
    "חיבור במאונך": {
        tags: ['כיתה ג׳', 'חיבור וחיסור'],
        relatedLinks: [
            { title: "תרגול חיבור במאונך", url: "/?tags=חיבור וחיסור", description: "תרגול בעיות חיבור במאונך" }
        ],
        getStarted: [
            "**איך מחברים במאונך?**",
            "",
            "רושמים את המספרים זה מתחת לזה",
            "מיישרים לפי האחדות (ימין)",
            "מתחילים לחבר מימין לשמאל",
            "זוכרים להעביר עשרות למספר הבא",
            "",
            "**שלבי החיבור:**",
            "1. מסדרים את המספרים זה תחת זה",
            "2. מתחילים מאחדות (הטור הימני)",
            "3. אם יש יותר מ-10, רושמים את האחדות ומעבירים את העשרת",
            "4. ממשיכים לטור הבא משמאל",
            "",
            "**טיפים:**",
            "תמיד לרשום מספרים ברורים",
            "לסמן את העשרות שמעבירים",
            "לבדוק את התוצאה בסוף",
            {
                drawing: {
                    type: "vertical",
                    firstNumber: 345,
                    secondNumber: 267,
                    operation: "addition",
                    steps: [
                        "מחברים אחדות: $5 + 7 = 12$",
                        "מעבירים 1 לעשרות",
                        "מחברים עשרות: $4 + 6 + 1 = 11$",
                        "מעבירים 1 למאות",
                        "מחברים מאות: $3 + 2 + 1 = 6$"
                    ]
                }
            }
        ],
        examples: [
            {
                question: "חברו במאונך: 234 + 156",
                solution: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 234 \\\\ + 156 \\\\ \\hline 390 \\end{array}$<br/><br/>אחדות: 4 + 6 = 10, נרשום 0 ונעביר 1<br/>עשרות: 1 + 3 + 5 = 9<br/>מאות: 2 + 1 = 3",
                drawing: {
                    type: "vertical",
                    firstNumber: 234,
                    secondNumber: 156,
                    operation: "addition",
                    steps: [
                        "מחברים אחדות: $4 + 6 = 10$",
                        "מעבירים 1 לעשרות",
                        "מחברים עשרות: $3 + 5 + 1 = 9$",
                        "מחברים מאות: $2 + 1 = 3$"
                    ]
                }
            },
            {
                question: "חברו במאונך: 567 + 285",
                solution: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.8ex}^1\\kern{0.8ex}\\\\ 567 \\\\ + 285 \\\\ \\hline 852 \\end{array}$<br/><br/>אחדות: 7 + 5 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 6 + 8 = 15, נרשום 5 ונעביר 1<br/>מאות: 1 + 5 + 2 = 8",
                drawing: {
                    type: "vertical",
                    firstNumber: 567,
                    secondNumber: 285,
                    operation: "addition",
                    steps: [
                        "מחברים אחדות: $7 + 5 = 12$",
                        "מעבירים 1 לעשרות",
                        "מחברים עשרות: $6 + 8 + 1 = 15$",
                        "מעבירים 1 למאות",
                        "מחברים מאות: $5 + 2 + 1 = 8$"
                    ]
                }
            }
        ],
        quiz: [
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 456 \\\\ + 327 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["783", "773", "793", "763"],
                hint: "התחילו מספרת האחדות (6 + 7). אם התוצאה גדולה מ-9, זכרו להעביר 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 456 \\\\ + 327 \\\\ \\hline 783 \\end{array}$<br/><br/>אחדות: 6 + 7 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 5 + 2 = 8<br/>מאות: 4 + 3 = 7<br/>התוצאה הסופית היא 783" 
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 628 \\\\ + 154 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["782", "772", "792", "762"],
                hint: "חברו את ספרות האחדות (8 + 4). אם יש העברה, זכרו להוסיף אותה לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 628 \\\\ + 154 \\\\ \\hline 782 \\end{array}$<br/><br/>אחדות: 8 + 4 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 2 + 5 = 8<br/>מאות: 6 + 1 = 7<br/>התוצאה הסופית היא 782"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 735 \\\\ + 267 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1002", "992", "1012", "982"],
                hint: "שימו לב שיש כאן העברות בכל הטורים. זכרו להעביר 1 כשסכום הספרות גדול מ-9",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 735 \\\\ + 267 \\\\ \\hline 1002 \\end{array}$<br/><br/>אחדות: 5 + 7 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 3 + 6 = 10, נרשום 0 ונעביר 1<br/>מאות: 1 + 7 + 2 = 10<br/>התוצאה הסופית היא 1002"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 843 \\\\ + 379 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1222", "1212", "1232", "1202"],
                hint: "שימו לב שיש כאן העברות בכל הטורים. בדקו היטב את סכום הספרות בכל טור כולל ההעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 843 \\\\ + 379 \\\\ \\hline 1222 \\end{array}$<br/><br/>אחדות: 3 + 9 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 4 + 7 = 12, נרשום 2 ונעביר 1<br/>מאות: 1 + 8 + 3 = 12<br/>התוצאה הסופית היא 1222"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 564 \\\\ + 438 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1002", "992", "1012", "982"],
                hint: "התחילו מאחדות (4 + 8). זכרו להעביר 1 כשהסכום גדול מ-9. שימו לב להעברות בכל הטורים",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 564 \\\\ + 438 \\\\ \\hline 1002 \\end{array}$<br/><br/>אחדות: 4 + 8 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 6 + 3 = 10, נרשום 0 ונעביר 1<br/>מאות: 1 + 5 + 4 = 10<br/>התוצאה הסופית היא 1002"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 347 \\\\ + 456 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["803", "813", "793", "783"],
                hint: "חברו את האחדות (7 + 6). כשמעבירים 1, זכרו להוסיף אותו לסכום העשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 347 \\\\ + 456 \\\\ \\hline 803 \\end{array}$<br/><br/>אחדות: 7 + 6 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 4 + 5 = 10, נרשום 0 ונעביר 1<br/>מאות: 1 + 3 + 4 = 8<br/>התוצאה הסופית היא 803"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 678 \\\\ + 235 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["913", "903", "923", "893"],
                hint: "חברו את האחדות (8 + 5). אם יש העברה, אל תשכחו להוסיף אותה לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 678 \\\\ + 235 \\\\ \\hline 913 \\end{array}$<br/><br/>אחדות: 8 + 5 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 7 + 3 = 11, נרשום 1 ונעביר 1<br/>מאות: 1 + 6 + 2 = 9<br/>התוצאה הסופית היא 913"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 583 \\\\ + 429 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1012", "1002", "1022", "992"],
                hint: "התחילו מאחדות (3 + 9). זכרו להעביר 1 כשהסכום גדול מ-9",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 583 \\\\ + 429 \\\\ \\hline 1012 \\end{array}$<br/><br/>אחדות: 3 + 9 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 8 + 2 = 11, נרשום 1 ונעביר 1<br/>מאות: 1 + 5 + 4 = 10<br/>התוצאה הסופית היא 1012"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 764 \\\\ + 348 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1112", "1102", "1122", "1092"],
                hint: "שימו לב להעברות בכל טור. זכרו להוסיף את ההעברה לטור הבא",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 764 \\\\ + 348 \\\\ \\hline 1112 \\end{array}$<br/><br/>אחדות: 4 + 8 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 6 + 4 = 11, נרשום 1 ונעביר 1<br/>מאות: 1 + 7 + 3 = 11<br/>התוצאה הסופית היא 1112"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 892 \\\\ + 219 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1111", "1101", "1121", "1091"],
                hint: "התחילו מאחדות (2 + 9). זכרו להעביר 1 כשהסכום גדול מ-9. שימו לב להעברות בכל הטורים",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 892 \\\\ + 219 \\\\ \\hline 1111 \\end{array}$<br/><br/>אחדות: 2 + 9 = 11, נרשום 1 ונעביר 1<br/>עשרות: 1 + 9 + 1 = 11, נרשום 1 ונעביר 1<br/>מאות: 1 + 8 + 2 = 11<br/>התוצאה הסופית היא 1111"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 445 \\\\ + 367 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["812", "802", "822", "792"],
                hint: "חברו את ספרות האחדות (5 + 7). אם התוצאה גדולה מ-9, זכרו להעביר 1 לעשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 445 \\\\ + 367 \\\\ \\hline 812 \\end{array}$<br/><br/>אחדות: 5 + 7 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 4 + 6 = 11, נרשום 1 ונעביר 1<br/>מאות: 1 + 4 + 3 = 8<br/>התוצאה הסופית היא 812"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 673 \\\\ + 248 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["921", "911", "931", "901"],
                hint: "התחילו מטור האחדות (3 + 8). בדקו בכל טור אם צריך להעביר 1 לטור הבא",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 673 \\\\ + 248 \\\\ \\hline 921 \\end{array}$<br/><br/>אחדות: 3 + 8 = 11, נרשום 1 ונעביר 1<br/>עשרות: 1 + 7 + 4 = 12, נרשום 2 ונעביר 1<br/>מאות: 1 + 6 + 2 = 9<br/>התוצאה הסופית היא 921"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 536 \\\\ + 487 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1023", "1013", "1033", "1003"],
                hint: "חברו את האחדות (6 + 7). כשיש העברה, זכרו להוסיף אותה לסכום בטור הבא",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 536 \\\\ + 487 \\\\ \\hline 1023 \\end{array}$<br/><br/>אחדות: 6 + 7 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 3 + 8 = 12, נרשום 2 ונעביר 1<br/>מאות: 1 + 5 + 4 = 10<br/>התוצאה הסופית היא 1023"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 784 \\\\ + 329 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1113", "1103", "1123", "1093"],
                hint: "התחילו מימין (4 + 9). שימו לב להעברות בכל טור וזכרו להוסיף אותן לטור הבא",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 784 \\\\ + 329 \\\\ \\hline 1113 \\end{array}$<br/><br/>אחדות: 4 + 9 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 8 + 2 = 11, נרשום 1 ונעביר 1<br/>מאות: 1 + 7 + 3 = 11<br/>התוצאה הסופית היא 1113"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 345 \\\\ + 333 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["678", "668", "688", "658"],
                hint: "חברו כל טור בנפרד מימין לשמאל. שימו לב שבתרגיל זה אין העברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 345 \\\\ + 333 \\\\ \\hline 678 \\end{array}$<br/><br/>אחדות: 5 + 3 = 8<br/>עשרות: 4 + 3 = 7<br/>מאות: 3 + 3 = 6<br/>התוצאה הסופית היא 678"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 756 \\\\ + 367 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1123", "1113", "1133", "1103"],
                hint: "התחילו מאחדות (6 + 7). זכרו להעביר 1 כשהסכום גדול מ-9. בדקו היטב את ההעברות בכל טור",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 756 \\\\ + 367 \\\\ \\hline 1123 \\end{array}$<br/><br/>אחדות: 6 + 7 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 5 + 6 = 12, נרשום 2 ונעביר 1<br/>מאות: 1 + 7 + 3 = 11<br/>התוצאה הסופית היא 1123"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 438 \\\\ + 585 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1023", "1013", "1033", "1003"],
                hint: "חברו את האחדות (8 + 5). אם יש העברה, זכרו להוסיף אותה לטור העשרות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 438 \\\\ + 585 \\\\ \\hline 1023 \\end{array}$<br/><br/>אחדות: 8 + 5 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 3 + 8 = 12, נרשום 2 ונעביר 1<br/>מאות: 1 + 4 + 5 = 10<br/>התוצאה הסופית היא 1023"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 897 \\\\ + 837 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1734", "1724", "1744", "1714"],
                hint: "התחילו מאחדות (7 + 7). שימו לב שבתרגיל זה יש העברות בכל הטורים",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 897 \\\\ + 837 \\\\ \\hline 1734 \\end{array}$<br/><br/>אחדות: 7 + 7 = 14, נרשום 4 ונעביר 1<br/>עשרות: 1 + 9 + 3 = 13, נרשום 3 ונעביר 1<br/>מאות: 1 + 8 + 8 = 17<br/>התוצאה הסופית היא 1734"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 829 \\\\ + 294 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1123", "1113", "1133", "1103"],
                hint: "חברו את האחדות (9 + 4). בדקו בכל טור אם יש צורך בהעברה לטור הבא",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 829 \\\\ + 294 \\\\ \\hline 1123 \\end{array}$<br/><br/>אחדות: 9 + 4 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 2 + 9 = 12, נרשום 2 ונעביר 1<br/>מאות: 1 + 8 + 2 = 11<br/>התוצאה הסופית היא 1123"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 563 \\\\ + 468 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1031", "1021", "1041", "1011"],
                hint: "התחילו מאחדות (3 + 8). זכרו להעביר 1 כשהסכום גדול מ-9. בדקו היטב את ההעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 563 \\\\ + 468 \\\\ \\hline 1031 \\end{array}$<br/><br/>אחדות: 3 + 8 = 11, נרשום 1 ונעביר 1<br/>עשרות: 1 + 6 + 6 = 13, נרשום 3 ונעביר 1<br/>מאות: 1 + 5 + 4 = 10<br/>התוצאה הסופית היא 1031"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 735 \\\\ + 386 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1121", "1111", "1131", "1101"],
                hint: "חברו את האחדות (5 + 6). כשיש העברה, זכרו להוסיף אותה לטור הבא",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 735 \\\\ + 386 \\\\ \\hline 1121 \\end{array}$<br/><br/>אחדות: 5 + 6 = 11, נרשום 1 ונעביר 1<br/>עשרות: 1 + 3 + 8 = 12, נרשום 2 ונעביר 1<br/>מאות: 1 + 7 + 3 = 11<br/>התוצאה הסופית היא 1121"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 875 \\\\ + 664 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1539", "1529", "1549", "1519"],
                hint: "התחילו מאחדות (5 + 4). שימו לב במיוחד לטור המאות, שם הסכום גדול מ-9",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{2.5ex} \\\\ 875 \\\\ + 664 \\\\ \\hline 1539 \\end{array}$<br/><br/>אחדות: 5 + 4 = 9<br/>עשרות: 7 + 6 = 13, נרשום 3 ונעביר 1<br/>מאות: 1 + 8 + 6 = 15<br/>התוצאה הסופית היא 1539"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 956 \\\\ + 167 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1123", "1113", "1133", "1103"],
                hint: "חברו את האחדות (6 + 7). בדקו בכל טור אם יש צורך בהעברה לטור הבא",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 956 \\\\ + 167 \\\\ \\hline 1123 \\end{array}$<br/><br/>אחדות: 6 + 7 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 5 + 6 = 12, נרשום 2 ונעביר 1<br/>מאות: 1 + 9 + 1 = 11<br/>התוצאה הסופית היא 1123"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 674 \\\\ + 458 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1132", "1122", "1142", "1112"],
                hint: "התחילו מאחדות (4 + 8). זכרו להעביר 1 כשהסכום גדול מ-9. בדקו היטב את ההעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 674 \\\\ + 458 \\\\ \\hline 1132 \\end{array}$<br/><br/>אחדות: 4 + 8 = 12, נרשום 2 ונעביר 1<br/>עשרות: 1 + 7 + 5 = 13, נרשום 3 ונעביר 1<br/>מאות: 1 + 6 + 4 = 11<br/>התוצאה הסופית היא 1132"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 875 \\\\ + 744 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1619", "1609", "1629", "1599"],
                hint: "חברו כל טור בנפרד. שימו לב במיוחד לטור המאות, שם הסכום גדול מ-9",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\ ^1\\kern{2.5ex} \\\\ 875 \\\\ + 744 \\\\ \\hline 1619 \\end{array}$<br/><br/>אחדות: 5 + 4 = 9<br/>עשרות: 7 + 4 = 11, נרשום 1 ונעביר 1<br/>מאות: 1 + 8 + 7 = 16<br/>התוצאה הסופית היא 1619"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 765 \\\\ + 378 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1143", "1133", "1153", "1123"],
                hint: "התחילו מאחדות (5 + 8). זכרו להעביר 1 כשהסכום גדול מ-9. שימו לב להעברות בכל הטורים",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 765 \\\\ + 378 \\\\ \\hline 1143 \\end{array}$<br/><br/>אחדות: 5 + 8 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 6 + 7 = 14, נרשום 4 ונעביר 1<br/>מאות: 1 + 7 + 3 = 11<br/>התוצאה הסופית היא 1143"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 896 \\\\ + 247 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1143", "1133", "1153", "1123"],
                hint: "חברו את האחדות (6 + 7). כשיש העברה, זכרו להוסיף אותה לטור הבא",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0ex} \\ ^1\\kern{1.5ex} \\\\ 896 \\\\ + 247 \\\\ \\hline 1143 \\end{array}$<br/><br/>אחדות: 6 + 7 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 9 + 4 = 14, נרשום 4 ונעביר 1<br/>מאות: 1 + 8 + 2 = 11<br/>התוצאה הסופית היא 1143"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 875 \\\\ + 524 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1399", "1389", "1409", "1379"],
                hint: "חברו כל טור בנפרד. שימו לב במיוחד לטור המאות, שם הסכום גדול מ-9",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}\\\\ 875 \\\\ + 524 \\\\ \\hline 1399 \\end{array}$<br/><br/>אחדות: 5 + 4 = 9<br/>עשרות: 7 + 2 = 9<br/>מאות: 8 + 5 = 13<br/>התוצאה הסופית היא 1399"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 738 \\\\ + 415 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1153", "1143", "1163", "1133"],
                hint: "התחילו מאחדות (8 + 5). זכרו להעביר 1 כשהסכום גדול מ-9. שימו לב לטור המאות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 738 \\\\ + 415 \\\\ \\hline 1153 \\end{array}$<br/><br/>אחדות: 8 + 5 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 3 + 1 = 5<br/>מאות: 7 + 4 = 11<br/>התוצאה הסופית היא 1153"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 849 \\\\ + 304 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1153", "1143", "1163", "1133"],
                hint: "התחילו מאחדות (9 + 4). שימו לב שבטור העשרות יש 0, ובטור המאות הסכום גדול מ-9",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 849 \\\\ + 304 \\\\ \\hline 1153 \\end{array}$<br/><br/>אחדות: 9 + 4 = 13, נרשום 3 ונעביר 1<br/>עשרות: 1 + 4 + 0 = 5<br/>מאות: 8 + 3 = 11<br/>התוצאה הסופית היא 1153"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 89 \\\\ + 35 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["124", "134", "123", "125"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 89 \\\\ + 35 \\\\ \\hline 124 \\end{array}$<br/><br/>אחדות: $9 + 5 = 14$, נרשום 4 ונעביר 1<br/>עשרות: $1 + 8 + 3 = 12$, נרשום 2 ונעביר 1<br/>מאות: $1 = 1$<br/><br/>התוצאה הסופית היא 124"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 394 \\\\ + 315 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["709", "719", "708", "799"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{2.5ex} \\\\ 394 \\\\ + 315 \\\\ \\hline 709 \\end{array}$<br/><br/>אחדות: $4 + 5 = 9$<br/>עשרות: $9 + 1 = 0$, נעביר 1<br/>מאות: $1 + 3 + 3 = 7$<br/><br/>התוצאה הסופית היא 709"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 1897 \\\\ + 1859 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["3756", "3856", "3656", "3766"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 1897 \\\\ + 1859 \\\\ \\hline 3756 \\end{array}$<br/><br/>אחדות: $7 + 9 = 16$, נרשום 6 ונעביר 1<br/>עשרות: $1 + 9 + 5 = 15$, נרשום 5 ונעביר 1<br/>מאות: $1 + 8 + 8 = 17$, נרשום 7 ונעביר 1<br/>אלפים: $1 + 1 + 1 = 3$<br/><br/>התוצאה הסופית היא 3756"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 946 \\\\ + 946 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1892", "1992", "1882", "1893"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. זכרו שמחברים אותו מספר פעמיים",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex}^1\\kern{1.5ex} \\\\ 946 \\\\ + 946 \\\\ \\hline 1892 \\end{array}$<br/><br/>אחדות: $6 + 6 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 4 + 4 = 9$<br/>מאות: $9 + 9 = 18$, נרשום 8 ונעביר 1<br/>אלפים: $1 = 1$<br/><br/>התוצאה הסופית היא 1892"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 2159 \\\\ + 1878 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["4037", "4137", "3937", "4047"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 2159 \\\\ + 1878 \\\\ \\hline 4037 \\end{array}$<br/><br/>אחדות: $9 + 8 = 17$, נרשום 7 ונעביר 1<br/>עשרות: $1 + 5 + 7 = 13$, נרשום 3 ונעביר 1<br/>מאות: $1 + 1 + 8 = 10$, נרשום 0 ונעביר 1<br/>אלפים: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 4037"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 4587 \\\\ + 4577 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["9164", "9264", "9064", "9174"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 4587 \\\\ + 4577 \\\\ \\hline 9164 \\end{array}$<br/><br/>אחדות: $7 + 7 = 14$, נרשום 4 ונעביר 1<br/>עשרות: $1 + 8 + 7 = 16$, נרשום 6 ונעביר 1<br/>מאות: $1 + 5 + 5 = 11$, נרשום 1 ונעביר 1<br/>אלפים: $1 + 4 + 4 = 9$<br/><br/>התוצאה הסופית היא 9164"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 1289 \\\\ + 1289 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["2578", "2678", "2568", "2587"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. זכרו שמחברים אותו מספר פעמיים",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.5ex}^1\\kern{1.5ex} \\\\ 1289 \\\\ + 1289 \\\\ \\hline 2578 \\end{array}$<br/><br/>אחדות: $9 + 9 = 18$, נרשום 8 ונעביר 1<br/>עשרות: $1 + 8 + 8 = 17$, נרשום 7 ונעביר 1<br/>מאות: $1 + 2 + 2 = 5$<br/>אלפים: $1 + 1 = 2$<br/><br/>התוצאה הסופית היא 2578"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 3567 \\\\ + 3376 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["6943", "7043", "6843", "6953"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.5ex}^1\\kern{1.5ex} \\\\ 3567 \\\\ + 3376 \\\\ \\hline 6943 \\end{array}$<br/><br/>אחדות: $7 + 6 = 13$, נרשום 3 ונעביר 1<br/>עשרות: $1 + 6 + 7 = 14$, נרשום 4 ונעביר 1<br/>מאות: $1 + 5 + 3 = 9$<br/>אלפים: $3 + 3 = 6$<br/><br/>התוצאה הסופית היא 6943"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 4328 \\\\ + 3887 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["8215", "8315", "8115", "8225"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 4328 \\\\ + 3887 \\\\ \\hline 8215 \\end{array}$<br/><br/>אחדות: $8 + 7 = 15$, נרשום 5 ונעביר 1<br/>עשרות: $1 + 2 + 8 = 11$, נרשום 1 ונעביר 1<br/>מאות: $1 + 3 + 8 = 12$, נרשום 2 ונעביר 1<br/>אלפים: $1 + 4 + 3 = 8$<br/><br/>התוצאה הסופית היא 8215"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 2567 \\\\ + 2862 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["5429", "5529", "5329", "5439"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.5ex}^1\\kern{2.5ex} \\\\ 2567 \\\\ + 2862 \\\\ \\hline 5429 \\end{array}$<br/><br/>אחדות: $7 + 2 = 9$<br/>עשרות: $6 + 6 = 12$, נרשום 2 ונעביר 1<br/>מאות: $1 + 5 + 8 = 14$, נרשום 4 ונעביר 1<br/>אלפים: $1 + 2 + 2 = 5$<br/><br/>התוצאה הסופית היא 5429"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 3578 \\\\ + 3783 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["7361", "7461", "7261", "7371"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 3578 \\\\ + 3783 \\\\ \\hline 7361 \\end{array}$<br/><br/>אחדות: $8 + 3 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 7 + 8 = 16$, נרשום 6 ונעביר 1<br/>מאות: $1 + 5 + 7 = 13$, נרשום 3 ונעביר 1<br/>אלפים: $1 + 3 + 3 = 7$<br/><br/>התוצאה הסופית היא 7361"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r}\\\\ 967 \\\\ + 837 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["1804", "1904", "1704", "1814"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.5ex}^1\\kern{1.5ex} \\\\ 967 \\\\ + 837 \\\\ \\hline 1804 \\end{array}$<br/><br/>אחדות: $7 + 7 = 14$, נרשום 4 ונעביר 1<br/>עשרות: $1 + 6 + 3 = 10$, נרשום 0 ונעביר 1<br/>מאות: $1 + 9 + 8 = 18$<br/><br/>התוצאה הסופית היא 1804"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 394 \\\\ + 453 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["847", "857", "837", "846"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{2.5ex} \\\\ 394 \\\\ + 453 \\\\ \\hline 847 \\end{array}$<br/><br/>אחדות: $4 + 3 = 7$<br/>עשרות: $9 + 5 = 14$, נרשום 4 ונעביר 1<br/>מאות: $1 + 3 + 4 = 7$<br/><br/>התוצאה הסופית היא 847"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 156 \\\\ + 135 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["291", "281", "301", "292"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.5ex}^1\\kern{1.5ex} \\\\ 156 \\\\ + 135 \\\\ \\hline 291 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 5 + 3 = 9$<br/>מאות: $1 + 1 = 2$<br/><br/>התוצאה הסופית היא 291"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 328 \\\\ + 235 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["563", "573", "553", "564"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 328 \\\\ + 235 \\\\ \\hline 563 \\end{array}$<br/><br/>אחדות: $8 + 5 = 13$, נרשום 3 ונעביר 1<br/>עשרות: $1 + 2 + 3 = 6$<br/>מאות: $3 + 2 = 5$<br/><br/>התוצאה הסופית היא 563"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 567 \\\\ + 328 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["895", "885", "905", "894"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 567 \\\\ + 328 \\\\ \\hline 895 \\end{array}$<br/><br/>אחדות: $7 + 8 = 15$, נרשום 5 ונעביר 1<br/>עשרות: $1 + 6 + 2 = 9$<br/>מאות: $5 + 3 = 8$<br/><br/>התוצאה הסופית היא 895"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 436 \\\\ + 213 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["649", "659", "639", "648"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 436 \\\\ + 213 \\\\ \\hline 649 \\end{array}$<br/><br/>אחדות: $6 + 3 = 9$<br/>עשרות: $3 + 1 = 4$<br/>מאות: $4 + 2 = 6$<br/><br/>התוצאה הסופית היא 649"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 347 \\\\ + 237 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["584", "594", "574", "585"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 347 \\\\ + 237 \\\\ \\hline 584 \\end{array}$<br/><br/>אחדות: $7 + 7 = 14$, נרשום 4 ונעביר 1<br/>עשרות: $1 + 4 + 3 = 8$<br/>מאות: $3 + 2 = 5$<br/><br/>התוצאה הסופית היא 584"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 528 \\\\ + 225 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["753", "763", "743", "754"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 528 \\\\ + 225 \\\\ \\hline 753 \\end{array}$<br/><br/>אחדות: $8 + 5 = 13$, נרשום 3 ונעביר 1<br/>עשרות: $1 + 2 + 2 = 5$<br/>מאות: $5 + 2 = 7$<br/><br/>התוצאה הסופית היא 753"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 156 \\\\ + 133 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["289", "299", "279", "288"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 156 \\\\ + 133 \\\\ \\hline 289 \\end{array}$<br/><br/>אחדות: $6 + 3 = 9$<br/>עשרות: $5 + 3 = 8$<br/>מאות: $1 + 1 = 2$<br/><br/>התוצאה הסופית היא 289"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 378 \\\\ + 267 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["645", "655", "635", "646"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{1.5ex}\\\\ 378 \\\\ + 267 \\\\ \\hline 645 \\end{array}$<br/><br/>אחדות: $8 + 7 = 15$, נרשום 5 ונעביר 1<br/>עשרות: $1 + 7 + 6 = 14$, נרשום 4 ונעביר 1<br/>מאות: $1 + 3 + 2 = 6$<br/><br/>התוצאה הסופית היא 645"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 456 \\\\ + 375 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["831", "841", "821", "832"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 456 \\\\ + 375 \\\\ \\hline 831 \\end{array}$<br/><br/>אחדות: $6 + 5 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 5 + 7 = 13$, נרשום 3 ונעביר 1<br/>מאות: $1 + 4 + 3 = 8$<br/><br/>התוצאה הסופית היא 831"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 487 \\\\ + 455 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["942", "932", "952", "941"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 487 \\\\ + 455 \\\\ \\hline 942 \\end{array}$<br/><br/>אחדות: $7 + 5 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 8 + 5 = 14$, נרשום 4 ונעביר 1<br/>מאות: $1 + 4 + 4 = 9$<br/><br/>התוצאה הסופית היא 942"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 523 \\\\ + 415 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["938", "928", "948", "937"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 523 \\\\ + 415 \\\\ \\hline 938 \\end{array}$<br/><br/>אחדות: $3 + 5 = 8$<br/>עשרות: $2 + 1 = 3$<br/>מאות: $5 + 4 = 9$<br/><br/>התוצאה הסופית היא 938"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 268 \\\\ + 148 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["416", "426", "406", "417"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 268 \\\\ + 148 \\\\ \\hline 416 \\end{array}$<br/><br/>אחדות: $8 + 8 = 16$, נרשום 6 ונעביר 1<br/>עשרות: $1 + 6 + 4 = 11$, נרשום 1 ונעביר 1<br/>מאות: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 416"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 506 \\\\ + 386 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["892", "882", "902", "891"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 506 \\\\ + 386 \\\\ \\hline 892 \\end{array}$<br/><br/>אחדות: $6 + 6 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 0 + 8 = 9$<br/>מאות: $5 + 3 = 8$<br/><br/>התוצאה הסופית היא 892"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 425 \\\\ + 344 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["769", "779", "759", "768"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 425 \\\\ + 344 \\\\ \\hline 769 \\end{array}$<br/><br/>אחדות: $5 + 4 = 9$<br/>עשרות: $2 + 4 = 6$<br/>מאות: $4 + 3 = 7$<br/><br/>התוצאה הסופית היא 769"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 378 \\\\ + 237 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["615", "625", "605", "616"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 378 \\\\ + 237 \\\\ \\hline 615 \\end{array}$<br/><br/>אחדות: $8 + 7 = 15$, נרשום 5 ונעביר 1<br/>עשרות: $1 + 7 + 3 = 11$, נרשום 1 ונעביר 1<br/>מאות: $1 + 3 + 2 = 6$<br/><br/>התוצאה הסופית היא 615"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 567 \\\\ + 325 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["892", "902", "882", "893"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{1.5ex} \\\\ 567 \\\\ + 325 \\\\ \\hline 892 \\end{array}$<br/><br/>אחדות: $7 + 5 = 12$, נרשום 2 ונעביר 1<br/>עשרות: $1 + 6 + 2 = 9$<br/>מאות: $5 + 3 = 8$<br/><br/>התוצאה הסופית היא 892"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 456 \\\\ + 313 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["769", "779", "759", "768"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r} \\\\ 456 \\\\ + 313 \\\\ \\hline 769 \\end{array}$<br/><br/>אחדות: $6 + 3 = 9$<br/>עשרות: $5 + 1 = 6$<br/>מאות: $4 + 3 = 7$<br/><br/>התוצאה הסופית היא 769"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 234 \\\\ + 191 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["425", "435", "415", "426"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{2.5ex}\\\\ 234 \\\\ + 191 \\\\ \\hline 425 \\end{array}$<br/><br/>אחדות: $4 + 1 = 5$<br/>עשרות: $3 + 9 = 12$, נרשום 2 ונעביר 1<br/>מאות: $1 + 2 + 1 = 4$<br/><br/>התוצאה הסופית היא 425"
            },
            {
                question: "חשבו במאונך:$\\qquad$$\\begin{array}{r} \\\\ 378 \\\\ + 453 \\\\ \\hline ? \\end{array}$",
                type: "multiplechoice",
                options: ["831", "841", "821", "832"],
                hint: "חברו כל עמודה בנפרד, התחילו מימין. שימו לב להעברות",
                solutionGuide: "נסדר את המספרים זה תחת זה ונחבר מימין לשמאל:<br/><br/>$\\begin{array}{r}^1\\kern{0.3ex}^1\\kern{1.5ex} \\\\ 378 \\\\ + 453 \\\\ \\hline 831 \\end{array}$<br/><br/>אחדות: $8 + 3 = 11$, נרשום 1 ונעביר 1<br/>עשרות: $1 + 7 + 5 = 13$, נרשום 3 ונעביר 1<br/>מאות: $1 + 3 + 4 = 8$<br/><br/>התוצאה הסופית היא 831"
            }
        ]
    }
}; 