export const learningContent = {
    "שברים ומספרים עשרוניים": {
        tags: ['כיתה ה׳', 'שברים', 'מספרים עשרוניים'],
        relatedLinks: [
            { title: "תרגול שברים ומספרים עשרוניים", url: "/?tags=שברים,מספרים%20עשרוניים", description: "תרגול בעיות שברים ומספרים עשרוניים" }
        ],
        getStarted: [
            "**מהו שבר?**",
            "שבר מייצג חלק משלם. כשמחלקים עוגה ל-4 חלקים שווים ולוקחים 3 חלקים, זה $\\frac{3}{4}$ מהעוגה.",
            "<b>מונה (למעלה):</b>מספר החלקים שלקחנו",
            "<b>מכנה (למטה):</b>מספר החלקים השווים בשלם",
            "",
            "**סוגי שברים:**",
            "<b>שבר פשוט:</b> המונה קטן מהמכנה (למשל $\\frac{1}{2}$)",
            "<b>שבר מדומה:</b> המונה גדול מהמכנה (למשל $\\frac{5}{3}$ = $1\\frac{2}{3}$)",
            "<b>מספר מעורב:</b> מספר שלם + שבר פשוט (למשל $2\\frac{1}{4}$ = $\\frac{9}{4}$)",
            "",
            "**פעולות בשברים:**",
            "1. **חיבור וחיסור שברים:**",
            "   צריך להביא למכנה משותף",
            "   דוגמה: $\\frac{1}{2} + \\frac{1}{3}$ = $\\frac{3}{6} + \\frac{2}{6}$ = $\\frac{5}{6}$",
            "   מוצאים מכנה משותף על ידי כפולה משותפת של המכנים",
            "   טיפ: חפשו את המכנה המשותף הקטן ביותר!",
            "",
            "2. **כפל שברים:**",
            "   כופלים מונה במונה ומכנה במכנה",
            "   דוגמה: $\\frac{2}{3}\\times\\frac{3}{4}$ = $\\frac{2×3}{3×4}$ = $\\frac{6}{12}$ = $\\frac{1}{2}$",
            "   אפשר לצמצם לפני הכפל כדי להקל על החישוב",
            "   טיפ: תמיד בדקו אם אפשר לצמצם בין מונה למכנה של שברים שונים",
            "",
            "3. **חילוק שברים:**",
            "   הופכים את השבר השני ומכפילים",
            "   דוגמה: $\\frac{2}{3} ÷ \\frac{1}{4}$ = $\\frac{2}{3}\\times\\frac{4}{1}$ = $\\frac{8}{3}$",
            "   טיפ: לפני שמתחילים לכפול, בדקו אם אפשר לצמצם",
            "",
            "**השוואת שברים:**",
            "<b>שיטה 1:</b> הבאה למכנה משותף",
            "<b>שיטה 2:</b> המרה למספר עשרוני",
            "<b>שיטה 3:</b> השוואה לשבר מוכר (כמו $\\frac{1}{2}$ או $1$)",
            "",
            "**טיפים חשובים:**",
            "לצמצם שברים תמיד כשאפשר",
            "בחיבור וחיסור - לבדוק אם יש מכנה משותף פשוט",
            "בכפל - אפשר לצמצם בין מונה למכנה של שברים שונים",
            "לזכור: בחילוק הופכים ומכפילים"
        ],
        examples: [
            {
                question: "בכיתה יש 24 תלמידים. $\\frac{2}{3}$ מהם בחוג מתמטיקה. כמה תלמידים בחוג?",
                solution: "24 ÷ 3 = 8, 8\\times2 = 16. לכן 16 תלמידים בחוג.",
                drawing: {
                    type: "rectangleGrid",
                    rows: 4,
                    cols: 6,
                    highlightCells: 16,
                    caption: "24 תלמידים בסך הכל, 16 מתוכם (באדום) בחוג מתמטיקה"
                }
            },
            {
                question: "האם $\\frac{15}{35} = \\frac{6}{14}$?",
                solution: "$\\frac{15}{35} = \\frac{3}{7}$ ו-$\\frac{6}{14} = \\frac{3}{7}$, לכן הם שווים.",
                drawing: {
                    type: "fractionCircles",
                    fractions: ["15/35", "6/14", "3/7"],
                    caption: "שלושת השברים מייצגים את אותו החלק"
                }
            },
            {
                question: "חלקו פיצה ל-8 חלקים שווים ואכלו 3 חלקים. איזה חלק נשאר?",
                solution: "נשארו 5 חלקים מתוך 8, כלומר $\\frac{5}{8}$ מהפיצה.",
                drawing: {
                    type: "circle",
                    divisions: 8,
                    highlightSections: [4, 5, 6, 7, 8],
                    caption: "החלקים הצבועים מייצגים את החלק שנשאר מהפיצה"
                }
            },
            {
                question: "כמה זה $\\frac{1}{4} + \\frac{2}{4}$?",
                solution: "כשהמכנים שווים, פשוט מחברים את המונים: $\\frac{1+2}{4} = \\frac{3}{4}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 4,
                    highlights: {
                        first: [1],
                        second: [2, 3],
                        result: [1, 2, 3]
                    },
                    caption: "חיבור רבע ושני רבעים נותן שלושה רבעים"
                }
            },
            {
                question: "כמה זה $\\frac{2}{5}$ של 20?",
                solution: "20 ÷ 5 = 4, 4\\times2 = 8. לכן $\\frac{2}{5}$ של 20 זה 8.",
                drawing: {
                    type: "rectangleGrid",
                    rows: 4,
                    cols: 5,
                    highlightCells: 8,
                    caption: "20 פריטים בסך הכל, 8 מתוכם (באדום) מייצגים $\\frac{2}{5}$"
                }
            }
        ],
        quiz: [
            {
                question: "פיצה חולקה ל-8 חלקים. דני אכל 3 וגילי 2. כמה נשאר?",
                type: "multiplechoice",
                options: ["$\\frac{3}{8}$", "$\\frac{5}{8}$", "$\\frac{3}{5}$", "$\\frac{2}{3}$"],
                hint: "חשבו כמה חלקים נאכלו בסך הכל, והחסירו מהמספר הכולל של החלקים",
                solutionGuide: "מתוך 8 חלקים, נאכלו 5 חלקים (3 על ידי דני ו-2 על ידי גילי). לכן נשארו 3 חלקים מתוך 8, כלומר $\\frac{3}{8}$ מהפיצה."
            },
            {
                question: "איזה שבר גדול יותר $\\frac{4}{7}$ או $\\frac{5}{9}$ ?",
                type: "multiplechoice",
                options: ["$\\frac{4}{7}$", "הם שווים", "$\\frac{5}{9}$", "אי אפשר להשוות"],
                hint: "כדי להשוות שברים עם מכנים שונים, צריך להביא אותם למכנה משותף",
                solutionGuide: "כדי להשוות את השברים נביא אותם למכנה משותף:<br />$\\frac{4}{7}\\times\\frac{9}{9} = \\frac{36}{63}$<br />$\\frac{5}{9}\\times\\frac{7}{7} = \\frac{35}{63}$<br />כעת קל לראות ש-$\\frac{4}{7}$ גדול יותר כי $36 > 35$."
                       },
            {
                question: "מה התוצאה של $\\frac{1}{2} + \\frac{1}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{4}$", "$\\frac{1}{6}$", "$\\frac{2}{4}$", "$\\frac{2}{6}$"],
                hint: "כדי לחבר שברים, צריך קודם להביא אותם למכנה משותף. שימו לב ש-$\\frac{1}{2}$ אפשר לכתוב גם כ-$\\frac{2}{4}$",
                solutionGuide: "נביא למכנה משותף של 4:<br/>$\\frac{1}{2} = \\frac{2}{4}$<br/>עכשיו נחבר: $\\frac{2}{4} + \\frac{1}{4} = \\frac{3}{4}$"
            },
            {
                question: "מה התוצאה של $\\frac{2}{3} \\times \\frac{3}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{7}{12}$", "$\\frac{5}{12}$", "$\\frac{5}{7}$"],
                hint: "בכפל שברים, כופלים מונה במונה ומכנה במכנה. אחרי זה, בדקו אם אפשר לצמצם",
                solutionGuide: "נכפול מונים ומכנים: $\\frac{2\\times3}{3\\times4} = \\frac{6}{12}$<br />נצמצם ב-6: $\\frac{6}{12} = \\frac{1}{2}$"
            },
            {
                question: "איזה מהשברים הבאים מייצג את אותו ערך כמו $\\frac{8}{12}$?",
                type: "multiplechoice",
                options: ["$\\frac{2}{3}$", "$\\frac{3}{4}$", "$\\frac{5}{6}$", "אין אף תשובה נכונה"],
                hint: "נסו לצמצם את השבר $\\frac{8}{12}$ למספרים הקטנים ביותר האפשריים",
                solutionGuide: "נצמצם את השבר $\\frac{8}{12}$:<br />$\\frac{8}{12} = \\frac{8÷4}{12÷4} = \\frac{2}{3}$<br />לכן התשובה הנכונה היא $\\frac{2}{3}$"            
            },
            {
                question: "כמה זה $2\\frac{1}{3} + 1\\frac{1}{6}$?",
                type: "multiplechoice",
                options: ["$3\\frac{1}{2}$", "$3\\frac{2}{3}$", "$3\\frac{1}{4}$", "$3\\frac{1}{3}$"],
                hint: "המירו את המספרים המעורבים לשברים רגילים, ואז הביאו למכנה משותף",
                solutionGuide: "נהפוך למספרים מעורבים:<br />$2\\frac{1}{3} = \\frac{7}{3}$<br />$1\\frac{1}{6} = \\frac{7}{6}$<br />נביא למכנה משותף 6:<br />$\\frac{14}{6} + \\frac{7}{6} = \\frac{21}{6} = 3\\frac{1}{2}$"            },
            {
                question: "מה התוצאה של $\\frac{3}{4} \\div \\frac{1}{2}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{2}$", "$\\frac{3}{8}$", "$\\frac{2}{3}$", "$\\frac{5}{4}$"],
                hint: "בחילוק שברים, הופכים את השבר השני ומכפילים",
                solutionGuide: "בחילוק שברים, נכפול בהופכי:<br/>$\\frac{3}{4}\\times\\frac{2}{1} = \\frac{6}{4} = \\frac{3}{2}$"
            },
            {
                question: "בכיתה יש 30 תלמידים. $\\frac{2}{5}$ מהם בנים. כמה בנות יש בכיתה?",
                type: "multiplechoice",
                options: ["18", "20", "12", "15"],
                hint: "קודם מצאו כמה בנים יש, ואז החסירו מהמספר הכולל",
                solutionGuide: "מספר הבנים: $30\\times\\frac{2}{5} = 12$<br/>מספר הבנות: $30 - 12 = 18$"
            },
            {
                question: "מה התוצאה של $\\frac{5}{6} - \\frac{1}{3}$?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$", "$\\frac{1}{3}$", "$\\frac{1}{6}$", "$\\frac{2}{3}$"],
                hint: "כדי לחסר שברים, צריך להביא אותם למכנה משותף. שימו לב ש-$\\frac{1}{3}$ אפשר לכתוב כ-$\\frac{2}{6}$",
                solutionGuide: "נביא למכנה משותף 6:<br/>$\\frac{1}{3} = \\frac{2}{6}$<br/>עכשיו נחסר: $\\frac{5}{6} - \\frac{2}{6} = \\frac{3}{6} = \\frac{1}{2}$"
            },
            {
                question: "אם $\\frac{3}{4}$ של מספר הוא 18, מהו המספר?",
                type: "multiplechoice",
                options: ["24", "28", "22", "26"],
                hint: "אם $\\frac{3}{4}$ של מספר הוא 18, מצאו קודם כמה זה $\\frac{1}{4}$ של המספר",
                solutionGuide: "אם $\\frac{3}{4}$ של מספר = 18<br/>אז $\\frac{1}{4}$ של המספר = 6<br/>ולכן המספר השלם = $6\\times4 = 24$"
            },
            {
                question: "מה התוצאה של $\\frac{7}{8} + \\frac{5}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{2}$", "$\\frac{14}{8}$", "$\\frac{13}{8}$", "$2$"],
                hint: "כשהמכנים שווים, פשוט מחברים את המונים. אחרי זה, בדקו אם אפשר לצמצם",
                solutionGuide: "כשהמכנים שווים, מחברים את המונים: $\\frac{7+5}{8} = \\frac{12}{8} = \\frac{3}{2}$"
            },
            {
                question: "מה התוצאה של $\\frac{4}{5}\\times\\frac{10}{8}$?",
                type: "multiplechoice",
                options: ["1", "$\\frac{8}{5}$", "$\\frac{5}{4}$", "$\\frac{8}{40}$"],
                hint: "כפלו מונה במונה ומכנה במכנה, ואז צמצמו אם אפשר",
                solutionGuide: "$\\frac{4}{5}\\times\\frac{10}{8} = \\frac{40}{40} = 1$"
            },
            {
                question: "אם $\\frac{2}{3}$ של כיתה הם בנות וסך הכל בכיתה 36 תלמידים, כמה בנים יש?",
                type: "multiplechoice",
                options: ["12", "16", "24", "8"],
                hint: "קודם מצאו כמה בנות יש, ואז החסירו מהמספר הכולל",
                solutionGuide: "מספר הבנות: $36\\times\\frac{2}{3} = 24$, לכן מספר הבנים: $36 - 24 = 12$"
            },
            {
                question: "מה התוצאה של $\\frac{9}{4} - \\frac{3}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{2}$", "$\\frac{5}{4}$", "$\\frac{7}{4}$", "$\\frac{3}{4}$"],
                hint: "כשהמכנים שווים, פשוט מחסירים את המונים. אחרי זה, בדקו אם אפשר לצמצם",
                solutionGuide: "$\\frac{9-3}{4} = \\frac{6}{4} = \\frac{3}{2}$"
            },
            {
                question: "איך כותבים את המספר המעורב $2\\frac{3}{5}$ כשבר?",
                type: "multiplechoice",
                options: ["$\\frac{13}{5}$", "$\\frac{8}{5}$", "$\\frac{7}{5}$", "$\\frac{11}{5}$"],
                hint: "הכפילו את המספר השלם במכנה והוסיפו את המונה",
                solutionGuide: "$2\\frac{3}{5} = \\frac{10}{5} + \\frac{3}{5} = \\frac{13}{5}$"
            },
            {
                question: "מה התוצאה של $\\frac{15}{16} ÷ \\frac{3}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{4}$", "$\\frac{4}{5}$", "$\\frac{5}{3}$", "$\\frac{45}{64}$"],
                hint: "בחילוק שברים, הופכים את השבר השני ומכפילים. אחרי זה צמצמו אם אפשר",
                solutionGuide: "$\\frac{15}{16} ÷ \\frac{3}{4} = \\frac{15}{16}\\times\\frac{4}{3}  = \\frac{5}{4}$"
            },
            {
                question: "איזה שבר גדול יותר, $\\frac{7}{9}$ או $\\frac{5}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{6}$", "$\\frac{7}{9}$", "הם שווים", "אי אפשר להשוות"],
                hint: "הביאו את השברים למכנה משותף כדי להשוות ביניהם",
                solutionGuide: "נביא למכנה משותף 18:<br />$\\frac{7}{9}\\times\\frac{2}{2} = \\frac{14}{18}$<br />$\\frac{5}{6}\\times\\frac{3}{3} = \\frac{15}{18}$<br />כעת קל לראות ש-$\\frac{5}{6}$ גדול יותר כי $15 > 14$"            },
            {
                question: "מה התוצאה של $\\frac{8}{3} - \\frac{5}{3}$?",
                type: "multiplechoice",
                options: ["1", "$\\frac{8}{3}$", "$3$", "$\\frac{13}{3}$"],
                hint: "כשהמכנים שווים, פשוט מחסירים את המונים",
                solutionGuide: "$\\frac{8-5}{3} = \\frac{3}{3} = 1$"
            },
            {
                question: "אם $\\frac{5}{8}$ של עוגה נשאר, איזה חלק כבר אכלו ממנה?",
                type: "multiplechoice",
                options: ["$\\frac{3}{8}$", "$\\frac{5}{3}$", "$\\frac{8}{5}$", "$\\frac{2}{5}$"],
                hint: "החסירו את החלק שנשאר מ-1 (העוגה השלמה)",
                solutionGuide: "אם נשאר $\\frac{5}{8}$, אז נאכל $1 - \\frac{5}{8} = \\frac{3}{8}$"
            },
            {
                question: "מה התוצאה של $1\\frac{1}{4} + 2\\frac{3}{4}$?",
                type: "multiplechoice",
                options: ["$4$", "$3\\frac{3}{4}$", "$3\\frac{1}{2}$", "$4\\frac{1}{2}$"],
                hint: "המירו את המספרים המעורבים לשברים רגילים ואז חברו",
                solutionGuide: "$1\\frac{1}{4} + 2\\frac{3}{4} = \\frac{5}{4} + \\frac{11}{4} = \\frac{16}{4} = 4$"
            },
            {
                question: "איזה שבר שווה ל-$\\frac{24}{36}$?",
                type: "multiplechoice",
                options: ["$\\frac{2}{3}$", "$\\frac{1}{2}$", "$\\frac{1}{6}$", "$\\frac{3}{4}$"],
                hint: "נסו לצמצם את השבר על ידי חילוק המונה והמכנה באותו מספר",
                solutionGuide: "$\\frac{24}{36} = \\frac{24÷12}{36÷12} = \\frac{2}{3}$"
            },
            {
                question: "מה התוצאה של $\\frac{3}{5}\\times\\frac{10}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{2}{3}$", "$\\frac{9}{16}$", "$\\frac{3}{45}$", "$\\frac{3}{2}$"],
                hint: "כפלו מונה במונה ומכנה במכנה, ואז צמצמו אם אפשר",
                solutionGuide: "$\\frac{3}{5}\\times\\frac{10}{9} = \\frac{30}{45} = \\frac{2}{3}$"
            },
            {
                question: "אם $\\frac{1}{6}$ של כיתה חולים, וחולים 5 תלמידים, כמה תלמידים בכיתה?",
                type: "multiplechoice",
                options: ["30", "20", "25", "35"],
                hint: "אם $\\frac{1}{6}$ זה 5 תלמידים, כפלו ב-6 כדי למצוא את המספר השלם",
                solutionGuide: "אם $\\frac{1}{6}$ הם 5 תלמידים, אז בכיתה יש $5\\times6 = 30$ תלמידים"
            },
            {
                question: "מה התוצאה של $3\\frac{2}{5} - 1\\frac{3}{5}$?",
                type: "multiplechoice",
                options: ["$1\\frac{4}{5}$", "$2$", "$2\\frac{1}{5}$", "$1\\frac{3}{5}$"],
                hint: "המירו את המספרים המעורבים לשברים רגילים ואז חסרו",
                solutionGuide: "$3\\frac{2}{5} - 1\\frac{3}{5} = \\frac{17}{5} - \\frac{8}{5} = \\frac{9}{5} = 1\\frac{4}{5}$"
            },
            {
                question: "איזה שבר קטן מ-$\\frac{2}{3}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{7}$", "$\\frac{5}{7}$", "$\\frac{3}{4}$", "$\\frac{7}{10}$"],
                hint: "הביאו את כל השברים למכנה משותף כדי להשוות",
                solutionGuide: "נביא למכנה משותף 21:<br />$\\frac{2}{3} \\times \\frac{7}{7} = \\frac{14}{21}$<br />$\\frac{4}{7}\\times\\frac{3}{3} = \\frac{12}{21}$<br />כעת קל לראות ש-$\\frac{4}{7}$ קטן יותר כי $12 < 14$"            },
            {
                question: "מה התוצאה של $\\frac{5}{6} ÷ \\frac{5}{12}$?",
                type: "multiplechoice",
                options: ["$2$", "$\\frac{5}{2}$", "$\\frac{3}{2}$", "$\\frac{25}{30}$"],
                hint: "בחילוק שברים, הופכים את השבר השני ומכפילים. אחרי זה צמצמו אם אפשר",
                solutionGuide: "$\\frac{5}{6} ÷ \\frac{5}{12} = \\frac{5}{6}\\times\\frac{12}{5} = \\frac{60}{30} = 2$"
            },
            {
                question: "אם $\\frac{4}{9}$ של תלמידים הם בנים ויש 27 תלמידים, כמה בנות יש?",
                type: "multiplechoice",
                options: ["15", "9", "12", "18"],
                hint: "קודם מצאו כמה בנים יש, ואז החסירו מהמספר הכולל",
                solutionGuide: "מספר הבנים: $27\\times\\frac{4}{9} = 12$, לכן מספר הבנות: $27 - 12 = 15$"
            },
            {
                question: "מה התוצאה של $2\\frac{5}{8} + 3\\frac{3}{8}$?",
                type: "multiplechoice",
                options: ["$6$", "$5\\frac{1}{2}$", "$5\\frac{3}{4}$", "$5\\frac{7}{8}$"],
                hint: "כשהמכנים שווים בחלק השברי, אפשר לחבר את החלקים השלמים בנפרד ואת השברים בנפרד",
                solutionGuide: "$2\\frac{5}{8} + 3\\frac{3}{8} = 2+\\frac{5}{8} + 3+\\frac{3}{8} = 5+\\frac{8}{8} = 6$"
            },
            {
                question: "אם מחלקים עוגה ל-12 חלקים שווים ואוכלים 5 חלקים, איזה חלק נשאר?",
                type: "multiplechoice",
                options: ["$\\frac{7}{12}$", "$\\frac{2}{3}$", "$\\frac{5}{12}$", "$\\frac{1}{2}$"],
                hint: "החסירו את מספר החלקים שנאכלו מהמספר הכולל של החלקים",
                solutionGuide: "נשארו 7 חלקים מתוך 12, כלומר $\\frac{7}{12}$"
            },
            {
                question: "מה התוצאה של $\\frac{11}{12} - \\frac{5}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{1}{12}$", "$\\frac{1}{6}$", "$\\frac{1}{4}$", "$\\frac{1}{3}$"],
                hint: "הביאו את השברים למכנה משותף לפני החיסור. שימו לב ש-$\\frac{5}{6} = \\frac{10}{12}$",
                solutionGuide: "$\\frac{11}{12} - \\frac{5}{6} = \\frac{11}{12} - \\frac{10}{12} = \\frac{1}{12}$"
            }
        ]
    }
};