export const learningContent = {
    "סוגריים וסדר פעולות": {
        tags: ['כיתה ב׳', 'חשבון', 'סדר פעולות'],
        relatedLinks: [
            { title: "תרגול סדר פעולות חשבון", url: "/?tags=סדר%20פעולות", description: "תרגול בעיות סדר פעולות חשבון" }
        ],
        getStarted: [
            "**מה זה סדר פעולות?**",
            "כשיש כמה פעולות חשבון, צריך לפתור אותן בסדר מסוים:",
            "",
            "1️⃣ קודם פותרים מה שבתוך הסוגריים ()",
            "2️⃣ אחר כך כפל וחילוק",
            "3️⃣ בסוף חיבור וחיסור",
            "",
            "**למה צריך סוגריים?**",
            "סוגריים עוזרים לנו לדעת מה צריך לפתור קודם",
            "מה שבתוך הסוגריים תמיד פותרים ראשון",
            "",
            "**דוגמאות:**",
            "$2 × (3 + 4) = 2 × 7 = 14$",
            "$(5 - 2) × 3 = 3 × 3 = 9$",
            "$10 - (2 × 3) = 10 - 6 = 4$",
            "",
            "**טיפים חשובים:**",
            "✨ תמיד לפתור קודם מה שבתוך הסוגריים",
            "✨ לרשום את התוצאה של כל שלב",
            "✨ לעבוד לאט ובזהירות"
        ],
        examples: [
            {
                question: "חשבו: $3 × (5 - 2)$",
                solution: "1. נפתור קודם את הסוגריים: $5 - 2 = 3$<br/>2. נכפול: $3 × 3 = 9$<br/>התשובה היא $9$"
            },
            {
                question: "חשבו: $10 - (2 × 3)$",
                solution: "1. נפתור קודם את הסוגריים: $2 × 3 = 6$<br/>2. נחסר: $10 - 6 = 4$<br/>התשובה היא $4$"
            },
            {
                question: "חשבו: $(8 - 3) × 2$",
                solution: "1. נפתור קודם את הסוגריים: $8 - 3 = 5$<br/>2. נכפול: $5 × 2 = 10$<br/>התשובה היא $10$"
            }
        ],
        quiz: [
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} × ({B} + {C})$",
                params: {
                    A: { range: [2, 4], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [1, 2], type: "integer" }
                },
                formula: "A*(B+C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}*{B}+{C}",        
                        "{A}*{B}",   
                        "{A}*{C}"
                    ]
                },
                hint: "פתרו קודם את החיבור שבסוגריים, ואז כפלו",
                metadata: {
                    innerSum: "{B}+{C}",
                    wrongAnswer: "{A}*{B}+{C}"
                },
                solutionGuide: "1) בסוגריים: $ {B} + {C} = {innerSum} $<br/>2) כפל: $ {A} × {innerSum} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} × ({B} + {C})$",
                params: {
                    A: { range: [3, 5], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 3], type: "integer" }
                },
                formula: "A*(B+C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}*{B}+{C}",        // Ignores parentheses
                        "{A}+{B}*{C}",        // Wrong order
                        "{A}*{C}+{B}"         // Wrong multiplication order
                    ]
                },
                hint: "פתרו קודם את החיבור שבסוגריים, ואז כפלו",
                metadata: {
                    innerSum: "{B}+{C}",
                    wrongAnswer: "{A}*{B}+{C}"
                },
                solutionGuide: "1) בסוגריים: $ {B} + {C} = {innerSum} $<br/>2) כפל: $ {A} × {innerSum} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} + {B}) × {C} $",
                params: {
                    A: { range: [2, 3], type: "integer" },
                    B: { range: [1, 2], type: "integer" },
                    C: { range: [2, 3], type: "integer" }
                },
                formula: "(A+B)*C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}*{C}",        // Ignores parentheses
                        "{A}*{C}+{B}",        // Wrong operation order
                        "({A}*{C})+{B}"       // Wrong operation in parentheses
                    ]
                },
                hint: "פתרו קודם את החיבור שבסוגריים, ואז כפלו",
                metadata: {
                    innerSum: "{A}+{B}",
                    wrongAnswer: "{A}+{B}*{C}"
                },
                solutionGuide: "1) בסוגריים: $ {A} + {B} = {innerSum} $<br/>2) כפל: $ {innerSum} × {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} × ({B} - {C})$",
                params: {
                    A: { range: [2, 4], type: "integer" },
                    B: { range: [4, 6], type: "integer" },
                    C: { range: [1, 3], type: "integer" }
                },
                formula: "A*(B-C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}*{B}-{C}",        // Ignores parentheses
                        "{A}-{B}*{C}",        // Wrong operation order
                        "{A}*{B}"             // Incomplete calculation
                    ]
                },
                hint: "פתרו קודם את החיסור שבסוגריים, ואז כפלו",
                metadata: {
                    innerDiff: "{B}-{C}",
                    wrongAnswer: "{A}*{B}-{C}"
                },
                solutionGuide: "1) בסוגריים: $ {B} - {C} = {innerDiff} $<br/>2) כפל: $ {A} × {innerDiff} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} - {B}) × {C} $",
                params: {
                    A: { range: [4, 6], type: "integer" },
                    B: { range: [1, 2], type: "integer" },
                    C: { range: [2, 3], type: "integer" }
                },
                formula: "(A-B)*C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}+{C}",        // Common mistake: ignoring parentheses (9-2+2 = 9)
                        "({A}-{B})+{C}",      // Different grouping ((9-2)+2 = 9)
                        "{A}-{B}",            // Common mistake: ignoring second number (9-2 = 7)
                        "{A}-{C}",            // Common mistake: using only last number (9-2 = 7)
                        "{B}+{C}",            // Common mistake: ignoring first number (2+2 = 4)
                    ]
                },
                hint: "פתרו קודם את החיסור שבסוגריים, ואז כפלו",
                metadata: {
                    innerDiff: "{A}-{B}",
                    wrongAnswer: "{A}-{B}*{C}"
                },
                solutionGuide: "1) בסוגריים: $ {A} - {B} = {innerDiff} $<br/>2) כפל: $ {innerDiff} × {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - ({B} + {C})$",
                params: {
                    A: { range: [8, 10], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [1, 2], type: "integer" }
                },
                formula: "A-(B+C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}+{C}",        // Ignores parentheses
                        "{A}+{B}-{C}",        // Wrong operation order
                        "{A}-{B}"             // Incomplete calculation
                    ]
                },
                hint: "פתרו קודם את החיבור שבסוגריים, ואז חסרו מהמספר הראשון",
                metadata: {
                    innerSum: "{B}+{C}",
                    wrongAnswer: "{A}-{B}+{C}"
                },
                solutionGuide: "1) בסוגריים: $ {B} + {C} = {innerSum} $<br/>2) חיסור: $ {A} - {innerSum} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - ({B} + {C})$",
                params: {
                    A: { range: [7, 9], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 3], type: "integer" }
                },
                formula: "A-(B+C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}+{C}",        // Ignores parentheses
                        "{A}+{B}-{C}",        // Wrong operation order
                        "{A}-{B}"             // Incomplete calculation
                    ]
                },
                hint: "פתרו קודם את החיבור שבסוגריים, ואז חסרו מהמספר הראשון",
                metadata: {
                    innerSum: "{B}+{C}",
                    wrongAnswer: "{A}-{B}+{C}"
                },
                solutionGuide: "1) בסוגריים: $ {B} + {C} = {innerSum} $<br/>2) חיסור: $ {A} - {innerSum} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - ({B} - {C})$",
                params: {
                    A: { range: [6, 8], type: "integer" },
                    B: { range: [4, 5], type: "integer" },
                    C: { range: [1, 2], type: "integer" }
                },
                formula: "A-(B-C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}+{C}",        // Ignores parentheses
                        "{A}+{B}-{C}",        // Wrong operation order
                        "{A}-{B}"             // Incomplete calculation
                    ]
                },
                hint: "פתרו קודם את החיסור שבסוגריים, ואז חסרו מהמספר הראשון",
                metadata: {
                    innerDiff: "{B}-{C}",
                    wrongAnswer: "{A}-{B}+{C}"
                },
                solutionGuide: "1) בסוגריים: $ {B} - {C} = {innerDiff} $<br/>2) חיסור: $ {A} - {innerDiff} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} + {B}) - {C} $",
                params: {
                    A: { range: [3, 4], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 3], type: "integer" }
                },
                formula: "(A+B)-C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}-{C}",        // Ignores parentheses
                        "{A}-{B}+{C}",        // Wrong operation order
                        "{A}+{B}"             // Incomplete calculation
                    ]
                },
                hint: "פתרו קודם את החיבור שבסוגריים, ואז חסרו",
                metadata: {
                    innerSum: "{A}+{B}",
                    wrongAnswer: "{A}+{B}-{C}"
                },
                solutionGuide: "1) בסוגריים: $ {A} + {B} = {innerSum} $<br/>2) חיסור: $ {innerSum} - {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} - {B}) - {C} $",
                params: {
                    A: { range: [7, 9], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [1, 2], type: "integer" }
                },
                formula: "(A-B)-C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}-{C}",        // Ignores parentheses
                        "{A}+{B}-{C}",        // Wrong operation order
                        "{A}-{B}"             // Incomplete calculation
                    ]
                },
                hint: "פתרו קודם את החיסור שבסוגריים, ואז חסרו שוב",
                metadata: {
                    innerDiff: "{A}-{B}",
                    wrongAnswer: "{A}-{B}-{C}"
                },
                solutionGuide: "1) בסוגריים: $ {A} - {B} = {innerDiff} $<br/>2) חיסור: $ {innerDiff} - {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} + ({B} + {C})$",
                params: {
                    A: { range: [2, 5], type: "integer" },
                    B: { range: [2, 4], type: "integer" },
                    C: { range: [1, 3], type: "integer" }
                },
                formula: "A+(B+C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}+({B}-{C})",    
                    ]
                },
                hint: "פתרו קודם את מה שבתוך הסוגריים",
                metadata: {
                    innerSum: "{B}+{C}",
                    total: "{A}+({B}+{C})"
                },
                solutionGuide: "1) בסוגריים: $ {B} + {C} = {innerSum} $<br/>2) חיבור סופי: $ {A} + {innerSum} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} + {B}) + {C} $",
                params: {
                    A: { range: [2, 4], type: "integer" },
                    B: { range: [1, 3], type: "integer" },
                    C: { range: [2, 4], type: "integer" }
                },
                formula: "(A+B)+C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}+{B}-{C}",   
                    ]
                },
                hint: "פתרו קודם את מה שבתוך הסוגריים",
                metadata: {
                    innerSum: "{A}+{B}",
                    total: "({A}+{B})+{C}"
                },
                solutionGuide: "1) בסוגריים: $ {A} + {B} = {innerSum} $<br/>2) חיבור סופי: $ {innerSum} + {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} + {B}) + ({C} + {D})$",
                params: {
                    A: { range: [1, 3], type: "integer" },
                    B: { range: [1, 2], type: "integer" },
                    C: { range: [1, 2], type: "integer" },
                    D: { range: [1, 2], type: "integer" }
                },
                formula: "(A+B)+(C+D)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}+{B}-{C}+{D}",  
                    ]
                },
                hint: "פתרו קודם את שני הסוגריים בנפרד",
                metadata: {
                    firstPair: "{A}+{B}",
                    secondPair: "{C}+{D}"
                },
                solutionGuide: "1) סוגריים ראשונים: $ {A} + {B} = {firstPair} $<br/>2) סוגריים שניים: $ {C} + {D} = {secondPair} $<br/>3) חיבור סופי: $ {firstPair} + {secondPair} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} + ({B} - {C})$",
                params: {
                    A: { range: [3, 5], type: "integer" },
                    B: { range: [4, 6], type: "integer" },
                    C: { range: [1, 3], type: "integer" }
                },
                formula: "A+(B-C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}+({B}-{C})",    
                    ]
                },
                hint: "פתרו קודם את החיסור שבסוגריים",
                metadata: {
                    innerDiff: "{B}-{C}"
                },
                solutionGuide: "1) בסוגריים: $ {B} - {C} = {innerDiff} $<br/>2) חיבור סופי: $ {A} + {innerDiff} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} - {B}) + {C} $",
                params: {
                    A: { range: [5, 8], type: "integer" },
                    B: { range: [1, 3], type: "integer" },
                    C: { range: [2, 4], type: "integer" }
                },
                formula: "(A-B)+C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}-{B}+{C}",    
                    ]
                },
                hint: "פתרו קודם את החיסור שבסוגריים",
                metadata: {
                    innerDiff: "{A}-{B}"
                },
                solutionGuide: "1) בסוגריים: $ {A} - {B} = {innerDiff} $<br/>2) חיבור סופי: $ {innerDiff} + {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} + {B}) - {C} $",
                params: {
                    A: { range: [3, 5], type: "integer" },
                    B: { range: [2, 4], type: "integer" },
                    C: { range: [1, 3], type: "integer" }
                },
                formula: "(A+B)-C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}-{B}+{C}",    
                    ]
                },
                hint: "פתרו קודם את החיבור שבסוגריים",
                metadata: {
                    innerSum: "{A}+{B}"
                },
                solutionGuide: "1) בסוגריים: $ {A} + {B} = {innerSum} $<br/>2) חיסור סופי: $ {innerSum} - {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - ({B} + {C})$",
                params: {
                    A: { range: [7, 9], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [1, 2], type: "integer" }
                },
                formula: "A-(B+C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}-{B}+{C}",    
                    ]
                },
                hint: "פתרו קודם את החיבור שבסוגריים",
                metadata: {
                    innerSum: "{B}+{C}"
                },
                solutionGuide: "1) בסוגריים: $ {B} + {C} = {innerSum} $<br/>2) חיסור סופי: $ {A} - {innerSum} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} - {B}) - {C} $",
                params: {
                    A: { range: [6, 9], type: "integer" },
                    B: { range: [1, 3], type: "integer" },
                    C: { range: [1, 2], type: "integer" }
                },
                formula: "(A-B)-C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}-{B}+{C}",    
                    ]
                },
                hint: "פתרו קודם את החיסור שבסוגריים",
                metadata: {
                    innerDiff: "{A}-{B}"
                },
                solutionGuide: "1) בסוגריים: $ {A} - {B} = {innerDiff} $<br/>2) חיסור סופי: $ {innerDiff} - {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} + {B}) - ({C} + {D})$",
                params: {
                    A: { range: [4, 6], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [1, 2], type: "integer" },
                    D: { range: [1, 2], type: "integer" }
                },
                formula: "(A+B)-(C+D)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}+{B}+({C}-{D})",    
                        "({A}+{B}-{C})+{D}",  
                    ]
                },
                hint: "פתרו קודם את שני הסוגריים בנפרד",
                metadata: {
                    firstPair: "{A}+{B}",
                    secondPair: "{C}+{D}"
                },
                solutionGuide: "1) סוגריים ראשונים: $ {A} + {B} = {firstPair} $<br/>2) סוגריים שניים: $ {C} + {D} = {secondPair} $<br/>3) חיסור סופי: $ {firstPair} - {secondPair} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $({A} - {B}) + ({C} - {D})$",
                params: {
                    A: { range: [5, 7], type: "integer" },
                    B: { range: [1, 2], type: "integer" },
                    C: { range: [4, 5], type: "integer" },
                    D: { range: [1, 2], type: "integer" }
                },
                formula: "(A-B)+(C-D)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "plus-three"],
                    custom: [
                        "{A}-{B}-{C}-{D}",    
                        "{A}-{B}-{C}+{D}",  
                        "{A}-({B}-{C}-{D})"   
                    ]
                },
                hint: "פתרו קודם את שני הסוגריים בנפרד",
                metadata: {
                    firstPair: "{A}-{B}",
                    secondPair: "{C}-{D}"
                },
                solutionGuide: "1) סוגריים ראשונים: $ {A} - {B} = {firstPair} $<br/>2) סוגריים שניים: $ {C} - {D} = {secondPair} $<br/>3) חיבור סופי: $ {firstPair} + {secondPair} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} + {B} × {C} $",
                params: {
                    A: { range: [2, 5], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 4], type: "integer" }
                },
                formula: "A+B*C",
                distractors: {
                    strategies: ["plus-three", "off-by-one", "off-by-two"],
                    custom: [
                        "({A}+{B})*{C}",      
                        "{A}*{B}+{C}",        
                        "({A}*{B})+{C}"       
                    ]
                },
                hint: "זכרו: קודם כפל, אחר כך חיבור",
                metadata: {
                    multFirst: "{B}*{C}",
                    wrongAnswer: "({A}+{B})*{C}"
                },
                solutionGuide: "1) קודם כפל: $ {B} × {C} = {multFirst} $<br/>2) אחר כך חיבור: $ {A} + {multFirst} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} - {B} × {C} $",
                params: {
                    A: { range: [10, 15], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 3], type: "integer" }
                },
                formula: "A-B*C",
                distractors: {
                    strategies: ["plus-three", "off-by-one", "off-by-two"],
                    custom: [
                        "({A}-{B})*{C}",      
                        "{A}+{B}*{C}",        
                        "({A}*{B})-{C}"       
                    ]
                },
                hint: "זכרו: קודם כפל, אחר כך חיסור",
                metadata: {
                    multFirst: "{B}*{C}",
                    wrongAnswer: "({A}-{B})*{C}"
                },
                solutionGuide: "1) קודם כפל: $ {B} × {C} = {multFirst} $<br/>2) אחר כך חיסור: $ {A} - {multFirst} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {B} × {C} + {A} $",
                params: {
                    A: { range: [2, 4], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 3], type: "integer" }
                },
                formula: "B*C+A",
                distractors: {
                    strategies: ["plus-three", "off-by-one", "off-by-two"],
                    custom: [
                        "({B}*{C})+{A}",      
                        "{B}+{C}*{A}",        
                        "({B}+{C})*{A}"       
                    ]
                },
                hint: "זכרו: קודם כפל, אחר כך חיבור",
                metadata: {
                    multFirst: "{B}*{C}",
                    wrongAnswer: "{B}*({C}+{A})"
                },
                solutionGuide: "1) קודם כפל: $ {B} × {C} = {multFirst} $<br/>2) אחר כך חיבור: $ {multFirst} + {A} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} × {B} + {C} $",
                params: {
                    A: { range: [3, 4], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 4], type: "integer" }
                },
                formula: "A*B+C",
                distractors: {
                    strategies: ["plus-three", "off-by-one", "off-by-two"],
                    custom: [
                        "({A}*{B})+{C}",      
                        "{A}+{B}*{C}",        
                        "({A}+{B})*{C}"       
                    ]
                },
                hint: "זכרו: קודם כפל, אחר כך חיבור",
                metadata: {
                    multFirst: "{A}*{B}",
                    wrongAnswer: "{A}*({B}+{C})"
                },
                solutionGuide: "1) קודם כפל: $ {A} × {B} = {multFirst} $<br/>2) אחר כך חיבור: $ {multFirst} + {C} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "חשבו: $ {A} + {B} × {C} + {D} $",
                params: {
                    A: { range: [2, 3], type: "integer" },
                    B: { range: [2, 3], type: "integer" },
                    C: { range: [2, 3], type: "integer" },
                    D: { range: [1, 2], type: "integer" }
                },
                formula: "A+B*C+D",
                distractors: {
                    strategies: ["plus-three", "off-by-one", "off-by-two"],
                    custom: [
                        "({A}+{B}*{C})+{D}",      
                        "{A}+{B}+{C}*{D}",        
                        "({A}*{B})+{C}+{D}"       
                    ]
                },
                hint: "זכרו: קודם כפל, אחר כך חיבור משמאל לימין",
                metadata: {
                    multFirst: "{B}*{C}",
                    addFirst: "{A}+({B}*{C})"
                },
                solutionGuide: "1) קודם כפל: $ {B} × {C} = {multFirst} $<br/>2) חיבור משמאל: $ {A} + {multFirst} = {addFirst} $<br/>3) חיבור אחרון: $ {addFirst} + {D} = {Result} $"
            }
        ]
    }
};