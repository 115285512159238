export const learningContent = {
    "חיבור שברים עם אותו מכנה": {
        tags: ['כיתה ג׳', 'כיתה ד׳', 'שברים'],
        relatedLinks: [
            { title: "תרגול חיבור שברים", url: "/?tags=שברים", description: "תרגול חיבור שברים עם אותו המנה" },
            { title: "תרגול חשבון לשברים", url: "/?grade=כיתה%20ו׳&tags=שברים", description: "תרגול בעיות שברים מוכוונות כיתה ו׳" }
        ],
        getStarted: [
            "**מהו שבר?**",
            "שבר הוא חלק מדבר שלם",
            "למשל: אם מחלקים עוגה ל-4 חלקים שווים ולוקחים חלק אחד, זה $\\frac{1}{4}$ (רבע) מהעוגה",
            "",
            "**חלקי השבר:**",
            "<b>מונה:</b> המספר שלמעלה - כמה חלקים לקחנו",
            "<b>מכנה:</b> המספר שלמטה - לכמה חלקים שווים חילקנו את השלם",
            "",
            "**חיבור שברים עם אותו מכנה:**",
            "כשהמכנה זהה, פשוט מחברים את המונים",
            "המכנה נשאר אותו דבר",
            "למשל: $\\frac{1}{5} + \\frac{2}{5} = \\frac{3}{5}$",
            "",
            "**טיפים חשובים:**",
            "לוודא שהמכנים באמת זהים",
            "לחבר רק את המספרים שבמונה",
            "לא לשכוח לכתוב את המכנה בתשובה",
            "לצייר ציור אם צריך",
            "",
            "**איך לפתור?**",
            "1. בודקים אם המכנים זהים",
            "2. אם כן - מחברים את המונים",
            "3. רושמים את התוצאה מעל אותו מכנה",
            "4. בודקים אם אפשר לצמצם"
        ],
        examples: [
            {
                question: "חשבו: $\\frac{2}{6} + \\frac{3}{6}$",
                solution: "המכנים זהים (6), לכן נחבר את המונים: $2 + 3 = 5$<br/>התשובה: $\\frac{5}{6}$",
                drawing: {
                    type: "rectangleStrip",
                    divisions: 6,
                    highlights: {
                        first: [1, 2],
                        second: [3, 4, 5],
                        result: [1, 2, 3, 4, 5]
                    },
                    caption: "חיבור שני שישיות ושלושה שישיות נותן חמישה שישיות"
                }
            },
            {
                question: "חשבו: $\\frac{1}{4} + \\frac{2}{4}$",
                solution: "המכנים זהים (4), לכן נחבר את המונים: $1 + 2 = 3$<br/>התשובה: $\\frac{3}{4}$",
                drawing: {
                    type: "circle",
                    divisions: 4,
                    highlightSections: [1, 2, 3],
                    caption: "שלושה רבעים של עיגול"
                }
            },
            {
                question: "חשבו: $\\frac{4}{8} + \\frac{3}{8}$",
                solution: "המכנים זהים (8), לכן נחבר את המונים: $4 + 3 = 7$<br/>התשובה: $\\frac{7}{8}$",
                drawing: {
                    type: "rectangleGrid",
                    rows: 1,
                    cols: 8,
                    highlightCells: 7,
                    caption: "שבעה שמיניות של מלבן"
                }
            }
        ],
        quiz: [
            {
                question: "כמה זה $\\frac{2}{7} + \\frac{4}{7}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{7}$", "$\\frac{8}{7}$", "$\\frac{6}{14}$", "$\\frac{4}{7}$"],
                hint: "כשהמכנים זהים (7), מחברים רק את המספרים שבמונה",
                solutionGuide: "מחברים את המונים: $2 + 4 = 6$<br/>המכנה נשאר 7<br/>התשובה: $\\frac{6}{7}$"
            },
            {
                question: "כמה זה $\\frac{3}{5} + \\frac{1}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{3}{5}$", "$\\frac{4}{25}$", "$\\frac{2}{5}$"],
                hint: "המכנה הוא 5 בשני השברים. מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 1 = 4$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{4}{5}$"
            },
            {
                question: "כמה זה $\\frac{1}{8} + \\frac{6}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{8}$", "$\\frac{6}{8}$", "$\\frac{7}{16}$", "$\\frac{5}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים בלבד",
                solutionGuide: "מחברים את המונים: $1 + 6 = 7$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{7}{8}$"
            },
            {
                question: "כמה זה $\\frac{2}{6} + \\frac{2}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{6}$", "$\\frac{2}{6}$", "$\\frac{4}{36}$", "$\\frac{3}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 2 = 4$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{4}{6}$"
            },
            {
                question: "כמה זה $\\frac{3}{10} + \\frac{5}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{8}{10}$", "$\\frac{7}{10}$", "$\\frac{8}{20}$", "$\\frac{6}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 5 = 8$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{8}{10}$"
            },
            {
                question: "כמה זה $\\frac{1}{4} + \\frac{2}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{16}$", "$\\frac{4}{4}$"],
                hint: "המכנה זהה (4). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 2 = 3$<br/>המכנה נשאר 4<br/>התשובה: $\\frac{3}{4}$"
            },
            {
                question: "כמה זה $\\frac{4}{9} + \\frac{3}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{9}$", "$\\frac{6}{9}$", "$\\frac{7}{81}$", "$\\frac{5}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 3 = 7$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{7}{9}$"
            },
            {
                question: "כמה זה $\\frac{2}{3} + \\frac{1}{3}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{3}$", "$\\frac{2}{3}$", "$\\frac{3}{6}$", "$\\frac{4}{3}$"],
                hint: "המכנה זהה (3). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 1 = 3$<br/>המכנה נשאר 3<br/>התשובה: $\\frac{3}{3}$"
            },
            {
                question: "כמה זה $\\frac{5}{12} + \\frac{4}{12}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{12}$", "$\\frac{8}{12}$", "$\\frac{9}{24}$", "$\\frac{10}{12}$"],
                hint: "המכנה זהה (12). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 4 = 9$<br/>המכנה נשאר 12<br/>התשובה: $\\frac{9}{12}$"
            },
            {
                question: "כמה זה $\\frac{1}{6} + \\frac{4}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{6}$", "$\\frac{4}{6}$", "$\\frac{5}{12}$", "$\\frac{3}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 4 = 5$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{5}{6}$"
            },
            {
                question: "כמה זה $\\frac{2}{5} + \\frac{2}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{2}{5}$", "$\\frac{4}{25}$", "$\\frac{3}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 2 = 4$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{4}{5}$"
            },
            {
                question: "כמה זה $\\frac{3}{8} + \\frac{4}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{8}$", "$\\frac{6}{8}$", "$\\frac{7}{16}$", "$\\frac{5}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 4 = 7$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{7}{8}$"
            },
            {
                question: "כמה זה $\\frac{1}{10} + \\frac{6}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{10}$", "$\\frac{6}{10}$", "$\\frac{7}{100}$", "$\\frac{5}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 6 = 7$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{7}{10}$"
            },
            {
                question: "כמה זה $\\frac{2}{4} + \\frac{1}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{16}$", "$\\frac{1}{4}$"],
                hint: "המכנה זהה (4). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 1 = 3$<br/>המכנה נשאר 4<br/>התשובה: $\\frac{3}{4}$"
            },
            {
                question: "כמה זה $\\frac{3}{6} + \\frac{2}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{6}$", "$\\frac{4}{6}$", "$\\frac{5}{12}$", "$\\frac{6}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 2 = 5$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{5}{6}$"
            },
            {
                question: "כמה זה $\\frac{4}{7} + \\frac{2}{7}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{7}$", "$\\frac{5}{7}$", "$\\frac{6}{81}$", "$\\frac{7}{7}$"],
                hint: "המכנה זהה (7). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 2 = 6$<br/>המכנה נשאר 7<br/>התשובה: $\\frac{6}{7}$"
            },
            {
                question: "כמה זה $\\frac{5}{8} + \\frac{2}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{8}$", "$\\frac{6}{8}$", "$\\frac{7}{16}$", "$\\frac{8}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 2 = 7$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{7}{8}$"
            },
            {
                question: "כמה זה $\\frac{2}{9} + \\frac{5}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{9}$", "$\\frac{6}{9}$", "$\\frac{7}{81}$", "$\\frac{8}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 5 = 7$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{7}{9}$"
            },
            {
                question: "כמה זה $\\frac{3}{10} + \\frac{4}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{10}$", "$\\frac{6}{10}$", "$\\frac{7}{100}$", "$\\frac{8}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 4 = 7$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{7}{10}$"
            },
            {
                question: "כמה זה $\\frac{4}{12} + \\frac{5}{12}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{12}$", "$\\frac{8}{12}$", "$\\frac{9}{24}$", "$\\frac{10}{12}$"],
                hint: "המכנה זהה (12). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 5 = 9$<br/>המכנה נשאר 12<br/>התשובה: $\\frac{9}{12}$"
            },
            {
                question: "כמה זה $\\frac{1}{5} + \\frac{3}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{3}{5}$", "$\\frac{4}{25}$", "$\\frac{5}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 3 = 4$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{4}{5}$"
            },
            {
                question: "כמה זה $\\frac{2}{8} + \\frac{3}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{8}$", "$\\frac{4}{8}$", "$\\frac{5}{16}$", "$\\frac{6}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 3 = 5$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{5}{8}$"
            },
            {
                question: "כמה זה $\\frac{3}{6} + \\frac{1}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{24}$", "$\\frac{5}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 1 = 4$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{4}{6}$"
            },
            {
                question: "כמה זה $\\frac{2}{10} + \\frac{7}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{10}$", "$\\frac{8}{10}$", "$\\frac{9}{20}$", "$\\frac{7}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 7 = 9$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{9}{10}$"
            },
            {
                question: "כמה זה $\\frac{5}{9} + \\frac{3}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{8}{9}$", "$\\frac{7}{9}$", "$\\frac{8}{81}$", "$\\frac{9}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 3 = 8$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{8}{9}$"
            },
            {
                question: "כמה זה $\\frac{1}{3} + \\frac{1}{3}$?",
                type: "multiplechoice",
                options: ["$\\frac{2}{3}$", "$\\frac{1}{3}$", "$\\frac{3}{6}$", "$\\frac{3}{3}$"],
                hint: "המכנה זהה (3). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 1 = 2$<br/>המכנה נשאר 3<br/>התשובה: $\\frac{2}{3}$"
            },
            {
                question: "כמה זה $\\frac{4}{8} + \\frac{2}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{8}$", "$\\frac{5}{8}$", "$\\frac{6}{16}$", "$\\frac{7}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 2 = 6$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{6}{8}$"
            },
            {
                question: "כמה זה $\\frac{2}{4} + \\frac{1}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{16}$", "$\\frac{1}{4}$"],
                hint: "המכנה זהה (4). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 1 = 3$<br/>המכנה נשאר 4<br/>התשובה: $\\frac{3}{4}$"
            },
            {
                question: "כמה זה $\\frac{1}{6} + \\frac{3}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{24}$", "$\\frac{2}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 3 = 4$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{4}{6}$"
            },
            {
                question: "כמה זה $\\frac{3}{5} + \\frac{1}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{3}{5}$", "$\\frac{4}{25}$", "$\\frac{2}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 1 = 4$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{4}{5}$"
            },
            {
                question: "התאימו כל תרגיל לתוצאה המתאימה",
                type: "matching",
                pairs: [
                    { left: "$\\frac{2}{8} + \\frac{3}{8}$", right: "$\\frac{5}{8}$" },
                    { left: "$\\frac{2}{8} + \\frac{4}{8}$", right: "$\\frac{6}{8}$" },
                    { left: "$\\frac{4}{8} + \\frac{3}{8}$", right: "$\\frac{7}{8}$" },
                    { left: "$\\frac{4}{8} + \\frac{4}{8}$", right: "$\\frac{8}{8}$" }
                ],
                hint: "חברו את המונים כשהמכנה זהה",
                solutionGuide: "חברו את המונים בכל תרגיל: $\\frac{5}{8}, \\frac{6}{8}, \\frac{7}{8}, \\frac{8}{8}$"
            },
            {
                question: "התאימו כל שבר לשבר השלם שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{2}{6} + \\frac{4}{6}$", right: "$\\frac{6}{6}$" },
                    { left: "$\\frac{3}{4} + \\frac{1}{4}$", right: "$\\frac{4}{4}$" },
                    { left: "$\\frac{7}{8} + \\frac{1}{8}$", right: "$\\frac{8}{8}$" },
                    { left: "$\\frac{5}{10} + \\frac{5}{10}$", right: "$\\frac{10}{10}$" }
                ],
                hint: "חפשו תרגילים שהתוצאה שלהם היא שבר שהמונה שווה למכנה",
                solutionGuide: "כל תרגיל מוביל לשבר שלם: $\\frac{6}{6} = \\frac{4}{4} = \\frac{8}{8} = \\frac{10}{10} = 1$"
            },
            {
                question: "התאימו תרגילים עם אותה תוצאה",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{4} + \\frac{2}{4}$", right: "$\\frac{2}{4} + \\frac{1}{4}$" },
                    { left: "$\\frac{3}{6} + \\frac{1}{6}$", right: "$\\frac{2}{6} + \\frac{2}{6}$" },
                    { left: "$\\frac{2}{8} + \\frac{2}{8}$", right: "$\\frac{1}{8} + \\frac{3}{8}$" },
                    { left: "$\\frac{1}{10} + \\frac{4}{10}$", right: "$\\frac{2}{10} + \\frac{3}{10}$" }
                ],
                hint: "חברו את המונים בכל תרגיל ומצאו זוגות עם אותה תוצאה",
                solutionGuide: "כל זוג תרגילים נותן אותה תוצאה:<br/>$\\frac{3}{4}, \\frac{4}{6}, \\frac{4}{8}, \\frac{5}{10}$"
            },
            {
                question: "כמה כדורים אדומים יש בכל ציור מתוך סך כל הכדורים?",
                type: "matching",
                pairs: [
                    { left: "$\\frac{2}{3}$", right: "🔴🔴⚪" },
                    { left: "$\\frac{4}{5}$", right: "🔴🔴🔴🔴⚪" },
                    { left: "$\\frac{3}{5}$", right: "🔴🔴🔴⚪⚪" },
                    { left: "$\\frac{1}{3}$", right: "🔴⚪⚪" }
                ],
                hint: "🔴 מייצג חלק צבוע, ⚪ מייצג חלק לא צבוע. ספרו כמה חלקים צבועים מתוך הסך הכל",
                solutionGuide: "בכל ציור, המכנה הוא סך כל העיגולים והמונה הוא מספר העיגולים האדומים. למשל בשורה הראשונה: 2 עיגולים אדומים מתוך 3 סך הכל = $\\frac{2}{3}$"
            },
            {
                question: "התאימו בין התרגיל לתיאור המילולי",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{5} + \\frac{2}{5}$", right: "חמישית ועוד שתי חמישיות" },
                    { left: "$\\frac{2}{5} + \\frac{2}{5}$", right: "שתי חמישיות ועוד שתי חמישיות" },
                    { left: "$\\frac{3}{5} + \\frac{1}{5}$", right: "שלוש חמישיות ועוד חמישית" },
                    { left: "$\\frac{1}{5} + \\frac{4}{5}$", right: "חמישית ועוד ארבע חמישיות" }
                ],
                hint: "קראו את התיאור המילולי וחפשו את התרגיל המתאים",
                solutionGuide: "התאימו בין המספרים בתרגיל למילים בתיאור"
            },
            {
                question: "כמה לבבות סגולים יש בכל שורה מתוך סך כל הלבבות?",
                type: "matching",
                pairs: [
                    { left: "$\\frac{3}{4}$", right: "💜💜💜🤍" },
                    { left: "$\\frac{2}{4}$", right: "💜💜🤍🤍" },
                    { left: "$\\frac{1}{4}$", right: "💜🤍🤍🤍" },
                    { left: "$\\frac{4}{4}$", right: "💜💜💜💜" }
                ],
                hint: "💜 מייצג חלק צבוע, 🤍 מייצג חלק לא צבוע. ספרו כמה לבבות סגולים מתוך הסך הכל",
                solutionGuide: "בכל שורה, המכנה הוא סך כל הלבבות והמונה הוא מספר הלבבות הסגולים"
            },
            {
                question: "כמה לבבות כחולים יש בכל שורה מתוך סך כל הלבבות?",
                type: "matching",
                pairs: [
                    { left: "$\\frac{3}{5}$", right: "💙💙💙🤍🤍" },
                    { left: "$\\frac{4}{5}$", right: "💙💙💙💙🤍" },
                    { left: "$\\frac{2}{5}$", right: "💙💙🤍🤍🤍" },
                    { left: "$\\frac{5}{5}$", right: "💙💙💙💙💙" }
                ],
                hint: "💙 מייצג חלק צבוע, 🤍 מייצג חלק לא צבוע. ספרו כמה לבבות כחולים מתוך הסך הכל",
                solutionGuide: "בכל שורה, המכנה הוא סך כל הלבבות והמונה הוא מספר הלבבות הכחולים"
            },
            {
                question: "כמה לבבות סגולים יש בכל שורה מתוך סך כל הלבבות?",
                type: "matching",
                pairs: [
                    { left: "$\\frac{2}{6}$", right: "💜💜🤍🤍🤍🤍" },
                    { left: "$\\frac{4}{6}$", right: "💜💜💜💜🤍🤍" },
                    { left: "$\\frac{3}{6}$", right: "💜💜💜🤍🤍🤍" },
                    { left: "$\\frac{6}{6}$", right: "💜💜💜💜💜💜" }
                ],
                hint: "💜 מייצג חלק צבוע, 🤍 מייצג חלק לא צבוע. ספרו כמה לבבות סגולים מתוך הסך הכל",
                solutionGuide: "בכל שורה, המכנה הוא סך כל הלבבות והמונה הוא מספר הלבבות הסגולים"
            },
            {
                question: "כמה כוכבים כחולים יש בכל שורה מתוך סך כל הכוכבים?",
                type: "matching",
                pairs: [
                    { left: "$\\frac{2}{3}$", right: "💙💙🤍" },
                    { left: "$\\frac{1}{3}$", right: "💙🤍🤍" },
                    { left: "$\\frac{3}{3}$", right: "💙💙💙" }
                ],
                hint: "💙 מייצג חלק צבוע, 🤍 מייצג חלק לא צבוע. ספרו כמה כוכבים כחולים מתוך הסך הכל",
                solutionGuide: "בכל שורה, המכנה הוא סך כל הכוכבים והמונה הוא מספר הכוכבים הכחולים"
            },
            {
                question: "כמה לבבות ירוקים יש בכל שורה מתוך סך כל הלבבות?",
                type: "matching",
                pairs: [
                    { left: "$\\frac{5}{8}$", right: "💚💚💚💚💚🤍🤍🤍" },
                    { left: "$\\frac{6}{8}$", right: "💚💚💚💚💚💚🤍🤍" },
                    { left: "$\\frac{4}{8}$", right: "💚💚💚💚🤍🤍🤍🤍" },
                    { left: "$\\frac{8}{8}$", right: "💚💚💚💚💚💚💚💚" }
                ],
                hint: "💚 מייצג חלק צבוע, 🤍 מייצג חלק לא צבוע. ספרו כמה לבבות ירוקים מתוך הסך הכל",
                solutionGuide: "בכל שורה, המכנה הוא סך כל הלבבות והמונה הוא מספר הלבבות הירוקים"
            },
            {
                question: "התאימו כל שבר לייצוג המילולי שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{3}{4}$", right: "שלושה רבעים" },
                    { left: "$\\frac{2}{3}$", right: "שני שלישים" },
                    { left: "$\\frac{5}{8}$", right: "חמישה שמיניות" },
                    { left: "$\\frac{1}{6}$", right: "שישית" }
                ],
                hint: "קראו את השבר: המונה מציין את הכמות, המכנה את החלקים",
                solutionGuide: "שברים וקריאתם בעברית"
            },
            {
                question: "התאימו שברים שווים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "$\\frac{2}{4}$" },
                    { left: "$\\frac{2}{3}$", right: "$\\frac{4}{6}$" },
                    { left: "$\\frac{3}{4}$", right: "$\\frac{6}{8}$" },
                    { left: "$\\frac{1}{3}$", right: "$\\frac{2}{6}$" }
                ],
                hint: "הכפילו את המונה והמכנה באותו מספר",
                solutionGuide: "שברים שווים מתקבלים על ידי הכפלת המונה והמכנה באותו מספר"
            },
            {
                question: "התאימו כל שבר לחלק המתאים בפיצה",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{8}$ מפיצה", right: "פרוסה אחת מפיצה חתוכה ל-8" },
                    { left: "$\\frac{1}{4}$ מפיצה", right: "2 פרוסות מפיצה חתוכה ל-8" },
                    { left: "$\\frac{1}{2}$ מפיצה", right: "4 פרוסות מפיצה חתוכה ל-8" },
                    { left: "$\\frac{3}{4}$ מפיצה", right: "6 פרוסות מפיצה חתוכה ל-8" }
                ],
                hint: "חשבו כמה פרוסות יש בכל חלק של הפיצה",
                solutionGuide: "פיצה חתוכה ל-8 חלקים שווים: כל פרוסה היא שמינית"
            },
            {
                question: "התאימו כל שבר לייצוג הגרפי שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{3}$", right: "■□□" },
                    { left: "$\\frac{2}{4}$", right: "■■□□" },
                    { left: "$\\frac{3}{5}$", right: "■■■□□" },
                    { left: "$\\frac{1}{2}$", right: "■□" }
                ],
                hint: "ספרו כמה חלקים צבועים מתוך הכל",
                solutionGuide: "■ מייצג חלק צבוע, □ מייצג חלק לא צבוע"
            },
            {
                question: "התאימו בין שברים לכמויות",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{4}$ של 20", right: "5" },
                    { left: "$\\frac{1}{2}$ של 30", right: "15" },
                    { left: "$\\frac{3}{4}$ של 40", right: "30" },
                    { left: "$\\frac{2}{5}$ של 50", right: "20" }
                ],
                hint: "כפלו את המספר בשבר",
                solutionGuide: "מכפילים את המספר במונה ומחלקים במכנה"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{1}{8}$", "$\\frac{1}{4}$", "$\\frac{1}{3}$", "$\\frac{1}{2}$"],
                hint: "המירו את השברים למכנה משותף להשוואה",
                solutionGuide: "$\\frac{1}{8} < \\frac{1}{4} < \\frac{1}{3} < \\frac{1}{2}$"
            },
            {
                question: "התאימו בעיות מילוליות לפתרונן",
                type: "matching",
                pairs: [
                    { left: "חצי מ-30 תלמידים", right: "15" },
                    { left: "רבע מ-40 שקלים", right: "10" },
                    { left: "שליש מ-60 דקות", right: "20" },
                    { left: "שני חמישיות מ-50 עוגיות", right: "20" }
                ],
                hint: "המירו את הבעיה המילולית לתרגיל כפל בשבר",
                solutionGuide: "פותרים על ידי הכפלת המספר בשבר המתאים"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{2}{8} + \\frac{1}{8}$", "$\\frac{1}{4} + \\frac{1}{4}$", "$\\frac{5}{10} + \\frac{3}{10}$", "$\\frac{3}{6} + \\frac{2}{6}$"],
                hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "$\\frac{2}{8} + \\frac{1}{8} = \\frac{3}{8}$<br/><br/>$\\frac{1}{4} + \\frac{1}{4} = \\frac{2}{4} = \\frac{1}{2}$<br/><br/>$\\frac{5}{10} + \\frac{3}{10} = \\frac{8}{10} = \\frac{4}{5}$<br/><br/>$\\frac{3}{6} + \\frac{2}{6} = \\frac{5}{6}$<br/><br/>סדר: $\\frac{3}{8} < \\frac{1}{2} < \\frac{4}{5} < \\frac{5}{6}$"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{3}{10} + \\frac{2}{10}$", "$\\frac{4}{8} + \\frac{2}{8}$", "$\\frac{1}{6} + \\frac{4}{6}$", "$\\frac{5}{6} + \\frac{1}{6}$"],
                hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "$\\frac{3}{10} + \\frac{2}{10} = \\frac{5}{10} = \\frac{1}{2}$<br/><br/>\n$\\frac{4}{8} + \\frac{2}{8} = \\frac{6}{8} = \\frac{3}{4}$<br/><br/>\n$\\frac{1}{6} + \\frac{4}{6} = \\frac{5}{6}$<br/><br/>\n$\\frac{5}{6} + \\frac{1}{6} = \\frac{6}{6} = 1$<br/><br/>\nסדר: $\\frac{1}{2} < \\frac{3}{4} < \\frac{5}{6} < 1$"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{4}{10} + \\frac{1}{10}$", "$\\frac{3}{8} + \\frac{2}{8}$", "$\\frac{1}{4} + \\frac{2}{4}$", "$\\frac{2}{6} + \\frac{3}{6}$"],
                hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "$\\frac{4}{10} + \\frac{1}{10} = \\frac{5}{10} = \\frac{1}{2}$<br/><br/>$\\frac{3}{8} + \\frac{2}{8} = \\frac{5}{8}$<br/><br/>$\\frac{1}{4} + \\frac{2}{4} = \\frac{3}{4}$<br/><br/>$\\frac{2}{6} + \\frac{3}{6} = \\frac{5}{6}$<br/><br/>סדר: $\\frac{1}{2} < \\frac{5}{8} < \\frac{3}{4} < \\frac{5}{6}$"
            },
            {
                question: "כמה זה $\\frac{3}{7} + \\frac{2}{7}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{7}$", "$\\frac{6}{7}$", "$\\frac{5}{14}$", "$\\frac{4}{7}$"],
                hint: "המכנה זהה (7). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 2 = 5$<br/>המכנה נשאר 7<br/>התשובה: $\\frac{5}{7}$"
            },
            {
                question: "כמה זה $\\frac{4}{9} + \\frac{4}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{8}{9}$", "$\\frac{7}{9}$", "$\\frac{8}{18}$", "$\\frac{6}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 4 = 8$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{8}{9}$"
            },
            {
                question: "כמה זה $\\frac{2}{11} + \\frac{5}{11}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{11}$", "$\\frac{6}{11}$", "$\\frac{7}{22}$", "$\\frac{8}{11}$"],
                hint: "המכנה זהה (11). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 5 = 7$<br/>המכנה נשאר 11<br/>התשובה: $\\frac{7}{11}$"
            },
            {
                question: "כמה זה $\\frac{5}{8} + \\frac{2}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{8}$", "$\\frac{6}{8}$", "$\\frac{7}{16}$", "$\\frac{8}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 2 = 7$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{7}{8}$"
            },
            {
                question: "כמה זה $\\frac{3}{12} + \\frac{6}{12}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{12}$", "$\\frac{8}{12}$", "$\\frac{9}{24}$", "$\\frac{10}{12}$"],
                hint: "המכנה זהה (12). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 6 = 9$<br/>המכנה נשאר 12<br/>התשובה: $\\frac{9}{12}$"
            },
            {
                question: "כמה זה $\\frac{4}{10} + \\frac{3}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{10}$", "$\\frac{6}{10}$", "$\\frac{7}{20}$", "$\\frac{8}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 3 = 7$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{7}{10}$"
            },
            {
                question: "כמה זה $\\frac{2}{5} + \\frac{1}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{5}$", "$\\frac{2}{5}$", "$\\frac{3}{10}$", "$\\frac{4}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 1 = 3$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{3}{5}$"
            },
            {
                question: "כמה זה $\\frac{5}{6} + \\frac{1}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{6}$", "$\\frac{5}{6}$", "$\\frac{6}{12}$", "$\\frac{7}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 1 = 6$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{6}{6}$"
            },
            {
                question: "כמה זה $\\frac{3}{7} + \\frac{3}{7}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{7}$", "$\\frac{5}{7}$", "$\\frac{6}{14}$", "$\\frac{7}{7}$"],
                hint: "המכנה זהה (7). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 3 = 6$<br/>המכנה נשאר 7<br/>התשובה: $\\frac{6}{7}$"
            },
            {
                question: "כמה זה $\\frac{4}{8} + \\frac{3}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{8}$", "$\\frac{6}{8}$", "$\\frac{7}{16}$", "$\\frac{8}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 3 = 7$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{7}{8}$"
            },
            {
                question: "כמה זה $\\frac{2}{9} + \\frac{5}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{9}$", "$\\frac{6}{9}$", "$\\frac{7}{81}$", "$\\frac{8}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 5 = 7$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{7}{9}$"
            },
            {
                question: "כמה זה $\\frac{3}{10} + \\frac{4}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{10}$", "$\\frac{6}{10}$", "$\\frac{7}{20}$", "$\\frac{8}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 4 = 7$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{7}{10}$"
            },
            {
                question: "כמה זה $\\frac{4}{12} + \\frac{5}{12}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{12}$", "$\\frac{8}{12}$", "$\\frac{9}{24}$", "$\\frac{10}{12}$"],
                hint: "המכנה זהה (12). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 5 = 9$<br/>המכנה נשאר 12<br/>התשובה: $\\frac{9}{12}$"
            },
            {
                question: "כמה זה $\\frac{1}{5} + \\frac{3}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{3}{5}$", "$\\frac{4}{25}$", "$\\frac{5}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 3 = 4$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{4}{5}$"
            },
            {
                question: "כמה זה $\\frac{2}{8} + \\frac{3}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{8}$", "$\\frac{4}{8}$", "$\\frac{5}{16}$", "$\\frac{6}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 3 = 5$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{5}{8}$"
            },
            {
                question: "כמה זה $\\frac{3}{6} + \\frac{1}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{24}$", "$\\frac{5}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 1 = 4$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{4}{6}$"
            },
            {
                question: "כמה זה $\\frac{2}{10} + \\frac{7}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{10}$", "$\\frac{8}{10}$", "$\\frac{9}{20}$", "$\\frac{7}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 7 = 9$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{9}{10}$"
            },
            {
                question: "כמה זה $\\frac{5}{9} + \\frac{3}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{8}{9}$", "$\\frac{7}{9}$", "$\\frac{8}{81}$", "$\\frac{9}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 3 = 8$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{8}{9}$"
            },
            {
                question: "כמה זה $\\frac{1}{3} + \\frac{1}{3}$?",
                type: "multiplechoice",
                options: ["$\\frac{2}{3}$", "$\\frac{1}{3}$", "$\\frac{3}{6}$", "$\\frac{3}{3}$"],
                hint: "המכנה זהה (3). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 1 = 2$<br/>המכנה נשאר 3<br/>התשובה: $\\frac{2}{3}$"
            },
            {
                question: "כמה זה $\\frac{4}{8} + \\frac{2}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{8}$", "$\\frac{5}{8}$", "$\\frac{6}{16}$", "$\\frac{7}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 2 = 6$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{6}{8}$"
            },
            {
                question: "כמה זה $\\frac{2}{4} + \\frac{1}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{16}$", "$\\frac{1}{4}$"],
                hint: "המכנה זהה (4). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 1 = 3$<br/>המכנה נשאר 4<br/>התשובה: $\\frac{3}{4}$"
            },
            {
                question: "כמה זה $\\frac{1}{6} + \\frac{3}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{24}$", "$\\frac{2}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 3 = 4$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{4}{6}$"
            },
            {
                question: "כמה זה $\\frac{3}{5} + \\frac{1}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{3}{5}$", "$\\frac{4}{25}$", "$\\frac{2}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 1 = 4$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{4}{5}$"
            },
            {
                question: "התאימו כל שבר לייצוג המילולי שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{3}{4}$", right: "שלושה רבעים" },
                    { left: "$\\frac{2}{3}$", right: "שני שלישים" },
                    { left: "$\\frac{5}{8}$", right: "חמישה שמיניות" },
                    { left: "$\\frac{1}{6}$", right: "שישית" }
                ],
                hint: "קראו את השבר: המונה מציין את הכמות, המכנה את החלקים",
                solutionGuide: "שברים וקריאתם בעברית"
            },
            {
                question: "התאימו שברים שווים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "$\\frac{2}{4}$" },
                    { left: "$\\frac{2}{3}$", right: "$\\frac{4}{6}$" },
                    { left: "$\\frac{3}{4}$", right: "$\\frac{6}{8}$" },
                    { left: "$\\frac{1}{3}$", right: "$\\frac{2}{6}$" }
                ],
                hint: "הכפילו את המונה והמכנה באותו מספר",
                solutionGuide: "שברים שווים מתקבלים על ידי הכפלת המונה והמכנה באותו מספר"
            },
            {
                question: "התאימו כל שבר לחלק המתאים בפיצה",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{8}$ מפיצה", right: "פרוסה אחת מפיצה חתוכה ל-8" },
                    { left: "$\\frac{1}{4}$ מפיצה", right: "2 פרוסות מפיצה חתוכה ל-8" },
                    { left: "$\\frac{1}{2}$ מפיצה", right: "4 פרוסות מפיצה חתוכה ל-8" },
                    { left: "$\\frac{3}{4}$ מפיצה", right: "6 פרוסות מפיצה חתוכה ל-8" }
                ],
                hint: "חשבו כמה פרוסות יש בכל חלק של הפיצה",
                solutionGuide: "פיצה חתוכה ל-8 חלקים שווים: כל פרוסה היא שמינית"
            },
            {
                question: "התאימו כל שבר לייצוג הגרפי שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{3}$", right: "■□□" },
                    { left: "$\\frac{2}{4}$", right: "■■□□" },
                    { left: "$\\frac{3}{5}$", right: "■■■□□" },
                    { left: "$\\frac{1}{2}$", right: "■□" }
                ],
                hint: "ספרו כמה חלקים צבועים מתוך הכל",
                solutionGuide: "■ מייצג חלק צבוע, □ מייצג חלק לא צבוע"
            },
            {
                question: "התאימו בין שברים לכמויות",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{4}$ של 20", right: "5" },
                    { left: "$\\frac{1}{2}$ של 30", right: "15" },
                    { left: "$\\frac{3}{4}$ של 40", right: "30" },
                    { left: "$\\frac{2}{5}$ של 50", right: "20" }
                ],
                hint: "כפלו את המספר בשבר",
                solutionGuide: "מכפילים את המספר במונה ומחלקים במכנה"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{1}{8}$", "$\\frac{1}{4}$", "$\\frac{1}{3}$", "$\\frac{1}{2}$"],
                hint: "המירו את השברים למכנה משותף להשוואה",
                solutionGuide: "$\\frac{1}{8} < \\frac{1}{4} < \\frac{1}{3} < \\frac{1}{2}$"
            },
            {
                question: "התאימו בעיות מילוליות לפתרונן",
                type: "matching",
                pairs: [
                    { left: "חצי מ-30 תלמידים", right: "15" },
                    { left: "רבע מ-40 שקלים", right: "10" },
                    { left: "שליש מ-60 דקות", right: "20" },
                    { left: "שני חמישיות מ-50 עוגיות", right: "20" }
                ],
                hint: "המירו את הבעיה המילולית לתרגיל כפל בשבר",
                solutionGuide: "פותרים על ידי הכפלת המספר בשבר המתאים"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{2}{8} + \\frac{1}{8}$", "$\\frac{1}{4} + \\frac{1}{4}$", "$\\frac{5}{10} + \\frac{3}{10}$", "$\\frac{3}{6} + \\frac{2}{6}$"],
                hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "$\\frac{2}{8} + \\frac{1}{8} = \\frac{3}{8}$<br/><br/>$\\frac{1}{4} + \\frac{1}{4} = \\frac{2}{4} = \\frac{1}{2}$<br/><br/>$\\frac{5}{10} + \\frac{3}{10} = \\frac{8}{10} = \\frac{4}{5}$<br/><br/>$\\frac{3}{6} + \\frac{2}{6} = \\frac{5}{6}$<br/><br/>סדר: $\\frac{3}{8} < \\frac{1}{2} < \\frac{4}{5} < \\frac{5}{6}$"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{3}{10} + \\frac{2}{10}$", "$\\frac{4}{8} + \\frac{2}{8}$", "$\\frac{1}{6} + \\frac{4}{6}$", "$\\frac{5}{6} + \\frac{1}{6}$"],
                hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "$\\frac{3}{10} + \\frac{2}{10} = \\frac{5}{10} = \\frac{1}{2}$<br/><br/>\n$\\frac{4}{8} + \\frac{2}{8} = \\frac{6}{8} = \\frac{3}{4}$<br/><br/>\n$\\frac{1}{6} + \\frac{4}{6} = \\frac{5}{6}$<br/><br/>\n$\\frac{5}{6} + \\frac{1}{6} = \\frac{6}{6} = 1$<br/><br/>\nסדר: $\\frac{1}{2} < \\frac{3}{4} < \\frac{5}{6} < 1$"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{4}{10} + \\frac{1}{10}$", "$\\frac{3}{8} + \\frac{2}{8}$", "$\\frac{1}{4} + \\frac{2}{4}$", "$\\frac{2}{6} + \\frac{3}{6}$"],
                hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "$\\frac{4}{10} + \\frac{1}{10} = \\frac{5}{10} = \\frac{1}{2}$<br/><br/>$\\frac{3}{8} + \\frac{2}{8} = \\frac{5}{8}$<br/><br/>$\\frac{1}{4} + \\frac{2}{4} = \\frac{3}{4}$<br/><br/>$\\frac{2}{6} + \\frac{3}{6} = \\frac{5}{6}$<br/><br/>סדר: $\\frac{1}{2} < \\frac{5}{8} < \\frac{3}{4} < \\frac{5}{6}$"
            },
            {
                question: "כמה זה $\\frac{3}{7} + \\frac{2}{7}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{7}$", "$\\frac{6}{7}$", "$\\frac{5}{14}$", "$\\frac{4}{7}$"],
                hint: "המכנה זהה (7). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 2 = 5$<br/>המכנה נשאר 7<br/>התשובה: $\\frac{5}{7}$"
            },
            {
                question: "כמה זה $\\frac{4}{9} + \\frac{4}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{8}{9}$", "$\\frac{7}{9}$", "$\\frac{8}{18}$", "$\\frac{6}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 4 = 8$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{8}{9}$"
            },
            {
                question: "כמה זה $\\frac{2}{11} + \\frac{5}{11}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{11}$", "$\\frac{6}{11}$", "$\\frac{7}{22}$", "$\\frac{8}{11}$"],
                hint: "המכנה זהה (11). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 5 = 7$<br/>המכנה נשאר 11<br/>התשובה: $\\frac{7}{11}$"
            },
            {
                question: "כמה זה $\\frac{5}{8} + \\frac{2}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{8}$", "$\\frac{6}{8}$", "$\\frac{7}{16}$", "$\\frac{8}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 2 = 7$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{7}{8}$"
            },
            {
                question: "כמה זה $\\frac{3}{12} + \\frac{6}{12}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{12}$", "$\\frac{8}{12}$", "$\\frac{9}{24}$", "$\\frac{10}{12}$"],
                hint: "המכנה זהה (12). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 6 = 9$<br/>המכנה נשאר 12<br/>התשובה: $\\frac{9}{12}$"
            },
            {
                question: "כמה זה $\\frac{4}{10} + \\frac{3}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{10}$", "$\\frac{6}{10}$", "$\\frac{7}{20}$", "$\\frac{8}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 3 = 7$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{7}{10}$"
            },
            {
                question: "כמה זה $\\frac{2}{5} + \\frac{1}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{5}$", "$\\frac{2}{5}$", "$\\frac{3}{10}$", "$\\frac{4}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 1 = 3$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{3}{5}$"
            },
            {
                question: "כמה זה $\\frac{5}{6} + \\frac{1}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{6}$", "$\\frac{5}{6}$", "$\\frac{6}{12}$", "$\\frac{7}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 1 = 6$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{6}{6}$"
            },
            {
                question: "כמה זה $\\frac{3}{7} + \\frac{3}{7}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{7}$", "$\\frac{5}{7}$", "$\\frac{6}{14}$", "$\\frac{7}{7}$"],
                hint: "המכנה זהה (7). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 3 = 6$<br/>המכנה נשאר 7<br/>התשובה: $\\frac{6}{7}$"
            },
            {
                question: "כמה זה $\\frac{4}{8} + \\frac{3}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{8}$", "$\\frac{6}{8}$", "$\\frac{7}{16}$", "$\\frac{8}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 3 = 7$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{7}{8}$"
            },
            {
                question: "כמה זה $\\frac{2}{9} + \\frac{5}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{9}$", "$\\frac{6}{9}$", "$\\frac{7}{81}$", "$\\frac{8}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 5 = 7$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{7}{9}$"
            },
            {
                question: "כמה זה $\\frac{3}{10} + \\frac{4}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{7}{10}$", "$\\frac{6}{10}$", "$\\frac{7}{20}$", "$\\frac{8}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 4 = 7$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{7}{10}$"
            },
            {
                question: "כמה זה $\\frac{4}{12} + \\frac{5}{12}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{12}$", "$\\frac{8}{12}$", "$\\frac{9}{24}$", "$\\frac{10}{12}$"],
                hint: "המכנה זהה (12). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 5 = 9$<br/>המכנה נשאר 12<br/>התשובה: $\\frac{9}{12}$"
            },
            {
                question: "כמה זה $\\frac{1}{5} + \\frac{3}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{3}{5}$", "$\\frac{4}{25}$", "$\\frac{5}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 3 = 4$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{4}{5}$"
            },
            {
                question: "כמה זה $\\frac{2}{8} + \\frac{3}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{5}{8}$", "$\\frac{4}{8}$", "$\\frac{5}{16}$", "$\\frac{6}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 3 = 5$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{5}{8}$"
            },
            {
                question: "כמה זה $\\frac{3}{6} + \\frac{1}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{24}$", "$\\frac{5}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 1 = 4$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{4}{6}$"
            },
            {
                question: "כמה זה $\\frac{2}{10} + \\frac{7}{10}$?",
                type: "multiplechoice",
                options: ["$\\frac{9}{10}$", "$\\frac{8}{10}$", "$\\frac{9}{20}$", "$\\frac{7}{10}$"],
                hint: "המכנה זהה (10). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 7 = 9$<br/>המכנה נשאר 10<br/>התשובה: $\\frac{9}{10}$"
            },
            {
                question: "כמה זה $\\frac{5}{9} + \\frac{3}{9}$?",
                type: "multiplechoice",
                options: ["$\\frac{8}{9}$", "$\\frac{7}{9}$", "$\\frac{8}{81}$", "$\\frac{9}{9}$"],
                hint: "המכנה זהה (9). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $5 + 3 = 8$<br/>המכנה נשאר 9<br/>התשובה: $\\frac{8}{9}$"
            },
            {
                question: "כמה זה $\\frac{1}{3} + \\frac{1}{3}$?",
                type: "multiplechoice",
                options: ["$\\frac{2}{3}$", "$\\frac{1}{3}$", "$\\frac{3}{6}$", "$\\frac{3}{3}$"],
                hint: "המכנה זהה (3). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 1 = 2$<br/>המכנה נשאר 3<br/>התשובה: $\\frac{2}{3}$"
            },
            {
                question: "כמה זה $\\frac{4}{8} + \\frac{2}{8}$?",
                type: "multiplechoice",
                options: ["$\\frac{6}{8}$", "$\\frac{5}{8}$", "$\\frac{6}{16}$", "$\\frac{7}{8}$"],
                hint: "המכנה זהה (8). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $4 + 2 = 6$<br/>המכנה נשאר 8<br/>התשובה: $\\frac{6}{8}$"
            },
            {
                question: "כמה זה $\\frac{2}{4} + \\frac{1}{4}$?",
                type: "multiplechoice",
                options: ["$\\frac{3}{4}$", "$\\frac{2}{4}$", "$\\frac{3}{16}$", "$\\frac{1}{4}$"],
                hint: "המכנה זהה (4). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $2 + 1 = 3$<br/>המכנה נשאר 4<br/>התשובה: $\\frac{3}{4}$"
            },
            {
                question: "כמה זה $\\frac{1}{6} + \\frac{3}{6}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{6}$", "$\\frac{3}{6}$", "$\\frac{4}{24}$", "$\\frac{2}{6}$"],
                hint: "המכנה זהה (6). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $1 + 3 = 4$<br/>המכנה נשאר 6<br/>התשובה: $\\frac{4}{6}$"
            },
            {
                question: "כמה זה $\\frac{3}{5} + \\frac{1}{5}$?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{3}{5}$", "$\\frac{4}{25}$", "$\\frac{2}{5}$"],
                hint: "המכנה זהה (5). מחברים את המונים",
                solutionGuide: "מחברים את המונים: $3 + 1 = 4$<br/>המכנה נשאר 5<br/>התשובה: $\\frac{4}{5}$"
            },
            {
                question: "התאימו כל שבר לייצוג המילולי שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{3}{4}$", right: "שלושה רבעים" },
                    { left: "$\\frac{2}{3}$", right: "שני שלישים" },
                    { left: "$\\frac{5}{8}$", right: "חמישה שמיניות" },
                    { left: "$\\frac{1}{6}$", right: "שישית" }
                ],
                hint: "קראו את השבר: המונה מציין את הכמות, המכנה את החלקים",
                solutionGuide: "שברים וקריאתם בעברית"
            },
            {
                question: "התאימו שברים שווים",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2}$", right: "$\\frac{2}{4}$" },
                    { left: "$\\frac{2}{3}$", right: "$\\frac{4}{6}$" },
                    { left: "$\\frac{3}{4}$", right: "$\\frac{6}{8}$" },
                    { left: "$\\frac{1}{3}$", right: "$\\frac{2}{6}$" }
                ],
                hint: "הכפילו את המונה והמכנה באותו מספר",
                solutionGuide: "שברים שווים מתקבלים על ידי הכפלת המונה והמכנה באותו מספר"
            },
            {
                question: "התאימו כל שבר לחלק המתאים בפיצה",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{8}$ מפיצה", right: "פרוסה אחת מפיצה חתוכה ל-8" },
                    { left: "$\\frac{1}{4}$ מפיצה", right: "2 פרוסות מפיצה חתוכה ל-8" },
                    { left: "$\\frac{1}{2}$ מפיצה", right: "4 פרוסות מפיצה חתוכה ל-8" },
                    { left: "$\\frac{3}{4}$ מפיצה", right: "6 פרוסות מפיצה חתוכה ל-8" }
                ],
                hint: "חשבו כמה פרוסות יש בכל חלק של הפיצה",
                solutionGuide: "פיצה חתוכה ל-8 חלקים שווים: כל פרוסה היא שמינית"
            },
            {
                question: "התאימו כל שבר לייצוג הגרפי שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{3}$", right: "■□□" },
                    { left: "$\\frac{2}{4}$", right: "■■□□" },
                    { left: "$\\frac{3}{5}$", right: "■■■□□" },
                    { left: "$\\frac{1}{2}$", right: "■□" }
                ],
                hint: "ספרו כמה חלקים צבועים מתוך הכל",
                solutionGuide: "■ מייצג חלק צבוע, □ מייצג חלק לא צבוע"
            },
            {
                question: "התאימו בין שברים לכמויות",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{4}$ של 20", right: "5" },
                    { left: "$\\frac{1}{2}$ של 30", right: "15" },
                    { left: "$\\frac{3}{4}$ של 40", right: "30" },
                    { left: "$\\frac{2}{5}$ של 50", right: "20" }
                ],
                hint: "כפלו את המספר בשבר",
                solutionGuide: "מכפילים את המספר במונה ומחלקים במכנה"
            },
            {
                question: "סדרו את השברים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{1}{8}$", "$\\frac{1}{4}$", "$\\frac{1}{3}$", "$\\frac{1}{2}$"],
                hint: "המירו את השברים למכנה משותף להשוואה",
                solutionGuide: "$\\frac{1}{8} < \\frac{1}{4} < \\frac{1}{3} < \\frac{1}{2}$"
            }
        ]
    }
};