export const calculateMastery = (quizAttempts) => {
  if (!quizAttempts?.length) return 0;

  const recentAttempts = quizAttempts
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, 5);

  const scores = recentAttempts.map(attempt => 
    attempt.correctAnswers / attempt.totalQuestions
  );

  // Adjust weights based on number of attempts
  const baseWeights = [0.05, 0.15, 0.2, 0.25, 0.35];
  const weights = baseWeights.slice(5 - scores.length);
  const totalWeight = weights.reduce((sum, w) => sum + w, 0);
  
  // Normalize weights to sum to 1
  const normalizedWeights = weights.map(w => w / totalWeight);
  
  const weightedScore = scores.reduce((sum, score, i) => 
    sum + (score * normalizedWeights[i]), 0);

  const hasHighStreak = scores.length >= 3 && 
    scores.slice(0, 3).every(score => score >= 0.9);
  const bonus = hasHighStreak ? 1.05 : 1;

  return Math.min(Math.round(weightedScore * 100 * bonus), 100);
};

export const calculateSuccessRate = (quizAttempts) => {
  if (!quizAttempts?.length) return 0;

  const recentAttempts = quizAttempts
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, 10);
  const scores = recentAttempts.map(attempt => 
    attempt.correctAnswers / attempt.totalQuestions

  );

  const weights = recentAttempts.map((_, i) => Math.exp(-i * 0.2));
  const totalWeight = weights.reduce((sum, w) => sum + w, 0);

  const weightedSuccessRate = scores.reduce((sum, score, i) => 
    sum + (score * weights[i]), 0) / totalWeight * 100;
  return Math.min(Math.round(weightedSuccessRate), 100);
};