import React, { useEffect, useState } from 'react';
import {
   Menu, MenuButton, MenuList, MenuItem, MenuDivider,
   IconButton, Avatar, Text, VStack, HStack,
   Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
   ModalBody, ModalCloseButton, Button, Textarea, useToast, useDisclosure,
} from '@chakra-ui/react';
import { FiMenu, FiMessageSquare, FiInfo, FiSettings, FiTrash2 } from 'react-icons/fi';
import { auth, db } from '../firebase';
import { signOut, deleteUser } from 'firebase/auth';
import { doc, deleteDoc } from 'firebase/firestore';
import { submitFeedback } from './feedback';
import { AboutModal } from './AboutModal';
import { AuthWrapper } from '../AuthWrapper';
import { UserOnboarding } from './userOnboarding';

export const UserProfile = ({ onGradeSelect }) => {
   const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();
   const { isOpen: isFeedbackOpen, onOpen: onFeedbackOpen, onClose: onFeedbackClose } = useDisclosure();
   const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
   const { isOpen: isAboutOpen, onOpen: onAboutOpen, onClose: onAboutClose } = useDisclosure();
   const { isOpen: isOnboardingOpen, onOpen: onOnboardingOpen, onClose: onOnboardingClose } = useDisclosure();
   const [userData, setUserData] = useState(null);
   const [feedback, setFeedback] = useState('');
   const toast = useToast();

   useEffect(() => {
       const unsubscribe = auth.onAuthStateChanged((user) => {
           if (user) {
               setUserData({
                   uid: user.uid,
                   email: user.email,
                   photoURL: user.photoURL,
                   displayName: user.displayName || user.email?.split('@')[0]
               });
           } else {
               setUserData(null);
           }
       });
       return () => unsubscribe();
   }, []);

   const handleAccountDelete = async () => {
    try {
      await deleteDoc(doc(db, 'users', userData.uid));
      await deleteDoc(doc(db, 'progress', userData.uid));
      await deleteDoc(doc(db, 'feedback', userData.uid));
      await deleteUser(auth.currentUser);
      localStorage.removeItem('lastGrade');
      localStorage.removeItem('userPreferences');
      localStorage.removeItem('lastTopic');
      onDeleteClose();
      toast({ title: 'החשבון נמחק בהצלחה', status: 'success' });
    } catch (error) {
      toast({ title: 'שגיאה במחיקת החשבון', status: 'error' });
    }
   };

   const handleFeedbackSubmit = async () => {
       try {
           await submitFeedback(feedback);
           toast({ title: 'תודה על המשוב!', status: 'success' });
           setFeedback('');
           onFeedbackClose();
       } catch (error) {
           toast({ title: 'שגיאה בשליחת המשוב', status: 'error' });
       }
   };

   const getInitials = (name) => name ? name.split(' ').map(w => w[0]).join('').toUpperCase().slice(0, 2) : '';

   return (
       <>
           <Menu>
               <MenuButton
                   as={IconButton}
                   icon={<FiMenu />}
                   variant="ghost"
                   fontSize={{ base: "28px", md: "28px", lg: "32px" }}
                   size={{ base: "xl", md: "lg" }}
                   color="gray.700"
                   p={{ base: "4", md: "2" }}
                   minW={{ base: "52px", md: "40px" }}
                   minH={{ base: "52px", md: "40px" }}
                   _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                   transition="all 0.2s"
               />
               <MenuList>
                   {userData ? (
                       <>
                           <MenuItem>
                               <HStack spacing={3}>
                                   <Avatar
                                       size="sm"
                                       src={userData.photoURL}
                                       name={getInitials(userData.displayName)}
                                       bgColor="#1a73e8"
                                       color="white"
                                   />
                                   <VStack align="flex-start" spacing={0}>
                                       <Text>{userData.displayName}</Text>
                                       <Text fontSize="sm" color="gray.500">{userData.email}</Text>
                                   </VStack>
                               </HStack>
                           </MenuItem>
                           <MenuDivider />
                           <MenuItem icon={<FiMessageSquare />} onClick={onFeedbackOpen}>משוב</MenuItem>
                           <Menu placement="end-start">
                               <MenuButton 
                                   as={Button} 
                                   variant="ghost"
                                   w="100%"
                                   h="40px"
                                   display="flex"
                                   alignItems="center"
                                   justifyContent="flex-start"
                                   px={3}
                                   _hover={{ bg: 'gray.100' }}
                               >
                                   <HStack spacing={3}>
                                       <FiSettings />
                                       <Text fontWeight="normal">הגדרות משתמש</Text>
                                   </HStack>
                               </MenuButton>
                               <MenuList>
                                   <MenuItem onClick={onOnboardingOpen}>עריכת פרטי משתמש</MenuItem>
                                   <MenuDivider />
                                   <MenuItem 
                                       icon={<FiTrash2 />} 
                                       onClick={onDeleteOpen} 
                                       color="red.500"
                                   >
                                       מחיקת חשבון
                                   </MenuItem>
                               </MenuList>
                           </Menu>
                           <MenuDivider />
                           <MenuItem icon={<FiInfo />} onClick={onAboutOpen}>אודות</MenuItem>
                           <MenuItem onClick={() => {
                               signOut(auth);
                           }}>התנתק</MenuItem>
                       </>
                   ) : (
                       <>
                           <MenuItem onClick={onLoginOpen}>התחבר</MenuItem>
                           <MenuItem icon={<FiInfo />} onClick={onAboutOpen}>אודות</MenuItem>
                       </>
                   )}
               </MenuList>
           </Menu>

           <AuthWrapper isOpen={isLoginOpen} onClose={onLoginClose} />
           <AboutModal isOpen={isAboutOpen} onClose={onAboutClose} />
           <UserOnboarding 
               isOpen={isOnboardingOpen} 
               onClose={onOnboardingClose}
               user={userData}
               onComplete={(grade, force) => {
                   onOnboardingClose();
                   if (grade && onGradeSelect) {
                       onGradeSelect(grade, force);
                   }
               }}
           />

           {userData && (
               <>
                   <Modal isOpen={isFeedbackOpen} onClose={onFeedbackClose}>
                       <ModalOverlay />
                       <ModalContent>
                           <ModalHeader>משוב</ModalHeader>
                           <ModalCloseButton />
                           <ModalBody>
                               <Textarea
                                   value={feedback}
                                   onChange={(e) => setFeedback(e.target.value)}
                                   placeholder="כתוב את המשוב שלך כאן..."
                               />
                           </ModalBody>
                           <ModalFooter>
                               <Button colorScheme="blue" mr={3} onClick={handleFeedbackSubmit}>
                                   שלח משוב
                               </Button>
                               <Button variant="ghost" onClick={onFeedbackClose}>
                                   סגור
                               </Button>
                           </ModalFooter>
                       </ModalContent>
                   </Modal>

                   <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
                       <ModalOverlay />
                       <ModalContent>
                           <ModalHeader>מחיקת חשבון</ModalHeader>
                           <ModalCloseButton />
                           <ModalBody>
                               האם אתה בטוח שברצונך למחוק את החשבון? פעולה זו בלתי הפיכה.
                           </ModalBody>
                           <ModalFooter>
                               <Button colorScheme="red" mr={3} onClick={handleAccountDelete}>
                                   מחק חשבון
                               </Button>
                               <Button variant="ghost" onClick={onDeleteClose}>
                                   בטל
                               </Button>
                           </ModalFooter>
                       </ModalContent>
                   </Modal>
               </>
           )}
       </>
   );
};