import { useAuth } from '../AuthContext';
import { doc, collection, addDoc, runTransaction, getDoc, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export const useCurrency = () => {
  const { user, updateUserData } = useAuth();
  
  const addTransaction = async (amount, type, reason) => {
    if (!user?.uid) return;
    
    try {
      const transactionData = {
        amount,
        type: type.toLowerCase(),
        reason: reason.toLowerCase(),
        timestamp: new Date()
      };
      const transactionRef = collection(db, 'users', user.uid, 'transactions');
      await addDoc(transactionRef, transactionData);
    } catch (error) {
      console.error('Failed to record transaction:', error);
      throw error;
    }
  };

  const addCoins = async (amount, reason = 'general', type = 'credit') => {
    if (!user?.uid) throw new Error('User not authenticated');
    
    try {
      const newTotal = await runTransaction(db, async (transaction) => {
        const userDoc = await transaction.get(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          throw new Error('User document not found');
        }

        const currentCoins = userDoc.data().coins || 0;
        const newTotal = currentCoins + amount;
        transaction.update(doc(db, 'users', user.uid), { coins: newTotal });
        return newTotal;
      });

      await addTransaction(amount, type, reason);
      await updateUserData({ coins: newTotal });
      return newTotal;
    } catch (error) {
      console.error('Failed to update coins:', error);
      throw error;
    }
  };

  const deductCoins = async (amount, reason = 'general') => {
    if (!user?.uid) throw new Error('User not authenticated');
    
    try {
      const newTotal = await runTransaction(db, async (transaction) => {
        const userDoc = await transaction.get(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          throw new Error('User document not found');
        }

        const currentCoins = userDoc.data().coins || 0;
        if (currentCoins < amount) {
          throw new Error('Insufficient coins');
        }

        const newTotal = currentCoins - amount;
        transaction.update(doc(db, 'users', user.uid), { coins: newTotal });
        return newTotal;
      });

      await addTransaction(-amount, 'purchase', reason);
      await updateUserData({ coins: newTotal });
      return newTotal;
    } catch (error) {
      console.error('Failed to update coins:', error);
      throw error;
    }
  };

  const validateBalance = async () => {
    if (!user?.uid) return null;
    
    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const transactionsSnapshot = await getDocs(query(
        collection(db, 'users', user.uid, 'transactions'),
        orderBy('timestamp', 'desc')
      ));
      
      if (transactionsSnapshot.empty) {
        const currentBalance = userDoc.data()?.coins || 0;
        return {
          isValid: true,
          calculated: currentBalance,
          current: currentBalance
        };
      }

      let calculatedBalance = 0;
      transactionsSnapshot.forEach(doc => {
        const transaction = doc.data();
        calculatedBalance += transaction.type === 'credit' ? transaction.amount : -transaction.amount;
      });

      const currentBalance = userDoc.data()?.coins || 0;
      
      if (calculatedBalance !== currentBalance) {
        console.error('Balance mismatch detected:', { 
          calculated: calculatedBalance, 
          current: currentBalance 
        });
      }
      
      return {
        isValid: calculatedBalance === currentBalance,
        calculated: calculatedBalance,
        current: currentBalance
      };
    } catch (error) {
      console.error('Failed to validate balance:', error);
      throw error;
    }
  };

  const getRecentTransactions = async (limit = 10) => {
    if (!user?.uid) return [];
    
    const transactionsRef = collection(db, 'users', user.uid, 'transactions');
    const q = query(
      transactionsRef,
      orderBy('timestamp', 'desc'),
      limit(limit)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  };

  return {
    coins: user?.coins || 0,
    addCoins,
    deductCoins,
    validateBalance,
    getRecentTransactions
  };
}; 