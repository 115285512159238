import { collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';

export const submitFeedback = async (feedback) => {
  const auth = getAuth();
  const user = auth.currentUser;
  
  try {
    console.log('Attempting to save feedback:', feedback);
    console.log('Current user:', user?.uid);
    
    const feedbackDoc = {
      message: feedback,
      timestamp: new Date(),
      userId: user?.uid || 'anonymous',
      userEmail: user?.email || 'anonymous',
    };
    
  //  console.log('Feedback document:', feedbackDoc);
    
    const docRef = await addDoc(collection(db, 'feedback'), feedbackDoc);
    console.log('Document written with ID:', docRef.id);
    
    return { success: true, message: 'Feedback saved successfully' };
  } catch (error) {
    console.error('Detailed error:', {
      message: error.message,
      code: error.code,
      stack: error.stack
    });
    throw error;
  }
};