export const learningContent = {
    "בעיות מילוליות והשוואת מספרים": {
        tags: ['כיתה ג׳', 'חיבור וחיסור', 'בעיות מילוליות'],
        relatedLinks: [
            { title: "תרגול בעיות מילוליות והשוואת מספרים", url: "/?tags=בעיות%20מילוליות", description: "תרגול בעיות מילוליות והשוואת מספרים" }
        ],
        getStarted: [
            "**איך פותרים בעיות מילוליות?**",
            "1. קוראים את השאלה בעיון",
            "2. מסמנים את הנתונים החשובים",
            "3. מזהים מה צריך למצוא",
            "4. בוחרים את דרך הפתרון המתאימה",
            "",
            "**סוגי שאלות נפוצות:**",
            "• שאלות השוואה - 'בכמה יותר/פחות'",
            "• שאלות של חלק-שלם - 'כמה היו בהתחלה'",
            "• שאלות של הוספה/הפחתה - 'כמה נשאר/צריך עוד'",
            "",
            "**טיפים לפתרון:**",
            "• ציירו ציור או תרשים",
            "• הדגישו את המספרים החשובים",
            "• בדקו אם התשובה הגיונית",
            "• קראו שוב את השאלה עם התשובה שלכם"
        ],

        examples: [
            {
                question: "במהלך המחצית השנייה של משחק הכדורסל, קלע אורי 11 'סלים', וכך הגיע בסוף המשחק לשיא במספר הקליעות שלו במשחק אחד, שהוא 19 'סלים'. כמה פעמים קלע אורי לסל במהלך המחצית הראשונה?",
                solution: "נפתור בשלבים:<br/>1. בסוף המשחק: 19 סלים<br/>2. במחצית השנייה: 11 סלים<br/>3. במחצית הראשונה: 19 - 11 = 8 סלים",
                    
            },
            {
                question: "לרוני יש 364 שקלים. היא רוצה לקנות אופניים שמחירם 600 שקלים. כמה כסף צריכה עוד רוני לקבל כדי לקנות את האופניים?",
                solution: "נפתור בשלבים:<br/>1. מחיר האופניים: 600 ש״ח<br/>2. יש לרוני: 364 ש״ח<br/>3. חסר לה: 600 - 364 = 236 ש״ח",
            },
            {
                question: "בחצר בית הספר היו מספר ילדים. 30 ילדים הלכו לביתם, ואז נשארו 150 ילדים. כמה ילדים היו בחצר מלכתחילה?",
                solution: "נפתור בשלבים:<br/>1. נשארו: 150 ילדים<br/>2. הלכו: 30 ילדים<br/>3. היו בהתחלה: 150 + 30 = 180 ילדים",             
            }
        ],


        quiz: [
            {
                type: "multiChoiceRandomBoolean",
                question: "בשכונה א׳ גרים {A} תושבים, בשכונה ב׳ גרים {B} תושבים, ובשכונה ג׳ גרים {C} תושבים. האם מספר התושבים בעיר עולה על {Target}?",
                params: {
                    A: {
                        range: [1000, 5000],
                        type: "integer"
                    },
                    B: {
                        range: [2000, 6000],
                        type: "integer"
                    },
                    C: {
                        range: [3000, 7000],
                        type: "integer"
                    }
                },
                metadata: {
                    Target: "{A}+{B}+{C}+1000",
                    Total: "{A}+{B}+{C}"
                },
                formula: "1",
                distractors: ["תלוי במזג האוויר", "אי אפשר לדעת"],
                hint: "חברו את מספר התושבים בכל השכונות והשוו ל-{Target}",
                solutiontemplate: {
                    true: "כן, כי $ {Total} > {Target} $",
                    false: "לא, כי $ {Total} < {Target} $"
                }
            },
            {
                type: "multiChoiceRandomMath",
                question: "לנועם יש {A} קלפי פוקימון. לליאור יש {B} קלפי פוקימון. בכמה קלפי פוקימון יש לנועם יותר מאשר לליאור?",
                params: {
                    A: {
                        range: [200, 600],
                        type: "integer"
                    },
                    B: {
                        range: [50, 150],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}",
                        "{B}-{A}"
                    ]
                },
                hint: "חסרו את מספר קלפי פוקימון של ליאור ממספר קלפי פוקימון של נועם",
                solutionGuide: "לנועם יש {A} קלפי פוקימון ולליאור יש {B} קלפי פוקימון. ההפרש הוא $ {A} - {B} = {Result} $"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בקופת החיסכון של לביא היו {Initial} שקלים. לביא קנה משחק ב-{Spent} שקלים. כמה שקלים נשארו בקופה?",
                params: {
                    A: {
                        range: [15, 35],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A-5"],
                        type: "integer"
                    }
                },
                metadata: {
                    Initial: "{A}*100",
                    Spent: "{B}*100"
                },
                formula: "A*100-B*100",
                distractors: {
                    strategies: ["off-by-ten", "off-by-two"],
                    custom: [
                        "{Initial}+{Spent}",
                        "{Spent}"
                    ]
                },
                hint: "חסרו את הסכום ששולם מהסכום ההתחלתי",
                solutionGuide: "בהתחלה היו {Initial} שקלים, שולמו {Spent} שקלים, נשארו {Result} שקלים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "באימון כדורסל קלע טל {A} סלים ועומרי קלע {B} סלים פחות מטל. כמה סלים קלעו שניהם יחד?",
                params: {
                    A: {
                        range: [25, 45],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                formula: "A+(A-B)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}",
                        "{B}-{A}"
                    ]
                },
                hint: "חברו את מספר הסלים של שני השחקנים",
                solutionGuide: "טל קלע {A} סלים ועומרי קלע {B} סלים. ביחד: $ {A} + ({A} - {B}) = {Result} $ סלים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "שי אספה {A} מדבקות בשבוע הראשון. בשבוע השני אספה פי {B} מהשבוע הראשון, ובשבוע השלישי {C} פחות משבוע השני. כמה מדבקות אספה בשבוע השלישי?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [2, 4],
                        type: "integer"
                    },
                    C: {
                        range: [2, 3],
                        type: "integer"
                    }
                },
                formula: "(A*B)-C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}+{C}",
                        "{A}-{B}-{C}"
                    ]
                },
                hint: "חשבו את מספר המדבקות בשבוע השני ואז חסרו ממנו את {C}",
                solutionGuide: "בשבוע הראשון אספה {A} מדבקות. בשבוע השני אספה פי {B} יותר, כלומר $ {A} × {B} $ מדבקות. בשבוע השלישי, לאחר חיסור {C}, נקבל: $ {A} × {B} - {C} = {Result} $ מדבקות"
            },
            {
                type: "multiChoiceRandomMath",
                question: "ליה קנתה {A} חבילות מחברות. בכל חבילה יש {B} מחברות, ומחיר כל מחברת {C} שקלים. כמה שילמה ליה בסך הכל?",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [12, 24],
                        type: "integer"
                    },
                    C: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                formula: "A*B*C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}+{C}",
                        "{A}-{B}-{C}"
                    ]
                },
                hint: "כפלו את מספר המחברות במספר החבילות ובמחיר כל מחברת",
                solutionGuide: "ליה קנתה {A} חבילות מחברות. בכל חבילה יש {B} מחברות ומחיר כל מחברת {C} שקלים. סה״כ: $ {A} × {B} × {C} = {Result} $ שקלים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בחנות היו {Total} חטיפים. נמכרו {Sold} חטיפים, ואז הגיעה הזמנה חדשה של פי {Times} יותר חטיפים ממה שנמכר. כמה חטיפים יש עכשיו בחנות?",
                params: {
                    A: {
                        range: [100, 300],
                        type: "integer"
                    },
                    B: {
                        range: [30, 80],
                        type: "integer"
                    },
                    C: {
                        range: [2, 4],
                        type: "integer"
                    }
                },
                metadata: {
                    Total: "{A}",
                    Sold: "{B}",
                    Times: "{C}"
                },
                formula: "A-B+(B*C)",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}",
                        "{B}-{A}"
                    ]
                },
                hint: "חסרו את החטיפים שנמכרו והוסיפו את החטיפים שנוספו מהזמנה חדשה",
                solutionGuide: "בהתחלה היו {Total} חטיפים. נמכרו {Sold} חטיפים ונוספו חטיפים מהזמנה חדשה של פי {Times} יותר חטיפים ממה שנמכר. עכשיו יש בחנות: $ {Total} - {Sold} + ({Sold} × {Times}) = {Result} $ חטיפים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "ליאור אסף {A} בולים בחודש הראשון. בחודש השני אסף {B} בולים פחות מהחודש הראשון. כמה בולים אסף בחודש השני?",
                params: {
                    A: {
                        range: [10, 30],
                        type: "integer"
                    },
                    B: {
                        range: [2, 8],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}",
                        "{B}"
                    ]
                },
                hint: "חסרו {B} מהמספר של החודש הראשון",
                solutionGuide: "בחודש ראשון אסף {A} בולים. בחודש שני אסף {B} פחות, כלומר $ {A} - {B} = {Result} $ בולים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "לביא היה ב-{A} משחקים בליגה. בכל משחק הבקיע {B} שערים. כמה שערים הבקיע לביא בסך הכל?",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [1, 4],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}",
                        "{A}-{B}"
                    ]
                },
                hint: "כפלו את מספר המשחקים במספר השערים בכל משחק",
                solutionGuide: "לביא שיחק {A} משחקים והבקיע {B} שערים בכל משחק. סה״כ: $ {A} × {B} = {Result} $ שערים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "עומרי חסך {Saved} שקלים ואז קנה במכולת ב-{Spent} שקלים. כמה כסף נשאר לו?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A-5"],
                        type: "integer"
                    }
                },
                metadata: {
                    Saved: "{A}",
                    Spent: "{B}"
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten"],
                    custom: [
                        "{A}+{B}",
                        "{B}"
                    ]
                },
                hint: "חסרו את הסכום שהוציא מהסכום שחסך",
                solutionGuide: "עומרי חסך {Saved} שקלים והוציא {Spent} שקלים. נשאר: $ {Saved} - {Spent} = {Result} $ שקלים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "ליה סידרה {Total} ספרים בספרייה. {OnShelf} ספרים על המדף ו-{InBox} ספרים בקופסה. כמה ספרים נשארו?",
                params: {
                    A: {
                        range: [30, 50],
                        type: "integer"
                    },
                    B: {
                        range: [10, 20],
                        type: "integer"
                    },
                    C: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                metadata: {
                    Total: "{A}",
                    OnShelf: "{B}",
                    InBox: "{C}"
                },
                formula: "A-B-C",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}",
                        "{B}+{C}"
                    ]
                },
                hint: "חסרו את הספרים שעל המדף ובקופסה מהמספר הכולל",
                solutionGuide: "מתוך {Total} ספרים, {OnShelf} על המדף ו-{InBox} בקופסה. נשארו: $ {Total} - {OnShelf} - {InBox} = {Result} $ ספרים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "במחצית הראשונה של משחק כדורגל הובקעו {A} שערים. במחצית השנייה הובקעו {B} שערים. כמה שערים הובקעו בסך הכל במשחק?",
                params: {
                    A: {
                        range: [1, 4],
                        type: "integer"
                    },
                    B: {
                        range: [1, 3],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}",
                        "{B}"
                    ]
                },
                hint: "חברו את מספר השערים בשתי המחציות",
                solutionGuide: "במחצית ראשונה הובקעו {A} שערים ובמחצית שנייה {B} שערים. סה״כ: $ {A} + {B} = {Result} $ שערים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "לדני היו {Total} קלפים. הוא נתן {Gave} קלפים לאחותו. כמה קלפים נשארו לדני?",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [5, 15],
                        type: "integer"
                    }
                },
                metadata: {
                    Total: "{A}",
                    Gave: "{B}"
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}",
                        "{B}"
                    ]
                },
                hint: "חסרו את מספר הקלפים שדני נתן מהמספר ההתחלתי",
                solutionGuide: "לדני היו {Total} קלפים ונתן {Gave} קלפים. נשארו: $ {Total} - {Gave} = {Result} $ קלפים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בכיתה יש {Current} תלמידים. {New} תלמידים חדשים הצטרפו לכיתה. כמה תלמידים יש עכשיו בכיתה?",
                params: {
                    A: {
                        range: [20, 30],
                        type: "integer"
                    },
                    B: {
                        range: [2, 5],
                        type: "integer"
                    }
                },
                metadata: {
                    Current: "{A}",
                    New: "{B}"
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}-{B}",
                        "{B}"
                    ]
                },
                hint: "חברו את מספר התלמידים החדשים למספר התלמידים הקיים",
                solutionGuide: "בכיתה היו {Current} תלמידים והצטרפו {New} תלמידים. עכשיו יש: $ {Current} + {New} = {Result} $ תלמידים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "במהלך המחצית השנייה של משחק הכדורסל, קלע אורי {Second} 'סלים', וכך הגיע בסוף המשחק לשיא במספר הקליעות שלו במשחק אחד, שהוא {Total} 'סלים'. כמה פעמים קלע אורי לסל במהלך המחצית הראשונה?",
                params: {
                    A: {
                        range: [15, 25],
                        type: "integer"
                    },
                    B: {
                        range: [8, 12],
                        type: "integer"
                    }
                },
                metadata: {
                    Total: "{A}",
                    Second: "{B}"
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two"],
                    custom: [
                        "{A}+{B}",
                        "{B}"
                    ]
                },
                hint: "חסרו את מספר הסלים במחצית השנייה מהמספר הכולל",
                solutionGuide: "בסוף המשחק: {Total} סלים, במחצית השנייה: {Second} סלים. במחצית הראשונה: $ {Total} - {Second} = {Result} $ סלים"
            },
            {
                type: "multiChoiceRandomMath",
                question: "לרוני יש {Has} שקלים. היא רוצה לקנות אופניים שמחירם {Price} שקלים. כמה כסף צריכה עוד רוני לקבל כדי לקנות את האופניים?",
                params: {
                    A: {
                        range: [300, 400],
                        type: "integer"
                    },
                    B: {
                        range: [500, 700],
                        type: "integer"
                    }
                },
                metadata: {
                    Has: "{A}",
                    Price: "{B}"
                },
                formula: "B-A",
                distractors: {
                    strategies: ["off-by-hundred"],
                    custom: [
                        "{A}+{B}",
                        "{A}"
                    ]
                },
                hint: "חסרו את הסכום שיש לרוני ממחיר האופניים",
                solutionGuide: "מחיר האופניים: {Price} ש״ח, יש לרוני: {Has} ש״ח. חסר לה: $ {Price} - {Has} = {Result} $ ש״ח"
            },
            {
                type: "multiChoiceRandomMath",
                question: "בחצר בית הספר היו מספר ילדים. {Left} ילדים הלכו לביתם, ואז נשארו {Remain} ילדים. כמה ילדים היו בחצר מלכתחילה?",
                params: {
                    A: {
                        range: [20, 40],
                        type: "integer"
                    },
                    B: {
                        range: [100, 200],
                        type: "integer"
                    }
                },
                metadata: {
                    Left: "{A}",
                    Remain: "{B}"
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-ten", "off-by-two"],
                    custom: [
                        "{B}-{A}",
                        "{B}"
                    ]
                },
                hint: "חברו את מספר הילדים שהלכו למספר הילדים שנשארו",
                solutionGuide: "נשארו: {Remain} ילדים, הלכו: {Left} ילדים. היו בהתחלה: $ {Remain} + {Left} = {Result} $ ילדים"
            }
        ]
    }
};