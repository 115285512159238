import { evaluate } from 'mathjs';

const DISTRACTOR_STRATEGIES = {
  'plus-one': (result) => result + 1 ,
  'plus-two': (result) => result + 2 ,
  'plus-three': (result) => result + 3 ,
  'off-by-one': (result) => Math.random() < 0.5 ? result + 1 : result - 1,
  'off-by-two': (result) => Math.random() < 0.5 ? result + 2 : result - 2,
  'off-by-three': (result) => Math.random() < 0.5 ? result + 3 : result - 3,
  'off-by-five': (result) => Math.random() < 0.5 ? result + 5 : result - 5,
  'off-by-ten': (result) => Math.random() < 0.5 ? result + 10 : result - 10,
  'off-by-hundred': (result) => Math.random() < 0.5 ? result + 100 : result - 100,
  'off-by-10th': (result) =>  Math.floor(Math.random() < 0.5 ? result + 0.1 : result - 0.1),
  'off-by-hundredth': (result) =>  Math.floor(Math.random() < 0.5 ? result + 0.01 : result - 0.01),
  'off-by-thousandth': (result) => Math.floor(Math.random() < 0.5 ? result + 0.001 : result - 0.001),
  "div-ten": (result) => result / 10,
  "get-hundreds": (result) => Math.floor(result / 100), // Get first digit (hundreds place)
  "get-hundreds-tens": (result) => Math.floor(result / 10),   // Get first two digits (hundreds and tens)
  "get-tens-ones": (result) => Math.floor(result % 100),  // Get last two digits (tens and ones)
  "get-tens": (result) => Math.floor((result % 100) / 10),  // Get middle digit (tens place)
  "get-ones": (result) => Math.floor(result % 10),// Get last digit (ones place)
  'sign-flip': (result) => -result,
  'double': (result) => result * 2,
  'half': (result) => result / 2,
  'off-by-eleven': (result) => Math.random() < 0.5 ? result + 11 : result - 11,
  'digit-swap': (result) => {
    const digits = String(Math.abs(result)).split('');
    if (digits.length === 1) return result;

    if (digits.length === 2) {
      [digits[0], digits[1]] = [digits[1], digits[0]];
    }
    else {
      const pos1 = Math.floor(Math.random() * digits.length);
      let pos2;
      do {
        pos2 = Math.floor(Math.random() * digits.length);
      } while (pos2 === pos1);

      [digits[pos1], digits[pos2]] = [digits[pos2], digits[pos1]];
    }
    const swappedResult = Number(digits.join(''));
    return result < 0 ? -swappedResult : swappedResult;
  },
};

export const processQuizQuestions = (quiz) => {
  return quiz.map(question => {
    if (['multiChoiceRandomMath', 'multiChoiceRandomBoolean'].includes(question.type)) {
      return prepareRandomExercise(question);
    }
    return { ...question };
  });
};

const evaluateMetadata = (metadata, values) => {
  const metadataValues = {};
  Object.entries(metadata || {}).forEach(([key, expression]) => {
    const processedExpression = expression.replace(/\{(\w+)\}/g, (_, p) => values[p]);
    metadataValues[key] = evaluate(processedExpression);
  });
  return metadataValues;
};

const generateAnswerOptions = (isCorrect, distractors = []) => {
  const baseOptions = isCorrect ? ["נכון", "לא נכון"] : ["לא נכון", "נכון"];
  return [...baseOptions, ...distractors];
};

export const prepareRandomExercise = (exercise) => {
  const values = {};
  const [minA, maxA] = exercise.params.A.range;
  values.A = Math.floor(Math.random() * (maxA - minA + 1)) + minA;
  console.log('Question:', exercise.question);
  Object.entries(exercise.params).forEach(([key, param]) => {
    if (key === 'A') return;
    
    // Process min range value
    const min = param.range[0] === 'A' ? values.A : 
      typeof param.range[0] === 'string' ? 
        Math.floor(evaluate(param.range[0].replace('A', values.A))) :
        param.range[0];

    // Process max range value
    const max = typeof param.range[1] === 'string' ?
      Math.floor(evaluate(param.range[1].replace('A', values.A))) :
      param.range[1];

    values[key] = Math.floor(Math.random() * (max - min + 1)) + min;
  });

  const formula = Object.entries(values).reduce(
    (f, [key, value]) => f.replace(new RegExp(key, 'g'), value),
    exercise.formula
  );
  const result = ['multiChoiceRandomBoolean', 'multiChoiceRandomMath'].includes(exercise.type) ?
    evaluate(formula) :
    Math.round(evaluate(formula));

  const distractors = exercise.type === 'multiChoiceRandomBoolean' 
    ? exercise.distractors // Use distractors directly for multiChoiceRandomBoolean
    : generateDistractors(result, exercise.distractors, values);
  const metadata = evaluateMetadata(exercise.metadata, values);
  const allValues = { ...values, ...metadata, Result: result };
  //console.log('Metadata:', metadata);
  //console.log('All values:', allValues);
  //console.log('Question before replacement:', exercise.question);

  const replacePlaceholders = (text, allValues) => {
    // Return early if text is undefined or null
    if (text == null) return text;
    
    // If it's a number, return as is
    if (typeof text === 'number') return text;

    let processed = text.toString();
    Object.entries(allValues).forEach(([key, value]) => {
      processed = processed.replace(new RegExp(`{${key}}`, 'g'), value);
    });

    return !isNaN(processed) ? Number(processed) : processed;
  };

  // Process drawing if present
  const processedDrawing = exercise.drawing ? {
    ...exercise.drawing,
    ...(exercise.drawing.type.startsWith('geom-') && {
      sideLength: replacePlaceholders(exercise.drawing.sideLength, allValues),
      length: replacePlaceholders(exercise.drawing.length, allValues),
      width: replacePlaceholders(exercise.drawing.width, allValues),
      base: replacePlaceholders(exercise.drawing.base, allValues),
      height: replacePlaceholders(exercise.drawing.height, allValues),
    }),
    total: replacePlaceholders(exercise.drawing.total, allValues),
    groupSize: replacePlaceholders(exercise.drawing.groupSize, allValues),
    groups: replacePlaceholders(exercise.drawing.groups, allValues),
    caption: exercise.drawing.caption ? replacePlaceholders(exercise.drawing.caption, allValues) : undefined
  } : undefined;

  if (exercise.type === 'multiChoiceRandomBoolean') {
    const isCorrect = !result; // 0 means divisible (true), 1 means not divisible (false)
    return {
      type: 'multiplechoice',
      question: replacePlaceholders(exercise.question, allValues),
      options: generateAnswerOptions(isCorrect, distractors),
      hint: replacePlaceholders(exercise.hint, allValues),
      solutionGuide: replacePlaceholders(exercise.solutiontemplate[isCorrect ? 'true' : 'false'], allValues),
      drawing: processedDrawing
    };
  }

  // Ensure we have enough options
  if (distractors.length < (exercise.distractors?.count || 3)) {
    while (distractors.length < (exercise.distractors?.count || 3)) {
      const randomDistractor = result + Math.floor(Math.random() * 20) - 10;
      if (!distractors.includes(randomDistractor) && randomDistractor !== result) {
        distractors.push(randomDistractor);
      }
    }
  }

  const options = [String(result), ...distractors.map(String)];
  return {
    type: 'multiplechoice',
    question: replacePlaceholders(exercise.question, allValues),
    options,
    hint: replacePlaceholders(exercise.hint, allValues),
    solutionGuide: replacePlaceholders(exercise.solutionGuide, allValues),
    drawing: processedDrawing
  };
};

const generateDistractors = (result, distractorConfig, values) => {
  const count = distractorConfig?.count || 3;
  
  
  let allDistractors = new Set();

  try {
    // 1. Process custom distractors
    if (distractorConfig?.custom) {
      distractorConfig.custom.forEach(distractor => {
        const entry = { distractor };
        
        if (typeof distractor === 'string' && distractor.includes('{')) {
          const processedDistractor = Object.entries(values).reduce(
            (f, [key, value]) => f.replace(new RegExp(`{${key}}`, 'g'), value),
            distractor
          );
          
          const distractorResult = /^[0-9+\-*/().\s]+$/.test(processedDistractor) ? 
            evaluate(processedDistractor) : processedDistractor;
          
          entry.processed = processedDistractor;
          entry.result = distractorResult;
          entry.accepted = distractorResult !== result;
          
          if (distractorResult !== result) {
            allDistractors.add(distractorResult);
          }
        } else {
          entry.processed = 'literal value';
          entry.result = distractor;
          entry.accepted = distractor !== result;
          
          if (distractor !== result) {
            allDistractors.add(distractor);
          }
        }
      });
    }
    
    

    // 2. Add strategy distractors if needed
    if (allDistractors.size < count && distractorConfig?.strategies) {
      const strategyResults = [];
      
      distractorConfig.strategies.forEach(strategy => {
        if (allDistractors.size < count) {
          try {
            const stratResult = DISTRACTOR_STRATEGIES[strategy](result);
            const entry = {
              strategy,
              result: stratResult,
              accepted: stratResult !== result && !allDistractors.has(stratResult)
            };
            strategyResults.push(entry);
            
            if (entry.accepted) {
              allDistractors.add(stratResult);
            }
          } catch (error) {
            strategyResults.push({
              strategy,
              error: error.message,
              accepted: false
            });
          }
        }
      });
      
    }
    
    

    // 3. Add random distractors if still needed
    let attempts = 0;
    const randomResults = [];
    
    while (allDistractors.size < count && attempts < 100) {
      const randomDistractor = result + Math.floor(Math.random() * 20) - 10;
      const accepted = randomDistractor !== result && !allDistractors.has(randomDistractor);
      
      randomResults.push({
        attempt: attempts + 1,
        value: randomDistractor,
        accepted
      });
      
      if (accepted) {
        allDistractors.add(randomDistractor);
      }
      attempts++;
    }
    


    const finalDistractors = Array.from(allDistractors).slice(0, count);
   
    
    return finalDistractors;

  } catch (error) {
    console.error('Error in generateDistractors:', error);
    const fallbackDistractors = new Set();
    let i = 1;
    while (fallbackDistractors.size < count) {
      fallbackDistractors.add(result + i);
      i++;
    }
    const fallback = Array.from(fallbackDistractors);
    
    return fallback;
  }
};
