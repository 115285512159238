export const learningContent = {
    "בעיות מילוליות עם שברים": {
        tags: ['כיתה ז׳', 'כיתה ו׳', 'שברים', 'בעיות מילוליות'],
        relatedLinks: [
            { title: "תרגול בעיות מילוליות עם שברים", url: "/?tags=שברים,בעיות%20מילוליות", description: "תרגול בעיות מילוליות עם שברים" }
        ],
        getStarted: [
            "**איך פותרים בעיות מילוליות עם שברים?**",
            "",
            "**קריאה והבנה:**",
            "   קראו את השאלה בעיון",
            "   סמנו את המספרים והשברים",
            "   זהו מה מבקשים למצוא",
            "",
            "**ארגון הנתונים:**",
            "   רשמו את השברים בצורה מסודרת",
            "   המירו מספרים מעורבים לשברים אם צריך",
            "   שרטטו ציור אם עוזר",
            "",
            "**בחירת דרך הפתרון:**",
            "   חיבור שברים - כשמצרפים כמויות",
            "   חיסור שברים - כשמורידים חלק מהכמות",
            "   כפל שברים - כשלוקחים חלק מכמות",
            "   חילוק שברים - כשמחלקים לחלקים שווים",
            "",
            "**פתרון ובדיקה:**",
            "   פתרו בזהירות",
            "   בדקו אם התשובה הגיונית",
            "   ודאו שעניתם על השאלה",
            "",
            "**טיפים חשובים:**",
            "לצייר ציור או דיאגרמה",
            "להמיר למכנה משותף בחיבור וחיסור",
            "לצמצם את התשובה הסופית",
            "לבדוק אם התשובה הגיונית"
        ],
        examples: [
            {
                question: "חילקו את העוגה. רבע עוגה לטל וממה שנותר נתנו שליש לשי. איזה חלק מהעוגה נותרה?",
                solution: "1. נחשב כמה נשאר אחרי שטל אכל:\n$1 - \\frac{1}{4} = \\frac{3}{4}$ מהעוגה\n\n2. שי אכלה $\\frac{1}{3}$ מ-$\\frac{3}{4}$:\n$\\frac{3}{4} × \\frac{1}{3} = \\frac{1}{4}$ אכלה שי\n\n3. נחסר מה ששי אכלה:\n$\\frac{3}{4} - \\frac{1}{4} = \\frac{1}{2}$ נשאר",
                drawing: {
                    type: "fractionCircles",
                    fractions: ["3/4", "1/4", "1/2"],
                }
            },
            {
                question: "אם $\\frac{3}{4}$ ק״ג עגבניות עולים 12 שקלים, כמה יעלה 1 ק״ג?",
                solution: "1. $\\frac{3}{4}$ ק״ג עולים 12 שקלים\n2. נחלק 12 ב-3 למחיר רבע ק״ג: 12 ÷ 3 = 4 שקלים\n3. נכפול ב-4 למחיר ק״ג שלם: 4 × 4 = 16 שקלים",
                drawing: {
                    type: "rectangleGrid",
                    rows: 4,
                    cols: 1,
                    highlightCells: 3,
                    caption: "המלבן מייצג ק״ג שלם, החלק הצבוע מייצג $\\frac{3}{4}$ ק״ג"
                }
            },
            {
                question: "גינה מלבנית שאורכה $2\\frac{1}{2}$ מטרים ורוחבה $1\\frac{1}{4}$ מטרים. מה שטחה?",
                solution: "1. נמיר למספרים מעורבים:\n$2\\frac{1}{2} = \\frac{5}{2}$ מטרים\n$1\\frac{1}{4} = \\frac{5}{4}$ מטרים\n\n2. נכפול אורך ברוחב:\n$\\frac{5}{2} × \\frac{5}{4} = \\frac{25}{8} = 3\\frac{1}{8}$ מטרים רבועים",
                drawing: {
                    type: "rectangleGrid",
                    rows: 4,
                    cols: 8,
                    highlightCells: 25,
                    caption: "הגינה מחולקת ל-32 חלקים שווים, 25 מהם מהווים $3\\frac{1}{8}$ מטרים רבועים"
                }
            }
        ],
        quiz: [
            {
                question: "חילקו את העוגה. רבע עוגה לטל וממה שנותר נתנו שליש לשי. איזה חלק מהעוגה נותרה?",
                type: "multiplechoice",
                options: ["$\\frac{5}{12}$", "$\\frac{1}{2}$", "$\\frac{1}{4}$", "$\\frac{5}{7}$"],
                hint: "כדי למצוא כמה נשאר, חברו את החלקים שחולקו ($\\frac{1}{4} + \\frac{1}{3}$) והחסירו מ-1. זכרו להביא למכנה משותף",
                solutionGuide: "$1 - (\\frac{1}{3} + \\frac{1}{4}) = 1 - (\\frac{4}{12} + \\frac{3}{12}) = 1 - \\frac{7}{12} = \\frac{5}{12}$"
            },
            {
                question: "אם $\\frac{2}{3}$ מטר בד עולה 8 שקלים, כמה יעלה מטר שלם?",
                type: "multiplechoice",
                options: ["12 שקלים", "16 שקלים", "10 שקלים", "14 שקלים"],
                hint: "אם $\\frac{2}{3}$ מטר עולה 8 שקלים, מצאו קודם כמה עולה $\\frac{1}{3}$ מטר",
                solutionGuide: "אם $\\frac{2}{3}$ עולה 8 שקלים, אז $\\frac{1}{3}$ עולה 4 שקלים, ומטר שלם ($\\frac{3}{3}$) יעלה 12 שקלים"
            },
            {
                question: "מיתר אכל $\\frac{1}{4}$ מהפיצה ומנור אכלה $\\frac{1}{3}$ מהנותר. כמה אכלה מנור מכל הפיצה?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{1}{3}$", "$\\frac{1}{2}$", "$\\frac{1}{12}$"],
                hint: "חשבו בשלבים: קודם מצאו כמה נשאר אחרי שמיתר אכל רבע, ואז חשבו שליש מהכמות הזו",
                solutionGuide: "נשאר $\\frac{3}{4}$ מהפיצה, ומנור אכלה $\\frac{1}{3}$ מזה: $\\frac{3}{4} × \\frac{1}{3} = \\frac{1}{4}$"
            },
            {
                question: "חבילת קמח שוקלת $1\\frac{1}{2}$ ק״ג. כמה חבילות צריך לקנות כדי להשיג 6 ק״ג קמח?",
                type: "multiplechoice",
                options: ["4 חבילות", "3 חבילות", "5 חבילות", "6 חבילות"],
                hint: "המירו את $1\\frac{1}{2}$ לשבר רגיל. כדי למצוא כמה חבילות, חלקו את המשקל הרצוי במשקל החבילה",
                solutionGuide: "כל חבילה היא $\\frac{3}{2}$ ק״ג. נחלק 6 ב-$\\frac{3}{2}$: $6 ÷ \\frac{3}{2} = 4$ חבילות"
            },
            {
                question: "מיכל שתתה $\\frac{2}{5}$ מבקבוק מים ויהונתן שתה $\\frac{1}{3}$ ממה שנשאר. כמה נשאר בבקבוק?",
                type: "multiplechoice",
                options: ["$\\frac{2}{5}$", "$\\frac{8}{15}$", "$\\frac{1}{3}$", "$\\frac{1}{15}$"],
                hint: "חשבו בשלבים: קודם מצאו כמה נשאר אחרי מיכל, ואז חשבו כמה נשאר אחרי שיהונתן שתה שליש מזה",
                solutionGuide: "נשאר $\\frac{3}{5}$ אחרי מיכל. יהונתן שתה $\\frac{1}{3}$ מ-$\\frac{3}{5}$ שזה $\\frac{1}{5}$. נשאר $\\frac{2}{5}$"
            },
            {
                question: "אם $\\frac{3}{4}$ ק״ג גבינה עולה 15 שקלים, כמה תעלה חצי ק״ג?",
                type: "multiplechoice",
                options: ["10 שקלים", "7.5 שקלים", "12 שקלים", "5 שקלים"],
                hint: "מצאו קודם כמה עולה קילו שלם של גבינה, ואז חשבו את המחיר לחצי קילו",
                solutionGuide: "נמצא מחיר לק״ג: 20  $ 15 ÷ \\frac{3}{4} = $ שקלים. חצי ק״ג יעלה 10 שקלים"
            },
            {
                question: "המתחרים רצים $2\\frac{1}{4}$ ק״מ בכל יום. כמה ק״מ רצים ב-3 ימים?",
                type: "multiplechoice",
                options: ["6.75 ק״מ", "7.25 ק״מ", "6.25 ק״מ", "7.75 ק״מ"],
                hint: "המירו את $2\\frac{1}{4}$ לשבר רגיל כדי להקל על הכפל ב-3",
                solutionGuide: "$2\\frac{1}{4} = \\frac{9}{4}$ ק״מ ליום. ב-3 ימים: $\\frac{9}{4} × 3 = \\frac{27}{4} = 6.75$ ק״מ"
            },
            {
                question: "בכיתה 40 תלמידים. $\\frac{3}{8}$ מהם בנים. כמה בנות יש בכיתה?",
                type: "multiplechoice",
                options: ["25 בנות", "15 בנות", "20 בנות", "30 בנות"],
                hint: "קודם מצאו כמה בנים יש בכיתה, ואז חסרו מהמספר הכולל של התלמידים",
                solutionGuide: "מספר הבנים: $40 × \\frac{3}{8} = 15$. מספר הבנות: $40 - 15 = 25$"
            },
            {
                question: "אמא חילקה עוגה ל-8 חלקים שווים. מיתר אכל 2 חלקים ומנור 3 חלקים. איזה חלק מהעוגה נאכל?",
                type: "multiplechoice",
                options: ["$\\frac{5}{8}$", "$\\frac{3}{5}$", "$\\frac{2}{3}$", "$\\frac{3}{4}$"],
                hint: "כל חלק הוא שמינית מהעוגה. חברו את החלקים שמיתר ומנור אכלו",
                solutionGuide: "מיתר אכל $\\frac{2}{8}$ ומנור אכלה $\\frac{3}{8}$. יחד: $\\frac{2}{8} + \\frac{3}{8} = \\frac{5}{8}$"
            },
            {
                question: "גינה מלבנית שאורכה $1\\frac{1}{2}$ מטרים ורוחבה $\\frac{2}{3}$ מטר. מה שטחה?",
                type: "multiplechoice",
                options: ["$1$ מ״ר", "$2$ מ״ר", "$\\frac{3}{2}$ מ״ר", "10 מ״ר"],
                hint: "זכרו: שטח מלבן = אורך × רוחב. המירו את $1\\frac{1}{2}$ לשבר רגיל לפני הכפל",
                solutionGuide: "$1\\frac{1}{2} × \\frac{2}{3} = \\frac{3}{2} × \\frac{2}{3} = 1$ מטר רבוע"
            },
            {
                question: "יש $2\\frac{1}{2}$ ק״ג סוכר. כמה שקיות של $\\frac{1}{4}$ ק״ג אפשר למלא?",
                type: "multiplechoice",
                options: ["10 שקיות", "8 שקיות", "12 שקיות", "6 שקיות"],
                hint: "המירו את $2\\frac{1}{2}$ לשבר רגיל עם מכנה של 4 כדי להשוות ל-$\\frac{1}{4}$ ק״ג",
                solutionGuide: "$2\\frac{1}{2} = \\frac{10}{4}$ ק״ג. נחלק ב-$\\frac{1}{4}$: $\\frac{10}{4} ÷ \\frac{1}{4} = 10$ שקיות"
            },
            {
                question: "אם $\\frac{2}{5}$ מהתלמידים בכיתה הם בנים ויש 16 בנים, כמה תלמידים יש בכיתה?",
                type: "multiplechoice",
                options: ["40 תלמידים", "30 תלמידים", "35 תלמידים", "25 תלמידים"],
                hint: "אם 16 בנים הם $\\frac{2}{5}$ מהכיתה, חשבו כמה תלמידים הם חמישית אחת",
                solutionGuide: "אם 16 בנים הם $\\frac{2}{5}$ מהכיתה, אז בכיתה יש: $16 ÷ \\frac{2}{5} = 16 × \\frac{5}{2} = 40$ תלמידים"
            },
            {
                question: "מיכל שתתה $\\frac{1}{3}$ מבקבוק מיץ ויהונתן שתה $\\frac{1}{2}$ ממה שנשאר. כמה נשאר בבקבוק?",
                type: "multiplechoice",
                options: ["$\\frac{1}{3}$", "$\\frac{1}{6}$", "$\\frac{1}{2}$", "$\\frac{1}{4}$"],
                hint: "חשבו בשלבים: כמה נשאר אחרי מיכל? ואז כמה נשאר אחרי שיהונתן שתה חצי מזה?",
                solutionGuide: "נשאר $\\frac{2}{3}$ אחרי מיכל. יהונתן שתה $\\frac{1}{2}$ מ-$\\frac{2}{3}$ שזה $\\frac{1}{3}$. נשאר $\\frac{1}{3}$"
            },
            {
                question: "אם חצי ק״ג תפוחים עולה 6 שקלים, כמה יעלו $1\\frac{1}{2}$ ק״ג?",
                type: "multiplechoice",
                options: ["18 שקלים", "12 שקלים", "15 שקלים", "9 שקלים"],
                hint: "מצאו קודם כמה עולה קילו שלם של תפוחים, ואז חשבו את המחיר ל-$1\\frac{1}{2}$ ק״ג",
                solutionGuide: "אם חצי ק״ג עולה 6 שקלים, אז ק״ג עולה 12 שקלים. $1\\frac{1}{2}$ ק״ג יעלה: $12 × 1.5 = 18$ שקלים"
            },
            {
                question: "בבקבוק $2\\frac{1}{4}$ ליטר מיץ. כמה כוסות של $\\frac{1}{4}$ ליטר אפשר למזוג?",
                type: "multiplechoice",
                options: ["9 כוסות", "8 כוסות", "10 כוסות", "7 כוסות"],
                hint: "המירו את $2\\frac{1}{4}$ לשבר רגיל עם מכנה של 4 כדי להשוות לגודל הכוס",
                solutionGuide: "$2\\frac{1}{4} = \\frac{9}{4}$ ליטר. נחלק ב-$\\frac{1}{4}$: $\\frac{9}{4} ÷ \\frac{1}{4} = 9$ כוסות"
            },
            {
                question: "נועם אכל $\\frac{1}{5}$ מהעוגיות, מנור אכלה $\\frac{1}{4}$ מהנותר, ויהונתן אכל $\\frac{1}{3}$ ממה שנשאר. כמה עוגיות נשארו?",
                type: "multiplechoice",
                options: ["$\\frac{2}{5}$", "$\\frac{9}{20}$", "$\\frac{3}{5}$", "$\\frac{3}{10}$"],
                hint: "חשבו בשלבים: כמה נשאר אחרי נועם? מתוך זה, כמה נשאר אחרי מנור? ולבסוף אחרי יהונתן?",
                solutionGuide: "נשאר $\\frac{4}{5}$ אחרי נועם, $\\frac{3}{5}$ אחרי מנור, ו-$\\frac{2}{5}$ בסוף"
            },
            {
                question: "בכד $1\\frac{1}{3}$ ליטר מים. אם שופכים $\\frac{5}{6}$ ליטר, כמה נשאר?",
                type: "multiplechoice",
                options: ["$\\frac{1}{2}$ ליטר", "$\\frac{5}{6}$ ליטר", "$\\frac{1}{3}$ ליטר", "$\\frac{1}{6}$ ליטר"],
                hint: "המירו את $1\\frac{1}{3}$ לשבר רגיל כדי שיהיה קל יותר לחסר ממנו $\\frac{5}{6}$",
                solutionGuide: "$1\\frac{1}{3} = \\frac{4}{3}$ ליטר. $\\frac{4}{3} - \\frac{5}{6} = \\frac{8}{6} - \\frac{5}{6} = \\frac{1}{2}$ ליטר"
            },
            {
                question: "אם $\\frac{3}{4}$ מהתלמידים עברו את המבחן ו-18 תלמידים עברו, כמה תלמידים בכיתה?",
                type: "multiplechoice",
                options: ["24 תלמידים", "20 תלמידים", "22 תלמידים", "26 תלמידים"],
                hint: "אם 18 תלמידים הם $\\frac{3}{4}$ מהכיתה, חשבו כמה תלמידים הם רבע אחד מהכיתה",
                solutionGuide: "אם 18 תלמידים הם $\\frac{3}{4}$ מהכיתה, אז בכיתה יש: $18 ÷ \\frac{3}{4} = 24$ תלמידים"
            },
            {
                question: "חבל באורך $3\\frac{1}{4}$ מטרים נחתך ל-5 חלקים שווים. מה אורך כל חלק?",
                type: "multiplechoice",
                options: ["$\\frac{13}{20}$ מטר", "$\\frac{3}{5}$ מטר", "$\\frac{2}{3}$ מטר", "$\\frac{11}{20}$ מטר"],
                hint: "המירו תחילה את $3\\frac{1}{4}$ לשבר רגיל, ואז חלקו אותו ל-5 חלקים שווים",
                solutionGuide: "$3\\frac{1}{4} = \\frac{13}{4}$ מטר. $\\frac{13}{4} ÷ 5 = \\frac{13}{20}$ מטר"
            },
            {
                question: "אם $\\frac{2}{3}$ ק״ג עגבניות עולים 8 שקלים וקונים $1\\frac{1}{2}$ ק״ג, כמה צריך לשלם?",
                type: "multiplechoice",
                options: ["18 שקלים", "12 שקלים", "15 שקלים", "20 שקלים"],
                hint: "מצאו קודם כמה עולה קילו שלם של עגבניות, ואז חשבו את המחיר ל-$1\\frac{1}{2}$ ק״ג",
                solutionGuide: "מחיר לק״ג: $8 ÷ \\frac{2}{3} = 12$ שקלים. עבור $1\\frac{1}{2}$ ק״ג: $12 × 1.5 = 18$ שקלים"
            },
            {
                question: "דן אכל $\\frac{1}{3}$ מהפיצה, גיל אכל $\\frac{1}{4}$ ממה שנשאר, ורון אכל $\\frac{1}{2}$ מהשארית. כמה נשאר?",
                type: "multiplechoice",
                options: ["$\\frac{1}{4}$", "$\\frac{1}{2}$", "$\\frac{1}{8}$", "$\\frac{1}{6}$"],
                hint: "חשבו בשלבים: כמה נשאר אחרי דן? מתוך זה, כמה נשאר אחרי גיל? ולבסוף אחרי רון?",
                solutionGuide: "נשאר $\\frac{2}{3}=\\frac{4}{6}$ אחרי דן, $\\frac{3}{6} = \\frac{1}{2}$ אחרי גיל, ו-$\\frac{1}{4}$ בסוף"
            },
            {
                question: "אם $2\\frac{1}{2}$ מטר בד עולים 30 שקלים, כמה יעלה $\\frac{3}{4}$ מטר?",
                type: "multiplechoice",
                options: ["9 שקלים", "12 שקלים", "15 שקלים", "10 שקלים"],
                hint: "מצאו קודם כמה עולה מטר אחד של בד, ואז חשבו את המחיר ל-$\\frac{3}{4}$ מטר",
                solutionGuide: "מחיר למטר: $30 ÷ \\frac{5}{2} = 12$ שקלים. מחיר ל-$\\frac{3}{4}$ מטר: $12 × \\frac{3}{4} = 9$ שקלים"
            },
            {
                question: "מיכל מים מכיל $3\\frac{1}{3}$ ליטר. אם שופכים $\\frac{2}{3}$ ממנו, כמה ליטרים נשארו?",
                type: "multiplechoice",
                options: ["$1\\frac{1}{9}$ ליטר", "2 ליטר", "$1\\frac{1}{3}$ ליטר", "$\\frac{10}{3}$ ליטר"],
                hint: "המירו את $3\\frac{1}{3}$ לשבר רגיל. כשנשפך $\\frac{2}{3}$ נשאר $\\frac{1}{3}$ מהכמות המקורית",
                solutionGuide: "$3\\frac{1}{3} = \\frac{10}{3}$ ליטר. נשאר $\\frac{10}{3} × \\frac{1}{3} = \\frac{10}{9} = 1\\frac{1}{9}$ ליטר"
            },
            {
                question: "חבל באורך $2\\frac{1}{2}$ מטרים נחתך לחתיכות באורך $\\frac{1}{3}$ מטר. כמה חתיכות שלמות מקבלים?",
                type: "multiplechoice",
                options: ["7 חתיכות", "8 חתיכות", "6 חתיכות", "9 חתיכות"],
                hint: "המירו את $2\\frac{1}{2}$ לשבר רגיל וחלקו ב-$\\frac{1}{3}$. שימו לב שמבקשים רק חתיכות שלמות",
                solutionGuide: "$2\\frac{1}{2} = \\frac{5}{2}$ מטר. $\\frac{5}{2} ÷ \\frac{1}{3} = \\frac{15}{2} ÷ \\frac{1}{1} = 7.5$, לכן 7 חתיכות שלמות"
            },
            {
                question: "שני שליש מהתלמידים בכיתה הם בנים. אם יש 12 בנות, כמה תלמידים יש בכיתה?",
                type: "multiplechoice",
                options: ["36 תלמידים", "30 תלמידים", "32 תלמידים", "28 תלמידים"],
                hint: "אם הבנות הן שליש מהכיתה, כמה שלישים יש בכיתה שלמה?",
                solutionGuide: "12 בנות הן $\\frac{1}{3}$ מהכיתה. מספר התלמידים: $12 × 3 = 36$"
            },
            {
                question: " $\\frac{5}{6}$ מהתפוחים הם אדומים והשאר ירוקים. יש 15 תפוחים ירוקים, כמה תפוחים יש בסך הכל?",
                type: "multiplechoice",
                options: ["90 תפוחים", "75 תפוחים", "60 תפוחים", "80 תפוחים"],
                hint: "אם $\\frac{5}{6}$ הם אדומים, איזה חלק הם הירוקים? זה יעזור למצוא את הכמות הכוללת",
                solutionGuide: "15 תפוחים הם $\\frac{1}{6}$ מהכמות. סך הכל: $15 × 6 = 90$ תפוחים"
            },
            {
                question: "מיכל מים מכיל $2\\frac{3}{4}$ ליטר. מוזגים ממנו $\\frac{3}{4}$ ליטר ואז עוד $\\frac{1}{2}$ ליטר. כמה נשאר?",
                type: "multiplechoice",
                options: ["$1\\frac{1}{2}$ ליטר", "$1\\frac{3}{4}$ ליטר", "$1\\frac{1}{4}$ ליטר", "$1$ ליטר"],
                hint: "כדאי קודם להמיר את $2\\frac{3}{4}$ לשבר רגיל ואז לחסר בזה אחר זה",
                solutionGuide: "$2\\frac{3}{4} - \\frac{3}{4} - \\frac{1}{2} = 2 - \\frac{1}{2}  = 1\\frac{1}{2}$ ליטר"
            },
            {
                question: "רבע מהילדים בגן מציירים, שליש מהנותרים משחקים בקוביות, ויתר 10 הילדים קוראים ספר. כמה ילדים בגן?",
                type: "multiplechoice",
                options: ["20 ילדים", "24 ילדים", "28 ילדים", "32 ילדים"],
                hint: "התחילו מהסוף - אם 10 ילדים הם החלק שנשאר, איזה חלק הם מהווים מכל הילדים?",
                solutionGuide: "נתחיל: רבע ($\\frac{1}{4}$) מהילדים מציירים, נשארו $\\frac{3}{4}$ מהילדים. מתוכם שליש משחקים בקוביות - כלומר $\\frac{3}{4} \\cdot \\frac{2}{3} = \\frac{1}{2}$ נשארו. אם נשארו 10 ילדים שקוראים ספר והם מהווים חצי מהילדים, אז בגן יש בסך הכל 20 ילדים"
            },
            {
                question: "אורך מלבן $1\\frac{1}{2}$ מטר ורוחבו $\\frac{2}{3}$ מהאורך. מה שטחו?",
                type: "multiplechoice",
                options: ["$\\frac{3}{2}$ מ״ר", "$2$ מ״ר", "$3$ מ״ר", "$1$ מ״ר"],
                hint: "זכרו: שטח מלבן = אורך × רוחב. קודם מצאו את הרוחב בעזרת האורך",
                solutionGuide: "רוחב = $1\\frac{1}{2} × \\frac{2}{3} = 1$ מטר. שטח = $1\\frac{1}{2} × 1 = 1\\frac{1}{2} = \\frac{3}{2}$ מ״ר"
            },
            {
                question: "בקבוק מכיל $2\\frac{1}{4}$ ליטר מיץ. אם שותים $\\frac{3}{4}$ ליטר ואז מוסיפים $\\frac{1}{2}$ ליטר, כמה מיץ יש בבקבוק?",
                type: "multiplechoice",
                options: ["2 ליטר", "$1\\frac{3}{4}$ ליטר", "$2\\frac{1}{2}$ ליטר", "$1\\frac{1}{2}$ ליטר"],
                hint: "המירו את $2\\frac{1}{4}$ לשבר רגיל. זה יקל על החישוב של חיסור ואז חיבור",
                solutionGuide: "$2\\frac{1}{4} - \\frac{3}{4} + \\frac{1}{2} = \\frac{9}{4} - \\frac{3}{4} + \\frac{2}{4} = \\frac{8}{4} = 2$ ליטר"
            },
            {
                question: "התאימו בין הפיצות לרעבתנים!",
                type: "matching",
                pairs: [
                    {
                        left: "נועם הדינוזאור שאכל $\\frac{3}{4}$ פיצה",
                        right: "נשאר רבע פיצה"
                    },
                    {
                        left: "קופי הקוף שאכל $\\frac{1}{2}$ פיצה",
                        right: "נשאר חצי פיצה"
                    },
                    {
                        left: "גילי הג׳ירפה שאכלה $\\frac{2}{3}$ פיצה",
                        right: "נשאר שליש פיצה"
                    },
                    {
                        left: "פילי הפיל שאכל $\\frac{5}{6}$ פיצה",
                        right: "נשאר שישית פיצה"
                    }
                ],
                hint: "חישבו: אם אוכלים חלק מהפיצה, כמה נשאר? 1 פחות החלק שנאכל",
                solutionGuide: "כדי למצוא כמה נשאר, מחסרים את החלק שנאכל מ-1 (פיצה שלמה):<br/>נועם: $1 - \\frac{3}{4} = \\frac{1}{4}$<br/>קופי: $1 - \\frac{1}{2} = \\frac{1}{2}$<br/>גילי: $1 - \\frac{2}{3} = \\frac{1}{3}$<br/>פילי: $1 - \\frac{5}{6} = \\frac{1}{6}$"
            },
            {
                question: "התאימו בין החיות למשקל האוכל היומי שלהן!",
                type: "matching",
                pairs: [
                    {
                        left: "חתול שאוכל $\\frac{1}{4}$ ק״ג ליום", 
                        right: "אוכל $1\\frac{3}{4}$ ק״ג בשבוע"
                    },
                    {
                        left: "כלב שאוכל $\\frac{1}{2}$ ק״ג ליום",
                        right: "אוכל $3\\frac{1}{2}$ ק״ג בשבוע" 
                    },
                    {
                        left: "ארנב שאוכל $\\frac{1}{3}$ ק״ג ליום",
                        right: "אוכל $2\\frac{1}{3}$ ק״ג בשבוע"
                    },
                    {
                        left: "תוכי שאוכל $\\frac{1}{6}$ ק״ג ליום",
                        right: "אוכל $1\\frac{1}{6}$ ק״ג בשבוע"
                    }
                ],
                hint: "כפלו את הכמות היומית ב-7 ימים כדי למצוא את הכמות השבועית",
                solutionGuide: "מכפילים את הכמות היומית ב-7:<br/>חתול: $\\frac{1}{4} × 7 = \\frac{7}{4} = 1\\frac{3}{4}$<br/>כלב: $\\frac{1}{2} × 7 = \\frac{7}{2} = 3\\frac{1}{2}$<br/>ארנב: $\\frac{1}{3} × 7 = \\frac{7}{3} = 2\\frac{1}{3}$<br/>תוכי: $\\frac{1}{6} × 7 = \\frac{7}{6} = 1\\frac{1}{6}$"
            },
            {
                question: "התאימו בין הליצנים לבלונים שלהם!",
                type: "matching",
                pairs: [
                    {
                        left: "צחי הליצן איבד $\\frac{1}{3}$ מהבלונים",
                        right: "נשארו לו $\\frac{2}{3}$ מהבלונים"
                    },
                    {
                        left: "קוקי הליצן איבד $\\frac{1}{4}$ מהבלונים",
                        right: "נשארו לו $\\frac{3}{4}$ מהבלונים"
                    },
                    {
                        left: "דודי הליצן איבד $\\frac{1}{2}$ מהבלונים",
                        right: "נשארו לו $\\frac{1}{2}$ מהבלונים"
                    },
                    {
                        left: "ג׳וג׳ו הליצן איבד $\\frac{2}{5}$ מהבלונים",
                        right: "נשארו לו $\\frac{3}{5}$ מהבלונים"
                    }
                ],
                hint: "אם איבדו חלק מהבלונים, כמה נשאר? 1 פחות החלק שאבד",
                solutionGuide: "מחסרים את החלק שאבד מ-1 (כל הבלונים):<br/>צחי: $1 - \\frac{1}{3} = \\frac{2}{3}$<br/>קוקי: $1 - \\frac{1}{4} = \\frac{3}{4}$<br/>דודי: $1 - \\frac{1}{2} = \\frac{1}{2}$<br/>ג׳וג׳ו: $1 - \\frac{2}{5} = \\frac{3}{5}$"
            },
            {
                question: "התאימו בין הקוסמים לשיקויים שלהם!",
                type: "matching",
                pairs: [
                    {
                        left: "שיקוי צחוק: $\\frac{2}{3}$ כוס מיץ צפרדע",
                        right: "צריך $\\frac{1}{3}$ כוס לחצי מנה"
                    },
                    {
                        left: "שיקוי קפיצה: $\\frac{3}{4}$ כוס אבקת פיות",
                        right: "צריך $\\frac{1}{4}$ כוס לשליש מנה"
                    },
                    {
                        left: "שיקוי גדילה: $\\frac{1}{2}$ כוס קרני חד-קרן",
                        right: "צריך $\\frac{1}{6}$ כוס לשליש מנה"
                    },
                    {
                        left: "שיקוי היעלמות: $\\frac{4}{5}$ כוס דמעות דרקון",
                        right: "צריך $\\frac{2}{5}$ כוס לחצי מנה"
                    }
                ],
                hint: "כשמקטינים את המנה, הכמויות קטנות באותו יחס. למשל, חצי מנה צריכה חצי מהכמות המקורית",
                solutionGuide: "מכפילים את המנה המקורית בשבר המתאים:<br/>צחוק: $\\frac{2}{3} × \\frac{1}{2} = \\frac{1}{3}$ לחצי מנה<br/>קפיצה: $\\frac{3}{4} × \\frac{1}{3} = \\frac{1}{4}$ לשליש מנה<br/>גדילה: $\\frac{1}{2} × \\frac{1}{3} = \\frac{1}{6}$ לשליש מנה<br/>נעלמות: $\\frac{4}{5} × \\frac{1}{2} = \\frac{2}{5}$ לחצי מנה"
            }
        ]
    }
};