export const learningContent = {
    "ביטויים ומשוואות אלגבריות": {
        tags: ['כיתה ז׳', 'אלגברה'],
        relatedLinks: [
            { title: "תרגול ביטויים אלגבריים", url: "/?tags=אלגברה", description: "תרגול ביטויים ומשוואות אלגבריות" },
            { title: "פתרון משוואות", url: "/?tags=אלגברה", description: "תרגול פתרון משוואות אלגבריות" }
        ],

        getStarted: [
            "**מושגי יסוד באלגברה:**",
            "<b>משתנה:</b> אות המייצגת מספר לא ידוע (למשל x, y, a)",
            "<b>ביטוי אלגברי:</b> צירוף של מספרים ומשתנים (למשל 2x + 3)",
            "<b>משוואה:</b> שוויון בין שני ביטויים אלגבריים (למשל 2x + 3 = 7)",
            "<b>פתרון משוואה:</b> הערך של המשתנה שעבורו המשוואה נכונה",
            "",
            "**חוקי פעולות חשבון באלגברה:**",
            "<b>חיבור וחיסור:</b> אפשר לחבר או לחסר רק איברים דומים",
            "דוגמה: 2x + 3x = 5x אבל 2x + 3y נשאר כמו שהוא",
            "",
            "**כללי כפל וחילוק:**",
            "כפל משתנים: $a × a = a^2$",
            "חילוק משתנים: $\\frac{a}{a} = 1$",
            "חזקות: $(a^m)^n = a^{m×n}$",
            "כפל בסוגריים: $a(b + c) = ab + ac$",
            "",
            "**חוקי פעולות נוספים:**",
            "חוק הפילוג: $a(b + c) = ab + ac$",
            "חוק החילוף: $a + b = b + a$ וגם $a × b = b × a$",
            "חוק הקיבוץ: $(a + b) + c = a + (b + c)$"
        ],
        examples: [
            {
                question: "אם $x + 5 = 12$, מה ערכו של $x$?",
                solution: "1) נחסיר 5 משני האגפים של המשוואה<br />2) $x + 5 - 5 = 12 - 5$<br />3) $x = 7$"
            },
            {
                question: "אם $3x - 8 = 16$, מה ערכו של $x$?",
                solution: "1) נוסיף 8 לשני האגפים:<br />$3x - 8 + 8 = 16 + 8$<br />$3x = 24$<br />2) נחלק ב-3 את שני האגפים:<br />$x = 8$"
            },
            {
                question: "אם $\\frac{2x + 6}{3} = 8$, מה ערכו של $x$?",
                solution: "1) נכפול את שני האגפים ב-3:<br />$2x + 6 = 24$<br />2) נחסיר 6 משני האגפים:<br />$2x = 18$<br />3) נחלק ב-2:<br />$x = 9$"
            },
            {
                question: "אם מכפילים שלושה מספרים שלמים שליליים, מה יהיה סימן התוצאה?",
                solution: "1) נסמן את המספרים כ: (-a), (-b), (-c)<br />2) כשמכפילים שני מספרים שליליים, התוצאה חיובית<br />3) (-a) × (-b) = (ab) חיובי<br />4) (ab) × (-c) = (-abc) שלילי<br />5) לכן התוצאה תמיד שלילית"
            }
        ],
        quiz: [
            {
                question: "אם $a × b × c = 12$ ו-$c$ שלילי, מה בהכרח נכון?",
                type: "multiplechoice",
                options: [" אחד מבין $a$ ו-$b$ שלילי", "$a$ ו-$b$ שניהם שליליים", "$a$ ו-$b$ שניהם חיוביים", "אי אפשר לדעת"],
                hint: "חשבו על הכלל: כפל של שני מספרים שליליים נותן תוצאה חיובית",
                solutionGuide: "כדי שהתוצאה הסופית תהיה חיובית, אם $c$ שלילי, אז אחד מבין $a$ ו-$b$ שלילי והשני חיובי, כדי לבטל את השליליות של $c$."
            },

            {
                question: "אם $2a + 3b = 12$ ו-$a = 3$, מה ערכו של $b$?",
                type: "multiplechoice",
                options: ["2", "3", "4", "6"],
                hint: "הציבו את הערך הידוע של $a$ במשוואה ופתרו עבור $b$",
                solutionGuide: "נפתור את המשוואה שלב אחר שלב:<br />נציב $a = 3$ במשוואה $2a + 3b = 12$:<br />$2(3) + 3b = 12$<br />$6 + 3b = 12$<br />נחסר 6 משני האגפים:<br />$3b = 6$<br />נחלק ב-3:<br />$b = 2$"
            },
            {
                question: "מהו הביטוי האלגברי המתאר: 'מספר כלשהו מוכפל בעצמו ועוד 5'?",
                type: "multiplechoice",
                options: ["$x^2 + 5$", "$(x + 5)^2$", "$x + 5$", "$x^2$"],
                hint: "חשבו על הסדר: קודם כל המספר מוכפל בעצמו (חזקה), ורק אז מוסיפים 5",
                solutionGuide: "נפרק את התיאור המילולי לשלבים:<br/>1) 'מספר כלשהו' - נסמן ב-x<br/>2) 'מוכפל בעצמו' - זה $x^2$<br/>3) 'ועוד 5' - מוסיפים 5<br/>4) לכן הביטוי המתאים הוא $x^2 + 5$<br/>שימו לב: $(x + 5)^2$ זה ביטוי אחר לגמרי שמייצג $(x + 5)(x + 5)$"
            },
            {
                question: "אם $a^3 = 8$, מה ערכו של $a$?",
                type: "multiplechoice",
                options: ["2", "3", "4", "6"],
                hint: "חשבו איזה מספר בחזקת 3 נותן 8. אפשר לפרק את 8 לגורמים ראשוניים",
                solutionGuide: "נפתור את המשוואה $a^3 = 8$:<br />1) כדי למצוא את $a$, נמצא את השורש השלישי של 8:<br />2) $8 = 2 × 2 × 2$<br />3) לכן $\\sqrt[3]{8} = 2$<br />4) כלומר $a = 2$ כי $2^3 = 8$"
            },
            {
                question: "אם $(a+2)^2 = 16$, מה יכולים להיות הערכים של $a$?",
                type: "multiplechoice",
                options: ["$2$ או $-6$", "$4$ או $-8$", "$-6$ או $6$", "$2$ או $6$"],
                hint: "16 הוא 4 בריבוע. אז $a+2$ יכול להיות שווה ל-4 או ל-(-4)",
                solutionGuide: "16 הוא 4 בריבוע.<br />לכן $(a+2) = 4$ או $(a+2) = -4$<br />מהמשוואה הראשונה: $a+2 = 4$ ולכן $a = 2$<br />מהמשוואה השנייה: $a+2 = -4$ ולכן $a = -6$<br />נבדוק: אם $a=2$ או $a=-6$ אז $(a+2)^2 = 16$"
            },
            {
                question: "אם $\\frac{x}{2} + \\frac{x}{3} = 10$, מה ערכו של $x$?",
                type: "multiplechoice",
                options: ["12", "15", "18", "20"],
                hint: "כדי לחבר שברים עם מכנים שונים, צריך להביא אותם למכנה משותף",
                solutionGuide: "נביא למכנה משותף 6:<br />$\\frac{3x}{6} + \\frac{2x}{6} = 10$<br />נחבר במונה: $\\frac{5x}{6} = 10$<br />נכפול ב-6: $5x = 60$<br />נחלק ב-5: $x = 12$"
            },
            {
                question: "אם $2^n = 32$, מה ערכו של $n$?",
                type: "multiplechoice",
                options: ["5", "4", "6", "8"],
                hint: "נסו לפרק את 32 לגורמי 2 ולספור כמה פעמים מופיע המספר 2",
                solutionGuide: "32 = 2 × 2 × 2 × 2 × 2<br />כלומר יש כאן 5 כפלים של 2<br />לכן $2^5 = 32$ ומכאן ש-$n = 5$"
            },
            {
                question: "אם $2x + 5 = 13$, מה ערכו של $x$?",
                type: "multiplechoice",
                options: ["4", "3", "5", "6"],
                hint: "כדי למצוא את $x$, צריך קודם להעביר את כל המספרים לצד אחד",
                solutionGuide: "נחסיר 5 משני האגפים: $2x = 8$<br />נחלק ב-2: $x = 4$"
            },

            {
                question: "מהו הביטוי האלגברי המתאר: 'מספר כפול 4 ועוד 7'?",
                type: "multiplechoice",
                options: ["$4x + 7$", "$7x + 4$", "$4(x + 7)$", "$(4 + x)7$"],
                hint: "סמנו את המספר ב-$x$ וכתבו את הפעולות לפי הסדר: קודם הכפל ואז החיבור",
                solutionGuide: "המספר מסומן כ-$x$, כופלים אותו ב-4 ומוסיפים 7: $4x + 7$"
            },
            {
                question: "אם $3a - 2 = 10$, מה ערכו של $a + 1$?",
                type: "multiplechoice",
                options: ["5", "4", "3", "6"],
                hint: "קודם מצאו את $a$ מהמשוואה הראשונה, ואז חשבו $a + 1$",
                solutionGuide: "נמצא קודם את $a$: $3a = 12$, לכן $a = 4$<br />לכן $a + 1 = 5$"
            },
            {
                question: "מה ערך הביטוי $2x^2$ כאשר $x = 3$?",
                type: "multiplechoice",
                options: ["18", "12", "9", "6"],
                hint: "קודם חשבו את $x^2$, ורק אז הכפילו ב-2",
                solutionGuide: "$2x^2 = 2(3^2) = 2(9) = 18$"
            },
            {
                question: "אם $\\frac{x}{3} = 6$, מה ערכו של $x$?",
                type: "multiplechoice",
                options: ["18", "2", "9", "12"],
                hint: "כדי למצוא את $x$, כפלו את שני האגפים ב-3",
                solutionGuide: "נכפול את שני האגפים ב-3: $x = 18$"
            },
            {
                question: "אם $p = 2q$ ו-$q = 3$, מה ערכו של $p + q$?",
                type: "multiplechoice",
                options: ["9", "7", "8", "6"],
                hint: "קודם מצאו את $p$ על ידי הצבת הערך של $q$, ואז חברו",
                solutionGuide: "נציב $q = 3$ במשוואה הראשונה:<br />$p = 2(3) = 6$<br />לכן $p + q = 6 + 3 = 9$"
            },
            {
                question: "מהו הביטוי האלגברי המתאר: 'שליש ממספר כלשהו ואז להפחית 2'?",
                type: "multiplechoice",
                options: ["$\\frac{x}{3} - 2$", "$\\frac{x-2}{3}$", "$3x - 2$", "$\\frac{2}{3}x$"],
                hint: "סמנו את המספר ב-$x$ וכתבו את הפעולות לפי הסדר: קודם השליש ואז החיסור",
                solutionGuide: "שליש ממספר הוא $\\frac{x}{3}$, פחות 2 הוא $\\frac{x}{3} - 2$"
            },
            {
                question: "אם $5x = 20$, מה ערכו של $2x + 1$?",
                type: "multiplechoice",
                options: ["9", "7", "8", "6"],
                hint: "קודם מצאו את $x$ מהמשוואה הראשונה, ואז הציבו בביטוי השני",
                solutionGuide: "מ $5x = 20$ נובע ש $x = 4$<br />לכן $2x + 1 = 2(4) + 1 = 8 + 1 = 9$"
            },
            {
                question: "אם $a + b = 10$ ו $a - b = 4$, מה ערכו של $a$?",
                type: "multiplechoice",
                options: ["7", "5", "8", "6"],
                hint: "חברו את שתי המשוואות כדי להיפטר מ-$b$",
                solutionGuide: "יש לנו שתי משוואות:<br />$a + b = 10$<br />$a - b = 4$<br />נחבר אותן: $2a = 14$<br />נחלק ב-2: $a = 7$"
            },
            {
                question: "אם $2y + 3 = y + 8$, מה ערכו של $y$?",
                type: "multiplechoice",
                options: ["5", "4", "6", "3"],
                hint: "העבירו את כל האיברים עם $y$ לצד אחד ואת כל השאר לצד השני",
                solutionGuide: "נחסיר $y$ משני האגפים: $y + 3 = 8$, לכן $y = 5$"
            },
            {
                question: "מה ערך הביטוי $3(x + 2)$ כאשר $x = 4$?",
                type: "multiplechoice",
                options: ["18", "20", "14", "16"],
                hint: "קודם הציבו את $x$ בתוך הסוגריים, ורק אז כפלו ב-3",
                solutionGuide: "$3(x + 2) = 3(4 + 2) = 3(6) = 18$"
            },
            {
                question: "אם $\\frac{x+1}{2} = 4$, מה ערכו של $x$?",
                type: "multiplechoice",
                options: ["7", "8", "6", "5"],
                hint: "כפלו את שני האגפים ב-2 כדי להיפטר מהשבר",
                solutionGuide: "נפתור שלב אחר שלב:<br />1) נתחיל מהמשוואה: $\\frac{x+1}{2} = 4$<br />2) נכפול את שני האגפים ב-2 כדי להיפטר מהשבר:<br />   $(x+1) = 8$<br />3) נחסיר 1 משני האגפים:<br />   $x = 8-1 = 7$"
            },
            {
                question: "מהו הביטוי האלגברי המתאר: 'כפליים ממספר ועוד חצי ממנו'?",
                type: "multiplechoice",
                options: ["$2.5x$", "$\\frac{3}{2}x$", "$2x + \\frac{2x}{2}$", "$\\frac{5}{2}x+2$"],
                hint: "כתבו כל חלק בנפרד: כפליים זה $2x$, חצי זה $\\frac{x}{2}$, ואז חברו",
                solutionGuide: "כפליים ממספר זה $2x$, וחצי ממנו זה $\\frac{x}{2}$. נחבר את שני הביטויים: $2x + \\frac{x}{2} = \\frac{4x}{2} + \\frac{x}{2} = \\frac{5x}{2} = 2.5x$."
            },
            {
                question: "אם $3x - 4 = 8$, מה ערכו של $x + 2$?",
                type: "multiplechoice",
                options: ["6", "5", "7", "8"],
                hint: "קודם מצאו את $x$ מהמשוואה הראשונה, ואז חשבו $x + 2$",
                solutionGuide: "נפתור שלב אחר שלב:<br />1) $3x - 4 = 8$<br />2) נוסיף 4 לשני האגפים: $3x = 12$<br />3) נחלק ב-3: $x = 4$<br />4) כעת נחשב $x + 2 = 4 + 2 = 6$"
            },
            
            {
                question: "מה ערך הביטוי $(x + 1)^2$ כאשר $x = 3$?",
                type: "multiplechoice",
                options: ["16", "12", "10", "14"],
                hint: "קודם הציבו את $x$ וחשבו מה בתוך הסוגריים, ורק אז העלו בריבוע",
                solutionGuide: "$(x + 1)^2 = (3 + 1)^2 = 4^2 = 16$"
            },
            {
                question: "אם $4(x - 1) = 12$, מה ערכו של $x$?",
                type: "multiplechoice",
                options: ["4", "3", "5", "2"],
                hint: "קודם חלקו ב-4 כדי לבודד את הסוגריים",
                solutionGuide: "נחלק ב-4: $x - 1 = 3$, לכן $x = 4$"
            },
            {
                question: "אם $\\frac{x}{2} + \\frac{x}{3} = 15$, מה ערכו של $x$?",
                type: "multiplechoice",
                options: ["18", "24", "30", "36"],
                hint: "הביאו את השברים למכנה משותף כדי לחבר אותם",
                solutionGuide: "נביא למכנה משותף:<br />1) $\\frac{3x + 2x}{6} = 15$<br />2) $\\frac{5x}{6} = 15$<br />3) נכפול ב-6: $5x = 90$<br />4) נחלק ב-5: $x = 18$"
            }
        ]
    }
}; 