import React from 'react';
import { Box, VStack, HStack, Text, CircularProgress, Icon, Tag, TagLabel, TagLeftIcon  } from '@chakra-ui/react';
import { FiBook, FiTag, FiArrowLeft } from 'react-icons/fi';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const TopicCard = ({ title, tags, progress, onSelect }) => {
  return (
    <MotionBox
      whileHover={{ y: -4, scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      cursor="pointer"
      onClick={() => onSelect(title)}
      bg="white"
      p={6}
      borderRadius="2xl"
      boxShadow="lg"
      position="relative"
      overflow="hidden"
      width={["100%", "380px"]} 
      margin="1px"
      height="280px"
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        bgGradient: 'linear(to-r, blue.400, purple.400)',
      }}
    >
      <VStack spacing={2} align="stretch" height="100%">
        <HStack justify="flex-start" mb={1} position="relative">
          <Text 
            fontSize="2xl" 
            fontWeight="bold"
            pl={8}
          >
            {title}
          </Text>
          <Icon 
            as={FiBook} 
            w={6} 
            h={6} 
            color="blue.500"
            position="absolute"
            left={0}
            top={2}
          />
        </HStack>
        
        <Box flex="1">
          {tags.map(tag => (
            <Tag
              key={tag}
              size="md"
              borderRadius="full"
              variant="subtle"
              colorScheme="blue"
              mr={2}
              mb={1}
            >
            <TagLeftIcon as={FiTag} />
            <TagLabel>{tag}</TagLabel>
            </Tag>
          ))}
        </Box>

        <VStack spacing={2} position="absolute" bottom={6} width="calc(100% - 48px)">
          <HStack 
            justify="space-between"
            p={2}
            bg="gray.50"
            borderRadius="lg"
            align="center"
            width="100%"
          >
            <Text fontSize="lg" color="green.500" fontWeight="bold">בקיאות</Text>
            <HStack spacing={3}>
              <Text fontSize="lg" color="green.500" fontWeight="bold">
                {progress.mastery}%
              </Text>
              <CircularProgress
                value={progress.mastery}
                color="green.400"
                size="36px"
                thickness="8px"
              />
            </HStack>
          </HStack>

          <HStack 
            color="blue.600"
            fontWeight="bold"
            justify="flex-start"
            spacing={2}
            opacity={0.8}
            _hover={{ opacity: 1 }}
            dir="rtl"
            width="100%"
          >
            <Icon as={FiArrowLeft} />
            <Text>למד עוד</Text>
          </HStack>
        </VStack>
      </VStack>
    </MotionBox>
  );
};

export default TopicCard;