export const learningContent = {
    "תורת הקבוצות": {
        tags: ['כיתה ז׳', 'אלגברה', 'קבוצות'],

        relatedLinks: [
            {
                title: "תרגול קבוצות",
                url: "/?tags=קבוצות",
                description: "תרגול נוסף בתורת הקבוצות"
            },
            {
                title: "בעיות מילוליות בקבוצות",
                url: "/?tags=קבוצות,בעיות%20מילוליות",
                description: "תרגול בעיות מילוליות בקבוצות"
            },
            {
                title: "יחסים וקבוצות",
                url: "/?tags=קבוצות,יחסים",
                description: "תרגול משולב של יחסים וקבוצות"
            }
        ],

        getStarted: [
            "**מהי קבוצה?**",
            "קבוצה היא אוסף איברים ללא חשיבות לחזרה וללא חשיבות לסדר",
            "מסמנים קבוצה בסוגריים מסולסלים {}",
            "",
            "**הקבוצה הריקה:**",
            "מסמנים ב-{} או ב-∅",
            "קבוצה שאין בה איברים",
            "",
            "x ∈ A משמעו: x שייך לקבוצה A",
            "x ∉ A משמעו: x לא שייך לקבוצה A",
            "A ⊆ B משמעו: כל איברי A נמצאים גם ב-B",
            "A ⊈ B משמעו: יש איברים ב-A שאינם נמצאים ב-B",
            "",
            "**פעולות על קבוצות:**",
            "• חיתוך (∩): איברים המשותפים לשתי הקבוצות",
            "• איחוד (∪): כל האיברים שנמצאים לפחות באחת הקבוצות",
            "• הפרש (\\): איברי הקבוצה הראשונה שאינם בשנייה"
        ],

        examples: [
            {
                question: "האם 2 ∈ {1,2,3}?",
                solution: "כן, 2 הוא אחד מאיברי הקבוצה"
            },
            {
                question: "האם 4 ∉ {1,2,3}?",
                solution: "כן, 4 אינו נמצא בקבוצה {1,2,3}"
            },
            {
                question: "האם {1,2} ⊆ {1,2,3}?",
                solution: "כן, כי כל איברי הקבוצה {1,2} נמצאים גם בקבוצה {1,2,3}"
            },
            {
                question: "האם {1,4} ⊈ {1,2,3}?",
                solution: "כן, כי האיבר 4 נמצא ב-{1,4} אך לא נמצא ב-{1,2,3}"
            },
            {
                question: "מהו {1,2} ∩ {2,3}?",
                solution: "{2}, כי 2 הוא האיבר היחיד המשותף לשתי הקבוצות"
            },
            {
                question: "מהו {1,2,3} ∩ {3,4,5}?",
                solution: "{3}, כי 3 הוא האיבר היחיד המשותף לשתי הקבוצות"
            },
            {
                question: "מהו {1,2} ∪ {2,3}?",
                solution: "{1,2,3}, כי אלו כל האיברים שמופיעים לפחות באחת הקבוצות"
            },
            {
                question: "מהו {1,2,3} \\ {2}?",
                solution: "{1,3}, כי אלו האיברים שנמצאים ב-{1,2,3} אך לא ב-{2}"
            },
            {
                question: "מהו {1,2,3,4} \\ {1,3}?",
                solution: "{2,4}, כי אלו האיברים שנמצאים ב-{1,2,3,4} אך לא ב-{1,3}"
            },
            {
                question: "האם {} = ∅?",
                solution: "כן, אלו שני סימונים שונים לאותה קבוצה - הקבוצה הריקה"
            }
        ],

        quiz: [
            {
                type: "multiplechoice",
                question: "האם 1 ∈ {1,2,3}?",
                options: ["כן", "לא", "אי אפשר לדעת", "תלוי בסדר האיברים"],
                hint: "בדקו האם 1 הוא אחד מאיברי הקבוצה",
                solutionGuide: "כן, כי 1 הוא אחד מאיברי הקבוצה {1,2,3}"
            },

            {
                type: "multiplechoice",
                question: "האם {1} ⊆ {1,2,3}?",
                options: ["כן", "לא", "אי אפשר לדעת", "תלוי בסדר האיברים"],
                hint: "בדקו האם כל איברי {1} נמצאים ב-{1,2,3}",
                solutionGuide: "כן, כי האיבר היחיד בקבוצה {1} (הוא 1) נמצא גם בקבוצה {1,2,3}"
            },

            {
                type: "multiplechoice",
                question: "מהו {1,2} ∩ {2,3}?",
                options: ["{2}", "{1}", "{1,2,3}", "{3}"],
                hint: "חיתוך כולל רק איברים המשותפים לשתי הקבוצות",
                solutionGuide: "התשובה היא {2} כי רק 2 נמצא בשתי הקבוצות"
            },

            {
                type: "multiplechoice",
                question: "מהו {1,2} ∪ {2,3}?",
                options: ["{1,2,3}", "{2}", "{1,2}", "{2,3}"],
                hint: "איחוד כולל את כל האיברים שמופיעים לפחות באחת הקבוצות",
                solutionGuide: "התשובה היא {1,2,3} כי אלו כל האיברים שמופיעים באחת הקבוצות לפחות"
            },

            {
                type: "multiplechoice",
                question: "מהו {2} \\ {1,2,3}?",
                options: ["{1,3}", "{2}", "{1,2}", "{1,2,3}"],
                hint: "הפרש כולל את איברי הקבוצה הראשונה שאינם בקבוצה השנייה",
                solutionGuide: "התשובה היא {1,3} כי אלו האיברים שנמצאים ב-{1,2,3} אך לא ב-{2}"
            },

            {
                type: "multiplechoice",
                question: "האם {1,2} = {2,1}?",
                options: ["כן", "לא", "אי אפשר לדעת", "תלוי בקבוצה"],
                hint: "האם יש חשיבות לסדר בקבוצה?",
                solutionGuide: "כן, כי בקבוצה אין חשיבות לסדר האיברים"
            },

            {
                type: "multiplechoice",
                question: "האם {1,1,2} = {1,2}?",
                options: ["כן", "לא", "אי אפשר לדעת", "תלוי בקבוצה"],
                hint: "האם יש חשיבות לחזרות בקבוצה?",
                solutionGuide: "כן, כי בקבוצה אין חשיבות לחזרות על אותו איבר"
            },

            {
                type: "multiplechoice",
                question: "מהי הקבוצה הריקה?",
                options: ["קבוצה שאין בה איברים {}", "קבוצה שיש בה את המספר 0", "{0}", "קבוצה שיש בה רווח"],
                hint: "הקבוצה הריקה היא קבוצה מיוחדת",
                solutionGuide: "הקבוצה הריקה היא קבוצה שאין בה איברים כלל, מסומנת ב-{} או ב-∅"
            },

            {
                type: "multiplechoice",
                question: "האם 3 ∈ {{1,2}, 3}?",
                options: ["כן", "לא", "אי אפשר לדעת", "תלוי בקבוצה"],
                hint: "מהם האיברים בקבוצה הנתונה?",
                solutionGuide: "כן, כי 3 הוא אחד משני האיברים בקבוצה (האיבר השני הוא הקבוצה {1,2})"
            },

            {
                type: "multiplechoice",
                question: "האם {1,2} ∈ {{1,2}, 3}?",
                options: ["כן", "לא", "אי אפשר לדעת", "תלוי בקבוצה"],
                hint: "האם הקבוצה {1,2} היא אחד מאיברי הקבוצה?",
                solutionGuide: "כן, כי הקבוצה {1,2} היא אחד משני האיברים בקבוצה"
            },

            {
                type: "multiplechoice",
                question: "האם 1 ∈ {{1,2}, 3}?",
                options: ["לא", "כן", "אי אפשר לדעת", "תלוי בקבוצה"],
                hint: "האם 1 הוא אחד מאיברי הקבוצה הנתונה?",
                solutionGuide: "לא, כי האיברים בקבוצה הם {1,2} ו-3, ו-1 אינו אחד מהם"
            },

            {
                type: "multiplechoice",
                question: "האם {} ⊆ {1,2,3}?",
                options: ["כן", "לא", "אי אפשר לדעת", "תלוי בקבוצה"],
                hint: "האם כל איברי הקבוצה הריקה נמצאים בקבוצה השנייה?",
                solutionGuide: "כן, הקבוצה הריקה מוכלת בכל קבוצה כי אין לה איברים שצריך לבדוק אם הם בקבוצה השנייה"
            },
            {
                type: "multiplechoice",
                question: "מהי הקבוצה {⋄ ,⋆ 1,}?",
                options: ["קבוצה של 3 איברים: המספר 1, כוכב ומעוין",
                    "קבוצה של 2 איברים: כוכב ומעוין",
                    "קבוצה של מספר אחד ושתי צורות",
                    "קבוצה לא חוקית"],
                hint: "ספרו כמה איברים יש בקבוצה",
                solutionGuide: "זוהי קבוצה של 3 איברים: המספר 1, הסימן ⋆ (כוכב), והסימן ⋄ (מעוין)"
            },
            {
                type: "multiplechoice",
                question: "מהי הקבוצה {3 , {1,2}}?",
                options: ["קבוצה של 2 איברים: המספר 3 והקבוצה {1,2}",
                    "קבוצה של 3 איברים: 1,2,3",
                    "קבוצה של זוג מספרים",
                    "קבוצה לא חוקית"],
                hint: "האם {1,2} הוא איבר אחד או שניים?",
                solutionGuide: "זוהי קבוצה בת שני איברים: המספר 3 והקבוצה {1,2} שהיא איבר אחד"
            },
            {
                type: "multiplechoice",
                question: "אם A = {1,2,3} ו-B = {2,3,4}, מהו A ∩ B?",
                options: ["{2,3}", "{1,4}", "{1,2,3,4}", "{1,2,3} ∩ {2,3,4}"],
                hint: "חיתוך מכיל רק איברים המשותפים לשתי הקבוצות",
                solutionGuide: "חיתוך מכיל את האיברים המשותפים: 2 ו-3 נמצאים בשתי הקבוצות"
            },
            {
                type: "multiplechoice",
                question: "אם A = {1,2,3} ו-B = {2,3,4}, מהו A ∪ B?",
                options: ["{1,2,3,4}", "{2,3}", "{1,2,3} ∪ {1,3,4}", "{1,2,2,3,3,4,5}"],
                hint: "איחוד מכיל את כל האיברים שמופיעים לפחות באחת הקבוצות",
                solutionGuide: "איחוד מכיל את כל האיברים: 1,2,3 מ-A ו-2,3,4 מ-B, ללא חזרות"
            },
            {
                type: "multiplechoice",
                question: "אם A = {1,2,3} ו-B = {2,3,4}, מהו A \\ B?",
                options: ["{1}", "{4}", "{2,3}", "{1,2,3}"],
                hint: "הפרש מכיל איברים שנמצאים ב-A אך לא ב-B",
                solutionGuide: "1 הוא האיבר היחיד שנמצא ב-A אך לא ב-B"
            },
            {
                type: "multiplechoice",
                question: "האם {4} ∈ {1,2,3,4}?",
                options: ["לא", "כן", "אי אפשר לדעת", "תלוי בהקשר"],
                hint: "האם {4} זהה ל-4?",
                solutionGuide: "לא, כי {4} היא קבוצה המכילה את 4, בעוד שבקבוצה הנתונה 4 מופיע כאיבר"
            },
            {
                type: "multiplechoice",
                question: "אם A = {1,2} ו-B = {1,2,3}, האם A ⊆ B?",
                options: ["כן", "לא", "אי אפשר לדעת", "תלוי בסדר"],
                hint: "בדקו אם כל איבר ב-A נמצא גם ב-B",
                solutionGuide: "כן, כי כל איברי A (1 ו-2) נמצאים גם ב-B"
            },
            {
                type: "multiplechoice",
                question: "האם {} = {1} \\ {1}?",
                options: ["כן", "לא", "{0}", "אי אפשר לדעת"],
                hint: "מהם האיברים שנמצאים ב-{1} אך לא ב-{1}?",
                solutionGuide: "כן, כי אין איברים שנמצאים ב-{1} אך לא ב-{1}"
            },
            {
                type: "multiplechoice",
                question: "האם {1,2,2,3} = {1,2,3}?",
                options: ["כן", "לא", "תלוי בסדר", "תלוי בהקשר"],
                hint: "האם יש חשיבות לחזרות בקבוצה?",
                solutionGuide: "כן, כי בקבוצה אין חשיבות לחזרות של אותו איבר"
            },
            {
                type: "multiplechoice",
                question: "האם {1} = {{1}}?",
                options: ["לא", "כן", "תלוי בהקשר", "שתיהן קבוצות ריקות"],
                hint: "האם קבוצה שמכילה את 1 זהה לקבוצה שמכילה את הקבוצה {1}?",
                solutionGuide: "לא, הראשונה היא קבוצה שמכילה את המספר 1, השנייה מכילה את הקבוצה {1} כאיבר"
            }
        ]
    }
};