import React, { useEffect } from 'react';
import { Box, Text, VStack, ScaleFade, Heading, Link, Divider } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import MathVisualization from './MathVisualization';

export const ExplanationCard = ({ content, relatedLinks }) => {
  useEffect(() => {
    window.MathJax?.typeset();
  }, [content]);

  return (
    <ScaleFade in={true}>
      <VStack spacing={4} align="stretch" bg="white" p={4} borderRadius="xl" boxShadow="lg">
        {/* Main content */}
        <VStack spacing={2} align="stretch">
          {content.map((item, index) => (
            <Box key={index}>
              {typeof item === 'string' ? (
                <Text
                  fontSize="lg"
                  dangerouslySetInnerHTML={{
                    __html: item.replace(/\*\*(.*?)\*\*/g, '<strong class="text-blue-600">$1</strong>')
                  }}
                />
              ) : item.drawing && (
                <Box mt={4}>
                  <MathVisualization type={item.drawing.type} config={item.drawing} />
                </Box>
              )}
            </Box>
          ))}
        </VStack>

        {/* Related links section */}
        {relatedLinks && relatedLinks.length > 0 && (
          <>
            <Divider my={4} />
            <Box>
              <Heading size="md" color="blue.600" mb={2}>
                נושאים קשורים
              </Heading>
              <VStack align="stretch" spacing={2}>
                {relatedLinks.map((link, index) => (
                  <Link
                    key={index}
                    as={RouterLink}
                    to={link.url}
                    color="blue.500"
                    _hover={{
                      textDecoration: 'none',
                      color: 'blue.600',
                      bg: 'blue.50',
                    }}
                    p={2}
                    borderRadius="md"
                    transition="all 0.2s"
                  >
                    <Text fontWeight="medium">{link.title}</Text>
                    <Text fontSize="sm" color="gray.600">
                      {link.description}
                    </Text>
                  </Link>
                ))}
              </VStack>
            </Box>
          </>
        )}
      </VStack>
    </ScaleFade>
  );
}; 