import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';

const MotionBox = motion(Box);

// Move animations object outside component to avoid recreating it on every render
const ANIMATIONS = {
  Dino: {
    Idle: { frames: 10, frameRate: 150, speed: 0 },
    Walk: { frames: 10, frameRate: 50, speed: 0.7 },
    Run: { frames: 8, frameRate: 80, speed: 1.5 },
    Jump: { frames: 12, frameRate: 70, speed: 1.0 },
    Dead: { frames: 8, frameRate: 200, speed: 0 }
  },
  Dog: {
    Dead: { frames: 10, frameRate: 150, speed: 0 },
    Fall: { frames: 8, frameRate: 100, speed: 0 },
    Hurt: { frames: 10, frameRate: 150, speed: 0 },
    Idle: { frames: 10, frameRate: 150, speed: 0 },
    Jump: { frames: 8, frameRate: 70, speed: 1.0 },
    Run: { frames: 8, frameRate: 80, speed: 1.5 },
    Slide: { frames: 10, frameRate: 100, speed: 0.7 },
    Walk: { frames: 10, frameRate: 50, speed: 0.7 }
  },
  Cat: {
    Dead: { frames: 10, frameRate: 150, speed: 0 },
    Fall: { frames: 8, frameRate: 100, speed: 0 },
    Hurt: { frames: 10, frameRate: 150, speed: 0 },
    Idle: { frames: 10, frameRate: 150, speed: 0 },
    Jump: { frames: 8, frameRate: 70, speed: 1.0 },
    Run: { frames: 8, frameRate: 80, speed: 1.5 },
    Slide: { frames: 10, frameRate: 100, speed: 0.7 },
    Walk: { frames: 10, frameRate: 50, speed: 0.7 }
  }
};

const MascotAnimation = ({ state = 'Walk', character = 'Mascot' }) => {
  const [currentFrame, setCurrentFrame] = useState(1);
  const [isMovingRight, setIsMovingRight] = useState(true);
  const [loadedSprites, setLoadedSprites] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const animationRef = useRef(null);
  const controls = useAnimation();
  const mascotRef = useRef(null);
  const positionRef = useRef(0);

  // Update preloadSprites to use character
  const preloadSprites = React.useCallback(async (animationState) => {
    const frameCount = ANIMATIONS[character][animationState].frames;
    const loadPromises = [];

    for (let i = 1; i <= frameCount; i++) {
      const promise = new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(`${animationState}-${i}`);
        img.onerror = (error) => {
          console.error(`Failed to load: ${animationState} frame ${i}`, error);
          reject(error);
        };
        img.src = `/sprites/${character.toLowerCase()}/${animationState} (${i}).png`;
      });
      loadPromises.push(promise);
    }

    try {
      const loaded = await Promise.all(loadPromises);
      setLoadedSprites(prev => ({
        ...prev,
        [animationState]: loaded
      }));
    } catch (error) {
      console.error(`Failed to load sprites for ${animationState}:`, error);
    }
  }, [character]);

  // Update first useEffect
  useEffect(() => {
    const loadAllSprites = async () => {
      setIsLoading(true);
      const states = Object.keys(ANIMATIONS[character]);
      await Promise.all(states.map(state => preloadSprites(state)));
      setIsLoading(false);
    };

    loadAllSprites();
  }, [preloadSprites, character]);

  // Handle sprite frame animation
  useEffect(() => {
    if (isLoading) return;

    let frameCount = 1;
    let lastTimestamp = 0;

    const animate = (timestamp) => {
      if (!lastTimestamp) lastTimestamp = timestamp;

      const elapsed = timestamp - lastTimestamp;
      const { frames, frameRate } = ANIMATIONS[character][state];

      if (elapsed > frameRate && loadedSprites[state]) {
        // For Dead state, only animate once through the frames
        if (state === 'Dead' && frameCount >= frames) {
          setCurrentFrame(frames); // Stay on last frame
          return;
        }

        frameCount = (frameCount % frames) + 1;
        setCurrentFrame(frameCount);
        lastTimestamp = timestamp;
      }

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [state, isLoading, loadedSprites, character]);

  // Handle movement animation
  useEffect(() => {
    let animationFrameId;
    const speed = ANIMATIONS[character][state].speed;
    const easing = 0.02;
    let currentVelocity = 0;
    const maxVelocity = 1;

    const animate = () => {
      if (mascotRef.current) {
        const bounds = mascotRef.current.getBoundingClientRect();
        const container = document.getElementById('mascot-container');
        const containerBounds = container.getBoundingClientRect();
        const overflowSpace = 96; // One mascot width

        // Check if we hit the boundaries (with overflow space)
        if (bounds.right >= containerBounds.right + overflowSpace && isMovingRight) {
          setIsMovingRight(false);
          controls.start({ scaleX: -1 });
          currentVelocity = 0;
        } else if (bounds.left <= containerBounds.left - overflowSpace && !isMovingRight) {
          setIsMovingRight(true);
          controls.start({ scaleX: 1 });
          currentVelocity = 0;
        }

        // Smoothly adjust velocity
        const targetVelocity = isMovingRight ? maxVelocity : -maxVelocity;
        currentVelocity += (targetVelocity - currentVelocity) * easing;

        // Update position using current velocity
        positionRef.current += currentVelocity * speed;
        controls.set({ x: positionRef.current });
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isMovingRight, controls, state, character]);

  // Special animations for jump and dead states
  useEffect(() => {
    const handleSpecialAnimation = async () => {
      if (state === 'Jump') {
        // Create infinite jump animation
        controls.start({
          y: [0, -50, 0],
          transition: {
            duration: 1,
            times: [0, 0.5, 1],
            ease: "easeInOut",
            repeat: Infinity // Make the jump animation repeat indefinitely
          }
        });
      } else if (state === 'Dead') {
        await controls.start({
          rotate: 0,
          y: 0,
          transition: {
            duration: 1,
            ease: "easeIn",
            repeat: 0
          }
        });
      } else {
        await controls.start({
          y: 0,
          rotate: 0,
          scale: 1,
          transition: {
            duration: 0.3,
            ease: "easeOut"
          }
        });
      }
    };

    handleSpecialAnimation();
  }, [state, controls, character]);

  return (
    <Box
      id="mascot-container"
      position="relative"
      width="100%"
      height="120px"
      overflow="hidden"
      zIndex={10}
      bg="transparent"
      dir="ltr"
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" />
      ) : (
        <MotionBox
          ref={mascotRef}
          id="mascot-sprite"
          position="absolute"
          width="96px"
          height="96px"
          left="0px"
          top="30%"
          transform="translateY(-50%)"
          animate={controls}
          dir="ltr"
        >
          <img
            src={`/sprites/${character.toLowerCase()}/${state} (${currentFrame}).png`}
            alt={`${character} ${state}`}
            onError={(e) => {
              console.error(`Failed to load sprite: ${e.target.src}`);
              if (loadedSprites[state]) {
                e.target.src = `/sprites/${character.toLowerCase()}/${state} (1).png`;
              }
            }}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </MotionBox>
      )}
    </Box>
  );
};

export default MascotAnimation;