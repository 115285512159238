import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { 
  getFirestore, 
  doc, 
  setDoc, 
  getDoc
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCFLKVzNItXrSFHrlWsD_6CJEerzctmjJ8",
  authDomain: "thinkmathnow.com",
  projectId: "mathtutor-9830e",
  storageBucket: "mathtutor-9830e.firebasestorage.app",
  messagingSenderId: "467584181820",
  appId: "1:467584181820:web:f70caac3d650bc6eff4539",
  measurementId: "G-0R9KZ9LFN5"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export default app;

export const updateLastLogin = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    const lastLogin = userDoc.data()?.lastLoginAt?.toDate();
    
    const ONE_DAY = 24 * 60 * 60 * 1000;
    if (!lastLogin || (Date.now() - lastLogin.getTime() > ONE_DAY)) {
      await setDoc(doc(db, 'users', userId), { 
        lastLoginAt: new Date() 
      }, { merge: true });
    }
  } catch (error) {
    console.error('Error updating last login:', error);
  }
};