import { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';

export const useCharacter = () => {
  const [selectedCharacter, setSelectedCharacter] = useState('None');
  const [unlockedCharacters, setUnlockedCharacters] = useState(['None']);
  const { user, updateUserData } = useAuth();

  useEffect(() => {
    // Load character data from user document
    if (user) {
      setSelectedCharacter(user.selectedCharacter || 'None');
      setUnlockedCharacters(['None', ...(user.unlockedCharacters || [])]);
    } else {
      // Fallback to localStorage for non-authenticated users
      const saved = localStorage.getItem('characters');
      const selected = localStorage.getItem('selectedCharacter');
      if (saved) {
        setUnlockedCharacters(['None', ...JSON.parse(saved)]);
      }
      if (selected) {
        setSelectedCharacter(selected);
      }
    }
  }, [user]);

  const selectCharacter = async (character) => {
    setSelectedCharacter(character);
    if (user) {
      await updateUserData({ selectedCharacter: character });
    } else {
      localStorage.setItem('selectedCharacter', character);
    }
  };

  const unlockCharacter = async (characterId) => {
    const newUnlocked = [...unlockedCharacters, characterId];
    setUnlockedCharacters(newUnlocked);
    
    if (user) {
      await updateUserData({ 
        unlockedCharacters: newUnlocked.filter(c => c !== 'None')
      });
    } else {
      localStorage.setItem('characters', 
        JSON.stringify(newUnlocked.filter(c => c !== 'None'))
      );
    }
  };

  return {
    selectedCharacter,
    unlockedCharacters,
    selectCharacter,
    unlockCharacter
  };
}; 