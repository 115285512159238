import { UserFormContent } from './userFormContent';
import React, { useState, useEffect } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useToast
} from '@chakra-ui/react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const UserOnboarding = ({ isOpen, onClose, user, onComplete }) => {
  const [formData, setFormData] = useState({
    name: '',
    grade: ''
  });

  const toast = useToast();

  useEffect(() => {
    const loadUserData = async () => {
      if (user?.uid) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFormData({
            name: userData.name || '',
            grade: userData.grade || ''
          });
        }
      }
    };
    loadUserData();
  }, [user, isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.name || !formData.grade) {
        toast({
          title: 'שגיאה',
          description: 'נא למלא את כל השדות',
          status: 'error'
        });
        return;
      }

      // Get existing user data first
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const existingData = userDoc.exists() ? userDoc.data() : {};

      const userData = {
        ...existingData, // Preserve existing data
        ...formData, // Update with new form data
        email: user.email,
        displayName: user.displayName,
        createdAt: existingData.createdAt || new Date(),
        coins: existingData.coins ?? 0,
        selectedCharacter: existingData.selectedCharacter || 'None',
        unlockedCharacters: existingData.unlockedCharacters || ['None']
      };

      await setDoc(doc(db, 'users', user.uid), userData);
      onComplete(formData.grade);
    } catch (error) {
      console.error('Error saving user data:', error);
      toast({
        title: 'שגיאה',
        description: 'אירעה שגיאה בשמירת הפרטים',
        status: 'error'
      });
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {user?.uid ? 'עריכת פרטי משתמש' : 'ברוכים הבאים! ספרו לנו קצת על עצמכם'}
        </ModalHeader>
        <ModalBody>
          <UserFormContent formData={formData} setFormData={setFormData} showTopics={true} />
          <Button mt={4} colorScheme="blue" onClick={handleSubmit}>
            {user?.uid ? 'שמור שינויים' : 'התחל ללמוד'}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};