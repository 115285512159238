export const learningContent = {
    "המרות בין שברים, אחוזים ומספרים עשרוניים": {
        tags: ['כיתה ז׳', 'כיתה ו׳', 'שברים', 'אחוזים'],
        relatedLinks: [
            { title: "תרגול המרות בין שברים, אחוזים ומספרים עשרוניים", url: "/?tags=שברים,אחוזים,מספרים%20עשרוניים", description: "תרגול בעיות המרות בין שברים, אחוזים ומספרים עשרוניים" }
        ],
        getStarted: [
            "**המרות בין צורות ייצוג מספריות:**",
            "",
            "1. **משבר למספר עשרוני:**",
            "   לחלק מונה במכנה",
            "   דוגמה: $\\frac{3}{4} = 0.75$",
            "",
            "2. **ממספר עשרוני לאחוזים:**",
            "   להכפיל ב-100",
            "   דוגמה: $0.75 = 75\\%$",
            "",
            "3. **מאחוזים לשבר:**",
            "   לכתוב כשבר עם 100 במכנה",
            "   לצמצם אם אפשר",
            "   דוגמה: $75\\% = \\frac{75}{100} = \\frac{3}{4}$",
            "",
            "**טיפים חשובים:**",
            "לזכור שברים נפוצים:",
            "  $\\frac{1}{2} = 0.5 = 50\\%$",
            "  $\\frac{1}{4} = 0.25 = 25\\%$",
            "  $\\frac{3}{4} = 0.75 = 75\\%$",
            "תמיד לצמצם את השבר הסופי",
            "לבדוק אם התשובה הגיונית"
        ],
        examples: [
            {
                question: "המירו את $\\frac{3}{8}$ למספר עשרוני ולאחוזים",
                solution: "1. מספר עשרוני:<br/>$\\frac{3}{8} = 0.375$<br/><br/>2. אחוזים:<br/>$0.375 × 100 = 37.5\\%$",
                drawing: {
                    type: "rectangleGrid",
                    rows: 2,
                    cols: 4,
                    highlightCells: 3,
                    caption: "מלבן מחולק ל-8 חלקים שווים, 3 מהם מודגשים ($\\frac{3}{8}$)"
                }
            },
            {
                question: "המירו את 65% לשבר",
                solution: "1. נכתוב כשבר:<br/>$65\\% = \\frac{65}{100}$<br/><br/>2. נצמצם:<br/>$\\frac{65}{100} = \\frac{13}{20}$",
            },
            {
                question: "המירו את 0.8 לשבר ולאחוזים",
                solution: "1. אחוזים:<br/>$0.8 × 100 = 80\\%$<br/><br/>2. שבר:<br/>$\\frac{8}{10} = \\frac{4}{5}$",
                drawing: {
                    type: "fractionCircles",
                    fractions: ["4/5", "0.8", "80/100"],
                    caption: "שלוש דרכים להציג את אותו מספר"
                }
            }
        ],
        quiz: [
            {
                question: "מהו המספר העשרוני המייצג את $\\frac{7}{10}$?",
                type: "multiplechoice",
                options: ["0.7", "0.07", "0.77", "0.707"],
                hint: "חלקו את המונה במכנה. זכרו: כשמחלקים ב-10, המספר זז מקום אחד ימינה מהנקודה העשרונית",
                solutionGuide: "$\\frac{7}{10} = 0.7$ כי 7 ÷ 10 = 0.7"
            },
            {
                question: "מהו האחוז המייצג את $\\frac{1}{5}$?",
                type: "multiplechoice",
                options: ["20%", "25%", "15%", "10%"],
                hint: "המירו קודם לשבר עשרוני (חלקו 1 ב-5), ואז הכפילו ב-100 לקבלת אחוזים",
                solutionGuide: "$\\frac{1}{5} = 0.2 = 20\\%$"
            },
            {
                question: "מהו השבר המייצג את המספר העשרוני 0.25?",
                type: "multiplechoice",
                options: ["$\\frac{25}{100}$", "$\\frac{1}{5}$", "$\\frac{2}{5}$", "$\\frac{3}{4}$"],
                hint: "הזיזו את הנקודה העשרונית שתי ספרות ימינה והשתמשו במכנה 100",
                solutionGuide: "0.25 = $\\frac{25}{100}$"
            },
            {
                question: "מהו השבר המייצג את 40%?",
                type: "multiplechoice",
                options: ["$\\frac{40}{100}$", "$\\frac{3}{5}$", "$\\frac{1}{4}$", "$\\frac{1}{5}$"],
                hint: "כתבו את האחוז כשבר עם מכנה 100. אחר כך בדקו אם אפשר לצמצם",
                solutionGuide: "40% = $\\frac{40}{100}$"
            },
            {
                question: "מהו המספר העשרוני המייצג את $\\frac{3}{4}$?",
                type: "multiplechoice",
                options: ["0.75", "0.7", "0.74", "0.734"],
                hint: "חלקו 3 ב-4. זה אחד מהשברים הנפוצים שכדאי לזכור",
                solutionGuide: "$\\frac{3}{4} = 0.75$ כי 3 ÷ 4 = 0.75"
            },
            {
                question: "מהו האחוז המייצג את $\\frac{3}{5}$?",
                type: "multiplechoice",
                options: ["60%", "55%", "65%", "50%"],
                hint: "המירו קודם לעשרוני (חלקו 3 ב-5), ואז הכפילו ב-100",
                solutionGuide: "$\\frac{3}{5} = 0.6 = 60\\%$"
            },
            {
                question: "מהו השבר המייצג את המספר העשרוני 0.8?",
                type: "multiplechoice",
                options: ["$\\frac{4}{5}$", "$\\frac{3}{4}$", "$\\frac{9}{10}$", "$\\frac{2}{3}$"],
                hint: "הזיזו את הנקודה העשרונית מקום אחד ימינה וכתבו כשבר עם מכנה 10. אחר כך צמצמו",
                solutionGuide: "0.8 = $\\frac{8}{10}$ = $\\frac{4}{5}$"
            },
            {
                question: "מהו המספר העשרוני המייצג את 35%?",
                type: "multiplechoice",
                options: ["0.35", "3.5", "0.035", "0.305"],
                hint: "חלקו את המספר ב-100. זכרו: לחלק ב-100 זה כמו להזיז את הנקודה העשרונית שני מקומות שמאלה",
                solutionGuide: "35% = $\\frac{35}{100} = 0.35$"
            },
            {
                question: "מהו השבר המייצג את 0.6?",
                type: "multiplechoice",
                options: ["$\\frac{6}{10}$", "$\\frac{2}{5}$", "$\\frac{6}{100}$", "$\\frac{1}{6}$"],
                hint: "הזיזו את הנקודה העשרונית מקום אחד ימינה וכתבו כשבר עם מכנה 10",
                solutionGuide: "0.6 = $\\frac{6}{10}$"
            },
            {
                question: "מהו האחוז המייצג את $\\frac{7}{10}$?",
                type: "multiplechoice",
                options: ["70%", "75%", "65%", "80%"],
                hint: "המירו קודם למספר עשרוני (חלקו 7 ב-10), ואז הכפילו ב-100",
                solutionGuide: "$\\frac{7}{10} = 0.7 = 70\\%$"
            },
            {
                question: "מהו השבר המייצג את 45%?",
                type: "multiplechoice",
                options: ["$\\frac{9}{20}$", "$\\frac{45}{10}$", "$\\frac{4}{10}$", "$\\frac{5}{9}$"],
                hint: "כתבו קודם כשבר עם מכנה 100, ואז נסו לצמצם",
                solutionGuide: "45% = $\\frac{45}{100}$ = $\\frac{9}{20}$"
            },
            {
                question: "מהו המספר העשרוני המייצג את $\\frac{2}{8}$?",
                type: "multiplechoice",
                options: ["0.25", "0.28", "0.125", "0.225"],
                hint: "נסו קודם לצמצם את השבר. אז יהיה קל יותר לחלק",
                solutionGuide: "$\\frac{2}{8} = \\frac{1}{4} = 0.25$"           },
            {
                question: "מהו האחוז המייצג את $\\frac{5}{8}$?",
                type: "multiplechoice",
                options: ["62.5%", "55%", "65%", "60%"],
                hint: "חלקו קודם 5 ב-8 לקבלת מספר עשרוני. אחר כך הכפילו ב-100",
                solutionGuide: "$\\frac{5}{8} = 5 \\times \\frac{1}{8} = 5 \\times 0.125 = 0.625 = 62.5%$"          },
            {
                question: "מהו השבר המייצג את 85%?",
                type: "multiplechoice",
                options: ["$\\frac{85}{100}$", "$\\frac{8}{100}$", "$\\frac{17}{25}$", "$\\frac{4}{5}$"],
                hint: "כתבו את האחוז כשבר עם מכנה 100",
                solutionGuide: "85% = $\\frac{85}{100}$"
            },
            {
                question: "מהו האחוז המייצג את $\\frac{11}{20}$?",
                type: "multiplechoice",
                options: ["55%", "56%", "54%", "52%"],
                hint: "כדי להגיע למכנה 100, כפלו את המונה והמכנה ב-5",
                solutionGuide: "$\\frac{11}{20} \\times 5 = \\frac{55}{100} = 55\\%$"
            },
            {
                question: "מהו השבר המייצג את 0.375?",
                type: "multiplechoice",
                options: ["$\\frac{3}{8}$", "$\\frac{37}{100}$", "$\\frac{3.75}{100}$", "$\\frac{3}{7}$"],
                hint: "הזיזו את הנקודה העשרונית 3 מקומות ימינה וכתבו כשבר עם מכנה 1000. אז נסו לצמצם",
                solutionGuide: "$0.375 = 3 \\times 0.125 = 3 \\times \\frac{1}{8} = \\frac{3}{8}$"
            },
            {
                question: "מהו המספר העשרוני המייצג את 15%?",
                type: "multiplechoice",
                options: ["0.15", "1.5", "0.015", "1.05"],
                hint: "חלקו ב-100. זכרו: לחלק ב-100 זה כמו להזיז את הנקודה העשרונית שני מקומות שמאלה",
                solutionGuide: "15% = $\\frac{15}{100} = 0.15$"
            },
            {
                question: "מהו השבר המייצג את 0.4?",
                type: "multiplechoice",
                options: ["$\\frac{2}{5}$", "$\\frac{1}{4}$", "$\\frac{4}{100}$", "$\\frac{10}{4}$"],
                hint: "הזיזו את הנקודה העשרונית מקום אחד ימינה וכתבו כשבר עם מכנה 10. אז נסו לצמצם",
                solutionGuide: "0.4 = $\\frac{4}{10}$ = $\\frac{2}{5}$"
            },
            {
                question: "מהו האחוז המייצג את $\\frac{3}{20}$?",
                type: "multiplechoice",
                options: ["15%", "14%", "16%", "13%"],
                hint: "כדי להגיע למכנה 100, כפלו את המונה והמכנה ב-5",
                solutionGuide: "$\\frac{3}{20} \\times 5= \\frac{15}{100} = 15\\%$"
            },
            {
                question: "מהו השבר המייצג את 0.625?",
                type: "multiplechoice",
                options: ["$\\frac{5}{8}$", "$\\frac{6}{10}$", "$\\frac{125}{1000}$", "$\\frac{5}{6}$"],
                hint: "הזיזו את הנקודה העשרונית 3 מקומות ימינה וכתבו כשבר עם מכנה 1000. אז נסו לצמצם",
                solutionGuide: "$\\frac{5}{8} = 5 \\times \\frac{1}{8} = 5 \\times 0.125 = 0.625$"
            },
            {
                question: "מהו המספר העשרוני המייצג את $\\frac{1}{8}$?",
                type: "multiplechoice",
                options: ["0.125", "0.81", "0.108", "0.18"],
                hint: "חלקו 1 ב-8. זה אחד מהשברים הבסיסיים שכדאי לזכור",
                solutionGuide: "$\\frac{1}{8} = 0.125$"
            },
            {
                question: "מהו השבר המייצג את 0.2?",
                type: "multiplechoice",
                options: ["$\\frac{1}{5}$", "$\\frac{1}{2}$", "$\\frac{2}{100}$", "$\\frac{10}{2}$"],
                hint: "הזיזו את הנקודה העשרונית מקום אחד ימינה וכתבו כשבר עם מכנה 10. אז נסו לצמצם",
                solutionGuide: "$\\frac{1}{5}  = \\frac{2}{10} =  0.2$"
            },
            {
                question: "מהו האחוז המייצג את $\\frac{7}{8}$?",
                type: "multiplechoice",
                options: ["87.5%", "80%", "82.5%", "85%"],
                hint: "חלקו 7 ב-8 לקבלת מספר עשרוני. אחר כך הכפילו ב-100",
                solutionGuide: "$\\frac{7}{8} = 7 \\times \\frac{1}{8} = 0.875 = \\frac{87.5}{100} = 87.5\\%$"
            },
            {
                question: "מהו השבר המייצג את 30%?",
                type: "multiplechoice",
                options: ["$\\frac{3}{10}$", "$\\frac{1}{3}$", "$\\frac{3}{1}$", "$\\frac{3}{100}$"],
                hint: "כתבו את האחוז כשבר עם מכנה 100. אז נסו לצמצם",
                solutionGuide: "30% = $\\frac{30}{100} = $\\frac{3}{10}$"
            },
            {
                question: "מהו המספר העשרוני המייצג את $\\frac{9}{10}$?",
                type: "multiplechoice",
                options: ["0.9", "0.91", "0.19", "0.09"],
                hint: "חלקו את המונה במכנה. זכרו: כשמחלקים ב-10, המספר זז מקום אחד ימינה מהנקודה העשרונית",
                solutionGuide: "$\\frac{9}{10} = 0.9$"
            },
            {
                question: "מהו השבר המייצג את 95%?",
                type: "multiplechoice",
                options: ["$\\frac{95}{100}$", "$\\frac{5}{9}$", "$\\frac{95}{10}$", "$\\frac{9}{5}$"],
                hint: "כתבו את האחוז כשבר עם מכנה 100",
                solutionGuide: "95% = $\\frac{95}{100}$"
            },
            {
                question: "מהו המספר העשרוני המייצג את 17.5%?",
                type: "multiplechoice",
                options: ["0.175", "1.75", "0.0175", "17.5"],
                hint: "חלקו ב-100. זכרו: לחלק ב-100 זה כמו להזיז את הנקודה העשרונית שני מקומות שמאלה",
                solutionGuide: "17.5% = $\\frac{17.5}{100} = 0.175$"
            },
            {
                question: "מהו השבר המייצג את 0.92?",
                type: "multiplechoice",
                options: ["$\\frac{92}{100}$", "$\\frac{92}{10}$", "$\\frac{9}{2}$", "$\\frac{9}{20}$"],
                hint: "הזיזו את הנקודה העשרונית שני מקומות ימינה וכתבו כשבר עם מכנה 100",
                solutionGuide: "0.92 = $\\frac{92}{100}$"
            },
            {
                question: "מהו האחוז המייצג את $\\frac{13}{20}$?",
                type: "multiplechoice",
                options: ["65%", "60%", "70%", "75%"],
                hint: "כדי להגיע למכנה 100, כפלו את המונה והמכנה ב-5",
                solutionGuide: "$\\frac{13}{20} = \\frac{65}{100} = 65\\%$"
            },
            {
                question: "מהו המספר העשרוני המייצג את $\\frac{11}{25}$?",
                type: "multiplechoice",
                options: ["0.44", "0.45", "0.4", "0.404"],
                hint: "כדי להגיע למכנה 100, כפלו את המונה והמכנה ב-4",
                solutionGuide: "$\\frac{11}{25} = \\frac{11 \\times 4}{25 \\times 4} = \\frac{44}{100} = 0.44$"
            },
            {
                question: "סדרו את המספרים מהקטן לגדול",
                type: "order",
                items: ["$\\frac{1}{2}$", "$\\frac{2}{3}$", "0.7", "75%", "0.8"],
                hint: "המירו את כל המספרים לאותה צורת ייצוג (למשל, מספרים עשרוניים) להשוואה קלה",
                solutionGuide: "$\\frac{1}{2} = 0.5 < \\frac{2}{3} ≈ 0.67 < 0.7 < 75\\% = 0.75 < 0.8$"
            },
            {
                question: "סדרו את השברים לפי גודלם מהגדול לקטן",
                type: "order",
                items: ["0.85", "$\\frac{4}{5}$", "77%", "$\\frac{3}{4}$", "$\\frac{7}{10}$"],
                hint: "המירו את כל המספרים לאחוזים או למספרים עשרוניים",
                solutionGuide: "0.85 = 85% > $\\frac{4}{5} = 80\\%$ > $77\\%$ > $\\frac{3}{4} = 75\\%$ > $\\frac{7}{10} = 70\\%$"
            },
            {
                question: "סדרו את המספרים מהקטן לגדול לפי ערכם",
                type: "order",
                items: ["$\\frac{1}{8}$", "0.135", "15%", "$\\frac{1}{5}$", "0.25"],
                hint: "המירו את כל המספרים למספרים עשרוניים. זכרו: $\\frac{1}{8} = 0.125$",
                solutionGuide: "$\\frac{1}{8} = 0.125 < 0.135 < 15\\% = 0.15 < \\frac{1}{5} = 0.2 < 0.25$"
            },
            
            {
                question: "סדרו את המספרים לפי המרחק שלהם מ-50% מהקטן לגדול",
                type: "order",
                items: ["0.52", "0.45", "56%", "$\\frac{4}{10}$", "$\\frac{4}{5}$"],
                hint: "המירו הכל לאחוזים וחשבו את המרחק מ-50%",
                solutionGuide: "0.52 = 52%, |52% - 50%| = 2%, 0.45 = 45%, |45% - 50%| = 5%, 56% - 50% = 6%, $\\frac{4}{10} = 40\\%$, |40% - 50%| = 10%, $\\frac{4}{5} = 80\\%$, |80% - 50%| = 30%"
            },
            {
                question: "התאימו כל מספר עשרוני לייצוג השבר הפשוט שלו",
                type: "matching",
                pairs: [
                    { left: "0.75", right: "$\\frac{3}{4}$" },
                    { left: "0.125", right: "$\\frac{1}{8}$" },
                    { left: "0.6", right: "$\\frac{3}{5}$" },
                    { left: "0.375", right: "$\\frac{3}{8}$" },
                    { left: "0.875", right: "$\\frac{7}{8}$" }
                ],
                hint: "חשבו על השברים הבסיסיים והמרותיהם למספרים עשרוניים",
                solutionGuide: "זכרו את ההמרות הנפוצות: $\\frac{1}{8} = 0.125$, $\\frac{1}{4} = 0.25$ וכו'"
            },
            {
                question: "התאימו כל אחוז לשבר המצומצם שלו",
                type: "matching",
                pairs: [
                    { left: "37.5%", right: "$\\frac{3}{8}$" },
                    { left: "62.5%", right: "$\\frac{5}{8}$" },
                    { left: "87.5%", right: "$\\frac{7}{8}$" },
                    { left: "12.5%", right: "$\\frac{1}{8}$" },
                    { left: "16.67%", right: "$\\frac{1}{6}$" }
                ],
                hint: "המירו את האחוזים לשברים עם מכנה 100 ואז צמצמו",
                solutionGuide: "לדוגמה: 37.5% = $\\frac{37.5}{100} = \\frac{3}{8}$"
            },
            {
                question: "התאימו כל ביטוי לערך העשרוני שלו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{2} + \\frac{1}{4}$", right: "0.75" },
                    { left: "$\\frac{1}{5} + \\frac{1}{10}$", right: "0.3" },
                    { left: "$1 - \\frac{1}{5}$", right: "0.8" },
                    { left: "$\\frac{3}{10} + \\frac{1}{5}$", right: "0.5" },
                    { left: "$1 - \\frac{1}{8}$", right: "0.875" }
                ],
                hint: "המירו כל שבר למספר עשרוני ובצעו את החישוב",
                solutionGuide: "לדוגמה: $\\frac{1}{2} + \\frac{1}{4} = 0.5 + 0.25 = 0.75$"
            },
            {
                question: "התאימו כל מספר לכל הייצוגים השווים לו",
                type: "matching",
                pairs: [
                    { left: "$\\frac{1}{4}$", right: "0.25" },
                    { left: "$\\frac{3}{4}$", right: "75%" },
                    { left: "$\\frac{1}{8}$", right: "12.5%" },
                    { left: "$\\frac{7}{8}$", right: "0.875" },
                    { left: "$\\frac{1}{5}$", right: "20%" }
                ],
                hint: "המירו את השבר הפשוט למספר עשרוני ולאחוזים",
                solutionGuide: "לדוגמה: $\\frac{1}{4} = 0.25 = 25\\%$"
            },
            {
                question: "התאימו כל טווח לזוג המספרים המתאים",
                type: "matching",
                pairs: [
                    { left: "בין 0.5 ל-0.6", right: "$\\frac{11}{20}$" },
                    { left: "בין 0.7 ל-0.8", right: "75%" },
                    { left: "בין 0.2 ל-0.3", right: "$\\frac{1}{4}$" },
                    { left: "בין 0.8 ל-0.9", right: "$\\frac{17}{20}$" },
                    { left: "בין 0.3 ל-0.4", right: "35%" }
                ],
                hint: "המירו את האחוזים והשברים למספרים עשרוניים ובדקו אם הם נמצאים בטווח",
                solutionGuide: "לדוגמה: 55% = 0.55 נמצא בין 0.5 ל-0.6"
            }
        ]
    }
};