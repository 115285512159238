import React from 'react';
import { Box } from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { MatchingBox } from './MatchingBox';

export const DraggableItem = ({ item, index, itemKey }) => (
  <Draggable key={itemKey} draggableId={itemKey} index={index}>
    {(provided, snapshot) => {
      setTimeout(() => window.MathJax?.typeset(), 0);
      return (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          mb={2}
        >
          <MatchingBox 
            content={item}
            isDraggable={true}
            isDragging={snapshot.isDragging}
          />
        </Box>
      );
    }}
  </Draggable>
); 