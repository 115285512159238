export const learningContent = {
    "חיבור חיסור וכפל בעיות מילוליות": {
        tags: ['כיתה ב׳', 'כיתה ג׳', 'בעיות מילוליות'],
        relatedLinks: [
            { title: "תרגול חיבור וחיסור מילולי", url: "/?tags=חיבור%20וחיסור", description: "תרגול בעיות מילוליות עם חיבור וחיסור" },
            { title: "תרגול כפל מילולי", url: "/?tags=כפל", description: "תרגול בעיות מילוליות בכפל" }
        ],

        getStarted: [
            "**איך פותרים בעיות מילוליות?**",
            "",
            "<b>צעד 1: קוראים בעיון</b>",
            "קוראים את השאלה לאט ובקול",
            "מסמנים מילים חשובות (כמה? סך הכל? נשאר?)",
            "",
            "<b>צעד 2: מציירים את הבעיה</b>",
            "ציור עוזר להבין מה צריך לעשות",
            "מסמנים את המספרים בציור",
            "",
            "<b>צעד 3: בוחרים פעולה חשבונית</b>",
            "חיבור (+) כשמוסיפים או מצרפים",
            "חיסור (-) כשמורידים או נשאר",
            "כפל (×) כשיש קבוצות שוות",
            "חילוק (÷) כשמחלקים שווה בשווה",
            "",
            "<b>צעד 4: פותרים ובודקים</b>",
            "כותבים תרגיל",
            "פותרים בזהירות",
            "בודקים אם התשובה הגיונית",
            "",
            "**טיפים חשובים:**",
            "קוראים את השאלה פעמיים",
            "מסמנים מספרים בצבע",
            "כדאי תמיד לצייר",
            "בודקים אם התשובה הגיונית"
        ],
        examples: [
            {
                question: "בכיתה יש 24 תלמידים. המורה חילקה אותם לקבוצות של 4 תלמידים. כמה קבוצות יש?",
                solution: "<br />1. מסמנים: 24 תלמידים בסך הכל<br />2. בכל קבוצה: 4 תלמידים<br />3. צריך לחלק: $24 ÷ 4 = 6$<br />4. התשובה: 6 קבוצות",
                drawing: {
                    type: "groups",
                    total: 24,
                    groupSize: 4,
                    caption: "24 תלמידים מחולקים ל-6 קבוצות של 4",
                    emoji: "👩‍🎓",
                }
            },
            {
                question: "דני קנה 3 קופסאות צבעים. בכל קופסה 8 צבעים. כמה צבעים יש לו בסך הכל?",
                solution: "$3 × 8 = 24$ צבעים",
                drawing: {
                    type: "groups",
                    total: 24,
                    groupSize: 3,
                    caption: "3 קופסאות עם 8 צבעים בכל אחת",
                    emoji: "🖍️"
                }
            },
            {
                question: "יש 15 סוכריות. חילקו אותן בין 3 ילדים. כמה קיבל כל ילד?",
                solution: "$15 ÷ 3 = 5$ סוכריות",
                drawing: {
                    type: "groups",
                    total: 15,
                    groupSize: 3,
                    caption: "15 סוכריות מחולקות ל-3 ילדים",
                    emoji: "🍬"
                }
            },
            {
                question: "בחנות יש 30 תפוחים. בעל החנות חילק אותם ל-5 ארגזים. כמה תפוחים יש בכל ארגז?",
                solution: "$30 ÷ 5 = 6$ תפוחים",
                drawing: {
                    type: "groups",
                    total: 30,
                    groupSize: 5,
                    caption: "30 תפוחים מחולקים ל-5 קבוצות של 6",
                    emoji: "🍏"
                }
            },
            {
                question: "יש 48 עוגיות. אם חילקו אותן ל-8 ילדים, כמה עוגיות קיבל כל ילד?",
                solution: "$48 ÷ 8 = 6$ עוגיות",
                drawing: {
                    type: "groups",
                    total: 48,
                    groupSize: 8,
                    caption: "48 עוגיות מחולקות ל-8 ילדים",
                    emoji: "🍪"
                }
            },
            {
                question: "בכיתה יש 36 תלמידים. המורה חילקה אותם ל-6 קבוצות. כמה תלמידים יש בכל קבוצה?",
                solution: "$36 ÷ 6 = 6$ תלמידים",
                drawing: {
                    type: "groups",
                    total: 36,
                    groupSize: 6,
                    caption: "36 תלמידים מחולקים ל-6 קבוצות של 6",
                    emoji: "👦"
                }
            }
        ],
        quiz: [
            {
                question: "ליהלי יש {A} שקלים. היא קנתה ספר ב-{B} שקלים. כמה כסף נשאר לה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 99],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-5"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "digit-swap"]
                },
                hint: "כדי למצוא כמה כסף נשאר, צריך להחסיר את מחיר הספר מהסכום ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ שקלים נשארו"
            },
            {
                question: "בכל שורה בגינה יש {A} פרחים. אם יש {B} שורות, כמה פרחים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [4, 9],
                        type: "integer"
                    },
                    B: {
                        range: [3, 8],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל הפרחים, צריך לכפול את מספר הפרחים בשורה במספר השורות",
                solutionGuide: "$ {A} × {B} = {Result} $ פרחים"
            },

            {
                question: "יהונתן קנה {A} גלידות. כל גלידה עולה {B} שקלים. הוא שילם עם שטר של 20 שקלים. כמה עודף הוא קיבל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 3],
                        type: "integer"
                    },
                    B: {
                        range: [4, 6],
                        type: "integer"
                    }
                },
                formula: "20-(A*B)",
                metadata: {
                    cost: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "קודם חשבו כמה עולות כל הגלידות ביחד, ואז הפחיתו את הסכום מהשטר ששילם",
                solutionGuide: "מחיר הגלידות: $ {A} × {B} = {cost} $ שקלים<br/>עודף: $20 - {cost} = {Result} $ שקלים"
            },

            {
                question: "במגרש משחקים {total} ילדים. הם רוצים להתחלק לזוגות. כמה זוגות יהיו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 10],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * 2"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "בכל זוג יש 2 ילדים. צריך לחלק את מספר הילדים ב-2",
                solutionGuide: "$ {total} ÷ 2 = {Result} $ זוגות"
            },

            {
                question: "בכיתה יש {A} בנים ו-{B} בנות. כמה תלמידים יש בכיתה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [10, 20],
                        type: "integer"
                    },
                    B: {
                        range: [10, 20],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "כדי למצוא את סך כל התלמידים, צריך לחבר את מספר הבנים עם מספר הבנות",
                solutionGuide: "$ {A} + {B} = {Result} $ תלמידים"
            },

            {
                question: "טל קנה {A} מחברות. כל מחברת עולה {B} שקלים. כמה שילם בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 9],
                        type: "integer"
                    },
                    B: {
                        range: [3, 8],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "כדי למצוא את המחיר הכולל, צריך לכפול את מחיר מחברת אחת במספר המחברות",
                solutionGuide: "$ {A} × {B} = {Result} $ שקלים"
            },

            {
                question: "היו {A} כדורים. {B} כדורים התפוצצו. כמה כדורים נשארו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A-5"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה כדורים נשארו, צריך להחסיר את מספר הכדורים שהתפוצצו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ כדורים"
            },

            {
                question: "לכל ילד יש {A} בלונים. כמה בלונים יש ל-{B} ילדים?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 6],
                        type: "integer"
                    },
                    B: {
                        range: [3, 10],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "כדי למצוא את סך כל הבלונים, צריך לכפול את מספר הבלונים לילד במספר הילדים",
                solutionGuide: "$ {A} × {B} = {Result} $ בלונים"
            },

            {
                question: "בקופסה היו {total} סוכריות. אבא חילק אותן שווה בשווה בין {B} ילדים. כמה סוכריות קיבל כל ילד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 9],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה סוכריות קיבל כל ילד, צריך לחלק את מספר הסוכריות במספר הילדים",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ סוכריות לכל ילד"
            },



            {
                question: "שי קנתה {A} קופסאות עפרונות. בכל קופסה {B} עפרונות. כמה עפרונות יש לה בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [4, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל העפרונות, צריך לכפול את מספר העפרונות בקופסה במספר הקופסאות",
                solutionGuide: "$ {A} × {B} = {Result} $ עפרונות"
            },

            {
                question: "בכיתה יש {A} תלמידים. {B} תלמידים הלכו הביתה. כמה תלמידים נשארו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 35],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A-5"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה תלמידים נשארו, צריך להחסיר את מספר התלמידים שהלכו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ תלמידים נשארו"
            },

            {
                question: "אמא קנתה {total} תפוזים וחילקה אותם שווה בשווה בין {B} ילדים. כמה תפוזים קיבל כל ילד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 8],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה תפוזים קיבל כל ילד, צריך לחלק את מספר התפוזים במספר הילדים",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ תפוזים לכל ילד"
            },

            {
                question: "בגינה יש {A} שורות של פרחים. בכל שורה {B} פרחים. כמה פרחים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [4, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל הפרחים, צריך לכפול את מספר הפרחים בשורה במספר השורות",
                solutionGuide: "$ {A} × {B} = {Result} $ פרחים"
            },

            {
                question: "יש {total} כוסות. צריך לחלק אותן ל-{B} שולחנות שווה בשווה. כמה כוסות יהיו בכל שולחן?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 10],
                        type: "integer"
                    },
                    B: {
                        range: [2, 8],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה כוסות בכל שולחן, צריך לחלק את מספר הכוסות במספר השולחנות",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ כוסות בכל שולחן"
            },

            {
                question: "לדני היו {A} שקלים. הוא קנה משחק ב-{B} שקלים. כמה כסף נשאר לו?",
                type: "multiChoiceRandomMath",
                params: {
                  A: {
                    range: [30, 90],
                    type: "integer"
                },
                    B: {
                        range: [20, 40],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה כסף נשאר, צריך להחסיר את מחיר המשחק מהסכום ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ שקלים נשארו"
            },

            {
                question: "בכל קומה בבניין יש {A} דירות. כמה דירות יש ב-{B} קומות?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 8],
                        type: "integer"
                    },
                    B: {
                        range: [3, 6],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל הדירות, צריך לכפול את מספר הדירות בקומה במספר הקומות",
                solutionGuide: "$ {A} × {B} = {Result} $ דירות"
            },

            {
                question: "יש {total} ילדים בחוג. המדריך רוצה לחלק אותם ל-{B} קבוצות שוות. כמה ילדים יהיו בכל קבוצה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [2, 8],
                        type: "integer"
                    },
                    B: {
                        range: [2, 6],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה ילדים בכל קבוצה, צריך לחלק את מספר הילדים במספר הקבוצות",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ ילדים בכל קבוצה"
            },

            {
                question: "ליהלי יש {A} מדבקות. היא קיבלה עוד {B} מדבקות. כמה מדבקות יש לה עכשיו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 40],
                        type: "integer"
                    },
                    B: {
                        range: [10, 30],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "כדי למצוא את סך כל המדבקות, צריך לחבר את המדבקות שהיו לה עם המדבקות החדשות",
                solutionGuide: "$ {A} + {B} = {Result} $ מדבקות"
            },

            {
                question: "יהונתן קנה {A} חבילות של ביסקוויטים. בכל חבילה {B} ביסקוויטים. כמה ביסקוויטים יש לו בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 9],
                        type: "integer"
                    },
                    B: {
                        range: [2, 6],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל הביסקוויטים, צריך לכפול את מספר הביסקוויטים בחבילה במספר החבילות",
                solutionGuide: "$ {A} × {B} = {Result} $ ביסקוויטים"
            },

            {
                question: "היו בסל {A} תפוחים. נרקבו {B} תפוחים. כמה תפוחים טובים נשארו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [30, 60],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A-10"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה תפוחים טובים נשארו, צריך להחסיר את מספר התפוחים שנרקבו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ תפוחים"
            },

            {
                question: "בכיתה יש {total} כיסאות. המורה סידרה אותם ב-{B} שורות שוות. כמה כיסאות יש בכל שורה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [3, 9],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה כיסאות בכל שורה, צריך לחלק את מספר הכיסאות במספר השורות",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ כיסאות בכל שורה"
            },

            {
                question: "לגן הגיעו {A} בנים ו-{B} בנות. כמה ילדים הגיעו בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [10, 25],
                        type: "integer"
                    },
                    B: {
                        range: [10, 25],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "כדי למצוא את סך כל הילדים, צריך לחבר את מספר הבנים עם מספר הבנות",
                solutionGuide: "$ {A} + {B} = {Result} $ ילדים"
            },

            {
                question: "בחנות יש {A} בלונים. נמכרו {B} בלונים. כמה בלונים נשארו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [30, 80],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-5"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה בלונים נשארו, צריך להחסיר את מספר הבלונים שנמכרו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ בלונים"
            },

            {
                question: "אמא קנתה {A} חבילות של סוכריות. בכל חבילה {B} סוכריות. כמה סוכריות יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [4, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל הסוכריות, צריך לכפול את מספר הסוכריות בחבילה במספר החבילות",
                solutionGuide: "$ {A} × {B} = {Result} $ סוכריות"
            },

            {
                question: "בגן החיות יש {total} ציפורים. הגנן חילק אותן ל-{B} כלובים שווים. כמה ציפורים בכל כלוב?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 12],
                        type: "integer"
                    },
                    B: {
                        range: [2, 8],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה ציפורים בכל כלוב, צריך לחלק את מספר הציפורים במספר הכלובים",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ ציפורים בכל כלוב"
            },


            {
                question: "טל קנה ארטיק ב-{A} שקלים ושתייה ב-{B} שקלים. כמה שילם בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [5, 10],
                        type: "integer"
                    },
                    B: {
                        range: [4, 8],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את הסכום הכולל, צריך לחבר את מחיר הארטיק עם מחיר השתייה",
                solutionGuide: "$ {A} + {B} = {Result} $ שקלים"
            },

            {
                question: "בספרייה יש {total} ספרים. הספרנית סידרה אותם ב-{B} מדפים שווים. כמה ספרים יש בכל מדף?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [6, 12],
                        type: "integer"
                    },
                    B: {
                        range: [3, 9],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה ספרים בכל מדף, צריך לחלק את מספר הספרים במספר המדפים",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ ספרים בכל מדף"
            },

            {
                question: "במגרש שיחקו {A} ילדים. הלכו הביתה {B} ילדים. כמה ילדים נשארו במגרש?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 40],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A-5"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה ילדים נשארו, צריך להחסיר את מספר הילדים שהלכו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ ילדים"
            },

            {
                question: "יש {A} קבוצות של תלמידים. בכל קבוצה {B} תלמידים. כמה תלמידים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [3, 6],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל התלמידים, צריך לכפול את מספר התלמידים בקבוצה במספר הקבוצות",
                solutionGuide: "$ {A} × {B} = {Result} $ תלמידים"
            },

            {
                question: "בחנות יש {A} ספרים. נמכרו {B} ספרים. כמה ספרים נשארו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [30, 60],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-5"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה ספרים נשארו, צריך להחסיר את מספר הספרים שנמכרו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ ספרים נשארו"
            },

            {
                question: "אמא קנתה {A} חבילות של עוגיות. בכל חבילה {B} עוגיות. כמה עוגיות יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [4, 9],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל העוגיות, צריך לכפול את מספר העוגיות בחבילה במספר החבילות",
                solutionGuide: "$ {A} × {B} = {Result} $ עוגיות"
            },

            {
                question: "בגינה יש {A} פרחים. קטפו {B} פרחים. כמה פרחים נשארו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [40, 70],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-5"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה פרחים נשארו, צריך להחסיר את מספר הפרחים שקטפו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ פרחים"
            },

            {
                question: "בכל קומה בבניין יש {A} דירות. כמה דירות יש ב-{B} קומות?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [4, 8],
                        type: "integer"
                    },
                    B: {
                        range: [3, 7],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל הדירות, צריך לכפול את מספר הדירות בקומה במספר הקומות",
                solutionGuide: "$ {A} × {B} = {Result} $ דירות"
            },

            {
                question: "יש {total} כדורים. חילקו אותם ל-{B} קבוצות שוות. כמה כדורים קיבלה כל קבוצה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [4, 10],
                        type: "integer"
                    },
                    B: {
                        range: [3, 9],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה כדורים בכל קבוצה, צריך לחלק את מספר הכדורים במספר הקבוצות",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ כדורים"
            },

            {
                question: "לדני היו {A} מדבקות. הוא קיבל עוד {B} מדבקות. כמה מדבקות יש לו עכשיו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 50],
                        type: "integer"
                    },
                    B: {
                        range: [15, 40],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "כדי למצוא את סך כל המדבקות, צריך לחבר את המדבקות שהיו לו עם המדבקות החדשות",
                solutionGuide: "$ {A} + {B} = {Result} $ מדבקות"
            },

            {
                question: "בחנות יש {A} מדפים. בכל מדף {B} ספרים. כמה ספרים יש בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [3, 8],
                        type: "integer"
                    },
                    B: {
                        range: [5, 10],
                        type: "integer"
                    }
                },
                formula: "A*B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא את סך כל הספרים, צריך לכפול את מספר הספרים במדף במספר המדפים",
                solutionGuide: "$ {A} × {B} = {Result} $ ספרים"
            },

            {
                question: "היו בסל {A} תפוחים. נרקבו {B} תפוחים. כמה תפוחים טובים נשארו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [40, 70],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A-5"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה תפוחים טובים נשארו, צריך להחסיר את מספר התפוחים שנרקבו מהמספר ההתחלתי",
                solutionGuide: "$ {A} - {B} = {Result} $ תפוחים"
            },

            {
                question: "בכיתה יש {total} כיסאות. המורה סידרה אותם ב-{B} שורות שוות. כמה כיסאות יש בכל שורה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [4, 8],
                        type: "integer"
                    },
                    B: {
                        range: [4, 8],
                        type: "integer"
                    }
                },
                formula: "A",
                metadata: {
                    total: "{A} * {B}"
                },
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-three"]
                },
                hint: "כדי למצוא כמה כיסאות בכל שורה, צריך לחלק את מספר הכיסאות במספר השורות",
                solutionGuide: "$ {total} ÷ {B} = {Result} $ כיסאות בכל שורה"
            },

            {
                question: "לגן הגיעו {A} בנים ו-{B} בנות. כמה ילדים הגיעו בסך הכל?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [15, 30],
                        type: "integer"
                    },
                    B: {
                        range: [15, 30],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-two", "off-by-ten"]
                },
                hint: "כדי למצוא את סך כל הילדים, צריך לחבר את מספר הבנים עם מספר הבנות",
                solutionGuide: "$ {A} + {B} = {Result} $ ילדים"
            },
            {
                question: "התאימו כל חיה למספר העוגיות שנשארו",
                type: "matching",
                pairs: [
                    { left: "דינוזאור אכל 15 עוגיות מתוך 23", right: "8 עוגיות" },
                    { left: "פיל אכל 14 עוגיות מתוך 20", right: "6 עוגיות" },
                    { left: "ג׳ירפה אכלה 8 עוגיות מתוך 15", right: "7 עוגיות" },
                    { left: "היפופוטם אכל 35 עוגיות מתוך 40", right: "5 עוגיות" }
                ],
                hint: "חסרו את מספר העוגיות שנאכלו מהמספר ההתחלתי",
                solutionGuide: "$23 - 15 = 8$<br/>$20 - 14 = 6$<br/>$15 - 8 = 7$<br/>$40 - 35 = 5$"
            },
            {
                question: "התאימו כל גיבור-על למספר החיות שהציל",
                type: "matching",
                pairs: [
                    { left: "ספיידרמן הציל 4 חתולים בכל אחד מ-6 בניינים", right: "24" },
                    { left: "סופרמן הציל 3 כלבים בכל אחד מ-8 רחובות", right: "32" },
                    { left: "באטמן הציל 8 ציפורים בכל אחד מ-2 פארקים", right: "16" },
                    { left: "וונדר-וומן הצילה 4 ארנבים בכל אחת מ-12 גינות", right: "48" }
                ],
                hint: "כפלו את מספר החיות בכל מקום במספר המקומות",
                solutionGuide: "$4 × 6 = 24$<br/>$3 × 8 = 24$<br/>$8 × 2 = 16$<br/>$4 × 12 = 48$"
            },
            {
                question: "כמה חפצים כל אחד נתן",
                type: "matching",
                pairs: [
                    { left: "הקוסם נתן 5 כובעים לכל אחד מ-3 הארנבים", right: "15" },
                    { left: "המכשפה נתנה 3 צפרדעים לכל אחת מ-7 הנסיכות", right: "21" },
                    { left: "הפיה נתנה 4 שיקויים לכל אחד מ-8 הנסיכים", right: "32" },
                    { left: "הדרקון נתן פרח לכל אחת מ-12 הנסיכות ", right: "12" }
                ],
                hint: "כפלו את מספר החפצים במספר התוצאות לכל חפץ",
                solutionGuide: "$5 × 3 = 15$<br/>$3 × 7 = 21$<br/>$4 × 8 = 32$<br/>$12 × 1 = 12$"
            },
            {
                question: "התאימו כל חיה לכמות האוכל שנשארה",
                type: "matching",
                pairs: [
                    { left: "פנדה אכל 25 במבוקים מתוך 40", right: "15" },
                    { left: "קנגורו אכל 35 גזרים מתוך 60", right: "25" },
                    { left: "תוכי אכל 10 בננות מתוך 50", right: "40" },
                    { left: "קוף אכל 45 תפוחים מתוך 55", right: "10" }
                ],
                hint: "חסרו את מה שנאכל מהכמות ההתחלתית",
                solutionGuide: "$40 - 25 = 15$<br/>$60 - 35 = 25$<br/>$50 - 10 = 40$<br/>$55 - 45 = 10$"
            },
            {
                question: "התאימו כל רובוט למספר הברגים שהוא הרכיב",
                type: "matching",
                pairs: [
                    { left: "רובוט א׳ הרכיב 6 ברגים בכל אחד מ-5 צעצועים", right: "30" },
                    { left: "רובוט ב׳ הרכיב 10 ברגים בכל אחד מ-4 רובוטים", right: "40" },
                    { left: "רובוט ג׳ הרכיב 2 ברגים בכל אחד מ-15 מכוניות", right: "20" },
                    { left: "רובוט ד׳ הרכיב 5 ברגים בכל אחד מ-7 מטוסים", right: "35" }
                ],
                hint: "כפלו את מספר הברגים בכל חפץ במספר החפצים",
                solutionGuide: "$6 × 5 = 30$<br/>$10 × 4 = 40$<br/>$2 × 15 = 20$<br/>$5 × 7 = 35$"
            },

            {
                question: "התאימו את הפעולה הנכונה לסיפור",
                type: "matching",
                pairs: [
                    { left: "דני קנה 3 קופסאות עם 4 עוגיות בכל אחת", right: "כפל" },
                    { left: "ליהלי היו 12 בלונים ו-5 התפוצצו", right: "חיסור" },
                    { left: "חילקו 20 סוכריות ל-4 ילדים שווה בשווה", right: "חילוק" },
                    { left: "בכיתה א׳ 15 ילדים ובכיתה ב׳ 17, כמה סך הכל?", right: "חיבור" }
                ],
                hint: "חשבו איזו פעולה חשבונית מתאימה לכל סיפור",
                solutionGuide: "$3 × 4 =$ כפל<br/>$12 - 5 =$ חיסור<br/>$20 ÷ 4 =$ חילוק<br/>$15 + 17 =$ חיבור"
            },
            {
                question: "התאימו כל מספר לתכונה המיוחדת שלו",
                type: "matching",
                pairs: [
                    { left: "15", right: "מתחלק ב-3 וב-5" },
                    { left: "24", right: "מתחלק ב-2 וב-3" },
                    { left: "25", right: "שווה ל 5 כפול עצמו" },
                    { left: "17", right: "אי אפשר לחלק אותו" }
                ],
                hint: "בדקו את המחלקים של כל מספר והתכונות המיוחדות שלו",
                solutionGuide: "$15 = 3 × 5$<br/>$24 = 2 × 12 = 3 × 8$<br/>$25 = 5 × 5$<br/>$17$ מתחלק רק ב-1 וב-17"
            },
            {
                question: "התאימו כל שעה לפעילות המתאימה",
                type: "matching",
                pairs: [
                    { left: "45 דקות אחרי 7:00", right: "7:45" },
                    { left: "רבע שעה לפני 9:00", right: "8:45" },
                    { left: "חצי שעה אחרי 10:30", right: "11:00" },
                    { left: "20 דקות לפני 6:00", right: "5:40" }
                ],
                hint: "חשבו כמה דקות צריך להוסיף או להחסיר",
                solutionGuide: "7:45 = 7:00 + 45 דקות<br/>8:45 = 15 דקות - 9:00 <br/>11:00 = 10:30 + 30 דקות<br/>5:40 = 6:00 - 20 דקות"
            },
            {
                question: "סדרו את התוצאות מהקטן לגדול",
                type: "order",
                items: [
                    "חילקו 20 סוכריות ל-4 ילדים שווה בשווה",
                    "ליהלי היו 15 בלונים ו-5 התפוצצו",
                    "דני קנה 3 קופסאות עם 4 עוגיות בכל אחת",
                    "בכיתה א׳ 8 ילדים ובכיתה ב׳ 7"
                ],
                hint: "פתרו כל בעיה מילולית וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "סוכריות: $20 ÷ 4 = 5$ לכל ילד<br/>יהלי: $15 - 5 = 10$ בלונים<br/>דני: $3 × 4 = 12$ עוגיות<br/>כיתות: $8 + 7 = 15$ ילדים<br/>סדר: $5 < 10 < 12 < 15$"
            },
            {
                question: "סדרו את התוצאות מהקטן לגדול",
                type: "order",
                items: [
                    "חילקו 40 סוכריות ל-8 ילדים",
                    "היו 30 כדורים ו-12 התפוצצו",
                    "בגינה 4 שורות של 6 פרחים בכל שורה",
                    "15 בנים ו-18 בנות בכיתה"
                ],
                hint: "פתרו כל בעיה מילולית וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "סוכריות: $40 ÷ 8 = 5$ לכל ילד<br/>כדורים: $30 - 12 = 18$ כדורים<br/>פרחים: $4 × 6 = 24$ פרחים<br/>ילדים: $15 + 18 = 33$ ילדים<br/>סדר: $5 < 18 < 24 < 33$"
            },
            {
                question: "סדרו את התוצאות מהקטן לגדול",
                type: "order",
                items: [
                    "25 תפוזים חולקו ל-5 ילדים",
                    "היו 27 כדורים ו-12 התפוצצו",
                    "3 בלונים לכל אחד מ-8 ילדים",
                    "7 מחברות במחיר 4 שקלים כל אחת"
                ],
                hint: "פתרו כל בעיה מילולית וסדרו את התוצאות מהקטן לגדול",
                solutionGuide: "תפוזים: $25 ÷ 5 = 5$ לכל ילד<br/>כדורים: $27 - 12 = 15$ כדורים<br/>בלונים: $3 × 8 = 24$ בלונים<br/>מחברות: $7 × 4 = 28$ שקלים<br/>סדר: $5 < 15 < 24 < 28$"
            }
        ]
    }
};