export const learningContent = {
    "חיבור וחיסור עד 100": {
        tags: ['כיתה ג׳', 'כיתה ב׳', 'חיבור וחיסור'],
        relatedLinks: [
            { title: "תרגול חיבור עד 100", url: "/?tags=חיבור%20וחיסור", description: "תרגול בעיות חיבור עד 100" },
            { title: "תרגול חיסור עד 100", url: "/?tags=חיבור%20וחיסור", description: "תרגול בעיות חיסור עד 100" }
        ],

        getStarted: [
            "**איך מחברים וחיסור מספרים עד 100?**",
            "",
            "✨ חיבור מספרים:",
            "מחברים קודם את האחדות",
            "אם יש יותר מ-10, מעבירים עשרת למספר העשרות",
            "אחר כך מחברים את העשרות",
            "",
            "✨ חיסור מספרים:",
            "מחסרים קודם את האחדות",
            "אם אי אפשר לחסר (המספר למעלה קטן יותר), פורטים עשרת",
            "אחר כך מחסרים את העשרות",
            "",
            "**טריקים מועילים:**",
            "חיבור לעשרת עגולה: משלימים לעשרת ומוסיפים את השאר",
            "  למשל: $8 + 5 = (8 + 2) + 3 = 10 + 3 = 13$",
            "",
            "חיסור מעשרת עגולה: מחסירים עד העשרת ואז את השאר",
            "  למשל: $20 - 7 = (20 - 5) - 2 = 15 - 2 = 13$",
            "",
            "שימוש במשלימים ל-10:",
            "  $7 + 8 = 7 + (3 + 5) = (7 + 3) + 5 = 10 + 5 = 15$"
        ],
        examples: [
            {
                question: "כמה זה $45 + 23$?",
                solution: "נחבר בנפרד עשרות ואחדות:<br/>עשרות: $40 + 20 = 60$<br/>אחדות: $5 + 3 = 8$<br/>סך הכל: $60 + 8 = 68$",
                drawing: {
                    type: "blocks",
                    operation: "addition",
                    firstNumber: { tens: 4, ones: 5 },
                    secondNumber: { tens: 2, ones: 3 },
                    result: { tens: 6, ones: 8 },
                    caption: "חיבור עשרות: $40 + 20 = 60$<br/>חיבור אחדות: $5 + 3 = 8$"
                }
            },
            {
                question: "כמה זה $52 - 17$?",
                solution: "נחסר קודם את האחדות:<br/>$52 - 7 = 45$<br/>עכשיו נחסר את העשרות:<br/>$45 - 10 = 35$",
                drawing: {
                    type: "blocks",
                    operation: "subtraction",
                    firstNumber: { tens: 5, ones: 2 },
                    secondNumber: { tens: 1, ones: 7 },
                    result: { tens: 3, ones: 5 },
                    caption: "חיסור אחדות: $12 - 7 = 5$<br/>חיסור עשרות: $40 - 10 = 30$"
                }
            },
            {
                question: "כמה זה $38 + 45$?",
                solution: "נחבר בנפרד:<br/>עשרות: $30 + 40 = 70$<br/>אחדות: $8 + 5 = 13$<br/>נעביר את העשרת: $70 + 13 = 83$",
                drawing: {
                    type: "blocks",
                    operation: "addition",
                    firstNumber: { tens: 3, ones: 8 },
                    secondNumber: { tens: 4, ones: 5 },
                    result: { tens: 8, ones: 3 },
                    caption: "חיבור עשרות: $30 + 40 = 70$<br/>חיבור אחדות: $8 + 5 = 13$<br/>העברת עשרת: $70 + 13 = 83$"
                }
            }
        ],
        quiz: [
            
               // Start of Selection
               {
                    question: "סדרו את התוצאות מהקטן לגדול",
                    type: "order",
                    items: ["$20 - 15$", "$35 - 15$", "$25 + 15$", "$45 + 15$"],
                    hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                    solutionGuide: "$20 - 15 = 5$<br/>$35 - 15 = 20$<br/>$25 + 15 = 40$<br/>$45 + 15 = 60$<br/>סדר: $5 < 20 < 40 < 60$"
                },
                {
                    question: "סדרו את המספרים מהקטן לגדול",
                    type: "order",
                    items: ["$30 - 25$", "$40 - 25$", "$35 + 25$", "$50 + 25$"],
                    hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                    solutionGuide: "$30 - 25 = 5$<br/>$40 - 25 = 15$<br/>$35 + 25 = 60$<br/>$50 + 25 = 75$<br/>סדר: $5 < 15 < 60 < 75$"
                },
                {
                    question: "סדרו את התוצאות מהקטן לגדול",
                    type: "order",
                    items: ["$40 - 30$", "$60 - 30$", "$50 + 30$", "$70 + 30$"],
                    hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                    solutionGuide: "$40 - 30 = 10$<br/>$60 - 30 = 30$<br/>$50 + 30 = 80$<br/>$70 + 30 = 100$<br/>סדר: $10 < 30 < 80 < 100$"
                },
                {
                    question: "סדרו את המספרים מהקטן לגדול",
                    type: "order",
                    items: ["$20 - 8$", "$25 - 8$", "$10 + 8$", "$15 + 8$"],
                    hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                    solutionGuide: "$20 - 8 = 12$<br/>$25 - 8 = 17$<br/>$10 + 8 = 18$<br/>$15 + 8 = 23$<br/>סדר: $12 < 17 < 18 < 23$"
                },
                {
                    question: "סדרו את התוצאות מהקטן לגדול",
                    type: "order",
                    items: ["$35 - 20$", "$40 - 20$", "$25 + 20$", "$30 + 20$"],
                    hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                    solutionGuide: "$35 - 20 = 15$<br/>$40 - 20 = 20$<br/>$25 + 20 = 45$<br/>$30 + 20 = 50$<br/>סדר: $15 < 20 < 45 < 50$"
                },
                {
                    question: "סדרו את המספרים מהקטן לגדול",
                    type: "order",
                    items: ["$30 - 10$", "$15 + 10$", "$40 - 10$", "$25 + 10$"],
                    hint: "פתרו כל תרגיל וסדרו את התוצאות מהקטן לגדול",
                    solutionGuide: "$30 - 10 = 20$<br/>$15 + 10 = 25$<br/>$40 - 10 = 30$<br/>$25 + 10 = 35$<br/>סדר: $20 < 25 < 30 < 35$"
                }, 
            {
                question: "כמה זה $ {A} - {B} $?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 99],
                        type: "integer"
                    },
                    B: {
                        range: [5, "A"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "digit-swap"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                },
                hint: "פרק את המספרים לעשרות ואחדות: {A} = {tensA} + {onesA}, {B} = {tensB} + {onesB}",
                solutionGuide: "$ {A} - {B} = {A} - {tensB} - {onesB}  = {Result} $"

            },
            {
                question: "חשבו: $ {A} + {B} $",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [15, 98],
                        type: "integer"
                    },
                    B: {
                        range: [5, "99-A"],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "digit-swap"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                    sumTens: "floor({A}/10)*10 + floor({B}/10)*10",
                    sumOnes: "{A}%10 + {B}%10"

                },
                hint: "חבר קודם את העשרות: {tensA} + {tensB} = {sumTens}, ואז הוסף את האחדות: {onesA} + {onesB} = {sumOnes}",
                solutionGuide: "$ {A} + {B} = {tensA} + {tensB}  + {onesA} + {onesB} = {sumTens} + {sumOnes} = {Result} $"
            },
            {
                question: "חשבו: $ {A} - {B} $?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [50, 99],
                        type: "integer"
                    },
                    B: {
                        range: [15, "A"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                },
                hint: "פרק את המספרים לעשרות ואחדות: {A} = {tensA} + {onesA}, {B} = {tensB} + {onesB}",
                solutionGuide: "$ {A} - {B} = {A} - {tensB} - {onesB}  = {Result} $"

            },
            {
                question: "חשבו: $ {A} + {B} $",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [50, 98],
                        type: "integer"
                    },
                    B: {
                        range: [15, "99-A"],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                    sumTens: "floor({A}/10)*10 + floor({B}/10)*10",
                    sumOnes: "{A}%10 + {B}%10"

                },
                hint: "חבר קודם את העשרות: {tensA} + {tensB} = {sumTens}, ואז הוסף את האחדות: {onesA} + {onesB} = {sumOnes}",
                solutionGuide: "$ {A} + {B} = {tensA} + {tensB}  + {onesA} + {onesB} = {sumTens} + {sumOnes} = {Result} $"
            },
            {
                question: "חשבו: $ {A} - {B} $?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [40, 99],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                },
                hint: "פרק את המספרים לעשרות ואחדות: {A} = {tensA} + {onesA}, {B} = {tensB} + {onesB}",
                solutionGuide: "$ {A} - {B} = {A} - {tensB} - {onesB}  = {Result} $"

            },
            {
                question: "חשבו: $ {A} + {B} $",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [40, 98],
                        type: "integer"
                    },
                    B: {
                        range: [10, "99-A"],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                    sumTens: "floor({A}/10)*10 + floor({B}/10)*10",
                    sumOnes: "{A}%10 + {B}%10"

                },
                hint: "חבר קודם את העשרות: {tensA} + {tensB} = {sumTens}, ואז הוסף את האחדות: {onesA} + {onesB} = {sumOnes}",
                solutionGuide: "$ {A} + {B} = {tensA} + {tensB}  + {onesA} + {onesB} = {sumTens} + {sumOnes} = {Result} $"
            },
            {
                question: "לקוסם היו {A} ארנבים. הוא העלים {B} ארנבים. כמה נותרו?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [40, 99],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                },
                hint: "פרק את המספרים לעשרות ואחדות: {A} = {tensA} + {onesA}, {B} = {tensB} + {onesB}",
                solutionGuide: "$ {A} - {B} = {A} - {tensB} - {onesB}  = {Result} $"

            },
            {
                question: "לטל היו {A} קלפי פוקימון. לנועם היו {B} קלפים. כמה קלפים לשניהם יחד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [40, 98],
                        type: "integer"
                    },
                    B: {
                        range: [10, "99-A"],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                    sumTens: "floor({A}/10)*10 + floor({B}/10)*10",
                    sumOnes: "{A}%10 + {B}%10"

                },
                hint: "חבר קודם את העשרות: {tensA} + {tensB} = {sumTens}, ואז הוסף את האחדות: {onesA} + {onesB} = {sumOnes}",
                solutionGuide: "$ {A} + {B} = {tensA} + {tensB}  + {onesA} + {onesB} = {sumTens} + {sumOnes} = {Result} $"
            },
            {
                question: "ללביא היו {A} עפרונות. הוא נתן לחבר {B} עפרונות. כמה עפרונות נותרו ללביא?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [40, 99],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                },
                hint: "פרק את המספרים לעשרות ואחדות: {A} = {tensA} + {onesA}, {B} = {tensB} + {onesB}",
                solutionGuide: "$ {A} - {B} = {A} - {tensB} - {onesB}  = {Result} $"

            },
            {
                question: "ללביא היו {A} גולות. לאלה היו {B} גולות. כמה גולות יש לשניהם ביחד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [40, 98],
                        type: "integer"
                    },
                    B: {
                        range: [10, "99-A"],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                    sumTens: "floor({A}/10)*10 + floor({B}/10)*10",
                    sumOnes: "{A}%10 + {B}%10"

                },
                hint: "חבר קודם את העשרות: {tensA} + {tensB} = {sumTens}, ואז הוסף את האחדות: {onesA} + {onesB} = {sumOnes}",
                solutionGuide: "$ {A} + {B} = {tensA} + {tensB}  + {onesA} + {onesB} = {sumTens} + {sumOnes} = {Result} $"
            },
            {
                question: "לליאור היו {A} סוכריות. היא אכלה {B} סוכריות. כמה סוכריות נותרו לליאור?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 99],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                },
                hint: "פרק את המספרים לעשרות ואחדות: {A} = {tensA} + {onesA}, {B} = {tensB} + {onesB}",
                solutionGuide: "$ {A} - {B} = {A} - {tensB} - {onesB}  = {Result} $"

            },
            {
                question: "ללביא היו {A} כדורים. לאלה היו {B} כדורים. כמה כדורים יש לשניהם ביחד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 98],
                        type: "integer"
                    },
                    B: {
                        range: [10, "99-A"],
                        type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                    sumTens: "floor({A}/10)*10 + floor({B}/10)*10",
                    sumOnes: "{A}%10 + {B}%10"

                },
                hint: "חבר קודם את העשרות: {tensA} + {tensB} = {sumTens}, ואז הוסף את האחדות: {onesA} + {onesB} = {sumOnes}",
                solutionGuide: "$ {A} + {B} = {tensA} + {tensB}  + {onesA} + {onesB} = {sumTens} + {sumOnes} = {Result} $"
            },
            {
                question: "לנינג'ה היו {A} כוכבים. הוא זרק {B} כוכבים. כמה כוכבים נותרו לנינג'ה?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 99],
                        type: "integer"
                    },
                    B: {
                        range: [10, "A"],
                        type: "integer"
                    }
                },
                formula: "A-B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                },
                hint: "פרק את המספרים לעשרות ואחדות: {A} = {tensA} + {onesA}, {B} = {tensB} + {onesB}",
                solutionGuide: "$ {A} - {B} = {A} - {tensB} - {onesB}  = {Result} $"

            },
            {
                question: "לדנה היו {A} פרחים. לאורי היו {B} פרחים. כמה פרחים יש לשניהם ביחד?",
                type: "multiChoiceRandomMath",
                params: {
                    A: {
                        range: [20, 98], type: "integer"
                    },
                    B: {
                        range: [1, "99-A"], type: "integer"
                    }
                },
                formula: "A+B",
                distractors: {
                    strategies: ["off-by-one", "off-by-ten", "off-by-eleven"]
                },
                metadata: {
                    tensA: "floor({A}/10)*10",
                    onesA: "{A}%10",
                    tensB: "floor({B}/10)*10",
                    onesB: "{B}%10",
                    sumTens: "floor({A}/10)*10 + floor({B}/10)*10",
                    sumOnes: "{A}%10 + {B}%10"
                },
                hint: "חבר קודם את העשרות: {tensA} + {tensB} = {sumTens}, ואז הוסף את האחדות: {onesA} + {onesB} = {sumOnes}",
                solutionGuide: "$ {A} + {B} = {tensA} + {tensB}  + {onesA} + {onesB} = {sumTens} + {sumOnes} = {Result} $"
            }
        ]
    }
};