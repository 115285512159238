import React from 'react';
import { Box } from '@chakra-ui/react';
import DinoAnimation from './MascotAnimation';
import { useQuizMascot } from './useQuizMascot';
import { useCharacter } from '../../hooks/useCharacter';

const MascotDisplay = ({ questionStartTime, isCorrect }) => {
  const { mascotState } = useQuizMascot(questionStartTime, isCorrect);
  const { selectedCharacter } = useCharacter();
  
  if (selectedCharacter === 'None') {
    return null;
  }

  return (
    <Box p={3}>
      <DinoAnimation state={mascotState} character={selectedCharacter} />
    </Box>
  );
};

export default MascotDisplay;