import React from 'react';
import { SimpleGrid, Box, Text, HStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

export const MultipleChoiceQuestion = ({ question, onAnswer, showFeedback }) => (
  <SimpleGrid columns={2} spacing={4} key={question.question}>
    {question.options.map((option, index) => (
      <MotionBox
        key={`option-${index}`}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        width="100%"
      >
        <Box
          onClick={() => !showFeedback && onAnswer(String.fromCharCode(97 + index))}
          isDisabled={showFeedback}
          bg="blue.50"
          borderWidth={2}
          borderColor="blue.200"
          borderRadius="md"
          p={6}
          cursor="pointer"
          _hover={{ bg: 'blue.200' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="120px"
          width="100%"
        >
          <HStack spacing={4} width="100%" justifyContent="center">
            <Text 
              dangerouslySetInnerHTML={{ __html: option }}
              textAlign="center"
              fontSize={['sm', 'md', 'xl']}
              fontWeight="bold"
              wordBreak="break-word"
              maxWidth="100%"
              sx={{
                fontSize: 'clamp(0.875rem, 2vw, 1.25rem)',
                lineHeight: '1.4',
                '& .MathJax': {
                  maxWidth: '100%',
                  overflow: 'visible !important',
                  display: 'inline-block !important',
                  border: 'none !important',
                  background: 'transparent !important',
                  '& > span': {
                    border: 'none !important',
                    background: 'transparent !important'
                  }
                },
                '& .MathJax_SVG': {
                  border: 'none !important',
                  background: 'transparent !important'
                },
                '& .MathJax_SVG_Display': {
                  border: 'none !important',
                  background: 'transparent !important'
                }
              }}
            />
          </HStack>
        </Box>
      </MotionBox>
    ))}
  </SimpleGrid>
); 