// QuizCard.js
import React, { useState, useEffect, useCallback } from 'react';
import { Box, HStack, Text, VStack, Icon, Button } from '@chakra-ui/react';
import { BsLightbulb } from 'react-icons/bs';
import { motion } from 'framer-motion';
import { WrongAnswerModal } from './WrongAnswerModal';
import QuizScore from './QuizScore';
import MathVisualization from './MathVisualization';
import { useRandomReward } from './rewardsAnimations';
import { useTopicProgress } from './useTopicProgress';
import { MascotDisplay } from './Mascot';
import { QuestionContent } from './questions/QuestionContent';
import { checkAnswer } from '../utils/answerChecker';
import { processQuizQuestions } from './questions/RandomExerciseGenerator';
import { useCurrency } from '../hooks/useCurrency';
import { calculateCoins } from '../utils/calculateCoins';
import { CoinAnimation } from './CoinAnimation';


const MotionBox = motion(Box);

const formatNumberOption = (value) => {
  // If already properly formatted with $ delimiters, return as is
  if (typeof value === 'string' && value.includes('$')) {
    return value;
  }

  // If it's a simple number
  if (typeof value === 'number' || !isNaN(value)) {
    const num = Number(value);
    if (num < 0) {
      return `$(${num})$`;
    }
    return `$${num}$`;
  }

  // If it's text mixed with potential equations, leave as is
  return value;
};

const getWeightedRandomIndex = (questions) => {
  // First, find what question types are actually available
  const availableTypes = new Set(questions.map(q => q.type));

  // Define relative weights (ratios between types)
  const relativeWeights = {
    multiChoiceRandomMath: 10,    // 15x weight
    multiChoiceRandomBoolean: 10,  // 15x weight 
    multiplechoice: 10,           // 15x weight
    matching: 1,                  // base weight
    order: 1                      // base weight
  };

  // Filter to only include available types and create normalized weights
  const availableWeights = Object.entries(relativeWeights)
    .filter(([type]) => availableTypes.has(type))
    .reduce((acc, [type, weight]) => {
      acc[type] = weight;
      return acc;
    }, {});

  // Normalize weights to sum to 1
  const totalWeight = Object.values(availableWeights).reduce((sum, weight) => sum + weight, 0);
  const normalizedWeights = Object.entries(availableWeights).reduce((acc, [type, weight]) => {
    acc[type] = weight / totalWeight;
    return acc;
  }, {});

  const roll = Math.random();
  let cumulativeWeight = 0;

  const preferredType = Object.entries(normalizedWeights).find(([type, weight]) => {
    cumulativeWeight += weight;
    return roll < cumulativeWeight;
  })?.[0] || questions[0].type;

  const matchingIndices = questions.reduce((acc, q, idx) => {
    if (q.type === preferredType) acc.push(idx);
    return acc;
  }, []);

  return matchingIndices[Math.floor(Math.random() * matchingIndices.length)];
};

export const QuizCard = ({ quiz, selectedTopic, setSelectedTopic }) => {
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const [totalQuizTime, setTotalQuizTime] = useState(0);
  const { addQuizScore } = useTopicProgress(selectedTopic);
  const [currentStreak, setCurrentStreak] = useState(0);
  const { addCoins } = useCurrency();
  const [showCoins, setShowCoins] = useState(null);
  const [answers, setAnswers] = useState([]);
  const { playRandomReward, FireworkComponent, FloatingEmojiComponent } = useRandomReward();
  const [showHint, setShowHint] = useState(false);
  const [canShowHint, setCanShowHint] = useState(false);


  const [questions] = useState(() => {
    const numQuestions = Math.min(10, quiz.length);
    const selectedIndices = [];
    while (selectedIndices.length < numQuestions) {
      const randomIndex = getWeightedRandomIndex(quiz);
      if (!selectedIndices.includes(randomIndex)) {
        selectedIndices.push(randomIndex);
      }
    }
    const selectedQuestions = selectedIndices.map(index => quiz[index]);
    const processedQuestions = processQuizQuestions(selectedQuestions);

    return processedQuestions.map(question => {
      //console.log('Processed questions:', processedQuestions);
      if (question.type === 'multiplechoice') {
        const shuffledOptions = question.options.map((opt, i) => ({
          option: formatNumberOption(opt),
          originalIndex: String.fromCharCode(97 + i)
        }));

        // Better randomization for options
        const randomOffset = Math.floor(Math.random() * shuffledOptions.length);
        for (let i = shuffledOptions.length - 1; i > 0; i--) {
          const j = (Math.floor(Math.random() * i) + randomOffset) % shuffledOptions.length;
          [shuffledOptions[i], shuffledOptions[j]] = [shuffledOptions[j], shuffledOptions[i]];
        }

        // Add extra randomization pass
        if (Math.random() > 0.5) {
          shuffledOptions.reverse();
        }

        return {
          ...question,
          options: shuffledOptions.map(o => o.option),
          shuffledAnswerMap: shuffledOptions.map(o => o.originalIndex)
        };
      } else if (question.type === 'order') {
        // Add shuffling for order type questions
        const originalOrder = [...question.items];
        const shuffledItems = [...question.items];

        // Shuffle the items
        for (let i = shuffledItems.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledItems[i], shuffledItems[j]] = [shuffledItems[j], shuffledItems[i]];
        }

        return {
          ...question,
          items: shuffledItems,
          originalOrder
        };
      }
      return question;
    });
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  // const [currentIcon, setCurrentIcon] = useState(ICONS[Math.floor(Math.random() * ICONS.length)]);

  useEffect(() => {
    window.MathJax?.typeset();
  }, [currentQuestionIndex, showFeedback, showHint]);

  // useEffect(() => {
  //   setCurrentIcon(ICONS[Math.floor(Math.random() * ICONS.length)]);
  // }, [currentQuestionIndex]);

  const handleModalClose = useCallback(() => {
    setShowExplanationModal(false);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
      setShowFeedback(false);
    } else {
      setTimeout(() => {
        setSelectedTopic('');
      }, 1500);
    }
  }, [currentQuestionIndex, questions.length, setSelectedTopic]);

  const calculateTimeSpent = () => {
    const MAX_QUESTION_TIME = 300; // 5 minutes max per question
    const timeSpent = Math.floor((Date.now() - questionStartTime) / 1000);
    return Math.min(timeSpent, MAX_QUESTION_TIME);
  };

  const handleAnswer = async (answer) => {
    if (showFeedback) return;

    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect = checkAnswer(answer, currentQuestion);
    const timeSpent = calculateTimeSpent();
    
    // Add current question time to total
    setTotalQuizTime(prev => prev + timeSpent);

    const newAnswers = [...answers, isCorrect];
    setAnswers(newAnswers);
    setShowFeedback(true);

    if (currentQuestionIndex === questions.length - 1) {
      try {
        const quizStats = {
          timestamp: Date.now(),
          score: newAnswers.filter(a => a).length,
          totalQuestions: questions.length,
          timeSpent: totalQuizTime + timeSpent,
          mastery: (newAnswers.filter(a => a).length / questions.length) * 100
        };
        console.log('Writing quiz stats:', JSON.stringify(quizStats));
        await addQuizScore(quizStats);
      } catch (error) {
        console.error('Failed to save quiz progress:', error);
      }
    }

    if (isCorrect) {
      const newStreak = currentStreak + 1;
      setCurrentStreak(newStreak);

      if (newStreak >= 3) {
        playRandomReward();
        setCurrentStreak(0); // Reset streak after reward
      }

      const audio = new Audio(require('../resources/correctanswer.mp3'));
      audio.play();

      const earnedCoins = calculateCoins({
        isCorrect: true,
        streak: currentStreak,
        questionStartTime,
        isQuizComplete: currentQuestionIndex === questions.length - 1,
        correctAnswers: newAnswers.filter(a => a).length,
        totalQuestions: questions.length
      });
      
      addCoins(earnedCoins, `${selectedTopic}`, 'answer');
      setShowCoins(earnedCoins);
      setTimeout(() => setShowCoins(null), 1500);

      setTimeout(() => {
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(prev => prev + 1);
          setShowFeedback(false);
          setQuestionStartTime(Date.now());
        } else {
          if (newAnswers.length === questions.length && newAnswers.every(a => a === true)) {
            const allCorrectAudio = new Audio(require('../resources/allcorrectsound.mp3'));
            allCorrectAudio.play();
          } else {
            const finalAudio = new Audio(require('../resources/finalsound.mp3'));
            finalAudio.play();
          }
          setTimeout(() => setSelectedTopic(''), 500);
        }
      }, 200);
    } else {
      setCurrentStreak(0); // Reset streak on wrong answer
      const audio = new Audio(require('../resources/wronganswer.mp3'));
      audio.play();
      setShowExplanationModal(true);
    }
  };


  const score = answers.filter(a => a).length;



  useEffect(() => {
    setShowHint(false);
    setCanShowHint(false);
    if (questions[currentQuestionIndex]?.hint) {
      const timer = setTimeout(() => {
        setCanShowHint(true);
      }, 50000);
      return () => clearTimeout(timer);
    }
  }, [currentQuestionIndex, questions]);

  return (
    <Box
      bg="white"
      p={[1, 6, 16]}
      borderRadius="xl"
      boxShadow="lg"
      position="relative"
      width="100%"
      maxW="100%"
    >
      <CoinAnimation coins={showCoins} />
      <span id="rewardId" className="fixed top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none" />
      <VStack spacing={6} align="stretch">
        <HStack justify="space-between">
          <div className="flex flex-col items-center gap-4 p-6 bg-white rounded-lg shadow-lg" style={{ width: '300px', height: '120px' }}>
            <div className="flex flex-col w-full">
              <Text fontSize={`clamp(0.875rem, 2vw, 1.25rem)`} fontWeight="bold" mb={4}>
                שאלה {currentQuestionIndex + 1} מתוך {questions.length}
              </Text>
              <div className="w-full">
                <div className="h-3 w-full bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="h-full rounded-full transition-all duration-1000 ease-out"
                    style={{
                      width: `${(currentQuestionIndex / Math.min(10, questions.length)) * 100}%`,
                      backgroundColor: (currentQuestionIndex / Math.min(10, questions.length)) >= 0.8 ? '#22c55e' :
                        (currentQuestionIndex / Math.min(10, questions.length)) >= 0.5 ? '#3b82f6' :
                          '#6b7280'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <QuizScore correct={score} total={answers.length} />
        </HStack>



        <Box bg="blue.510" p={6} borderRadius="lg">
          <Box
            position="absolute"
            width="100%"
            left="0"
            style={{
              transform: 'translateY(-85%)'
            }}
          >
            <MascotDisplay
              questionStartTime={questionStartTime}
              isCorrect={showFeedback ? answers[answers.length - 1] : null}
            />
          </Box>
          <Text fontSize="xl" mb={6} dangerouslySetInnerHTML={{ __html: questions[currentQuestionIndex].question }} />
          {questions[currentQuestionIndex].drawing && (
            <Box mb={6}>
              <MathVisualization type={questions[currentQuestionIndex].drawing.type} config={questions[currentQuestionIndex].drawing} />
            </Box>
          )}
          {questions[currentQuestionIndex]?.hint && canShowHint && !showHint && (
            <Box textAlign="center" mb={4}>
              <Button
                leftIcon={<BsLightbulb />}
                colorScheme="yellow"
                variant="outline"
                onClick={() => setShowHint(true)}
              >
                רמז
              </Button>
            </Box>
          )}
          {showHint && (
            <VStack spacing={4} mb={4}>
              <MotionBox
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ duration: 0.3 }}
                bg="yellow.100"
                color="yellow.800"
                p={4}
                borderRadius="md"
                textAlign="center"
                w="full"
              >
                <HStack justify="center" spacing={3}>
                  <Icon as={BsLightbulb} w={6} h={6} />
                  <Text
                    fontSize="xl"
                    fontWeight="bold"
                    dangerouslySetInnerHTML={{ __html: questions[currentQuestionIndex].hint }}
                  />
                </HStack>
              </MotionBox>
            </VStack>
          )}
          <QuestionContent
            question={questions[currentQuestionIndex]}
            onAnswer={handleAnswer}
            showFeedback={showFeedback}
          />
        </Box>

        <WrongAnswerModal
          isOpen={showExplanationModal}
          onClose={handleModalClose}
          solutionGuide={questions[currentQuestionIndex]?.solutionGuide}
        />
      </VStack>
      {FireworkComponent}
      {FloatingEmojiComponent}
    </Box>
  );
};